import constants from '../constants';

//export default "https://localhost:5001"
//export const SERVER = window.location.origin? window.location.origin : "https://localhost:5001";
//export const SERVER = "https://workpldev.azurewebsites.net"; //development server
//export const SERVER = "https://hpepngmywpdemo.azurewebsites.net"; //multitenancy itg server MTF
//export const SERVER = "http://localhost:50266/";
//export const SERVER = "https://myworkplace-itg.intelligentspaces.int.hpe.com/api"; //itg server HPE-MTF
//export const SERVER = "https://myworkplace.intelligentspaces.int.hpe.com/api"; // production server
//export const SERVER = "https://jmmywp.azurewebsites.net";
//export const SERVER = "https://myworkplace-td.azurewebsites.net";

export const SERVER = constants.release().Serverurl;