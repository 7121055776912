import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
//eslint-disable-next-line
import { Grid, Box, Heading, FormField, DataTable, CheckBox, Text, Tab, Tabs } from 'grommet';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withPagination from './common/PaginatedDataTable';
import TextInputWithState from './common/TextInputWithState';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


const PaginatedDataTable = withPagination(DataTable);

var start = undefined;

export class OrgIntegrationClearpassEditContainer extends React.Component {


    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            index: 0,
            tabindex: 0,
            viewClearpassClientId: false,
            viewClearpassClientSecret: false,
            viewClearpassURL: false,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Error handling
    //TAB 1
    clearpassClientID_err = "";
    clearpassSecret_err = "";
    clearpassURL_err = "";

    //TAB 2

    eventDelete(event) {
        //const selectedId = this.state.selectedSite;

        //if (selectedId) {
        //    this.setState({ selectedSite: 0, open: undefined });
        //    this.props.action.deleteSiteAction(selectedId)
        //        .catch(error => {
        //            console.log('*DEBUG*- Site List Delete Error', error);
        //        });
        //}
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        const id = this.props.organisationdata.id;
        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Authentication" }
                            });
    }

    handleNew(event) {

    }

    handleEdit(event, nro) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //TAB 1
        this.clearpassClientID_err = "";
        this.clearpassSecret_err = "";
        this.clearpassURL_err = "";

        //TAB 2
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data

        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (organisationdefault.clearpassClientId.trim().length == 0) {
            errorsno++;
            this.clearpassClientID_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.clearpassClientId.trim().length > 50) {
            errorsno++;
            this.clearpassClientID_err = "Client ID can't be longer than 50 character";
            errortab = 0;
        }
        if (organisationdefault.clearpassClientSecret.trim().length == 0) {
            errorsno++;
            this.clearpassSecret_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.clearpassClientSecret.trim().length > 100) {
            errorsno++;
            this.clearpassSecret_err = "Client secret can't be longer than 100 character";
            errortab = 0;
        }
        if (organisationdefault.clearpassUrl.trim().length == 0) {
            errorsno++;
            this.clearpassURL_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.clearpassUrl.trim().length > 511) {
            errorsno++;
            this.clearpassURL_err = "Client ID can't be longer than 511 character";
            errortab = 0;
        }
        if (organisationdefault.clearpassUrl.trim().length != 0 && !(validator.isURL(organisationdefault.clearpassUrl, constants.URL_OPTIONS))) {
            errorsno++;
            this.clearpassURL_err = constants.URL_ERROR;
            errortab = 0;
        }

        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.onActive(errortab);
            this.setState({});
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Authentication" }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Authentication" }
                            });
                });
        }
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }

    //Handling data
    updateClearpass = (e) => {
        this.props.organisationdata.clearpassEnabled = !this.props.organisationdata.clearpassEnabled;

        if (this.props.organisationdata.clearpassEnabled && this.props.organisationdata.oauthEnabled) {
            this.props.organisationdata.oauthEnabled = false;
        }

        this.setState({ updated: !this.state.updated });
    }
    updateClearpassClientID = (e) => {
        this.props.organisationdata.clearpassClientId = e.target.value;
    }
    updateClearpassSecret = (e) => {
        this.props.organisationdata.clearpassClientSecret = e.target.value;
    }
    updateclearpassURL = (e) => {
        this.props.organisationdata.clearpassUrl = e.target.value;
    }

    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const { organisationdataLoading } = this.state;

        if (organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>Aruba Clearpass</Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" height="1200px">
                    <PopUp
                        header=""
                        open={this.state.isSaved}
                        text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                        onClose={() => this.onClose()}
                        createOrg={false}
                        orgName={organisationdefault.name}
                        error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="Configure">
                                <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Box width="75px">
                                        <FormField label="Active">
                                            <Box pad="small">
                                                <CheckBox checked={organisationdefault.clearpassEnabled} onChange={() => this.updateClearpass()} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    <FormField label="Client ID *" error={this.clearpassClientID_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateClearpassClientID} value={this.state.viewClearpassClientId ? organisationdefault.clearpassClientId : organisationdefault.clearpassClientId != "" ? "********" : ""} disabled={!this.state.viewClearpassClientId} plain type={this.state.viewClearpassClientId ? "text" : "password"} />
                                            <CheckBox label="View" checked={this.state.viewClearpassClientId} onChange={() => this.setState({ viewClearpassClientId: !this.state.viewClearpassClientId })} />
                                        </Box>
                                    </FormField>
                                    <FormField label="Client Secret *" error={this.clearpassSecret_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateClearpassSecret} value={this.state.viewClearpassClientSecret ? organisationdefault.clearpassClientSecret : organisationdefault.clearpassClientSecret != "" ? "********" : ""} disabled={!this.state.viewClearpassClientSecret} plain type={this.state.viewClearpassClientSecret ? "text" : "password"} />
                                            <CheckBox label="View" checked={this.state.viewClearpassClientSecret} onChange={() => this.setState({ viewClearpassClientSecret: !this.state.viewClearpassClientSecret })} />
                                        </Box>
                                    </FormField>
                                    <FormField label="URL *" error={this.clearpassURL_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateclearpassURL} value={this.state.viewClearpassURL ? organisationdefault.clearpassUrl : organisationdefault.clearpassUrl != "" ? "********" : ""} disabled={!this.state.viewClearpassURL} plain type={this.state.viewClearpassURL ? "text" : "password"} />
                                            <CheckBox label="View" checked={this.state.viewClearpassURL} onChange={() => this.setState({ viewClearpassURL: !this.state.viewClearpassURL })} />
                                        </Box>
                                    </FormField>
                                </StyledBox>
                            </Tab>
                            <Tab title="Documentation">
                                <StyledBox pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Text>
                                        Nothing to see here yet, Documentation coming soon
                                    </Text>
                                </StyledBox>
                            </Tab>
                        </Tabs>
                    </form>
                </Box>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || '';
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgIntegrationClearpassEditContainer));
