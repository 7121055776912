import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CollectionAction from '../action/CollectionAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import CollectionListTable from './CollectionListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PopUpConfirm from './common/PopUpConfirm';

export class CollectionListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedCollection: 0,
            deletedCollectionIds: [],
            open: undefined,
            collectionsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getAssetLabelsAction(true)
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedCollection: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedCollection;

        if (selectedId) {
            this.setState({ selectedCollection: 0, open: undefined });
            this.setState({ deletedCollectionIds: this.state.deletedCollectionIds.concat([selectedId]) });
            this.props.action.deleteCollectionAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Collection List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push('/collection/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/collection/' + nro);
    }

    render() {
        const { collectionsdata, assetlabelsdata } = this.props;
        const { open, selectedCollection, deletedCollectionIds, collectionsdataLoading, assetlabelsdataLoading } = this.state;

        if (collectionsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <PopUpConfirm open={open} text={constants.confirmMessage("collection")} onClose={() => this.onClose()} onDelete={() => this.eventDelete()} />
                    <CollectionListTable
                        collectionsdata={collectionsdata /*constants.COLLECTIONS*/}
                        assetlabelsdata={assetlabelsdata}
                        handleNew={this.handleNew}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        deletedCollectionIds={deletedCollectionIds} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    collectionsdata: state.collectionsReducer.collectionsdata,
    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CollectionAction, ...AssetLabelsAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(CollectionListContainer);
