import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedTrackerReducer = (state = initialState.selectedTrackerReducer, action) => {
    switch(action.type) {

        case ActionType.GET_TRACKER_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                trackerdata: _.assign(action.trackerdata)
            };
        }

        default: { return state; }
    }
};


export default selectedTrackerReducer;