import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class AssetStatusHistoryListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: '',
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }

    componentDidUpdate() {
        this.onDTUpdated();
    }

    render() {
        return (
            <StyledBox ref={this.dtwrapper} pad={{"horizontal":"small", "top":"small"}} margin={{"top":"small", "bottom":"medium"}}>
                {this.renderPagesTable(this.props.historydata)}
            </StyledBox>
        );
    }

    renderPagesTable(historydata) {
        console.log("hali", historydata);

        const COLUMNS = [
            {
                property: 'serviceStateId',
                header: 'Status',
                render: s => <Text>{s.name}</Text>
            },
            {
                property: 'servicedBy',
                header: 'User',
                render: s => <Text>{s.servicedBy}</Text>
            },
            //{
            //    property: 'startTime',
            //    header: 'Start Time',
            //    render: s => <Text>{s.start}</Text>
            //},
            {
                property: 'date',
                header: 'Start Date',
                render: s => <Text>{s.date}</Text>
            },
            {
                property: 'time',
                header: 'Start Time',
                render: s => <Text>{s.time}</Text>
            },
            {
                property: 'note',
                header: 'Notes',
                render: s => <Text>{this.renderNote(s.name)}</Text>
            },
            {
                property: 'id',
                primary: true,
                size: "0px",
                plain: true,
                header: '',
                render: s => <Text hidden>{s.id}</Text>
            }
        ];

        let DATA = [];
        //historydata.sort((p1, p2) => { if (p1.servicedBy.toLowerCase() < p2.servicedBy.toLowerCase()) return -1; return 1; });
        if (historydata[0] && historydata[0].count != 0) {
            historydata.forEach((ev, index) => {
                //let p = (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent.toLowerCase() : '';
                //let l = (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location.toLowerCase() : '';
                //let n = (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name.toLowerCase() : '';
                //let lpn = constants.APP_LPN(l,p,n);
                var fulltime = ev.startTime.split("T");
                var time = "";
                var date = "";
                if (fulltime.length == 2) {
                    date = fulltime[0];
                    time = fulltime[1].replace("Z", "");
                }
                var dot = time.indexOf(".");
                dot = dot > 0 ? dot : time.length;
                var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
                statusName = statusName ? statusName.name : "";
                DATA.push({
                    ...ev,
                    name: statusName,
                    start: ev.startTime.replace("T", " ").replace("Z", ""),
                    date: date,
                    time: time.slice(0, dot),
                    note: this.renderNote(statusName),
                    //id: index,
                    //categoryname: (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name : '',
                    //lccategoryname: n,
                    //categoryparent: (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '',
                    //lccategoryparent: p,
                    //categorylocation: (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '',
                    //lccategorylocation: l,
                    //lcparentloc: lpn,
                    //orphaned: ev.category && ev.category.deleted
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.servicedBy.toLowerCase().includes(search) ||
                d.start.toLowerCase().includes(search) ||
                d.date.toLowerCase().includes(search) ||
                d.time.toLowerCase().includes(search) ||
                d.note.toLowerCase().includes(search)
            );
        }

        DATA.sort((p1, p2) => { if (p1.startTime.toLowerCase() > p2.startTime.toLowerCase()) return -1; return 1; });

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property ==="lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={historydata.length != 0}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFsearchable={true}
            />
        );
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    renderNote(status) {
        var note = "";
        switch (status) {
            case "Maintenance":
                note = "Issue resolved";
                break;
            case "Maintenance required":
                note = "Seems to have issues powering on";
                break;
            case "Cleaning required":
                note = "Cleaning as per covid policy";
                break;
            default:
                break;
        }
        return note;
    }
}


export default AssetStatusHistoryListTable;
