import * as ActionType from './ActionType';
import AssetApi from '../api/AssetApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';
import initialState from '../reducer/initialState';


export const getAssetsResponse = assetsdata => ({
    type: ActionType.GET_ASSETS_RESPONSE,
    assetsdata
});
const queuedGetAllAssets = queueApiCall(AssetApi.getAllAssets);
const queuedGetAllMappedAssets = queueApiCall(AssetApi.getAllMappedAssets);

export function getAssetsAction(param = "", notOnlyLinked = false) {
    return (dispatch) => {
        //dispatch(getAssetsResponse(initialState.assetsReducer.assetsdata));
        dispatch(ApiCallBeginAction());


        return queuedGetAllAssets(param, notOnlyLinked)
            .then(assetsdata => {
                dispatch(getAssetsResponse(assetsdata));
                return assetsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export function getMappedAssetsAction(param = "", notOnlyLinked = false, deleteStore = true) {
    return (dispatch) => {
        if (deleteStore) dispatch(getAssetsResponse(initialState.assetsReducer.assetsdata));
        dispatch(ApiCallBeginAction());


        return queuedGetAllMappedAssets(param, notOnlyLinked)
            .then(assetsdata => {
                dispatch(getAssetsResponse(assetsdata));
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewAssetResponse = () => ({
    type: ActionType.ADD_NEW_ASSET_RESPONSE
});

export const updateExistingAssetResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ASSET_RESPONSE
});

export function addAssetAction(assetdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the asset is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new asset that is being added, therefore add it
        return AssetApi.addAsset(assetdraft)
            .then((response) => {
                dispatch(addNewAssetResponse());
                return response;
            }).then((response) => {
                //if (assetdraft.id == "0") {
                //    dispatch(getAssetAction(assetdraft.id));
                //}
                //dispatch(getAssetAction("0"));
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export function saveAssetAction(assetdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the asset is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new asset that is being added, therefore add it
        return AssetApi.saveAsset(assetdraft)
            .then((response) => {
                if (assetdraft.id) {
                    dispatch(updateExistingAssetResponse());
                } else {
                    dispatch(addNewAssetResponse());
                }
                return response;
            }).then((response) => {
                if (assetdraft.id == "0") {
                    dispatch(getAssetAction(assetdraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getAssetResponse = assetdata => ({
    type: ActionType.GET_ASSET_RESPONSE,
    assetdata
});

export function getAssetAction(assetId) {
    return (dispatch) => {
        dispatch(getAssetResponse(initialState.selectedAssetReducer.assetdata));
        dispatch(ApiCallBeginAction());

        return AssetApi.getAsset(assetId)
            .then(assetdata => {
                dispatch(getAssetResponse(assetdata));
                return assetdata;
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteAssetResponse = () => ({
    type: ActionType.DELETE_ASSET_RESPONSE
});

const queuedDeleteAsset = queueApiCall(AssetApi.deleteAsset);

export function deleteAssetAction(assetId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteAsset(assetId)
            .then((response) => {
                dispatch(deleteAssetResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getAssetsAction("", true, false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}