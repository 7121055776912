import { useState, useEffect, useContext } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField } from 'grommet';
import AppInsightsApi from '../api/AppInsightsApi';
import NotificationApi from '../api/NotificationApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
import AnalyticsFilter from './AnalyticsFilter';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import { Context } from './Context';
import { useHistory } from "react-router";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

import AnalyticsCard from './AnalyticsCards';
import AnalyticsCountries from './AnalyticsCountries';
import AnalyticsEvents from './AnalyticsEvents';
import AnalyticsUsers from './AnalyticsUsers';
import AnalyticsNotifications from './AnalyticsNotifications';

const Analytics = () => {

    return (
        <form>
            <Box gap="small" margin={{bottom: "small"}}>
                <AnalyticsCountries />
                <AnalyticsUsers />
                <AnalyticsNotifications />
                <AnalyticsEvents />
            </Box>
        </form>
    );
}

export default Analytics;