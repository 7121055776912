import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const calendarHistoryReducer = (state = initialState.calendarHistoryReducer, action) => {
    switch(action.type) {
        case ActionType.GET_CALENDARHISTORIES_RESPONSE: {
            return {
                ...state, 
                calendarhistoriesdata: _.assign(action.calendarhistoriesdata)
            };
        }
        default: { return state; }
    }
};



export default calendarHistoryReducer;