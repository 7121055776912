import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const assetsHistoryReducer = (state = initialState.assetsHistoryReducer, action) => {
    switch (action.type) {
        case ActionType.GET_ASSETS_HISTORY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                historiesdata: _.assign(action.historiesdata)
            };
        }


        default: { return state; }
    }
};



export default assetsHistoryReducer;