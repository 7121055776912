import * as ActionType from './ActionType';
import GroupApi from '../api/GroupApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getGroupsResponse = groupsdata => ({
    type: ActionType.GET_GROUPS_RESPONSE,
    groupsdata
});

const queuedGetAllGroups = queueApiCall(GroupApi.getAllGroups);

export function getGroupsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllGroups()
            .then(groupsdata => {
                dispatch(getGroupsResponse(groupsdata));
                return groupsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewGroupResponse = () => ({
    type: ActionType.ADD_NEW_GROUP_RESPONSE
});

export const updateExistingGroupResponse = () => ({
    type: ActionType.UPDATE_EXISTING_GROUP_RESPONSE
});

export function saveGroupAction(groupdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return GroupApi.saveGroup(groupdraft)
            .then((response) => {
                if (groupdraft.id) {
                    dispatch(updateExistingGroupResponse());
                } else {
                    dispatch(addNewGroupResponse());
                }
                return response;
            }).then((response) => {
                if (groupdraft.id == "0") {
                    dispatch(getGroupAction(groupdraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getGroupResponse = groupdata => ({
    type: ActionType.GET_GROUP_RESPONSE,
    groupdata
});

export function getGroupAction(groupId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return GroupApi.getGroup(groupId)
            .then(groupdata => {
                dispatch(getGroupResponse(groupdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteGroupResponse = () => ({
    type: ActionType.DELETE_GROUP_RESPONSE
});

const queuedDeleteGroup = queueApiCall(GroupApi.deleteGroup);

export function deleteGroupAction(groupId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteGroup(groupId)
            .then((response) => {
                dispatch(deleteGroupResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getGroupsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}