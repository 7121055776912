import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, StatusGoodSmall, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PrimaryFilter from "./common/PrimaryFilter";
import ReactTooltip from "react-tooltip";

const PaginatedDataTable = withPagination(DataTable);

class OrgCollectionListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }

    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName) {
            if (node.getElementsByTagName('tbody') && node.getElementsByTagName('tbody')[0]) {
                let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
                let handleEdit = this.props.handleEdit;
                [...trs].forEach((elem, idx) => {
                    let insideElements = trs[idx].getElementsByTagName('td');
                    [...insideElements].forEach((element, nro) => {
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;

                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                            //});
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    });
                    let insideElementsTH = trs[idx].getElementsByTagName('th');
                    [...insideElementsTH].forEach((element, nro) => {
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'pointer';
                        });
                    });
                });
            }

        }
    }

    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.lcname.includes(search) ||
                d.created.toLowerCase().includes(search) ||
                d.updated.toLowerCase().includes(search)
            );
        }
        return data;
    }

    renderCollectionsTable(props) {
        const COLUMNS = [
            {
                property: 'hexColor',
                header: 'Color',
                render: collectiondata => < Box round height="23px" background={collectiondata.hexColor} width="23px" border style={{ "border-width": "2px" }} />
            },
            {
                property: 'lcname',
                header: 'Collection Name',
                render: collectiondata => <Text>{collectiondata.name}</Text>
            },
            {
                property: 'created',
                header: 'Created',
                render: collectiondata => <Text>{collectiondata.created}</Text>
            },
            {
                property: 'updated',
                header: 'Updated',
                render: collectiondata => <Text>{collectiondata.updated}</Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: collectiondata => <Text hidden>{collectiondata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                //sortable: false,
                render: collectiondata => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, collectiondata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedCollectionIds.includes(collectiondata.id)} onClick={evt => props.handleEdit(evt, collectiondata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedCollectionIds.includes(collectiondata.id)} onClick={evt => props.handleDelete(evt, collectiondata.id)} icon={<Trash />} />
                    </Box>,
            },
            {
                sortable: false,
                property: 'lcinfo',
                header: "",
                sortable: false,
                plain: true,
                render: collectiondata => collectiondata.error ?
                    <Box align="center" justify="center">
                        <a data-for="PageInfo" style={{ "height": "24px" }}
                            data-tip={collectiondata.message}>
                            <CircleInformation size="medium" color="#F04953" />
                        </a>
                        <ReactTooltip id="PageInfo" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                    </Box> : null
            }
        ];

        let DATA = [];
        let meridianSites = props.sitesdata.filter(l => l.meridianEnabled && l.assetTrackingEnabled && l.meridianOrgId != null && l.appID != null && !l.deleted);
        if (props.collectionsdata[0] && props.collectionsdata[0].count != 0) {
            props.collectionsdata.forEach((ev, id) => {
                let sitesWithLabel = props.sitesdata.filter(s => props.assetlabelsdata.filter(l => l.name == ev.labelName && l.name.endsWith("sys")).map(l => l.locationId).includes(s.id));
                let meridianSitesNoLabel = meridianSites.filter(s => !sitesWithLabel.map(l => l.id).includes(s.id));
                let message = "";
                //Evaluate error message
                if (meridianSitesNoLabel.length == 1) {
                    message = `The corresponding label for this collection is not available in ${meridianSitesNoLabel[0].name}.`;
                }
                if (meridianSitesNoLabel.length > 1) {
                    message = "The corresponding label for this collection is not available for in ";
                    meridianSitesNoLabel.forEach((item, id) => {
                        if (meridianSitesNoLabel.length - 1 == id) {
                            //last element
                            message += `${item.name}.`;
                        }
                        else {
                            message += `${item.name}, `;
                        }
                    });
                }
                if (meridianSitesNoLabel.length == meridianSites.length) {
                    //all
                    message = "The corresponding label for this collection is not available for all locations.";
                }
                DATA.push({
                    ...ev,
                    lcname: ev.name ? ev.name.toLowerCase() : '',
                    created: ev.createdAt ? constants.formatDate(ev.createdAt) : "",
                    updated: ev.updatedAt ? constants.formatDate(ev.updatedAt) : "",
                    error: meridianSitesNoLabel.length > 0 ? true : false,
                    message: message
                });
            });
        }
        DATA = this.filterData(DATA);
        DATA.sort(constants.byPropertyCalled("name"));
        //console.log("collDa",DATA);
        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcparent" || c.property === "lclocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.collectionsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Collection Name' },
                //    { col: 'created', label: 'Created' },
                //    { col: 'updated', label: 'Updated' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                //PFcsvdata={[]}
                //PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Collections </Heading>
                    <Box direction="row" gap="small" >

                        <StyledButton typeSave label='Create New' alignSelf="center" onClick={this.props.handleNew} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    {/*<PrimaryFilter*/}
                    {/*    placeholder="Search by Collection name"*/}
                    {/*    search={this.state.search}*/}
                    {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                    {/*>*/}
                    {/*</PrimaryFilter>*/}
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "770px" }}>
                            {this.renderCollectionsTable(this.props)}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }


}

export default OrgCollectionListTable;
