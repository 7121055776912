import * as ActionType from './ActionType';
import UserPreferencesApi from '../api/UserPreferencesApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getUsersPreferencesResponse = userspreferencesdata => ({
    type: ActionType.GET_USERS_PREFERENCES_RESPONSE,
    userspreferencesdata
});

const queuedGetAllUsers = queueApiCall(UserPreferencesApi.getAllUsersPreferences);

export function getUsersPreferencesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return UserApi.getAllUsers()
        return queuedGetAllUsers()
            .then(userspreferencesdata => {
                dispatch(getUsersPreferencesResponse(userspreferencesdata));
                return userspreferencesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewUserPreferencesResponse = () => ({
    type: ActionType.ADD_NEW_USER_PREFERENCES_RESPONSE
});

export const updateExistingUserPreferencesResponse = () => ({
    type: ActionType.UPDATE_EXISTING_USER_PREFERENCES_RESPONSE
});

export function saveUserPreferencesAction(userdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the user is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new user that is being added, therefore add it
        return UserPreferencesApi.saveUserPreferences(userdraft)
            .then((response) => {
                if (userdraft.id) {
                    dispatch(updateExistingUserPreferencesResponse());
                } else {
                    dispatch(addNewUserPreferencesResponse());
                }
                return response;
            }).then((response) => {
                //if (userdraft.id == "0") {
                //    dispatch(getUserPreferencesAction(userdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getUserPreferencesResponse = userpreferencesdata => ({
    type: ActionType.GET_USER__PREFERENCES_RESPONSE,
    userpreferencesdata
});

export function getUserPreferencesAction(userId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return UserPreferencesApi.getUserPreferences(userId)
            .then(userpreferencesdata => {
                dispatch(getUserPreferencesResponse(userpreferencesdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteUserPreferencesResponse = () => ({
    type: ActionType.DELETE_USER_PREFERENCES_RESPONSE
});

const queuedDeleteUser = queueApiCall(UserPreferencesApi.deleteUserPreferences);

export function deleteUserPreferencesAction(userId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return UserApi.deleteUser(userId)
        return queuedDeleteUser(userId)
            .then((response) => {
                dispatch(deleteUserPreferencesResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getUsersPreferencesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}