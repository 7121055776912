import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
import constants from '../constants';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import RB from './common/RBAC';
import withPagination from './common/PaginatedDataTable';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

const PaginatedDataTable = withPagination(DataTable);
//const CorrectedDataTable = withCorrectedSearch(DataTable);

// var location = [
//     { name: "HPE Galway", test: true, production: false, active: true },
//     { name: "HPE London", test: false, production: true, active: true },
//     { name: "HPE San Jose", test: true, production: false, active: false },];
//const organisation= {id: "4c5b5f821c2045d1bf2b2248b3167cd9", label: "Hewlett Packard Enterprise"};

class AppLocationListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }

    componentDidMount() {
        this.onDTUpdated();
    }

    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            const sitesfull = this.props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId") && RB.canAccessLocation(s.id)).slice();
            sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            let handleEdit = this.props.handleEdit;
            let isMeridianEnabled = this.props.context.isMeridianEnabled;
            let setPlacemarkEnable = this.props.context.setPlacemarkEnable;
            // console.log('<tr> s:', trs);
            [...trs].forEach((elem, idx) => {
                if (elem.getElementsByTagName('th')[0]) {
                    //console.log('Add click item:', elem.getElementsByTagName('th')[0]);
                    let tid = elem.getElementsByTagName('th')[0].textContent;
                    //console.log('Add click item:', tid);
                    //elem.addEventListener("click", function (evt) {
                    //    evt.preventDefault();
                    //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                    //    let selectedsite = sitesfull.find(s => s.id == tid);
                    //    if (selectedsite && selectedsite.meridianEnabled !== isMeridianEnabled) {
                    //        //setPlacemarkEnable(selectedsite.meridianEnabled);
                    //    }
                    //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                    //});
                    elem.removeEventListener("click", this.externalFunc);
                    elem.addEventListener("click", this.externalFunc);
                }
                elem.addEventListener("mouseover", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "#E6E6E6";
                    elem.style.cursor = 'pointer';
                });
                elem.addEventListener("mouseout", function (evt) {
                    evt.preventDefault();
                    elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                });
            });
        }
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [1, 1] }
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' > Sites </Heading>
                    <Box direction="row" gap="small" >
                        {/*<StyledButton typeCancellabel="Cancel" />
                        <StyledButton typeSave label="Save" />*/}
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <div ref={this.dtwrapper}>
                            <StyledBox>
                                {this.renderSitesTable(this.props)}
                            </StyledBox>
                        </div>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    ArrayToString(arr) {
        var string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.name;
            }
            else {
                string += item.name;
                string += ", ";
            }

        })
        return string;
    }
    renderSitesTable(props) {
        const COLUMNS = [
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: locations => <Box direction='row'><Button plain onClick={evt => props.handleEdit(evt, locations.id)} icon={<Edit />} /></Box>,
            //},
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: locations =>
            //    <Box direction='row'>
            //        <Button plain disabled={props.deletedSiteID === locations.id} onClick={evt => props.handleDelete(evt, locations.id)} icon={<Trash />} />
            //        <Text hidden>{locations.id}</Text>
            //    </Box>
            //},
            {
                property: 'lcname',
                header: 'Site Name',
                render: locations => <Box pad={{ vertical: "small" }}>{locations.name}</Box>
            },
            //{
            //    property: 'orgname',
            //    header: 'Organization Name',
            //    render: locations => <Text>{locations.orgname}</Text>
            //},
            {
                property: 'test',
                header: <Box justify="center" height="100%">Test</Box>,
                render: (locations) => <CheckBox checked={locations.test} disabled={true} />
                //onChange={() => {
                // location.forEach((loca, i) => {
                //     if (location[i].name == locations.name) {
                //         location[i].test = !location[i].test;
                //     }
                // });
                // console.log(location);
                //}} />
            },
            {
                property: 'production',
                header: <Box justify="center" height="100%">Production</Box>,
                render: locations => <CheckBox checked={locations.production} disabled={true} />
            },
            {
                property: 'active',
                header: <Box justify="center" height="100%">Active</Box>,
                render: locations => <CheckBox checked={locations.active} disabled={true} />
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: locations => <Text hidden>{locations.id}</Text>,
            },
        ];

        let DATA = [];
        //console.log('SITES:', props.sitesdata);
        if (props.sitesdata[0] && props.sitesdata[0].count != 0) {
            const sitesfull = props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId") && s.deleted == false).slice();
            sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            sitesfull.forEach(ev => {
                //    location.forEach(ev => {
                let orgname = (ev.organization && ev.organization.name) || '';
                if (RB.canAccessLocation(ev.id)) {
                    DATA.push({
                        ...ev,
                        lcname: ev.name.toLowerCase(),
                        //orgname: constants.organisation.label,
                        orgname: orgname,
                        //test: ev.test,
                        //production: ev.production,
                        //lactive: ev.active.toString()
                    })
                }

            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                ("test".includes(search) && d.test) ||
                ("production".includes(search) && d.production) ||
                ("active".includes(search) && d.active)
            );
        }

        console.log('DATA:', DATA);

        //if (DATA.length == 0 && props.sitesdata[0] && props.sitesdata[0].count != 0) {
        //    return (
        //        <LoadingIndicator />
        //    );
        //}

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "orgname"
                }))}
                data={DATA}
                noLoadingMsg={this.props.sitesdata.length != 0}
                //sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Site Name' },
                //    { col: 'orgname', label: 'Organization Name' },
                //    { col: 'test', label: 'Test' },
                //    { col: 'production', label: 'Production' },
                //    { col: 'active', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                customWarning={<Box align="center">
                    <Text>{"There are no sites configured for this Organization"}</Text>
                    <Text>{"Please check back again soon"}</Text>
                </Box>}
            />
        );
    }
}

export default AppLocationListTable;
