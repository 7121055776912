import { useState, useEffect, useContext, createRef } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField, Collapsible } from 'grommet';
import { Edit, CircleInformation, Next, FormNext, FormPrevious, Download } from 'grommet-icons';
import AppInsightsApi from '../api/AppInsightsApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import ReactTooltip from "react-tooltip";
import { Bar, Pie, Line } from 'react-chartjs-2';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';
const PaginatedDataTable = withPagination(DataTable);
import SingleChart from './SingleChart';
import Card from './common/Card';


const AnalyticsEventsList = () => {
    const dtwrapper = createRef();
    const [eventTableExtended, setEventTableExtended] = useState([]);
    const [eventTableExtendedLoading, setEventTableExtendedLoading] = useState(true);

    const [eventData, setEventData] = useState({});
    const [eventDataLoading, setEventDataLoading] = useState(true);

    //const [eventsCount, setEventsCount] = useState();
    //const [eventsAVG, setEventsAVG] = useState();
    //const [unhandledErrorsCount, setUnhandledErrorsCount] = useState();

    const [search, setSearch] = useState("");

    const { time, site, targetOS, version, count, axesColor } = useContext(AnalyticsContext);
    console.log("AnalyticsContext", site, targetOS, version, count);

    const getTimespan=()=> {

        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    const cmp = (a, b) => {
        const alc = a.value;
        const blc = b.value;
        if (alc < blc) {
            return 1;
        }
        else if (alc > blc) {
            return -1;
        }
        else {
            return 0;
        }
    }

    const isNumber = (value) => {
        return typeof value === 'number' && isFinite(value);
    }

    const externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            //let hE = this.props.handleEdit;
            //hE(evt, id);
            //console.log("handleEdit", id, element.cells);
            Array.from(element.cells).forEach(cell => console.log(cell.textContent));
            let name, type, loc;
            name = element.cells[0].textContent;
            type = element.cells[1].textContent;
            loc = element.cells[2].textContent;

            //Api Call
            AppInsightsApi.getIndividualEvent(getTimespan(), version, targetOS, name, type, loc, site, sessionStorage.getItem("organisationCode"))
                .then(data => {
                    setEventData({ data, name,type, loc });
                    setEventDataLoading(false);
                })
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    //console.log('*DEBUG*- getIndividualEvent Error', error);
                });
        }
    }

    const onDTUpdated=()=> {
        let node = dtwrapper.current;
        if (node && node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            // console.log('<tr> s:', trs);
            [...trs].forEach((elem, idx) => {
                console.log('Add click item:', elem, idx);
                if (elem.getElementsByTagName('th')[0]) {
                    let tid = elem.getElementsByTagName('th')[0].textContent;
                    console.log('Add click item:', tid);
                    //elem.addEventListener("click", function (evt) {
                    //    evt.preventDefault();
                    //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                    //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                    //});
                    elem.removeEventListener("click", externalFunc);
                    elem.addEventListener("click", externalFunc);
                }
                elem.addEventListener("mouseover", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "#E6E6E6";
                    elem.style.cursor = 'pointer';
                });
                elem.addEventListener("mouseout", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "white";
                });
            });
        }
    }

    //useEffect(() => {
    //    onDTUpdated();
    //}, [])
    useEffect(() => {
        onDTUpdated();
    })

    useEffect(() => {
        let isSuperUser = RB.isSuperUser();
        //AppInsightsApi.getEventsCount(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), isSuperUser)
        //    .then(data => {
        //        if (data.tables[0].rows[0])
        //            setEventsCount(data.tables[0].rows[0][0]);
        //    });
        //AppInsightsApi.getAvarageEventsPerUser(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), isSuperUser)
        //    .then(data => {
        //        if (data.tables[0].rows[0])
        //            setEventsAVG(isNumber(data.tables[0].rows[0][0]) ? Number.parseFloat(data.tables[0].rows[0][0]).toFixed(2) : "N/A");
        //    });
        //AppInsightsApi.getErrorsBySpecificName(getTimespan(), version, targetOS, "UnhandledErrorLog")
        //    .then(data => {
        //        if (data.tables[0].rows[0])
        //            setUnhandledErrorsCount(data.tables[0].rows[0][0]);
        //    });
        AppInsightsApi.getEventsTableExtended(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), RB.isSuperUser())
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let nameIdx, typeIdx, locIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "event") {
                        nameIdx = 0;
                        typeIdx = 1;
                        locIdx = 2;
                        valueIdx = 3;
                    }
                    else {
                        nameIdx = 3;
                        typeIdx = 2;
                        locIdx = 1;
                        valueIdx = 0;
                    }

                    setEventTableExtended(data.tables[0].rows.map((row, i) => {
                        return {
                            id:  i + 1,
                            name: row[nameIdx],
                            type: row[typeIdx],
                            loc: row[locIdx],
                            value: row[valueIdx],
                        }
                    })
                        .filter(d => d.name.trim() != "")
                        .sort(cmp));
                    setEventTableExtendedLoading(false);
                }
            })
            .catch(error => {
                //var errorArray = this.state.apiErrorList.concat(error);
                //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getEventsTable Error', error);
            });

        if (!eventDataLoading) {
            AppInsightsApi.getIndividualEvent(getTimespan(), version, targetOS, eventData.name, eventData.type, eventData.loc, site, sessionStorage.getItem("organisationCode"))
                .then(data => {
                    setEventData(previousState => {
                        return { ...previousState, data  }
                    });
                    setEventDataLoading(false);
                })
                .catch(error => {
                    //var errorArray = this.state.apiErrorList.concat(error);
                    //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*- getIndividualEvent Error', error);
                });
        }

        //onDTUpdated();
    }, [count])

    const axisMaxValue=(maxValue)=> {
        if (maxValue <= 5) { return 5 }
        else if (maxValue <= 10) { return 10 }
        else if (maxValue <= 200) { return Math.ceil(maxValue / 10) * 10 }
        else if (maxValue <= 2000) { return Math.ceil(maxValue / 100) * 100 }
        else if (maxValue <= 20000) { return Math.ceil(maxValue / 1000) * 1000 }
        else { return Math.ceil(maxValue / 10000) * 10000 }
    }
    const dataMaxValue=(data)=> {
        if (data) {
            if (data.length > 1) {
                const max = data.reduce((prev, current) => (prev.value > current.value) ? prev : current)
                return max.value
            }
            else {
                return 0
            }
        }
    }
    const formatLabelName=(name)=> {
        if (name.startsWith("https://")) {
            name = name.slice(8, name.length)
        }
        if (name.startsWith("http://")) {
            name = name.slice(7, name.length)
        }
        if (name.startsWith("www.")) {
            name = name.slice(4, name.length)
        }
        if (name.length > 15) {
            name = name.slice(0, 15) + "..."
        }

        return name
    }

    const filteredData = () => {
        if (search && !eventTableExtendedLoading) {
            var s = search.toLowerCase();
            return eventTableExtended.filter(d =>
                d.name.toLowerCase().includes(s) ||
                d.type.toLowerCase().includes(s) ||
                d.loc.toLowerCase().includes(s) //||
                //d.value.toString().toLowerCase().includes(search)
            );
        }
        return eventTableExtended;
    }

    let DATA = filteredData();

    return (
        <Box>
            {eventDataLoading && <StyledBox style1>
                <Box justify="between"
                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                    //margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}

                    round="xsmall"
                    //basis="75%"
                >
                    <Box direction="column-responsive" justify="between" margin={{ "bottom": "none" }}>
                        <Heading level='3' margin={{ "top": "xsmall", "left": "none", "right": "none" }}>
                            <Box direction="column-responsive" justify="between" gap="small">
                                Events
                                <Box justify="center" height="25px" alignSelf="center">
                                    <a data-for="Top features" data-tip="All events used in the app by it's active users">
                                        <CircleInformation size="medium" color="#444444" />
                                    </a>
                                    <ReactTooltip id="Top features" place="right" type="dark" effect="solid" arrowColor="transparent" multiline />
                                </Box>
                            </Box>
                        </Heading>
                    </Box>
                    <Box>
                        {eventTableExtendedLoading ? <LoadingIndicator plain /*error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}*/ /> :
                            <Box >
                                <div ref={dtwrapper}>
                                    {eventDataLoading && <PaginatedDataTable
                                        bg={constants.BOX_BACKGROUND_COLOR_1}
                                        columns={[
                                            {
                                                property: 'name',
                                                header: 'Name',
                                                //primary: true,
                                                size: "",
                                                render: eventdata => <Box><Text>{eventdata.name}</Text></Box>
                                            },
                                            {
                                                property: 'type',
                                                header: 'Event Type',
                                                primary: false,
                                                size: "300px",
                                                render: eventdata => <Box><Text truncate>{eventdata.type}</Text></Box>
                                            },
                                            {
                                                property: 'loc',
                                                header: 'Location',
                                                size: "",
                                                render: eventdata => <Box><Text>{eventdata.loc}</Text></Box>
                                            },
                                            {
                                                property: 'value',
                                                header: 'Count',
                                                align: 'end',
                                                size: "70px",
                                                render: eventdata => <Box><Text>{eventdata.value}</Text></Box>
                                            },
                                            {
                                                property: 'id',
                                                header: '',
                                                primary: true,
                                                size: "0px",
                                                plain: true,
                                                render: s => <Text hidden>{s.id}</Text>
                                            },
                                        ]}
                                        data={DATA}
                                        noLoadingMsg={eventTableExtended && eventTableExtended.length != 0}
                                        sortable
                                        resizeable={false}
                                        displayrows={9/*constants.PAGE_SIZE*/}
                                        onUpdated={() => onDTUpdated()}
                                        styled

                                        PFplaceholder="Search"
                                        PFsearch={search}
                                        PFonChange={e => setSearch(e.target.value)}
                                        PFonClear={e => setSearch("")}

                                        PFcsvdata={[]}

                                        PFsearchable={true}
                                        PFfilterable={false}
                                        PFdownloadable={0}
                                    />}
                                </div>
                            </Box>}
                    </Box>
                </Box>
            </StyledBox>}

             <Box
                justify="between"
                //margin={{ "top": "small", "horizontal": "small", "bottom": "medium" }}
                margin={{ "top": "none", "bottom": "small" }}
            >
                <Box
                    height="100%"
                    width="100%"
                    //justify="between"
                    //pad={{ "top": "small" }}
                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                    {!eventDataLoading && <SingleChart back={() => setEventDataLoading(true)} item={eventData} location={site == "" ? "All" : site} timeSpan={getTimespan()} data={eventData.data} singleFeatureDataLoading={eventDataLoading} />}
                </Box>
            </Box>
        </Box>
    );
}

export default AnalyticsEventsList;