import * as ActionType from './ActionType';
import RoleApi from '../api/RoleApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getRolesResponse = rolesdata => ({
    type: ActionType.GET_ROLES_RESPONSE,
    rolesdata
});

export function getRolesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return RoleApi.getAllRoles()
            .then(rolesdata => {
                dispatch(getRolesResponse(rolesdata));
                return rolesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewRoleResponse = () => ({
    type: ActionType.ADD_NEW_ROLE_RESPONSE
});

export const updateExistingRoleResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ROLE_RESPONSE
});

export function saveRoleAction(roledraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the role is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new role that is being added, therefore add it
        return RoleApi.saveRole(roledraft)
            .then(() => {
                if (roledraft.id) {
                    dispatch(updateExistingRoleResponse());
                } else {
                    dispatch(addNewRoleResponse());
                }
            }).then(() => {
                dispatch(getRoleAction(roledraft.id));
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getRoleResponse = roledata => ({
    type: ActionType.GET_ROLE_RESPONSE,
    roledata
});

export function getRoleAction(roleId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return RoleApi.getRole(roleId)
            .then(roledata => {
                dispatch(getRoleResponse(roledata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteRoleResponse = () => ({
    type: ActionType.DELETE_ROLE_RESPONSE
});

export function deleteRoleAction(roleId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return RoleApi.deleteRole(roleId)
            .then((response) => {
                dispatch(deleteRoleResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getRolesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}