import * as ActionType from './ActionType';
import MergedBuildingApi from '../api/MergedBuildingApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';

export const getBuildingsResponse = mergedbuildingsdata => ({
    type: ActionType.GET_MERGEDBUILDINGS_RESPONSE,
    mergedbuildingsdata
});

export function getMergedBuildingsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MergedBuildingApi.getAllBuildings()
            .then(mergedbuildingsdata => {
                dispatch(getBuildingsResponse(mergedbuildingsdata));
                return mergedbuildingsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const getBuildingResponse = mergedbuildingdata => ({
    type: ActionType.GET_MERGEDBUILDING_RESPONSE,
    mergedbuildingdata
});

export function getMergedBuildingAction(id) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MergedBuildingApi.getBuilding(id)
            .then(mergedbuildingdata => {
                dispatch(getBuildingResponse(mergedbuildingdata));
            }).catch(error => {
                throw error;
            });
    };
}
export const updateExistingMergedBuildingResponse = () => ({
    type: ActionType.UPDATE_EXISTING_MERGEDBUILDING_RESPONSE
});

export function saveMergedBuildingAction(mergedbuilding) {
    return function (dispatch) {
        dispatch(ApiCallBeginAction());
        return MergedBuildingApi.saveMergedBuilding(mergedbuilding)
            .then((response) => {
                if (mergedbuilding.id) {
                    dispatch(updateExistingMergedBuildingResponse());
                } else {
                    //dispatch(addNewPageResponse());
                }
                return response;
            }).then((response) => {
                //if (mergedbuilding.id == "0") {
                //    dispatch(getMergedBuildingAction(mergedbuilding.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}