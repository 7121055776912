import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import * as BuildingAction from '../action/BuildingAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import * as PolicyAction from '../action/PolicyAction';
import * as GroupAction from '../action/GroupAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash, Add, Alert, Key, FormClose, System } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Tabs, Tab, Layer, DataTable, TextArea, Stack } from 'grommet';
import { error } from 'util';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';
import ReactTooltip from "react-tooltip";
import AccessControl from './common/AccessControl';
import FilterSidePanel from './common/FilterSidePanel';

const PaginatedDataTable = withPagination(DataTable);

var start = undefined;

export class LocGroupEditContainer extends React.Component {
    constructor(props) {
        super();
        this.dtwrapper = React.createRef();
        this.allRoles = [];
        this.allRolesSites = [];
        this.selectedTypes = [];
        this.state = {
            isSaved: false,
            disabledButton: false,
            open: false,
            openManageRoles: false,
            openManageType: "",
            manageRoleType: "",
            manageRoleSites: [],
            manageRoleRoles: [],
            cancel: false,
            isSaveSuccessful: false,
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],

            //policies: constants.GROUPS.find(g => g.id == props.match.params.groupId).policies,
            group: constants.GROUPS.find(g => g.id == /*props.match.params.groupId*/ 0),
            usersdataLoading: true,
            buildingsdataLoading: true,
            assetlabelsdataLoading: true,
            departmentsdataLoading: true,
            collectionsdataLoading: true,
            policiesdataLoading: true,
            groupdataLoading: true,

            selectedUsers: [],
            deleteUserId: [],

            selectedRoles: [],
            filteredRoles: [],//constants.ROLES.filter(r => r.id != 0),

            isRoleSidePanelOpen: false,
            selectedRolesCopy: [],
            roleSearch: "",

            isUserSidaPanelOpen: false,
            selectedUsersCopy: [],
            userSearch: "",
        };
    }

    //Validating errors
    /*group_err = '';*/
    email_err = '';
    username_err = '';
    showActiveMsg = false;

    name_err = '';
    description_err = '';

    ClearErrorMessages() {
        /*this.group_err = '';*/
        this.email_err = '';
        this.username_err = '';
        this.name_err = '';
        this.description_err = '';
        //this.entryerrors.map((item) => {
        //    item.locationId = "";
        //    item.role = "";
        //})
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const id = this.props.match.params.userId;
        //this.props.action.getUserAction("bence.koppany@hpe.com")
        //    .then(response => this.setState({ userdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log("*DEBUG*-UserEdit Error", error);
        //    });
        this.props.action.getUsersAction()
            .then(response => this.setState({ usersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
        //this.props.action.getRolesAction()
        //    .then(response => this.setState({ rolesdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log("*DEBUG*-Role Error", error);
        //    });
        //this.props.action.getSitesAction()
        //    .then(response => this.setState({ sitesdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Sites List Error', error);
        //    });
        //this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
        //    .then(response => this.setState({ organisationdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
        //        console.log('*DEBUG*-Organisation Error', error);
        //    });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getPoliciesAction()
            .then(response => this.setState({ policiesdataLoading: false, filteredRoles: response.hasOwnProperty("status") ? [] : response }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
        let id = this.props.match.params.groupId;
        this.props.action.getGroupAction(id)
            .then(response => this.setState({ groupdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && /*false*/this.props.groupdata && !_.isEqual(start, this.props.groupdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        //const userdefault = this.props.userdata;
        //if (userdefault.id == "0") {
        //    userdefault.active = true;
        //}

        //const { selected, locationroles } = this.state;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (this.props.groupdata.name.trim().length == 0) {
            errorsno++;
            this.name_err = "Name is required";
        }
        if (this.props.groupdata.name.length > 50) {
            errorsno++;
            this.name_err = "Name can not be longer than 50 cahracter";
        }
        if (this.props.groupdata.description.length > 200) {
            errorsno++;
            this.description_err = "Description can not be longer than 200 cahracter";
        }
        //if (userdefault.email.trim().length == 0) {
        //    errorsno++;
        //    this.email_err = "E-mail is required"
        //}
        //if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
        //    errorsno++;
        //    this.email_err = "E-mail is not correct";
        //}
        if (errorsno == 0) {
            this.setState({ disabledButton: true })
            //this.saveRoles();
            //console.log('Save: OK', userdefault, this.state.group);
            //if (this.props.match.params.groupId != "0")
            //    constants.SET_GROUP(this.state.group);
            //else 
            //    constants.CREATE_GROUP(this.state.group);
            this.props.action.saveGroupAction(this.props.groupdata)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false })
                });
            //this.setState({ isSaved: true, isSaveSuccessful: true });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        //this.saveRoles();
        if (!this.state.cancel && /*false*/ !_.isEqual(start, this.props.groupdata)) {
            this.setState({ cancel: true });
        }
        else {
            //const userId = this.props.userdata.id;
            //this.props.action.getUserAction(userId)
            //    .then(() => {
            //        if (this.nextLoc) {
            //            this.props.history.push(this.nextLoc);
            //        } else {
            //            //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            //            this.props.history.push({
            //                pathname: constants.getOrgLevelRoute() + '/users',
            //                state: { index: 2 }
            //            });
            //        }
            //    }).catch(error => {
            //        console.log('*DEBUG*-UserEdit Error', error);
            //        //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            //        this.props.history.push({
            //            pathname: constants.getOrgLevelRoute() + '/users',
            //            state: { index: 2 }
            //        });
            //    });
            //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                //this.props.history.push(constants.getOrgLevelRoute() + '/users');
                this.props.history.push({
                    pathname: constants.getSiteLevelRoute() + '/locusers',
                    state: { index: 2 }
                });
            }
        }
    }

    onClose() {
        this.setState({ disabledButton: false, openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }

    onCloseSaving() {
        this.setState({ isSaved: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
           //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            this.props.history.push({
                pathname: constants.getSiteLevelRoute() + '/locusers',
                state: { index: 2 }
            });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    getOptionsByType = (type) => {
        const { sitesdata, buildingsdata, assetlabelsdata, departmentsdata, collectionsdata } = this.props;
        switch (type) {
            case "Site":
                return sitesdata.sort(constants.byPropertyCalled());
            case "Building":
                return buildingsdata;
            case "Collection":
                return collectionsdata;
            case "Department":
                return departmentsdata;
            case "Label":
                return assetlabelsdata;
            case "Floor":
                return [];
            default:
                return [];
        }
    }

    renderUsersTable(props, isOriginal = true) {
        const COLUMNS = [
            {
                property: 'lcusername',
                header: 'Username',
                render: usersdata => <Text>{usersdata.username}</Text>
            },
            {
                property: 'lcemail',
                header: 'Email',
                render: usersdata => <Text>{usersdata.email}</Text>
            },
            {
                property: 'orgadmin',
                header: <Box justify="center" height="100%">Org Admin</Box>,
                size: "75px",
                render: usersdata => <CheckBox readOnly checked={usersdata.orgadmin} disabled />
            },
            {
                property: 'lcactive',
                header: <Box justify="center" height="100%">Active</Box>,
                size: "75px",
                render: usersdata => <CheckBox readOnly checked={usersdata.lcactive} disabled />
            },
            //{
            //    property: 'idtag',
            //    header: '',
            //    primary: true,
            //    size: "0px",
            //    plain: true,
            //    render: usersdata => <Text hidden>{usersdata.id}</Text>,
            //},
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: usersdata =>
                    <Box width="24px" direction='row' gap="xsmall">
                        <Button plain /*disabled={props.deletedUserIds.includes(usersdata.id)} onClick={evt => props.handleDelete(evt, usersdata.id)}*/ icon={<Trash />}
                            onClick={() => this.setState(prevState => ({
                            openUser: true,
                            deleteUserId: [usersdata.email]
                        }))} />
                    </Box>
            },
        ];

        let DATA = [];
        const locationid = sessionStorage.getItem("locationId");

        function isOrgAdmin(user) {
            var orgAdmin = false;
            if (!user.userRoles || user.userRoles.length == 0) {
                return orgAdmin;
            }
            user.userRoles.forEach(r => {
                if (r.role.name == "Organisation Admin") {
                    orgAdmin = true;
                }
            })
            return orgAdmin;
        }

        function isActive(user) {
            var active = false;
            if (!user.userRoles || user.userRoles.length == 0) {
                return active;
            }
            if (user.userRoles.filter(r => r.active).length != 0) {
                active = true;
            }
            return active;
        }

        if (this.props.usersdata && this.props.usersdata[0] && this.props.usersdata[0].count != 0) {
            //let users = isOriginal ? constants.GROUPS.find(g => g.id == props.match.params.groupId).users.map(u=> u.name) :  this.state.group.users.map(u=> u.name);
            let users = props.groupdata.userGroups.map(u=> u.userId);
            this.props.usersdata.forEach(ev => {
                const roles = ev.userRoles.filter(r => r.locationId == locationid).slice();
                if (users && users.includes(ev.email) && true) {
                    DATA.push({
                        ...ev, lcusername: ev.username.toLowerCase(),
                        lcemail: ev.email.toLowerCase(),
                        //role: ev.roles.map(role => role.name).toString(),
                        //role: this.ArrayToString(ev.userRoles),
                        //role: this.ArrayToString(roles),
                        //lcrole: ev.roles.map(role => role.name).toString().toLowerCase(),
                        //lcrole: this.ArrayToString(ev.userRoles).toLowerCase(),
                        //lcrole: this.ArrayToString(roles).toLowerCase(),
                        lcactive: isActive(ev),
                        orgadmin: isOrgAdmin(ev)
                    })
                }
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.username.toLowerCase().includes(search) ||
                d.email.toLowerCase().includes(search) ||
                ("active".includes(search) && d.lcactive) ||
                ("org admin".includes(search) && d.orgadmin)
            );
        }
        this.count = DATA.length;
        console.log("dago", DATA, this.state.group.users);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={/*this.props.usersdata*/props.groupdata.userGroups && props.groupdata.userGroups.length != 0 && notFilteredData.length != 0}
                sortable
                select={this.state.selectedUsers}
                onSelect={ev => { console.log(ev); this.setState({ selectedUsers: ev }) }}
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //onUpdated={this.onDTUpdated.bind(this)}
                styled
                line1="No members added to this group yet."
                line2={<div>Please select the <b>Add User(s)</b> button to add users to this group.</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={isOriginal ? false : true}
                PFfilterable={false}
                PFdownloadable={0}
                extraComponent={
                    <Box data-for={"addUserbtn"} data-tip={"Select to add a user to this group"} /*style={{ "height": "24px" }}*/>
                        <StyledButton
                            typeSave
                            label="Add User(s)"
                            alignSelf="end"
                            onClick={() => {
                                //console.log("dir", this.props.history);
                                //this.props.history.push({
                                //    pathname: this.props.history.location.pathname + '/users/0',
                                //    //state: { index: 2 }
                                //});
                                this.setState({ isUserSidaPanelOpen: true, selectedUsersCopy: /*this.state.group.users*/ this.props.groupdata.userGroups  });
                            }}
                        />
                        <ReactTooltip id={"addUserbtn"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                }
            />
        );
    }

    renderControlTable(props) {
        const COLUMNS = [
            {
                property: 'lcsite',
                header: 'Site',
                render: content => <Text>{content.site}</Text>
            },
            {
                property: 'lctype',
                header: 'Type',
                render: content => <Text>{content.type}</Text>
            },
            {
                property: 'lcname',
                header: 'Name',
                render: content => <Text>{content.name}</Text>
            },
            {
                property: 'lcauth',
                header: 'Auth',
                render: content => <Text>{content.auth}</Text>
            },
        ];

        let DATA = [];

        if (props.content) {
            props.content.forEach(ev => {
                if (true) {
                    DATA.push({
                        ...ev,
                        lcsite: ev.site.toLowerCase(),
                        lctype: ev.type.toLowerCase(),
                        lcname: ev.name.toLowerCase(),
                        lcauth: ev.auth.toLowerCase()
                    })
                }
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.lcsite.includes(search) ||
                d.lctype.includes(search) ||
                d.lcname.includes(search) ||
                d.lcauth.includes(search)
            );
        }
        this.count = DATA.length;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.usersdata.length != 0 && notFilteredData.length != 0}
                sortable
                //select={this.state.selectedUsers}
                //onSelect={ev => { console.log(ev); this.setState({ selectedUsers: ev }) }}
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //onUpdated={this.onDTUpdated.bind(this)}
                styled
                //line1="No members added to this group yet."
                //line2={<div>Please select the <b>Add User(s)</b> button to add users to this group.</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    permEntry = (policies) => {
        let emptyPolicy = {
            id: policies.length,
            name: "",
            access: "",
            type: ""
        };
        this.selectedTypes = [];
        //const selectedTypes = [...new Set(policies.map(item => item.type))];
        const groups = policies.reduce((groups, item) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), item]
        }), {});
        console.log("gg", groups, policies, Object.keys(groups));
        const types = ["Site", "Building", "Collection", "Department", "Label", "Floor", ""];
        return (
            <Box gap="small">
                {policies.length == 0 &&
                    <StyledBox style1 pad="large" gap="small" direction="column" align="center">
                        <Alert color="#DADADA" size="100px" />
                        <Box align="center">
                            <Text>No permissions assigned to this group yet.</Text>
                            <Text>Please select the <b>Add New Type</b> button to assign permissions to this group</Text>
                        </Box>
                    </StyledBox>
                }
                {policies.length != 0 && /*types*/Object.keys(groups).map((p, idx) => {
                    if (groups[p]) {
                        this.selectedTypes.push(types.indexOf(p));
                                                return (
                            <StyledBox style1 pad={{ "horizontal": "small", bottom: "small" }} gap="small">
                                <Box direction="row" /*gap="small"*/>
                                    <Box /*width="260px"*/>
                                        {false && <FormField label="Type *" error={this.email_err}>
                                            <Select
                                                placeholder="Select Type"
                                                options={["Site", "Building", "Collection", "Department", "Label", "Floor"]}
                                                //disabled={selectedTypes}
                                                value={groups[p][0].type}
                                                onChange={(evt) => {
                                                    groups[evt.target.value] = groups[p];
                                                    groups[p].forEach((item, i) => {
                                                        let policy = policies.find(po => po.id == item.id);
                                                        policy.type = evt.target.value;
                                                    });
                                                    //let policy = policies.find(po => po.id == groups[p][0].id);
                                                    //policy.type = evt.target.value;
                                                    delete groups[p];
                                                    console.log(groups, this.state.group);
                                                    this.setState(prevState => ({
                                                        group: {
                                                            ...prevState.group,
                                                            policies: policies,
                                                        }
                                                    }
                                                    ))
                                                }}
                                            />
                                                                </FormField>}
                                                                <FormField label="Type" error={this.email_err}>
                                                                    <TextInput value={groups[p][0].type} readOnly/>
                                                                </FormField>

                                    </Box>
                                    <Box gap="small" /*direction="row"*/ width="100%">
                                        {groups[p].map((e, i) =>
                                            <Box direction="row" justify="between">
                                            <Grid pad={{ "horizontal": "small" }} /*direction="row"*/ gap="small" columns={{count: 4, size: "auto"}}>
                                                <Box /*width="260px"*/>
                                                    <FormField label={i == 0 ? "Roles *" : ""} error={this.email_err} margin="unset">
                                                        {/*<StyledBox style1>
                                                        <Box pad="small">*/}
                                                        <Select
                                                                disabled={e.type == "Site" ? [1]: []}
                                                                placeholder="Select Permission"
                                                                options={["Admin", /*"Editor",*/ /*"Mobil Client"*/, "Read", "User"].concat(constants.ROLES.filter(r => r.isDefault == false && r.id != 0).map(n=> n.name))}
                                                            value={e.access}
                                                            onChange={(evt) => {
                                                                this.setState(prevState => ({
                                                                    group: {
                                                                        ...prevState.group,
                                                                        //policies: {
                                                                        //    ...prevState.group.policies,
                                                                        //    //[prevState.group.policies[index].access]: e.target.value,
                                                                        //},
                                                                        policies: prevState.group.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { access: evt.target.value}) : obj))
                                                                    }
                                                                }
                                                                ))
                                                            }}
                                                        />
                                                        {/*</Box>
                                                    </StyledBox>*/}
                                                    </FormField>
                                                </Box>
                                                {e.type != "Site" && <Box /*width="260px"*/ /*style={{ visibility: e.type !="Site" ? "visible" : "hidden"}}*/>
                                                    <FormField label={i == 0 ? "Site *" : ""} error={this.email_err} margin="unset">
                                                        <Select
                                                            placeholder={"Select Site"}
                                                            options={e.type == "Floor" ? this.getOptionsByType("Site") : [{ id: 0, name: "All" }].concat(this.getOptionsByType("Site"))}
                                                            //value={e.name}
                                                            labelKey="name"
                                                        //disabled={!e.type}
                                                        />
                                                    </FormField>
                                                </Box>}
                                                {e.type == "Floor" && <Box /*width="260px"*/ /*style={{ visibility: e.type == "Floor" ? "visible" : "hidden" }}*/>
                                                    <FormField label={i == 0 ? "Building *" : ""} error={this.email_err} margin="unset">
                                                        <Select
                                                            placeholder={"Select Building"}
                                                            options={this.getOptionsByType("Building")}
                                                            //value={e.name}
                                                            labelKey="name"
                                                        //disabled={!e.type}
                                                        />
                                                    </FormField>
                                                </Box>}
                                                <Box /*width="260px"*/>
                                                    <FormField label={i == 0 ? "Name *" : ""} error={this.email_err} margin="unset">
                                                        <Select
                                                            placeholder={"Select " + e.type}
                                                            options={this.getOptionsByType(e.type)/*.map(t=> t.name)*/}
                                                            defaultValue={e}
                                                            labelKey="name"
                                                            valueKey="name"
                                                                //value={e.name}
                                                                disabled={!e.type}
                                                                onChange={(evt) => {
                                                                    console.log("drill", evt, e);
                                                                    this.setState(prevState => ({
                                                                        group: {
                                                                            ...prevState.group,
                                                                            policies: prevState.group.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { name: evt.value.name }) : obj))
                                                                        }
                                                                    }
                                                                    ), () => console.log("drill1", this.state.group))
                                                                }}
                                                            />
                                                    </FormField>
                                                </Box>
                                                </Grid>
                                                <Box width="31px" align="center" alignContent="center" alignSelf="center" margin={i == 0 ? { top: "32px" } : "unset"}>
                                                    <Button icon={<Trash />}
                                                        onClick={() => this.setState(prevState => ({
                                                            group: {
                                                                ...prevState.group,
                                                                policies: prevState.group.policies.filter(po => po.id != e.id)
                                                            }
                                                        }))}
                                                    />
                                                </Box>

                                            </Box>
                                        )}

                                    </Box>
                                </Box>
                                <Box direction="row" gap="small">
                                    <StyledButton
                                        disabled={groups[p][0].type == ""}
                                        typeAdd
                                        label={"Add "/* + groups[p][0].type*/}
                                        alignSelf="start"
                                        onClick={() => this.setState(prevState => ({
                                            group: {
                                                ...prevState.group,
                                                policies: [...prevState.group.policies, {
                                                    id: prevState.group.policies.length,
                                                    name: "",
                                                    access: "",
                                                    type: groups[p][0].type
                                                }]
                                            }
                                        })
                                        )} />
                                    <StyledButton typeCancel label="Delete all"
                                        onClick={() => this.setState(prevState => ({
                                            group: {
                                                ...prevState.group,
                                                policies: prevState.group.policies.filter(po => po.type != groups[p][0].type)
                                            }
                                        }))} />
                                </Box>
                            </StyledBox>
                        );
                    }
                })
                }
            </Box>
        );
    }

    renderNewRolesTable() {
        const COLUMNS = [
            {
                property: 'lcrole',
                header: 'Role',
                render: roles => <Text>{roles.name}</Text>
            },
            {
                property: 'description',
                header: 'Description',
                render: roles => <Text>{roles.description}</Text>
            },
            {
                property: 'policynumber',
                header: 'Management',
                align: "center",
                render: role => {
                    let size = role.permissions ? role.permissions.length : 0;
                    if (size > 0) {
                        return (
                            <Box direction="row" alignContent="center" >
                                <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                    <Stack anchor="top-right" data-for={role.id}
                                        data-tip>
                                        <System
                                            size="large"
                                            style={{ height: "24px" }}
                                        />
                                        <Box
                                            background="brand"
                                            pad={{ horizontal: 'xsmall' }}
                                            round
                                        >
                                            {size}
                                        </Box>
                                    </Stack>
                                    {size > 0 && <ReactTooltip id={role.id} place="left" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <Box/*StyledBox style1 border="all" gap="small" pad="small"*/>
                                            {role.permissions && role.permissions.map((policy, idx) => (
                                                <Text>
                                                    {policy.type + " - " + policy.level}
                                                </Text>
                                            ))}
                                        </Box>
                                    </ReactTooltip>}
                                </Box>
                            </Box>
                        );
                    } else {
                        return;
                    }
                }
            },
            {
                property: 'trackerNumber',
                header: 'Permissions',
                align: "center",
                render: role => {
                    let size = role.trackerNumber;
                    if (size > 0) {
                        return (
                            <Box direction="row" alignContent="center" >
                                <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                    <Stack anchor="top-right">
                                        <Key
                                            size="large"
                                            style={{ height: "24px" }}
                                        />
                                        <Box
                                            background="brand"
                                            pad={{ horizontal: 'xsmall' }}
                                            round
                                        >
                                            {size}
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>
                        );
                    } else {
                        return;
                    }
                }
            },
            //{
            //    property: 'count',
            //    header: 'User Count',
            //    align: "center"
            //},
            {
                property: 'default',
                header: 'Default',
                render: role => role.isDefault ? <CheckBox checked={true} disabled /> : <CheckBox disabled />,
                align: "center"
            },
            {
                property: 'isDeletable',
                header: '',
                sortable: false,
                //render: role => role.isDefault ? <Blank /> : <Trash />,
                render: role =>
                    <Box width="24px" direction='row' gap="xsmall">
                        <Button plain icon={<Trash />} onClick={() => { this.props.groupdata.roles = this.props.groupdata.roles.filter(r => r.id != role.id); this.setState({ validating: true }); }} />
                    </Box>,
                align: "center"
            },
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    size: "0px",
            //    plain: true,
            //    render: role => <Text hidden>{role.id}</Text>,
            //},
        ];

        let DATA = [];

        /*this.state.selectedRoles*/this.props.groupdata.roles.sort((p1, p2) => { if (p1.name < p2.name) return -1; return 1; }).filter(r => r.id != 0).forEach((ev, idx) => {
            DATA.push({
                ...ev,
                lcrole: ev.name.toLowerCase(),
                /* lcpermission: ev.permission.toLowerCase()*/
                //permission: ArrayToString(ev.permissions),
                lcpermissiondetail: ev.description,
                count: idx % 2 == 0 ? idx + 1 : idx,//Math.floor(Math.random()* 10), //ev.userCount.toString(),
                policynumber: ev.policies ? ev.policies.length : 0,
                trackerNumber: ev.assetPermissions ? ev.assetPermissions.length : 0,
                isDeletable: !ev.isDefault
            })
        });

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.description.toLowerCase().includes(search)
            );
        }

        //if (!this.context.feature.find(f => f.name == "contentOrg").isEnabled) {
        //    DATA = DATA.filter(d => d.name != "Content Admin");
        //}

        //DATA.sort((p1, p2) => { if (p1.lcrole < p2.lcrole) return -1; return 1; });

        /*console.log('DATA:', DATA);*/

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcrole" || c.property === "lcpermissiondetail" || c.property === "count"
                }))}
                data={DATA}
                noLoadingMsg={this.props.groupdata.roles/*this.state.selectedRoles*/.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcrole', label: 'Role' },
                //    { col: 'lcpermissiondetail', label: 'Permission' },
                //    { col: 'userCount', label: 'User Count' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                line1="No roles added yet."
                line2={<div>Please select the <b>Add Role(s)</b> button to add roles to this group.</div>}
                //onUpdated={this.onDTUpdated.bind(this)}
                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                extraComponent={
                    <Box data-for={"addRolebtn"} data-tip={"Select to add a role(s) to this user"} /*style={{ "height": "24px" }}*/>
                        <StyledButton
                            typeSave
                            label="Add Role(s)"
                            alignSelf="end"
                            onClick={() => this.setState({ isRoleSidePanelOpen: true, selectedRolesCopy: this.props.groupdata.roles/*this.state.selectedRoles*/ })}
                        />
                        <ReactTooltip id={"addRolebtn"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                }
            />
        );
    }

    onNameChange = (evt) => {
        this.props.groupdata.name = evt.target.value;
        if (this.props.groupdata.name.length > 50)
            this.name_err = "Name can not be longer than 50 cahracter";
        else
            this.name_err = "";
        this.setState({validating: true});
    }

    onDescriptionChange = (evt) => {
        this.props.groupdata.description = evt.target.value;
        if (this.props.groupdata.description.length > 200)
            this.description_err = "Description can not be longer than 200 cahracter";
        else
            this.description_err = "";
        this.setState({ validating: true });
    }

    render() {
        const { options, selected, checked, loaded, userdataLoading, rolesdataLoading, sitesdataLoading, organisationdataLoading, policies, usersdataLoading, group, openUser,
            buildingsdataLoading, assetlabelsdataLoading, departmentsdataLoading, collectionsdataLoading, roleSearch, userSearch, policiesdataLoading, groupdataLoading
        } = this.state;
        //const userdefault = this.props.userdata;
        const { usersdata, buildingsdata, assetlabelsdata, departmentsdata, collectionsdata, groupdata, policiesdata } = this.props;
        /*const sitesdata = this.props.sitesdata;*/

        if (usersdataLoading || groupdataLoading/*|| rolesdataLoading || sitesdataLoading || organisationdataLoading*/
            /*|| buildingsdataLoading, assetlabelsdataLoading, departmentsdataLoading, collectionsdataLoading, policiesdataLoading, groupdataLoading*/) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = this.props.match.params.groupId != "0" ? 'Edit' : 'Create';
        let selectedTypes = [...new Set(group.policies.map(item => item.type))];
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Group - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium", bottom: "medium" }} fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onCloseSaving()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <PopUpConfirm open={openUser} text={constants.confirmMessage(this.state.deleteUserId.length > 1 ? "users" : "user")} onClose={() => this.setState({ openUser: undefined })}
                        onDelete={() => {
                            groupdata.userGroups = groupdata.userGroups.filter(user => !this.state.deleteUserId.includes(user.userId));
                            this.setState(prevState => ({
                                //group: {
                                //    ...prevState.group,
                                //    //users: prevState.group.users.filter(user => user.name != prevState.deleteUserId)
                                //    users: prevState.group.users.filter(user => !prevState.deleteUserId.includes(user.name))
                                //},
                                openUser: undefined,
                                deleteUserId: [],
                                selectedUsers: []
                            }))
                        }} />
                    <form>
                        <Box gap="medium">
                            <Box gap="small">
                                <Box>
                                    <Text size="large">General</Text>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                </Box>
                                <Box width="310px">
                                    <FormField label="Group Name *" error={this.name_err}>
                                        <TextInput defaultValue={groupdata.name}
                                            //onChange={evt => { this.props.groupdata.name = evt.target.value; this.setState(prevState => ({ group: { ...prevState.group, name: evt.target.value } })) }}
                                            onChange={this.onNameChange}
                                        />
                                    </FormField>
                                    <FormField label="Description" error={this.description_err }>
                                        <TextArea resize="vertical" defaultValue={groupdata.description}
                                            //onChange={evt => { this.props.groupdata.description = evt.target.value; this.setState(prevState => ({ group: { ...prevState.group, description: evt.target.value } })) }}
                                            onChange={this.onDescriptionChange}
                                        />
                                    </FormField>
                                </Box>
                                <Tabs flex justify="start" /*activeIndex={tabindex} onActive={(i) => this.onActive(i)}*/ >
                                    <Tab title="Users">
                                        <Box>
                                            {groupdata.userGroups.length == 0 && <Box>
                                                <Box data-for={"addUserbtn"} data-tip={"Select to add a user to this group"} style={{ "height": "24px", "width": "fit-content", alignSelf: "end" }}>
                                                    <StyledButton
                                                        //typeAdd
                                                        typeSave
                                                        label="Add User(s)"
                                                        alignSelf="end"
                                                        onClick={() => {
                                                            this.setState({ isUserSidaPanelOpen: true, selectedUsersCopy: /*group.users*/ this.props.groupdata.userGroups });
                                                            //console.log("dir", this.props.history);
                                                            //this.props.history.push({
                                                            //    pathname: this.props.history.location.pathname + '/users/0',
                                                            //    //state: { index: 2 }
                                                            //});
                                                        }}
                                                    />
                                                    <ReactTooltip id={"addUserbtn"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                </Box>
                                            </Box>}

                                            <FilterSidePanel open={this.state.isUserSidaPanelOpen}
                                                onAssign={() => {
                                                    console.log("assi", this.state.selectedUsersCopy, this.props.groupdata.userGroups);
                                                    this.props.groupdata.userGroups = this.state.selectedUsersCopy;
                                                    this.setState(prevState => ({
                                                    //group: {
                                                    //    ...prevState.group,
                                                    //    users: prevState.selectedUsersCopy.map(u => {return { "id": u.email, "name": u.email, "email": u.email };})
                                                    //},
                                                    isUserSidaPanelOpen: false,
                                                    userSearch: "",
                                                }))}}
                                                onClose={() => this.setState({ isUserSidaPanelOpen: false, userSearch: "", selectedUsersCopy: [] })}>
                                                <Box style={{ "margin-bottom": "15px" }}>
                                                    <Text>Select a user(s) from the list. Users must exist before they can be added to this group. This is either by having logged into the mobile client or being added to this organisation manually via the portal.</Text>
                                                </Box>
                                                <Box gap="small" /*width={{ min: 'medium' }}*/ >
                                                    <Box direction="row" flex>
                                                        <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                            <TextInput focusIndicator={false} plain placeholder="Search" value={userSearch}
                                                                onChange={e => {
                                                                    let searchText = e.target.value;
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    this.setState({ userSearch: e.target.value });
                                                                }} />
                                                            {(userSearch != "" && userSearch != undefined) ?
                                                                <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={userSearch == ""} onClick={() => this.setState({ userSearch: "" })} />
                                                                :
                                                                <Button icon={<Blank />} disabled />}
                                                        </StyledBox>
                                                    </Box>

                                                    <Box direction="row" pad="xsmall" justify="between" background="white" align="center">
                                                        <Text>{this.state.selectedUsersCopy.length} of {this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).length} selected</Text>
                                                        <Button label={this.state.selectedUsersCopy.length != this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).length ? "Select All" : "Clear All"}
                                                            onClick={evt => {
                                                                if (this.state.selectedUsersCopy.length == this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).length) {
                                                                    //this.setState(prevState => ({
                                                                    //    group: {
                                                                    //        ...prevState.group,
                                                                    //        users: []
                                                                    //    },
                                                                    //}))
                                                                    this.setState({ selectedUsersCopy: [] });
                                                                }
                                                                else {
                                                                    this.setState({ selectedUsersCopy: this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).map(us => { return { userId: us.email } }) })
                                                                    //let all = options.map(o => { return { id: o, name: o }; });
                                                                    //all.shift();
                                                                    //this.setState(prevState => ({
                                                                    //    group: {
                                                                    //        ...prevState.group,
                                                                    //        users: all
                                                                    //    },
                                                                    //}))
                                                                }
                                                            }} />
                                                    </Box>


                                                    {this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).length > 0 && <StyledBox style1 style={{ "overflow-y": "auto", "overflow-x": "hidden", "display": "block", cursor: "pointer" }} /*width={{ min: 'medium', max: 'medium' }}*/ height={{ max: '515px' }}>
                                                        {this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).map(option =>
                                                            <Box pad="small" background={this.state.selectedUsersCopy.some(r => r.userId == option.email) ? 'brand' : undefined}
                                                                focusIndicator={false}
                                                                onClick={() => {
                                                                    if (this.state.selectedUsersCopy.some(u => u.userId == option.email)) {
                                                                        //remove
                                                                        this.setState(prevState => ({
                                                                            selectedUsersCopy: prevState.selectedUsersCopy.filter(r => r.userId != option.email)
                                                                        }))
                                                                    }
                                                                    else {
                                                                        let obj = { userId: option.email };
                                                                        this.setState(prevState => ({
                                                                            selectedUsersCopy: [...prevState.selectedUsersCopy, obj]
                                                                        }))
                                                                    }
                                                                    console.log("assiB",this.state.selectedUsersCopy)
                                                                }}>
                                                                <Box direction="row" justify="between">
                                                                    <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                        <Text weight="bold">{option.email}</Text>
                                                                    </Box>
                                                                    <Box>
                                                                        <CheckBox focusIndicator={false} checked={this.state.selectedUsersCopy.some(r => r.userId == option.email)} />
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                    {option.username}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </StyledBox>}
                                                    {this.props.usersdata.filter(u => userSearch == "" || u.email.includes(userSearch)).length == 0 &&
                                                        <Box>
                                                            <Text>
                                                                No Users found
                                                            </Text>
                                                        </Box>
                                                    }
                                                </Box>
                                            </FilterSidePanel>

                                            <StyledBox style={{ "min-width": "790px" }}>
                                                <Box /*margin={{ bottom: groupdata.userGroups.length == 0 ? "24px" : "0px" }}*/>
                                                    {this.renderUsersTable(this.props, false)}
                                                </Box>
                                                {this.state.selectedUsers.length > 0 && <Box background="light-4" width="100%" justify="between" direction="row" pad="small" /*style={{ borderRadius: "5px 5px 5px 5px", boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" }}*/>
                                                    <Box direction="row" gap="medium">
                                                        <Box>
                                                            {this.state.selectedUsers.length} of {this.count} selected
                                                        </Box>
                                                        <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ selectedUsers: [] })}>
                                                            Clear
                                                        </Box>
                                                    </Box>
                                                    <Box direction='row'>
                                                        <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ icon={<Trash />}
                                                            onClick={() => this.setState(prevState => ({
                                                                openUser: true,
                                                                deleteUserId: prevState.selectedUsers
                                                            }))} />
                                                    </Box>
                                                </Box>}
                                            </StyledBox>
                                        </Box>
                                    </Tab>
                                    <Tab title="Roles">
                                        <Box /*pad={{ "top": "small" }}*/>
                                            <ReactTooltip id={"addRolebtnEmpty"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            {/*this.state.selectedRoles*/groupdata.roles.length == 0 && <Box data-for={"addRolebtnEmpty"} data-tip={"Select to add a role(s) to this user"} style={{ "height": "24px" }}>
                                                <StyledButton
                                                    typeSave
                                                    label="Add Role(s)"
                                                    alignSelf="end"
                                                    onClick={() => this.setState({ isRoleSidePanelOpen: true, selectedRolesCopy: groupdata.roles/*this.state.selectedRoles*/ })}
                                                />
                                            </Box>}
                                            {this.renderNewRolesTable()}
                                            <FilterSidePanel open={this.state.isRoleSidePanelOpen} onAssign={() => { this.props.groupdata.roles = this.state.selectedRolesCopy; this.setState({ selectedRoles: this.state.selectedRolesCopy, isRoleSidePanelOpen: false, roleSearch: "", filteredRoles: policiesdata/*constants.ROLES.filter(r => r.id != 0) })*/ }); }} onClose={() => this.setState({ isRoleSidePanelOpen: false, roleSearch: "", filteredRoles: policiesdata /*constants.ROLES.filter(r => r.id != 0)*/ })}>
                                                <Box style={{ "margin-bottom": "15px" }}>
                                                    <Text>A user is not assigned any permissions when created or added to your organization. Assign a role to the user to assign permissions allowed for that role.</Text>
                                                </Box>
                                                <Box gap="small" /*width={{ min: 'medium' }}*/ >
                                                    <Box direction="row" flex>
                                                        <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                            <TextInput focusIndicator={false} plain placeholder="Search" value={roleSearch}
                                                                onChange={e => {
                                                                    let searchText = e.target.value;
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = policiesdata.filter(o => o.id != 0 && o.name.match(regexp));
                                                                    this.setState({ filteredRoles: filtered, roleSearch: e.target.value });
                                                                }} />
                                                            {(roleSearch != "" && roleSearch != undefined) ?
                                                                <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={roleSearch == ""} onClick={() => this.setState({ roleSearch: "", filteredRoles: policiesdata })} />
                                                                :
                                                                <Button icon={<Blank />} disabled />}
                                                        </StyledBox>
                                                    </Box>
                                                    {this.state.filteredRoles.length > 0 && <StyledBox style1 style={{ "overflow-y": "auto", "display": "block", cursor: "pointer" }} /*width={{ min: 'medium', max: 'medium' }}*/ height={{ max: '600px' }}>
                                                        {this.state.filteredRoles/*.concat(this.state.filteredRoles).concat(this.state.filteredRoles)*/.map(option =>
                                                            <Box pad="small" background={this.state.selectedRolesCopy.some(r => r.name == option.name) ? 'brand' : undefined}
                                                                focusIndicator={false}
                                                                onClick={() => {
                                                                    if (this.state.selectedRolesCopy.some(u => u.name == option.name)) {
                                                                        //remove
                                                                        this.setState(prevState => ({
                                                                            selectedRolesCopy: prevState.selectedRolesCopy.filter(r => r.name != option.name)
                                                                        }))
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => ({
                                                                            selectedRolesCopy: [...prevState.selectedRolesCopy, option]
                                                                        }))
                                                                    }
                                                                }}>
                                                                <Box direction="row" justify="between">
                                                                    <Text weight="bold">{option.name}</Text>
                                                                    <Box>
                                                                        <CheckBox focusIndicator={false} checked={this.state.selectedRolesCopy.some(r => r.name == option.name)} />
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                    {option.description}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </StyledBox>}
                                                    {this.state.filteredRoles.length == 0 &&
                                                        <Box>
                                                            <Text>
                                                                No Roles found
                                                            </Text>
                                                        </Box>
                                                    }
                                                </Box>
                                            </FilterSidePanel>
                                        </Box>
                                    </Tab>
                                    {false && <Tab title="Permissions">
                                        <Box margin={{ /*top: "small",*/ bottom: "small" }} direction="row" justify="end">
                                            {selectedTypes.length != 6 && <Box /*border={{ side: "all", color: constants.HPE_GREEN, size: "small" }} style={{ "border-radius": "5px 5px 5px 5px" }}*/>
                                                <Select
                                                    plain
                                                    options={["Site", "Building", "Collection", "Department", "Label", "Floor"]}
                                                    disabled={selectedTypes}
                                                    value=""
                                                    onChange={evt =>
                                                        this.setState(prevState => ({
                                                            group: {
                                                                ...prevState.group,
                                                                //policies: [...prevState.group.policies, {
                                                                //    id: prevState.group.policies.length,
                                                                //    name: "",
                                                                //    access: "",
                                                                //    type: evt.option
                                                                //}]
                                                                policies: [{
                                                                    id: prevState.group.policies.length,
                                                                    name: "",
                                                                    access: "",
                                                                    type: evt.option
                                                                }, ...prevState.group.policies]
                                                            }
                                                        })
                                                        )
                                                    }
                                                    valueLabel={<Button primary label="Add New Type" />}
                                                    icon={null}
                                                />
                                            </Box>}
                                        </Box>
                                        <Box gap="small" pad={{ vertical: "small" }}>
                                            {this.permEntry(group.policies)}
                                            <Box direction="row" gap="small">
                                                {false && selectedTypes.length != 6 && <Box border={{ side: "all", color: constants.HPE_GREEN, size: "small" }} style={{ "border-radius": "5px 5px 5px 5px" }}>
                                                    <Select
                                                        plain
                                                        options={["Site", "Building", "Collection", "Department", "Label", "Floor"]}
                                                        disabled={selectedTypes}
                                                        value={<Box
                                                            pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
                                                        //margin="xsmall"
                                                        >
                                                            <b>Add New Type</b>
                                                        </Box>}
                                                        onChange={evt =>
                                                            this.setState(prevState => ({
                                                                group: {
                                                                    ...prevState.group,
                                                                    policies: [...prevState.group.policies, {
                                                                        id: prevState.group.policies.length,
                                                                        name: "",
                                                                        access: "",
                                                                        type: evt.option
                                                                    }]
                                                                }
                                                            })
                                                            )
                                                        }
                                                    />
                                                </Box>}
                                            </Box>
                                        </Box>
                                    </Tab>}
                                    {false && <Tab title="App Content">
                                        {this.renderControlTable({ content: constants.CONTENT })}
                                        {false && <AccessControl
                                            checkBoxAvailable
                                            disabled={false}
                                            onCheck={() => { }}
                                            onSelectAuth={() => { }}
                                            onSelectGroups={() => { }}
                                            groups={constants.GROUPS.filter(g => g.id != 0).sort(constants.byPropertyCalled())} />}
                                    </Tab>}
                                </Tabs>
                            </Box>
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    //const userId = ownProps.match.params.userId || '';
    const userId = "bence.koppany@hpe.com";
    if (/*state.selectedUserReducer.userdata && userId == state.selectedUserReducer.userdata.id*/true) {
        start = JSON.parse(JSON.stringify(state.selectedGroupReducer.groupdata));
        return {
            //userdata: state.selectedUserReducer.userdata,
            usersdata: state.usersReducer.usersdata,
            //rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            buildingsdata: state.buildingsReducer.buildingsdata,
            assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
            departmentsdata: state.departmentsReducer.departmentsdata,
            collectionsdata: state.collectionsReducer.collectionsdata,
            //usersdata: state.usersReducer.usersdata,
            groupdata: state.selectedGroupReducer.groupdata,
            policiesdata: state.policiesReducer.policiesdata
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            //sitesdata: state.sitesReducer.sitesdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction, ...OrganisationAction, ...BuildingAction, ...AssetLabelsAction, ...DepartmentAction, ...CollectionAction, ...PolicyAction, ...GroupAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocGroupEditContainer));