import * as ActionType from './ActionType';
import ProfileApi from '../api/ProfileApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getProfilesResponse = profilesdata => ({
    type: ActionType.GET_PROFILES_RESPONSE,
    profilesdata
});

const queuedGetAllProfiles = queueApiCall(ProfileApi.getAllProfiles);

export function getProfilesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return ProfileApi.getAllProfiles()
        return queuedGetAllProfiles()
            .then(profilesdata => {
                dispatch(getProfilesResponse(profilesdata));
                return profilesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewProfileResponse = () => ({
    type: ActionType.ADD_NEW_PROFILE_RESPONSE
});

export const updateExistingProfileResponse = () => ({
    type: ActionType.UPDATE_EXISTING_PROFILE_RESPONSE
});

export function saveProfileAction(profiledraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the profile is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new profile that is being added, therefore add it
        return ProfileApi.saveProfile(profiledraft)
            .then(() => {
                if (profiledraft.id) {
                    dispatch(updateExistingProfileResponse());
                } else {
                    dispatch(addNewProfileResponse());
                }
            }).then(() => {
                dispatch(getProfileAction(profiledraft.id));
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getProfileResponse = profiledata => ({
    type: ActionType.GET_PROFILE_RESPONSE,
    profiledata
});

export function getProfileAction(profileId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return ProfileApi.getProfile(profileId)
            .then(profiledata => {
                dispatch(getProfileResponse(profiledata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteProfileResponse = () => ({
    type: ActionType.DELETE_PROFILE_RESPONSE
});

const queuedDeleteProfile = queueApiCall(ProfileApi.deleteProfile);

export function deleteProfileAction(profileId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return ProfileApi.deleteProfile(profileId)
        return queuedDeleteProfile(profileId)
            .then((response) => {
                dispatch(deleteProfileResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getProfilesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}