import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const placemarksHistoryReducer = (state = initialState.placemarksHistoryReducer, action) => {
    switch (action.type) {
        case ActionType.GET_PLACEMARKS_HISTORY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                placemarkhistoriesdata: _.assign(action.placemarkhistoriesdata)
            };
        }


        default: { return state; }
    }
};



export default placemarksHistoryReducer;