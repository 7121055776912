import { FormField, Box, CheckBox, Select, Tag, Grid, Text, Button, Grommet } from 'grommet';
import { FormClose } from 'grommet-icons';
import { StyledBox } from './StyledBox';
import React from 'react';
import constants from '../../constants';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import * as GroupAction from '../../action/GroupAction';
import GroupApi  from '../../api/GroupApi';;
import { connect } from 'react-redux';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    tag: {
        background: "brand",
        border: false
    }
});

const AccessControl = (props) => {
    let def = [];
    React.useEffect(() => {
        console.log(props);
        if (props.groups) {
            GroupApi.getAllGroups().then(data => { setFilter(data); setDefaultOptions(data); });
        }
        else {
            setFilter(props.groups);
            setDefaultOptions(props.groups);
        }
        //props.action.getGroupsAction()
        //    .then(response => response/*this.setState({ groupsdataLoading: false })*/)
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Groups List Error', error);
        //    });
    }, []);
    const [checked, setChecked] = React.useState(props.defaultChecked);
    const [auth, setAuth] = React.useState("Authenticated / Non-Authenticated");
    const [selectedGroups, setGroups] = React.useState([]);
    const [selectedGroupIndexs, setGroupIndexs] = React.useState([]);
    const [filtered, setFilter] = React.useState(props.groups);
    const [defaultOptions, setDefaultOptions] = React.useState([]);
    const { checkBoxAvailable, onCheck, disabled, onSelectAuth, groups, onSelectGroups } = props;
    return (
        <Box>
            <Box>
                <Text size="large">Access Controls</Text>
            </Box>
            <StyledBox style1 pad={{ "horizontal": "small", bottom: "small" }} margin={{bottom: "small"}} width="600px">
                <Box width="60%">
                {checkBoxAvailable && <Box width="fit-content">
                    <FormField label="Active">
                        <Box pad="small">
                            <CheckBox
                                checked={checked}
                                    disabled={disabled}
                                    onChange={evt => { onCheck(evt.target.checked); setChecked(evt.target.checked) }}
                            />
                        </Box>
                    </FormField>
                </Box>}
                <Box>
                    <FormField label="Authentication Status">
                            <Select
                                options={["Authenticated / Non-Authenticated", "Authenticated", "Non-Authenticated"]}
                                //options={["Public","Private"]}
                                value={auth}
                                onChange={evt => { onSelectAuth(evt.option); setAuth(evt.option) }}
                            />
                    </FormField>
                </Box>
                <Box>
                    <FormField label="Group(s)">
                            <Select
                                closeOnChange={false}
                                placeholder="Select Group(s)"
                                labelKey="name"
                                value={selectedGroups}
                                multiple
                                options={filtered}
                                selected={selectedGroupIndexs}
                                onChange={evt => { onSelectGroups(evt.value); setGroups(evt.value); setGroupIndexs(evt.selected) }}
                                onSearch={(text) => {
                                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                    const exp = new RegExp(escapedText, 'i');
                                    setFilter(defaultOptions.filter((o) => exp.test(o.name)));
                                }}
                            >
                                {(option, index, options, { active, disabled, selected }) =>
                                    <Box direction="row" pad="small" justify="between" background={/*selected*/selectedGroups.some(g => g.id == option.id) ? 'brand' : undefined}>
                                        {option.name}
                                        <CheckBox checked={selectedGroups.some(g=> g.id == option.id)} />
                                    </Box>}
                            </Select>
                    </FormField>
                    <Box>
                        <Grid
                            columns={{
                                count: 2,
                                size: 'auto'
                            }}
                            gap="xsmall"
                        >
                            {selectedGroups.map((g,idx) =>
                                <Grommet theme={customTheme} >
                                    <Tag
                                        value={g.name}
                                        size="xsmall"
                                        onRemove={() => {
                                            onSelectGroups(selectedGroups.filter(item => item.id != g.id));
                                            setGroups(selectedGroups.filter(item => item.id != g.id))
                                        }}
                                        style={{ width: "fit-content" }}
                                    />
                                </Grommet>
                                )}
                        </Grid>
                    </Box>
                    </Box>
                    </Box>
            </StyledBox>
        </Box>
    );
};

export default AccessControl;