import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as SiteAction from '../action/SiteAction';
import * as AssetHistoryAction from '../action/AssetHistoryAction';
import TrackerApi from '../api/TrackerApi';
import AssociationApi from '../api/AssociationApi';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import constants from '../constants';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, Tabs, Tab } from 'grommet';
import { Android, Apple, Tag, Sync, Refresh } from 'grommet-icons';
import * as MeridianSDK from "@meridian/web-sdk";
import './Meridian.css';
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import AssetStatusHistoryListTable from './AssetStatusHistoryListTable';
import AssetHistoryListContainer from './AssetHistoryListContainer';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';
import { getPortalToken } from '../api/ApiUtils';
import ReactTooltip from "react-tooltip";
//var date = new Date().toLocaleDateString("en-US");

class OrgAssetEditContainer extends Component {
    static contextType = Context;
    constructor() {
        super();
        this.done = false;
        this.mapRenderDone = false;
        this.state = {
            validating: false, value: "", loaded: false, status: "Available", tabIndex: 0, disabledButton: false, meridianApiFailed: false,
            meridianData: {
                map: {
                    name: "",
                    group: {
                        name: ""
                    }
                },
                id: "",
                tags: []
            },
            syncTime: "",
            showCurrent: true,
            showLastKnown: false,
            assetdataLoading: true,
            sitedataLoading: true,
            historydataLoading: true,
            departmentsdataLoading: true,
            collectionsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    statusCode_err = '';

    ClearErrorMessages() {
        this.statusCode_err = '';
    }
    async componentWillReceiveProps(props) {
        console.log("troubleC", props.sitedata, props.assetdata, props.assetdata.mapId, this.state.tabIndex == 0, !this.mapRenderDone);
        if (props.sitedata && props.assetdata && props.assetdata.mapId && this.state.tabIndex == 0 && !this.mapRenderDone) {
            this.mapRenderDone = true;
            //setTimeout(() => this.renderMap(props.assetdata.mapId, props.sitedata.appID, props.sitedata.appToken), 1000);
        }
        //Fecth meridian
        console.log("MERIDIAN: asd", props, sessionStorage.getItem("locationId"))
        if (props.sitedata && props.sitedata.appID /*&& props.sitedata.id == sessionStorage.getItem("locationId")*/ && props.assetdata && props.assetdata.id && this.state.tabIndex == 0 && this.state.meridianData.id == "" && !this.done) {
            this.done = true;
            let token = await getPortalToken();
            constants.meridianApi(`asset-beacons/${props.assetdata.id}`, props.sitedata.meridianOrgId, props.sitedata.appID, token)
                .then(res => res.json()).then(data => {
                    var strDate = "";
                    if (data && data.last_heard != null) {
                        var date = new Date(data.last_heard * 1000);
                        strDate = props.assetdata.mapId ? this.formatDate(date) : "";
                    }
                    if (data.detail != "Not found.") {
                        this.setState({ meridianData: data, syncTime: strDate }, () => {

                            if (this.state.meridianData.map && this.state.meridianData.map.id) {
                                setTimeout(() => this.renderMap(this.state.meridianData.map.id), 2000);
                            }

                        });
                        //GET ZONE
                        if (data.map.id) {
                            this.fetchMeridianZone(props.sitedata.meridianOrgId, props.sitedata.appID, data.map.id, props.assetdata.id);
                        }
                    }
                    else if (data.detail == "Not found.") {
                        this.setState({ meridianApiFailed: true });
                    }
                });
        }
        //if (!this.state.tracker) {
        //    AssociationApi.getAllAssociations()
        //        .then(data => {
        //            let assoc = data.find(a => a.beaconId == this.props.match.params.id);
        //            if (assoc) {
        //                TrackerApi.getTracker(assoc.assetId)
        //                    .then(tracker => this.setState({ tracker: tracker }))
        //                    .catch(error => console.log('*DEBUG*- Get Tracker error', error));
        //            } else {
        //                this.setState({ tracker: {department: "Not available", colection: "Not availbale"} })
        //            }
        //        })
        //        .catch(error => console.log('*DEBUG*-  Get All Association error', error));
        //}
    }
    componentDidMount() {
        const id = this.props.match.params.assetid;
        this.props.action.getAssetAction(id)
            .then(data => {
                this.props.action.getSiteAction(data.locationId)
                    .then(response => this.setState({ sitedataLoading: false }))
                    .catch(error => {
                        var errorArray = this.state.apiErrorList.concat(error);
                        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                        console.log('*DEBUG*- Site List Error', error);
                    });
                AssociationApi.getAllAssociations()
                    .then(adata => {
                        this.setState({ assetdataLoading: false });
                        let assoc = adata.find(a => a.beaconId == this.props.match.params.assetid && a.locationId == data.locationId );
                        if (assoc) {
                            TrackerApi.getTracker(assoc.assetId)
                                .then(tracker => this.setState({ tracker: tracker }))
                                .catch(error => {
                                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                                    console.log('*DEBUG*- Get Tracker error', error)
                                });
                        } else {
                            this.setState({ tracker: { departmentId: null, collectionId: null } });
                        }
                    })
                    .catch(error => {
                        var errorArray = this.state.apiErrorList.concat(error);
                        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                        console.log('*DEBUG*-  Get All Association error', error)
                    });
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assets List Error', error);
            });
        //this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
        //    .catch(error => {
        //        console.log('*DEBUG*- Site List Error', error);
        //    });
        this.props.action.getAssetHistoryAction(id)
            .then(response => this.setState({ historydataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetHistory List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        //if (!this.state.tracker) {
        //    AssociationApi.getAllAssociations()
        //        .then(data => {
        //            this.setState({ assetdataLoading: false });
        //            let assoc = data.find(a => a.beaconId == this.props.match.params.assetid);
        //            if (assoc) {
        //                TrackerApi.getTracker(assoc.assetId)
        //                    .then(tracker => this.setState({ tracker: tracker }))
        //                    .catch(error => {
        //                        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
        //                        console.log('*DEBUG*- Get Tracker error', error)
        //                    });
        //            } else {
        //                this.setState({ tracker: { departmentId: null, collectionId: null } });
        //            }
        //        })
        //        .catch(error => {
        //            var errorArray = this.state.apiErrorList.concat(error);
        //            this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //            console.log('*DEBUG*-  Get All Association error', error)
        //        });
        //}
    }
    componentWillUnmount() {
        let cs = document.querySelector("[id='coloured']");
        if (cs)
            document.querySelector("[id='coloured']").remove();
        if (this.map) {
            this.map.destroy();
        }
    }
    renderMap(floorid, locid = this.props.sitedata.appID, token = this.props.sitedata.appToken) {
        let point = this.state.showLastKnown ? [{
            type: "point",
            x: this.props.assetdata.x,
            y: this.props.assetdata.y,
            backgroundColor: /*"#c00"*/"none",
            //size: 100,
            //backgroundImage: 'https://hpepngdemostor.blob.core.windows.net/images/locationmarker.gif',
            size: 50,
            backgroundImage: 'https://hpepngdemostor.blob.core.windows.net/images/locationm.gif',
            title: "Last Known"
        }] : [];
        if (!floorid || !locid || !token || this.state.tabIndex != 0) {
            //Invalid parameters to show the map
            //Do nothing
        }
        //else if (this.map && !this.map.isDestroyed) {
        //    this.map.update({ floorID: floorid });
        //}
        else if (document.getElementById("meridian-map")) {
            const api = new MeridianSDK.API({
                environment: this.props.sitedata.eUorNon ? "eu" : "production",
                //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                token: token
            });
            var element = document.getElementById("meridian-map");
            //if (element) {
            //    element.innerHTML = "";
            //}
            //console.log("element", element);
            this.map = MeridianSDK.createMap(
                //document.getElementById("meridian-map"),
                element,
                {
                    //locationID: "5198682008846336",
                    api: api,
                    locationID: locid,
                    floorID: floorid,
                    shouldMapPanZoom: function restrictedPanZoom(event) {
                        if (event.type === "wheel" && !event.altKey) {
                            return false;
                        } else if (event.type === "touchstart") {
                            return event.touches.length >= 2;
                        }
                        return true;
                    },
                    //showFloorsControl: true, //this.state.showMapControls,
                    //showTagsControl: true, //this.state.showMapControls,

                    annotations: point,
                    height: "500px",
                    loadPlacemarks: false,
                    placemarks: {
                        filter: p => {
                            return false;
                        }
                    },

                    tags: {
                        filter: t => {
                            //return this.state.tags.includes(t.mac);
                            //no real-time
                            return this.state.showCurrent && this.props.assetdata.id === t.mac;
                        },
                        //showControlTags: true
                        updateInterval: 10000
                    },
                    showTagsControl: false,
                    showSearchControl: false
                }
            );
        }
        //return (<div id="meridian-map" className="map" />);
    }
    renderBatteryIcon(level) {
        if (0 < level && level < 20) {
            return (
                <Box /*direction="row" */ gap="xsmall" direction="row">
                    <Box margin={{ "top": "3px" }}>
                        <BatteryRed color="#FF4040" style={{ width: "34.365", height: "18" }} />
                    </Box>
                    <Text weight="bold">{level}%</Text>
                </Box>);
        }
        else if (20 <= level && level <= 60) {
            return (
                <Box /*direction="row"*/ gap="xsmall" direction="row">
                    <Box margin={{ "top": "3px" }}>
                        <BatteryAmber color="#FFAA15" style={{ width: "34.365", height: "18" }} />
                    </Box>
                    <Text weight="bold">{level}%</Text>
                </Box>);
        }
        else if (60 < level && level <= 100) {
            return (
                <Box /*direction="row"*/ gap="xsmall" direction="row">
                    <Box margin={{ "top": "3px" }}>
                        <Battery color="#01a982" style={{ width: "34.365", height: "18" }} />
                    </Box>
                    <Text weight="bold">{level}%</Text>
                </Box>);
        }
        else {
            return (
                <Box gap="xsmall" direction="row">
                    <Box margin={{ "top": "3px" }}>
                        <BatteryUnknown color="#CCCCCC" style={{ width: "34.365", height: "18" }} />
                    </Box>
                    <Text weight="bold">unknown</Text>
                </Box>);
        }

    }
    updateStatus(o) {
        this.statusCode_err = "";
        var option = o.option;
        //this.props.assetdata.serviceStateId = o.selected;
        this.props.historydata.serviceStateId = option.id;
        //this.props.assetdata.serviceState = o.option;
        this.setState({ status: option.name });
    }
    handleSave() {
        let errorsno = 0;
        if (this.context.feature.find(f => f.name == "wellness").isEnabled && (this.props.historydata.serviceStateId == null || this.props.historydata.serviceStateId === "")) {
            errorsno++;
            this.statusCode_err = "Select a Status";
        }
        if (errorsno == 0) {
            this.setState({ disabledButton: true });
            this.props.historydata.history = [];
            console.log('Save: OK', this.props.historydata);
            this.props.action.saveAssetHistoryAction(this.props.historydata)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/categories');
                }).catch(error => {
                    console.log('*DEBUG*-Save AssetEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ disabledButton: false });
        }

    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/eventstable');
        }
    }
    DeviceFlag() {
        const data = this.props.assetdata;
        //A0E6F83810BB - Holly's cart id
        if (data.traceable != true) {
            return "Untraceable";
        }
        if (data.traceable) {
            return "Healthy";
        }
        //These two beneath will never be evaluated
        if (data.assetBeaconTags.length == 0) {
            return "Orphans";
        }
        if (data.batteryLevel < 20) {
            return "Low Charge";
        }
    }
    async fetchMeridian() {
        let token = await getPortalToken();
        constants.meridianApi(`asset-beacons/${this.props.assetdata.id}`, this.props.sitedata.meridianOrgId, this.props.sitedata.appID, token)
            .then(res => res.json()).then(data => {
                var strDate = "";
                if (data && data.last_heard != null) {
                    var date = new Date(data.last_heard * 1000);
                    strDate = this.props.assetdata.mapId ? this.formatDate(date) : "";
                }
                this.setState({ meridianData: data, syncTime: strDate }, () => { if (data.map.id) this.renderMap(data.map.id); });
                console.log("helo", data, strDate);
                //GET ZONE
                if (data.map.id) {
                    this.fetchMeridianZone(this.props.sitedata.meridianOrgId, this.props.sitedata.appID, data.map.id, this.props.assetdata.id);
                }
            });
    }
    async fetchMeridianZone(orgid, locationId, mapId, assetId) {
        let token = await getPortalToken();
        constants.meridianApi(`tag-zones?map_id=${mapId}`, orgid, locationId, token)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //NO ZONE
                    return { results: [] };
                }
            })
            .then(data => {
                let isZoneFound = false;
                data.results.forEach(zone => {
                    if (zone.tag_ids.includes(assetId)) {
                        this.setState({ currentZone: zone.name });
                        isZoneFound = true;
                        return;
                    }
                });
                if (!isZoneFound) {
                    this.setState({ currentZone: "No Zone" });
                }
            })
            .catch(error => this.setState({ currentZone: "Zone Error" }))
    }
    formatDate(utc) {
        var date = new Date(utc);
        var strDate =
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
            + "-" + ("00" + date.getDate()).slice(-2)
            + " "
            + ("00" + date.getHours()).slice(-2) + ":"
            + ("00" + date.getMinutes()).slice(-2)
            + ":" + ("00" + date.getSeconds()).slice(-2);
        return strDate;
    }

    render() {
        const { assetdata, sitedata, historydata, collectionsdata, departmentsdata } = this.props;
        const { loaded, assetdataLoading, sitedataLoading, historydataLoading, departmentsdataLoading, collectionsdataLoading } = this.state;

        if (assetdataLoading || sitedataLoading || historydataLoading || this.state.meridianData.id == "" && !this.state.meridianApiFailed || !this.state.meridianData.id && !this.state.meridianApiFailed || !this.state.tracker) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("Asset:", assetdata, historydata, this.state.meridianData);
        console.log("traki", this.state);
        const battery = assetdata.batteryLevel + "%";
        var stat = constants.ASSET_STATUS.find(s => s.id == historydata.serviceStateId);
        stat = stat ? stat : {};
        var groupName = assetdata.id == "546C0E032A87" ? "Radiology" : assetdata.id == "A0E6F83810BB" ? "Maternity" : assetdata.id == "546C0E082AFB" ? "Emergency " : "";
        let collection = this.state.tracker && this.state.tracker.collectionId && collectionsdata ? collectionsdata.find(c => c.id == this.state.tracker.collectionId) : "";
        if (collection && !document.querySelector("[id='coloured']")) {
            let selector = `.meridian-tag-label-${collection.labelName.replace(" ", "-")}`;
            let rule = `background-color:${collection.hexColor};background-image:none`;
            var style = document.createElement("style");
            style.setAttribute('id', "coloured");
            style.innerHTML = `${selector} {
                ${rule}
                }`;
            document.head.appendChild(style);
        }
        let collectionName = collection ? collection.name : "No collection available";
        let departmentName = this.state.tracker && this.state.tracker.departmentId && departmentsdata ? departmentsdata.find(d => d.id == this.state.tracker.departmentId) : "";
        departmentName = departmentName ? departmentName.name : "No department available";
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >{this.state.tabIndex == 0 ? "Asset Details" : this.state.tabIndex == 1 ? "Asset History" : "Asset Status"}</Heading>
                    <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                        <Box width="100px">
                            <StyledButton typeCancel label="Back"
                                onClick={() => {
                                    if (sessionStorage.getItem("organisationId"))
                                        this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgassets');
                                    else
                                        this.props.history.push('/orgassets');
                                }}
                                disabled={this.state.disabledButton} />
                        </Box>
                        {this.state.tabIndex == 0 && this.context.feature.find(f => f.name == "wellness").isEnabled &&
                            <Box width="100px">
                                <StyledButton typeSave label="Save" disabled={this.state.disabledButton} onClick={() => this.handleSave()} />
                            </Box>
                        }
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <Box id="relativeBox" style={{ position: "relative" }}>
                    <Tabs flex justify="start" activeIndex={this.state.tabIndex} onActive={(idx) => {
                        this.setState({ tabIndex: idx }, () => setTimeout(() => this.renderMap(this.props.assetdata.mapId, this.props.sitedata.appID, this.props.sitedata.appToken), 1000));
                    }}>
                        <Tab title="Details">
                            <Box gap="small" margin={{ "vertical": "small" }}>
                                <StyledBox style1 direction="column" pad={{ "horizontal": "small" }}>
                                    <Box direction="row" margin={{ "top": "35px", "bottom": "35px" }}>
                                        <Box width="20%" pad={{ right: "medium" }}>
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Name</Text>
                                            {this.state.tracker && this.state.tracker.name ?
                                                <Text weight="bold">{this.state.tracker.name}</Text>
                                                :
                                                <Box>
                                                    <Box height="25px" width="50px" data-for="name_data_tip" data-tip></Box>
                                                    <ReactTooltip id="name_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                        <span>No data available</span>
                                                    </ReactTooltip>
                                                </Box>
                                            }
                                        </Box>
                                        <Box width="20%" pad={{ right: "medium" }}>
                                            <Text size="14px" margin={{ "bottom": "15px" }}>External ID</Text>
                                            {assetdata.externalId ?
                                                <Text weight="bold">{assetdata.externalId}</Text>
                                                :
                                                <Box>
                                                    <Box height="25px" width="50px" data-for="external_data_tip" data-tip></Box>
                                                    <ReactTooltip id="external_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                        <span>No data available</span>
                                                    </ReactTooltip>
                                                </Box>
                                            }
                                        </Box>
                                        <Box width="20%">
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Battery Level</Text>
                                            <Box direction="row">
                                                {this.renderBatteryIcon(assetdata.batteryLevel)}
                                            </Box>
                                        </Box>
                                    </Box>
                                </StyledBox>
                                <StyledBox style1 direction="column" pad={{ "horizontal": "small" }}>
                                    <Box direction="row" margin={{ "top": "35px", "bottom": "25px" }}>
                                        <Box width="20%" pad={{ right: "medium" }}>
                                            <Text size="14px" margin={{ "bottom": "15px" }}>MAC</Text>
                                            {assetdata.id ?
                                                <Text weight="bold">{assetdata.id}</Text>
                                                :
                                                <Box>
                                                    <Box height="25px" width="50px" data-for="id_data_tip" data-tip></Box>
                                                    <ReactTooltip id="id_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                        <span>No data available</span>
                                                    </ReactTooltip>
                                                </Box>
                                            }
                                        </Box>
                                        <Box width="20%" pad={{ right: "medium" }}>
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Type</Text>
                                            <Text weight="bold">{assetdata.isControlTag ? "Control Tag" : "Tag"}</Text>
                                        </Box>
                                        <Box width="20%" pad={{ right: "medium" }}>
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Device Flag</Text>
                                            <Text weight="bold">{this.DeviceFlag()}</Text>
                                        </Box>
                                        <Box pad={{ right: "medium" }} direction="row">
                                            <Text size="14px">Image</Text>
                                            <FormField label="" error={this.image_err} margin={{ "left": "10px" }}>
                                                <Box direction="row" justify="center" >
                                                    {
                                                        (assetdata.imageUrl == "" || assetdata.imageUrl == null) ?
                                                            <Box height="75px" justify="center">
                                                                <Tag />
                                                            </Box>
                                                            :
                                                            <Box justify="center">
                                                                <Image height="75" src={`${assetdata.imageUrl}?${this.stamp}`} />
                                                            </Box>
                                                    }
                                                </Box>
                                            </FormField>
                                        </Box>
                                    </Box>
                                </StyledBox>

                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                    <Box direction="row" margin={{ "top": "35px", "bottom": "35px" }}>
                                        <Box width="20%">
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Collection</Text>
                                            <Box pad={{ right: "medium" }} direction="row">
                                                <Box round height="23px" background={collection.hexColor ? collection.hexColor : "white"} width="23px" border style={{ "border-width": "2px" }} />
                                                <Box margin={{ "left": "15px" }}>
                                                    {collectionName ?
                                                        <Text weight="bold">{collectionName}</Text>
                                                        :
                                                        <Box>
                                                            <Box height="25px" width="50px" data-for="collection_data_tip" data-tip></Box>
                                                            <ReactTooltip id="collection_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                <span>No data available</span>
                                                            </ReactTooltip>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box width="20%">
                                            <Text size="14px" margin={{ "bottom": "15px" }}>Department</Text>
                                            {departmentName ?
                                                <Text weight="bold">{departmentName}</Text>
                                                :
                                                <Box>
                                                    <Box height="25px" width="50px" data-for="department_data_tip" data-tip></Box>
                                                    <ReactTooltip id="department_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                        <span>No data available</span>
                                                    </ReactTooltip>
                                                </Box>
                                            }
                                        </Box>
                                        {this.state.tracker && this.state.tracker.class == "Asset" &&
                                            <Box width="20%">
                                                <Text size="14px" margin={{ "bottom": "15px" }}>Manufacturer</Text>
                                                {this.state.tracker.manufacturerId ?
                                                    <Text weight="bold">{this.state.tracker.manufacturer.name}</Text>
                                                    :
                                                    <Box>
                                                        <Box height="25px" width="50px" data-for="manu_data_tip" data-tip></Box>
                                                        <ReactTooltip id="manu_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                            <span>No data available</span>
                                                        </ReactTooltip>
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                        {this.state.tracker && this.state.tracker.class == "Asset" &&
                                            <Box width="20%">
                                                <Text size="14px" margin={{ "bottom": "15px" }}>Model</Text>
                                                {this.state.tracker.modelId ?
                                                    <Text weight="bold">{this.state.tracker.model.name}</Text>
                                                    :
                                                    <Box>
                                                        <Box height="25px" width="50px" data-for="model_data_tip" data-tip></Box>
                                                        <ReactTooltip id="model_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                            <span>No data available</span>
                                                        </ReactTooltip>
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                        {this.state.tracker && this.state.tracker.class == "Asset" &&
                                            <Box width="20%">
                                                <Text size="14px" margin={{ "bottom": "15px" }}>Model Type</Text>
                                                {this.state.tracker.deviceType ?
                                                    <Text weight="bold">{this.state.tracker.deviceType.name}</Text>
                                                    :
                                                    <Box>
                                                        <Box height="25px" width="50px" data-for="device_data_tip" data-tip></Box>
                                                        <ReactTooltip id="device_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                            <span>No data available</span>
                                                        </ReactTooltip>
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                    </Box>
                                    {/*<Box basis="1/3">
                                        <FormField label="Labels" height="100%">
                                            {assetdata.assetBeaconTags && assetdata.assetBeaconTags.length > 0 ?
                                                <Box direction="row" gap="small" pad={{ "left": "small", "right": "small", "top": "medium", "bottom": "xsmall" }} align="end" height="100%">
                                                    {assetdata.assetBeaconTags.map(t =>
                                                        <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                            {t.tag.name}
                                                        </Box>
                                                    )}
                                                </Box>
                                                :
                                                <TextInput value="No label available" readOnly disabled />
                                            }
                                        </FormField>
                                    </Box>*/}
                                </StyledBox>
                                <StyledBox style1 pad={{ "horizontal": "small", "vertical": "small" }}>
                                    <Box width="100%" margin={{ "top": "15px", "bottom": "15px" }}>
                                        <Text size="14px" margin={{ "bottom": "15px" }}>Labels</Text>
                                        {assetdata.assetBeaconTags && assetdata.assetBeaconTags.length > 0 ?
                                            <Box wrap direction="row" style={{ gap: "10px" }} pad={{ "right": "small", "bottom": "medium" }} align="end" height="100%">
                                                {assetdata.assetBeaconTags.map(t =>
                                                    <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                        {t.tag.name}
                                                    </Box>
                                                )}
                                            </Box>
                                            :
                                            <Box>
                                                <Box height="25px" width="50px" data-for="label_data_tip" data-tip></Box>
                                                <ReactTooltip id="label_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                    <span>No data available</span>
                                                </ReactTooltip>
                                            </Box>                                        }
                                    </Box>
                                </StyledBox>
                                <StyledBox style1 margin={{ bottom: "small" }}>
                                    <Tabs justify="start"
                                        activeIndex={this.state.showCurrent ? 0 : 1}
                                        onActive={idx => {
                                            let isCurrent = idx == 0 ? true : false;
                                            let mapID = isCurrent ? this.state.meridianData.map.id : assetdata.mapId;
                                            this.setState({ showCurrent: isCurrent, showLastKnown: !isCurrent }, () => setTimeout(() => this.renderMap(/*assetdata.mapId*/mapID), 500));
                                        }}>
                                        <Tab title="Current location">
                                            <Box pad={{ left: "small", right: "small", top: "small" }}>
                                                {this.state.meridianData && this.state.meridianData.map && this.state.meridianData.map.id ?
                                                    (<Box>
                                                        <Box direction="row" pad={{ "vertical": "small" }}>
                                                            <Box width="23%">
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Building</Text>
                                                                {this.state.meridianData.map.group_name ?
                                                                    <Text weight="bold">{this.state.meridianData.map.group_name}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="building_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="building_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%">
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Floor</Text>
                                                                {this.state.meridianData.map.name ?
                                                                    <Text weight="bold">{this.state.meridianData.map.name}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="floor_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="floor_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%">
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Zone</Text>
                                                                {this.state.currentZone ?
                                                                    <Text weight="bold">{this.state.currentZone}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="zone_tip" data-tip></Box>
                                                                        <ReactTooltip id="zone_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%">
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Last Heard</Text>
                                                                {this.state.syncTime ?
                                                                    <Text weight="bold">{this.state.syncTime}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="lastheard_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="lastheard_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }                                                            </Box>
                                                            <Box width="8%" align="center" alignSelf="center">
                                                                <Button plain icon={<Refresh color="#01a982" />} onClick={() => this.fetchMeridian()} />
                                                            </Box>
                                                        </Box>
                                                    </Box>)
                                                    :
                                                    (<Box align="center" alignContent="center" alignSelf="center">
                                                        <Heading style={{ "max-width": "none" }} level='3' >{constants.NOT_TRACEABLE}</Heading>
                                                    </Box>)}
                                            </Box>
                                        </Tab>
                                        <Tab title="Last known location">
                                            <Box pad={{ left: "small", right: "small", top: "small" }}>
                                                {this.state.meridianData && this.state.meridianData.map && this.state.meridianData.map.id && <ReactTooltip id="ttlastheard" place="top" type="dark" effect="solid" arrowColor="transparent">
                                                    <span>{constants.LAST_HEARD_TOOLTIP}</span>
                                                </ReactTooltip>}
                                                {assetdata.mapId ?
                                                    <Box>
                                                        <Box direction="row" pad={{ "vertical": "small" }}>
                                                            <Box width="23%" pad={{ right: "medium" }}>
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Building</Text>
                                                                {assetdata.map && assetdata.map.group ?
                                                                    <Text weight="bold">{assetdata.map.group.name}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="building_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="building_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%" pad={{ right: "medium" }}>
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Floor</Text>
                                                                {assetdata.map && assetdata.map.name ?
                                                                    <Text weight="bold">{assetdata.map.name}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="floor_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="floor_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%" pad={{ right: "medium" }}>
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Zone</Text>
                                                                {assetdata.zoneId && assetdata.zone ?
                                                                    <Text weight="bold">{assetdata.zone.name}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="zone_tip" data-tip></Box>
                                                                        <ReactTooltip id="zone_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="23%" data-for="ttlastheard" data-tip>
                                                                <Text size="14px" margin={{ "bottom": "10px" }}>Last Heard</Text>
                                                                {assetdata.timestamp ?
                                                                    <Text weight="bold">{this.formatDate(assetdata.timestamp)}</Text>
                                                                    :
                                                                    <Box>
                                                                        <Box height="24px" width="50px" data-for="lastheard_data_tip" data-tip></Box>
                                                                        <ReactTooltip id="lastheard_data_tip" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                            <span>No data available</span>
                                                                        </ReactTooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box width="8%" align="center" alignSelf="center">
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box align="center" alignContent="center" alignSelf="center">
                                                        <Heading level='3'>{constants.NO_LOCATION}</Heading>
                                                    </Box>
                                                }
                                            </Box>
                                        </Tab>
                                    </Tabs>
                                    {((this.state.meridianData && this.state.meridianData.map && this.state.meridianData.map.id && this.state.showCurrent) || (assetdata.mapId && this.state.showLastKnown)) && <Box pad={{ left: "small", right: "small", bottom: "small" }}>
                                        <Box border="all" pad="xsmall" background="#fafafa" style={{ borderRadius: "5px 5px 5px 5px"/*, boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)"*/ }}>
                                            <div id="meridian-map" className="map" />
                                        </Box>
                                    </Box>}
                                </StyledBox>

                                {/*<StyledBox style1 direction="row" margin={{ vertical: "small" }} height="90px">
                                <FormField label="Labels" width="100%" >
                                        {this.state.meridianData.tags.length > 0 ?
                                            <Box direction="row" gap="small" pad={{ "left": "small", "right": "small", "top": "medium", "bottom": "xsmall" }} align="end" height="100%">
                                                {this.state.meridianData.tags.map(t =>
                                                    <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                        {t.name}
                                                    </Box>
                                                )}
                                            </Box>
                                            :
                                            <TextInput value="No label available" />
                                        }
                                    </FormField>

                            </StyledBox>*/}

                                {/*<StyledBox style1 height="90px" pad={{ "horizontal": "small" }}>
                                        <FormField label="Description" >
                                            <TextInput value={this.state.meridianData.map.group ? this.state.meridianData.map.group_name : "No Building"} readOnly disabled />
                                        </FormField>
                                    </StyledBox>*/}
                            </Box>

                        </Tab>
                        {this.context.feature.find(f => f.name == "assetTrackingHistory").isEnabled && <Tab title="History">
                            <AssetHistoryListContainer
                                historydata={historydata}
                                locationId={this.props.sitedata.appID}
                                token={this.props.sitedata.appToken}
                                image={assetdata.imageUrl}
                                siteId={assetdata.locationId}
                                trackId={this.state.tracker.id}
                            />
                        </Tab>}
                        {this.context.feature.find(f => f.name == "wellness").isEnabled && <Tab title="Status">
                            <AssetStatusHistoryListTable historydata={historydata.history} />
                        </Tab>}
                    </Tabs>
                    {this.context.feature.find(f => f.name == "wellness").isEnabled && this.state.tabIndex == 0 &&
                        <Box id="status" style={{ position: "absolute", right: "0px", top: "9px" }}>
                            <Select placeholder={"Select Status"} value={stat} options={constants.ASSET_STATUS} labelKey="name" onChange={evt => this.updateStatus(evt)}>
                                {option =>
                                    <Box pad="small">
                                        {option.name}
                                    </Box>}
                            </Select>
                        </Box>
                    }
                    </Box>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}
const mapStateToProps = state => ({
    assetdata: state.selectedAssetReducer.assetdata,
    sitedata: state.selectedSiteReducer.sitedata,
    historydata: state.selectedAssetHistoryReducer.historydata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetAction, ...SiteAction, ...AssetHistoryAction, ...DepartmentAction, ...CollectionAction, }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(OrgAssetEditContainer);