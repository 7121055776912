import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class SuperUserListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (/*nro != 0 || !RB.isSuperUser()*/true) {
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2'>Users</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeSave label='Create Super User' alignSelf="center" onClick={this.props.handleNew} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper}>
                            {this.renderUsersTable(this.props)}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderUsersTable(props) {
        const COLUMNS = [
            {
                property: 'lcusername',
                header: 'Username',
                render: superusersdata => <Text>{superusersdata.username}</Text>
            },
            {
                property: 'lcemail',
                header: 'Email',
                render: superusersdata => <Text>{superusersdata.email}</Text>
            },
            {
                property: 'lcsuperuser',
                header: <Box justify="center" height="100%">Super User</Box>,
                render: superusersdata => <CheckBox readOnly checked={superusersdata.superUser} disabled />
            },
            {
                property: 'lcactive',
                header: <Box justify="center" height="100%">Active</Box>,
                render: superusersdata => <CheckBox readOnly checked={superusersdata.active} disabled />
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: superusersdata => <Text hidden>{superusersdata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: superusersdata => !RB.isSuperUser() ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, superusersdata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedUserIds.includes(superusersdata.id)} onClick={evt => props.handleEdit(evt, superusersdata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedUserIds.includes(superusersdata.id)} onClick={evt => props.handleDelete(evt, superusersdata.id)} icon={<Trash />} />
                    </Box>,
            },
        ];

        let DATA = [];

        if (props.usersdata[0] && props.usersdata[0].count != 0) {
            props.usersdata.forEach(ev => {
                DATA.push({
                    ...ev, lcusername: ev.username.toLowerCase(),
                    lcemail: ev.email.toLowerCase(),
                    lcactive: ev.active.toString(),
                    lcsuperuser: ev.superUser.toString()
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.username.toLowerCase().includes(search) ||
                d.email.toLowerCase().includes(search) ||
                ("active".includes(search) && d.active) ||
                ("super user".includes(search) && d.superUser)
            );
        }

        /*console.log('DATA:', DATA);*/

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail"
                }))}
                data={DATA}
                noLoadingMsg={this.props.usersdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
export default SuperUserListTable;
