import * as ActionType from './ActionType';
import CategoryApi from '../api/CategoryApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';
import initialState from '../reducer/initialState';


export const getCategoriesResponse = categoriesdata => ({
    type: ActionType.GET_CATEGORIES_RESPONSE,
    categoriesdata
});

const queuedGetAllCategories = queueApiCall(CategoryApi.getAllCategories);

export function getCategoriesAction(deleteStore = true) {
    return (dispatch) => {
        if (deleteStore) dispatch(getCategoriesResponse(initialState.categoriesReducer.categoriesdata));
        dispatch(ApiCallBeginAction());

        // return CategoryApi.getAllCategories()
        return queuedGetAllCategories()
            .then(categoriesdata => {
                dispatch(getCategoriesResponse(categoriesdata));
                return categoriesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewCategoryResponse = () => ({
    type: ActionType.ADD_NEW_CATEGORY_RESPONSE
});

export const updateExistingCategoryResponse = () => ({
    type: ActionType.UPDATE_EXISTING_CATEGORY_RESPONSE
});

export function saveCategoryAction(categorydraft) {

    categorydraft.listPageType = categorydraft.listPageType == "List" || categorydraft.listPageType == "Standard" ? "Standard" : "Parallax";

    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the category is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new category that is being added, therefore add it
        return CategoryApi.saveCategory(categorydraft)
            .then((response) => {
                if (categorydraft.id) {
                    dispatch(updateExistingCategoryResponse());
                } else {
                    dispatch(addNewCategoryResponse());
                }
                return response;
            }).then((response) => {
                //if (categorydraft.id == "0") {
                //    dispatch(getCategoryAction(categorydraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getCategoryResponse = categorydata => ({
    type: ActionType.GET_CATEGORY_RESPONSE,
    categorydata
});

export function getCategoryAction(categoryId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return CategoryApi.getCategory(categoryId)
            .then(categorydata => {
                dispatch(getCategoryResponse(categorydata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteCategoryResponse = () => ({
    type: ActionType.DELETE_CATEGORY_RESPONSE
});

const queuedDeleteCategory = queueApiCall(CategoryApi.deleteCategory);

export function deleteCategoryAction(categoryId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        // return CategoryApi.deleteCategory(categoryId)
        return queuedDeleteCategory(categoryId)
            .then((response) => {
                dispatch(deleteCategoryResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getCategoriesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}