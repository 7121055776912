import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PageAction from '../action/PageAction';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as LevelAction from '../action/LevelAction';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
// eslint-disable-next-line
import validator from 'validator';
import constants from '../constants';
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, Video, Youtube, PhoneVertical, CircleInformation, Trash, Upload, Secure, MapLocation, Slack } from 'grommet-icons';
import { Phone } from '../grommet-extras/PhoneIcon';
import { Yammer } from '../grommet-extras/YammerIcon';
import { RadioButton, ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import TextInputWithState from './common/TextInputWithState';
import { Grid, Box, Heading, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, Grommet } from 'grommet';
import CustomOption from './common/CustomOption';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactQuill, { Quill } from 'react-quill';
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register({ 'modules/htmlEditButton': htmlEditButton });
import ReactTooltip from "react-tooltip";
import { Deeplink } from './common/Deeplink';


var start = undefined;
var base64 = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none"
    }
});
const customTheme3 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
});

const boxStyle = { alignItems: "end", height: "16px" };
const textSize = "14px";
const boxHeight = "18px";

export class PageEditContainer extends React.Component {
    constructor(props) {
        super(props);
        this.stamp = Date.now().toString();
        this.categoryfull = [];
        this.parentfull = [];
        this.placemarkfull = [];
        this.moreinfo_err = [];
        for (let i = 1; i <= constants.MOREINFO_LINES; i += 1) this.moreinfo_err.push({ display: "", url: "" });
        this.newname = "";
        this.newparent = "";
        this.newparentId = "";
        this.newlocation = "";
        this.uploadErrorText = "";
        this.state = {
            detailChecked: false,
            webChecked: false,
            options: [],
            options_2: constants.MODE,
            parents: [],
            placemarks: [],
            value: "",
            parent: "",
            placemarkname: "",
            isNewCategory: false,
            isSaved: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            isUploadError: false,
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            HTMLTextLenght: 0,
            categoriesdataLoading: true,
            locationsdataLoading: true,
            placemarksdataLoading: true,
            levelsdataLoading: true,
            pagedataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            firstRun: true,
            categoryLocation: "",
            coordinates: ""
        };
        this.types = [{ name: "Detail Page", type: "Detail" }, { name: "HTML Page", type: "Html" }, { name: "Web Page", type: "Web" }, { name: "Maps Link", type: "Maps" }];
    }

    launchEnumOptions = [
        { label: 'External Browser', value: "External" },
        { label: 'Hosted In App', value: "InApp" },
        { label: 'In App Browser', value: "InAppBrowser" },
    ];

    editorURLs = [];

    pageType_err = "";
    newname_err = "";
    parent_err = "";
    name_err = "";
    detail_err = "";
    description_err = "";
    categoryid_err = "";
    placemarkid_err = "";
    email_err = "";
    phone_err = '';
    phone_help = '';
    twitter_err = '';
    linkedin_err = '';
    yammer_err = '';
    facebook_err = '';
    url_err = "";
    instagram_err = '';
    video_err = '';
    youtube_err = '';
    weburl_err = "";
    mode_err = "";
    image_err = "";
    orientation_err = "";
    slack_err = "";
    map_err = "";

    clearErrorMessages() {
        this.pageType_err = "";
        this.newname_err = "";
        this.parent_err = "";
        this.name_err = "";
        this.detail_err = "";
        this.description_err = "";
        this.categoryid_err = "";
        this.placemarkid_err = "";
        this.email_err = "";
        this.phone_err = '';
        this.phone_help = '';
        this.twitter_err = '';
        this.linkedin_err = '';
        this.yammer_err = '';
        this.facebook_err = '';
        this.url_err = "";
        this.instagram_err = '';
        this.video_err = '';
        this.youtube_err = '';
        this.weburl_err = "";
        this.mode_err = "";
        this.image_err = "";
        this.orientation_err = "";
        this.slack_err = "";
        this.map_err = "";
        for (let i = 0; i <= this.moreinfo_err.length - 1; i += 1) {
            this.moreinfo_err[i].display = '';
            this.moreinfo_err[i].url = '';
        }
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === 'string') {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    onUploadClose() {
        this.setState({ isUploadError: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.categoriesdata && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
            //console.log(this.props.categoriesdata);
            this.categoryfull = this.props.categoriesdata.slice();
            //this.categoryfull = this.categoryfull.filter(item => item.level == 2);

            this.categoryfull.push({
                id: "0",
                name: "",
                parent: "",
                location: "",
                lpn: "",
                synced: false
            });
            var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
            var spi = this.categoryfull.indexOf(sp);
            this.categoryfull.splice(spi, 1);
            var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
            var i = this.categoryfull.indexOf(ri);
            this.categoryfull.splice(i, 1);
            var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
            var index = this.categoryfull.indexOf(info);
            this.categoryfull.splice(index, 1);
            var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
            var i2 = this.categoryfull.indexOf(ri2);
            this.categoryfull.splice(i2, 1);
            var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
            var index2 = this.categoryfull.indexOf(info2);
            this.categoryfull.splice(index2, 1);
            this.categoryfull.forEach(item => item.lpn = constants.APP_LPN(item.location, item.parent, item.name));
            this.categoryfull.sort((p1, p2) => { if (p1.lpn.toLowerCase() < p2.lpn.toLowerCase()) return -1; return 1; });
            //this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.categoryfull });
            //console.log("DidUpdate:", this.categoryfull);
        }
        //Load parent-location dictionary
        if (this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
            //console.log(this.props.locationsdata);
            this.parentfull = this.props.locationsdata.filter((item) => item.location != "").slice();
            this.parentfull.push({
                parent: "",
                location: ""
            });
            this.parentfull.forEach(function (item) {
                if (item.parent == "" && item.location == "") {
                    item.parentloc = "";
                }
                else if (item.parent != "" && item.location == "") {
                    item.parentloc = item.parent;
                }
                else if (item.parent == item.location) {
                    item.parentloc = item.location;
                }
                else {
                    item.parentloc = item.location + " / " + item.parent;
                }

            })
            this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
            this.setState({ parents: this.parentfull });
            //console.log("DidUpdate:", this.parentfull);
        }
        //Load parent-location dictionary
        if (this.props.placemarksdata[0] && this.props.placemarksdata[0].count != 0 && this.props.placemarksdata.length > 0 && this.placemarkfull.length == 0) {
            //console.log(this.props.placemarksdata);
            this.placemarkfull = this.props.placemarksdata.slice();
            this.placemarkfull.push({
                id: "",
                name: ""
            });
            this.placemarkfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ placemarks: this.placemarkfull });
            //console.log("DidUpdate:", this.placemarkfull);
        }
        if (this.props.pagedata && this.props.pagedata.type == "None" && this.props.location.state && this.props.location.state.type) {
            this.props.pagedata.type = this.props.location.state.type;
        }
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.pageId;
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PageEdit/Category Error', error);
            });
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PagekEdit/Parent Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PagekEdit/Placemark Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getPageAction(id)
            .then(response => this.setState({ pagedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PageEdit Error', error);
            });
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.pagedata && !_.isEqual(start, this.props.pagedata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    //updateDetailPageValue(v) { this.props.pagedata.detailpage = v.target.checked };
    //updateWebPageValue(v) { this.props.pagedata.webpage = v.target.checked };
    updateType(v, type) {
        this.clearErrorMessages();
        if (v.target.checked) {
            this.props.pagedata.type = type;

        }
        else {
            this.props.pagedata.type = "None";
        }
        this.setState({ updated: true });
    }

    updateCategoryName(event) {
        this.props.pagedata.categoryid = this.state.options[event.selected].id;
        this.setState({
            value: event.option,
            selected: event.selected,
            options: this.categoryfull,
            categoryLocation: event.option
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.pagedata.categoryid = (this.categoryfull.find((option) => option.name == event.option)).id;
        if (this.props.pagedata.category) {
            this.props.pagedata.category.id = this.props.pagedata.categoryid;
        };
        this.categoryid_err = "";
        //if (event.option.length == 0) {
        //    this.categoryid_err = "Category must be selected";
        //}
    }
    updateNewCategoryName(event) {
        //this.props.pagedata.category.name = event.target.value;
        this.newname = event.target.value;
    }

    updateCategoryParent(event) {
        if (event.option == "No match found") {
            event.option = "";
        }
        this.newparent = this.parentfull[event.selected].parent;
        this.newparentId = this.parentfull[event.selected].id;
        this.newlocation = this.parentfull[event.selected].location;
        this.setState({
            parent: event.option,
            parents: this.parentfull
        });

        //this.newparent = (this.parentfull.find((option) => option.parentloc == event.option)).parent;
        //this.newparentId = (this.parentfull.find((option) => option.parentloc == event.option)).id
        //this.newlocation = (this.parentfull.find((option) => option.parentloc == event.option)).location;
        this.parent_err = "";
        //if (event.option.trim().length == 0) {
        //    this.parent_err = "Select an Option";
        //}
    }
    updatePlacemark(event) {
        this.props.pagedata.placemarkid = event.value.id;
        this.setState({
            placemarks: this.placemarkfull
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.pagedata.placemarkid = this.placemarkfull.find((o) => o.name == event.option).id;

        this.placemarkid_err = "";
        if (event.option.name.trim().length == 0) {
            this.placemarkid_err = "Select an Option";
        }
    }

    updateLocation(event) {
        this.props.pagedata.category.location = event.target.value;
    }

    updatePageNameValue(v) {
        this.props.pagedata.name = v.target.value;
        if (v.target.value.length > 50) {
            this.name_err = "Name can't be longer than 50 characters";
        }
        else {
            this.name_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateDescriptionValue(v) {
        this.props.pagedata.description = v.target.value;
        if (v.target.value.length > 60) {
            this.description_err = "Description can't be longer than 60 characters"
        }
        else {
            this.description_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    //updateDetailValue(v) {
    //    this.props.pagedata.detail = v.target.value
    //    if (v.target.value.length > 2000) {
    //        this.detail_err = "Detail can't be longer than 2000 characters";
    //    }
    //    else {
    //        this.detail_err = "";
    //    }
    //    this.setState({ validating: !this.state.validating });
    //};

    updateImageValue(v, nro) {
        v.preventDefault();
        this.props.pagedata.image = nro;
        if (nro == -1) {
            this.props.pagedata.placemarkid = null;
        }
        this.setState({ selimage: nro });
    };

    updateWebUrlValue(evt) {
        this.props.pagedata.weburl = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.weburl_err = "URL can't be longer than 1024 characters";;
        }
        else {
            this.weburl_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateAuthStatus(evt, idx) {
        this.props.pagedata.moreinfo[idx].urlAuth = !this.props.pagedata.moreinfo[idx].urlAuth;
        this.setState({ updated: true });
    };

    updateLaunchMode(evt, idx) {
        this.props.pagedata.moreinfo[idx].urlType = this.launchEnumOptions.find(e => e.label == evt.option).value;
        this.setState({ updated: true });
    };

    updateAuthStatusWeb(evt, idx) {
        this.props.pagedata.webUrlAuth = !this.props.pagedata.webUrlAuth;
        this.setState({ updated: true });
    };

    updateDisplayTypeWeb(evt) {
        this.props.pagedata.weburlType = this.launchEnumOptions.find(e => e.label == evt.option).value;
        this.setState({ updated: true });
    };

    //updateModeValue(evt) {
    //    //this.props.pagedata.inApp = evt.option
    //    this.setState({ value_2: evt.option });
    //    if (evt.option == "Open in App") {
    //        this.props.pagedata.inApp = true;
    //    }
    //    else {
    //        this.props.pagedata.inApp = false;
    //    }
    //    this.mode_err = "";
    //};
    //UpdatePlacemark

    updateEmailValue(evt) { this.props.pagedata.email = evt.target.value };
    updatePhoneValue(evt) { this.props.pagedata.phone = evt.target.value };
    updateTwitterValue(evt) {
        this.props.pagedata.twitter = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.twitter_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.twitter_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateLinkedinValue(evt) {
        this.props.pagedata.linkedin = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.linkedin_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateYammerValue(evt) {
        this.props.pagedata.yammer = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.yammer_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.yammer_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateFacebookValue(evt) {
        this.props.pagedata.facebook = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.facebook_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.facebook_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateUrlValue(evt) {
        this.props.pagedata.url = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.url_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.url_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateInstagramValue(evt) {
        this.props.pagedata.instagram = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.instagram_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.instagram_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateVideoValue(evt) {
        this.props.pagedata.video = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.video_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.video_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateYoutubeValue(evt) {
        this.props.pagedata.youtube = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.youtube_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.youtube_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateMapValue(evt) {
        var coordinates = evt.target.value;
        if (coordinates != "") {
            if (coordinates != "" && !constants.REGEX.COORDINATES.test(coordinates)) {
                this.map_err = "Please ensure the coordinates are properly formed (ie: 53.2926521, -9.007333)";
            }
            else if (coordinates != "" && coordinates.length > 50) {
                this.map_err = constants.COORDINATES_LIMIT;
            }
            else {
                this.map_err = "";

                let lat = coordinates.substr(0, evt.target.value.indexOf(','));
                let p = lat.indexOf(".");
                if (p >= 0) {
                    let n = lat.substr(p + 1, lat.length).length;
                    lat = Number.parseFloat(lat).toFixed(n);
                }

                let lon = coordinates.split(" ")[1];
                let p2 = lon.indexOf(".");
                if (p2 >= 0) {
                    let n = lon.substr(p2 + 1, lon.length).length;
                    lon = Number.parseFloat(lon).toFixed(n);
                }

                this.props.pagedata.latitude = lat;
                this.props.pagedata.longitude = lon;
            }
        }
        else {
            this.map_err = "";
            this.props.pagedata.latitude = null;
            this.props.pagedata.longitude = null;
        }
        this.setState({ coordinates: coordinates });
    }
    updateSlackValue(evt) {
        this.props.pagedata.slack = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.slack_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.slack_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateMoreInfoDisplayValue(evt, idx) {
        this.props.pagedata.moreinfo[idx].display = evt.target.value;
        if (evt.target.value.length > 30) {
            this.moreinfo_err[idx].display = "Dispay text can't be longer than 30 characters";
        }
        else {
            this.moreinfo_err[idx].display = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateMoreInfoUrlValue(evt, idx) {
        this.props.pagedata.moreinfo[idx].url = evt.target.value;
        if (this.props.pagedata.moreinfo[idx].url.length > 1024) {
            this.moreinfo_err[idx].url = constants.MORE_INFO_URL_ERR;
        }
        else this.moreinfo_err[idx].url = "";
        this.setState({ updated: true });
    }
    handleAddItem(event) {
        event.preventDefault();
        this.setState({ validating: false });
        if (!this.props.pagedata.moreinfo) {
            this.props.pagedata.moreinfo = [];
        }
        this.props.pagedata.moreinfo.push({ display: "", url: "" });
        this.setState({ validating: true });
    }


    handleCancel() {
        if (this.props.pagedata.moreinfo) {
            this.props.pagedata.moreinfo = this.props.pagedata.moreinfo.filter(info => info.display.trim().length != 0 || info.url.trim().length != 0);
        }
        this.saveNewCategory(false);
        console.log("cancel", this.props.pagedata);
        if (!this.state.cancel && !_.isEqual(start, this.props.pagedata)) {
            this.setState({ cancel: true });
        }
        else {
            const pageId = this.props.pagedata.id;
            this.props.action.getPageAction(pageId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/pages');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-PageEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/pages');
                });
        }
    }
    handleSave() {
        const pagedefault = this.props.pagedata;
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });
        //BOTH PAGE
        if (pagedefault.name.trim().length == 0) {
            errorsno++;
            this.name_err = "Name is required";
        }
        if (pagedefault.name.length > 50) {
            errorsno++;
            this.name_err = "Name can't be longer than 50 characters";
        }
        if (pagedefault.description.length > 60) {
            errorsno++;
            this.description_err = "Description can't be longer than 60 characters";
        }

        //newCategory
        if (this.state.isNewCategory) {
            if (this.newname.trim().length == 0) {
                errorsno++;
                this.newname_err = "Category Name is required"
            }
            if (this.props.categoriesdata.find(o =>
                o.name.trim().toLowerCase() == this.newname.trim().toLowerCase() &&
                o.parent.trim().toLowerCase() == this.newparent.trim().toLowerCase() &&
                o.location.trim().toLowerCase() == this.newlocation.trim().toLowerCase()
            )) {
                errorsno++;
                this.newname_err = "Category Name already exists";
            }
            //if (this.newparent.trim().length == 0) {
            //    errorsno++;
            //    this.parent_err = "Select an Option";
            //}
        }
        else {
            //if (pagedefault.categoryid == 0 || !this.categoryfull.find((opt) => opt.id == pagedefault.categoryid)) {
            //    errorsno++;
            //    this.categoryid_err = "Select an option";
            //}
        }

        if (pagedefault.type == "Detail") {
            //DETAIL PAGE
            if (pagedefault.detail.includes("http://")) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
            for (var i = 0; i < this.editorURLs.length; i++) {
                if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY) && !this.editorURLs[i].startsWith(this.props.organisationdata.appURI.split(':')[0])) {
                    errorsno++;
                    this.detail_err = constants.DETAIL_HTTP_ERROR;
                }
            }
            if (this.state.HTMLTextLenght > 10000) {
                errorsno++;
                this.detail_err = "Detail can't be longer than 10000 characters";
            }
            //if (pagedefault.placemarkid == "" && pagedefault.image != -1) {
            //    errorsno++;
            //    this.placemarkid_err = "Select an Option";
            //}
            if (pagedefault.email) {
                let emails = pagedefault.email.split(",");
                if (emails.some(email => email.trim().length == 0 || !(validator.isEmail(email)))) {
                    errorsno++;
                    this.email_err = "E-mail is not correct";
                }
            }
            if (!pagedefault.phone) {
                pagedefault.phone = "";
            }
            if (pagedefault.phone.trim().length != 0 && !(constants.REGEX.PHONE.test(pagedefault.phone))) {
                errorsno++;
                this.phone_err = constants.PHONE_ERROR;
                this.phone_help = constants.PHONE_HELP;
            }
            if (pagedefault.twitter) {
                if (pagedefault.twitter.trim().length != 0 && !(validator.isURL(pagedefault.twitter, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.twitter_err = constants.URL_ERROR;
                }
                if (pagedefault.twitter.length > 1024) {
                    errorsno++;
                    this.twitter_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.linkedin) {
                if (pagedefault.linkedin.trim().length != 0 && !(validator.isURL(pagedefault.linkedin, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.linkedin_err = constants.URL_ERROR;
                }
                if (pagedefault.linkedin.length > 1024) {
                    errorsno++;
                    this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.yammer) {
                if (pagedefault.yammer.trim().length != 0 && !(validator.isURL(pagedefault.yammer, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.yammer_err = constants.URL_ERROR;
                }
                if (pagedefault.yammer.length > 1024) {
                    errorsno++;
                    this.yammer_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.facebook) {
                if (pagedefault.facebook.trim().length != 0 && !(validator.isURL(pagedefault.facebook, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.facebook_err = constants.URL_ERROR;
                }
                if (pagedefault.facebook.length > 1024) {
                    errorsno++;
                    this.facebook_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.url) {
                if (pagedefault.url.trim().length != 0 && !(validator.isURL(pagedefault.url, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.url_err = constants.URL_ERROR;
                }
                if (pagedefault.url.length > 1024) {
                    errorsno++;
                    this.url_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.instagram) {
                if (pagedefault.instagram.trim().length != 0 && !(validator.isURL(pagedefault.instagram, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.instagram_err = constants.URL_ERROR;
                }
                if (pagedefault.instagram.length > 1024) {
                    errorsno++;
                    this.instagram_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.video) {
                if (pagedefault.video.trim().length != 0 && !(validator.isURL(pagedefault.video, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.video_err = constants.URL_ERROR;
                }
                if (pagedefault.video.length > 1024) {
                    errorsno++;
                    this.video_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.youtube) {
                if (pagedefault.youtube.trim().length != 0 && !(validator.isURL(pagedefault.youtube, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.youtube_err = constants.URL_ERROR;
                }
                if (pagedefault.youtube.length > 1024) {
                    errorsno++;
                    this.youtube_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (pagedefault.slack) {
                if (pagedefault.slack.trim().length != 0 && !(validator.isURL(pagedefault.slack, constants.URL_OPTIONS))) {
                    errorsno++;
                    this.slack_err = constants.URL_ERROR;
                }
                if (pagedefault.slack.length > 1024) {
                    errorsno++;
                    this.slack_err = constants.CONNECT_NOW_URL_ERR;
                }
            }
            if (this.state.coordinates != "" && !(constants.REGEX.COORDINATES.test(this.state.coordinates))) {
                errorsno++;
                this.map_err = constants.COORDINATES_ERROR;
            }
            else if (this.state.coordinates != "" && this.state.coordinates.length > 50) {
                errorsno++;
                this.map_err = constants.COORDINATES_LIMIT;
            }
            // more info validating 
            if (pagedefault.moreinfo) {
                pagedefault.moreinfo.map((info, idx) => {
                    // soft-deleted lines not validating
                    if (!info.deleted) {
                        if (info.display.trim().length == 0 && info.url.trim().length == 0) {
                            //OK, Save
                        }
                        else {
                            if (info.display.trim().length == 0) {
                                errorsno++;
                                this.moreinfo_err[idx].display = "Display Text is required";
                            }
                            if (info.display.length > 30) {
                                errorsno++;
                                this.moreinfo_err[idx].display = "Dispay text can't be longer than 30 characters";
                            }

                            if (info.url.substr(info.url.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(info.url) && !(this.props.organisationdata.contentUriEnabled && info.url.startsWith(this.props.organisationdata.appURI))) {
                                errorsno++;
                                this.moreinfo_err[idx].url = constants.URL_ERROR;
                            }
                            if (info.url.substr(info.url.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(info.url)) {
                                errorsno++;
                                this.moreinfo_err[idx].url = constants.URL_ERROR_HTTPS_ONLY;
                            }
                            if (info.url.length > 1024) {
                                errorsno++;
                                this.moreinfo_err[idx].url = constants.MORE_INFO_URL_ERR;
                            }
                        }
                    }
                });
                pagedefault.moreinfo = pagedefault.moreinfo.filter(info => info.display.trim().length != 0 || info.url.trim().length != 0);
            }
        }
        else if (pagedefault.type == "Web") {
            //WEB PAGE
            if (pagedefault.weburl.trim().length == 0) {
                errorsno++;
                this.weburl_err = "URL is required";
            }
            if (pagedefault.weburl.trim().length !== 0 && pagedefault.weburl.substr(pagedefault.weburl.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(pagedefault.weburl)) {
                errorsno++;
                this.weburl_err = constants.URL_ERROR;
            }
            if (pagedefault.weburl.trim().length !== 0 && pagedefault.weburl.substr(pagedefault.weburl.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(pagedefault.weburl)) {
                errorsno++;
                this.weburl_err = constants.URL_ERROR_HTTPS_ONLY;
            }
            if (pagedefault.weburl.length > 1024) {
                errorsno++;
                this.weburl_err = "URL can't be longer than 1024 characters";
            }
        }
        else if (pagedefault.type == "Html") {
            //HTML PAGE
            if (pagedefault.detail.includes("http://")) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
            //for (var i = 0; i < this.editorURLs.length; i++) {
            //    if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY)) {
            //        errorsno++;
            //        this.detail_err = constants.DETAIL_HTTP_ERROR;
            //    }
            //}
            if (this.state.HTMLTextLenght > 10000) {
                errorsno++;
                this.detail_err = "Detail can't be longer than 10000 characters";
            }
        }
        else if (pagedefault.type == "Maps") {
            if (this.state.coordinates != "" && !(constants.REGEX.COORDINATES.test(this.state.coordinates))) {
                errorsno++;
                this.map_err = constants.COORDINATES_ERROR;
            }
            else if (this.state.coordinates != "" && this.state.coordinates.length > 50) {
                errorsno++;
                this.map_err = constants.COORDINATES_LIMIT;
            }
        }
        else {
            errorsno++;
            this.pageType_err = "Please select a page type first";
        }

        if (errorsno == 0) {
            //Ok, saving data
            if (this.state.newimage) {
                this.props.pagedata.imageContent = base64;
                base64 = null;
            }

            this.setState({ disabledButton: true });
            this.saveNewCategory(true);
            //sanitize fields depending on type
            //this.saveType();
            let locId = '';
            if (sessionStorage.getItem('locationId')) {
                locId = sessionStorage.getItem('locationId');
            }
            pagedefault.locationId = locId;
            console.log('Saving:', pagedefault);
            this.props.action.savePageAction(pagedefault)
                .then((response) => {
                    this.setState({ isSaveSuccessful: response.ok, isSaved: true });
                    this.categoryfull = [];
                    this.props.action.getCategoriesAction().then(() => { /*this.props.history.push('/pages')*/ });
                    //this.props.history.push('/pages');
                }).catch(error => {
                    console.log('*DEBUG*-Save PagesEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }
    saveNewCategory(isFromHandleSave) {
        const pagedefault = this.props.pagedata;
        if (this.state.isNewCategory) {
            // new category
            pagedefault.category = {};
            pagedefault.categoryid = "0";
            pagedefault.category.id = null;
            pagedefault.category.name = this.newname;
            pagedefault.category.parent = this.newparent;
            pagedefault.category.parentId = this.newparentId;
            pagedefault.category.location = this.newlocation;
            pagedefault.category.synced = false;
            pagedefault.category.deleted = false;
            pagedefault.category.locationId = sessionStorage.getItem("locationId");
            pagedefault.category.organizationId = sessionStorage.getItem("organisationId");
        } else {
            // selected category
            if (isFromHandleSave) {
                pagedefault.category = null;
            }

        }
    }
    saveType() {
        const pagedefault = this.props.pagedata;
        if (pagedefault.type == "Detail") {
            pagedefault.weburl = "";
        }
        if (pagedefault.type == "Web") {
            pagedefault.email = "";
            pagedefault.facebook = "";
            pagedefault.instagram = "";
            pagedefault.linkedin = "";
            pagedefault.phone = "";
            pagedefault.twitter = "";
            pagedefault.url = "";
            pagedefault.yammer = "";
            pagedefault.image = 0;
            pagedefault.moreinfo = [];
        }
    }

    handleDelete(event, id) {
        this.props.pagedata.moreinfo.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }

    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.pagedata.imageUrl = null;
        this.props.pagedata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }

    renderMoreInfo(infos) {
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        return (
            <Box gap="medium">
                {infos && infos.map((info, idx) => (
                    <Box direction="row" gap="small">
                        <StyledBox style1 key={idx} direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }}>
                            <Box width="250px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].display} label={<Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box>}>
                                        <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            <Box width="400px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].url} label={<Box direction="row" gap="xsmall" align="center"><Link />URL</Box>}>
                                        <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => this.updateMoreInfoUrlValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            {info.url.trim().substr(info.url.trim().length - 4).toLowerCase() == ".pdf" ?
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <Secure />
                                                Authentication Status:
                                            </Box>
                                            <Box>
                                                <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here .">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={["Authenticated", "Not Authenticated"]}
                                            value={info.urlAuth ? "Authenticated" : "Not Authenticated"}
                                            onChange={evt => this.updateAuthStatus(evt, idx)} />
                                    </FormField>
                                </Box>
                                :
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <PhoneVertical />
                                                Launch Mode:
                                            </Box>
                                            <Box>
                                                <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={this.launchEnumOptions.map(e => e.label)}
                                            defaultValue={!info.urlType ? info.urlType = "InAppBrowser" : ""}
                                            value={this.launchEnumOptions.find(e => e.value == info.urlType) ? this.launchEnumOptions.find(e => e.value == info.urlType).label : ""}
                                            onChange={evt => this.updateLaunchMode(evt, idx)} />
                                    </FormField>
                                </Box>}
                            <Box alignSelf="center" margin={{ "right": "small" }} >
                                {canedit1 &&
                                    <StyledButton icon={<Trash />} plain onClick={evt => this.handleDelete(evt, idx)} disabled={!canedit1} />}
                            </Box>
                        </StyledBox>
                    </Box>
                ))}
                <Box direction="row" gap="small">
                    <Box width="medium">
                        {canedit1 &&
                            <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={this.handleAddItem.bind(this)} disabled={!canedit1} />}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderMoreInfoV2(infos) {
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        return (
            <Box gap="medium" width="fit-content">
                {infos.length > 0 && <StyledBox style1 gap="small">
                    {infos && infos.map((info, idx) => (
                        <Box key={idx} direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }}>
                            <Box width="250px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].display} label={idx == 0 ? <Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box> : ""}>
                                        <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            <Box width="400px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].url} label={idx == 0 ? <Box direction="row" gap="xsmall" align="center"><Link />URL</Box> : ""}>
                                        <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => this.updateMoreInfoUrlValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            {info.url.trim().substr(info.url.trim().length - 4).toLowerCase() == ".pdf" ?
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        idx == 0 ?
                                            <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                <Box direction="row" gap="xsmall" justify="between">
                                                    <Secure />
                                                    Authentication Status:
                                                </Box>
                                                <Box>
                                                    <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here .">
                                                        <CircleInformation />
                                                    </a>
                                                    <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </Box> : ""}>
                                        <Select
                                            options={["Authenticated", "Not Authenticated"]}
                                            value={info.urlAuth ? "Authenticated" : "Not Authenticated"}
                                            onChange={evt => this.updateAuthStatus(evt, idx)} />
                                    </FormField>
                                </Box>
                                :
                                <Box direction="row" width="200px" style={{ display: this.props.organisationdata.contentUriEnabled && info.url.trim().startsWith(this.props.organisationdata.appURI) ? "none" : "initial" }}>
                                    <FormField label={
                                        idx == 0 ?
                                            <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                <Box direction="row" gap="xsmall" justify="between">
                                                    <PhoneVertical />
                                                    Launch Mode:
                                                </Box>
                                                <Box>
                                                    <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                        <CircleInformation />
                                                    </a>
                                                    <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </Box> : ""}>
                                        <Select
                                            options={this.launchEnumOptions.map(e => e.label)}
                                            defaultValue={!info.urlType ? info.urlType = "InAppBrowser" : ""}
                                            value={this.launchEnumOptions.find(e => e.value == info.urlType) ? this.launchEnumOptions.find(e => e.value == info.urlType).label : ""}
                                            onChange={evt => this.updateLaunchMode(evt, idx)} />
                                    </FormField>
                                </Box>}
                            <Box alignSelf={idx == 0 ? "center" : ""} margin={{ "right": "small" }} >
                                {canedit1 &&
                                    <StyledButton icon={<Trash />} plain onClick={evt => this.handleDelete(evt, idx)} disabled={!canedit1} />}
                            </Box>
                        </Box>

                    ))}
                </StyledBox>
                }
                <Box direction="row" gap="small">
                    <Box width="medium">
                        {canedit1 &&
                            <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={this.handleAddItem.bind(this)} disabled={!canedit1} />}
                    </Box>
                </Box>
            </Box>
        )
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/pages');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        ////this.setState({ cancel: false });
        //const pageId = this.props.pagedata.id;
        ////console.log('Restoring:', eventId);
        //console.log("disc:", this.props.pagedata);
        //this.props.action.getPagesAction(pageId)
        //    .then(() => {
        //        this.props.history.push('/pages');
        //    }).catch(error => {
        //        console.log('*DEBUG*-PageEdit Error', error);
        //        this.props.history.push('/pages');
        //    });
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.pagedata.detail = editor.getHTML();
        if (editor.getLength() - 1 > 10000) {
            this.detail_err = "Details can't be longer than 10000 characters";
        }
        else {
            this.detail_err = "";
        }
        this.setState({ HTMLTextLenght: editor.getLength() - 1 });
        //console.log("HTML_EDITOR", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getText()); // plain text
        //console.log("HTML_EDITOR", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }
    renderCustomInputs(num = 4) {
        return null;
        return (
            <Box>
                <Box direction="row" gap="small">
                    <Text size="large">Custom Attributes</Text>
                    <Box data-for="custom_info" data-tip>
                        <CircleInformation />
                        <ReactTooltip id="custom_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                            <span>Set attributes here to allow custom integrations.</span>
                        </ReactTooltip>
                    </Box>
                </Box>
                <StyledBox style1 pad="small">
                    <Grid columns={{ count: num, size: "auto" }} gap="small">
                        {[...Array(num)].map((e, i) =>
                            <FormField label={`Custom Attribute ${i + 1}`}>
                                <TextInput id={i} defaultValue={this.props.pagedata[`custom${i + 1}`]} onChange={evt => this.props.pagedata[`custom${i + 1}`] = evt.target.value} />
                            </FormField>
                        )}
                    </Grid>
                </StyledBox>
            </Box>
        );
    }

    render() {
        const { options, isNewCategory, parent, parents, placemarks, categoriesdataLoading, locationsdataLoading, placemarksdataLoading, levelsdataLoading, pagedataLoading, organisationdataLoading } = this.state;
        const pagedefault = this.props.pagedata;
        const levelsdata = this.props.levelsdata;
        const categoriesdefault = this.props.categoriesdata;
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        if (categoriesdataLoading || locationsdataLoading || placemarksdataLoading || pagedataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        //console.log(pagedefault);
        const heading = pagedefault && (pagedefault.id != "0") ? 'Edit' : 'Create';
        let type = pagedefault && pagedefault.type != "None" ? pagedefault.type + " " : "";
        //console.log("PD:", pagedefault);
        //console.log('EDIT:', JSON.stringify(pagedefault));
        //console.log('EDIT2:', JSON.stringify(categoriesdefault));

        //Check
        //const vcategory = categoriesdefault.find((opt) => opt.id == pagedefault.categoryid);
        const vcategory = this.categoryfull.find((opt) => opt.id == pagedefault.categoryid);
        const vcategoryname = vcategory ? vcategory.name : "";
        const vcategoryparent = vcategory ? vcategory.parent : "";
        const vcategorylocation = vcategory ? vcategory.location : "";
        //const vcategoryparentpluslocation = vcategoryparent && vcategorylocation ? vcategorylocation + " / " + vcategoryparent: "";
        const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        this.state.firstRun ? this.setState({ firstRun: false, categoryLocation: vcategoryparentpluslocation }) : null;
        //console.log('EDIT:', pagedefault.categoryid, JSON.stringify(vcategory));

        const vplacemark = this.placemarkfull.find((o) => o.id == pagedefault.placemarkid);
        const vplacemarkname = vplacemark ? vplacemark.name : "";

        if (pagedefault.landscape) {
            pagedefault.weburlType = 'InApp';
        }

        if (this.props.organisationdata) {
            Quill.import('formats/link').PROTOCOL_WHITELIST = ["http", "https", this.props.organisationdata.appURI.split(':')[0], "mailto"];
        }

        const isDeepLinkURLAvailable = this.props.organisationdata.appURI && this.props.organisationdata.contentUriEnabled && pagedefault.id != "0";

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' > {type}Page - {heading} </Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label={constants.visible(canedit1) == "visible" ? "Cancel" : "Back"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {constants.visible(canedit1) == "visible" &&
                            <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUp open={this.state.isUploadError} onClose={() => this.onUploadClose()} text={this.uploadErrorText} header="File Upload Error" error={this.state.isUploadError} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The image associated with this page will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                    <form>
                        {pagedefault.id == "0" && <Box margin={{ "bottom": "medium" }} gap="small">
                            <Box width="fit-content">
                                <Select
                                    placeholder="Select Page Type"
                                    labelKey="name"
                                    options={this.types}
                                    disabled={pagedefault.id != "0"}
                                    //valueKey="type"
                                    value={this.types.find(t => t.type == pagedefault.type)}
                                    onChange={evt => { this.clearErrorMessages(); this.props.pagedata.type = evt.value.type; this.setState({ updated: true }); }}
                                />
                            </Box>
                            {this.pageType_err != "" && <Text color="red">{this.pageType_err}</Text>}
                        </Box>}

                        {pagedefault.type != "None" &&
                            <form>
                                <Box margin={{ "bottom": "medium" }}>

                                    {/*Detail Page only*/}

                                    {pagedefault.type == "Detail" &&
                                        <Box gap="medium">
                                            {/*Page Type*/}
                                            {/*<Box direction="row">*/}
                                            {/*    <StyledBox style1 flex="shrink" direction="row" gap="xlarge" pad={{ "vertical": "medium", "horizontal": "small" }}>*/}
                                            {/*        <CheckBox*/}
                                            {/*            label="Detail Page"*/}
                                            {/*            checked*/}
                                            {/*            disabled={heading == "Edit"}*/}
                                            {/*            onChange={v => this.updateType(v, "Detail")}*/}
                                            {/*        />*/}
                                            {/*    </StyledBox>*/}
                                            {/*</Box>*/}


                                            {/*Description*/}
                                            <Box>
                                                <Box>
                                                    <Text size="large">Content Details</Text>
                                                    <Text>All fields marked with an * are mandatory.</Text>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                    <FormField label="Name *" error={this.name_err}>
                                                        <TextInput defaultValue={pagedefault.name} onChange={v => this.updatePageNameValue(v)} pad={{ "left": "medium" }} disabled={!canedit1} />
                                                    </FormField>
                                                    <FormField label="Description" error={this.description_err}>
                                                        <TextInput defaultValue={pagedefault.description} onChange={v => this.updateDescriptionValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Details" error={this.detail_err} data-bounds="html_editor">
                                                            <ReactQuill
                                                                theme="snow"
                                                                bounds={`[data-bounds="html_editor"]`}
                                                                scrollingContainer="html"
                                                                modules={{
                                                                    toolbar: [
                                                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                        ['blockquote', 'code-block'],
                                                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                                        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                                        [{ 'direction': 'rtl' }],                         // text direction
                                                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                                        ['link', 'image'],
                                                                        ['clean']                                         // remove formatting button
                                                                    ],
                                                                    clipboard: {
                                                                        matchVisual: false,
                                                                    },
                                                                    htmlEditButton: {
                                                                        debug: true, // logging, default:false
                                                                        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                                                                        okText: "Ok", // Text to display in the OK button, default: Ok,
                                                                        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                                                                        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                                                                        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                                                                        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                                                                        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                                                                        editorModules: {} // The default mod
                                                                    }
                                                                }}
                                                                onChange={this.handleEditorChange}
                                                                value={pagedefault.detail}
                                                                readOnly={!canedit1} />
                                                        </FormField>
                                                    </Grommet>
                                                    {isDeepLinkURLAvailable && <Deeplink uri={this.props.organisationdata.appURI} path={this.props.location.pathname} />}
                                                    {/* <FormField label="Placemark Name" error={this.placemarkid_err}>
                                        <Select
                                            placeholder="Placemark Name"
                                            onClose={() => this.setState({ placemarks: this.placemarkfull })}
                                            onSearch={(searchText) => {
                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                const filtered = this.placemarkfull.filter(o => o.name.match(regexp));
                                                if (filtered.length > 0) {
                                                    this.setState({ placemarks: filtered });
                                                }
                                                else {
                                                    this.setState({ placemarks: [{ id: "", name: "No match found" }] });
                                                }
                                            }}
                                            value={vplacemarkname}
                                            onChange={event => this.updatePlacemark(event)}
                                            options={placemarks.map((option) => option.name)}
                                        />
                                    </FormField> */}
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Image" label={
                                                            <Box direction="row" gap="xsmall" align="center">
                                                                <Box>Image</Box>
                                                                <Box data-for="img_info" data-tip>
                                                                    <CircleInformation size="small" />
                                                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                        <span>Please select a brand approved image to display with your content. <br />
                                                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                                                    </ReactTooltip>
                                                                </Box>
                                                            </Box>} >
                                                            <Grommet theme={customTheme3}>
                                                                <Box direction="row" gap="small">
                                                                    <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                                        <FormField label="" error={this.image_err}>
                                                                            <Box direction="row" justify="center">
                                                                                {this.state.newimage ?
                                                                                    <Box alignSelf="center">
                                                                                        {this.shortcut()}
                                                                                    </Box>
                                                                                    :
                                                                                    (pagedefault.imageUrl == "" || pagedefault.imageUrl == null) ?
                                                                                        <Box height="75px" justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                                                const c = document.getElementById("real");
                                                                                                c.click();
                                                                                            }} />
                                                                                        </Box>
                                                                                        :
                                                                                        <Box justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <Image height="75" src={`${pagedefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                                onClick={e => {
                                                                                                    const c = document.getElementById("real");
                                                                                                    c.click();
                                                                                                }} />
                                                                                        </Box>
                                                                                }
                                                                            </Box>
                                                                        </FormField>
                                                                    </StyledBox>
                                                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((pagedefault.imageUrl == "" || pagedefault.imageUrl == null) && !this.state.newimage)}>
                                                                            <Trash />
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Grommet>
                                                        </FormField>
                                                    </Grommet>
                                                </StyledBox>
                                            </Box>
                                            {/*Categorise*/}
                                            <Box>
                                                <Box><Text size="large">Categorise</Text></Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                                    {!isNewCategory ? (
                                                        <Box direction="row" gap="small">
                                                            <Box fill>
                                                                <FormField label="Category Name" error={this.categoryid_err}>
                                                                    <Select
                                                                        placeholder="Select a Category"
                                                                        onClose={() => this.setState({ options: this.categoryfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ options: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        //value={(categoriesdefault.find((option) => option.id == pagedefault.categoryid)).name}
                                                                        //selected={selected}
                                                                        //value={vcategoryname}
                                                                        value={this.state.categoryLocation}
                                                                        onChange={event => this.updateCategoryName(event)}
                                                                        options={options.map((option) => option.lpn)}
                                                                        replace={false}
                                                                        disabled={!canedit1}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                            <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        //New Category
                                                        <Box>
                                                            <Box fill direction="row" border="bottom" gap="small">
                                                                <Box fill>
                                                                    <FormField label="Category Name *" error={this.newname_err}>
                                                                        <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                                    </FormField>
                                                                </Box>
                                                                <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                    <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                                </Box>
                                                            </Box>
                                                            <Box>
                                                                <FormField label="App Location" error={this.parent_err}>
                                                                    <Select
                                                                        placeholder="App Location"
                                                                        onClose={() => this.setState({ parents: this.parentfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.parentfull.filter(o => o.parentloc.match(regexp))
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ parents: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        value={parent ? parent : '\u00A0'}
                                                                        //selected={selected}
                                                                        onChange={event => this.updateCategoryParent(event)}
                                                                        options={parents.map((option) => option.parentloc ? option.parentloc : '\u00A0')}
                                                                        replace={false}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                            {/*<FormField label="Location">
                                                <TextInput readOnly={true} placeholder="Location" onChange={e => this.updateLocation(e)} value={(this.parentfull.find((option) => option.parent == parent)).location} />
                                            </FormField>*/}
                                                        </Box>
                                                    )}
                                                </StyledBox>
                                            </Box>

                                            {/*Image*/}
                                            {/*<Box pad={{ vertical: 'medium' }}>*/}
                                            {/*    <Box><Text size="large">Image</Text></Box>*/}
                                            {/*    <Box width="large">*/}
                                            {/*        <Text >*/}
                                            {/*            Please select a brand approved image to display with your content.*/}
                                            {/*        </Text>*/}
                                            {/*        */}{/*<Text >*/}
                                            {/*        */}{/*    Please select a brand approved image to display with your content. <br/>*/}
                                            {/*        */}{/*    Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.*/}
                                            {/*        */}{/*</Text>*/}
                                            {/*    </Box>*/}
                                            {/*    <StyledBox style1 pad={{ "horizontal": "small" }} width="806px">*/}
                                            {/*        <FormField label="Select image">*/}
                                            {/*            <Box direction="row" gap="small">*/}
                                            {/*                <Box border={pagedefault.image == 0 ? { color: "brand", size: "medium" } : { size: "medium" }} onClick={!canedit1 ? null : evt => this.updateImageValue(evt, 0)}>*/}
                                            {/*                    <Image src="./Images/1.jpg" />*/}
                                            {/*                </Box>*/}
                                            {/*                <Box border={pagedefault.image == 1 ? { color: "brand", size: "medium" } : { size: "medium" }} onClick={!canedit1 ? null : evt => this.updateImageValue(evt, 1)}>*/}
                                            {/*                    <Image src="./Images/2.jpg" />*/}
                                            {/*                </Box>*/}
                                            {/*                <Box border={pagedefault.image == 2 ? { color: "brand", size: "medium" } : { size: "medium" }} onClick={!canedit1 ? null : evt => this.updateImageValue(evt, 2)}>*/}
                                            {/*                    <Image src="./Images/3.jpg" />*/}
                                            {/*                </Box>*/}
                                            {/*                <Box border={pagedefault.image == 3 ? { color: "brand", size: "medium" } : { size: "medium" }} onClick={!canedit1 ? null : evt => this.updateImageValue(evt, 3)}>*/}
                                            {/*                    <Image src="./Images/4.jpg" />*/}
                                            {/*                </Box>*/}
                                            {/*                <Box border={pagedefault.image == 4 ? { color: "brand", size: "medium" } : { size: "medium" }} onClick={!canedit1 ? null : evt => this.updateImageValue(evt, 4)}>*/}
                                            {/*                    <Image src="./Images/5.jpg" />*/}
                                            {/*                </Box>*/}
                                            {/*                <Box border={pagedefault.image == -1 ? { color: "brand", size: "medium" } : { size: "medium" }} width="120px" onClick={!canedit1 ? null : evt => this.updateImageValue(evt, -1)}>*/}
                                            {/*                    <Text alignSelf="center" textAlign="center" margin="small">No Image</Text>*/}
                                            {/*                </Box>*/}
                                            {/*            </Box>*/}
                                            {/*        </FormField>*/}
                                            {/*        */}{/*Placemark*/}
                                            {/*    </StyledBox>*/}
                                            {/*</Box>*/}


                                            <Box>
                                                <Box><Text size="large">Placemark</Text></Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                                    <FormField label="Placemark Name" error={this.placemarkid_err}>
                                                        <Select
                                                            placeholder="Select a Placemark"
                                                            onClose={() => this.setState({ placemarks: this.placemarkfull })}
                                                            onSearch={(searchText) => {
                                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                const filtered = this.placemarkfull.filter(o => o.name.match(regexp));
                                                                if (filtered.length > 0) {
                                                                    this.setState({ placemarks: filtered });
                                                                }
                                                                else {
                                                                    this.setState({ placemarks: [{ id: "", name: "No match found" }] });
                                                                }
                                                            }}
                                                            labelKey="name"
                                                            valueKey="id"
                                                            value={pagedefault.placemarkid ? placemarks.find(p => p.id == pagedefault.placemarkid) : null}
                                                            onChange={event => this.updatePlacemark(event)}
                                                            options={placemarks.filter(option => option.name != "" && option.name != null && option.enabled)}
                                                            replace={false}
                                                            disabled={!canedit1}
                                                        >
                                                            {option =>
                                                                <Box pad="small" justify="between" direction="row" background={pagedefault.placemarkid && pagedefault.placemarkid == option.id ? 'brand' : undefined}>
                                                                    {option.name}
                                                                    <Box>{`${placemarks.find(f => f.id == option.id).placemarktype}, 
                                                                ${levelsdata.find(f => f.mergedId == placemarks.find(fi => fi.id == option.id).levelId).mergedName}`}</Box>
                                                                </Box>
                                                            }
                                                        </Select>
                                                    </FormField>
                                                </StyledBox>
                                            </Box>

                                            {/*Connect Now*/}
                                            <IconThemeContext.Extend value={galwayIconTheme}>
                                                <Box>
                                                    <Box>
                                                        <Text size="large">Connect Now</Text>
                                                    </Box>
                                                    {false && <StyledBox style1 gap="small" pad={{ "horizontal": "small", "top": "small" }} justify="between" direction="row">
                                                        <Box direction="column" width="20%">
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Mail />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.email_err} fill="horizontal" label='Email Address'>
                                                                        <TextInput defaultValue={pagedefault.email} onChange={v => this.updateEmailValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start" >
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    {/* <Image src="./Images/Phone_black.png" width="24px" height="24px" /> */}
                                                                    <Phone />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField help={this.phone_help} error={this.phone_err} fill="horizontal" label="Phone Number">
                                                                        <TextInput defaultValue={pagedefault.phone} onChange={evt => this.updatePhoneValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box direction="column" width="20%">
                                                            <Box direction="row" align="start" >
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Twitter />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.twitter_err} fill="horizontal" label='Twitter'>
                                                                        <TextInput defaultValue={pagedefault.twitter} onChange={v => this.updateTwitterValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <LinkedinOption />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.linkedin_err} fill="horizontal" label='LinkedIn'>
                                                                        <TextInput defaultValue={pagedefault.linkedin} onChange={v => this.updateLinkedinValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box direction="column" width="20%">
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    {/*<Image src="./Images/Yammer_black.png" width="24px" height="24px" />*/}
                                                                    <Yammer />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.yammer_err} fill="horizontal" label='Yammer'>
                                                                        <TextInput defaultValue={pagedefault.yammer} onChange={v => this.updateYammerValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <FacebookOption />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.facebook_err} fill="horizontal" label='Facebook'>
                                                                        <TextInput defaultValue={pagedefault.facebook} onChange={v => this.updateFacebookValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box direction="column" width="20%">
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Link />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.url_err} fill="horizontal" label='URL'>
                                                                        <TextInput defaultValue={pagedefault.url} onChange={v => this.updateUrlValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Instagram />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.instagram_err} fill="horizontal" label='Instagram'>
                                                                        <TextInput defaultValue={pagedefault.instagram} onChange={v => this.updateInstagramValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box direction="column" width="20%">
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Video />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.video_err} fill="horizontal" label="Video">
                                                                        <TextInput defaultValue={pagedefault.video} onChange={evt => this.updateVideoValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Youtube />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.youtube_err} fill="horizontal" label="YouTube">
                                                                        <TextInput defaultValue={pagedefault.youtube} onChange={evt => this.updateYoutubeValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </StyledBox>}

                                                    <StyledBox style1 gap="small" pad={{ "horizontal": "small", "top": "small" }} justify="between" direction="column">
                                                        <Grid columns={{ count: 4, size: "auto" }}>
                                                            <Box direction="row" align="start" width="100%">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Mail />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.email_err} fill="horizontal" label='Email Address'>
                                                                        <TextInput defaultValue={pagedefault.email} onChange={v => this.updateEmailValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start" >
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Twitter />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.twitter_err} fill="horizontal" label='Twitter'>
                                                                        <TextInput defaultValue={pagedefault.twitter} onChange={v => this.updateTwitterValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    {/*<Image src="./Images/Yammer_black.png" width="24px" height="24px" />*/}
                                                                    <Yammer />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.yammer_err} fill="horizontal" label='Yammer'>
                                                                        <TextInput defaultValue={pagedefault.yammer} onChange={v => this.updateYammerValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Link />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.url_err} fill="horizontal" label='URL'>
                                                                        <TextInput defaultValue={pagedefault.url} onChange={v => this.updateUrlValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>

                                                            <Box direction="row" align="start" >
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    {/* <Image src="./Images/Phone_black.png" width="24px" height="24px" /> */}
                                                                    <Phone />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField help={this.phone_help} error={this.phone_err} fill="horizontal" label="Phone Number">
                                                                        <TextInput defaultValue={pagedefault.phone} onChange={evt => this.updatePhoneValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <LinkedinOption />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.linkedin_err} fill="horizontal" label='LinkedIn'>
                                                                        <TextInput defaultValue={pagedefault.linkedin} onChange={v => this.updateLinkedinValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <FacebookOption />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.facebook_err} fill="horizontal" label='Facebook'>
                                                                        <TextInput defaultValue={pagedefault.facebook} onChange={v => this.updateFacebookValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Instagram />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.instagram_err} fill="horizontal" label='Instagram'>
                                                                        <TextInput defaultValue={pagedefault.instagram} onChange={v => this.updateInstagramValue(v)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>

                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Slack />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.slack_err} fill="horizontal" label="Slack">
                                                                        <TextInput defaultValue={pagedefault.slack} onChange={evt => this.updateSlackValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>

                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Video />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.video_err} fill="horizontal" label="Video">
                                                                        <TextInput defaultValue={pagedefault.video} onChange={evt => this.updateVideoValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <Youtube />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.youtube_err} fill="horizontal" label="YouTube">
                                                                        <TextInput defaultValue={pagedefault.youtube} onChange={evt => this.updateYoutubeValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>
                                                            <Box direction="row" align="start">
                                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                                    <MapLocation />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <FormField error={this.map_err} fill="horizontal" label="Maps">
                                                                        <TextInput
                                                                            placeholder="Latitude, Longitude"
                                                                            defaultValue={(pagedefault.latitude != null && pagedefault.longitude != null) ? (pagedefault.latitude + ", " + pagedefault.longitude) : ""}
                                                                            onChange={evt => this.updateMapValue(evt)} disabled={!canedit1} />
                                                                    </FormField>
                                                                </Box>
                                                            </Box>


                                                        </Grid>
                                                    </StyledBox>
                                                </Box>
                                            </IconThemeContext.Extend>

                                            {/*Custom Attributes*/}
                                            {this.renderCustomInputs()}
                                            {/*More Information*/}
                                            <IconThemeContext.Extend value={galwayIconTheme}>
                                                <Box>
                                                    <Text size="large">More Information</Text>
                                                    {this.renderMoreInfoV2(this.props.pagedata.moreinfo)}
                                                </Box>
                                            </IconThemeContext.Extend>
                                        </Box>
                                    }

                                    {/*Web Page only*/}

                                    {pagedefault.type == "Web" &&
                                        <Box gap="medium">
                                            {/*Page Type*/}
                                            {/*<Box direction="row">*/}
                                            {/*    <StyledBox style1 flex="shrink" direction="row" gap="xlarge" pad={{ "vertical": "medium", "horizontal": "small" }}>*/}
                                            {/*        <CheckBox*/}
                                            {/*            label="Web Page"*/}
                                            {/*            checked*/}
                                            {/*            disabled={heading == "Edit"}*/}
                                            {/*            onChange={v => this.updateType(v, "Web")} />*/}
                                            {/*    </StyledBox>*/}
                                            {/*</Box>*/}


                                            {/*Description*/}
                                            <Box>
                                                <Box>
                                                    <Text size="large">Content Details</Text>
                                                    <Text>All fields marked with an * are mandatory.</Text>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                    <FormField label="Name *" error={this.name_err}>
                                                        <TextInput defaultValue={pagedefault.name} onChange={v => this.updatePageNameValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <FormField label="Description" error={this.description_err}>
                                                        <TextInput defaultValue={pagedefault.description} onChange={v => this.updateDescriptionValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <Box direction="row" gap="medium" justify="between">
                                                        <Box flex>
                                                            <FormField label="URL *" error={this.weburl_err}>
                                                                <TextInput defaultValue={pagedefault.weburl} onChange={v => this.updateWebUrlValue(v)} disabled={!canedit1} />
                                                            </FormField>
                                                        </Box>
                                                        {pagedefault.weburl.trim().substr(pagedefault.weburl.trim().length - 4).toLowerCase() == ".pdf" ?
                                                            <FormField
                                                                label={
                                                                    <Box direction="row" gap="xsmall" justify="between" style={boxStyle}>
                                                                        <Box direction="row" gap="xsmall" justify="between">
                                                                            <Secure />
                                                                            <Text size={textSize}>Authentication Status:</Text>
                                                                        </Box>
                                                                        <Box height={boxHeight}>
                                                                            <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                        To ensure this content opens correctly, ensure you specify if <br />
                                                                                        this PDF is authenticated or not authenticated here .">
                                                                                <CircleInformation />
                                                                            </a>
                                                                            <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                            >
                                                                <Select
                                                                    options={["Authenticated", "Not Authenticated"]}
                                                                    value={pagedefault.webUrlAuth ? "Authenticated" : "Not Authenticated"}
                                                                    onChange={evt => this.updateAuthStatusWeb(evt)} />
                                                            </FormField>
                                                            :
                                                            <FormField
                                                                label={
                                                                    <Box direction="row" gap="xsmall" justify="between" style={boxStyle}>
                                                                            <Box direction="row" gap="xsmall" justify="between">
                                                                                <PhoneVertical />
                                                                                <Text size={textSize}>Launch Mode:</Text>
                                                                            </Box>
                                                                            <Box height={boxHeight}>
                                                                                <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                                                    <CircleInformation />
                                                                                </a>
                                                                                <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                                            </Box>
                                                                    </Box>
                                                                 }
                                                            >
                                                                {!pagedefault.landscape &&
                                                                    <Select
                                                                        options={this.launchEnumOptions.map(e => e.label)}
                                                                        value={this.launchEnumOptions.find(e => e.value == pagedefault.weburlType).label}
                                                                        onChange={event => this.updateDisplayTypeWeb(event)} />}
                                                                {pagedefault.landscape &&
                                                                    <Select
                                                                        options={['Hosted In App']}
                                                                        value={'Hosted In App'}
                                                                        onChange={event => this.updateDisplayTypeWeb(event)} />}
                                                            </FormField>}
                                                    </Box>
                                                    <Box direction="row">
                                                        <FormField label="Landscape mode" error={this.orientation_err}>
                                                            <Box direction="row" height="34px" align="center">
                                                                <CheckBox checked={pagedefault.landscape} label="App will reorientate to landscape mode for this page" onChange={evt => { pagedefault.landscape = !pagedefault.landscape; this.setState({ updated: true }); }} disabled={!canedit1} />
                                                            </Box>
                                                        </FormField>
                                                    </Box>
                                                    {isDeepLinkURLAvailable && <Deeplink uri={this.props.organisationdata.appURI} path={this.props.location.pathname} />}
                                                    {/*<FormField label="Mode" error={this.mode_err}>
                                            <Select
                                                placeholder="Select an Option"
                                                options={constants.MODE}
                                                value={pagedefault.inApp == true ? constants.MODE[0] : pagedefault.inApp == false ? constants.MODE[1] : ""}
                                                onChange={v => this.updateModeValue(v)}
                                            />
                                        </FormField>*/}
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Image" label={
                                                            <Box direction="row" gap="xsmall" align="center">
                                                                <Box>Image</Box>
                                                                <Box data-for="img_info" data-tip>
                                                                    <CircleInformation size="small" />
                                                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                        <span>Please select a brand approved image to display with your content. <br />
                                                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                                                    </ReactTooltip>
                                                                </Box>
                                                            </Box>} >
                                                            <Grommet theme={customTheme3}>
                                                                <Box direction="row" gap="small">
                                                                    <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                                        <FormField label="" error={this.image_err}>
                                                                            <Box direction="row" justify="center">
                                                                                {this.state.newimage ?
                                                                                    <Box alignSelf="center">
                                                                                        {this.shortcut()}
                                                                                    </Box>
                                                                                    :
                                                                                    (pagedefault.imageUrl == "" || pagedefault.imageUrl == null) ?
                                                                                        <Box height="75px" justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                                                const c = document.getElementById("real");
                                                                                                c.click();
                                                                                            }} />
                                                                                        </Box>
                                                                                        :
                                                                                        <Box justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <Image height="75" src={`${pagedefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                                onClick={e => {
                                                                                                    const c = document.getElementById("real");
                                                                                                    c.click();
                                                                                                }} />
                                                                                        </Box>
                                                                                }
                                                                            </Box>
                                                                        </FormField>
                                                                    </StyledBox>
                                                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((pagedefault.imageUrl == "" || pagedefault.imageUrl == null) && !this.state.newimage)}>
                                                                            <Trash />
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Grommet>
                                                        </FormField>
                                                    </Grommet>
                                                </StyledBox>
                                            </Box>
                                            {/*Categorise*/}
                                            <Box >
                                                <Box>
                                                    <Text size="large">Categorise</Text>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                                    {!isNewCategory ? (
                                                        <Box direction="row" gap="small">
                                                            <Box fill>
                                                                <FormField label="Category Name" error={this.categoryid_err}>
                                                                    <Select
                                                                        placeholder="Select a Category"
                                                                        onClose={() => this.setState({ options: this.categoryfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.categoryfull.filter(o => o.lpn.match(regexp))
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ options: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        //value={(categoriesdefault.find((option) => option.id == pagedefault.categoryid)).name}
                                                                        //selected={selected}
                                                                        //value={vcategoryname}
                                                                        value={this.state.categoryLocation}
                                                                        onChange={event => this.updateCategoryName(event)}
                                                                        options={options.map((option) => option.lpn)}
                                                                        replace={false}
                                                                        disabled={!canedit1}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                            <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        //New Category
                                                        <Box>
                                                            <Box fill direction="row" border="bottom" gap="small">
                                                                <Box fill>
                                                                    <FormField label="Category Name *" error={this.newname_err}>
                                                                        <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                                    </FormField>
                                                                </Box>
                                                                <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                    <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                                </Box>
                                                            </Box>
                                                            <Box fill>
                                                                <FormField label="App Location *" error={this.parent_err}>
                                                                    <Select
                                                                        placeholder="App Location"
                                                                        onClose={() => this.setState({ parents: this.parentfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.parentfull.filter(o => o.parentloc.match(regexp));
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ parents: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        value={parent ? parent : '\u00A0'}
                                                                        //selected={selected}
                                                                        onChange={event => this.updateCategoryParent(event)}
                                                                        options={parents.map((option) => option.parentloc ? option.parentloc : '\u00A0')}
                                                                        replace={false}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </StyledBox>
                                            </Box>
                                            {/*Custom Attributes*/}
                                            {this.renderCustomInputs()}
                                        </Box>
                                    }

                                    {/*HTML Page only*/}

                                    {pagedefault.type == "Html" &&
                                        <Box gap="medium">
                                            {/*Page Type*/}
                                            {/*<Box direction="row">*/}
                                            {/*    <StyledBox style1 flex="shrink" direction="row" gap="xlarge" pad={{ "vertical": "medium", "horizontal": "small" }}>*/}
                                            {/*        <CheckBox*/}
                                            {/*            label="HTML Page"*/}
                                            {/*            checked*/}
                                            {/*            disabled={heading == "Edit"}*/}
                                            {/*            onChange={v => this.updateType(v, "Html")} />*/}
                                            {/*    </StyledBox>*/}
                                            {/*</Box>*/}

                                            {/*Description*/}
                                            <Box>
                                                <Box>
                                                    <Text size="large">Content Details</Text>
                                                    <Text>All fields marked with an * are mandatory.</Text>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                    <FormField label="Name *" error={this.name_err}>
                                                        <TextInput defaultValue={pagedefault.name} onChange={v => this.updatePageNameValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <FormField label="Description" error={this.description_err}>
                                                        <TextInput defaultValue={pagedefault.description} onChange={v => this.updateDescriptionValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Details" error={this.detail_err} data-bounds="html_editor">
                                                            <ReactQuill
                                                                theme="snow"
                                                                bounds={`[data-bounds="html_editor"]`}
                                                                scrollingContainer="html"
                                                                modules={{
                                                                    toolbar: [
                                                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                        ['blockquote', 'code-block'],
                                                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                                        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                                        [{ 'direction': 'rtl' }],                         // text direction
                                                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                                        ['link', 'image'],
                                                                        ['clean']                                         // remove formatting button
                                                                    ],
                                                                    clipboard: {
                                                                        matchVisual: false,
                                                                    },
                                                                    htmlEditButton: {
                                                                        debug: true, // logging, default:false
                                                                        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                                                                        okText: "Ok", // Text to display in the OK button, default: Ok,
                                                                        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                                                                        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                                                                        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                                                                        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                                                                        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                                                                        editorModules: {} // The default mod
                                                                    }
                                                                }}
                                                                onChange={this.handleEditorChange}
                                                                value={pagedefault.detail}
                                                                readOnly={!canedit1} />
                                                        </FormField>
                                                    </Grommet>
                                                    {isDeepLinkURLAvailable && <Deeplink uri={this.props.organisationdata.appURI} path={this.props.location.pathname} />}
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Image" label={
                                                            <Box direction="row" gap="xsmall" align="center">
                                                                <Box>Image</Box>
                                                                <Box data-for="img_info" data-tip>
                                                                    <CircleInformation size="small" />
                                                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                        <span>Please select a brand approved image to display with your content. <br />
                                                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                                                    </ReactTooltip>
                                                                </Box>
                                                            </Box>} >
                                                            <Grommet theme={customTheme3}>
                                                                <Box direction="row" gap="small">
                                                                    <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                                        <FormField label="" error={this.image_err}>
                                                                            <Box direction="row" justify="center">
                                                                                {this.state.newimage ?
                                                                                    <Box alignSelf="center">
                                                                                        {this.shortcut()}
                                                                                    </Box>
                                                                                    :
                                                                                    (pagedefault.imageUrl == "" || pagedefault.imageUrl == null) ?
                                                                                        <Box height="75px" justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                                                const c = document.getElementById("real");
                                                                                                c.click();
                                                                                            }} />
                                                                                        </Box>
                                                                                        :
                                                                                        <Box justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <Image height="75" src={`${pagedefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                                onClick={e => {
                                                                                                    const c = document.getElementById("real");
                                                                                                    c.click();
                                                                                                }} />
                                                                                        </Box>
                                                                                }
                                                                            </Box>
                                                                        </FormField>
                                                                    </StyledBox>
                                                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((pagedefault.imageUrl == "" || pagedefault.imageUrl == null) && !this.state.newimage)}>
                                                                            <Trash />
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Grommet>
                                                        </FormField>
                                                    </Grommet>
                                                </StyledBox>
                                            </Box>
                                            {/*Categorise*/}
                                            <Box>
                                                <Box><Text size="large">Categorise</Text></Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                                    {!isNewCategory ? (
                                                        <Box direction="row" gap="small">
                                                            <Box fill>
                                                                <FormField label="Category Name" error={this.categoryid_err}>
                                                                    <Select
                                                                        placeholder="Select a Category"
                                                                        onClose={() => this.setState({ options: this.categoryfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.categoryfull.filter(o => o.lpn.match(regexp))
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ options: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        //value={(categoriesdefault.find((option) => option.id == pagedefault.categoryid)).name}
                                                                        //selected={selected}
                                                                        //value={vcategoryname}
                                                                        value={this.state.categoryLocation}
                                                                        onChange={event => this.updateCategoryName(event)}
                                                                        options={options.map((option) => option.lpn)}
                                                                        replace={false}
                                                                        disabled={!canedit1}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                            <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        //New Category
                                                        <Box>
                                                            <Box fill direction="row" border="bottom" gap="small">
                                                                <Box fill>
                                                                    <FormField label="Category Name *" error={this.newname_err}>
                                                                        <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                                    </FormField>
                                                                </Box>
                                                                <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                    <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                                </Box>
                                                            </Box>
                                                            <Box fill>
                                                                <FormField label="App Location *" error={this.parent_err}>
                                                                    <Select
                                                                        placeholder="App Location"
                                                                        onClose={() => this.setState({ parents: this.parentfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.parentfull.filter(o => o.parentloc.match(regexp));
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ parents: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        value={parent ? parent : '\u00A0'}
                                                                        onChange={event => this.updateCategoryParent(event)}
                                                                        options={parents.map((option) => option.parentloc ? option.parentloc : '\u00A0')}
                                                                        replace={false}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </StyledBox>
                                            </Box>
                                            {/*Custom Attributes*/}
                                            {this.renderCustomInputs()}
                                        </Box>
                                    }

                                    {/*Map Link Page only*/}

                                    {pagedefault.type == "Maps" &&
                                        <Box gap="medium">
                                            <Box>
                                                <Box>
                                                    <Text size="large">Content Details</Text>
                                                    <Text>All fields marked with an * are mandatory.</Text>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                    <FormField label="Name *" error={this.name_err}>
                                                        <TextInput defaultValue={pagedefault.name} onChange={v => this.updatePageNameValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <FormField label="GPS Coordinates" error={this.map_err}>
                                                        <TextInput
                                                            placeholder="Latitude, Longitude"
                                                            defaultValue={(pagedefault.latitude != null && pagedefault.longitude != null) ? (pagedefault.latitude + ", " + pagedefault.longitude) : ""}
                                                            onChange={v => this.updateMapValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <FormField label="Description" error={this.description_err}>
                                                        <TextInput defaultValue={pagedefault.description} onChange={v => this.updateDescriptionValue(v)} disabled={!canedit1} />
                                                    </FormField>
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Details" error={this.detail_err} data-bounds="html_editor">
                                                            <ReactQuill
                                                                theme="snow"
                                                                bounds={`[data-bounds="html_editor"]`}
                                                                scrollingContainer="html"
                                                                modules={{
                                                                    toolbar: [
                                                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                        ['blockquote', 'code-block'],
                                                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                                        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                                        [{ 'direction': 'rtl' }],                         // text direction
                                                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                                        ['link', 'image'],
                                                                        ['clean']                                         // remove formatting button
                                                                    ],
                                                                    clipboard: {
                                                                        matchVisual: false,
                                                                    },
                                                                    htmlEditButton: {
                                                                        debug: true, // logging, default:false
                                                                        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                                                                        okText: "Ok", // Text to display in the OK button, default: Ok,
                                                                        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                                                                        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                                                                        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                                                                        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                                                                        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                                                                        editorModules: {} // The default mod
                                                                    }
                                                                }}
                                                                onChange={this.handleEditorChange}
                                                                value={pagedefault.detail}
                                                                readOnly={!canedit1} />
                                                        </FormField>
                                                    </Grommet>
                                                    {isDeepLinkURLAvailable && <Deeplink uri={this.props.organisationdata.appURI} path={this.props.location.pathname} />}
                                                    <Grommet theme={customTheme}>
                                                        <FormField label="Image" label={
                                                            <Box direction="row" gap="xsmall" align="center">
                                                                <Box>Image</Box>
                                                                <Box data-for="img_info" data-tip>
                                                                    <CircleInformation size="small" />
                                                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                                        <span>Please select a brand approved image to display with your content. <br />
                                                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                                                    </ReactTooltip>
                                                                </Box>
                                                            </Box>} >
                                                            <Grommet theme={customTheme3}>
                                                                <Box direction="row" gap="small">
                                                                    <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                                        <FormField label="" error={this.image_err}>
                                                                            <Box direction="row" justify="center">
                                                                                {this.state.newimage ?
                                                                                    <Box alignSelf="center">
                                                                                        {this.shortcut()}
                                                                                    </Box>
                                                                                    :
                                                                                    (pagedefault.imageUrl == "" || pagedefault.imageUrl == null) ?
                                                                                        <Box height="75px" justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                                                const c = document.getElementById("real");
                                                                                                c.click();
                                                                                            }} />
                                                                                        </Box>
                                                                                        :
                                                                                        <Box justify="center">
                                                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                            <Image height="75" src={`${pagedefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                                onClick={e => {
                                                                                                    const c = document.getElementById("real");
                                                                                                    c.click();
                                                                                                }} />
                                                                                        </Box>
                                                                                }
                                                                            </Box>
                                                                        </FormField>
                                                                    </StyledBox>
                                                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((pagedefault.imageUrl == "" || pagedefault.imageUrl == null) && !this.state.newimage)}>
                                                                            <Trash />
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Grommet>
                                                        </FormField>
                                                    </Grommet>
                                                </StyledBox>
                                            </Box>
                                            {/*Categorise*/}
                                            <Box>
                                                <Box><Text size="large">Categorise</Text></Box>
                                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                                    {!isNewCategory ? (
                                                        <Box direction="row" gap="small">
                                                            <Box fill>
                                                                <FormField label="Category Name" error={this.categoryid_err}>
                                                                    <Select
                                                                        placeholder="Select a Category"
                                                                        onClose={() => this.setState({ options: this.categoryfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.categoryfull.filter(o => o.lpn.match(regexp))
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ options: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        //value={(categoriesdefault.find((option) => option.id == pagedefault.categoryid)).name}
                                                                        //selected={selected}
                                                                        //value={vcategoryname}
                                                                        value={this.state.categoryLocation}
                                                                        onChange={event => this.updateCategoryName(event)}
                                                                        options={options.map((option) => option.lpn)}
                                                                        replace={false}
                                                                        disabled={!canedit1}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                            <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        //New Category
                                                        <Box>
                                                            <Box fill direction="row" border="bottom" gap="small">
                                                                <Box fill>
                                                                    <FormField label="Category Name *" error={this.newname_err}>
                                                                        <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                                    </FormField>
                                                                </Box>
                                                                <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                                                    <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                                </Box>
                                                            </Box>
                                                            <Box fill>
                                                                <FormField label="App Location *" error={this.parent_err}>
                                                                    <Select
                                                                        placeholder="App Location"
                                                                        onClose={() => this.setState({ parents: this.parentfull })}
                                                                        onSearch={(searchText) => {
                                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                            const filtered = this.parentfull.filter(o => o.parentloc.match(regexp));
                                                                            if (filtered.length > 0) {
                                                                                this.setState({ parents: filtered });
                                                                            }
                                                                            else {
                                                                                this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                            }
                                                                        }}
                                                                        value={parent ? parent : '\u00A0'}
                                                                        onChange={event => this.updateCategoryParent(event)}
                                                                        options={parents.map((option) => option.parentloc ? option.parentloc : '\u00A0')}
                                                                        replace={false}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </StyledBox>
                                            </Box>
                                            {/*Custom Attributes*/}
                                            {this.renderCustomInputs()}
                                        </Box>
                                    }
                                </Box>
                            </form>}
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    const pageId = ownProps.match.params.pageId || ''; //from the path '/event/:eventId'
    //console.log('*DEBUG*-EV1- MapStatetoProps',JSON.stringify(state));
    //console.log('*DEBUG*-EV2- MapStatetoProps',JSON.stringify(ownProps));
    if (state.selectedPageReducer.pagedata && pageId == state.selectedPageReducer.pagedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedPageReducer.pagedata));
        return {
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            placemarksdata: state.placemarksReducer.placemarksdata,
            levelsdata: state.levelsReducer.levelsdata,
            pagedata: state.selectedPageReducer.pagedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    }

    else {
        return {
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            placemarksdata: state.placemarksReducer.placemarksdata,
            levelsdata: state.levelsReducer.levelsdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...PageAction, ...LocationAction, ...PlacemarkAction, ...LevelAction, ...SiteAction, ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageEditContainer));