import { combineReducers } from 'redux';
//import {reducer as formReducer} from 'redux-form';
import quickActionsReducer from './quickActionsReducer';
import selectedQuickActionReducer from './selectedQuickActionReducer';
import eventsReducer from './eventsReducer';
import selectedEventReducer from './selectedEventReducer';
import categoriesReducer from './categoriesReducer';
import selectedCategoryReducer from './selectedCategoryReducer';
import pagesReducer from './pagesReducer';
import selectedPageReducer from './selectedPageReducer';
import usersReducer from './usersReducer';
import selectedUserReducer from './selectedUserReducer';
import rolesReducer from './rolesReducer';
import selectedRoleReducer from './selectedRoleReducer';
import placemarksReducer from './placemarksReducer';
import selectedPlacemarkReducer from './selectedPlacemarkReducer';
import locationsReducer from './locationsReducer';
import organisationsReducer from './organisationsReducer';
import selectedOrganisationReducer from './selectedOrganisationReducer';
import sitesReducer from './sitesReducer';
import selectedSiteReducer from './selectedSiteReducer';
import spacesReducer from './spacesReducer';
import buildingsReducer from './buildingsReducer';
import levelsReducer from './levelsReducer';
import timeZonesReducer from './timeZonesReducer';
import beaconsReducer from './beaconsReducer';
import spotsReducer from './spotsReducer';
import overviewsReducer from './overviewsReducer';
import profilesReducer from './profilesReducer';
import selectedProfileReducer from './selectedProfileReducer';
import appsReducer from './appsReducer';
import masterAppsReducer from './masterAppsReducer';
import selectedAppReducer from './selectedAppReducer';
import selectedMasterAppReducer from './selectedMasterAppReducer';
import formsReducer from './formsReducer';
import selectedFormReducer from './selectedFormReducer';
import usersPreferencesReducer from './usersPreferencesReducer';
import selectedUserPreferencesReducer from './selectedUserPreferencesReducer';
import assetsReducer from './assetsReducer';
import assetsOverviewReducer from './assetsOverviewReducer';
import assetLabelsReducer from './assetLabelsReducer';
import selectedAssetLabelReducer from './selectedAssetLabelReducer ';
import selectedAssetReducer from './selectedAssetReducer';
import assetsHistoryReducer from './assetsHistoryReducer';
import selectedAssetHistoryReducer from './selectedAssetHistoryReducer';
import notificationsReducer from './notificationsReducer';
import selectedNotificationReducer from './selectedNotificationReducer';
import mergedLevelsReducer from './mergedLevelsReducer';
import selectedMergedLevelReducer from './selectedMergedLevelReducer';
import mergedBuildingsReducer from './mergedBuildingsReducer';
import selectedMergedBuildingReducer from './selectedMergedBuildingReducer';
import placemarksHistoryReducer from './placemarksHistoryReducer';
import selectedPlacemarkHistoryReducer from './selectedPlacemarkHistoryReducer';
import calendarHistoryReducer from './calendarHistoryReducer';
import departmentsReducer from './departmentsReducer';
import selectedDepartmentReducer from './selectedDepartmentReducer';
import trackersReducer from './trackersReducer';
import selectedCalendarReducer from './selectedCalendarReducer';
import calendarsReducer from './calendarsReducer';
import selectedTrackerReducer from './selectedTrackerReducer';
import collectionsReducer from './collectionsReducer';
import selectedCollectionReducer from './selectedCollectionReducer';
import associationsReducer from './associationsReducer';
import manufacturersReducer from './manufacturersReducer';
import selectedManufacturerReducer from './selectedManufacturerReducer';
import modelsReducer from './modelsReducer';
import selectedModelReducer from './selectedModelReducer';
import typesReducer from './typesReducer';
import selectedTypeReducer from './selectedTypeReducer';
import metadatasReducer from './metadatasReducer';
import selectedMetadataReducer from './selectedMetadataReducer';
import policiesReducer from './policiesReducer';
import selectedPolicyReducer from './selectedPolicyReducer';
import groupsReducer from './groupsReducer';
import selectedGroupReducer from './selectedGroupReducer';
import sessionsReducer from './sessionsReducer';
import exhibitorsReducer from './exhibitorsReducer';
import apiReducer from './apiReducer';

export default combineReducers({
    quickActionsReducer,
    selectedQuickActionReducer,
    eventsReducer,
    selectedEventReducer,
    categoriesReducer,
    selectedCategoryReducer,
    pagesReducer,
    selectedPageReducer,
    usersReducer,
    selectedUserReducer,
    rolesReducer,
    selectedRoleReducer,
    placemarksReducer,
    selectedPlacemarkReducer,
    locationsReducer,
    organisationsReducer,
    selectedOrganisationReducer,
    sitesReducer,
    selectedSiteReducer,
    buildingsReducer,
    spacesReducer,
    levelsReducer,
    timeZonesReducer,
    beaconsReducer,
    spotsReducer,
    overviewsReducer,
    profilesReducer,
    selectedProfileReducer,
    appsReducer,
    selectedAppReducer,
    masterAppsReducer,
    selectedMasterAppReducer,
    formsReducer,
    selectedFormReducer,
    selectedUserPreferencesReducer,
    usersPreferencesReducer,
    selectedAssetReducer,
    assetsReducer,
    assetsOverviewReducer,
    assetLabelsReducer,
    selectedAssetLabelReducer,
    assetsHistoryReducer,
    selectedAssetHistoryReducer,
    notificationsReducer,
    selectedNotificationReducer,
    mergedLevelsReducer,
    selectedMergedLevelReducer,
    mergedBuildingsReducer,
    selectedMergedBuildingReducer,
    placemarksHistoryReducer,
    selectedPlacemarkHistoryReducer,
    calendarHistoryReducer,
    departmentsReducer,
    selectedDepartmentReducer,
    trackersReducer,
    selectedTrackerReducer,
    calendarsReducer,
    selectedCalendarReducer,
    collectionsReducer,
    selectedCollectionReducer,
    associationsReducer,
    manufacturersReducer,
    selectedManufacturerReducer,
    modelsReducer,
    selectedModelReducer,
    typesReducer,
    selectedTypeReducer,
    metadatasReducer,
    selectedMetadataReducer,
    policiesReducer,
    selectedPolicyReducer,
    groupsReducer,
    selectedGroupReducer,
    sessionsReducer,
    exhibitorsReducer,
    apiReducer
});
