import * as ActionType from './ActionType';
import CollectionApi from '../api/CollectionApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getCollectionsResponse = collectionsdata => ({
    type: ActionType.GET_COLLECTIONS_RESPONSE,
    collectionsdata
});

const queuedGetAllCollections = queueApiCall(CollectionApi.getAllCollections);

export function getCollectionsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllCollections()
            .then(collectionsdata => {
                dispatch(getCollectionsResponse(collectionsdata));
                return collectionsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewCollectionResponse = () => ({
    type: ActionType.ADD_NEW_COLLECTION_RESPONSE
});

export const updateExistingCollectionResponse = () => ({
    type: ActionType.UPDATE_EXISTING_COLLECTION_RESPONSE
});

export function saveCollectionAction(collectiondraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return CollectionApi.saveCollection(collectiondraft)
            .then((response) => {
                if (collectiondraft.id) {
                    dispatch(updateExistingCollectionResponse());
                } else {
                    dispatch(addNewCollectionResponse());
                }
                return response;
            }).then((response) => {
                if (collectiondraft.id == "0") {
                    dispatch(getCollectionAction(collectiondraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getCollectionResponse = collectiondata => ({
    type: ActionType.GET_COLLECTION_RESPONSE,
    collectiondata
});

export function getCollectionAction(collectionId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return CollectionApi.getCollection(collectionId)
            .then(collectiondata => {
                dispatch(getCollectionResponse(collectiondata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteCollectionResponse = () => ({
    type: ActionType.DELETE_COLLECTION_RESPONSE
});

const queuedDeleteCollection = queueApiCall(CollectionApi.deleteCollection);

export function deleteCollectionAction(collectionId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteCollection(collectionId)
            .then((response) => {
                dispatch(deleteCollectionResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getCollectionsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}