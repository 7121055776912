import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedTypeReducer = (state = initialState.selectedTypeReducer, action) => {
    switch(action.type) {

        case ActionType.GET_TYPE_RESPONSE: {
            if (action.typedata) {
                return {
                    ...state,
                    typedata: _.assign(action.typedata)
                };
            }
            else {
                return {
                    ...state,
                    typedata: initialState.selectedTypeReducer.typedata
                };
            }

        }

        default: { return state; }
    }
};


export default selectedTypeReducer;