import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import App from '../App';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class AppListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <StyledBox ref={this.dtwrapper}>
                {this.renderPagesTable(this.props)}
            </StyledBox>
        );
    }

    showTooltip(orphaned, status, name) {
        let toolTip = "";

        if (orphaned && status) {//orphaned, but active
            toolTip = "This content has no app location or quick action associated. It will not be displayed in the client app.";
            return toolTip;
        }
        else if (!orphaned && !status) {//not orphaned, but inactive
            toolTip = name + " is not currently active. Please contact support to resolve.";
            return toolTip;
        }
        else if (orphaned && !status) {//orphaned and inactive
            toolTip = "1. This content has no app location or quick action associated. It will not be displayed in the client app. <br> 2. " + name + " is not currently active. Please contact support to resolve.";
            return toolTip;
        }
        else {
            return false;
        }
    }

    renderPagesTable(props) {

        const COLUMNS = [
            {
                property: 'lcname',
                header: 'Application Name',
                render: appdata => {
                    if (appdata.masterApp) {
                        return <Text>{appdata.masterApp.name}</Text>
                    }
                    else {
                        return <Text></Text>
                    }
                }
            },
            {
                property: 'lcparentloc',
                header: 'App Location',
                render: appdata =>
                    <Text>
                        {constants.APP_LPN(appdata.categorylocation, appdata.categoryparent, appdata.categoryname)}
                    </Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: appdata => <Text hidden>{appdata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: appdata => !RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, appdata.id)} icon={<Blank />} /></Box>
                    : appdata.orphaned ?
                        <Box width="24px" direction='row' gap="xsmall">
                            {/*<Button plain disabled={props.deletedAppIds.includes(appdata.id)} onClick={evt => props.handleEdit(evt, appdata.id)} icon={<Edit color="#f04953" />} />*/}
                            <Button plain disabled={props.deletedAppIds.includes(appdata.id)} onClick={evt => props.handleDelete(evt, appdata.id)} icon={<Trash color="#333333" />} />
                        </Box> :
                        <Box width="24px" direction='row' gap="xsmall">
                            {/*<Button plain disabled={props.deletedAppIds.includes(appdata.id)} onClick={evt => props.handleEdit(evt, appdata.id)} icon={<Edit color="#333333" />} />*/}
                            <Button plain disabled={props.deletedAppIds.includes(appdata.id)} onClick={evt => props.handleDelete(evt, appdata.id)} icon={<Trash color="#333333" />} />
                        </Box>
            },
            {
                sortable: false,
                property: 'lcinfo',
                header: "",
                size: "24px",
                render: appdata => /*this.showTooltip(appdata.orphaned, appdata.status, appdata.masterAppName)*/appdata.lcinfo ?
                    <Box align="end">
                        <a data-for={"AppInfo" + appdata.masterAppName} style={{ "height": "24px" }}
                            data-tip={this.showTooltip(appdata.orphaned, appdata.status, appdata.masterAppName)}>
                            <CircleInformation size="medium" color="#F04953" />
                        </a>
                        <ReactTooltip id={"AppInfo" + appdata.masterAppName} place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                    </Box>
                    :
                    null
            },
        ];

        let DATA = [];
        let QuickActions = [];
        this.props.sitesdata.quickActions.forEach((qa) => {
            if (!qa.deleted && qa.type == "APPLICATION") {
                QuickActions.push(
                    qa.foreignId,
                );
            }
        });
        
        if (props.appsdata[0] && props.appsdata[0].count != 0) {
            props.appsdata.sort((p1, p2) => { if (p1.masterApp.name.toLowerCase() < p2.masterApp.name.toLowerCase()) return -1; return 1; });
            props.appsdata.forEach(ev => {
                let p = (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent.toLowerCase() : '';
                let l = (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location.toLowerCase() : '';
                let n = (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name.toLowerCase() : '';
                let lpn = constants.APP_LPN(l, p, n);
                DATA.push({
                    ...ev,
                    lcname: ev.masterApp.name ? ev.masterApp.name.toLowerCase() : '',
                    categoryname: (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name : '',
                    lccategoryname: n,
                    categoryparent: (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '',
                    lccategoryparent: p,
                    categorylocation: (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '',
                    lccategorylocation: l,
                    lcparentloc: lpn,
                    orphaned: ev.category == null && !QuickActions.includes(ev.id),
                    status: ev.masterApp ? ev.masterApp.status : false,
                    masterAppName: ev.masterApp ? ev.masterApp.name : "",
                    get lcinfo() { return (this.orphaned && this.status) || (!this.orphaned && !this.status) || (this.orphaned && !this.status) }
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.masterApp.name.toLowerCase().includes(search) ||
                constants.APP_LPN(d.categorylocation, d.categoryparent, d.categoryname).toLowerCase().includes(search) ||
                (("error".includes(search) || "info".includes(search)) && d.orphaned)
            );
        }

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.appsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Application Name' },
                //    { col: 'lcparentloc', label: 'App Location' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
export default AppListTable;
