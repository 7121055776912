import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactiveCheckBoxes from './common/CheckBoxFilters';

const PaginatedDataTable = withPagination(DataTable);
//const CorrectedDataTable = withCorrectedSearch(DataTable);

class EventListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.timeRanges = {
            boxes: ['Current', 'Upcoming', 'Past'],
            default: ['Current', 'Upcoming', 'Past'],
        };
        this.state = {
            timeFilters: this.timeRanges.default,
            search: "",
        };
    }

    onCheckBoxUpdated = filters => {
        if (this.state.timeFilters.length != filters.length || !filters.every(e => this.state.timeFilters.includes(e))) {
            this.setState({ timeFilters: filters });
        }
    }

    timeFilterEvent(event) {
        if (this.state.timeFilters.length === 3) {
            return true;
        }
        const n = new Date();
        const now = new Date(this.timeFilterConversion(n));
        const start = new Date(event.startdate.slice(0, -1));
        const end = new Date(event.enddate.slice(0, -1));
        const filters = this.state.timeFilters;
        let validDate = false;
        if (filters.includes("Past")) {
            validDate = validDate || end < now;
        }
        if (filters.includes("Current")) {
            validDate = validDate || (start <= now && now <= end);
        }
        if (filters.includes("Upcoming")) {
            validDate = validDate || now < start;
        }
        return validDate;
    }

    timeFilterConversion(date) {
        const d = date.toLocaleString('en-GB', { timeZone: this.props.sitedata.ianaTimeZone });
        const ld = d.split(", ")[0];
        const lt = d.split(", ")[1];
        return ld.split("/")[2] + "-" + ld.split("/")[1] + "-" + ld.split("/")[0] + "T" + lt;
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Spotlight </Heading>
                    <Box direction="row" gap="small" >
                        <Box style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.LOC_EVENTS)) }}>
                            <Select
                                plain
                                placeholder="Select Page Type"
                                labelKey="name"
                                options={[{ name: "Detail Page", type: "Detail" }, { name: "HTML Page", type: "Html" }].concat(this.props.sitedata.sessionsEnabled ? [{ name: "Session Page", type: "Session" }] : [])}
                                onChange={evt => this.props.handleNew(evt)}
                                valueLabel={<Button primary label="Create New" />}
                                icon={null}
                            />
                        </Box>
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "870px" }}>
                            {this.renderEventsTable(this.props, this.timeRanges, this.onCheckBoxUpdated, this.timeFilterEvent.bind(this))}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }

    renderEventsTable(props, timeRanges, onCheckBoxUpdated, timeFilterEvent) {

        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        const siteLocalTimeZone = this.props.sitedata.timeZone ? this.props.sitedata.timeZone.replace("Standard Time", "ST") : "UTC ST";

        const COLUMNS = [
            {
                property: 'spotlightType',
                header: 'Spotlight Type',
                render: eventdata => <Text>{eventdata.spotlightType}</Text>
            },
            {
                property: 'lctitle',
                header: 'Title',
                render: eventdata => <Text>{eventdata.title}</Text>
            },
            {
                property: 'type',
                header: 'Page Type',
                render: eventdata => <Text>{eventdata.type}</Text>
            },
            {
                property: 'lcorganiser',
                header: 'Organiser',
                render: eventdata => <Text>{eventdata.organiser}</Text>
            },
            {
                property: 'tags',
                header: <Box justify="center" height="100%">Tags</Box>,
            },
            {
                property: 'lclocation',
                header: 'Location',
                render: eventdata => <Text>{eventdata.location}</Text>
            },
            {
                property: 'startdate',
                header: <Box justify="center" height="100%">{`Start (${siteLocalTimeZone})`}</Box>,
                render: eventdata => customDateFormatter.format(Date.parse(eventdata.startdate.slice(0, -1))),
            },
            {
                property: 'enddate',
                header: <Box justify="center" height="100%">{`End (${siteLocalTimeZone})`}</Box>,
                render: eventdata => customDateFormatter.format(Date.parse(eventdata.enddate.slice(0, -1))),
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: eventdata => <Text hidden>{eventdata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: eventdata => !RB.canEdit(constants.PERMS.LOC_EVENTS) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, eventdata.id)} icon={<Blank />} /></Box>
                    : <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedEventIds.includes(eventdata.id)} onClick={evt => props.handleEdit(evt, eventdata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedEventIds.includes(eventdata.id)} onClick={evt => props.handleDelete(evt, eventdata.id)} icon={<Trash />} />
                    </Box>,
            },
        ];

        let DATA = [];

        if (props.eventsdata[0] && props.eventsdata[0].count != 0) {
            props.eventsdata.filter(timeFilterEvent).forEach(ev => {
                DATA.push({
                    ...ev,
                    lctitle: ev.title ? ev.title.toLowerCase() : '',
                    lcorganiser: ev.organiser ? ev.organiser.toLowerCase() : '',
                    lclocation: ev.location ? ev.location.toLowerCase() : '',
                    tags: (ev.tagfeatured ? 'Featured ' : ev.tagalert ? 'Alert' : ev.tagbreaking ? 'Breaking' : '') + (ev.tagemployee ? 'Employee Only' : '')
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.title.toLowerCase().includes(search) ||
                d.type.toLowerCase().includes(search) ||
                d.organiser.toLowerCase().includes(search) ||
                d.location.toLowerCase().includes(search) ||
                customDateFormatter.format(Date.parse(d.startdate.slice(0, -1))).toString().toLowerCase().includes(search) ||
                customDateFormatter.format(Date.parse(d.enddate.slice(0, -1))).toString().toLowerCase().includes(search) ||
                ("featured".includes(search) && d.tagfeatured) ||
                ("employee only".includes(search) && d.tagemployee)
            );
        }

        console.log('DATA:', DATA);
        
        if (DATA) {
            DATA.sort((a, b) => {
                return new Date(b.enddate) - new Date(a.enddate);
            });
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                renderExtraHeader={() => <ReactiveCheckBoxes checkboxes={timeRanges} onUpdate={onCheckBoxUpdated} />}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctitle" || c.property === "lcorganiser" || c.property === "lclocation"
                }))}
                data={DATA}
                noLoadingMsg={this.props.eventsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lctitle', label: 'Title' },
                //    { col: 'lcorganiser', label: 'Organiser' },
                //    { col: 'lclocation', label: 'Location' },
                //    { col: 'startdate', label: 'Start Date' },
                //    { col: 'enddate', label: 'End Date' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
// function renderPagination(props) {
//     const prevstartIndex = (props.startIndex || 0) - 10;
//     const nextstartIndex = (props.startIndex || 0) + 10;

//     return (
//         <div></div>
//     );
// }

export default EventListTable;
