import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, myHeadersWithTokenNoIds, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/userrole`;
const SERVER_URL_SU = `${SERVER}/tables/superuser`;
//const SERVER_URL = `${SERVER}/api/userdata`;
const apiqueue = [];

const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof UserApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class UserApi {
    static async getAllUsers(noId = false) {
        // const options = {
        //     method: 'get',
        //     headers: await myHeadersWithToken()
        // }
        // return fetch(`${SERVER_URL}/?$expand=UserRoles/Role&${API_VERSION}`, options).then(response => response.json());
        const options = {
            method: 'get',
            headers: noId ? await myHeadersWithTokenNoIds() : await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?$expand=UserRoles($expand=Role)&$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=UserRoles($expand=Role)&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async saveUser(userdata, noId = false) {
        if (userdata.id && userdata.id != "0") {
            return fetch(`${SERVER_URL}/${userdata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: noId ? await myHeadersWithTokenNoIds() : await myHeadersWithToken(),
                body: JSON.stringify(userdata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: noId ? await myHeadersWithTokenNoIds() : await myHeadersWithToken(),
                body: JSON.stringify(userdata)
            }).then(response => response);
        }
    }

    static async saveSuperUser(userdata) {
        return fetch(`${SERVER_URL_SU}/?${API_VERSION}`, {
            method: 'post',
            headers: await myHeadersWithTokenNoIds(),
            body: JSON.stringify(userdata)
        }).then(response => response);
    }

    static async deleteUser(userId, noId = false) {
        const options = {
            method: 'delete',
            headers: noId ? await myHeadersWithTokenNoIds() : await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/?id=${encodeURIComponent(userId)}&${API_VERSION}`, options)
            .then(response => response);
    }

    static async getUser(userId, noId = false) {
        console.log('API:', userId);
        const options = {
            method: 'get',
            headers: noId ? await myHeadersWithTokenNoIds() : await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/?id=${encodeURIComponent(userId)}&$expand=UserRoles($expand=Role)&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getUserAllRoles(userId) {
        console.log('API:', userId);
        const options = {
            method: 'get',
            headers: await myHeadersWithTokenNoIds()
        }
        return fetch(`${SERVER_URL}/?id=${encodeURIComponent(userId)}&$expand=UserRoles($expand=Role)&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}


applyHocOnClass(UserApi);

export default UserApi;
