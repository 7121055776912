import React from "react";
// eslint-disable-next-line
import { Box, Layer, Button, Text, Heading } from 'grommet';
// eslint-disable-next-line
import { Close, FormClose } from 'grommet-icons';
import constants from '../../constants';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';

export default class PopUpConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: this.props.open, isDisabled: false };
    }
    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({ open: this.props.open });
        }
    }

    render() {
        let { open, isDisabled } = this.state;
        if (open) {
            return (
                    <Layer
                        responsive={false}
                        position="center"
                        modal={true}
                        onClickOutside={() => this.props.onClose()}
                        onEsc={() => this.props.onClose()}
                    >
                        <Box pad="medium" gap="small" width="medium" >
                            <Heading level={3} margin="none">
                                {this.props.header || "Confirm"}
                            </Heading>
                            <Text /*weight="bold"*/>{this.props.text || "Please confirm that you want to delete this item."}</Text>
                            <Text>{this.props.info}</Text>
                            <Box
                                tag="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                                
                            >
                            <StyledButton typeCancel label="Cancel" onClick={() => this.props.onClose()} />
                            <StyledButton typeSave label="Confirm" disabled={isDisabled}
                                onClick={() => {
                                    if (this.props.clickonce) {
                                        this.setState({ isDisabled: true });
                                    }                                   
                                    this.props.onDelete();
                            }} />
                            </Box>
                        </Box>
                    </Layer>
            );
        }
        else {
            return null;
        }
    }
}
