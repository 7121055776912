import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SessionAction from '../action/SessionAction';
import * as SiteAction from '../action/SiteAction';
import SessionListTable from './SessionListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class SessionListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedEvent: 0,
            deletedEventIds: [],
            open: undefined,
            sessionsdataLoading: true,
            sitedataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getSessionsAction()
            .then(response => this.setState({ sessionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- EventList Error', error);
            });
        //this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
        //    .then(response => this.setState({ sitedataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Categories List Error', error);
        //    });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedEvent: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedEvent;

        if (selectedId) {
            this.setState({ selectedEvent: 0, open: undefined });
            //this.setState({ deletedEventId: selectedId });
            this.setState({ deletedEventIds: this.state.deletedEventIds.concat([selectedId]) });
            this.props.action.deleteEventAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- EventList Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push({
            pathname: constants.getSiteLevelRoute() + '/event/0',
            state: { type: event.value.type }
        });
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/event/' + nro);
    }

    render() {
        const { sessionsdata, sitedata } = this.props;
        const { open, selectedEvent, deletedEventIds, sessionsdataLoading, sitedataLoading } = this.state;

        if (sessionsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <SessionListTable sessionsdata={sessionsdata}/>
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    sessionsdata: state.sessionsReducer.sessionsdata,
    //sitedata: state.selectedSiteReducer.sitedata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SessionAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(SessionListContainer);
