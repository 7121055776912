import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Footer, ResponsiveContext, Text } from 'grommet';
import { FormNextLink, FormPreviousLink } from 'grommet-icons';
import { WizardContext } from '.';

export const StepFooterG = ({ steps, currentStep, nextId, onNext, onPrev, onSave, onValidate, numberOfIssue, isDataUploaded, ...rest }) => {
    const size = useContext(ResponsiveContext);
    const [activeIndex, setActiveIndex] = useState(0);
    // for readability, this is used to display numeric value of step on screen,
    // such as step 1 of 3. it will always be one more than the active array index
    const [activeStep, setActiveStep] = useState(activeIndex + 1);
    //const { activeIndex, id, steps, width, setActiveIndex, activeStep } = useContext(WizardContext);
    //const steps = [1, 2, 3];

    //useEffect(() => {
    //    setActiveStep(activeIndex + 1);
    //}, [activeIndex]);
    let issues = currentStep == 1 && numberOfIssue > 0 ? numberOfIssue > 1 ? <b>({numberOfIssue} issues found)</b> : <b>(1 issue found)</b> : "";
    return (
        <Box
            margin={
                !['xsmall', 'small'].includes(size)
                    ? { horizontal: 'medium' }
                    : undefined
            }
            flex={false}
            {...rest}
        >
            <Footer
                border={{ side: 'top', color: 'border' }}
                justify="between"
                pad={
                    !['xsmall', 'small'].includes(size)
                        ? { vertical: 'medium' }
                        : { vertical: 'small', top: 'medium' }
                }
                alignSelf="center"
                //width={width}
                width="100%"
                height="85px"
            >
                <Text /*id={summaryId}*/>
                    Step {currentStep} of {steps} {issues}
                </Text>
                <Box gap="small" direction="row">
                    <Box direction="row" flex>
                        {currentStep > 1 && (
                            <Button
                                //id={previousId}
                                label={
                                    !['xsmall', 'small'].includes(size)
                                        ? //(steps[activeIndex - 1] && steps[activeIndex - 1].title) ||
                                        //`Step ${activeStep - 1} title`
                                        `Previous`
                                        : undefined
                                }
                                icon={<FormPreviousLink />}
                                onClick={() => {
                                    //setActiveIndex(activeIndex - 1);
                                    onPrev();
                                }}
                            />
                        )}
                    </Box>
                    <Button
                        disabled={!isDataUploaded || numberOfIssue}
                        id={nextId}
                        icon={<FormNextLink />}
                        primary
                        reverse
                        label={currentStep === steps ? 'Finish' : 'Next'}
                        //form={`${id}-form`}
                        //type="submit"
                        onClick={() => {
                            if (currentStep < steps) {
                                console.log("hallo", activeIndex, steps - 1);
                                //setActiveIndex(activeIndex + 1);
                                //setActiveStep(activeStep + 1);
                                if (isDataUploaded) {
                                    if (onValidate && currentStep == 1) {
                                        let errors = onValidate();
                                        if (errors) {
                                            //do nothing
                                        }
                                        else
                                            onNext();
                                    }
                                    else
                                        onNext();
                                }
                                else {
                                    //do nothing
                                }
                            }
                            else
                                onSave();
                        }}
                    />
                </Box>
            </Footer>
        </Box>
    );
};

StepFooterG.propTypes = {
    //nextId: PropTypes.string,
};