import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/api/AssetInfo`;
console.log('HOST:', SERVER_URL);

const apiqueue = [];
const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof MetaDataApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class MetaDataApi {
    static async getAllMetadatas() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}?$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=DeviceType,Manufacturer,Model&${API_VERSION}`, options, data.count)

            })
            .catch(error => {
                throw error;
            });
    };

    static async saveMetadata(metadata) {
        if (metadata.id && metadata.id != "0") {
            return fetch(`${SERVER_URL}/${metadata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(metadata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(metadata)
            }).then(response => response);
        }
    }

    static async addMetadata(metadata) {
        return fetch(`${SERVER_URL}/?${API_VERSION}`, {
            method: 'post',
            headers: await myHeadersWithToken(),
            body: JSON.stringify(metadata)
        }).then(response => response);
    }

    static async deleteMetadata(metadataId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${metadataId}/?${API_VERSION}`, options)
            .then(response => response);
    }

    static async getMetadata(metadataId) {
        console.log('API:', metadataId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${metadataId}/?$expand=DeviceType,Manufacturer,Model&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

applyHocOnClass(MetaDataApi);

export default MetaDataApi;
