import React from 'react';
import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';
import constants from '../constants';

// export const adalConfig = {
//     tenant: '105b2061-b669-4b31-92ac-24d304d195dc',
//     clientId: 'ffeb06e5-5419-4898-a9ad-3221501f7983',
//     endpoints: {
//         api: 'ffeb06e5-5419-4898-a9ad-3221501f7983',
//     },
//     cacheLocation: 'sessionStorage',
//     redirectUri: window.location.origin,
//     // popUp: true,
// };

// Dani féle cucc
export const adalConfig = {
    //tenant: '105b2061-b669-4b31-92ac-24d304d195dc',
    ////tenant: 'common', 
    ////tenant: 'fff6a18f-5b07-4ab8-bcc0-360a64e8af65', 
    ////clientId: '04019260-0212-4a7d-ad6a-5359e24ba79e', //dev
    ////clientId: 'ffeb06e5-5419-4898-a9ad-3221501f7983',
    //clientId: 'a8924b94-789b-4afb-9c9c-03cb66a3cb1c', //itg MTF HPE-MTF
    ////clientId: "e36150b0-7727-4b4e-85a4-a8ed3991d3dc", //prod
    ////cliendId: constants.release().AdalclientID,
    ////clientId: 'ec6613d3-63d9-447a-abe8-038aa9cccde1',
    //endpoints: {
    //    //api: '3e254237-1d81-498d-b213-f7eab79ac43d', //dev
    //    api: '377b1853-9640-422d-9316-821b5c24e6e8', //itg MTF HPE-MTF
    //    //api: '380fbeeb-6af6-49d2-85b4-c5bee8650184', //prod
    //    //api: constants.release().Adalapi,
    //},
    //cacheLocation: 'sessionStorage',
    //redirectUri: window.location.origin,
    //// popUp: true,
};

// level: 0: Error, 1: Warning, 2: Info, 3: Verbose
if (typeof window !== 'undefined') {
    window.Logging = {
        // piiLoggingEnabled: false,
        level: 3,
        log: function (message) {
            console.log(`ADAL logging:: ${message}`);
        }
    };
}



export const authContext = new AuthenticationContext(constants.release().adalConfig);

export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, constants.release().adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, constants.release().adalConfig.endpoints.api);

export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};

export const adalGetUser = () => {
    let user = authContext.getCachedUser();
    //authContext.config.extraQueryParameter = "na";
    console.log('User: ', user, authContext.config.extraQueryParameter);
    return user;
};

export const getApiToken = (resourceId) => {
    return new Promise((resolve, reject) => {
        authContext.acquireToken(resourceId, (err, token) => {
            if (!err) {
                resolve(token);
                console.log("asdnoerr", token, resolve(token));
            } else {
                console.log(`Acquire resource token for ${resourceId} has error: ${err}`, token);
                reject(err);
            }
        });
    });
};

const myWithAdalLogin = (authContext, resourceId) => {
    // eslint-disable-next-line
    return function (WrappedComponent, renderLoading, renderError) {
        return class extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    logged: false,
                    error: null,
                };

                adalGetToken(authContext, resourceId)
                    .then((param) => {
                        console.log("ittToken", param);
                        if (this.mounted) {
                            this.setState({ logged: true });
                        } else {
                            this.todoSetState = { logged: true };
                        }
                    })
                    .catch((error) => {
                        const { msg } = error;
                        console.log(error, msg);  // eslint-disable-line
                        if (msg === 'login_required' || msg === 'login required') {
                            if (sessionStorage.getItem("mail"))
                                authContext.config.extraQueryParameter = 'login_hint=' + sessionStorage.getItem("mail");
                            authContext.login();
                        } else if (this.mounted) {
                            this.setState({ error });
                        } else {
                            this.todoSetState = { error };
                        }
                    });
            }

            componentDidMount = () => {
                if(this.props.location.pathname.includes("map")){
                    sessionStorage.setItem("path", this.props.location.pathname);}
                this.mounted = true;
                if (this.todoSetState) {
                    this.setState(this.todoSetState);
                }
            };

            componentWillUnmount = () => {
                this.mounted = false;
            };

            render() {
                const { logged, error } = this.state;
                if (logged) return <WrappedComponent {...this.props} />;
                if (error) return typeof renderError === 'function' ? renderError(error) : null;
                return typeof renderLoading === 'function' ? renderLoading() : null;
            }
        };
    };
};

// export const withOnlyAdalLogin = withAdalLogin(authContext, adalConfig.clientId);
export const withOnlyAdalLogin = myWithAdalLogin(authContext, constants.release().adalConfig.clientId);