// eslint-disable-next-line
import { Box, FormField, Grid, Heading, Image, Button, ResponsiveContext, Tab, Tabs, Text, Meter, Stack, Select, DataTable } from 'grommet';
import { Bar, Pie } from 'react-chartjs-2';
import { Android, Apple, FormNext, User, CircleInformation } from 'grommet-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OverviewAction from '../action/OverviewAction';
import * as BuildingAction from '../action/BuildingAction';
import * as AssetAction from '../action/AssetAction';
import * as AssetsOverviewAction from '../action/AssetsOverviewAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import * as LevelAction from '../action/LevelAction';
import * as SiteAction from '../action/SiteAction';
import AssetUtilizationListTable from './AssetUtilizationListTable';
import constants from '../constants';
import _ from 'lodash';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';
import { Context } from './Context';
import { SERVER } from '../api/serverUrl';
import RB from './common/RBAC';
//import {Tag} from 'grommet-controls'
//import { Tag } from 'grommet-controls'; 
import { NoContent } from './common/NoContent';

const PaginatedDataTable = withPagination(DataTable);
var date = new Date().toLocaleDateString("en-US");
var borderr = false;
const green2 = "#01a982";
const yellow2 = "#fbcc2d";
const red2 = "#ff454f";
const grey2 = "#cccccc";
const elementPerRow = 4;
var monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
var pieColor = [green2, yellow2, red2];

class Home extends Component {
    constructor() {
        super();
        this.state = {
            validating: false,
            value: "",
            loaded: false,
            index: 0,
            selectedTimeSpan: "Last 7 Days",
            selectedBuilding: "All",
            selectedGroup: "All",
            selectedType: "All",
            typeLabelList: ["Available for Use", "Booked"],
            selectedLabel: "All",
            buildingList: [],
            floorList: [],
            labelList: [],
            selectedMergedBuilding: {},
            selectedMergedFloors: [],
            rows: 0,
            departments: [],
            selectedDepartment: "All",
            spacesTimeFilter: "Last 7 Days",
            spacesBuildingFilter: "All",
            spacesFloorFilter: "All",
            overviewsdataLoading: true,
            buildingsdataLoading: true,
            assetsdataLoading: true,
            assetsoverviewdataLoading: true,
            assetlabelsdataLoading: true,
            mergedlevelsdataLoading: true,
            mergedbuildingsdataLoading: true,
            levelsdataLoading: true,
            sitedataLoading: true,
            departmentsdataLoading: true,
            spacesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    spacesdata = -1;
    axisMaxValue(maxValue) {
        if (maxValue <= 5) { return 5 }
        else if (maxValue <= 10) { return 10 }
        else if (maxValue <= 200) { return Math.ceil(maxValue / 10) * 10 }
        else if (maxValue <= 2000) { return Math.ceil(maxValue / 100) * 100 }
        else if (maxValue <= 20000) { return Math.ceil(maxValue / 1000) * 1000 }
        else { return Math.ceil(maxValue / 10000) * 10000 }
    }

    dataMaxValue(data) {
        if (data) {
            if (data.length > 1) {
                const max = data.reduce((prev, current) => (prev.value > current.value) ? prev : current)
                return max.value
            }
            else {
                return 0
            }
        }
    }

    getTimespan(dataType, timeSpan = "") {
        var Type = "";
        if (dataType == "Assets") {
            Type = this.state.selectedTimeSpan;
        }
        else if (dataType == "Spaces") {
            Type = timeSpan;
        }
        switch (Type) {
            case "Last 3 Days":
                return "3";
            case "Last 7 Days":
                return "7";
            case "Last 30 Days":
                return "30";
            case "Last 90 Days":
                return "90";//new Date().getMonth()
            case "Last 6 Months":
                return "182";//new Date().getMonth()
            case "Last 12 Months":
                return "365";//new Date().getMonth()
        }
    }

    getBuildingId(selectedBuilding) {
        if (selectedBuilding == "All") {
            return "";
        }
        else {
            var buildingId = this.props.buildingsdata.find(b => b.meridianName === selectedBuilding)
            if (buildingId) {
                return buildingId.meridianId;
            }
            else {
                return "";
            }
        }
    }

    getLabelId(selectedLabel) {
        console.log("asd1", this.props.assetlabelsdata);
        if (selectedLabel == "All") {
            return "";
        }
        else {
            var labelId = this.props.assetlabelsdata.find(a => a.name === selectedLabel)
            if (labelId) {
                return labelId.id;
            }
            else {
                return "";
            }
        }
    }

    componentWillMount() {
        var index = this.props.location.state ? this.props.location.state.index : 0;
        this.setState({ index: index });
    }

    componentDidMount() {
        this.props.action.getOverviewsAction()
            .then(response => this.setState({ overviewsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- OverviewAction List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- BuildingAction List Error', error);
            });
        this.props.action.getAssetsAction()
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetAction List Error', error);
            });
        this.props.action.getAssetsOverviewAction()
            .then(response => this.setState({ assetsoverviewdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetsOverviewAction List Error', error);
            });
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });

        var options = {
            method: 'get',
            headers: {
                'X-LocID': sessionStorage.getItem('locationId')
            }
        };
        if (constants.release().Feature_assets) {
            fetch(`${SERVER}/tables/placemark?$apply=groupby((Department))`, options)
                .then(response => {
                    this.setState({ departmentsdataLoading: false });
                    if (response.ok) {
                        return response.json();
                    }
                    return [];
                })
                .then(data => {
                    var array = data.filter(f => f.department != null && f.department != "").map(d => d.department)
                    array.unshift("All")
                    this.setState({ departments: array })
                })
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ departments: [], departmentsdataLoading: false, apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                });
        }
        this.spacesApiCall(this.getTimespan("Spaces", this.state.spacesTimeFilter));
    }

    componentWillReceiveProps({ buildingsdata, assetlabelsdata, levelsdata }) {
        if (buildingsdata && buildingsdata.length > 0) {
            buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() > p2.meridianName.toLowerCase()) return 1; return -1; });
        }
        if (assetlabelsdata && assetlabelsdata.length > 0) {
            assetlabelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() > p2.name.toLowerCase()) return 1; return -1; });
        }
        this.setState({ buildingList: buildingsdata, labelList: assetlabelsdata });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.overviewsdata && !this.state.loaded) {
            this.setState({ loaded: true });
        };
    }

    spacesApiCall(time) {
        var options = {
            method: 'get',
            headers: {
                'X-LocID': sessionStorage.getItem('locationId')
            }
        };
        if (constants.release().Feature_assets) {
            fetch(`${SERVER}/api/SpaceUsage?days=`.concat(time).concat('&buildingId=&department=&spaceType='), options)
                .then(response => {
                    this.setState({ spacesdataLoading: false });
                    if (response.ok) {
                        return response.json();
                    }
                    return [];
                })
                .then(data => {
                    this.spacesdata = data;
                    this.setState({ updated: true });
                })
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                });
        }
    }
    resetFilters() {
        this.setState({ spacesBuildingFilter: "All", spacesFloorFilter: "All", selectedDepartment: "All", spacesTimeFilter: "Last 7 Days" });
    }
    chartData(ChartType, DeskOrRoom) {
        var data = [];
        var array = [];
        data = this.spacesdata.filter(s => s.type == DeskOrRoom)

        if (this.state.spacesBuildingFilter != "All") {
            data = data.filter(s => s.building == this.state.spacesBuildingFilter)
        }

        if (this.state.spacesFloorFilter != "All" && this.state.spacesBuildingFilter != "All") {
            data = data.filter(s => s.floor == this.state.spacesFloorFilter)
        }

        if (this.state.selectedDepartment != "All" && ChartType == "department") {
            data = data.filter(s => s.department == this.state.selectedDepartment)
        }

        var sumAvgRoom = 0;
        var countRoom = 0;
        var sumAvgDesk = 0;
        var countDesk = 0;

        data.forEach(s => {
            if (s.type == "Desk") {
                sumAvgDesk = sumAvgDesk + s.usage;
                countDesk++;
            }
            if (s.type == "Conference Room") {
                sumAvgRoom = sumAvgRoom + s.usage;
                countRoom++;
            }
        });

        if (countRoom != 0) var avgRoom = Math.round(sumAvgRoom / countRoom);
        if (countDesk != 0) var avgDesk = Math.round(sumAvgDesk / countDesk);

        if (DeskOrRoom == "Desk" && avgDesk) {
            array.push(100 - avgDesk);;
            array.push(avgDesk);
        }
        else if (DeskOrRoom == "Conference Room" && avgRoom) {
            array.push(100 - avgRoom);
            array.push(avgRoom);
        }

        return array;
    }

    barChartData(DeskOrRoom) {
        var data = [];
        var array = [];

        data = this.spacesdata.filter(s => s.type == DeskOrRoom)

        if (this.state.spacesBuildingFilter != "All") {
            data = data.filter(s => s.building == this.state.spacesBuildingFilter)
        }

        if (this.state.spacesFloorFilter != "All" && this.state.spacesBuildingFilter != "All") {
            data = data.filter(s => s.floor == this.state.spacesFloorFilter)
        }

        this.state.departments.forEach(d => {
            var avg = 0;
            var counter = 0;
            var sumAvg = 0;
            data.forEach(dat => {
                if (dat.department == d && d != "All") {
                    counter++;
                    sumAvg = sumAvg + dat.usage;
                }
                else if (d == "All") {
                    counter++;
                    sumAvg = sumAvg + dat.usage;
                }
            });

            if (counter != 0) avg = Math.round(sumAvg / counter);

            array.push({ label: d, data: avg });
        });

        return array;
    }

    elapsedMinutesSinceSync(syncDate) {
        //make sure syncDate is millisecs
        var current = Date.now();
        var sD = Date.parse(syncDate);
        //if syncDate is invalid
        if (sD > current) {
            return NaN;
        }
        //console.log("Diff: ", (current - sD) / (60 * 1000));
        return (current - sD) / (60 * 1000);
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        if (index == 3 || index == 4) {
            this.resetFilters();
            if (this.state.spacesTimeFilter != "Last 7 Days") {
                this.spacesApiCall(this.getTimespan("Spaces", this.state.spacesTimeFilter));
            }
        }
        this.setState({ index: index });
    }
    handleClick(flag) {
        this.props.history.push({ pathname: constants.getSiteLevelRoute() + '/tags', state: { filter: flag } });
    }
    calculateColor(capacity, occupancy) {
        var percentage
        if (capacity == 0 && occupancy == 0) {
            percentage = 0
        }
        else if (capacity == 0 && occupancy > 0) {
            percentage = 100
        }
        else if (isNaN(Math.floor(occupancy / capacity * 100))) {
            percentage = 0
        }
        else {
            percentage = Math.floor(occupancy / capacity * 100)
        }

        if (percentage <= 75) {
            return green2;
        }
        if (percentage > 75 && percentage < 100) {
            return yellow2;
        }
        if (percentage >= 100) {
            return red2;
        }
    }
    calculateIcon(capacity, occupancy) {
        var percentage
        if (capacity == 0 && occupancy == 0) {
            percentage = 0
        }
        else if (capacity == 0 && occupancy > 0) {
            percentage = 100
        }
        else if (isNaN(Math.floor(occupancy / capacity * 100))) {
            percentage = 0
        }
        else {
            percentage = Math.floor(occupancy / capacity * 100)
        }

        if (percentage <= 75) {
            return <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
        }
        if (percentage > 75 && percentage < 100) {
            return <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />;
        }
        if (percentage >= 100) {
            return <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />;
        }
    }
    calculateOccupancy(capacity, occupancy) {
        let perc
        if (capacity == 0 && occupancy == 0) {
            perc = "0%"
        }
        else if (capacity == 0 && occupancy > 0) {
            perc = "100%+"
        }
        else if (isNaN(Math.floor(occupancy / capacity * 100))) {
            perc = "0%"
        }
        else {
            perc = Math.floor(occupancy / capacity * 100) + "%"
        }

        return perc
    }
    renderCards(index) {
        console.log("index", index, this.state.rows);
        let content = [];
        for (let i = index * elementPerRow; i < ((index + 1) * elementPerRow); i++) {
            content.push(
                <Box>
                    <Text>
                        Card {i}
                    </Text>
                </Box>);
        }
        return content;
    }
    renderRows() {
        for (let i = 0; i < this.state.rows; i++) {
            return (
                <Box direction="row" gap="small">
                    {this.renderCards(i)}
                </Box>);
        }
    }
    updateType(evt) {
        this.setState({ selectedType: evt })
        if (evt == "All") {
            this.setState({ typeLabelList: ["Available for Use", "Desk", "Rooms"] })
        }
        else if (evt == "Desk" || evt == "Room") {
            this.setState({ typeLabelList: ["Available", "In Use"] })
        }
    }
    updateDepartment(option) {
        this.setState({ selectedDepartment: option.value });
    }
    renderSpacesTable(filter) {
        const COLUMNS = [
            {
                property: 'lcsite',
                header: 'Site',
                render: spacesdata => <Text>{spacesdata.site}</Text>
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: spacesdata => <Text>{spacesdata.building}</Text>
            },
            {
                property: 'lcfloor',
                header: 'Floor',
                render: spacesdata => <Text>{spacesdata.floor}</Text>
            },
            {
                property: 'lcspacename',
                header: 'Space Name',
                render: spacesdata => <Text>{spacesdata.space}</Text>
            },
            {
                property: 'lcusage',
                header: 'Usage',
                render: spacesdata => <Text>{Math.round(spacesdata.usage) + "%"}</Text>
            },
            {
                property: 'lcnumberofbookings',
                header: 'Number of Bookings',
                render: spacesdata => <Text>{spacesdata.count}</Text>
            },
            {
                property: 'lcbookinghours',
                header: 'Booked Hours',
                render: spacesdata => <Text>{Math.round(spacesdata.sum)}</Text>
            },
        ];

        let DATA = [];
        let ExpData = [];
        //DATA sort
        if (this.spacesdata[0] && this.spacesdata[0].count != 0) {
            this.spacesdata.sort((p1, p2) => { if (p1.usage > p2.usage) return -1; return 1; });
            this.spacesdata.forEach(ev => {
                if (ev.type == filter) {
                    DATA.push({
                        ...ev,
                        lcsite: ev.site ? ev.site.toLowerCase() : '',
                        lcbuilding: ev.building ? ev.building.toLowerCase() : '',
                        lcfloor: ev.floor ? ev.floor.toLowerCase() : '',
                        lcspacename: ev.space ? ev.space.toLowerCase() : '',
                        lcusage: ev.usage,
                        lcnumberofbookings: ev.count,
                        lcbookinghours: ev.sum
                    });
                    ExpData.push({
                        "Site": ev.site,
                        "Building": ev.building,
                        "Floor": ev.floor,
                        "Space Name": ev.space,
                        "Usage": ev.usage,
                        "Number of Bookings": ev.count,
                        "Booked Hours": ev.sum,
                    });
                }
            });
        }

        const { spacesdataLoading } = this.state;
        if (spacesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    search: c.property === "lcsite" || c.property === "lcbuilding" || c.property === "lcfloor" || c.property === "lcspacename"
                }))}
                data={DATA}
                //sortable
                sortoptions={[
                    { col: 'lcsite', label: 'Site' },
                    { col: 'lcbuilding', label: 'Building' },
                    { col: 'lcfloor', label: 'Floor' },
                    { col: 'lcspacename', label: 'Space Name' },
                    { col: 'lcusage', label: 'Usage' },
                    { col: 'lcnumberofbookings', label: 'Number of Bookings' },
                    { col: 'lcbookinghours', label: 'Booking Hours' }
                ]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                downloadable={true}
                exportdata={ExpData}
            />
        );
    }

    render() {
        const Node = ({ id, ...rest }) => (
            <Box
                id={id}
                basis="xxsmall"
                margin="small"
                pad="medium"
                round="small"
                background="light-4"
                {...rest}
            />
        );
        const connection = (fromTarget, toTarget, { color, ...rest } = {}) => ({
            fromTarget,
            toTarget,
            color: color || "accent-1",
            thickness: "xsmall",
            round: true,
            type: "rectilinear",
            ...rest
        });
        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            //weekday: 'short',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        const { overviewsdata, assetsoverviewdata, assetsdata, buildingsdata, assetlabelsdata, mergedlevelsdata, mergedbuildingsdata, levelsdata, sitedata } = this.props;
        const { loaded, overviewsdataLoading, buildingsdataLoading, assetsdataLoading, assetsoverviewdataLoading, assetlabelsdataLoading, mergedlevelsdataLoading, mergedbuildingsdataLoading, levelsdataLoading, sitedataLoading, departmentsdataLoading, spacesdataLoading } = this.state;

        if (!loaded || overviewsdataLoading || (buildingsdataLoading && (constants.release().Feature_assets || constants.release().Feature_citrix)) || assetsdataLoading || assetsoverviewdataLoading || assetlabelsdataLoading || mergedlevelsdataLoading || mergedbuildingsdataLoading || levelsdataLoading || sitedataLoading || (departmentsdataLoading && constants.release().Feature_assets) || (spacesdataLoading && constants.release().Feature_assets)) {
            return (
                <LoadingIndicator size="medium" error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            )
        }

        const d1 = overviewsdata.locationSyncTimestamp ? customDateFormatter.format(Date.parse(overviewsdata.locationSyncTimestamp)) : "";
        const d2 = overviewsdata.spaceSyncTimestamp ? customDateFormatter.format(Date.parse(overviewsdata.spaceSyncTimestamp)) : "";

        //buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() > p2.meridianName.toLowerCase()) return 1; return -1; });
        //assetlabelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() > p2.name.toLowerCase()) return 1; return -1; });

        var datalist = [];
        var DATA_BAR = [];
        assetlabelsdata.map(l => {
            datalist = assetsoverviewdata.assets.filter(a => a.labels.filter(la => la.id == l.id).length > 0);

            var sum = 0;
            var count = 0;
            var avg = 0;

            datalist.map(d => {
                sum = sum + d.unavailable;
                count++;
            })

            if (count == 0) {
                avg = 0;
            }
            else {
                avg = sum / count;
                DATA_BAR.push({ LabelName: l.name, AvgValue: avg });
            }
        })
        if (DATA_BAR.length > 0) {
            DATA_BAR.sort((p1, p2) => { if (p1.AvgValue < p2.AvgValue) return 1; return -1; });
        }

        const r = [0, 1];


        return (
            <Context.Consumer>
                {values => {

                    return (
                        <Grid fill={true}
                            rows={['0px', 'auto']}
                            columns={['100%', 'flex']}

                            areas={[
                                { name: 'header', start: [0, 0], end: [1, 0] },

                                { name: 'main', start: [0, 1], end: [0, 1] },
                                { name: 'side', start: [1, 0], end: [1, 1] },
                            ]}
                        >
                            <Box gridArea='header' /*background='brand'*/ direction="row"
                                align="center"
                                //pad={{ vertical: "medium" }}
                                pad="medium"
                                justify="between"
                                background={constants.BACKGROUND_COLOR}>
                                {/*<Heading level='2' >Dashboard</Heading>*/}

                            </Box>

                            <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ "min-width": "900px" }}>
                                <form>
                                    <Box pad={{vertical: "small"}}>
                                    <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                                        <Tab title="Overview">
                                            <ResponsiveContext.Consumer>
                                                {size => (
                                                    <Box gap="small" margin={{ "top": "small" }}>
                                                        {values.feature.find(f => f.name == "siteAssetTracking").isEnabled && constants.release().Feature_assets && (overviewsdata.totalTags > 0 || overviewsdata.totalAssets > 0 || overviewsdata.totalPeople > 0 || overviewsdata.totalControlTags > 0) &&
                                                            <Box direction={size.includes("small") ? "column" : "row"} justify="start" gap="small" width="100%" height="270px">
                                                                {overviewsdata.totalTags > 0 && constants.release().Feature_assets && (
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Tags</Text>
                                                                        </Box>
                                                                        <Box pad={{ right: "small" }}>
                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {overviewsdata.healthyTags > 0 ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : overviewsdata.healthyTags == 0 ? (
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.healthyTags} {(overviewsdata.healthyTags == 1) ? 'healthy tag is' : 'healthy tags are'} in this site
                                                                    </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.lowBatteryTags == 0) ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : (overviewsdata.lowBatteryTags / overviewsdata.totalTags * 100 > 0) ? ( //low battery / all * 100 gives %
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.lowBatteryTags} {(overviewsdata.lowBatteryTags == 1) ? 'tag has' : 'tags have'} low battery charge
                                                                    </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.untraceableTags == 0) ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : (overviewsdata.untraceableTags / overviewsdata.totalTags * 100 > 0) ? ( //untraceable / all * 100 gives %
                                                                                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.untraceableTags} {(overviewsdata.untraceableTags == 1) ? 'untraceable tag is' : 'untraceable tags are'} in this site
                                                                    </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.orphanTags == 0) ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : (overviewsdata.orphanTags / overviewsdata.totalTags * 100 > 0) ? ( //untraceable / all * 100 gives %
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.orphanTags} {(overviewsdata.orphanTags == 1) ? 'orphaned tag is' : 'orphaned tags are'} in this site
                                                                    </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </StyledBox>
                                                                )}

                                                                {((overviewsdata.totalAssets > 0 || overviewsdata.totalPeople > 0) && constants.release().Feature_assets) && (
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Trackers</Text>
                                                                        </Box>
                                                                        <Box pad={{ right: "small" }}>
                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.totalAssets} {(overviewsdata.totalAssets == 1) ? 'asset' : 'assets'} at this site
                                                                </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.totalPeople} {(overviewsdata.totalPeople == 1) ? 'person' : 'people'} at this site
                                                                </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.orphanTrackers == 0) ?
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                        :
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />}
                                                                                </Box>
                                                                                {overviewsdata.orphanTrackers > 0 && <Box alignSelf="center">
                                                                                    {overviewsdata.orphanTrackers} {(overviewsdata.orphanTrackers == 1) ? 'untraceable tracker' : 'untraceable trackers'} at this site
                                                                                </Box>}
                                                                                {overviewsdata.orphanTrackers == 0 && <Box alignSelf="center">
                                                                                    All trackers are linked to a tag at this site
                                                                                </Box>}
                                                                            </Box>

                                                                        </Box>
                                                                    </StyledBox>
                                                                )}

                                                                {overviewsdata.totalControlTags > 0 && constants.release().Feature_assets && (
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Control Tags</Text>
                                                                        </Box>
                                                                        <Box pad={{ right: "small" }}>
                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {overviewsdata.healthyControlTags > 0 ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : overviewsdata.healthyControlTags == 0 ? (
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.healthyControlTags} {(overviewsdata.healthyControlTags == 1) ? 'healthy control tag' : 'healthy control tags'} at this site
                                                                    </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.lowBatteryControlTags == 0) ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : (overviewsdata.lowBatteryControlTags / overviewsdata.totalControlTags * 100 > 0) ? ( //low battery / all * 100 gives %
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.lowBatteryControlTags} {overviewsdata.lowBatteryControlTags == 1 ? 'control tag has' : 'control tags have'} low battery charge
                                                                    </Box>
                                                                            </Box>

                                                                            <Box direction="row">
                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                    {(overviewsdata.untraceableControlTags == 0) ? (
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    ) : (overviewsdata.untraceableControlTags / overviewsdata.totalControlTags * 100 > 0) ? ( //untraceable / all * 100 gives %
                                                                                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                    ) : (
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            )}
                                                                                </Box>
                                                                                <Box alignSelf="center">
                                                                                    {overviewsdata.untraceableControlTags} {overviewsdata.untraceableControlTags == 1 ? 'untraceable control tag' : 'untraceable control tags'} at this site
                                                                    </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </StyledBox>
                                                                )}
                                                                {(overviewsdata.totalTags <= 0 || !constants.release().Feature_assets) &&
                                                                    <Box basis="1/3" background="transparent" />}

                                                                {(!(overviewsdata.totalAssets > 0 || overviewsdata.totalPeople > 0) || !constants.release().Feature_assets) &&
                                                                    <Box basis="1/3" background="transparent" />}

                                                                {(overviewsdata.totalControlTags <= 0 || !constants.release().Feature_assets) &&
                                                                    <Box basis="1/3" background="transparent" />}

                                                            </Box>
                                                        }
                                                        {(values.feature.find(f => f.name == "siteMap").isEnabled ||
                                                            (values.feature.find(f => f.name == "roomBooking").isEnabled || values.feature.find(f => f.name == "deskBooking").isEnabled)) &&
                                                            <Box direction={size.includes("small") ? "column" : "row"} justify="between" gap="small" width="100%" height="270px">
                                                                {console.log("SIZE:", size, window.innerWidth)}
                                                                {values.feature.find(f => f.name == "siteMap" /*siteMap*/).isEnabled &&
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Floors</Text>
                                                                        </Box>
                                                                        <Box gap="small" pad={{ right: "small" }}>
                                                                            {overviewsdata.noImageMaps > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        Not all floors have a map
                                                        </Box>
                                                                                </Box>
                                                                            ) : (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            All floors have a map
                                                            </Box>
                                                                                    </Box>
                                                                                )}
                                                                            {overviewsdata.noGpsMaps > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        You haven't set GPS coordinates for all floors
                                                        </Box>
                                                                                </Box>
                                                                            ) : (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            You have set GPS coordinates for all floors
                                                            </Box>
                                                                                    </Box>
                                                                                )}
                                                                        </Box>
                                                                    </StyledBox>
                                                                }
                                                                {(values.feature.find(f => f.name == "deskBooking").isEnabled || values.feature.find(f => f.name == "roomBooking").isEnabled) &&
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Spaces</Text>
                                                                        </Box>
                                                                        <Box gap="small" pad={{ right: "small" }}>
                                                                            {overviewsdata.unmatchedBuildings > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        {overviewsdata.unmatchedBuildings} {(overviewsdata.unmatchedBuildings == 1) ? 'building is' : 'buildings are'} mapped incorrectly
                                                        </Box>
                                                                                </Box>
                                                                            ) : overviewsdata.unmatchedBuildings < 0 ? (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            No data available
                                                        </Box>
                                                                                    </Box>
                                                                            ) : (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        All buildings are mapped correctly
                                                                                    </Box>
                                                                                </Box>
                                                                                )}
                                                                            {overviewsdata.unmatchedLevels > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        {overviewsdata.unmatchedLevels} {(overviewsdata.unmatchedLevels == 1) ? 'level is' : 'levels are'} mapped incorrectly
                                                        </Box>
                                                                                </Box>
                                                                            ) : overviewsdata.unmatchedLevels < 0 ? (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            No data available
                                                        </Box>
                                                                                    </Box>
                                                                            ) : (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        All levels are mapped correctly
                                                                                    </Box>
                                                                                </Box>
                                                                                )}
                                                                            {overviewsdata.unmatchedSpaces > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        {overviewsdata.unmatchedSpaces} {(overviewsdata.unmatchedSpaces == 1) ? 'space is' : 'spaces are'} mapped incorrectly
                                                        </Box>
                                                                                </Box>
                                                                            ) : overviewsdata.unmatchedSpaces < 0 ? (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            No data available
                                                        </Box>
                                                                                    </Box>
                                                                            ) : (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        All spaces are mapped correctly
                                                                                    </Box>
                                                                                </Box>
                                                                                )}
                                                                        </Box>
                                                                    </StyledBox>
                                                                }
                                                                <StyledBox style1 basis="1/3">
                                                                    <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                        <Text style={{ color: "grey" }} size="large">Status</Text>
                                                                    </Box>
                                                                    <Box gap="small" pad={{ right: "small" }}>
                                                                        <Box direction="row">
                                                                            {values.feature.find(f => f.name == "siteMap").isEnabled &&
                                                                                <Box direction="row" data-for={"locationInfo"} data-tip="The sync runs every 60 minutes" flex="shrink">
                                                                                    <ReactTooltip id={"locationInfo"} place="top" type="dark" effect="solid" arrowColor="transparent" />
                                                                                    {overviewsdata.locationSyncTimestamp == null && (this.elapsedMinutesSinceSync(this.props.sitedata.createdAt) / 60) < 24 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Location Services sync has not completed yet. No app data available.
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : this.elapsedMinutesSinceSync(overviewsdata.locationSyncTimestamp) < 40 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Location Services sync is active and up to date
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : this.elapsedMinutesSinceSync(overviewsdata.locationSyncTimestamp) < 120 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Location Services hasn't synced in a while. There may be an issue
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : (
                                                                                                    <Box direction="row">
                                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                            <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                                        </Box>
                                                                                                        <Box alignSelf="center">
                                                                                                            Location Services sync has stopped working. App data may be incorrect
                                                                </Box>
                                                                                                    </Box>
                                                                                                )}
                                                                                </Box>
                                                                            }
                                                                        </Box>

                                                                        <Box direction="row">
                                                                            {(values.feature.find(f => f.name == "deskBooking").isEnabled || values.feature.find(f => f.name == "roomBooking").isEnabled) &&
                                                                                <Box direction="row" data-for={"spacemngmntInfo"} data-tip="The sync runs every 60 minutes" flex="shrink">
                                                                                    <ReactTooltip id={"spacemngmntInfo"} place="top" type="dark" effect="solid" arrowColor="transparent" />
                                                                                    {overviewsdata.spaceSyncTimestamp == null && (this.elapsedMinutesSinceSync(this.props.sitedata.createdAt) / 60) < 24 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center" >
                                                                                                Space Management sync has not completed yet. No app data available.
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : this.elapsedMinutesSinceSync(overviewsdata.spaceSyncTimestamp) < 40 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center" >
                                                                                                Spaces Management sync is active and up to date
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : this.elapsedMinutesSinceSync(overviewsdata.spaceSyncTimestamp) < 120 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Spaces Management hasn't synced in a while. There may be an issue
                                                                </Box>
                                                                                        </Box>
                                                                                    ) : (
                                                                                                    <Box direction="row">
                                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                            <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                                                                                                        </Box>
                                                                                                        <Box alignSelf="center">
                                                                                                            Spaces Management sync has stopped working. App data may be incorrect
                                                                </Box>
                                                                                                    </Box>)}
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                </StyledBox>
                                                                {!(values.feature.find(f => f.name == "siteMap").isEnabled &&
                                                                    (values.feature.find(f => f.name == "roomBooking").isEnabled || values.feature.find(f => f.name == "deskBooking").isEnabled)) &&
                                                                    <Box style1 basis="1/3">
                                                                    </Box>
                                                                }

                                                            </Box>
                                                        }
                                                        {values.feature.find(f => f.name == "siteWayFinding").isEnabled && constants.release().Feature_assets &&
                                                            <Box direction={size.includes("small") ? "column" : "row"} justify="start" gap="small" width="100%" height="270px">
                                                                {constants.release().Feature_assets &&
                                                                    <StyledBox style1 basis="1/3">
                                                                        <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                                            <Text style={{ color: "grey" }} size="large">Beacons</Text>
                                                                        </Box>
                                                                        <Box gap="small" pad={{ right: "small" }}>
                                                                            {overviewsdata.deployedBeacons > 0 ? (
                                                                                <Box direction="row">
                                                                                    <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                        <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                    </Box>
                                                                                    <Box alignSelf="center">
                                                                                        Beacons are configured and deployed in your location
                                                                        </Box>
                                                                                </Box>
                                                                            ) : (
                                                                                    <Box direction="row">
                                                                                        <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                            <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                        </Box>
                                                                                        <Box alignSelf="center">
                                                                                            Beacons aren't configured or deployed in your location
                                                                        </Box>
                                                                                    </Box>
                                                                                )}
                                                                            {overviewsdata.deployedBeacons > 0 &&
                                                                                <Box gap="small">
                                                                                    {overviewsdata.unheardBeacons > 0 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Some beacons haven't been heard from in a while
                                                                        </Box>
                                                                                        </Box>
                                                                                    ) : (
                                                                                            <Box direction="row">
                                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                    <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                                </Box>
                                                                                                <Box alignSelf="center">
                                                                                                    All your beacons are up to date
                                                                        </Box>
                                                                                            </Box>
                                                                                        )}

                                                                                    {overviewsdata.lowBatteryBeacons > 0 ? (
                                                                                        <Box direction="row">
                                                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />
                                                                                            </Box>
                                                                                            <Box alignSelf="center">
                                                                                                Some beacons battery levels aren't good
                                                                        </Box>
                                                                                        </Box>
                                                                                    ) : (
                                                                                            <Box direction="row">
                                                                                                <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                                                    <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
                                                                                                </Box>
                                                                                                <Box alignSelf="center">
                                                                                                    Your beacon battery levels are good
                                                                        </Box>
                                                                                            </Box>
                                                                                        )}
                                                                                </Box>}
                                                                        </Box>
                                                                    </StyledBox>
                                                                }
                                                                <Box basis="2/3" background="transparent" margin={{ "left": "small" }} />
                                                            </Box>
                                                        }
                                                    </Box>
                                                )}
                                            </ResponsiveContext.Consumer>
                                        </Tab>

                                        {RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) && values.feature.find(f => f.name == "siteAssetTracking").isEnabled && overviewsdata.totalTags != 0 && constants.release().Feature_assets &&
                                            <Tab title="Trackers">
                                                <Box margin={{ "top": "small", "bottom": "small" }}>
                                                    <Text size="large" weight="bold">Health Status</Text>
                                                </Box>
                                                <Box gap="medium" width="100%" height="350px" direction="row" alignContent="stretch">
                                                    <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                        <Box border="bottom" direction="row" justify="between" height="35px">
                                                            <Box pad={{ "left": "small" }} justify="end">
                                                                <Text style={{ color: "grey" }} size="large">Healthy</Text>
                                                            </Box>
                                                            <Box border={borderr} align="end" justify="center" pad={{ "right": "xsmall" }}>
                                                                {
                                                                    overviewsdata.healthyTags == overviewsdata.totalTags && overviewsdata.healthyTags != 0 ?
                                                                        (<Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />) :
                                                                        overviewsdata.healthyTags != 0 ?
                                                                            (<Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />)
                                                                            :
                                                                            (<Image width="30" height="30" src="./images/Red.svg" title={constants.RED_LABEL} />)
                                                                }
                                                            </Box>
                                                        </Box>

                                                        <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                            <Stack anchor="center" align="center" justify="center" fill>
                                                                <Box fill margin={{ "top": "none", "bottom": "none" }}>
                                                                    <Meter type="circle" width="50%" style={{ "max-height": "210px" }} alignSelf="center" justify="center"
                                                                        thickness="medium" background={overviewsdata.healthyTags == 0 ? (red2) : (grey2)}
                                                                        values={[
                                                                            {
                                                                                value: overviewsdata.totalTags == 0 ? 0 : overviewsdata.healthyTags / overviewsdata.totalTags * 100,
                                                                                label: "Healthy",
                                                                                color: green2,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                                <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                    <Text size="55px" weight="bold" textAlign="center">
                                                                        {overviewsdata.healthyTags}
                                                                    </Text>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box border={borderr} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                                                            <Text size="small">Tags that are linked and traceable.</Text>
                                                        </Box>
                                                        <Box border="top" height="40px" justify="center" align="end">
                                                            <Stack>
                                                                <Button
                                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "small" }}
                                                                    style={{ color: green2, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                                    size="large"
                                                                    plain="true"
                                                                    label="View"
                                                                    onClick={() => this.handleClick("Healthy")}
                                                                    //icon={<FormNext color="green" />}
                                                                    reverse="true"
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    </StyledBox>

                                                    <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                        <Box border="bottom" direction="row" justify="between" height="35px">
                                                            <Box pad={{ "left": "small" }} justify="end">
                                                                <Text style={{ color: "grey" }} size="large">Untraceable</Text>
                                                            </Box>
                                                            <Box border={borderr} align="end" pad={{ "right": "xsmall" }}>
                                                                {
                                                                    overviewsdata.untraceableTags == 0 ?
                                                                        (<Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />) :
                                                                        false ?
                                                                            (<Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />)
                                                                            :
                                                                            (<Image width="30" height="30" src="./images/Red.svg" title={constants.RED_LABEL} />)
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                            <Stack anchor="center" align="center" justify="center" fill>
                                                                <Box fill margin={{ "top": "none", "bottom": "none" }}>
                                                                    <Meter type="circle" width="50%" style={{ "max-height": "210px" }} alignSelf="center" justify="center"
                                                                        thickness="medium" background={overviewsdata.untraceableTags == 0 ? green2 : grey2}
                                                                        values={[
                                                                            {
                                                                                value: overviewsdata.totalTags == 0 ? 0 : overviewsdata.untraceableTags / overviewsdata.totalTags * 100,
                                                                                label: "Untraceable",
                                                                                color: red2,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                                <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                    <Text size="55px" weight="bold" textAlign="center">
                                                                        {overviewsdata.untraceableTags}
                                                                    </Text>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box border={borderr} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                                                            <Text size="small">Tags that are not transmitting a location.</Text>
                                                        </Box>
                                                        <Box border="top" height="40px" justify="center" align="end">
                                                            <Stack>
                                                                <Button
                                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "small" }}
                                                                    style={{ color: green2, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                                    size="large"
                                                                    plain="true"
                                                                    label="View"
                                                                    onClick={() => this.handleClick("Untraceable")}
                                                                    //icon={<FormNext color="green" />}
                                                                    reverse="true"
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    </StyledBox>

                                                    <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                        <Box border="bottom" direction="row" justify="between" height="35px">
                                                            <Box pad={{ "left": "small" }} justify="end">
                                                                <Text style={{ color: "grey" }} size="large">Low Charge</Text>
                                                            </Box>
                                                            <Box border={borderr} align="end" pad={{ "right": "xsmall" }}>
                                                                {
                                                                    overviewsdata.lowBatteryTags == 0 ?
                                                                        (<Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />) :
                                                                        false ?
                                                                            (<Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />)
                                                                            :
                                                                            (<Image width="30" height="30" src="./images/Red.svg" title={constants.RED_LABEL} />)
                                                                }
                                                            </Box>
                                                        </Box>

                                                        <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                            <Stack anchor="center" align="center" justify="center" fill>
                                                                <Box fill margin={{ "top": "none", "bottom": "none" }}>
                                                                    <Meter type="circle" width="50%" style={{ "max-height": "210px" }} alignSelf="center" justify="center"
                                                                        thickness="medium" background={overviewsdata.lowBatteryTags == 0 ? green2 : grey2}
                                                                        values={[
                                                                            {
                                                                                value: overviewsdata.totalTags == 0 ? 0 : overviewsdata.lowBatteryTags / overviewsdata.totalTags * 100,
                                                                                label: "Low Charge",
                                                                                color: red2,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                                <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                    <Text size="55px" weight="bold" textAlign="center">
                                                                        {overviewsdata.lowBatteryTags}
                                                                    </Text>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box border={borderr} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                                                            <Text size="small">Tags that have a battery level below 20%.</Text>
                                                        </Box>
                                                        <Box border="top" height="40px" justify="center" align="end">
                                                            <Stack>
                                                                <Button
                                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "small" }}
                                                                    style={{ color: green2, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                                    size="large"
                                                                    plain="true"
                                                                    label="View"
                                                                    onClick={() => this.handleClick("Low Charge")}
                                                                    //icon={<FormNext color="green" />}
                                                                    reverse="true"
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    </StyledBox>

                                                    <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                        <Box border="bottom" direction="row" justify="between" height="35px">
                                                            <Box pad={{ "left": "small" }} justify="end">
                                                                <Text style={{ color: "grey" }} size="large">Orphans</Text>
                                                            </Box>
                                                            <Box border={borderr} align="end" pad={{ "right": "xsmall" }}>
                                                                {
                                                                    overviewsdata.orphanTags == 0 ?
                                                                        (<Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />) :
                                                                        true ?
                                                                            (<Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />)
                                                                            :
                                                                            (<Image width="30" height="30" src="./images/Red.svg" title={constants.RED_LABEL} />)
                                                                }
                                                            </Box>
                                                        </Box>

                                                        <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                            <Stack anchor="center" align="center" justify="center" fill>
                                                                <Box fill margin={{ "top": "none", "bottom": "none" }}>
                                                                    <Meter type="circle" width="50%" style={{ "max-height": "210px" }} alignSelf="center" justify="center"
                                                                        thickness="medium" background={overviewsdata.orphanTags == 0 ? green2 : grey2}
                                                                        values={[
                                                                            {
                                                                                value: overviewsdata.totalTags == 0 ? 0 : overviewsdata.orphanTags / overviewsdata.totalTags * 100,
                                                                                label: "Orphans",
                                                                                color: yellow2,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                                <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                    <Text size="55px" weight="bold" textAlign="center">
                                                                        {overviewsdata.orphanTags}
                                                                    </Text>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box border={borderr} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                                                            <Text size="small">Tags that are not currently linked.</Text>
                                                        </Box>
                                                        <Box border="top" height="40px" justify="center" align="end">
                                                            <Stack>
                                                                <Button
                                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "small" }}
                                                                    style={{ color: green2, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                                    size="large"
                                                                    plain="true"
                                                                    label="View"
                                                                    onClick={() => this.handleClick("Orphans")}
                                                                    //icon={<FormNext color="green" />}
                                                                    reverse="true"
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    </StyledBox>
                                                </Box>

                                                {/*<Box margin={{ "top": "medium", "bottom": "small" }}>*/}
                                                {/*    <Text size="large" weight="bold">Asset Utilization</Text>*/}
                                                {/*</Box>*/}

                                                {/*<Box direction="row" gap="none" justify="between" border={borderr} gap="xsmall"*/}
                                                {/*    margin={{ "top": "none", "bottom": "medium", "left": "none", "right": "none" }}>*/}
                                                {/*    <Box direction="row" gap="small">*/}
                                                {/*        <Box direction="row" alignSelf="center" justify="end" width="40px" border={borderr}>*/}
                                                {/*            <Text>Time:</Text>*/}
                                                {/*        </Box>*/}
                                                {/*        <StyledBox style1 alignContent="end" border="true" width="230px">*/}
                                                {/*            <Select*/}
                                                {/*                plain*/}
                                                {/*                options={["Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 6 Months", "Last 12 Months"]}*/}
                                                {/*                value={this.state.selectedTimeSpan}*/}
                                                {/*                onChange={({ option }) => this.setState({ selectedTimeSpan: option },*/}
                                                {/*                    () => this.props.action.getAssetsOverviewAction(this.getTimespan("Assets"), this.getBuildingId(this.state.selectedBuilding), this.getLabelId(this.state.selectedLabel)))}*/}
                                                {/*            />*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}
                                                {/*    <Box direction="row" gap="small">*/}
                                                {/*        <Box direction="row" alignSelf="center" justify="end" width="62px" border={borderr}>*/}
                                                {/*            <Text>Building:</Text>*/}
                                                {/*        </Box>*/}
                                                {/*        <StyledBox style1 alignContent="end" border="true" width="230px">*/}
                                                {/*            <Select*/}
                                                {/*                plain*/}
                                                {/*                options={["All"].concat(this.state.buildingList.map(b => b.meridianName))}*/}
                                                {/*                value={this.state.selectedBuilding}*/}
                                                {/*                onSearch={(searchText) => {*/}
                                                {/*                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');*/}
                                                {/*                    const filtered = buildingsdata.filter(b => b.meridianName.match(regexp));*/}
                                                {/*                    this.setState({ buildingList: filtered });*/}
                                                {/*                }}*/}
                                                {/*                onChange={option => this.setState({ selectedBuilding: option.value },*/}
                                                {/*                    () => this.props.action.getAssetsOverviewAction(this.getTimespan("Assets"), this.getBuildingId(this.state.selectedBuilding), this.getLabelId(this.state.selectedLabel)))}*/}
                                                {/*            />*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}
                                                {/*    <Box direction="row" gap="small">*/}
                                                {/*        <Box direction="row" alignSelf="center" justify="end" width="47px" border={borderr}>*/}
                                                {/*            <Text>Label:</Text>*/}
                                                {/*        </Box>*/}
                                                {/*        <StyledBox style1 alignContent="end" border="true" width="230px">*/}
                                                {/*            <Select*/}
                                                {/*                plain*/}
                                                {/*                options={["All"].concat(this.state.labelList.map(a => a.name))}*/}
                                                {/*                value={this.state.selectedLabel}*/}
                                                {/*                onSearch={(searchText) => {*/}
                                                {/*                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');*/}
                                                {/*                    const filtered = assetlabelsdata.filter(b => b.name.match(regexp));*/}
                                                {/*                    this.setState({ labelList: filtered });*/}
                                                {/*                }}*/}
                                                {/*                onChange={option => this.setState({ selectedLabel: option.value },*/}
                                                {/*                    () => this.props.action.getAssetsOverviewAction(this.getTimespan("Assets"), this.getBuildingId(this.state.selectedBuilding), this.getLabelId(this.state.selectedLabel)))}*/}
                                                {/*            />*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}

                                                {/*    <Box direction="row" gap="small">*/}
                                                {/*        <Box direction="row" alignSelf="center" justify="end" width="47px" border={borderr}>*/}
                                                {/*            <Text>Group:</Text>*/}
                                                {/*        </Box>*/}
                                                {/*        <StyledBox style1 alignContent="end" border="true" width="230px">*/}
                                                {/*            <Select*/}
                                                {/*                plain*/}
                                                {/*                value={this.state.selectedGroup}*/}
                                                {/*                options={["All", "Audiology", "Emergency", "Endoscopy", "Maternity", "Radiology"]}*/}
                                                {/*                onChange={option => this.setState({ selectedGroup: option.value })}*/}
                                                {/*            />*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}

                                                {/*</Box>*/}

                                                {/*<Box direction="column" gap="medium">*/}
                                                {/*    <Box direction="row" gap="medium" height="300px">*/}
                                                {/*        <StyledBox style1 basis="40%">*/}
                                                {/*            <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage</Text>*/}
                                                {/*            <Box pad={{ "bottom": "xsmall" }} height="100%">*/}
                                                {/*                <Pie*/}
                                                {/*                    data={{*/}
                                                {/*                        labels: ["Available for Use", "Servicing", "Booked"],*/}
                                                {/*                        datasets: [{*/}
                                                {/*                            data: [assetsoverviewdata.total.available,*/}

                                                {/*                            (assetsoverviewdata.total.maintenance +*/}
                                                {/*                                assetsoverviewdata.total.maintenanceRequired +*/}
                                                {/*                                assetsoverviewdata.total.cleaning +*/}
                                                {/*                                assetsoverviewdata.total.cleaningRequired),*/}

                                                {/*                            assetsoverviewdata.total.unavailable],*/}
                                                {/*                            borderWidth: 1,*/}
                                                {/*                            borderColor: "#ffffff",*/}
                                                {/*                            backgroundColor: pieColor,*/}
                                                {/*                            hoverBackgroundColor: pieColor*/}
                                                {/*                        }]*/}
                                                {/*                    }}*/}
                                                {/*                    options={{*/}
                                                {/*                        rotation: -0.5 * Math.PI,*/}
                                                {/*                        legend: {*/}
                                                {/*                            position: 'bottom',*/}
                                                {/*                        },*/}
                                                {/*                        responsive: true,*/}
                                                {/*                        maintainAspectRatio: false,*/}
                                                {/*                    }}*/}
                                                {/*                />*/}
                                                {/*            </Box>*/}
                                                {/*        </StyledBox>*/}
                                                {/*        <StyledBox style1 direction="column" basis="60%">*/}
                                                {/*            <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Hours unavailable to the Business</Text>*/}
                                                {/*            <Box border={borderr} direction="column" height="100%" gap="small" margin={{ "left": "medium", "bottom": "medium", "right": "medium", "top": "none" }}>*/}
                                                {/*                <Box border={borderr} direction="row" height="100%" width="100%" gap="medium" justify="between">*/}
                                                {/*                    <StyledBox style1 border={borderr} direction="column" width="100%">*/}
                                                {/*                        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Maintenance required</Text>*/}
                                                {/*                        <Text size="35px" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}> {assetsoverviewdata.total.maintenanceRequired} </Text>*/}
                                                {/*                    </StyledBox>*/}
                                                {/*                    <StyledBox style1 border={borderr} direction="column" width="100%">*/}
                                                {/*                        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Maintenance</Text>*/}
                                                {/*                        <Text size="35px" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}> {assetsoverviewdata.total.maintenance} </Text>*/}
                                                {/*                    </StyledBox>*/}
                                                {/*                </Box>*/}

                                                {/*                <Box border={borderr} direction="row" height="100%" width="100%" gap="medium" justify="between">*/}
                                                {/*                    <StyledBox style1 border={borderr} direction="column" width="100%">*/}
                                                {/*                        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Cleaning required</Text>*/}
                                                {/*                        <Text size="35px" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}> {assetsoverviewdata.total.cleaningRequired} </Text>*/}
                                                {/*                    </StyledBox>*/}
                                                {/*                    <StyledBox style1 border={borderr} direction="column" width="100%">*/}
                                                {/*                        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Cleaning</Text>*/}
                                                {/*                        <Text size="35px" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}> {assetsoverviewdata.total.cleaning} </Text>*/}
                                                {/*                    </StyledBox>*/}
                                                {/*                </Box>*/}

                                                {/*            </Box>*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}
                                                {/*    <Box direction="row" gap="medium" height="300px">*/}
                                                {/*        <StyledBox style1 direction="column" basis="100%">*/}
                                                {/*            <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Average Utilization by Group</Text>*/}
                                                {/*            <Box margin={{ "left": "small", "right": "small" }} height="100%" align="center">*/}
                                                {/*                <Bar*/}
                                                {/*                    data={{*/}
                                                {/*                        labels: DATA_BAR.map(d => d.LabelName).slice(0, 10),*/}
                                                {/*                        datasets: [*/}
                                                {/*                            {*/}
                                                {/*                                data: DATA_BAR.map(d => d.AvgValue).slice(0, 10),*/}
                                                {/*                                borderWidth: 0,*/}
                                                {/*                                backgroundColor: "#FF8300",*/}
                                                {/*                                hoverBackgroundColor: "#FF8300"*/}
                                                {/*                            }*/}
                                                {/*                        ]*/}
                                                {/*                    }}*/}
                                                {/*                    options={{*/}
                                                {/*                        legend: {*/}
                                                {/*                            display: false*/}
                                                {/*                        },*/}
                                                {/*                        scales: {*/}
                                                {/*                            yAxes: [{*/}
                                                {/*                                gridLines: {*/}
                                                {/*                                    display: false,*/}
                                                {/*                                    lineWidth: 3,*/}
                                                {/*                                    color: '#425563'*/}
                                                {/*                                },*/}
                                                {/*                                ticks: {*/}
                                                {/*                                    min: 0,*/}
                                                {/*                                    max: 70,*/}
                                                {/*                                    stepSize: 70 / 5,*/}
                                                {/*                                    max: DATA_BAR.length > 0 ? this.axisMaxValue(DATA_BAR[0].AvgValue) : this.axisMaxValue(0),*/}
                                                {/*                                    stepSize: DATA_BAR.length > 0 ? (this.axisMaxValue(DATA_BAR[0].AvgValue) / 5) : (this.axisMaxValue(0) / 5)*/}
                                                {/*                                }*/}
                                                {/*                            }],*/}
                                                {/*                            xAxes: [{*/}
                                                {/*                                barPercentage: 0.4,*/}
                                                {/*                                gridLines: {*/}
                                                {/*                                    display: false,*/}
                                                {/*                                    lineWidth: 3,*/}
                                                {/*                                    color: '#425563'*/}
                                                {/*                                },*/}
                                                {/*                            }]*/}
                                                {/*                        },*/}
                                                {/*                        responsive: true,*/}
                                                {/*                        maintainAspectRatio: false,*/}
                                                {/*                    }}*/}
                                                {/*                />*/}
                                                {/*            </Box>*/}
                                                {/*        </StyledBox>*/}
                                                {/*    </Box>*/}

                                                {/*    */}{/*<Box direction="row" gap="medium" height="300px">*/}
                                                {/*    */}{/*    <StyledBox style1 basis="1/2">*/}
                                                {/*    */}{/*        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Most Utilized Assets</Text>*/}
                                                {/*    */}{/*        <Box>*/}
                                                {/*    */}{/*            <AssetUtilizationListTable assetsoverviewdata={assetsoverviewdata} backgroundcolor={grey2} colorgreen={green2} coloryellow={yellow2} colorred={red2} descendant={true} />*/}
                                                {/*    */}{/*        </Box>*/}
                                                {/*    */}{/*    </StyledBox>*/}
                                                {/*    */}{/*    <StyledBox style1 basis="1/2">*/}
                                                {/*    */}{/*        <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Least Utilized Assets</Text>*/}
                                                {/*    */}{/*        <Box>*/}
                                                {/*    */}{/*            <AssetUtilizationListTable assetsoverviewdata={assetsoverviewdata} backgroundcolor={grey2} colorgreen={green2} coloryellow={yellow2} colorred={red2} descendant={false} />*/}
                                                {/*    */}{/*        </Box>*/}
                                                {/*    */}{/*    </StyledBox>*/}
                                                {/*    */}{/*</Box>*/}
                                                {/*</Box>*/}

                                            </Tab>
                                        }
                                        {false /*constants.release().Feature_assets*/ &&
                                            <Tab title="Location Services">

                                            </Tab>
                                        }
                                        {values.feature.find(f => f.name == "deskBooking").isEnabled && constants.release().Feature_assets &&
                                            <Tab title="Desks">
                                                <Box margin={{ "top": "small", "bottom": "small" }} direction="row" justify="between">
                                                    <Box direction="row" gap="xsmall" align="center">
                                                        <Text size="large" weight="bold">Desk Usage</Text>
                                                        <a data-for="Usage info" data-tip="Usage is calculated as a percentage of office hours" style={{ "height": "24px" }}>
                                                            <CircleInformation size="medium" />
                                                        </a>
                                                        <ReactTooltip id="Usage info" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                    </Box>
                                                </Box>

                                                <Box direction="row" gap="none" justify="left" border={borderr} gap="large"
                                                    margin={{ "top": "none", "bottom": "medium", "left": "none", "right": "none" }}>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="40px" border={borderr}>
                                                            <Text>Time:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain
                                                                options={["Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 6 Months", "Last 12 Months"]}
                                                                value={this.state.spacesTimeFilter}
                                                                onChange={({ option }) => { this.spacesApiCall(this.getTimespan("Spaces", option)); this.setState({ spacesTimeFilter: option }) }}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="62px" border={borderr}>
                                                            <Text>Building:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain
                                                                options={["All"].concat(this.state.buildingList.map(b => b.meridianName))}
                                                                value={this.state.spacesBuildingFilter}
                                                                onSearch={(searchText) => {
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = buildingsdata.filter(b => b.meridianName.match(regexp));
                                                                    this.setState({ buildingList: filtered });
                                                                }}
                                                                onChange={option => {
                                                                    if (option.value == "All") {
                                                                        this.setState({ spacesFloorFilter: "All" })
                                                                    }
                                                                    this.setState({ spacesBuildingFilter: option.value, floorList: levelsdata.filter(f => f.mergedBuildingName == option.value) })
                                                                }}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="40px" border={borderr}>
                                                            <Text>Floor:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain disabled={this.state.spacesBuildingFilter == "All"}
                                                                options={["All"].concat(this.state.floorList.map(b => b.meridianName))}
                                                                value={this.state.spacesFloorFilter}
                                                                onSearch={(searchText) => {
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = levelsdata.filter(b => b.meridianName.match(regexp));
                                                                    this.setState({ floorList: filtered });
                                                                }}
                                                                onChange={option => this.setState({ spacesFloorFilter: option.value })}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                </Box>

                                                <Box direction="column" gap="medium">
                                                    <Box direction="row" gap="large" height="300px">
                                                        <StyledBox style1 width="100%">
                                                            <Box direction="row" justify="between" height="55px" pad={{ "top": "0px", "right": "0px" }}>
                                                                <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Building</Text>
                                                            </Box>
                                                            {this.chartData("nodepartment", "Desk").length != 0 ?
                                                                <Box pad={{ "bottom": "xsmall" }} height="100%">
                                                                    <Pie
                                                                        data={{
                                                                            labels: this.state.typeLabelList,
                                                                            datasets: [{
                                                                                data: this.chartData("nodepartment", "Desk"),
                                                                                borderWidth: 1,
                                                                                borderColor: "#ffffff",
                                                                                backgroundColor: pieColor,
                                                                                hoverBackgroundColor: pieColor
                                                                            }]
                                                                        }}
                                                                        options={{
                                                                            rotation: -0.5 * Math.PI,
                                                                            legend: {
                                                                                display: true,
                                                                                position: 'bottom',
                                                                                onClick: (evt) => evt.preventDefault()
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                        }}
                                                                    />
                                                                </Box>
                                                                :
                                                                <NoContent/>
                                                            }
                                                        </StyledBox>

                                                        {this.chartData("department", "Desk").length != 0 ?
                                                            <StyledBox style1 width="100%">
                                                                <Box direction="row" justify="between" height="55px" pad={{ "top": "0px", "right": "0px" }}>
                                                                    <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Department</Text>
                                                                    <Box direction="row" gap="small">
                                                                        <Box direction="row" alignSelf="center" justify="end" width="47px" border={borderr}>
                                                                            <Text></Text>
                                                                        </Box>
                                                                        <StyledBox style1 alignContent="end" width="200px">
                                                                            <Select onChange={option => this.updateDepartment(option)} value={this.state.selectedDepartment}
                                                                                options={this.state.departments} />
                                                                        </StyledBox>
                                                                    </Box>
                                                                </Box>
                                                                <Box pad={{ "bottom": "xsmall" }} height="100%">
                                                                    <Pie
                                                                        data={{
                                                                            labels: this.state.typeLabelList,
                                                                            datasets: [{
                                                                                data: this.chartData("department", "Desk"),
                                                                                borderWidth: 1,
                                                                                borderColor: "#ffffff",
                                                                                backgroundColor: pieColor,
                                                                                hoverBackgroundColor: pieColor
                                                                            }]
                                                                        }}
                                                                        options={{
                                                                            rotation: -0.5 * Math.PI,
                                                                            legend: {
                                                                                position: 'bottom',
                                                                                onClick: (evt) => evt.preventDefault()
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </StyledBox>
                                                            :
                                                            <StyledBox style1 width="100%">
                                                                <Box height="55px">
                                                                    <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Department</Text>
                                                                </Box>
                                                                <NoContent />
                                                            </StyledBox>
                                                        }
                                                    </Box>
                                                    <Box direction="row" gap="medium" height="300px">
                                                        <StyledBox style1 direction="column" basis="100%">
                                                            <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Average Usage by Department</Text>
                                                            {this.barChartData("Desk").reduce((prev, current) => prev.data + current.data ) > 0 ?
                                                                <Box margin={{ "left": "small", "right": "small" }} height="100%" align="center">
                                                                <Bar
                                                                    data={{
                                                                        labels: this.barChartData("Desk").map(d => d.label).slice(0, 10),
                                                                        datasets: [
                                                                            {
                                                                                data: this.barChartData("Desk").map(d => d.data).slice(0, 10),
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#FF8300",
                                                                                hoverBackgroundColor: "#FF8300"
                                                                            }
                                                                        ]
                                                                    }}
                                                                    options={{
                                                                        legend: {
                                                                            display: false
                                                                        },
                                                                        scales: {
                                                                            yAxes: [{
                                                                                gridLines: {
                                                                                    display: false,
                                                                                    lineWidth: 3,
                                                                                    color: '#425563'
                                                                                },
                                                                                ticks: {
                                                                                    min: 0,
                                                                                }
                                                                            }],
                                                                            xAxes: [{
                                                                                barPercentage: 0.4,
                                                                                gridLines: {
                                                                                    display: false,
                                                                                    lineWidth: 3,
                                                                                    color: '#425563'
                                                                                },
                                                                            }]
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                    }}
                                                                />
                                                        </Box>
                                                                :
                                                                <NoContent/>
                                                            }
                                                        </StyledBox>
                                                    </Box>

                                                    <Box direction="row" width="100%">
                                                        <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} fill>
                                                            <Text size="small" weight="bold">Average Space Usage</Text>
                                                            {this.renderSpacesTable("Desk")}
                                                        </StyledBox>
                                                    </Box>
                                                </Box>
                                            </Tab>
                                        }
                                        {values.feature.find(f => f.name == "roomBooking").isEnabled && constants.release().Feature_assets &&
                                            <Tab title="Rooms">
                                                <Box margin={{ "top": "small", "bottom": "small" }} direction="row" justify="between">
                                                    <Box direction="row" gap="xsmall" align="center">
                                                        <Text size="large" weight="bold">Room Usage</Text>
                                                        <a data-for="Usage info" data-tip="Usage is calculated as a percentage of office hours" style={{ "height": "24px" }}>
                                                            <CircleInformation size="medium" />
                                                        </a>
                                                        <ReactTooltip id="Usage info" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                    </Box>
                                                </Box>

                                                <Box direction="row" gap="none" justify="left" border={borderr} gap="large"
                                                    margin={{ "top": "none", "bottom": "medium", "left": "none", "right": "none" }}>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="40px" border={borderr}>
                                                            <Text>Time:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain
                                                                options={["Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 6 Months", "Last 12 Months"]}
                                                                value={this.state.spacesTimeFilter}
                                                                onChange={({ option }) => { this.spacesApiCall(this.getTimespan("Spaces", option)); this.setState({ spacesTimeFilter: option }) }}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="62px" border={borderr}>
                                                            <Text>Building:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain
                                                                options={["All"].concat(this.state.buildingList.map(b => b.meridianName))}
                                                                value={this.state.spacesBuildingFilter}
                                                                onSearch={(searchText) => {
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = buildingsdata.filter(b => b.meridianName.match(regexp));
                                                                    this.setState({ buildingList: filtered });
                                                                }}
                                                                onChange={option => {
                                                                    if (option.value == "All") {
                                                                        this.setState({ spacesFloorFilter: "All" })
                                                                    }
                                                                    this.setState({ spacesBuildingFilter: option.value, floorList: levelsdata.filter(f => f.mergedBuildingName == option.value) })
                                                                }}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Box direction="row" alignSelf="center" justify="end" width="40px" border={borderr}>
                                                            <Text>Floor:</Text>
                                                        </Box>
                                                        <StyledBox style1 alignContent="end" border="true" width="300px">
                                                            <Select
                                                                plain disabled={this.state.spacesBuildingFilter == "All"}
                                                                options={["All"].concat(this.state.floorList.map(b => b.meridianName))}
                                                                value={this.state.spacesFloorFilter}
                                                                onSearch={(searchText) => {
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = levelsdata.filter(b => b.meridianName.match(regexp));
                                                                    this.setState({ floorList: filtered });
                                                                }}
                                                                onChange={option => this.setState({ spacesFloorFilter: option.value })}
                                                            />
                                                        </StyledBox>
                                                    </Box>
                                                </Box>

                                                <Box direction="column" gap="medium">
                                                    <Box direction="row" gap="large" height="300px">
                                                        <StyledBox style1 width="100%">
                                                            <Box direction="row" justify="between" height="55px" pad={{ "top": "0px", "right": "0px" }}>
                                                                <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Building</Text>
                                                            </Box>
                                                            {this.chartData("nodepartment", "Conference Room").length != 0 ?
                                                                <Box pad={{ "bottom": "xsmall" }} height="100%">
                                                                    <Pie
                                                                        data={{
                                                                            labels: this.state.typeLabelList,
                                                                            datasets: [{
                                                                                data: this.chartData("nodepartment", "Conference Room"),
                                                                                borderWidth: 1,
                                                                                borderColor: "#ffffff",
                                                                                backgroundColor: pieColor,
                                                                                hoverBackgroundColor: pieColor
                                                                            }]
                                                                        }}
                                                                        options={{
                                                                            rotation: -0.5 * Math.PI,
                                                                            legend: {
                                                                                position: 'bottom',
                                                                                onClick: (evt) => evt.preventDefault()
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                        }}
                                                                    />
                                                                </Box>
                                                                :
                                                                <NoContent/>
                                                            }
                                                        </StyledBox>

                                                        {this.chartData("department", "Conference Room").length != 0 ?
                                                            <StyledBox style1 width="100%">
                                                                <Box direction="row" justify="between" height="55px" pad={{ "top": "0px", "right": "0px" }}>
                                                                    <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Department</Text>
                                                                    <Box direction="row" gap="small">
                                                                        <Box direction="row" alignSelf="center" justify="end" width="47px" border={borderr}>
                                                                            <Text></Text>
                                                                        </Box>
                                                                        <StyledBox style1 alignContent="end" width="200px">
                                                                            <Select onChange={option => this.updateDepartment(option)} value={this.state.selectedDepartment}
                                                                                options={this.state.departments} />
                                                                        </StyledBox>
                                                                    </Box>
                                                                </Box>
                                                                <Box pad={{ "bottom": "xsmall" }} height="100%">
                                                                    <Pie
                                                                        data={{
                                                                            labels: this.state.typeLabelList,
                                                                            datasets: [{
                                                                                data: this.chartData("department", "Conference Room"),
                                                                                borderWidth: 1,
                                                                                borderColor: "#ffffff",
                                                                                backgroundColor: pieColor,
                                                                                hoverBackgroundColor: pieColor
                                                                            }]
                                                                        }}
                                                                        options={{
                                                                            rotation: -0.5 * Math.PI,
                                                                            legend: {
                                                                                position: 'bottom',
                                                                                onClick: (evt) => evt.preventDefault()
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </StyledBox>
                                                            :
                                                            <StyledBox style1 width="100%">
                                                                <Box height="55px">
                                                                    <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Typical Usage by Department</Text>
                                                                </Box>
                                                                <NoContent />
                                                            </StyledBox>
                                                        }
                                                    </Box>
                                                    <Box direction="row" gap="medium" height="300px">
                                                        <StyledBox style1 direction="column" basis="100%">
                                                            <Text size="small" weight="bold" margin={{ "left": "small", "top": "xsmall", "bottom": "small" }}>Average Usage by Department</Text>
                                                            {this.barChartData("Conference Room").reduce((prev, current) => prev.data + current.data) > 0 ?
                                                                <Box margin={{ "left": "small", "right": "small" }} height="100%" align="center">
                                                                    <Bar
                                                                        data={{
                                                                            labels: this.barChartData("Conference Room").map(d => d.label).slice(0, 10),
                                                                            datasets: [
                                                                                {
                                                                                    data: this.barChartData("Conference Room").map(d => d.data).slice(0, 10),
                                                                                    borderWidth: 0,
                                                                                    backgroundColor: "#FF8300",
                                                                                    hoverBackgroundColor: "#FF8300"
                                                                                }
                                                                            ]
                                                                        }}
                                                                        options={{
                                                                            legend: {
                                                                                display: false
                                                                            },
                                                                            scales: {
                                                                                yAxes: [{
                                                                                    gridLines: {
                                                                                        display: false,
                                                                                        lineWidth: 3,
                                                                                        color: '#425563'
                                                                                    },
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    }
                                                                                }],
                                                                                xAxes: [{
                                                                                    barPercentage: 0.4,
                                                                                    gridLines: {
                                                                                        display: false,
                                                                                        lineWidth: 3,
                                                                                        color: '#425563'
                                                                                    },
                                                                                }]
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                        }}
                                                                    />
                                                                </Box>
                                                                :
                                                            <NoContent />
                                                            }
                                                        </StyledBox>
                                                    </Box>

                                                    <Box direction="row" width="100%">
                                                        <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} fill>
                                                            <Text size="small" weight="bold">Average Space Usage</Text>
                                                            {this.renderSpacesTable("Conference Room")}
                                                        </StyledBox>
                                                    </Box>
                                                </Box>
                                            </Tab>
                                        }
                                        {values.feature.find(f => f.name == "siteMobilClient").isEnabled &&
                                            <Tab title="Status">
                                                <StyledBox style1 fill="horizontal"  margin={{ "top": "small" }}>
                                                    <Box pad={{ "left": "small" }} justify="center" height="40px" border="bottom">
                                                        <Text style={{ color: "grey" }} size="large">Releases</Text>
                                                    </Box>
                                                    <Box>
                                                        <Box direction="row">
                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                <Android color="#A4C639" size="medium" />
                                                            </Box>
                                                            <Box alignSelf="center">{/*<a href="https://play.google.com/store/apps/details?id=com.hpe.myworkplace" target="_blank"> Version </a> */}
                                                                Version: {overviewsdata.androidAppVersion || '-'}
                                                            </Box>
                                                        </Box>
                                                        <Box direction="row">
                                                            <Box alignSelf="center" pad="small" style={{ "min-width": "auto" }}>
                                                                <Apple color="#999999" />
                                                            </Box>
                                                            <Box alignSelf="center"> {/*<a href="https://itunes.apple.com/us/app/hpe-my-workplace/id1451567305" target="_blank"> Version </a>*/}
                                                                Version: {overviewsdata.iOSAppVersion || '-'}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </StyledBox>
                                            </Tab>}
                                        {values.feature.find(f => f.name == "siteWellness").isEnabled && constants.release().Feature_citrix &&
                                            <Tab title="Occupancy">
                                                <Box margin={{ "bottom": "small", top: "small" }} direction="row" gap="small">
                                                    <Box alignSelf="center">
                                                        <Text size="large" weight="bold">Building</Text>
                                                    </Box>
                                                    <StyledBox style1 alignContent="end" border="true" width="230px">
                                                        <Select
                                                            value={this.state.selectedMergedBuildingName}
                                                            plain
                                                            options={this.props.mergedbuildingsdata.map(mb => mb.name)}
                                                            placeholder="Select Building"
                                                            onChange={evt => {
                                                                let building = this.props.mergedbuildingsdata.find(b => b.name == evt.option);
                                                                let numberOfFloors = this.props.mergedlevelsdata.filter(l => l.buildingId == building.id).length;
                                                                let rows = Math.ceil(numberOfFloors / elementPerRow);
                                                                this.setState({ selectedMergedBuildingName: evt.option, selectedMergedBuilding: building, selectedMergedFloors: this.props.mergedlevelsdata.filter(l => l.buildingId == building.id).sort(constants.byPropertyCalled("name")), rows: rows });
                                                            }}
                                                        //onChange={evt => this.setState({ selectedMergedBuilding: evt.option, selectedMergedFloors: this.props.mergedlevelsdata.filter(l => l.buildingId == evt.option.id) })}
                                                        />
                                                    </StyledBox>
                                                </Box>
                                                {Object.entries(this.state.selectedMergedBuilding).length != 0 && <Box>
                                                    <Box border={borderr} gap="medium" width="100%" height="370px" direction="row" alignContent="stretch">
                                                        <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                            <Box border="bottom" direction="row" justify="between" height="40px">
                                                                <Box pad={{ "left": "small" }} justify="center">
                                                                    <Text style={{ color: "grey" }} size="large">Current Capacity</Text>
                                                                </Box>
                                                            </Box>

                                                            <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                                <Stack anchor="center" align="center" justify="center" fill>
                                                                    <Box border={borderr} height="100%" margin={{ "top": "none", "bottom": "none" }} name="nana" id="hey">
                                                                        <Meter type="circle" width="30%" alignSelf="center" justify="center"
                                                                            thickness="medium" background={grey2}
                                                                            values={[
                                                                                {
                                                                                    value: 100,
                                                                                    label: "Capacity",
                                                                                    color: grey2,
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Box>
                                                                    <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                        <Text size="50px" weight="bold" textAlign="center">
                                                                            {this.state.selectedMergedBuilding.capacity /*80*/}
                                                                        </Text>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                            <Box border={borderr} align="left" height="40px" justify="end" pad={{ "left": "xsmall" }}>
                                                                <Text size="small">Capacity currently set for this building.</Text>
                                                            </Box>
                                                            <Box border="top" height="40px" justify="center" align="end">
                                                            </Box>
                                                        </StyledBox>

                                                        <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                            <Box border="bottom" direction="row" justify="between" height="40px">
                                                                <Box pad={{ "left": "small" }} justify="center">
                                                                    <Text style={{ color: "grey" }} size="large">Current Occupancy</Text>
                                                                </Box>
                                                                <Box border={borderr} align="end" justify="center" pad={{ "right": "xsmall" }}>
                                                                    {
                                                                        this.calculateIcon(this.state.selectedMergedBuilding.capacity, this.state.selectedMergedBuilding.occupancy)
                                                                    }
                                                                </Box>
                                                            </Box>

                                                            <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                                <Stack anchor="center" align="center" justify="center" fill>
                                                                    <Box border={borderr} height="100%" margin={{ "top": "none", "bottom": "none" }}>
                                                                        <Meter type="circle" width="30%" alignSelf="center" justify="center"
                                                                            thickness="medium" background={yellow2}
                                                                            values={[
                                                                                {
                                                                                    value: 100,
                                                                                    label: "Occupancy",
                                                                                    color: this.calculateColor(this.state.selectedMergedBuilding.capacity, this.state.selectedMergedBuilding.occupancy),
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Box>
                                                                    <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                        <Text size="50px" weight="bold" textAlign="center">
                                                                            {this.state.selectedMergedBuilding.occupancy /*60*/}
                                                                        </Text>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                            <Box border={borderr} align="left" height="40px" justify="end" pad={{ "left": "xsmall" }}>
                                                                <Text size="small">The number of occupants currently in the building.</Text>
                                                            </Box>
                                                            <Box border="top" height="40px" justify="center" align="end">
                                                            </Box>
                                                        </StyledBox>

                                                        <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                            <Box border="bottom" direction="row" justify="between" height="40px">
                                                                <Box pad={{ "left": "small" }} justify="center">
                                                                    <Text style={{ color: "grey" }} size="large">Occupancy %</Text>
                                                                </Box>
                                                                <Box border={borderr} align="end" justify="center" pad={{ "right": "xsmall" }}>
                                                                    {this.calculateIcon(this.state.selectedMergedBuilding.capacity, this.state.selectedMergedBuilding.occupancy)}
                                                                </Box>
                                                            </Box>

                                                            <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                                <Stack anchor="center" align="center" justify="center" fill>
                                                                    <Box border={borderr} height="100%" margin={{ "top": "none", "bottom": "none" }}>
                                                                        <Meter type="circle" width="30%" alignSelf="center" justify="center"
                                                                            thickness="medium" background={grey2}
                                                                            values={[
                                                                                {
                                                                                    //value: 75,
                                                                                    value: Math.floor(this.state.selectedMergedBuilding.occupancy / this.state.selectedMergedBuilding.capacity * 100),
                                                                                    label: "Occupancy %",
                                                                                    color: this.calculateColor(this.state.selectedMergedBuilding.capacity, this.state.selectedMergedBuilding.occupancy),
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Box>
                                                                    <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                        <Text size="50px" weight="bold" textAlign="center">
                                                                            {this.calculateOccupancy(this.state.selectedMergedBuilding.capacity, this.state.selectedMergedBuilding.occupancy)}
                                                                        </Text>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                            <Box border={borderr} align="left" height="40px" justify="end" pad={{ "left": "xsmall" }}>
                                                                <Text size="small">The % of occupancy current reached based on capacity and current occupancy.</Text>
                                                            </Box>
                                                            <Box border="top" height="40px" justify="center" align="end">
                                                            </Box>
                                                        </StyledBox>
                                                    </Box>
                                                    {this.state.selectedMergedFloors.length > 0 && <Box margin={{ "top": "small", "bottom": "small" }} direction="row" gap="small">
                                                        <Text size="large" weight="bold">Floors</Text>
                                                    </Box>}
                                                    {/* <Box gap="medium" width="100%" height="350px" direction="row" alignContent="stretch">
                                        {/*this.state.selectedMergedFloors.map(f =>
                                            <StyledBox style1 border={borderr} height="100%" width="100%" direction="column">
                                                <Box border="bottom" direction="row" justify="between" height="35px">
                                                    <Box pad={{ "left": "small" }} justify="end">
                                                        <Text style={{ color: "grey" }} size="large">{f.name}</Text>
                                                    </Box>
                                                    <Box border={borderr} align="end" justify="center" pad={{ "right": "xsmall" }}>
                                                        {
                                                            this.calculateIcon(f.capacity, f.occupancy)
                                                        }
                                                    </Box>
                                                </Box>

                                                <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                    <Stack anchor="center" align="center" justify="center" fill="true">
                                                        <Box height="200px" margin={{ "top": "none", "bottom": "none" }}>
                                                            <Meter type="circle" width="50%" alignSelf="center" justify="center"
                                                                thickness="medium" background={grey2}
                                                                values={[
                                                                    {
                                                                        value: Math.floor(f.occupancy / f.capacity * 100),
                                                                        label: "Capacity",
                                                                        color: this.calculateColor(f.capacity, f.occupancy),
                                                                    }
                                                                ]}
                                                            />
                                                        </Box>
                                                        <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                            <Text size="55px" weight="bold" textAlign="center">
                                                                {Math.floor(f.occupancy / f.capacity * 100) + "%"/*80*/}
                                                    {/*</Text>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Box border={borderr} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                                                    <Text size="small"></Text>
                                                </Box>
                                                <Box border="top" height="40px" justify="center" align="start">
                                                    <Stack>
                                                        {f.occupancy >= f.capacity ?
                                                            <Box direction="row" gap="small" data-for="main" data-tip>
                                                                <User />
                                                                {f.occupancy + "/" + f.capacity}
                                                            </Box> :
                                                            <Box direction="row" gap="small">
                                                                <User />
                                                                {f.occupancy + "/" + f.capacity}
                                                            </Box>
                                                            }
                                                    </Stack>
                                                    <ReactTooltip id="main" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                                        <span>Based on capacity, this floor is now over occupied, either increase capacity or restrict further access.</span>
                                                    </ReactTooltip>
                                                </Box>
                                            </StyledBox>
                                        )
                                    </Box>*/}
                                                    <Grid columns={{ count: 4, size: "auto" }} gap="medium" >
                                                        {this.state.selectedMergedFloors.map(f =>
                                                            <StyledBox style1 border={borderr} height="350px" width="100%" direction="column">
                                                                <Box border="bottom" direction="row" justify="between" height="40px">
                                                                    <Box pad={{ "left": "small" }} justify="center">
                                                                        <Text style={{ color: "grey" }} size="large">{f.name}</Text>
                                                                    </Box>
                                                                    <Box border={borderr} align="end" justify="center" pad={{ "right": "xsmall" }}>
                                                                        {
                                                                            this.calculateIcon(f.capacity, f.occupancy)
                                                                        }
                                                                    </Box>
                                                                </Box>

                                                                <Box border={borderr} align="center" justify="center" height="250px" pad={{ "top": "small", "bottom": "small" }}>
                                                                    <Stack anchor="center" align="center" justify="center" fill>
                                                                        <Box height="200px" margin={{ "top": "none", "bottom": "none" }}>
                                                                            <Meter type="circle" width="50%" alignSelf="center" justify="center"
                                                                                thickness="medium" background={grey2}
                                                                                values={[
                                                                                    {
                                                                                        value: Math.floor(f.occupancy / f.capacity * 100),
                                                                                        label: "Capacity",
                                                                                        color: this.calculateColor(f.capacity, f.occupancy),
                                                                                    }
                                                                                ]}
                                                                            />
                                                                        </Box>
                                                                        <Box border={borderr} justify="center" align="center" pad={{ "bottom": "7px" }}>
                                                                            <Text size="50px" weight="bold" textAlign="center">
                                                                                {this.calculateOccupancy(f.capacity, f.occupancy)}
                                                                            </Text>
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                                <Box border={borderr} align="left" height="40px" justify="end" pad={{ "left": "xsmall" }}>
                                                                    <Text size="small"></Text>
                                                                </Box>
                                                                <Box border="top" height="40px" justify="center" align="start" pad={{ "left": "xsmall" }}>
                                                                    <Stack>
                                                                        {f.occupancy >= f.capacity && f.occupancy != 0 && f.capacity != 0 ?
                                                                            <Box direction="row" gap="small" data-for="main" data-tip>
                                                                                <User />
                                                                                {f.occupancy + "/" + f.capacity}
                                                                            </Box> :
                                                                            <Box direction="row" gap="small">
                                                                                <User />
                                                                                {f.occupancy + "/" + f.capacity}
                                                                            </Box>
                                                                        }
                                                                    </Stack>
                                                                    <ReactTooltip id="main" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                                                        <span>Based on capacity, this floor is now over occupied, either increase capacity or restrict further access.</span>
                                                                    </ReactTooltip>
                                                                </Box>
                                                            </StyledBox>
                                                        )
                                                        }
                                                    </Grid>
                                                </Box>
                                                }
                                            </Tab>}
                                        </Tabs>
                                    </Box>
                                </form>
                            </Box>
                            <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                            </Box>

                        </Grid >
                    );
                }}
            </Context.Consumer>
        );
    }
}
const mapStateToProps = state => ({
    overviewsdata: state.overviewsReducer.overviewsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
    assetsdata: state.assetsReducer.assetsdata,
    assetsoverviewdata: state.assetsOverviewReducer.assetsoverviewdata,
    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
    mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
    mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
    spacesdata: state.assetsReducer.assetsdata,
    levelsdata: state.levelsReducer.levelsdata,
    sitedata: state.selectedSiteReducer.sitedata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OverviewAction, ...BuildingAction, ...AssetAction, ...AssetsOverviewAction, ...AssetLabelsAction, ...MergedBuildingAction, ...MergedLevelAction, ...LevelAction, ...SiteAction }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
