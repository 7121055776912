import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, CheckBox, Heading, DataTable, Text, TextInput, Grommet, FormField, Select, DateInput, CheckBoxGroup } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls/components/PagingTable';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactiveCheckBoxes from './common/CheckBoxFilters';
import { Context } from './Context';
import { hpe } from 'grommet-theme-hpe';


const PaginatedDataTable = withPagination(DataTable);

class NotificationListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.timeRanges = {
            boxes: ['Sent', 'Scheduled',],
            default: ['Sent', 'Scheduled'],
        };
        this.dtwrapper = React.createRef();
        this.state = {
            filter: false,
            showSent: true,
            showScheduled: true,
            timeFilters: [],//this.timeRanges.default,
            search: "",

            isFilterOpen: false,
            selectedType: [],
            date: []

        }
    }

    onCheckBoxUpdated = filters => {
        if (this.state.timeFilters.length != filters.length || !filters.every(e => this.state.timeFilters.includes(e))) {
            this.setState({ timeFilters: filters });
        }
    }
    timeFilterEvent(event) {
        if (this.state.timeFilters.length === 0 || this.state.timeFilters.length === 2) {
            return true;
        }
        const now = new Date();
        const notidate = new Date(event.schedule);
        const filters = this.state.timeFilters;
        let validDate = false;
        if (filters.includes("Sent")) {
            validDate = validDate || notidate < now;
        }
        if (filters.includes("Scheduled")) {
            validDate = validDate || now < notidate;
        }
        return validDate;
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    convertDateToUTC2(date) {
        let utcDate = new Date(date);
        return new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate(),
            utcDate.getUTCHours(),
            utcDate.getUTCMinutes(),
            utcDate.getUTCSeconds(),
            utcDate.getUTCMilliseconds(),
        );
    }

    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    renderFilters() {
        let typeOptions = ["Notification", "News", "Security", /*"Meet And Greet",*/ "Space"].concat(this.context.feature.find(f => f.name == "session").isEnabled ? ["Session", "Sponsor"] : []);
        const dateFormat = new Intl.DateTimeFormat(undefined, {
            month: 'short',
            day: 'numeric',
        });
        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} /*gap="small"*/>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Type">
                                <Box>
                                    <CheckBoxGroup
                                        options={typeOptions}
                                        value={this.state.selectedType}
                                        onChange={evt => this.setState({ selectedType: evt.value })} />
                                </Box>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedType.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedType: [] })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Status">
                                <Box>
                                    <CheckBoxGroup
                                        options={["Sent", "Scheduled"]}
                                        value={this.state.timeFilters}
                                        onChange={evt => this.setState({ timeFilters: evt.value })} />
                                </Box>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.timeFilters.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ timeFilters: [] })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Date">
                                <DateInput
                                    value={this.state.date}
                                    format={this.state.date.length == 1 ? "mm/dd/yyyy" : "mm/dd/yyyy-mm/dd/yyyy"}
                                    buttonProps={{
                                        label: this.state.date.length == 2 ?
                                            this.state.date[0] == this.state.date[1] ?
                                                `${dateFormat.format(new Date(this.state.date[1]))}`
                                                :
                                                `${dateFormat.format(new Date(this.state.date[0]),)} - ${dateFormat.format(new Date(this.state.date[1]))}`
                                            :
                                            "mm/dd/yyyy",
                                    }}
                                    onChange={ev => {
                                        if(ev.value)
                                            this.setState({ date: ev.value })
                                    }}
                                />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.date.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ date: [] })}
                    />}
                </Box>
            </Box>
        )
    }


    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Notifications</Heading>
                    <Box direction="row" gap="small" >

                        <StyledButton typeSave label='Create New' style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.ORG_ADMINISTRATION)) }} alignSelf="center" onClick={this.props.handleNew} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "1090px" }}>
                            {this.renderNotificationsTable(this.props, this.timeRanges, this.onCheckBoxUpdated, this.timeFilterEvent.bind(this))}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderNotificationsTable(props, timeRanges, onCheckBoxUpdated, timeFilterEvent) {
        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        let COLUMNS = [
            {
                property: 'lctitle',
                header: <Box justify="center" height="100%">Title</Box>,
                render: notification => <Text>{notification.title}</Text>,
                isFixed: true
            },
            {
                property: 'lcsubtitle',
                header: <Box justify="center" height="100%">Subtitle</Box>,
                render: notification => <Text>{notification.subtitle}</Text>
            },
            //{
            //    property: 'lcmessage',
            //    header: <Box justify="center" height="100%">Details</Box>,
            //    size: "300px",
            //    render: notification => <Text>{notification.message}</Text>,
            //    isActive: false
            //},
            {
                property: 'lctype',
                header: <Box justify="center" height="100%">Type</Box>,
                render: notification => <Text>{notification.type}</Text>
            },
            {
                property: 'lctags',
                header: <Box justify="center" height="100%">Tags</Box>,
                render: notification => <Text>{notification.tags}</Text>,
                isActive: false
            },
            //{
            //    property: 'lcdate',
            //    header: <Box justify="center" height="100%">Date</Box>,
            //    render: notification => <Text>{notification.schedule}</Text>
            //},
            {
                property: 'dd',
                header: <Box justify="center" height="100%">Device</Box>,
                render: notification => <Text>{notification.success}/{notification.device}</Text>,
            },
            {
                property: 'uu',
                header: <Box justify="center" height="100%">User</Box>,
                render: notification => <Text>{notification.read}/{notification.audience}</Text>,
            },
            {
                property: 'date',
                header: <Box justify="center" height="100%">Date & Time (UTC)</Box>,
                render: notification => customDateFormatter.format(Date.parse(this.convertDateToUTC2(notification.date))),
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: notification => <Text hidden>{notification.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: notification => (notification.synced || !RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, notification.id)} icon={<Blank />} /></Box>
                    :
                    <Box direction='row' gap="xsmall" width="24px">
                        {/*<Button plain disabled={props.deletedNotificationIds.includes(notification.id)} onClick={evt => props.handleEdit(evt, notification.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedNotificationIds.includes(notification.id)} onClick={evt => props.handleDelete(evt, notification.id)} icon={<Trash />} />
                    </Box>,
            },
        ];


        let DATA = [];

        if (props.notificationsdata[0] && props.notificationsdata[0].count != 0) {
            props.notificationsdata.filter(timeFilterEvent).forEach(ev => {
                DATA.push({
                    ...ev,
                    lctitle: ev.title ? ev.title.toLowerCase() : '',
                    lcsubtitle: ev.subtitle ? ev.subtitle.toLowerCase() : '',
                    //typeName: ["Notification", "News", "Security", "Meet And Greet", "Space","Session","Sponsor"][ev.type - 1],
                    lctype: ev.type ? ev.type.toLowerCase() : '',
                    date: ev.schedule ? /*ev.schedule : "2023-05-31T23:59:59Z",*/ev.schedule : ev.createdAt,
                    lctags: ev.tags ? ev.tags.toLowerCase() : '',
                    lcmessage: ev.message ? ev.message.toLowerCase() : '',
                    dd: ev.hasOwnProperty("success") && ev.hasOwnProperty("device") ? ev.success.toString() + "/" + ev.device.toString() : "",
                    uu: ev.hasOwnProperty("read") && ev.hasOwnProperty("audience") ? ev.read.toString() + "/" + ev.audience.toString() : "",
                    //createdAt: "2023-05-31T23:59:59Z"
                })
            });
            DATA.sort(constants.byPropertyCalled("date", "desc"));
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.title.toLowerCase().includes(search) ||
                d.subtitle.toLowerCase().includes(search) ||
                d.lctype.toLowerCase().includes(search) ||
                customDateFormatter.format(this.convertDateToUTC2(Date.parse(d.date))).toString().toLowerCase().includes(search) ||
                d.lctags.includes(search) ||
                d.dd.includes(search) ||
                d.uu.includes(search)
            );
        }
        if (this.state.selectedType.length > 0) {
            DATA = DATA.filter(d => this.state.selectedType.includes(d.type));
        }
        if (this.state.date.length == 2) {
            if (this.state.date[0] == this.state.date[1]) {
                let end = new Date(this.state.date[1]/*.substring(0, 10)*/);
                end.setHours(0, 0, 0, 0);
                end = end.getTime()
                DATA = DATA.filter(d => {
                    let noti = new Date(d.createdAt/*.substring(0, 10)*/);
                    noti.setHours(0, 0, 0, 0);
                    noti = noti.getTime();
                    if (/*d.title.includes("basic")*/noti == end) {
                        console.log("basic", '\n', noti, '\n', end, '\n', new Date(d.createdAt.substring(0, 10)), '\n', new Date(this.state.date[1].substring(0, 10)));
                        console.log("basic1", '\n', d.createdAt, '\n', this.state.date[1]);
                    }


                    let e = new Date(this.state.date[1]);
                    let en = e.getTime() + 60 * -1000 * e.getTimezoneOffset();

                    let no = new Date(d.date);
                    let not = no.setUTCHours(0,0,0,0);

                    return not == en;
                });
            }
            else {
                let start = new Date(this.state.date[0]/*.substring(0, 10)*/);
                start.setHours(0, 0, 0, 0);
                start = start.getTime();
                let end = new Date(this.state.date[1]/*.substring(0, 10)*/);
                end.setHours(0, 0, 0, 0);
                end = end.getTime();
                DATA = DATA.filter(d => {
                    let noti = new Date(d.createdAt);
                    noti.setHours(0, 0, 0, 0);
                    noti = noti.getTime();

                    let e = new Date(this.state.date[1]);
                    let en = e.getTime() + 60 * -1000 * e.getTimezoneOffset();

                    let s = new Date(this.state.date[0]);
                    let st = s.getTime() + 60 * -1000 * s.getTimezoneOffset();

                    let no = new Date(d.date);
                    let not = no.setUTCHours(0, 0, 0, 0);
                    if (d.title == "bk 2nd future") {
                        console.log("bk", '\n', new Date(st), '\n', new Date(not),'\n', new Date(en) );
                    }
                    return st <= not && not <= en;
                });
            }
        }
        else if (this.state.date.length == 1) {
            let e = new Date(this.state.date[0]);
            let en = e.getTime() + 60 * -1000 * e.getTimezoneOffset();
            DATA = DATA.filter(d => {
                let no = new Date(d.date);
                let not = no.setUTCHours(0, 0, 0, 0);

                return not == en;
            });
        }

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
        }

        console.log('DATA:', DATA);
        let filterCount = 0;
        filterCount += this.state.selectedType.length == 0 ? 0 : 1;
        filterCount += this.state.timeFilters.length == 0 ? 0 : 1;
        filterCount += this.state.date.length == 0 ? 0 : 1;
        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctitle" || c.property === "lcsubtitle" || c.property === "type"
                }))}
                data={DATA}
                noLoadingMsg={this.props.notificationsdata.length != 0}
                //sortoptions={[
                //    {col: 'lctitle', label: 'Title'},
                //    { col: 'lcsubtitle', label: 'Subtitle' },
                //    { col: 'typeName', label: 'Type' },
                //    { col: 'date', label: 'Date' },
                //    ]}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => 
                //    <Box direction="row" justify="between" gap="medium">
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showSent && this.state.showScheduled} onChange />
                //            <Text>All</Text>
                //        </Box>
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showSent} onChange/>
                //            <Text>Sent</Text>
                //        </Box>
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showScheduled} onChange/>
                //            <Text>Scheduled</Text>
                //        </Box>
                //    </Box>}
                //renderExtraHeader={() => <ReactiveCheckBoxes checkboxes={timeRanges} onUpdate={onCheckBoxUpdated} />}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFsearchable={true}
                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFfilterable={true}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({
                    selectedType: [],
                    timeFilters: [],
                    date: []
                })}
                PFisClearVisible={filterCount}
                //PFisClearVisible={(
                //    this.state.selectedType.length == 0 &&
                //    this.state.date.length == 0
                //) ? false : true}
                PFFileters={this.renderFilters()}

                PFdownloadable={0}
                PFcsvdata={[]}

                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
            />
        );
    }
}

export default NotificationListTable;