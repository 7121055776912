import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, CheckBox, Select, SelectMultiple, FormField, Grommet, CheckBoxGroup } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation, Download, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import PrimaryFilter from "./common/PrimaryFilter";
import { Context } from './Context';
import { hpe } from 'grommet-theme-hpe';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class OrgTrackerListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.count = 0;
        this.dtwrapper = React.createRef();
        this.Exp_Data = [];
        this.state = {
            select: [],
            search: "",
            isFilterOpen: false,
            selectedCollections: [],
            department: [],
            departmentId: "",
            link: [],
            class: "",
            type: "",
            site: "",
            siteId: "",
            manufacturerId: "",
            manufacturer: [],
            modelId: "",
            model: [],
            deviceTypeId: "",
            deviceType: [],
            modelSuggestions: [],
            typeSuggestions: []
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }
    clicked = false;

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    onDTUpdated() {
        let self = this;
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (nro != 0) { //this is for the select function
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    if (self.clicked)
                            //        return
                            //    else
                            //        self.clicked = true;
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(trs[idx].getElementsByTagName('th')[0].textContent);
                            //});

                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    renderFilters() {
        const typeList = constants.getTypeByClass(this.state.class);

        let collectionOption = this.props.collectionsdata.sort(constants.byPropertyCalled());
        collectionOption = collectionOption.length > 0 ? collectionOption.concat([{ id: null, name: "" }]) : [];

        let departmentOption = this.props.departmentsdata.sort(constants.byPropertyCalled());
        departmentOption = departmentOption.length > 0 ? departmentOption.concat([{ id: null, name: "" }]) : [];

        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} /*gap="small"*/>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Site">
                                <Select
                                    placeholder="Select"
                                    value={this.state.site}
                                    options={this.props.sitesdata}
                                    labelKey="name"
                                    onChange={evt => this.setState({ site: evt.option, siteId: this.props.sitesdata[evt.selected].id })}
                                />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.site && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ site: "", siteId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Class">
                                <Select placeholder="Select" value={this.state.class} options={this.context.feature.find(f => f.name == "personTracking").isEnabled ? ["Asset", "Person"] : ["Asset"]} onChange={o => { this.setState({ class: o.option }); }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.class && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ class: "", type: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Type">
                                <Select placeholder="Select" value={this.state.type} options={typeList} onChange={o => { this.setState({ type: o.option }); }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.type && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ type: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Manufacturer">
                                {this.props.manufacturersdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        value={this.state.manufacturer}
                                        options={this.props.manufacturersdata.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])}
                                        onChange={o => {
                                            let tmp = o.value;
                                            //let tmp = [...this.state.manufacturer];
                                            //if (Array.isArray(o.option)) {
                                            //    if (tmp.length > 0) {
                                            //        tmp = [];
                                            //    } else if (tmp.length == 0) {
                                            //        tmp = o.option;
                                            //    }
                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                            //} else {
                                            //    tmp.push(o.option);
                                            //}

                                            let modelSuggestions = this.props.metadatas.filter(m => tmp.some(v => v.id == m.manufacturerId)/*m.manufacturerId == o.option.id*/ && m.modelId).sort(constants.byPropertyCalled).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            let typeSuggestions = this.props.metadatas.filter(m => {
                                                if (/*m.manufacturerId == o.option.id*/tmp.some(v => v.id == m.manufacturerId) && m.deviceTypeId) {
                                                    if (this.state.modelId) {
                                                        if (m.modelId == this.state.modelId)
                                                            return m;
                                                        return;
                                                    }
                                                    else {
                                                        if (m.modelId == null)
                                                            return m;
                                                        return;
                                                    }
                                                    return;
                                                }
                                                return;
                                            })
                                                .sort(constants.byPropertyCalled)
                                                .map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            //this.setState({ manufacturerId: o.option.id, manufacturer: o.option, modelSuggestions, typeSuggestions });
                                            //console.log("luc", o, modelSuggestions);
                                            this.setState({ manufacturer: tmp, modelSuggestions, typeSuggestions, modelId: "", model: [], deviceTypeId: "", deviceType: [] });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.manufacturersdata.length == 0 &&
                                    <Select placeholder="Select" options={[]}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.manufacturer.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ manufacturerId: "", manufacturer: [], modelId: "", model: [], deviceTypeId: "", deviceType: [], modelSuggestions: [], typeSuggestions: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Model">
                                {this.state.modelSuggestions.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        value={this.state.model}
                                        options={this.state.modelSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.modelsdata*/}
                                        onChange={o => {
                                            let tmp = o.value;
                                            let typeSuggestions = this.props.metadatas.filter(m =>
                                                //m.manufacturerId == this.state.manufacturerId && m.modelId == o.option.id
                                                this.state.manufacturer.some(v => v.id == m.manufacturerId) && tmp.some(v => v.id == m.modelId)
                                                && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            this.setState({ modelId: o.option.id, model: tmp, typeSuggestions, deviceTypeId: "", deviceType: [] });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.state.modelSuggestions.length == 0 &&
                                    <Select placeholder="Select" options={[]}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.model.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ modelId: "", model: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Model Type">
                                {this.state.typeSuggestions.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        placeholder="Select"
                                        value={this.state.deviceType}
                                        options={this.state.typeSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.typesdata*/}
                                        onChange={o => {
                                            let tmp = o.value;
                                            this.setState({ deviceTypeId: o.option.id, deviceType: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.state.typeSuggestions.length == 0 &&
                                    <Select placeholder="Select" options={[]} />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.deviceType.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ deviceTypeId: "", deviceType: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Collection">
                                {this.props.collectionsdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={collectionOption}
                                        value={this.state.selectedCollections}
                                        onChange={o => {
                                                let tmp = o.value;
                                            //let tmp = [...this.state.selectedCollections];
                                            //if (o.option.length > 1) {
                                            //    if (tmp.length > 0) {
                                            //        tmp = [];
                                            //    } else if (tmp.length == 0) {
                                            //        tmp = o.option;
                                            //    }
                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                            //} else {
                                            //    tmp.push(o.option);
                                            //}
                                            this.setState({ selectedCollections: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.collectionsdata.length == 0 &&
                                    <Select
                                        placeholder="Select Collection"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.collectionsdata}
                                        value={this.state.selectedCollections}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedCollections.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedCollections: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Department">
                                {this.props.departmentsdata.length > 0 &&
                                <SelectMultiple placeholder="Select"
                                    value={this.state.department}
                                    options={departmentOption}
                                    labelKey="name"
                                    valueKey="id"
                                    onChange={o => {
                                        let tmp = o.value;
                                        //let tmp = [...this.state.department];
                                        //if (o.option.length > 1) {
                                        //    if (tmp.length > 0) {
                                        //        tmp = [];
                                        //    } else if (tmp.length == 0) {
                                        //        tmp = o.option;
                                        //    }
                                        //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                        //    tmp = tmp.filter(t => t.id != o.option.id);
                                        //} else {
                                        //    tmp.push(o.option);
                                        //}
                                        this.setState({ department: tmp });
                                    }}
                                    sortSelectedOnClose={false}
                                    showSelectedInline
                                />}
                                {this.props.departmentsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.departmentsdata}
                                        value={this.state.department}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.department.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ department: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Link Status">
                                <CheckBoxGroup
                                    options={["Linked", "Unlinked"]}
                                    value={this.state.link}
                                    onChange={evt => this.setState({ link: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.link.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ link: [] })}
                    />}
                </Box>
            </Box>
        )
    }
    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.lcName.includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.lcClass.includes(search) ||
                d.lcType.includes(search) ||
                d.lcCollection.includes(search) ||
                d.lcDepartment.includes(search) ||
                d.tagName.toLowerCase().includes(search) ||
                ("linked".includes(search) && d.isAssigned) ||
                constants.formatDate(d.updatedAt).toLowerCase().includes(search) ||
                d.lcManufacturer.includes(search) ||
                d.lcModel.includes(search) ||
                d.lcDeviceType.includes(search)
            );
        }
        if (this.state.siteId) {
            data = data.filter(d => d.locationId == this.state.siteId);
        }
        if (this.state.selectedCollections.length > 0) {
            data = data.filter(d => this.state.selectedCollections.map(e => e.id).includes(d.collectionId));
        }
        if (this.state.department.length > 0) {
            data = data.filter(d => this.state.department.map(e => e.id).includes(d.departmentId));
        }
        if (this.state.class == "Asset") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.class == "Person") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.manufacturer.length > 0) {
            data = data.filter(d => this.state.manufacturer.some(m => m.id == d.manufacturerId));
        }
        if (this.state.model.length > 0) {
            data = data.filter(d => this.state.model.some(m => m.id == d.modelId));
        }
        if (this.state.deviceType.length > 0) {
            data = data.filter(d => this.state.deviceType.some(m => m.id == d.deviceTypeId));
        }
        if (this.state.type) {
            data = data.filter(d => d.type == this.state.type);
        }
        if (this.state.link.length == 1 && this.state.link.includes("Linked")) {
            data = data.filter(d => d.isAssigned == true);
        }
        else if (this.state.link.length == 1 && this.state.link.includes("Unlinked")) {
            data = data.filter(d => d.isAssigned == false);
        }
        return data;
    }

    renderTrackerTable(props) {
        let COLUMNS = [
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            {
                property: 'lcName',
                header: 'Name',
                render: t => <Text>{t.name}</Text>,
                isFixed: true
            },
            {
                property: 'siteName',
                header: 'Site',
                render: s => <Text>{s.siteName}</Text>
            },
            {
                property: 'lcClass',
                header: 'Class',
                render: t => <Text>{t.class}</Text>
            },
            {
                property: 'lcType',
                header: 'Type',
                render: t => <Text>{t.type}</Text>
            },
            {
                property: 'lcManufacturer',
                header: 'Manufacturer',
                isActive: false,
                render: t => <Text>{t.manufacturerName}</Text>
            },
            {
                property: 'lcModel',
                header: 'Model',
                isActive: false,
                render: t => <Text>{t.modelName}</Text>
            },
            {
                property: 'lcDeviceType',
                header: 'Model Type',
                isActive: false,
                render: t => <Text>{t.deviceType}</Text>
            },
            {
                property: 'lcCollection',
                header: 'Collection',
                render: t => <Text>{t.collectionName}</Text>
            },
            {
                property: 'lcDepartment',
                header: 'Department',
                render: t => <Text>{t.departmentName}</Text>
            },
            //{
            //    property: 'id',
            //    header: 'ID',
            //    render: t => <Text>{t.id}</Text>
            //},
            {
                property: 'isAssigned',
                header: 'Linked',
                size: "65px",
                render: t => <CheckBox checked={t.isAssigned} disabled />
            },
            {
                property: 'lcTagName',
                header: 'Tag Name',
                render: t => <Text> {t.tagName}</Text>,
                isFixed: true
            },
            {
                property: 'updatedAt',
                header: 'Last Modified',
                render: t => <Text>{constants.formatDate(t.updatedAt)}</Text>
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: t => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, t.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedTrackerIds.includes(t.id)} onClick={evt => props.handleEdit(t.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedTrackerIds.includes(t.id)} onClick={evt => { this.setState({ select: this.state.select.filter(id => id != t.id) }); props.handleDelete("tracker", t.id); }} icon={<Trash />} />
                    </Box>
            },
        ];

        let DATA = [];
        this.Exp_Data = [];
        if (props.trackersdata[0] && props.trackersdata[0].count != 0) {
            props.trackersdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.trackersdata.forEach(ev => {
                let collectionName = ev.collectionId && props.collectionsdata && props.collectionsdata[0] && props.collectionsdata[0].count != 0 ? props.collectionsdata.find(d => d.id == ev.collectionId) : '';
                collectionName = collectionName ? collectionName.name : collectionName;

                let departmentName = ev.departmentId && props.departmentsdata && props.departmentsdata[0] && props.departmentsdata[0].count != 0 ? props.departmentsdata.find(d => d.id == ev.departmentId) : '';
                departmentName = departmentName ? departmentName.name : departmentName;
                let tag = props.associationsdata && props.associationsdata[0] && props.associationsdata[0].count != 0 ? props.associationsdata.find(a => a.assetId == ev.id) : false;
                let isAssigned = tag ? true : false;
                let manufacturerName = ev.manufacturerId && props.manufacturersdata && props.manufacturersdata[0] && props.manufacturersdata[0].count != 0 ? props.manufacturersdata.find(d => d.id == ev.manufacturerId) : '';
                manufacturerName = manufacturerName ? manufacturerName.name : manufacturerName;
                let modelName = ev.modelId && props.modelsdata && props.modelsdata[0] && props.modelsdata[0].count != 0 ? props.modelsdata.find(d => d.id == ev.modelId) : '';
                modelName = modelName ? modelName.name : modelName;
                let deviceTypeName = ev.deviceTypeId && props.typesdata && props.typesdata[0] && props.typesdata[0].count != 0 ? props.typesdata.find(d => d.id == ev.deviceTypeId) : '';
                tag = tag ? tag.beaconName : "";
                deviceTypeName = deviceTypeName ? deviceTypeName.name : deviceTypeName;
                let site = ev.locationId && this.props.sitesdata ? this.props.sitesdata.find(s => s.id == ev.locationId) : "";
                let siteName = site ? site.name : "";
                DATA.push({
                    ...ev,
                    lcName: ev.name ? ev.name.toLowerCase() : '',
                    siteName: siteName,
                    lcClass: ev.class ? ev.class.toLowerCase() : '',
                    lcType: ev.type ? ev.type.toLowerCase() : '',
                    departmentName: departmentName,
                    collectionName: collectionName,
                    lcDepartment: departmentName ? departmentName.toLowerCase() : '',
                    lcCollection: collectionName ? collectionName.toLowerCase() : '',
                    isAssigned: isAssigned,
                    manufacturerName: manufacturerName,
                    modelName: modelName,
                    deviceType: deviceTypeName,
                    lcManufacturer: manufacturerName ? manufacturerName.toLowerCase() : '',
                    lcModel: modelName ? modelName.toLowerCase() : '',
                    lcDeviceType: deviceTypeName ? deviceTypeName.toLowerCase() : '',
                    tagName: tag,
                    lcTagName: tag.toLowerCase()
                });
                this.Exp_Data.push({
                    "ID": ev.id,
                    "Tracker Name": ev.name,
                    "Site": siteName,
                    "Class": ev.class,
                    "Type": ev.type,
                    "Collection Name": collectionName,
                    "Department Name": departmentName,
                    "Linked": isAssigned,
                    "Manufacturer": manufacturerName,
                    "Model": modelName,
                    "Model Type": deviceTypeName,
                    "Tag Name": tag,
                    "Last Modified At (UTC)": ev.updatedAt
                });
            });
        }
        //FILTER
        DATA = this.filterData(DATA);
        this.count = DATA.length;
        console.log('DATA:', DATA);

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
        }

        let filterCount = 0;
        filterCount += this.state.site == "" ? 0 : 1;
        filterCount += this.state.selectedCollections.length == 0 ? 0 : 1;
        filterCount += this.state.department.length == 0 ? 0 : 1;
        filterCount += this.state.link.length == 0 ? 0 : 1;
        filterCount += this.state.class == "" ? 0 : 1;
        filterCount += this.state.type == "" ? 0 : 1;
        filterCount += this.state.manufacturer.length == 0 ? 0 : 1;
        filterCount += this.state.model.length == 0 ? 0 : 1;
        filterCount += this.state.deviceType.length == 0 ? 0 : 1;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcName" || c.property === "lcClass" || c.property === "lcType" || c.property === "lcDepartment"
                }))}
                data={DATA}
                noLoadingMsg={this.props.trackersdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcName', label: 'Tracker Name' },
                //    { col: 'lcClass', label: 'Class' },
                //    { col: 'lcType', label: 'Type' },
                //    { col: 'lcDepartment', label: 'Department' },
                //    { col: 'updatedAt', label: 'Last Modified' }                  
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                select={this.props.select}
                onSelect={list => { this.props.onChangeSelect(list, this.count), this.setState({ select: list }); }}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({ site: "", siteId: "", selectedCollections: [], department: "", departmentId: "", link: "", type: "", class: "", manufacturerId: "", manufacturer: "", modelId: "", model: "", deviceTypeId: "", deviceType: "" })}
                PFisClearVisible={filterCount}
                PFcsvdata={this.Exp_Data}
                PFcsvfilename="orgtrackers-export"
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={DATA.length > 0 ? 1 : 2}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
            />
        );
    }

    updateFilteredColumns = (data) => this.setState({ filteredColumns: data });

    render() {
        let measure = "1460px";
        let activeOnes = this.state.filteredColumns ? this.state.filteredColumns.filter(f => f.isActive == false) : null;
        if (activeOnes) {
            measure = activeOnes.length <= 1 ? "1760px" : measure;
        }
        return (
            <Box>
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Tracker name, ID or classification"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                {/*    open={this.state.isFilterOpen}*/}
                {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                {/*    onResetFilter={() => this.setState({ collection: "", collectionId: "", department: "", departmentId: "", link: "", type: "", class: "" })}*/}
                {/*    isClearVisible={(*/}
                {/*        this.state.collection == "" &&*/}
                {/*        this.state.collectionId == "" &&*/}
                {/*        this.state.department == "" &&*/}
                {/*        this.state.departmentId == "" &&*/}
                {/*        this.state.link == "" &&*/}
                {/*        this.state.class == "" &&*/}
                {/*        this.state.type == ""*/}
                {/*    ) ? false : true}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Class" value={this.state.class} options={["Person", "Asset"]} onChange={o => { this.setState({ class: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.class && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ class: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Type" value={this.state.type} options={typeList} onChange={o => { this.setState({ type: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.type && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ type: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.collection && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ collection: "", collectionId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.department && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ department: "", departmentId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.link != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ link: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox ref={this.dtwrapper} style={{ "min-width": measure }}>
                    {this.renderTrackerTable(this.props)}
                </StyledBox>
            </Box>
        );
    }
}

export default OrgTrackerListTable;
