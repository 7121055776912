import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, DataTable, Layer } from 'grommet';
import { error } from 'util';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';

const PaginatedDataTable = withPagination(DataTable);

var start = undefined;
/*var roles = undefined;*/
//const organisation = { id: "4c5b5f821c2045d1bf2b2248b3167cd9", label: "Hewlett Packard Enterprise" };

class Option extends React.Component {
    render() {
        const { value, selected } = this.props;
        return (
            <Box direction="row" gap="small" align="center" pad="xsmall">
                <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
                {value}
            </Box>
        );
    }
}

export class LocUserEditContainer extends React.Component {
    constructor() {
        super();
        //this.dtwrapper = React.createRef();
        //this.rolefull = [];
        //this.sitesfull = [];
        //this.sitesloc = [];
        //this.entryerrors = [];
        /*this.didupdate = false;*/
        this.state = {
            //checked: false,
            //selected: [],
            //options: [],
            isSaved: false,
            /*loaded: false,*/
            //locationroles: [
            //    //{ location: "HPE Galway", roles: [] },
            //    //{ location: "HPE London", roles: [] },
            //],
            openManageRoles: false,
            manageRoleRoles: [],
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    /*group_err = '';*/
    email_err = '';
    username_err = '';
    showActiveMsg = false;

    ClearErrorMessages() {
        /*this.group_err = '';*/
        this.email_err = '';
        this.username_err = '';
        //this.entryerrors.map((item) => {
        //    item.locationId = "";
        //    item.role = "";
        //})
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));

        this.props.action.getUserAction(this.props.match.params.userId)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-UserEdit Error", error);
            });
        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Role Error", error);
            });
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }
    //externalFunc = (evt) => {
    //    let element = evt.target;
    //    //Search for the TR (table-row) in the hierarchy to get the item's ID
    //    while (element.nodeName != "TR") {
    //        if (element.parentElement) {
    //            element = element.parentElement;
    //        } else {
    //            //we have problem
    //            break;
    //        }
    //    }
    //    let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
    //    if (id != null) {
    //        let hE = this.handleEdit.bind(this);
    //        hE(evt, id);
    //    }
    //}

    //onDTUpdated() {
    //    let node = this.dtwrapper.current;
    //    if (node && node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
    //        let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
    //        //let handleEdit = this.handleEdit;
    //        [...trs].forEach((elem, idx) => {
    //            let insideElements = trs[idx].getElementsByTagName('td');
    //            [...insideElements].forEach((element, nro) => {
    //                if (elem.getElementsByTagName('th')[0]) {
    //                    let tid = elem.getElementsByTagName('th')[0].textContent;

    //                    element.removeEventListener("click", this.externalFunc);
    //                    element.addEventListener("click", this.externalFunc);
    //                    //element.addEventListener("click", function (evt) {
    //                    //    evt.preventDefault();
    //                    //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
    //                    //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
    //                    //});
    //                }
    //                element.addEventListener("mouseover", function (evt) {
    //                    evt.preventDefault();
    //                    elem.style.background = "#E6E6E6";
    //                    elem.style.cursor = 'pointer';
    //                });
    //                element.addEventListener("mouseout", function (evt) {
    //                    evt.preventDefault();
    //                    elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
    //                    elem.style.cursor = 'default';
    //                });
    //            });
    //            let insideElementsTH = trs[idx].getElementsByTagName('th');
    //            [...insideElementsTH].forEach((element, nro) => {
    //                element.addEventListener("mouseover", function (evt) {
    //                    evt.preventDefault();
    //                    elem.style.background = "#E6E6E6";
    //                    elem.style.cursor = 'pointer';
    //                });
    //                element.addEventListener("mouseout", function (evt) {
    //                    evt.preventDefault();
    //                    elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
    //                    elem.style.cursor = 'pointer';
    //                });
    //            });
    //        });
    //    }
    //}
    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.userdata && !_.isEqual(start, this.props.userdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        /*this.onDTUpdated();*/
        //if (this.props.rolesdata && this.props.rolesdata.length > 0 && this.rolefull.length === 0) {
        //    this.rolefull = this.props.rolesdata.filter(r => r.level == constants.ROLE_LEVEL.LOC).slice();
        //    this.rolefull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //    this.setState({ options: this.rolefull });
        //}
        //if (this.props.userdata && this.state.selected.length === 0 && !this.didupdate && this.sitesfull.length > 0) {
        //    this.setState({ selected: this.props.userdata.userRoles.map(r => r.role.name) });
        //    var sf = [...this.sitesfull];
        //    var lr = [];
        //    this.props.userdata.userRoles.forEach(function (item, id) {

        //        if (lr.length == 0) {
        //            let loc = sf.find(l => l.id == item.locationId);
        //            if (loc) {
        //                let name = loc.name;
        //                lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
        //            }
        //        }
        //        else {
        //            var index = -1;
        //            lr.forEach(function (element, i) {
        //                if (element.locationId == item.locationId) {
        //                    index = i;
        //                }
        //                else {

        //                }
        //            })
        //            if (index > -1) {
        //                lr[index].roles.push(item.role.name);
        //            }
        //            else {
        //                let loc = sf.find(l => l.id == item.locationId);
        //                if (loc) {
        //                    let name = loc.name;
        //                    lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
        //                }
        //            }

        //        }
        //    })
        //    console.log("Created:", lr);
        //    roles = JSON.parse(JSON.stringify(lr));
        //    this.setState({ locationroles: lr });

        //    this.didupdate = true;
        //}
        //if (this.props.sitesdata.length > 0 && !this.state.loaded) {
        //    this.sitesfull = this.props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId")).slice();
        //    this.sitesfull.map((info, idx) => {
        //        this.entryerrors.push({ locationId: "", role: "" });

        //    })
        //    this.sitesloc = this.sitesfull.filter(s => s.id == sessionStorage.getItem("locationId")).slice();
        //    //console.log('SDATA:', this.sitesfull, this.sitesloc, sessionStorage.getItem("locationId"));
        //    this.setState({ loaded: true });
        //}
    }

    //renderSelected = () => {
    //    const { selected } = this.state;
    //    return (
    //        <Box pad={{ horizontal: "small" }} fill >
    //            {selected.map((item, idx) => <Box><TextInput key={idx} plain readOnly value={item} /> </Box>)}
    //        </Box>
    //    );
    //}

    //renderSelected1 = (id) => {
    //    const { selected, locationroles } = this.state;
    //    return (
    //        <Box pad={{ horizontal: "small" }} /*direction="row" gap="xsmall" border="all" fill="vertical"*/>
    //            {/*locationroles[id].roles.map((item, idx) => <Box><Text>{item}</Text> </Box>)*/}
    //            {locationroles[id].roles.map((item, idx) => <Box key={idx}><TextInput key={idx} plain readOnly value={item} /> </Box>)}
    //            {/*locationroles[id].roles.map((item, idx) => <Box ><Text>{item}</Text> </Box>)*/}
    //        </Box>
    //    );
    //}

    handleSave() {
        const userdefault = this.props.userdata;
        if (userdefault.id == "0") {
            userdefault.active = true;
        }
        /* const { selected, locationroles } = this.state;*/
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (userdefault.username.trim().length == 0) {
            errorsno++;
            this.username_err = "Username is required";
        }
        if (userdefault.email.trim().length == 0) {
            errorsno++;
            this.email_err = "E-mail is required"
        }
        if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
            errorsno++;
            this.email_err = "E-mail is not correct";
        }
        //if (locationroles.length != 0) {
        //    locationroles.map((item, i) => {
        //        //if (item.locationId == "") {
        //        //    errorsno++;
        //        //    this.entryerrors[i].locationId = "Select Site";
        //        //    console.log("Select Site");
        //        //}
        //        if (item.roles.length == 0) {
        //            errorsno++;
        //            this.entryerrors[i].role = "Select Group";
        //            console.log("Select Group");
        //        }
        //    })
        //}
        if (errorsno == 0) {
            this.setState({ disabledButton: true });
            //updating roles
            /*this.updateRoles();*/
            //updating roles 
            console.log('Save: OK', userdefault);
            this.props.action.saveUserAction(userdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/locusers');
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        //this.updateRoles();
        if (!this.state.cancel && !_.isEqual(start, this.props.userdata)) {
            this.setState({ cancel: true });
        }
        else {
            const userId = this.props.userdata.id;
            this.props.action.getUserAction(userId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-UserEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
                });
            this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
        }
    }
    //updateRoles() {
    //    const userdefault = this.props.userdata;
    //    const { selected, locationroles } = this.state;
    //    const locid = sessionStorage.getItem("locationId");
    //    userdefault.userRoles = [];
    //    this.rolefull.map((item) => {
    //        locationroles.map((element) => {
    //            if (element.roles.indexOf(item.name) >= 0) {
    //                const ur = { role: item, locationId: locid, organizationId: sessionStorage.getItem("organisationId"), userid: userdefault.email, roleid: item.id };
    //                userdefault.userRoles.push(ur);
    //            }
    //        })
    //    })
    //}
    //updateActiveValue(evt) {
    //    this.setState({ checked: !this.state.checked });
    //    this.props.userdata.active = evt.target.checked;
    //}
    updateUsernameValue(evt) {
        this.props.userdata.username = evt.target.value;
        this.setState({ updated: true });
    }
    updateEmailValue(evt) {
        this.props.userdata.email = evt.target.value;
        this.props.userdata.userRoles.map(r => r.userId = evt.target.value);
        this.setState({ updated: true });
    }
    //renderOptions() {

    //    const loc = [];
    //    const idlist = [];
    //    this.state.locationroles.forEach(function (element, id) {
    //        idlist.push(element.locationId);
    //    })
    //    this.sitesloc.forEach(function (item, idx) {
    //        const isIncluded = idlist.includes(item.id);
    //        if (!isIncluded) {
    //            loc.push(item.name);
    //        }
    //    })
    //    //console.log('RO:', loc);
    //    return loc;
    //}
    //UpdateL(evt, id) {
    //    const entries = [...this.state.locationroles];
    //    var locid = this.sitesfull.find(l => l.name == evt.option).id;
    //    entries[id].locationId = locid;
    //    entries[id].name = evt.option;
    //    this.setState({ locationroles: entries });
    //}
    //DeleteL(id) {
    //    const entries = [...this.state.locationroles];
    //    entries.splice(id, 1);
    //    this.setState({ locationroles: entries });
    //}
    //IsViewable(item) {
    //    return (item.locationId == "" || item.locationId == sessionStorage.getItem("locationId"));
    //}
    //CountL() {
    //    return this.state.locationroles.filter(item => this.IsViewable(item)).length;
    //}
    //Add() {
    //    if (this.state.locationroles.length < this.sitesfull.length) {
    //        const entries = [...this.state.locationroles];
    //        entries.push({ name: "", locationId: "", roles: [] });
    //        this.setState({ locationroles: entries });
    //    }

    //}
    //LocValue(evt, info) {
    //    console.log("LOCV");
    //    var loc = this.sitesfull.find(l => l.id == info.locationId);
    //    return loc ? loc.name : "";
    //}
    onClose() {
        this.setState({ disabledButton: false, isSaved: false, openManageRoles: false, manageRoleRoles: [] });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
        }
    }
    onCloseAdd() {
        this.setState({ openManageRoles: false });
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    handleEdit(event, siteId) {
        event.preventDefault();
        var existingRoles = this.allRolesSites.find(r => r.siteId == siteId).roleId;

        this.setState({ openManageRoles: true, manageRoleType: siteId == -1 ? "Organization" : "Site", manageRoleSites: [siteId], manageRoleRoles: existingRoles });
    }
    isActive() {
        const { userdata } = this.props;
        let activeRoles = userdata.userRoles.filter(r => r.active == true);

        if (activeRoles.length != 0) {
            this.showActiveMsg = false;
            return true;
        }
        else if (activeRoles.length == 0) {
            return false;
        }
    }
    SetActive() {
        const { userdata } = this.props;
        const active = this.isActive();
        userdata.userRoles.map(r => r.active = !active);

        if (!active) {
            this.showActiveMsg = true;
        }

        this.setState({ updated: true });
    }
    showActiveMsgFn() {
        const { userdata } = this.props;
        if (userdata.userRoles.map(r => r).length != 0 && this.showActiveMsg) {
            return false;
        }
        else if (userdata.userRoles.map(r => r).length == 0 && this.showActiveMsg) {
            return true;
        }
    }
    Add() {
        this.setState({ openManageRoles: true });
    }
    setRole(evt, id) {
        if (evt.target.checked) {
            var roleList = this.state.manageRoleRoles;
            roleList.push(id);
            this.setState({ manageRoleRoles: roleList });
        }
        else {
            var roleList = this.state.manageRoleRoles.filter(r => r != id);
            this.setState({ manageRoleRoles: roleList });
        }
    }
    addRoles() {
        const { manageRoleRoles } = this.state;
        const { rolesdata, userdata } = this.props;
        const active = userdata.id == "0" ? true : this.isActive();

        this.showActiveMsg = false;

        manageRoleRoles.forEach((roleId) => {
            const role = rolesdata.find(r => r.id == roleId);
            console.log('ST_RL_DAT3', rolesdata, roleId, role);
            const roleToAdd = {
                active: active, locationId: sessionStorage.getItem("locationId"), organizationId: sessionStorage.getItem("organisationId"), role: role, roleId: role.id, userId: userdata.email
            };

            if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId && r.locationId == sessionStorage.getItem("locationId"))) {
                userdata.userRoles.push(roleToAdd);
            }
        })
        this.setState({ openManageRoles: false, manageRoleRoles: [] });
    }
    DeleteRole(roleId) {
        const { userdata } = this.props;

        userdata.userRoles = userdata.userRoles.filter(r => r.roleId != roleId);
        this.setState({ updated: true });
    }
    isOrgAdmin() {
        const { userdata } = this.props;
        if (userdata && userdata.userRoles) {
            if (userdata.userRoles.find(r => r.roleId == "f028c85c16a148e8820da3537d74e9d9")) return true;
            else return false;

        }
    }
    renderManage() {
        const { manageRoleRoles } = this.state;
        const { sitesdata } = this.props;

        const disableAdd = () => {
            if (manageRoleRoles.length == 0) {
                return true;
            }
            else {
                return false;
            }
        }

        const returnRolesOptions = () => {
            return this.props.rolesdata.filter(s => s.level == "Location").sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        }

        return (
            <Layer
                position="center"
                modal={true}
                onClickOutside={() => this.onCloseAdd()}
                onEsc={() => this.onCloseAdd()}
            >
                <Box pad="medium" width="medium">
                    <Box tag="header" direction="row">
                        <Text weight="bold">Add new Role(s)</Text>
                    </Box>
                    <Box tag="body" direction="column">
                        <FormField label="Role(s)" error={this.email_err}>
                            <Box gap="small" pad="small">
                                {returnRolesOptions().map((info, idx) =>
                                    <Box>
                                        <CheckBox label={info.name} checked={manageRoleRoles.includes(info.id)} onChange={evt => this.setRole(evt, info.id)} />
                                    </Box>
                                )}
                            </Box>
                        </FormField>
                    </Box>
                    <Box tag="footer" direction="row" align="center" justify="end" gap="small">
                        <StyledButton typeSave label={"Add"} onClick={() => this.addRoles()} disabled={disableAdd()} />
                        <StyledButton typeCancel label="Cancel" onClick={() => this.onCloseAdd()} />
                    </Box>
                </Box>
            </Layer>
        )
    }
    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'lcrole',
                header: 'Role',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.roleName}</Text></Box>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: rolesdata => <Text hidden>{rolesdata.roleId}</Text>,
            },
            {
                property: 'delete',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: rolesdata =>
                    <Box width="24px">
                        <Button plain onClick={evt => this.DeleteRole(rolesdata.roleId)} icon={<Trash />} />
                    </Box>,
            },
        ];

        if (this.isOrgAdmin()) {
            COLUMNS.splice(COLUMNS.findIndex(c => c.delete == "delete"), 1);
        }

        const { userdata } = this.props;
        let DATA = [];

        if (userdata) {
            userdata.userRoles.forEach((ev, id) => {
                if (ev.roleId != "f028c85c16a148e8820da3537d74e9d9") {
                    DATA.push({
                        ...ev,
                        roleName: ev.role.name
                    })
                }
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.roleName.toLowerCase().includes(search)
            );
        }

        if (userdata.userRoles.filter(r => r.roleId != "f028c85c16a148e8820da3537d74e9d9").length == 0) return;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.userdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                noCounter

                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    render() {
        const { userdataLoading, rolesdataLoading, sitesdataLoading } = this.state;
        const userdefault = this.props.userdata;
        /*const sitesdata = this.props.sitesdata;*/

        if (userdataLoading || rolesdataLoading || sitesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = userdefault && (userdefault.id != "0") ? 'Edit' : 'Create';
        //const locationid = sessionStorage.getItem("locationId");
        //const canAddL = (this.CountL() < 1);
        //const locname = sitesdata.find(l => l.id == sessionStorage.getItem("locationId")).name;
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >User - {heading} </Heading>
                    {this.isOrgAdmin() ?
                        <Box direction="row" gap="small" >
                            <StyledButton typeCancel label="Back" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        </Box>
                        :
                        <Box direction="row" gap="small" >
                            <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                            <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                        </Box>}
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="small">
                            <Box>
                                <Box pad={{ vertical: "small" }}>
                                    <Text size="large">Description</Text>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                                    <FormField label="Email *" error={this.email_err}>
                                        <TextInput defaultValue={userdefault.email} onChange={evt => this.updateEmailValue(evt)} disabled={userdefault.id != "0"} />
                                    </FormField>
                                    <FormField label="Username *" error={this.username_err}>
                                        <TextInput defaultValue={userdefault.username} onChange={evt => this.updateUsernameValue(evt)} disabled={this.isOrgAdmin()} />
                                    </FormField>
                                    {/*<FormField label="Group" >
                                <Select
                                    multiple
                                    placeholder="Select Role"
                                    value={selected.length ? this.renderSelected() : undefined}
                                    options={options.map(o => o.name)}
                                    onChange={({ option }) => {
                                        const newSelected = [...selected];
                                        const selectedIndex = newSelected.indexOf(option);
                                        if (selectedIndex >= 0) {
                                            newSelected.splice(selectedIndex, 1);
                                        } else {
                                            newSelected.push(option);
                                        }
                                        this.setState({ selected: newSelected });
                                    }}
                                >
                                    {option => (
                                        <Option value={option} selected={selected.indexOf(option) >= 0} />
                                    )}
                                </Select>
                            </FormField>*/}
                                    {userdefault.id != "0" &&
                                        <Box width="60px">
                                            <FormField label="Org Admin">
                                                <Box pad="small" align="center" justify="center">
                                                    <CheckBox checked={this.isOrgAdmin()} disabled />
                                                </Box>
                                            </FormField>
                                        </Box>}
                                    <Box direction="row" gap="small">
                                        <Box width="60px">
                                            <FormField label="Active">
                                                <Box pad="small" align="center" justify="center">
                                                    <CheckBox checked={this.isActive()} onChange={evt => this.SetActive(evt)} disabled={this.isOrgAdmin()} />
                                                </Box>
                                            </FormField>
                                        </Box>
                                        {this.showActiveMsgFn() &&
                                            <Box justify="center" pad={{ "top": "medium" }}>
                                                <Text color="red">Users without any roles can not be set to active</Text>
                                            </Box>}
                                    </Box>
                                </StyledBox>
                            </Box>
                            <Box direction="row" gap="large">
                                <Box gap="small" flex="shrink">
                                    {!this.isOrgAdmin() &&
                                        <Box gap="small">
                                            <Box>
                                                <Text size="large">Roles</Text>
                                            </Box>
                                            <Box>
                                                <StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} />
                                            </Box>
                                        </Box>}
                                    <Box ref={this.dtwrapper} width={{ min: "700px" }}>
                                        {this.renderRolesTable()}
                                    </Box>
                                    {/*<Box gap="small">*/}
                                    {/*    {this.state.locationroles.map((info, idx) => (*/}
                                    {/*        this.IsViewable(info) && (*/}
                                    {/*            <StyledBox style1 direction="row" justify="start" gap="medium" pad={{ "horizontal": "small" }} width="700px">*/}
                                    {/*                <Box key={idx} width="350px" margin={{ "right": "medium" }}>*/}
                                    {/*                    <FormField label="Site" style={{ background: "white" }} error={this.entryerrors[idx].locationId}>*/}
                                    {/*                        */}{/*<Select*/}{/*
                            */}{/*                    style={{ background: "white" }}*/}{/*
                            */}{/*                    placeholder="Site"*/}{/*
                            */}{/*                    options={this.renderOptions()}*/}{/*
                            */}{/*                    onChange={evt => this.UpdateL(evt, idx)}*/}{/*
                            */}{/*                    //value={this.sitesfull.find(l => l.id == info.locationId) ? this.sitesfull.find(l => l.id == info.locationId).name : ""}*/}{/*
                            */}{/*                    //value={evt => this.LocValue(evt,info)}*/}{/*
                            */}{/*                    value={info.name}*/}{/*
                            */}{/*                />*/}
                                    {/*                        <TextInput defaultValue={locname} readOnly />*/}
                                    {/*                    </FormField>*/}
                                    {/*                </Box>*/}
                                    {/*                <Box width="200px">*/}
                                    {/*                    <FormField label="Role" style={{ background: "white" }} error={this.entryerrors[idx].role}>*/}
                                    {/*                        <Select*/}
                                    {/*                            style={{ background: "white" }}*/}
                                    {/*                            multiple*/}
                                    {/*                            placeholder="Select Role"*/}
                                    {/*                            value={this.state.locationroles[idx].roles.length ? this.renderSelected1(idx) : undefined}*/}
                                    {/*                            options={options.map(o => o.name)}*/}
                                    {/*                            onChange={({ option }) => {*/}
                                    {/*                                const newSelected = [...this.state.locationroles];*/}
                                    {/*                                const selectedIndex = newSelected[idx].roles.indexOf(option);*/}
                                    {/*                                if (selectedIndex >= 0) {*/}
                                    {/*                                    newSelected[idx].roles.splice(selectedIndex, 1);*/}
                                    {/*                                } else {*/}
                                    {/*                                    newSelected[idx].roles.push(option);*/}
                                    {/*                                }*/}
                                    {/*                                this.setState({ selected: newSelected });*/}
                                    {/*                            }}*/}
                                    {/*                        >*/}
                                    {/*                            {option => (*/}
                                    {/*                                <Option value={option} selected={this.state.locationroles[idx].roles.indexOf(option) >= 0} />*/}
                                    {/*                            )}*/}
                                    {/*                        </Select>*/}
                                    {/*                    </FormField>*/}
                                    {/*                </Box>*/}
                                    {/*                <Button icon={<Trash />} onClick={() => this.DeleteL(idx)} />*/}
                                    {/*            </StyledBox>*/}
                                    {/*        )*/}
                                    {/*    ))}*/}
                                    {/*</Box>*/}
                                    {/*{canAddL &&*/}
                                    {/*    <StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} disabled={!canAddL} />}*/}
                                </Box>
                            </Box>
                        </Box>
                    </form>
                    {this.state.openManageRoles &&
                        <Box>
                            {this.renderManage()}
                        </Box>
                    }
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    const userId = ownProps.match.params.userId || '';
    if (state.selectedUserReducer.userdata && userId == state.selectedUserReducer.userdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedUserReducer.userdata));
        return {
            userdata: state.selectedUserReducer.userdata,
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocUserEditContainer));