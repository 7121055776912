import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as CollectionAction from '../action/CollectionAction';
import * as SiteAction from '../action/SiteAction';
import * as MetaDataAction from '../action/MetaDataAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as AssociationAction from '../action/AssociationAction';
import * as AssetAction from '../action/AssetAction';
import AssociationApi from '../api/AssociationApi';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, Grommet, CheckBox } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "none",
            error: {
                color: "none"
            }
        }
    }
});

var start = undefined;

export class OrgTrackerEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.state = {
            isSaved: false,
            open: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            departmentsdataLoading: true,
            trackerdataLoading: true,
            collectionsdataLoading: true,
            trackersdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            modelSuggestions: [],
            typeSuggestions: []
        };
    }

    //Validating errors
    trackerName_err = '';
    class_err = '';
    type_err = '';
    isLinked = false;
    site_err = '';
    metadata_err = {
        manufacturer: '',
        model: '',
        deviceType: ''
    };

    //utility
    //function to sort strings by abc
    sortingByName(a,b) {
        const alc = a.name? a.name.toLowerCase(): '';
        const blc = b.name? b.name.toLowerCase(): '';
        if(alc < blc)
        {
          return -1;
        }
        else if(alc > blc)
        {
          return 1;
        }
        else {
          return 0;
        }
    }

    ClearErrorMessages() {
        this.trackerName_err = '';
        this.class_err = '';
        this.type_err = '';
        this.site_err = '';
    }
    //componentWillMount() {
    //    sessionStorage.setItem("locationId", "157c7517d92d4a4c856ae4bbff50070b");
    //}
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.trackerid;
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getTrackerAction(id)
            .then(response => this.setState({ trackerdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-TrackerEdit Error", error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getTrackersAction()
            .then(response => this.setState({ trackersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getMetadatasAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
        this.props.action.getAssociationsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getAssetsAction("", true)
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assets List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }
    componentWillReceiveProps({ trackerdata, metadatas }) {
        if (this.props.match.params.trackerid != 0 && trackerdata && metadatas) {
            //Edit - set options
            if (trackerdata.modelId) {
                let modelSuggestions = metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.modelId).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                let typeSuggestions = [];
                if (trackerdata.deviceTypeId) {
                    typeSuggestions = metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.modelId == trackerdata.modelId && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                }
                this.setState({ modelSuggestions, typeSuggestions });
            }
            else if (trackerdata.deviceTypeId) {
                let typeSuggestions = metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.deviceTypeId && m.modelId == null).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                this.setState({ typeSuggestions });
            }
            else if (trackerdata.manufacturerId) {
                let modelSuggestions = metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.modelId).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                let typeSuggestions = metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.deviceTypeId && m.modelId == null).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                this.setState({ modelSuggestions, typeSuggestions });
            }
        }
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.trackerdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const trackerdata = this.props.trackerdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (trackerdata.name.trim().length == 0) {
            errorsno++;
            this.trackerName_err = "Tracker Name is required";
        }
        if (trackerdata.name.length > 30) {
            errorsno++;
            this.trackerName_err = "Tracker Name can't be longer than 30 character";
        }
        if (this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 && this.props.trackersdata.filter(item => item.id != trackerdata.id).map(t => t.name.toLowerCase()).includes(trackerdata.name.toLowerCase().trim())) {
            errorsno++;
            this.trackerName_err = "Tracker Name already exists";
        }
        if (!trackerdata.locationId) {
            errorsno++;
            this.site_err = "Site is required";
        }
        if (!trackerdata.class) {
            errorsno++;
            this.class_err = "Class is required";
        }
        for (const item in this.metadata_err) {
            if (this.metadata_err[item].length != 0) {
                errorsno++;
                break;
            }
        }
        //else if (trackerdata.class == "Asset" && ["Employee", "Visitor", "Contractor", "Other"].includes(trackerdata.type)) {
        //    errorsno++;
        //    this.type_err = "Type is invalid";
        //}
        //else if (trackerdata.class == "Person" && trackerdata.type == "Critical") {
        //    errorsno++;
        //    this.type_err = "Type is invalid";
        //}
        //if (!trackerdata.type) {
        //    errorsno++;
        //    this.type_err = "Type is required";
        //}
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            //let locId = '';
            //if (sessionStorage.getItem('locationId')) {
            //    locId = sessionStorage.getItem('locationId');
            //}
            //trackerdata.locationId = locId;
            trackerdata.category = null;
            trackerdata.categoryId = null;
            trackerdata.departmentId = trackerdata.departmentId != 0 ? trackerdata.departmentId : null;
            trackerdata.department = null;
            trackerdata.collection = null;
            trackerdata.collectionId = trackerdata.collectionId != 0 ? trackerdata.collectionId : null;
            console.log('Save: OK', trackerdata);
            this.props.action.saveTrackerAction(trackerdata)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    return response.json();
                })
                .then(response => {
                    if (this.state.isSaveSuccessful)
                        this.saveLink(response);
                })
                .catch(error => {
                    console.log('*DEBUG*-Save TrackerEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.trackerdata)) {
            this.setState({ cancel: true });
        }
        else {
            const trackerId = this.props.trackerdata.id;
            this.props.action.getTrackerAction(trackerId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push({
                                pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                                state: { index: 0 }
                            });
                        else
                            this.props.history.push({
                                pathname: '/orgtrackers',
                                state: { index: 0 }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-TrackerEdit Error', error);
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push({
                            pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                            state: { index: 0 }
                        });
                    else
                        this.props.history.push({
                            pathname: '/orgtrackers',
                            state: { index: 0 }
                        });
                });
        }

    }
    updateNameValue(evt) {
        this.props.trackerdata.name = evt.target.value;
        if (evt.target.value.length > 30) {
            this.trackerName_err = "Tracker Name can't be longer than 30 character";
        }
        if (this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 && this.props.trackersdata.filter(item => item.id != this.props.trackerdata.id).map(t => t.name.toLowerCase()).includes(evt.target.value.toLowerCase().trim())) {
            this.trackerName_err = "Tracker Name already exists";
        }
        else {
            this.trackerName_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateSite(evt) {
        this.props.trackerdata.locationId = evt.option.id;
        this.site_err = "";
        this.setState({ selectedTag: null });
    }
    updateClass(evt) {
        this.props.trackerdata.class = evt.option;
        this.class_err = "";
        this.props.trackerdata.type = null;
        if (evt.option == "Person") {
            this.props.trackerdata.manufacturer = null;
            this.props.trackerdata.manufacturerId = null;
            this.props.trackerdata.model = null;
            this.props.trackerdata.modelId = null;
            this.props.trackerdata.deviceType = null;
            this.props.trackerdata.deviceTypeId = null;
        }
        //this.meta.current.scrollIntoView({
        //    behavior: "smooth",
        //    block: "end"
        //});
        this.setState({ validating: !this.state.validating }, () => {
            setTimeout(function () {
                let box = document.getElementById("scroll");
                box.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                });
            }, 0);
        });
    }
    updateType(evt) {
        this.props.trackerdata.type = evt.option;
        this.type_err = "";
        this.setState({ validating: !this.state.validating });
    }
    resetType() {
        this.props.trackerdata.type = null;
        this.setState({ validating: !this.state.validating });
    }
    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 0 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 0 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    resetMetadata(name, nameId) {
        this.props.trackerdata[name] = null;
        this.props.trackerdata[nameId] = null;
        this.metadata_err[name] = "";
        if (name == "manufacturer")
            this.setState({ modelSuggestions: [], typeSuggestions: [] });
        else if (name == "model") {
            let typeSuggestions = this.props.metadatas.filter(m => m.manufacturerId == this.props.trackerdata.manufacturerId && m.deviceTypeId && m.modelId == null).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
            this.setState({ typeSuggestions });
        }
        else if (name == "deviceType") {
            this.setState({ update: true });
        }
    }

    saveLink(tracker) {
        if (this.state.selectedTag) {
            //create & update
            const id = this.props.match.params.trackerid;
            if (this.state.selectedTag.beaconId != tracker.assetBeaconId) {
                const link = { beaconId: this.state.selectedTag.beaconId, beaconName: this.state.selectedTag.beaconName, assetName: tracker.name, assetId: tracker.id };
                AssociationApi.saveAssociation(link);
            }
        }
    }

    linkOptions() {
        let list = this.props.associationsdata.filter(a => a.assetId == null && a.locationId == this.props.trackerdata.locationId);
        if (this.props.assetsdata) {
            var filteredTags = this.props.assetsdata.filter(f => f.isControlTag).map(e => e.name);
            list = this.props.associationsdata.filter(f => !filteredTags.includes(f.beaconName) && f.assetId == null);
        }
        if (this.props.trackerdata.assetBeaconId)
            list = list.concat(this.props.associationsdata.find(a => a.beaconId == this.props.trackerdata.assetBeaconId)); //Add exisitng one to the list to display it
        return list.sort((p1, p2) => { return p1.beaconName < p2.beaconName ? -1 : 1 });
    }

    displayTag() {
        if (this.state.selectedTag)
            return this.state.selectedTag;
        else if (this.props.trackerdata.assetBeaconId)
            return this.props.associationsdata.find(link => link.beaconId == this.props.trackerdata.assetBeaconId);
        else
            return null;
    }

    render() {
        const { isNewDepartment, departmentsdataLoading, trackerdataLoading, collectionsdataLoading, trackersdataLoading, sitesdataLoading } = this.state;
        const { departmentsdata, trackerdata, collectionsdata, sitesdata } = this.props;

        //const trackerdata = constants.TRACKERS.find(t => t.id == this.props.match.params.trackerid);

        if (trackerdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = trackerdata && this.props.match.params.trackerid != 0 ? 'Edit' : 'Create';
        const typeList = constants.getTypeByClass(trackerdata.class);
        let department = departmentsdata.find(d => d.id == trackerdata.departmentId);
        department = department ? department : "";
        let collection = collectionsdata.find(c => c.id == trackerdata.collectionId);
        collection = collection ? collection : "";
        let collectionvalue = (<Box pad="small" direction="row" gap="small">
            <Box round height="23px" background={collection ? collection.hexColor : "white"} width="23px" border style={{ "border-width": "2px" }} />
            {collection ? collection.name : ""}
        </Box>);
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Tracker - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        <Box pad={{ vertical: "small" }}>
                            <Text size="large">Description</Text>
                            <Text>All fields marked with an * are mandatory.</Text>
                        </Box>
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                            {trackerdata.id && trackerdata.id != "0" && <FormField label="ID">
                                <TextInput defaultValue={trackerdata.id} disabled />
                            </FormField>}
                            <FormField label="Name *" error={this.trackerName_err}>
                                <TextInput defaultValue={trackerdata.name} placeholder="Tracker Name" onChange={evt => this.updateNameValue(evt)} />
                            </FormField>
                            <FormField label="Site *" error={this.site_err}>
                                <Select
                                    placeholder="Site"
                                    disabled={this.props.match.params.trackerid != 0}
                                    value={sitesdata.find(s => s.id == trackerdata.locationId)}
                                    options={sitesdata.sort(constants.byPropertyCalled("name"))}
                                    labelKey="name"
                                    onChange={evt => this.updateSite(evt)}
                                />
                            </FormField>
                            {this.props.match.params.trackerid != 0 &&
                                <FormField label="Linked">
                                    <CheckBox checked={/*this.isLinked*/trackerdata.assetBeaconId ? true : false} disabled />
                                </FormField>
                            }
                        </StyledBox>

                        <Box>
                            <Box pad={{ vertical: "small" }}>
                                <Text size="large">Collection</Text>
                            </Box>
                            <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                <Grommet theme={customTheme}>
                                    <FormField label="Collection Name">
                                        <Box direction="row" gap="small">
                                            <Box width="90%">
                                                <Select width="large"
                                                    value={collectionvalue}
                                                    placeholder="Select a Collection"
                                                    onChange={o => { trackerdata.collectionId = o.option.id; this.setState({ dummy: true }); }}
                                                    options={collectionsdata.sort(constants.byPropertyCalled("name"))}
                                                    labelKey="name">
                                                    {option => {
                                                        return (
                                                            <Box pad="small" direction="row" gap="small">
                                                                <Box round height="23px" background={option.hexColor} width="23px" border style={{ "border-width": "2px" }} />
                                                                {option.name}
                                                            </Box>);
                                                    }}
                                                </Select>
                                            </Box>
                                            {collection.hasOwnProperty("name") && <Box alignSelf="center">
                                                <Button icon={<FormClose />} onClick={() => { trackerdata.collectionId = "0"; this.setState({ updateC: true }); }} data-tip=" Select to reset this value" data-for="collectionid" />
                                                <ReactTooltip id="collectionid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            </Box>}
                                        </Box>
                                    </FormField>
                                </Grommet>
                            </StyledBox>
                            {/*this.state.*/collection == "" && this.state.updateC &&
                                <Text size="xsmall"> {constants.removeText("collection")}</Text>
                            }
                        </Box>

                        <Box>
                            <Box pad={{ vertical: "small" }}>
                                <Text size="large">Department</Text>
                            </Box>
                            <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                <Grommet theme={customTheme}>
                                    <FormField label="Department Name" >
                                        <Box direction="row" gap="small">
                                            <Box width="90%">
                                                <Select /*width="large"*/
                                                    value={department}
                                                    placeholder="Select a Department"
                                                    onChange={o => { trackerdata.departmentId = o.option.id; this.setState({ dummy: true }); }}
                                                    options={departmentsdata.sort(constants.byPropertyCalled("name"))}
                                                    labelKey="name">
                                                    {option => {
                                                        return (
                                                            <Box pad="small">
                                                                {option.name}
                                                            </Box>);
                                                    }}
                                                </Select>
                                            </Box>
                                            {department.hasOwnProperty("name") && <Box alignSelf="center">
                                                <Button icon={<FormClose />} onClick={() => { trackerdata.departmentId = "0"; this.setState({ updateD: true }); }} data-tip=" Select to reset this value" data-for="departmentid" />
                                                <ReactTooltip id="departmentid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            </Box>}
                                        </Box>
                                    </FormField>
                                </Grommet>
                            </StyledBox>
                            {/*this.state.*/department == "" && this.state.updateD &&
                                <Text size="xsmall"> {constants.removeText("department")}</Text>
                            }
                        </Box>

                        <Box id="scroll">
                            <Box pad={{ vertical: "small" }}>
                                <Text size="large">Classification</Text>
                            </Box>
                            <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                <Box direction="row" gap="small">
                                    <Box width="90%">
                                        <FormField label="Class *" error={this.class_err}>
                                            <Select
                                                value={trackerdata.class}
                                                options={this.context.feature.find(f => f.name == "personTracking").isEnabled ? ["Asset", "Person"] : ["Asset"]}
                                                placeholder="Class"
                                                onChange={evt => this.updateClass(evt)} />
                                        </FormField>
                                    </Box>
                                </Box>

                                {trackerdata.class == "Asset" && <Box>

                                    <Grommet theme={customTheme}>
                                        <FormField label="Manufacturer" error={this.metadata_err.manufacturer}>
                                            <Box direction="row" justify="between">
                                                <Box width="90%">
                                                    <Select
                                                        value={trackerdata.manufacturerId ? trackerdata.manufacturer.name : ""}
                                                        placeholder="Manufacturer Name"
                                                        options={this.props.manufacturersdata.sort(this.sortingByName).map(m => m.name)}
                                                        onChange={evt => {
                                                            let man = this.props.manufacturersdata.find(m => m.name == evt.option);
                                                            trackerdata.manufacturer = man
                                                            trackerdata.manufacturerId = man.id;
                                                            trackerdata.model = null;
                                                            trackerdata.modelId = null;
                                                            trackerdata.deviceType = null;
                                                            trackerdata.deviceTypeId = null;
                                                            let modelSuggestions = this.props.metadatas.filter(m => m.manufacturerId == man.id && m.modelId).sort(this.sortingByName).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                            //let typeSuggestions = this.props.metadatas.filter(m => m.manufacturerId == man.id && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                            let typeSuggestions = this.props.metadatas.filter(m => {
                                                                if (m.manufacturerId == man.id && m.deviceTypeId) {
                                                                    if (trackerdata.modelId) {
                                                                        if (m.modelId == trackerdata.modelId)
                                                                            return m;
                                                                        return;
                                                                    }
                                                                    else {
                                                                        if (m.modelId == null)
                                                                            return m;
                                                                        return;
                                                                    }
                                                                    return;
                                                                }
                                                                return;
                                                            })
                                                            .sort(this.sortingByName)
                                                            .map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                            
                                                            this.setState({ validating: !this.state.validating, modelSuggestions, typeSuggestions });
                                                        }}
                                                    />
                                                </Box>
                                                {trackerdata.manufacturer && <Box alignSelf="center">
                                                    <Button icon={<FormClose />} onClick={() => { this.resetMetadata("deviceType", "deviceTypeId"); this.resetMetadata("model", "modelId"); this.resetMetadata("manufacturer", "manufacturerId"); }} data-tip=" Select to reset this value" data-for="manufacturerid" />
                                                    <ReactTooltip id="manufacturerid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                </Box>}
                                            </Box>
                                        </FormField>
                                    </Grommet>


                                    <Grommet theme={customTheme}>
                                        <FormField label="Model" error={this.metadata_err.model}>
                                            <Box direction="row" justify="between">
                                                <Box width="90%">
                                                    <Select
                                                        value={trackerdata.modelId ? trackerdata.model.name : ""}
                                                        placeholder="Model"
                                                        disabled={this.state.modelSuggestions.length == 0}
                                                        options={this.state.modelSuggestions.sort(this.sortingByName).map(m => m.name)}
                                                        onChange={evt => {
                                                            let model = this.state.modelSuggestions.find(m => m.name == evt.option);
                                                            trackerdata.model = model;
                                                            trackerdata.modelId = model.id;
                                                            this.resetMetadata("deviceType", "deviceTypeId");
                                                            let typeSuggestions = this.props.metadatas.filter(m => m.manufacturerId == trackerdata.manufacturerId && m.modelId == model.id && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                            this.setState({ validating: !this.state.validating, typeSuggestions });
                                                        }}
                                                    />
                                                </Box>
                                                {trackerdata.model && <Box alignSelf="center">
                                                    <Button icon={<FormClose />} onClick={() => { this.resetMetadata("deviceType", "deviceTypeId"); this.resetMetadata("model", "modelId") }} data-tip=" Select to reset this value" data-for="modelid" />
                                                    <ReactTooltip id="modelid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                </Box>}
                                            </Box>
                                        </FormField>
                                    </Grommet>



                                    <Grommet theme={customTheme}>
                                        <FormField label="Model Type" error={this.metadata_err.deviceType}>
                                            <Box direction="row" justify="between">
                                                <Box width="90%">
                                                    <Select
                                                        value={trackerdata.deviceTypeId ? trackerdata.deviceType.name : ""}
                                                        placeholder="Model Type"
                                                        disabled={this.state.typeSuggestions.length == 0}
                                                        options={this.state.typeSuggestions.sort(this.sortingByName).map(t => t.name)}
                                                        onChange={evt => {
                                                            let type = this.state.typeSuggestions.find(t => t.name == evt.option);
                                                            trackerdata.deviceType = type;
                                                            trackerdata.deviceTypeId = type.id;
                                                            this.setState({ validating: !this.state.validating });
                                                        }}
                                                    />
                                                </Box>
                                                {trackerdata.deviceType && <Box alignSelf="center">
                                                    <Button icon={<FormClose />} onClick={() => this.resetMetadata("deviceType", "deviceTypeId")} data-tip=" Select to reset this value" data-for="deviceTypeid" />
                                                    <ReactTooltip id="deviceTypeid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                </Box>}
                                            </Box>
                                        </FormField>
                                    </Grommet>
                                </Box>}

                                <Grommet theme={customTheme}>
                                    <FormField label="Type" >
                                        <Box direction="row" justify="between">
                                            <Box width="90%">
                                                <Select
                                                    value={trackerdata.type ? trackerdata.type : ""}
                                                    options={typeList}
                                                    placeholder="Type"
                                                    onChange={evt => this.updateType(evt)} />
                                            </Box>
                                            {trackerdata.type && <Box alignSelf="center">
                                                <Button icon={<FormClose />} onClick={() => this.resetType()} data-tip=" Select to reset this value" data-for="typeid" />
                                                <ReactTooltip id="typeid" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            </Box>}
                                        </Box>
                                    </FormField>
                                </Grommet>
                            </StyledBox>
                            <Box pad={{ vertical: "medium" }} direction="row">
                                <a data-for="links" data-tip="Please select Site first">
                                    <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                        <FormField label="Tag">
                                            <Select
                                                dropHeight="medium"
                                                disabled={trackerdata.locationId == null}
                                                placeholder="Select Tag"
                                                options={this.linkOptions()}
                                                labelKey="beaconName"
                                                onChange={evt => this.setState({ selectedTag: evt.option })}
                                                value={this.displayTag()}
                                            >
                                                {option =>
                                                    <Box pad="small" justify="between" direction="row">
                                                        {option.beaconName.length <= 35 ? option.beaconName : option.beaconName.substring(0, 35) + "..."}
                                                        <Box>{option.beaconId}</Box>
                                                    </Box>}
                                            </Select>
                                        </FormField>
                                    </StyledBox>
                                </a>
                                {!trackerdata.locationId && <ReactTooltip id="links" place="right" type="dark" effect="solid" arrowColor="transparent" />}
                            </Box>
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const trackerId = ownProps.match.params.trackerid || '';
    if (state.selectedTrackerReducer.trackerdata && trackerId == state.selectedTrackerReducer.trackerdata.id) {
        start = { ...state.selectedTrackerReducer.trackerdata };
        return {
            departmentsdata: state.departmentsReducer.departmentsdata,
            trackerdata: state.selectedTrackerReducer.trackerdata,
            collectionsdata: state.collectionsReducer.collectionsdata,
            trackersdata: state.trackersReducer.trackersdata,
            sitesdata: state.sitesReducer.sitesdata,
            manufacturersdata: state.manufacturersReducer.manufacturersdata,
            modelsdata: state.modelsReducer.modelsdata,
            typesdata: state.typesReducer.typesdata,
            associationsdata: state.associationsReducer.associationsdata,
            metadatas: state.metadatasReducer.metadatas,
            assetsdata: state.assetsReducer.assetsdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...DepartmentAction, ...TrackerAction, ...CollectionAction, ...SiteAction, ...ManufacturerAction, ...ModelAction, ...TypeAction, ...AssociationAction, ...MetaDataAction, ...AssetAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgTrackerEditContainer));