import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import * as SiteAction from '../action/SiteAction';
import * as AssetAction from '../action/AssetAction';
import * as CollectionAction from '../action/CollectionAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, Close, FormClose, StatusGoodSmall, Filter, Expand, Contract, Technology, Time, Clock, Projects, Location, Calendar } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Image, TextArea, Anchor, Header, Layer, RadioButtonGroup, DateInput, RadioButton } from 'grommet';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import * as MeridianSDK from "@meridian/web-sdk";
import './Meridian.css';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import RB from './common/RBAC';
import styled, { css } from 'styled-components';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import { getPortalToken } from '../api/ApiUtils';
import { setTimeout } from 'timers';
import { MsalContext } from "@azure/msal-react";
import { withOktaAuth } from '@okta/okta-react';

var start = undefined;
const FilterButton = styled(Button)`
        height: 48px;
        border-radius: 5px 5px 5px 5px;`;

let BookViaGraph = constants.BOOKVIAGRAPH;
var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();

export class MeridianMapBookSpace extends React.Component {

    static contextType = Context;
    constructor() {
        super();
        this.mix = [];
        this.target = React.createRef();
        this.bookedSpaces = [];
        this.availableSpaces = [
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            },
            {
                name: "Desk",
                building: "Limavady",
                floor: "First Floor"
            }
        ];
        this.isDesksFetched = false;
        this.HeaderInfo = "";
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            selectedBuilding: "",
            selectedFloor: "",
            floors: [],
            showPlacemarks: true,
            showTags: false,
            showControlTags: /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false,
            selectedFloorId: "",
            selectedAsset: "",
            assetid: "",
            assetList: [],
            control: "All",

            mapVisible: false,
            organisationdataLoading: true,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            sitedataLoading: true,
            assetsdataLoading: true,
            placemarksdataLoading: true,
            selectedCollections: [],
            showUnlinkedTags: /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false,
            assetsOnMap: [],
            selectedDepartment: "",
            isFullScreen: false,

            isDestroyed: false,
            selectedStatus: "",
            unlinks: [],

            placemarkid: "",

            open: false,
            placemarkName: "",
            radio: 'c2',
            showSpacesOnly: true,
            date: new Date().toISOString(),
            time: "9AM - 5PM",
            duration: "8 hours",
            availableDesks: [],
            dateTime: "Full Day",
            placemark: {},
            openBookedDesk: false,
            justBooked: false,
            saveInProgress: false
        };
    }

    emptyBuildingArr = [];
    headerHeight = 0;
    //Validating fields error handling
    summary_err = '';
    text_err = '';
    url_err = '';
    async componentWillReceiveProps({ assetsdata, placemarksdata, levelsdata, sitedata }) {
        //if (assetsdata && assetsdata.length > 0) {
        //    assetsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //}
        //console.log("nct", assetsdata);
        //this.setState({ assetList: assetsdata });

        if (sitedata && !this.state.sitedataLoading && !this.isRendered && levelsdata && !this.state.levelsdataLoading) {
            //if (sitedata.campusId) {
            //    this.getDesksByTeem(sitedata.campusId);
            //}
            if (sitedata.mapID) {
                this.isRendered = true;
                let level = levelsdata.find(l => l.meridianId == sitedata.mapID);
                if (level) {
                    this.setState({ selectedFloor: level });
                }
                this.setState({ selectedFloorId: sitedata.mapID }, () => {
                    if (this.state.selectedFloorId) {
                        console.log("noe", document.getElementById("meridian-map1"), levelsdata);
                        setTimeout(() => this.renderMap(this.state.selectedFloorId), 2000);
                    }
                });
            } else if (levelsdata && !this.state.levelsdataLoading) {
                this.isRendered = true;
                let level = levelsdata.find(l => l.meridianId && l.published == true);
                if (level) {
                    this.setState({ selectedFloorId: level.meridianId }, () => {
                        if (this.state.selectedFloorId) {
                            console.log("noe", document.getElementById("meridian-map1"));
                            setTimeout(() => this.renderMap(this.state.selectedFloorId), 2000);
                        }
                    });
                } else {
                    //No available floor
                    this.HeaderInfo = "There are no floors published. Please contact your site admin.";
                }
            }
        }

        //Available Desk
        if (!this.isDesksFetched && placemarksdata && placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count")) && sitedata && !this.state.sitedataLoading ) {
            this.isDesksFetched = true;
            BookViaGraph = sitedata.graphEnabled && constants.BOOKVIAGRAPH; //controll from constants
            if (BookViaGraph) {
                if (BookViaGraph)
                    this.user = await this.getGraphUser();
                this.getDesks(placemarksdata)
                    .then(r => console.log("reno", r));
            } else {
                this.getDesksByTeem(sitedata.campusId, sitedata.teemOrgId, placemarksdata);
            }
        }

        if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            (assetsdata && placemarksdata) && ((assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) && (placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count"))) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count"))))) {
            console.log("cwrp", assetsdata, placemarksdata);
            let filteredPlacemarks = placemarksdata.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published)
                    return true;
                return false;
            });
            const mix = assetsdata.concat(filteredPlacemarks).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            assetsdata && assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) {
            const mix = assetsdata.sort(constants.byPropertyCalled("name"));
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (placemarksdata && placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count")) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count")))) {
            const mix = placemarksdata.filter(p => p.levelId && p.levelId != "" && p.levelId != null).sort(constants.byPropertyCalled("name"));
            this.mix = mix.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published)
                    return true;
                return false;
            });
            this.setState({ assetList: this.mix });
        }

        let withNoBuilding = this.props.levelsdata.filter(l => (l.mergedBuildingId == null || l.mergedBuildingName == "") && l.published);
        if (withNoBuilding.length > 0) {
            var obj = Object.assign({}, this.props.buildingsdata[0]);
            for (var key of Object.keys(obj)) {
                obj[key] = null;
            }
            obj.meridianName = "Unassigned";
            this.emptyBuildingArr = [obj];

            //const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == null);
            //this.setState({ floors: floors });
        }
    }
    async componentDidMount() {
        //if (BookViaGraph)
        //    this.user = await this.getGraphUser();
        //console.log("zsoU", this.user);
        //const id = constants.organisation.id;
        const id = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*- Site Edit Error', error);
            });
        let isControlTagsIncluded = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) ? "" : false;
        this.props.action.getMappedAssetsAction(isControlTagsIncluded, RB.canEdit(constants.PERMS.LOC_ADMINISTRATION))
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*- MappedAssets List Error', error);
            });
        this.props.action.getCollectionsAction()
            .catch(error => {
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getAssociationsAction()
            .catch(error => {
                console.log('*DEBUG*- Assocation List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .catch(error => {
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getTrackersAction()
            .catch(error => {
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                console.log('*DEBUG*- Placemarks List Error', error);
            });
    }

    componentWillUnmount() {
        //this.historyUnblock();
        if (this.map) {
            this.map.destroy();
        }
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }


    clearErrorMessages() {
        this.summary_err = '';
        this.text_err = '';
        this.url_err = '';
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data
        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });
        if (organisationdefault.privacySummary) {
            if (organisationdefault.privacySummary.length > 600) {
                errorsno++;
                this.summary_err = "Privacy Statement Summary can't be longer than 600 characters";
            }
        }
        if (organisationdefault.privacyText) {
            if (organisationdefault.privacyText.length > 50) {
                errorsno++;
                this.text_err = "Display Text can't be longer than 50 characters";
            }
        }
        if (organisationdefault.privacyURL) {
            if (organisationdefault.privacyURL.trim().length != 0 && !(validator.isURL(organisationdefault.privacyURL, constants.URL_OPTIONS))) {
                errorsno++;
                this.url_err = constants.URL_ERROR;
            }
        }

        if (errorsno == 0) {
            //Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/privacy');
                }).catch(error => {
                    console.log('*DEBUG*-Save Privacy Edit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            //console.log('Restoring:', id);
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        //this.props.history.push('/meridianmap');
                        //this.renderMap();
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Privacy & Security Edit Error', error);
                    this.props.history.push('/privacy');
                });
        }
    }

    //dealing with data
    updateSummaryValue(evt) {
        this.props.organisationdata.privacySummary = evt.target.value;
        if (evt.target.value.length > 600) {
            this.summary_err = "Privacy Statement Summary can't be longer than 600 characters";
        }
        else {
            this.summary_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateTextValue(evt) {
        this.props.organisationdata.privacyText = evt.target.value;
        if (evt.target.value.length > 50) {
            this.text_err = "Display Text can't be longer than 50 characters";
        }
        else {
            this.text_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateUrlValue(evt) { this.props.organisationdata.privacyURL = evt.target.value };


    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/privacy');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    createPolygonPoints(x, y, r, n) {
        const points = [];
        const turn = Math.PI * 2;
        const step = turn / n;
        for (let theta = 0; theta < turn; theta += step) {
            const dx = Math.sin(theta) * r;
            const dy = Math.cos(theta) * r;
            points.push(x + dx, y + dy);
        }
        console.log("area_points", points);
        return points;
    }
    renderMap(floorid, reRender = false) {
        let id = this.state.isFullScreen ? "meridian-map2" : "meridian-map1";
        if (this.map && !this.map.isDestroyed && !reRender) {
            console.log("up");
            this.map.update({ floorID: floorid });
        }
        else {
            console.log("propi", floorid);
            const macid = ["CC78ABC6CB63", "CC78AB9D9845"];
            const api = new MeridianSDK.API({
                environment: this.props.sitedata.eUorNon ? "eu" : "production",
                //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                token: this.props.sitedata.appToken
            });
            var element = document.getElementById(id);
            if (element) {
                element.innerHTML = "";
                //console.log("element", element);
                //console.log("mapelement", this.props.sitedata.appID, floorid);
                this.map = MeridianSDK.createMap(
                    //document.getElementById("meridian-map"),
                    element,
                    {
                        //locationID: "5198682008846336",
                        //loorID: "5755685136498688"
                        api: api,
                        locationID: this.props.sitedata.appID,
                        floorID: floorid,
                        shouldMapPanZoom: function restrictedPanZoom(event) {
                            if (event.type === "wheel" && !event.altKey) {
                                return false;
                            } else if (event.type === "touchstart") {
                                return event.touches.length >= 2;
                            }
                            return true;
                        },
                        onFloorChange: floor => {
                            console.log("floor", floor);
                            let level = this.props.levelsdata.find(l => l.meridianId == floor.id);
                            if (level) {
                                this.setState({ selectedFloorId: floor.id, selectedFloor: level });
                            }
                            else {
                                this.setState({ selectedFloorId: floor.id });
                            }
                        },
                        showFloorsControl: true, //this.state.showMapControls,
                        showTagsControl: false, //this.state.showMapControls,
                        showSearchControl: false,
                        onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
                            this.map.update({
                                overlays: filteredPlacemarks
                                    .filter(placemark => placemark.area && placemark.type == "desk")
                                    .map((placemark, i) => {
                                        const hue = (i * (360 / 6)) % 360;
                                        //red - already booked
                                        let fill = `rgba(255, 51, 51, 0.3)`;
                                        let stroke = `rgb(255, 51, 51)`;
                                        //let free = new Date(this.state.date).getHours() >= 12 ? "01" : "10";
                                        let desk = this.state.availableDesks.find(d => d.meridianId == placemark.id);
                                        if (desk) {
                                            console.log(this.state.availableDesks, desk.name, "bookDesk");
                                            if (desk.bookingStatus == "bookable") {
                                                fill = `rgba(51, 255, 51, 0.3)`; //green
                                                stroke = `rgb(51, 255, 51)`; //green
                                            }
                                            else {
                                                fill = `rgba(169, 169, 169, 0.3)`; //grey
                                                stroke = `rgb(169, 169, 169)`; //grey
                                            }
                                        }
                                        console.log("area", /*this.state.radio, placemark,*/ desk);
                                        return {
                                            type: "polygon",
                                            points: MeridianSDK.pointsFromArea(placemark.area),
                                            //fill: "#cc00cc",
                                            //strokeWidth: 0,
                                            //fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                                            //stroke: `hsl(${hue}, 100%, 60%)`,
                                            //fill: `rgba(51, 255, 51, 0.3)`,
                                            //stroke: `rgb(51, 255, 51)`,
                                            data: placemark,
                                            fill: fill,
                                            stroke: stroke,
                                            onClick: data => {
                                                // arbitrary data can be passed for each polygon
                                                console.info("clicked polygon, data:", data);
                                                let d = new Date();
                                                if (!desk) {
                                                    this.setState({ openBookedDesk: true, placemarkName: placemark.name });
                                                }
                                                else if (desk.bookingStatus == "bookable") {
                                                    this.setState({ open: true, placemarkName: placemark.name, placemark: desk });
                                                }
                                                else {
                                                    //PopUp or do nothing
                                                }
                                            },
                                        };
                                    })
                            });
                        },
                        //height: "650px", //650px for Keith's DEMO
                        height: this.state.isFullScreen ? "calc(100vh - 100px - 66px)" : "calc(100vh - 300px - 66px)",
                        //height: this.state.isFullScreen ? (window.innerHeight - headerHeight - 100) + "px" : "450px",
                        placemarks: {
                            filter: p => {
                                if (this.state.showPlacemarks) {
                                    if (this.state.assetid)
                                        return false;
                                    if (this.state.placemarkid)
                                        return p.id == this.state.placemarkid;
                                    else if (this.state.showSpacesOnly) {
                                        return p.type == "desk";
                                    }
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            },
                            //labelZoomLevel: 0.1,
                        },

                        tags: {
                            updateInterval: 10000,
                            filter: t => {
                                if (!this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled) {
                                    //if its not enabled then we dont show anything.
                                    console.log("disa")
                                    return false;
                                }
                                //console.log("Tags", t);
                                //t.image_url = "https://hpepngdemostor.blob.core.windows.net/images/00FFFF.png";
                                //return this.state.tags.includes(t.mac);

                                //if assetsOnMap.length == 0 && selectedDepartment then it means that we dont have asset(s) with that department, therefore array should be empty.
                                //let assets = this.state.assetsOnMap.length > 0 ? this.state.assetsOnMap : this.state.selectedDepartment ? [] : this.props.assetsdata;
                                let assets = [];
                                let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);

                                if (this.state.selectedDepartment && this.state.selectedStatus)
                                    assets = this.state.departments.filter(d => this.state.statuses.includes(d));
                                else if (this.state.selectedStatus && !this.showUnlinkedTags)
                                    assets = this.state.statuses.filter(d => unl.includes(d));
                                else if (this.state.selectedStatus && this.showUnlinkedTags)
                                    assets = this.state.statuses.filter(d => unl.includes(d));
                                else if (this.state.selectedDepartment)
                                    assets = this.state.departments;
                                else {
                                    if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                        //Dont have links && only show links
                                        assets = [];
                                    }
                                    else {
                                        assets = unl;
                                    }
                                }


                                assets = this.filterDataBySelections();
                                console.log("disab",assets);
                                if (!assets/*.map(a => a.id)*/.includes(t.mac)) return false;
                                if (!t.is_control_tag) {
                                    if (this.state.showTags) {
                                        if (this.state.placemarkid)
                                            return false;
                                        if (this.state.assetid) {
                                            let labels = t.tags.map(l => l.name);
                                            let value = false;
                                            if (this.state.selectedCollections.length == 0) {
                                                value = true;
                                            }
                                            else {
                                                this.state.selectedCollections.forEach(e => {
                                                    if (labels.includes(e.labelName)) {
                                                        value = true;
                                                        return true;
                                                    }
                                                });
                                            }
                                            return t.mac == this.state.assetid && value;
                                        } else {
                                            if (this.state.selectedCollections.length == 0) {
                                                return true;
                                            }
                                            else {
                                                let labels = t.tags.map(l => l.name);
                                                let value = false;
                                                this.state.selectedCollections.forEach(e => {
                                                    if (labels.includes(e.labelName)) {
                                                        value = true;
                                                        return true;
                                                    }
                                                });
                                                return value;
                                            }
                                        }
                                    } else {
                                        return false;
                                    }
                                }
                                else {
                                    if (this.state.showControlTags) {
                                        if (this.state.placemarkid)
                                            return false;
                                        if (this.state.assetid) {
                                            return t.mac == this.state.assetid;
                                        } else {
                                            if (this.state.selectedCollections.length == 0) {
                                                return true;
                                            }
                                            else {
                                                let labels = t.tags.map(l => l.name);
                                                let value = false;
                                                this.state.selectedCollections.forEach(e => {
                                                    if (labels.includes(e.name)) {
                                                        value = true;
                                                        return true;
                                                    }
                                                });
                                                return value;
                                            }
                                        }
                                    } else {
                                        return false;
                                    }

                                }
                                //if (this.state.selectedCollections.length == 0) {
                                //    return true;
                                //}
                                //else {
                                //    let labels = t.tags.map(l => l.name);
                                //    let value = false;
                                //    this.state.selectedCollections.forEach(e => {
                                //        if (labels.includes(e.name)) {
                                //            value = true;
                                //            return true;
                                //        }
                                //    });
                                //    return value;
                                //}

                                //if (this.state.assetid && this.state.showTags && !t.is_control_tag) {
                                //    return t.mac == this.state.assetid;
                                //}
                                //else if (this.state.assetid && this.state.showControlTags && t.is_control_tag) {
                                //    return t.mac == this.state.assetid;
                                //}
                                //else if (this.state.showTags && /*this.state.control == "All"*/ this.state.showControlTags) {
                                //    return true;
                                //}
                                //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showControlTags) {
                                //    return t.is_control_tag;
                                //}
                                //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showTags) {
                                //    return !t.is_control_tag;
                                //}
                                //return this.state.showTags;
                            },
                            showControlTags: this.state.showControlTags/*this.state.control !== "Tags"*/
                        },
                        //overlays: [
                        //    {
                        //        type: "polygon",
                        //        points: this.createPolygonPoints(2800, 2100, 300, 6),
                        //        fill: "#F00",
                        //        strokeWidth: 2
                        //    },

                        //    {
                        //        type: "polygon",
                        //        points: [1000, 1000, 1500, 1500, 500, 1500, 250, 1000]
                        //    },
                        //    {
                        //        type: "polygon",
                        //        points: [2000, 2000, 2500, 2500, 1500, 2500],
                        //        fill: "none",
                        //        stroke: "#fcaf3e",
                        //        strokeWidth: 8,
                        //        strokeLineJoin: "round",
                        //        data: { name: "foo", area: "cafe" },
                        //        onClick: data => {
                        //            // arbitrary data can be passed for each polygon
                        //            console.info("clicked polygon, data:", data);
                        //        }
                        //    },
                        //],
                    }
                );
                element.style.visibility = "visible";
                //$('.meridian-tag-label-Chair').css('background', 'yellow');
                //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];
                if (this.props.collectionsdata && this.props.collectionsdata[0] && !this.props.collectionsdata[0].hasOwnProperty("count")) {
                    let rules = [];
                    this.props.collectionsdata.forEach(item => {
                        //this.addCSSclass([
                        //    {
                        //        selector: `.meridian-tag-label-${item.name.replace(" ", "-")}`,
                        //        rule: `background-color:${item.hexColor};background-image:none`
                        //    }
                        //]);
                        //this.toggleClass(element, `meridian-tag-label-${item.name.replace(" ", "-")}`, false);
                        rules.push(
                            {
                                selector: `.meridian-tag-label-${item.labelName.replace(" ", "-")}`,
                                rule: `background-color:${item.hexColor};background-image:none`
                            }
                        );
                    });
                    this.addCSSclass2(rules);
                }
                this.setState({ mapVisible: true });
            }


        }
    }
    getOption(token, method = 'get') {
        //let token = "6c09d265b1413fc768f4e08447ea95c8923a9c78"
        let bearer = `Token ${token}`;
        let headers = new Headers();
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        var option = {
            method: method,
            headers: headers
        };
        return option;
    }
    toggleClass(elem, theClass, newState) {
        var matchRegExp = new RegExp('(?:^|\\s)' + theClass + '(?!\\S)', 'g');
        var add = (arguments.length > 2 ? newState : (elem.className.match(matchRegExp) === null));

        elem.className = elem.className.replace(matchRegExp, ''); // clear all
        if (add) elem.className += ' ' + theClass;
    }

    addCSSclass(rules) {
        var style = document.createElement("style");
        style.setAttribute(`${rules[0].selector}`, `${rules[0].selector}`);
        style.appendChild(document.createTextNode("")); // WebKit hack :(
        document.head.appendChild(style);
        var sheet = style.sheet;

        rules.forEach((rule, index) => {
            try {
                if ("insertRule" in sheet) {
                    sheet.insertRule(rule.selector + "{" + rule.rule + "}", index);
                } else if ("addRule" in sheet) {
                    sheet.addRule(rule.selector, rule.rule, index);
                }
            } catch (e) {
                // firefox can break here          
            }

        });
    }
    addCSSclass2(rules) {
        var style = document.createElement("style");
        style.setAttribute('id', "coloured");
        let innerHTML = '';
        rules.forEach((rule, index) => {
            innerHTML += `${rule.selector} {
                ${rule.rule}
            }`;
        });
        style.innerHTML = innerHTML;
        document.head.appendChild(style);
    }
    componentWillUnmount() {
        let cs = document.querySelectorAll("[id='coloured']");
        cs.forEach(item => item.remove());
    }
    renderValue() {
        return this.state.selectedCollections.length == 1 ?
            <Box pad="small" direction="row" gap="small">
                {/*<StatusGoodSmall color={this.state.selectedCollections[0].hexColor} />*/}
                <Box round height="23px" background={this.state.selectedCollections[0].hexColor} width="23px" border style={{ /*"border-color": "red",*/ "border-width": "2px" }} />
                {this.state.selectedCollections[0].name}
            </Box>
            :
            this.state.selectedCollections.length == 0 ?
                <Box pad="small">All Collections</Box>
                :
                <Box pad="small">Multiple Collections</Box>;
    }
    resetFilter = () => {
        this.setState({
            showPlacemarks: true,
            showTags: false,
            showControlTags: /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false,
            //control: "All",
            //mapVisible: true,
            selectedCollections: [],
            assetsOnMap: [],
            showUnlinkedTags: /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false,
            selectedDepartment: "",
            selectedStatus: "",
            statuses: [],
            departments: [],
            unlinks: [],
            showSpacesOnly: true
        }, () => {
            if (this.state.selectedFloorId)
                this.renderMap(this.state.selectedFloorId);
        });
    }

    filterDataBySelections() {
        const { selectedDepartment, selectedManufacturer, selectedModel, selectedDeviceType, selectedStatus } = this.state;
        let assets = [];
        let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);
        if (!(selectedDepartment == "" /*&& selectedManufacturer == "" && selectedModel == "" && selectedDeviceType == ""*/ && selectedStatus == "")) {
            let trackers = this.props.trackersdata.filter(t =>
                ((selectedDepartment && selectedDepartment.id == t.departmentId) || selectedDepartment == "") &&
                ((selectedManufacturer && selectedManufacturer.id == t.manufacturerId) || selectedManufacturer == "") &&
                ((selectedModel && selectedModel.id == t.modelId) || selectedModel == "") &&
                ((selectedDeviceType && selectedDeviceType.id == t.deviceTypeId) || selectedDeviceType == "")
            )
            console.log("dirr", trackers)
            if (trackers) {
                //check if we have linked tag(s) for that tracker(s)

                //assets = trackers.map(t => t.assetBeaconId);

                //let links = this.props.associationsdata.filter(a => trackers.map(t => t.id).includes(a.assetId) && a.beaconId);
                //if (links) {
                //    assets = [];
                //    links.forEach(l => {
                //        l.id = l.beaconId;
                //        assets.push(l.beaconId);
                //    });
                //}

                let links = this.props.assetsdata.filter(a => trackers.map(t => t.assetBeaconId).includes(a.id));
                if (selectedStatus != "") {
                    assets = links.filter(a => a.serviceStateId == selectedStatus.id).map(a => a.id);
                } else {
                    assets = links.map(a => a.id);
                }
            }
        }
        else {
            if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                //Dont have links && only show links
                assets = [];
            }
            else {
                assets = unl;
            }
        }

        return assets;
    }

    renderActionBar(toFullScreen = true) {
        let isClearVisible =
            this.state.showPlacemarks == true &&
                this.state.showTags == false &&
                this.state.showControlTags == /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false &&
                this.state.selectedCollections.length == 0 &&
                this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags == /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false &&
                this.state.selectedDepartment == "" &&
                this.state.selectedStatus == "" &&
                this.state.showSpacesOnly == true
                ? false : true;

        return (
            <Box id="actionbar" direction="row" >
                <StyledBox id="search" style1 width="70%" direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid", height: "48px" }} /*onClick={() => { }}*/ ref={this.target}>
                    <TextInput
                        plain
                        focusIndicator={false}
                        placeholder="Search"
                        dropTarget={this.target.current}
                        onClose={() => this.setState({ assetList: this.mix })}
                        value={this.state.selectedAsset ? this.state.selectedAsset.name : ""}
                        onChange={evt => {
                            //console.log("rara", evt.target.value);
                            let searchText = evt.target.value;
                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                            const filtered = this.mix.filter(o => o.name.match(regexp));
                            this.setState({ assetList: filtered, selectedAsset: { name: evt.target.value } });
                        }}
                        onSuggestionSelect={evt => {
                            this.setState({ selectedAsset: evt.suggestion.option });
                            console.log("raraS", evt);
                            let element = evt.suggestion.option;
                            if (element) {
                                if (element.hasOwnProperty("isControlTag")) {
                                    //Tag or Asset
                                    const assetid = element.id;
                                    const buildingid = element.map.group.id;
                                    const mapid = element.map.id;
                                    const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                    const floorName = this.props.levelsdata.find(l => l.meridianId == mapid);
                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                    console.log("floors", floors);
                                    this.setState({ selectedFloorId: mapid, selectedFloor: floorName, selectedBuilding: buildingName, floors: floors, assetid: assetid, placemarkid: "" }, () => this.renderMap(mapid));
                                }
                                else {
                                    //Placemark
                                    const placemarkid = element.meridianId;
                                    //const buildingid = element.map.group.id;
                                    //const mapid = element.meridianId;
                                    //const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                    const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
                                    const mapid = floor.meridianId;
                                    //const buildingName = floor.mergedBuildingName;
                                    const buildingName = this.emptyBuildingArr.concat(this.props.buildingsdata).find(b => b.mergedId == floor.mergedBuildingId);
                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                    console.log("floors", floors);
                                    this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));
                                }
                            }
                        }}
                        suggestions={this.state.assetList.map((option, index, list) => {
                            let info = "";
                            let name = "";
                            if (option.hasOwnProperty("isControlTag")) {
                                //Tag or Asset
                                if (RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                    if (this.props.associationsdata && this.props.associationsdata.length > 0 && !this.props.associationsdata[0].hasOwnProperty("count") &&
                                        this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(option.id)) {
                                        //Linked
                                        info = "Tracker";
                                        //Get the Tracker Name
                                        let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                        let tracker = this.props.trackersdata.find(t => t.id == association.assetId);
                                        name = tracker ? tracker.name : option.name;
                                    }
                                    else {
                                        //Not Linked, isControlTag will determine it
                                        info = option.isControlTag ? "Control Tag" : "Unlinked Tag";
                                        name = option.name;
                                    }
                                }
                                else {
                                    //Only Linked
                                    info = "Tracker";
                                    //Get the Tracker Name
                                    let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                    let tracker = this.props.association ? this.props.trackersdata.find(t => t.id == association.assetId) : "";
                                    name = tracker ? tracker.name : option.name;
                                }
                            } else {
                                //Placemark
                                info = "Placemark";
                                if (option.placemarktype == "Desk" || option.teemType == "desk")
                                    info = "Desk";
                                else if (option.placemarktype == "Conference Room" || option.teemType == "room")
                                    info = "Room";
                                name = option.name;
                            }
                            return {
                                label: (
                                    <Box pad="small" justify="between" direction="row">
                                        {name}
                                        <Box>{info}</Box>
                                    </Box>),
                                value: name,
                                option: option

                            };
                        })
                        }
                    />
                    <Button
                        icon={this.state.selectedAsset ? < FormClose color={constants.HPE_GREEN} /> : <Blank />}
                        disabled={this.state.selectedAsset ? false : true}
                        onClick={() => this.setState({ selectedAsset: "", assetid: "", placemarkid: "", assetList: this.mix }, () => {
                            if (this.state.selectedFloorId) {
                                this.renderMap(this.state.selectedFloorId);
                            }
                        })}
                    />
                </StyledBox>

                <Box justify="between" direction="row" width="100%">
                    <Box id="filter" direction="row" gap="small" height="48px">
                        <StyledBox style1 alignSelf="center" focusIndicator={true} style={{ "margin-left": "5px", border: "rgba(100, 100, 100, 0.35) 1px solid" }}>
                            <FilterButton icon={<Filter />} onClick={() => this.setState({ showFilter: true })} />
                        </StyledBox>
                        <Box style={{ visibility: isClearVisible ? "visible" : "hidden" }} alignSelf="center">
                            <Anchor label={<Text size="xsmall">Clear filters</Text>} onClick={this.resetFilter} />
                        </Box>
                    </Box>
                    <Box id={toFullScreen ? "expand" : "close"} alignSelf="center" alignContent="end" data-for={toFullScreen ? "seefull" : "closefull"} data-tip={toFullScreen ? "See Fullscreen" : "Close Fullscreen"}>
                        <Button icon={toFullScreen ? <Expand /> : <Contract />}
                            focusIndicator={this.state.isFullScreen}
                            onClick={() => this.setState({ isFullScreen: toFullScreen }, () => {
                                if (this.map) {
                                    this.map.destroy();
                                    if (this.state.selectedFloorId) {
                                        this.setState({ isDestroyed: true }, () =>
                                            this.renderMap(this.state.selectedFloorId));
                                    }
                                }
                            })}
                            plain />
                        <ReactTooltip id={toFullScreen ? "seefull" : "closefull"} place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                </Box>
            </Box>
        );
    }

    async getDesks(data = [], time = "") {
        let bookableSpaces = data.filter(p => p.graphUri && p.placemarktype == "Desk");
        if (bookableSpaces.length == 0) {
            //We dont have bookable spaces, therefore we won't make any api call
            this.setState({ availableDesks: [] });
            return;
        }
        let schedules = bookableSpaces.map(d => d.graphUri);
        //date.toISOString
        let start = new Date(this.state.date);
        let end = new Date(this.state.date);
        let startUTC = "";
        let endUTC = "";
        let availabilityView = "";
        switch (this.state.dateTime) {
            case 'Half Day AM':
                start.setHours(9, 0, 0, 0);
                end.setHours(12, 0, 0, 0);
                availabilityView = "000";
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 9, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 12, 0, 0, 0));
                break;
            case 'Half Day PM':
                start.setHours(12, 0, 0, 0);
                end.setHours(23, 59, 59, 0);
                availabilityView = "000000000000";
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 12, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0));
                break;
            case 'Full Day':
                start.setHours(9, 0, 0, 0);
                end.setHours(23, 59, 59, 0);
                availabilityView = "000000000000000";
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 9, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0));
                break;
        }
        let body = {
            schedules: schedules,
            availabilityViewInterval: 60,
            startTime: {
                //dateTime: "2021-07-15T10:46:55+01:00",
                dateTime: startUTC.toISOString().replace("Z", ""),
                timeZone: "UTC" //UTC?
            },
            endTime: {
                //dateTime: "2021-07-14T23:59:59+01:00",
                dateTime: endUTC.toISOString().replace("Z", ""),
                timeZone: "UTC" //UTC?
            }

        };
        console.log("grapdata", body, JSON.stringify(body));
        let j = '{"schedules":["Chrishroom@hpepngalway.onmicrosoft.com","erris@hpepngalway.onmicrosoft.com","Desk1@hpepngalway.onmicrosoft.com","Desk2@hpepngalway.onmicrosoft.com","Desk3@hpepngalway.onmicrosoft.com","Desk4@hpepngalway.onmicrosoft.com","Desk5@hpepngalway.onmicrosoft.com","Desk6@hpepngalway.onmicrosoft.com","Desk7@hpepngalway.onmicrosoft.com","Desk8@hpepngalway.onmicrosoft.com","Desk9@hpepngalway.onmicrosoft.com","Desk10@hpepngalway.onmicrosoft.com","Desk11@hpepngalway.onmicrosoft.com","Desk12@hpepngalway.onmicrosoft.com","Desk13@hpepngalway.onmicrosoft.com","Desk14@hpepngalway.onmicrosoft.com","Desk15@hpepngalway.onmicrosoft.com","Desk16@hpepngalway.onmicrosoft.com","Desk17@hpepngalway.onmicrosoft.com","Desk18@hpepngalway.onmicrosoft.com","Desk19@hpepngalway.onmicrosoft.com","Desk20@hpepngalway.onmicrosoft.com","Desk21@hpepngalway.onmicrosoft.com","Desk22@hpepngalway.onmicrosoft.com","Desk23@hpepngalway.onmicrosoft.com","Desk24@hpepngalway.onmicrosoft.com","Desk25@hpepngalway.onmicrosoft.com","Desk26@hpepngalway.onmicrosoft.com","Desk27@hpepngalway.onmicrosoft.com","Desk28@hpepngalway.onmicrosoft.com","Desk29@hpepngalway.onmicrosoft.com","Desk30@hpepngalway.onmicrosoft.com"],"startTime":{"dateTime":"2021-07-13T10:46:55+01:00","timeZone":"GMT"},"endTime":{"dateTime":"2021-07-13T23:55:00+01:00","timeZone":"GMT"},"availabilityViewInterval":788}';
        let headers = new Headers;
        const options = {
            method: 'post',
            headers: {
                //'Ocp-Apim-Subscription-Key': 'b9964e2d7c374c27905d9c9857f86f6e',
                //'Ocp-Apim-Trace': 'True',
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken()
                //'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyIsImtpZCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyJ9.eyJhdWQiOiJhZmFlNTNiOS0zM2Y2LTRiZDYtOWJiMS0wMGMwZTg1ZDZhZDgiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mZmY2YTE4Zi01YjA3LTRhYjgtYmNjMC0zNjBhNjRlOGFmNjUvIiwiaWF0IjoxNjI2NzY3MDQ0LCJuYmYiOjE2MjY3NjcwNDQsImV4cCI6MTYyNjc3MDk0NCwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQWlBY3dPMGNyWGh0eEtVTzgxbFFyUFRrK2ZUNGpRcnpEalgxQm5PUkNVcnFvclIyREc1TzlYcEt2RUszTEprU0UiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiZDc2ZTBkZGUtMDhmZS00YzFiLTg4N2ItZDFiYmU4ZDhhZWM2IiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJVc2VyIiwiZ2l2ZW5fbmFtZSI6IkVtcGxveWVlIiwiaXBhZGRyIjoiODkuMTMyLjE3OS42MSIsIm5hbWUiOiJFbXBsb3llZSBVc2VyIiwib2lkIjoiMWVmN2IxNWUtMzc4OC00NjdjLWJkZDctOTNhOWFjYzAyN2E4IiwicmgiOiIwLkFURUFqNkgyX3dkYnVFcTh3RFlLWk9pdlpkNE5idGYtQ0J0TWlIdlJ1LWpZcnNZeEFEQS4iLCJzY3AiOiJ1c2UuYWxsIiwic3ViIjoiMm4zR3NOVTVaWWlnYVFuVHVGdTBlVmlUV3lFZjBwbHJhZzh3ak1ZVDN2VSIsInRpZCI6ImZmZjZhMThmLTViMDctNGFiOC1iY2MwLTM2MGE2NGU4YWY2NSIsInVuaXF1ZV9uYW1lIjoiZW1wbG95ZWVAaHBlcG5nYWx3YXkub25taWNyb3NvZnQuY29tIiwidXBuIjoiZW1wbG95ZWVAaHBlcG5nYWx3YXkub25taWNyb3NvZnQuY29tIiwidXRpIjoiVGw2TlNTVkU5MFdScGhRc0l3QnJBQSIsInZlciI6IjEuMCJ9.Q_gKQADP8cJfLsU5v-7N6GPgUjaFqIUTlq1HaBgVQcVQWxb8kmXiQGuBLiHnq7FFKjCJpx_nn3lukOKhyr03yGERMDhP7RKDsxqyWjLwkO8wMJGTtKPXEdKmcguGoZYa1nbfXiLx8lckfgMHyV4zFX5lSm0ZFMgdoAL5_fhWQSaXouKM98GsUbPKv4ZfKGoyN88FhreHkBdtXbMU9AOsOH3ujkhLws3hriGXPqX8DxKi_HU-pwltfJy2Caxdn5smHvwIwVShaaO18tprF9gvbSoLXOqJju0HH5AUBvpEl_ms656ZzOp5-zX-lq6iNiErdAgCzisCcr8qH7EfDVZKGg',
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlbXBsb3llZUBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJ1bmlxdWVfbmFtZSI6ImVtcGxveWVlQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsIm5hbSI6IkVtcGxveWVlIFVzZXIiLCJ0ZW4iOiI1MWQ1NzE3NzBkOWY0MjVkOWY4NzEyYjVmMTY4NzYzMyIsIm9yZyI6Ik1PT0ciLCJhcHAiOiIyLjMuMiIsInZlciI6IjMiLCJpc3MiOiJodHRwczovL2hwZXBuZ215d3BkZW1vLmF6dXJld2Vic2l0ZXMubmV0LyIsImF1ZCI6Imh0dHBzOi8vaHBlcG5nbXl3cGRlbW8uYXp1cmV3ZWJzaXRlcy5uZXQvIiwiZXhwIjoxNjI2NzMxNjcwLCJuYmYiOjE2MjY3MDI4NzB9.R1snk7LGp1XhA1q7g4TmXrfSKD8sdS1xAmyYrm9wdkQ'
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlbXBsb3llZUBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJ1bmlxdWVfbmFtZSI6ImVtcGxveWVlQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsIm5hbSI6IkVtcGxveWVlIFVzZXIiLCJ0ZW4iOiI1MWQ1NzE3NzBkOWY0MjVkOWY4NzEyYjVmMTY4NzYzMyIsIm9yZyI6Ik1PT0ciLCJ2ZXIiOiIzIiwiaXNzIjoiaHR0cHM6Ly9ocGVwbmdteXdwZGVtby5henVyZXdlYnNpdGVzLm5ldC8iLCJhdWQiOiJodHRwczovL2hwZXBuZ215d3BkZW1vLmF6dXJld2Vic2l0ZXMubmV0LyIsImV4cCI6MTYyNjIyODc3NiwibmJmIjoxNjI2MTk5OTc2fQ.Jac4rQ1kkjHPxFo90_YAjyPt3WV3xgHEiSzSbMrZsXg',
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlbXBsb3llZUBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJ1bmlxdWVfbmFtZSI6ImVtcGxveWVlQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsIm5hbSI6IkVtcGxveWVlIFVzZXIiLCJ0ZW4iOiI1MWQ1NzE3NzBkOWY0MjVkOWY4NzEyYjVmMTY4NzYzMyIsIm9yZyI6Ik1PT0ciLCJ2ZXIiOiIzIiwiaXNzIjoiaHR0cHM6Ly9ocGVwbmdteXdwZGVtby5henVyZXdlYnNpdGVzLm5ldC8iLCJhdWQiOiJodHRwczovL2hwZXBuZ215d3BkZW1vLmF6dXJld2Vic2l0ZXMubmV0LyIsImV4cCI6MTYyNjIyNzcxOSwibmJmIjoxNjI2MTk4OTE5fQ.gz3GxvdrHtTs6FHNQYEO00xFtOHqQ46bJ0Wv4BHGifQ',
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtYWxsQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsInVuaXF1ZV9uYW1lIjoibWFsbEBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJuYW0iOiJNYWwgTCIsInRlbiI6IjUxZDU3MTc3MGQ5ZjQyNWQ5Zjg3MTJiNWYxNjg3NjMzIiwib3JnIjoiTU9PRyIsImFwcCI6IjIuMy4yIiwidmVyIjoiMyIsImlzcyI6Imh0dHBzOi8vaHBlcG5nbXl3cGRlbW8uYXp1cmV3ZWJzaXRlcy5uZXQvIiwiYXVkIjoiaHR0cHM6Ly9ocGVwbmdteXdwZGVtby5henVyZXdlYnNpdGVzLm5ldC8iLCJleHAiOjE2MjYyMDIwMTUsIm5iZiI6MTYyNjE3MzIxNX0.r0bgoDemOIfu-10aFDF4btuK9E-s-ot2EFk48Stq4VM'
            },
            //headers: headers,
            //body: j
            body: JSON.stringify(body)
        };
        return fetch('https://hpepngdemo.azure-api.net/graph/me/calendar/microsoft.graph.getSchedule', options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(response => {
                
                let availableDesks = [];
                if (response.value) {
                    response.value.forEach(item => {
                        //A) if the Space Department is null it will show up for everyone
                        //B) if the Space Department = "HR" or whatever, it will show up for the Graph User who has their "department" property set to "HR"
                        //space.dep is null && user.dep is null => Bookable if free
                        //space_dep is NOT null && user.dep is null => NOT Bookable if free
                        //space.dep is null && user.dep is NOT null => Bookable if free
                        //space.dep is NOT null && user.dep is NOT null  &&  space.dep==user.dep => Bookable if free
                        let space = bookableSpaces.find(s => s.graphUri == item.scheduleId);
                        if (space) {
                            //let availabilityView = time.getHours() >= 12 ? "01" : "10";
                            if (item.availabilityView == availabilityView) {
                                if (space.department == "" || space.department == null) {
                                    //space.dep is null => Bookable if free
                                    //green - Free to book
                                    space.bookingStatus = "bookable";
                                }
                                else if (this.user && this.user.department && space.department == this.user.department) {
                                    //space.dep is NOT null && user.dep is NOT null && space.dep==user.dep => Bookable if free
                                    //green - Free to book
                                    space.bookingStatus = "bookable";
                                }
                                else {
                                    //space_dep is NOT null && user.dep is null => NOT Bookable
                                    //grey - not allowed to book
                                    space.bookingStatus = "notAllowed";
                                }
                                availableDesks.push(space);
                            }
                        }
                    });
                }
                console.log("bookDesk-get", availableDesks);
                console.log("zso",availableDesks);
                this.setState({ availableDesks: availableDesks });
                return true;
            })
            .catch(error => console.log("graph-error", error));


        //fetch(url, {
        //    method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //    //mode: 'cors', // no-cors, *cors, same-origin
        //    //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //    //credentials: 'same-origin', // include, *same-origin, omit
        //    headers: {
        //        'Content-Type': 'application/json'
        //        // 'Content-Type': 'application/x-www-form-urlencoded',
        //    },
        //    //redirect: 'follow', // manual, *follow, error
        //    //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //    body: JSON.stringify(data) // body data type must match "Content-Type" header
        //});
    }
    async bookDesk(placemark, time= "") {
        //let utc = time.toISOString().replace("Z", "");
        let start = new Date(this.state.date);
        let end = new Date(this.state.date);
        let startUTC = "";
        let endUTC = "";
        switch (this.state.dateTime) {
            case 'Half Day AM':
                start.setHours(9, 0, 0, 0);
                end.setHours(12, 0, 0, 0);
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 9, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 12, 0, 0, 0));
                break;
            case 'Half Day PM':
                start.setHours(12, 0, 0, 0);
                end.setHours(17, 0, 0, 0);
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 12, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 17, 0, 0, 0));
                break;
            case 'Full Day':
                start.setHours(9, 0, 0, 0);
                end.setHours(17, 0, 0, 0);
                startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 9, 0, 0, 0));
                endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 17, 0, 0, 0));
                break;
        }
        let body = {
            subject: `Desk Booking - ${placemark.name}`,
            //showAs: "free",
            body: {
                content: `Portal Booking - ${placemark.name}`,
                contentType: "HTML"
            },
            start: {
                //dateTime: "2021-07-15T10:46:55+01:00",
                dateTime: startUTC.toISOString().replace("Z", ""),
                timeZone: "UTC" //UTC?
            },
            end: {
                //dateTime: "2021-07-14T23:59:59+01:00",
                dateTime: endUTC.toISOString().replace("Z", ""),
                timeZone: "UTC" //UTC?
            },
            location: {
                displayName: `${placemark.name}`,
                locationEmailAddress: `${placemark.graphUri}`,
                locationType: "conferenceRoom"//`${placemark.placemarktype}`
            },
            attendees: [
                {
                    emailAddress: {
                        address: `${placemark.graphUri}`,
                        name: `${placemark.name}`
                    },
                    type: "resource"
                }
            ],
        };
        let j = '{"subject":"Desk Booking - Desk 15","showAs":"free","body":{"contentType":"HTML","content":"My Workplace Booking - Desk 15 (reserved via My Workplace by Mal L)."},"start":{"dateTime":"2021-07-14T13:21:56+01:00","timeZone":"GMT"},"end":{"dateTime":"2021-07-14T23:55:00+01:00","timeZone":"GMT"},"location":{"displayName":"Desk 15","locationType":"conferenceRoom","locationEmailAddress":"Desk15@hpepngalway.onmicrosoft.com"},"attendees":[{"type":"resource","emailAddress":{"name":"Desk 15","address":"Desk15@hpepngalway.onmicrosoft.com"}}]}';
        const options = {
            method: 'post',
            headers: {
                //'X-AppName': 'myWorkplace Demo',
                //'X-LocationID': '134040',
                //'X-OrgID': '11574',
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyIsImtpZCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyJ9.eyJhdWQiOiJhZmFlNTNiOS0zM2Y2LTRiZDYtOWJiMS0wMGMwZTg1ZDZhZDgiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mZmY2YTE4Zi01YjA3LTRhYjgtYmNjMC0zNjBhNjRlOGFmNjUvIiwiaWF0IjoxNjI2NzY3MDQ0LCJuYmYiOjE2MjY3NjcwNDQsImV4cCI6MTYyNjc3MDk0NCwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQWlBY3dPMGNyWGh0eEtVTzgxbFFyUFRrK2ZUNGpRcnpEalgxQm5PUkNVcnFvclIyREc1TzlYcEt2RUszTEprU0UiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiZDc2ZTBkZGUtMDhmZS00YzFiLTg4N2ItZDFiYmU4ZDhhZWM2IiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJVc2VyIiwiZ2l2ZW5fbmFtZSI6IkVtcGxveWVlIiwiaXBhZGRyIjoiODkuMTMyLjE3OS42MSIsIm5hbWUiOiJFbXBsb3llZSBVc2VyIiwib2lkIjoiMWVmN2IxNWUtMzc4OC00NjdjLWJkZDctOTNhOWFjYzAyN2E4IiwicmgiOiIwLkFURUFqNkgyX3dkYnVFcTh3RFlLWk9pdlpkNE5idGYtQ0J0TWlIdlJ1LWpZcnNZeEFEQS4iLCJzY3AiOiJ1c2UuYWxsIiwic3ViIjoiMm4zR3NOVTVaWWlnYVFuVHVGdTBlVmlUV3lFZjBwbHJhZzh3ak1ZVDN2VSIsInRpZCI6ImZmZjZhMThmLTViMDctNGFiOC1iY2MwLTM2MGE2NGU4YWY2NSIsInVuaXF1ZV9uYW1lIjoiZW1wbG95ZWVAaHBlcG5nYWx3YXkub25taWNyb3NvZnQuY29tIiwidXBuIjoiZW1wbG95ZWVAaHBlcG5nYWx3YXkub25taWNyb3NvZnQuY29tIiwidXRpIjoiVGw2TlNTVkU5MFdScGhRc0l3QnJBQSIsInZlciI6IjEuMCJ9.Q_gKQADP8cJfLsU5v-7N6GPgUjaFqIUTlq1HaBgVQcVQWxb8kmXiQGuBLiHnq7FFKjCJpx_nn3lukOKhyr03yGERMDhP7RKDsxqyWjLwkO8wMJGTtKPXEdKmcguGoZYa1nbfXiLx8lckfgMHyV4zFX5lSm0ZFMgdoAL5_fhWQSaXouKM98GsUbPKv4ZfKGoyN88FhreHkBdtXbMU9AOsOH3ujkhLws3hriGXPqX8DxKi_HU-pwltfJy2Caxdn5smHvwIwVShaaO18tprF9gvbSoLXOqJju0HH5AUBvpEl_ms656ZzOp5-zX-lq6iNiErdAgCzisCcr8qH7EfDVZKGg',
                //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlbXBsb3llZUBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJ1bmlxdWVfbmFtZSI6ImVtcGxveWVlQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsIm5hbSI6IkVtcGxveWVlIFVzZXIiLCJ0ZW4iOiI1MWQ1NzE3NzBkOWY0MjVkOWY4NzEyYjVmMTY4NzYzMyIsIm9yZyI6Ik1PT0ciLCJ2ZXIiOiIzIiwiaXNzIjoiaHR0cHM6Ly9ocGVwbmdteXdwZGVtby5henVyZXdlYnNpdGVzLm5ldC8iLCJhdWQiOiJodHRwczovL2hwZXBuZ215d3BkZW1vLmF6dXJld2Vic2l0ZXMubmV0LyIsImV4cCI6MTYyNjIyODc3NiwibmJmIjoxNjI2MTk5OTc2fQ.Jac4rQ1kkjHPxFo90_YAjyPt3WV3xgHEiSzSbMrZsXg',              
            },
            body: JSON.stringify(body)
        };
        return fetch('https://hpepngdemo.azure-api.net/graph/me/events/', options)
            .then(response => {
                if (response.status == 201) {
                    //Success, refresh the map, desk should be booked as red
                    this.setState({ saveInProgress: true });
                    setTimeout(() => {
                        this.getDesks(this.props.placemarksdata)
                            .then(response => {
                                this.setState({ open: false, /*saveInProgress: false*/ }, () => {
                                    if (this.state.selectedFloorId) {
                                        console.log("bookDesk");
                                        this.setState({ saveInProgress: false }, () => {
                                            this.renderMap(this.state.selectedFloorId, true);
                                        });
                                    }

                                });
                            });
                    }, 5000);

                    return response.json();
                }
                return [];
            })
            .then(response => { this.setState({ response: response }); console.log(response); return true; })
            .catch(error => console.log("graph-error", error));
    }
    deskList(height) {
        //const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
        //const mapid = floor.meridianId;
        //const buildingName = floor.mergedBuildingName;
        //const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
        //this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));

        let desks = this.state.availableDesks.filter(p => {
            if (this.state.selectedFloorId) {
                if (BookViaGraph) {
                    //Graph
                    return (p.graphUri && p.placemarktype == "Desk" && p.levelId == this.state.selectedFloor.mergedId) && p.bookingStatus == "bookable";
                }
                else {
                    //Teem
                    return (p.teemId && p.teemType == "desk" && p.levelId == this.state.selectedFloor.mergedId) && p.bookingStatus == "bookable";
                }
            }
            if (BookViaGraph) {
                //Graph
                return (p.graphUri && p.placemarktype == "Desk") && p.bookingStatus == "bookable";
            }
            else {
                //Teem
                return (p.teemId && p.teemType == "desk") && p.bookingStatus == "bookable";
            }

        }).sort(function (a, b) {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
            });

        //desks = this.state.availableDesks;
        if (/*this.state.availableDesks.length*/ desks.length > 0 && this.state.selectedFloorId) {
            if (false) {
                //When Loading
                //this.state.isFullScreen ? "calc(100vh - 100px - 66px)" : "calc(100vh - 300px - 66px)",
                return (
                    <Box width="30%" height={this.state.isFullScreen ? "calc(100vh - 100px)" : "calc(100vh - 300px - 66px)"} style={{ textAlign: "center" }} border="all">
                        <Heading level='3' >Available Desks</Heading>
                        <LoadingIndicator/>
                    </Box>
                );
            }
            return (
                <Box width="30%" height={this.state.isFullScreen ? "calc(100vh - 100px)" : "calc(100vh - 300px - 66px)"}>
                    <div id="dateD">
                        <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={this.state.date}
                            onChange={evt =>
                                this.setState({ date: evt.value/*.substr(0, 10)*/ },
                                    () => {
                                        if (BookViaGraph) {
                                            this.getDesks(this.props.placemarksdata)
                                                .then(resp => {
                                                    if (this.state.selectedFloorId) {
                                                        this.renderMap(this.state.selectedFloorId, true);
                                                    }
                                                });
                                        } else {
                                            this.getDesksByTeem(this.props.sitedata.campusId, this.props.sitedata.teemOrgId, this.props.placemarksdata)
                                                .then(resp => {
                                                    if (this.state.selectedFloorId) {
                                                        this.renderMap(this.state.selectedFloorId, true);
                                                    }
                                                });
                                        }

                                    })}
                            format="dd/mm/yyyy" />
                    </div>
                    <Select options={["Half Day AM", "Half Day PM", "Full Day"]} value={this.state.dateTime}
                        onChange={(event) => {
                            let duration = "";
                            let time = "";
                            switch (/*event.target.value*/event.option) {
                                case 'Half Day AM':
                                    duration = "3 hours";
                                    time = "9AM - 12AM";
                                    break;
                                case 'Half Day PM':
                                    duration = "5 hours";
                                    time = "12AM - 5PM"
                                    break;
                                case 'Full Day':
                                    duration = "8 hours";
                                    time = "9AM - 5PM"
                                    break;
                            }
                            console.log("radio", time, duration);
                            this.setState({ dateTime: event.option, time: time, duration: duration },
                                () => {
                                    if (BookViaGraph) {
                                        this.getDesks(this.props.placemarksdata)
                                            .then(resp => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId, true);
                                                }
                                            });
                                    }
                                    else {
                                        this.getDesksByTeem(this.props.sitedata.campusId, this.props.sitedata.teemOrgId, this.props.placemarksdata)
                                            .then(resp => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId, true);
                                                }
                                            });
                                    }
                                });
                        }}/>
                    <Box /*width="30%"*/ border="all" overflow="auto" /*height={`calc(100vh - ${height}px)`}*/>
                        {/*<DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={this.state.date} onChange={evt => this.setState({ date: evt.value })} format="dd/mm/yyyy" />*/}
                        {/*this.props.placemarksdata.filter(p => {
                            if (this.state.selectedFloorId) {
                                return (p.graphUri && p.placemarktype == "Desk" && p.levelId == this.state.selectedFloor.mergedId);
                            }
                            return (p.graphUri && p.placemarktype == "Desk");
                        }).sort(function (a, b) {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base'
                            });
                            })*/desks
                            .map((s, i) =>
                                <Box /*background={this.state.grey ? "#E6E6E6" : "white"}*/
                                gap="small"
                                border="all"
                                direction="row"
                                pad="small"
                                flex={false}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    console.log("nana", s);
                                    if (this.state.selectedFloorId && this.map)
                                        this.map.zoomToPoint({ scale: 2, x: s.x, y: s.y });
                                    //this.setState({ placemarkName: s.name, open: true });
                                }} >
                                <Box alignSelf="center">
                                    <Image src="./Images/desk.jpg" /*width="80px"*/ style={{ height: "fit-content", width: "auto" }} />
                                </Box>
                                <Box width="100%" align="start">
                                    <Text weight="bold">{s.name}</Text>
                                        {/*<Text>{this.props.levelsdata.find(l => l.mergedId == s.levelId) ? this.props.levelsdata.find(l => l.mergedId == s.levelId).mergedBuildingName : "Unknown"}</Text>
                                        <Text>{this.props.levelsdata.find(l => l.mergedId == s.levelId) ? this.props.levelsdata.find(l => l.mergedId == s.levelId).mergedName : "Unknown"}</Text>*/}
                                        <Text>{this.state.selectedFloor.meridianName}, {/*this.state.selectedBuilding.meridianName*/this.state.selectedFloor.mergedBuildingName}</Text>
                                    <Text>Capacity: {s.capacity}</Text>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            );
        } else {
            return null;
        }

    }

    async getGraphUser() {
        const options = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
            },
        };
        let me = 'https://hpepngdemo.azure-api.net/graph/me?$select=displayName,mail,department';
        return fetch(me, options)
            .then(response => {
                if (response.status == 200) {
                    //Success, refresh the map, desk should be booked as red
                    return response.json();
                }
                return null;
            })
            .catch(err=> null);
    }

    async getDesksByTeem(campusID, teemOrgID, data) {
        let bookableSpaces = data.filter(p => p.teemId);
        if (bookableSpaces.length == 0) {
            //We dont have bookable spaces, therefore we won't make any api call
            this.setState({ availableDesks: [] });
            return;
        }
        let start = new Date(this.state.date);
        let end = new Date(this.state.date);

        switch (this.state.dateTime) {
            case 'Half Day AM':
                start.setHours(9, 0, 0, 0);
                end.setHours(12, 0, 0, 0);
                break;
            case 'Half Day PM':
                start.setHours(12, 0, 0, 0);
                end.setHours(23, 59, 59, 0);
                break;
            case 'Full Day':
                start.setHours(9, 0, 0, 0);
                end.setHours(23, 59, 59, 0);
                break;
        }

        let startEpo = Math.floor(start.getTime() / 1000);
        let endEpo = Math.floor(end.getTime() / 1000);
        const options = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
                'X-LocationID': campusID,
                'X-OrgID': teemOrgID
            },
        };
        let u ="inherit_settings=0&include[]=devices&include[]=buildings&include[]=campuses&include[]=floors&include[]=licenses&include[]=room_resource_categories&include[]=room_resources&include[]=calendars&bookable=true&capacity=1&floor_id=159753&inherit_settings=1&ordering=name&page=1&per_page=20&range_end_at=1639520400&range_start_at=1639519500"
        return fetch(`https://myworkplace-itg.intelligentspaces.int.hpe.com/connectors/teem/deployment/rooms/?inherit_settings=0&include[]=devices&include[]=buildings&include[]=campuses&include[]=floors&include[]=licenses&include[]=room_resource_categories&include[]=room_resources&include[]=calendars&bookable=true&capacity=1&floor_id=159753&inherit_settings=1&ordering=name&page=1&per_page=20&range_end_at=${endEpo}&range_start_at=${startEpo}&type=desk`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then(response => {
                let availableDesks = [];
                console.log("teem", response);
                if (response.rooms) {
                    response.rooms.forEach(item => {
                        let space = bookableSpaces.find(s => s.teemId == item.id);
                        if (space) {
                            space.bookingStatus = "bookable";
                            availableDesks.push(space);
                        }
                    });
                }
                console.log("teem", availableDesks);
                this.setState({ availableDesks: availableDesks });
                return true;
            })
            .catch(error => console.log("graph-error", error));
    }

    async bookDeskByTeem(campusID, teemOrgID, placemark, user) {
        this.setState({ bookClicked: true});
        let start = new Date(this.state.date);
        let end = new Date(this.state.date);

        switch (this.state.dateTime) {
            case 'Half Day AM':
                start.setHours(9, 0, 0, 0);
                end.setHours(12, 0, 0, 0);
                break;
            case 'Half Day PM':
                start.setHours(12, 0, 0, 0);
                end.setHours(17, 0, 0, 0);
                break;
            case 'Full Day':
                start.setHours(9, 0, 0, 0);
                end.setHours(17, 0, 0, 0);
                break;
        }
        //let startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth() + 1, start.getDate(), start.getHours(), start.getMinutes()));
        //let endUTC = new Date(Date.UTC(end.getFullYear(), end.getMonth() + 1, end.getDate(), end.getHours(), end.getMinutes()));
        let startUTC = Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes(), start.getUTCSeconds());
        let endUTC = Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate(), end.getUTCHours(), end.getUTCMinutes(), end.getUTCSeconds());

        //let startEpo = Math.floor(start.getTime() / 1000);
        //let endEpo = Math.floor(end.getTime() / 1000);
        let startEpo = Math.floor(startUTC / 1000);
        let endEpo = Math.floor(endUTC / 1000);
        let email = user ? user : "";
        let body = {
            reservation:
                {
                    title: `Reservation for ${placemark.name}`,
                    description: `Desk reservation of ${placemark.name} | ${this.state.selectedFloor.meridianName}, ${this.state.selectedFloor.mergedBuildingName} [${email}]`,
                    starts_at: startEpo,
                    ends_at: endEpo,
                    is_past: false,
                    is_current: false,
                    is_upcoming: false,
                    is_private: false,
                    all_day: false,
                    pending_delete: false,
                    room_id: placemark.teemId,
                    //participants: [{ "name": "Teem Resource", "email": "bence.koppany@hpe.com" }]
                },
        };
        console.log("body", '\n', body.reservation.starts_at, '\n', new Date(startEpo * 1000), '\n', start, '\n', body.reservation.ends_at, '\n', new Date(endEpo*1000), end);
        const options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
                'X-LocationID': campusID,
                'X-OrgID': teemOrgID
            },
            body: JSON.stringify(body)
        };
        return fetch('https://myworkplace-itg.intelligentspaces.int.hpe.com/connectors/teem/calendars/reservations/', options)
            .then(response => {
                if (response.status == 201) {
                    //Success, refresh the map, desk should be booked as red
                    this.setState({ saveInProgress: true });
                    setTimeout(() => {
                        this.getDesksByTeem(this.props.sitedata.campusId, this.props.sitedata.teemOrgId, this.props.placemarksdata)
                            .then(response => {
                                this.setState({ open: false, /*saveInProgress: false*/ }, () => {
                                    if (this.state.selectedFloorId) {
                                        this.setState({ saveInProgress: false }, () => {
                                            this.renderMap(this.state.selectedFloorId, true);
                                        });
                                    }
                                });
                            });
                    }, 5000)
                    return response.json();
                }
                return [];
            })
            .then(response => { this.setState({ response: response, bookClicked: false }); console.log("body",response); return true; })
            .catch(error => { console.log("graph-error", error); this.setState({ open: false, bookClicked: false });});
    }



    render() {
        const organisationdefault = this.props.organisationdata;
        const { assetsdata, /*collectionsdata,*/ departmentsdata, trackersdata, associationsdata, placemarksdata } = this.props;
        //const { buildingsdata, levelsdata } = this.props;
        const { organisationdataLoading, levelsdataLoading, buildingsdataLoading, sitedataLoading, assetsdataLoading, placemarksdataLoading } = this.state;

        if (organisationdataLoading || levelsdataLoading || buildingsdataLoading || sitedataLoading || assetsdataLoading || placemarksdataLoading) {
            return (
                <LoadingIndicator />
            );
        }
        //e = document.getElementsByClassName('meridian-tag-label-CT-Human');
        //if (e && e.length > 0) {
        //    console.log("halo1", e, e[0]);
        //    for (let i = 0; i < e.length; i++) {
        //        e[i].style.backgroundColor = "red";
        //        e[i].style.backgroundImage = "none";
        //    }
        //}
        //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];
        var headerHeight = "0px";
        if (document.getElementById('header')) {
            headerHeight = document.getElementById('header').offsetHeight + "px";
        }

        let collectionsdata = this.props.collectionsdata.filter(c => !c.hasOwnProperty("count"));
        let activeCollections = this.state.selectedCollections.length != 0 ? this.state.selectedCollections : collectionsdata;
        activeCollections.sort(constants.byPropertyCalled("name"));
        const isSiteAdmin = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        console.log("propi", this.props);
        this.props.buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });
        this.props.levelsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });

        let isClearVisible =
            this.state.showPlacemarks == true &&
                this.state.showTags == false &&
                this.state.showControlTags == /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false &&
                this.state.selectedCollections.length == 0 &&
                this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags == /*RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)*/false &&
                this.state.selectedDepartment == "" &&
                this.state.selectedStatus == "" &&
                this.state.showSpacesOnly == true
                ? false : true;

        return (
            <Box width="100%">
                <Grid fill={true}
                    rows={['xsmall', "auto"]}
                    columns={['100%', 'flex']}
                    areas={[
                        { name: 'header', start: [0, 0], end: [1, 0] },

                        { name: 'main', start: [0, 1], end: [1, 1] }, ,
                        { name: 'side', start: [1, 0], end: [1, 1] },
                    ]}
                >
                    <Box gridArea='header' direction="row"
                        align="center"
                        pad="medium"
                        justify="between"
                        background={constants.BACKGROUND_COLOR}>
                        <Heading level='2' >Book a Space</Heading>

                    </Box>
                    <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} margin={this.state.mapVisible ? { "bottom": "157px" } : ""} height="100%" fill="vertical">
                        <PopUp open={this.state.openBookedDesk} onClose={() => this.setState({ openBookedDesk: false })} text="This Space is not bookable at the moment" header={this.state.placemarkName}/*error={!this.state.isSaveSuccessful}*/ />
                        <MsalContext>
                            {user =>
                                <PopUpCancel
                                    loading={this.state.saveInProgress}
                                    width={"500px"}
                                    open={this.state.open}
                                    onCancel={() => this.setState({ open: false })}
                                    isDisabled={this.state.open && this.state.bookClicked}
                                    onSave={() => {
                                        if (BookViaGraph) {
                                            this.bookDesk(this.state.placemark)
                                        } else {
                                            let username = user.accounts.length != 0 ? user.accounts[0].username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "";
                                            this.bookDeskByTeem(this.props.sitedata.campusId, this.props.sitedata.teemOrgId, this.state.placemark, username);
                                        }
                                        //.then(response => this.setState({ open: false }));
                                        //if (this.state.selectedFloorId)
                                        //    this.renderMap(this.state.selectedFloorId, true);
                                    }}
                                    onDiscard={() => {
                                        this.setState({ open: false });
                                        //this.getDesks(this.props.placemarksdata);
                                    }}
                                    header={this.state.placemarkName}
                                    text=" "
                                    //text={`Are you sure you want to book this desk? It will be booked for the end of the day. From ${new Date()}`}
                                    SaveText="Book Now"
                                    CancelText="Cancel">
                                    <Box>
                                        <Box direction="row" justify="between">
                                            <Box /*justify="between"*/ gap="small">
                                                {/*<RadioButtonGroup
                                        name="book"
                                        options={['Half Day AM', 'Half Day PM', 'Full Day']}
                                        options={[
                                            { label: 'Half Day AM', value: 'c1' },
                                            { label: 'Half Day PM', value: 'c2' },
                                            { label: 'Full Day', value: 'c3' },
                                        ]}
                                        value={this.state.radio}
                                            onChange={(event) => {
                                                let duration = "";
                                                let time = "";
                                                switch (event.target.value) {
                                                    case 'c1':
                                                        duration = "3 hours";
                                                        time = "9AM - 12AM";
                                                        break;
                                                    case 'c2':
                                                        duration = "5 hours";
                                                        time = "12AM - 5PM"
                                                        break;
                                                    case 'c3':
                                                        duration = "8 hours";
                                                        time = "9AM - 5PM"
                                                        break;
                                                }
                                                console.log("radio", time, duration);
                                                this.setState({ radio: event.target.value, time: time, duration: duration });
                                            }}
                                        />*/}
                                                <Box /*style={{ height: "100%", justifyContent: "center" }}*/ gap="small">
                                                    <Box direction="row" gap="small">
                                                        <Calendar />
                                                        <Text>{new Date(this.state.date).toDateString()}</Text>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Clock />
                                                        <Text>{this.state.time} {abbs}</Text>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Projects />
                                                        <Text>Space Type: Desk</Text>
                                                    </Box>
                                                    <Box direction="row" gap="small">
                                                        <Location />
                                                        <Text>
                                                            {this.state.selectedFloor.meridianName}
                                                            {", "}
                                                            {this.state.selectedFloor.mergedBuildingName}
                                                        </Text>
                                                    </Box>

                                                    {/*<Text>Time: {new Date(this.state.date).toLocaleTimeString()}</Text>
                                            <Text>Organizer: {this.props.userdata.username}</Text>
                                            
                                            <Box direction="row">
                                                <Clock />
                                                Duration: {this.state.duration}
                                            </Box>
                                            <Box direction="row">
                                                <Time />
                                                Time: {this.state.time}
                                            </Box>*/}
                                                </Box>
                                            </Box>
                                            <Box width="30%">
                                                <Image src="./Images/desk.jpg" />
                                            </Box>
                                        </Box>
                                        {/*<FormField label="Start Time" error={this.starttime_err}>
                                    <DateInput disabled calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={this.state.date} onChange={evt => this.setState({ date: evt.value })} format="dd/mm/yyyy" />
                                </FormField>*/}
                                    </Box>
                                </PopUpCancel>
                            }
                            </MsalContext>
                        {this.renderActionBar(true)}


                        <Box direction="row" margin={{ "top": "small", "bottom": "medium" }}>
                            {this.deskList(350)}
                            <Box width="100%">
                                <div id="meridian-map1" className="map" style={{ visibility: "hidden" }} />
                                {!this.state.mapVisible &&
                                    <Box background="#fafafa" /*height="450px"*/ align="center">
                                        <Heading>
                                        {this.HeaderInfo == "" ?
                                            (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                            :
                                            (this.HeaderInfo)
                                        }
                                    </Heading>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                {
                    this.state.isFullScreen &&
                    <Layer
                        //position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        margin={{ "top": headerHeight }}
                        full
                        modal
                        onClickOutside={() => this.setState({ isFullScreen: false })}
                        onEsc={() => this.setState({ isFullScreen: false })}
                    >
                        <Box /*gridArea='main'*/ background={constants.BACKGROUND_COLOR} pad="medium" /*margin={this.state.mapVisible ? { "bottom": "157px" } : ""}*/ height="100%" fill="vertical">
                            
                            {this.renderActionBar(false)}

                            <Box direction="row" margin={{ "top": "small", "bottom": "medium" }}>
                                {this.deskList(150)}
                                <Box width="100%">
                                    <div id="meridian-map2" className="map" style={{ visibility: "hidden" }} />
                                    {!this.state.mapVisible &&
                                        <Box background="#fafafa" /*height="450px"*/ align="center">
                                            <Heading>
                                            {this.HeaderInfo == "" ?
                                                (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                                :
                                                (this.HeaderInfo)
                                            }
                                            </Heading>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Layer>
                }



                {this.state.showFilter && (
                    <Layer margin={{ "top": headerHeight }}
                        position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        full={/*size !== 'small' ? 'vertical' : true*/"vertical"}
                        modal
                        onClickOutside={() => this.setState({ showFilter: false })}
                        onEsc={() => this.setState({ showFilter: false })}
                    >
                        <Box
                            flex
                            overflow="auto"
                            width={{ min: 'medium' }}
                            pad={{ horizontal: 'medium', vertical: 'medium' }}
                            gap="medium"
                            fill="vertical"
                        >
                            <Header>
                                <Heading as="p" color="text-strong" margin="none">
                                    Filters
                                </Heading>
                                <Box pad={{ right: "small" }}>
                                    <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ showFilter: false })}
                                    />
                                </Box>
                            </Header>
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && <StyledBox style1 pad="small" flex="false">
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                        <Select placeholder="Select Department" options={departmentsdata.filter(d => !d.hasOwnProperty("count"))} labelKey="name" value={this.state.selectedDepartment}
                                            onChange={evt => {
                                                let trackers = trackersdata.filter(t => t.departmentId == evt.option.id);
                                                if (trackers) {
                                                    //check if we have linked tag(s) for that tracker(s)
                                                    let links = associationsdata.filter(a => trackers.map(t => t.id).includes(a.assetId));
                                                    if (links) {
                                                        links.forEach(l => l.id = l.beaconId);
                                                        console.log("departm", links);
                                                        this.setState({ assetsOnMap: links, departments: links.map(l => l.id) }, () => {
                                                            if (this.state.selectedFloorId) {
                                                                this.renderMap(this.state.selectedFloorId);
                                                            }
                                                        });
                                                    } else {
                                                        this.setState({ departments: [] });
                                                    }
                                                } else {
                                                    this.setState({ departments: [] });
                                                }
                                                this.setState({ selectedDepartment: evt.option });
                                            }} />
                                    </StyledBox>
                                    {this.state.selectedDepartment && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ selectedDepartment: "", departments: [] }, () => {
                                            if (this.state.selectedFloorId) {
                                                this.renderMap(this.state.selectedFloorId);
                                            }
                                        })}
                                    />}
                                </Box>
                            </StyledBox>
                            }
                            {false && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox style1 pad="small" flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Collection"
                                                //value={this.state.selectedCollections}
                                                value={this.renderValue()}
                                                options={collectionsdata}
                                                //labelKey="name"
                                                multiple
                                                onChange={o => {
                                                    let tmp = [...this.state.selectedCollections];
                                                    if (tmp.map(c => c.id).includes(o.option.id)) {
                                                        tmp = tmp.filter(t => t.id != o.option.id);
                                                    } else {
                                                        tmp.push(o.option);
                                                    }
                                                    this.setState({ selectedCollections: tmp }, () => {
                                                        if (this.state.selectedFloorId) {
                                                            this.renderMap(this.state.selectedFloorId);
                                                        }
                                                    });
                                                }}
                                            >
                                                {option => {
                                                    return (
                                                        <Box pad="small" direction="row" gap="small">
                                                            {/*<StatusGoodSmall color={option.hexColor} />*/}
                                                            <Box round height="23px" background={option.hexColor} width="23px" border style={{ /*"border-color": "red",*/ "border-width": "2px" }} />
                                                            {option.name}
                                                        </Box>);
                                                }}
                                            </Select>
                                        </StyledBox>
                                        {!this.state.selectedCollections.length == 0 && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedCollections: [] })}
                                        />}
                                    </Box>
                                    {activeCollections.length > 0 && <Box width="100%" gap="medium" align="start" pad={{ top: "small" }}>
                                        {activeCollections.map(c => (
                                            <Box justify="between" direction="row" gap="small" pad={{ horizontal: "small" }}>
                                                <Box round height="23px" background={c.hexColor} width="23px" border style={{ "border-width": "2px" }} />
                                                <Text>{c.name}</Text>
                                            </Box>
                                        ))}
                                    </Box>}
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteWellness").isEnabled &&
                                <StyledBox style1 pad="small" flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Status"
                                                value={this.state.selectedStatus} options={constants.ASSET_STATUS}
                                                onChange={evt => {
                                                    let list = this.props.assetsdata.filter(a => a.serviceStateId == evt.option.id);
                                                    this.setState({ selectedStatus: evt.option, statusId: evt.option.id, assetsOnMap: list, statuses: list.map(l => l.id) }, () => {
                                                        if (this.state.selectedFloorId) {
                                                            this.renderMap(this.state.selectedFloorId);
                                                        }
                                                    });
                                                }}
                                                labelKey="name">
                                                {option =>
                                                    <Box pad="small">
                                                        {option.name}
                                                    </Box>
                                                }
                                            </Select>
                                        </StyledBox>
                                        {this.state.selectedStatus && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedStatus: "", statuses: [] }, () => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId);
                                                }
                                            })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            <StyledBox style1 pad="small" flex="false">
                                {/*this.state.selectedFloorId*/true &&
                                    <Box gap="medium" align="start" /*border="all"*//* width="20%"*/>
                                    {false && <Box>
                                        <CheckBox /*reverse*/ checked={this.state.showPlacemarks} toggle label="Placemarks" onChange={() =>
                                            this.setState({ showPlacemarks: !this.state.showPlacemarks },
                                                () => {
                                                    if (this.state.selectedFloorId)
                                                        this.renderMap(this.state.selectedFloorId);
                                                }
                                            )} />
                                    </Box>}
                                        <Box>
                                            <CheckBox /*reverse*/ checked={this.state.showSpacesOnly} toggle label="Spaces Only" onChange={() =>
                                                this.setState({ showSpacesOnly: !this.state.showSpacesOnly },
                                                    () => {
                                                        if (this.state.selectedFloorId)
                                                            this.renderMap(this.state.selectedFloorId);
                                                    }
                                                )} />
                                        </Box>

                                        {false && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && <Box>
                                            <CheckBox /*reverse*/ checked={this.state.showTags} toggle label="Tags" onChange={() => this.setState({ showTags: !this.state.showTags }, () => {
                                                if (this.state.selectedFloorId)
                                                    this.renderMap(this.state.selectedFloorId);
                                            })} />
                                        </Box>}
                                        {false && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && isSiteAdmin &&
                                            <Box>
                                                <CheckBox /*reverse*/ checked={this.state.showControlTags} toggle label="Control Tags" onChange={() => this.setState({ showControlTags: !this.state.showControlTags }, () => {
                                                    if (this.state.selectedFloorId)
                                                        this.renderMap(this.state.selectedFloorId);
                                                })} />
                                            </Box>
                                        }
                                        {false && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && isSiteAdmin &&
                                            <Box>
                                                <CheckBox /*reverse*/ checked={this.state.showUnlinkedTags} toggle label="Unlinked Tags"
                                                    //onChange={() => this.setState({ showControlTags: !this.state.showControlTags }, () => {
                                                    //    if (this.state.selectedFloorId)
                                                    //        this.renderMap(this.state.selectedFloorId);
                                                    //})}
                                                    onChange={(evt) => {
                                                        console.log("Unlink", evt.target.checked);
                                                        //let includeControlTags = this.state.showControlTags ? "" : false;
                                                        //this.props.action.getMappedAssetsAction(includeControlTags, !this.state.showUnlinkedTags)
                                                        //    .catch(error => {
                                                        //        console.log('*DEBUG*- MappedAssets List Error', error);
                                                        //    });
                                                        let list = [];
                                                        if (evt.target.checked) {
                                                            //if (this.state.selectedDepartment) {
                                                            //    list = this.state.assetsOnMap;
                                                            //} else {
                                                            //    list = this.props.assetsdata;
                                                            //}
                                                            list = this.props.assetsdata;
                                                        }
                                                        else {
                                                            //if (this.state.selectedDepartment) {
                                                            //    list = this.state.assetsOnMap;
                                                            //} else {
                                                            //    list = this.props.assetsdata.filter(at => this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(at.id));
                                                            //}
                                                            list = this.props.assetsdata.filter(at => this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(at.id));
                                                        }
                                                        console.log("unlink", list, this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId));
                                                        this.setState({ showUnlinkedTags: !this.state.showUnlinkedTags, assetsOnMap: list, unlinks: list.map(l => l.id) }, () => {
                                                            if (this.state.selectedFloorId)
                                                                this.renderMap(this.state.selectedFloorId);
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </Box>}
                            </StyledBox>
                            <Box justify="between" direction="row" flex="false">
                                <StyledButton typeAdd label="Reset Filter" onClick={this.resetFilter} />
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    organisationdata: state.selectedOrganisationReducer.organisationdata,
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
    sitedata: state.selectedSiteReducer.sitedata,
    assetsdata: state.assetsReducer.assetsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    associationsdata: state.associationsReducer.associationsdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    trackersdata: state.trackersReducer.trackersdata,
    placemarksdata: state.placemarksReducer.placemarksdata.sort(constants.byPropertyCalled("name")),
    userdata: state.selectedUserReducer.userdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...LevelAction, ...BuildingAction, ...SiteAction, ...AssetAction, ...CollectionAction, ...AssociationAction, ...DepartmentAction, ...TrackerAction, ...PlacemarkAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(MeridianMapBookSpace)));