import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const placemarksReducer = (state = initialState.placemarksReducer, action) => {
    switch(action.type) {
        case ActionType.GET_PLACEMARKS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                placemarksdata: _.assign(action.placemarksdata)
            };
        }


        default: { return state; }
    }
};



export default placemarksReducer;