import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const mergedBuildingsReducer = (state = initialState.mergedBuildingsReducer, action) => {
    switch (action.type) {
        case ActionType.GET_MERGEDBUILDINGS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                mergedbuildingsdata: _.assign(action.mergedbuildingsdata)
            };
        }


        default: { return state; }
    }
};



export default mergedBuildingsReducer;