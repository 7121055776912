import * as ActionType from './ActionType';
import DepartmentApi from '../api/DepartmentApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getDepartmentsResponse = departmentsdata => ({
    type: ActionType.GET_DEPARTMENTS_RESPONSE,
    departmentsdata
});

const queuedGetAllDepartments = queueApiCall(DepartmentApi.getAllDepartments);

export function getDepartmentsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllDepartments()
            .then(departmentsdata => {
                dispatch(getDepartmentsResponse(departmentsdata));
                return departmentsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewDepartmentResponse = () => ({
    type: ActionType.ADD_NEW_DEPARTMENT_RESPONSE
});

export const updateExistingDepartmentResponse = () => ({
    type: ActionType.UPDATE_EXISTING_DEPARTMENT_RESPONSE
});

export function saveDepartmentAction(departmentdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return DepartmentApi.saveDepartment(departmentdraft)
            .then((response) => {
                if (departmentdraft.id) {
                    dispatch(updateExistingDepartmentResponse());
                } else {
                    dispatch(addNewDepartmentResponse());
                }
                return response;
            }).then((response) => {
                if (departmentdraft.id == "0") {
                    dispatch(getDepartmentAction(departmentdraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getDepartmentResponse = departmentdata => ({
    type: ActionType.GET_DEPARTMENT_RESPONSE,
    departmentdata
});

export function getDepartmentAction(departmentId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return DepartmentApi.getDepartment(departmentId)
            .then(departmentdata => {
                dispatch(getDepartmentResponse(departmentdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteDepartmentResponse = () => ({
    type: ActionType.DELETE_DEPARTMENT_RESPONSE
});

const queuedDeleteDepartment = queueApiCall(DepartmentApi.deleteDepartment);

export function deleteDepartmentAction(departmentId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteDepartment(departmentId)
            .then((response) => {
                dispatch(deleteDepartmentResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getDepartmentsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}