import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose, Download } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";

const PaginatedDataTable = withPagination(DataTable);
class AssociationListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            search: "",
            isFilterOpen: false,
            selectedTag: "",
            selectedTracker: ""
        };
    }
    getData() {
        var data = /*constants.ASSOCIATIONS*/ this.props.associationsdata;
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.assetId.toLowerCase().includes(search) ||
                d.assetName.toLowerCase().includes(search) ||
                d.beaconName.toLowerCase().includes(search) ||
                d.beaconId.toLowerCase().includes(search) ||
                (d.mergedAt ? constants.formatDate(d.mergedAt) : "").toLowerCase().includes(search)
            );
        }
        return data;
    }
    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.Exp_Data = [];
        if (this.getData()[0] && this.getData()[0].count != 0) {
            this.getData().forEach(ev => {
                this.DATA.push({
                    ...ev,
                    lcAssetName: ev.assetName ? ev.assetName.toLowerCase() : "",
                    lcBeaconName: ev.beaconName ? ev.beaconName.toLowerCase() : "",
                    created: ev.mergedAt ? constants.formatDate(ev.mergedAt) : ""
                    //lastheard: ev.timestamp ? this.formatDate(ev.timestamp) : "",
                })
                this.Exp_Data.push({
                    //"ID": ev.id,
                    "Tracker ID": ev.assetId,
                    "Tracker Name": ev.assetName,
                    "Tag ID Tag": ev.beaconId,
                    "Tag Name": ev.beaconName,
                    "Created At (UTC)": ev.mergedAt
                    //"Last Heard": ev.timestamp ? this.formatDate(ev.timestamp) : "",

                })
            });
        }
        this.DATA.sort(constants.byPropertyCalled("assetName"));
        console.log('DATA:', this.DATA);
        return (
            <Box gap="medium">
                {/*<Box direction="row" gap="large" pad={{ "top": "small" }} justify="between">
                    <Box direction="row" gap="large" width="80%">
                    <StyledBox style1>
                        <Select
                            placeholder="Select Tracker"
                                options={/*constants.TRACKERS.filter(t => t.id != "0" && !t.isAssigned).map(m=> m.name)this.props.trackersdata.filter(t => !t.hasOwnProperty("count"))}
                            labelKey="name"
                            value={this.state.selectedTracker}
                            onChange={evt => this.setState({ selectedTracker: evt.option })}
                        />
                    </StyledBox>
                    <StyledBox style1>
                        <Select
                            placeholder="Select Tag"
                                options={this.props.assetsdata.filter(a => !a.hasOwnProperty("count"))/*.map(a => a.name).filter(u => !constants.ASSOCIATIONS.map(n => n.beaconName).includes(u)).sort()}
                            labelKey="name"
                            value={this.state.selectedTag}
                            onChange={evt => this.setState({ selectedTag: evt.option })}
                        />
                    </StyledBox>
                    <Box alignSelf="center" width="100px">
                        <StyledButton
                            onClick={() => {
                                var association = {
                                    assetId: this.state.selectedTracker.id,
                                    assetName: this.state.selectedTracker.name,
                                    beaconId: this.state.selectedTag.id,
                                    beaconName: this.state.selectedTag.name
                                };
                                this.props.handleSave(association);
                                this.setState({ selectedTag: "", selectedTracker: "" });
                            }}
                            typeSave
                            label="Link"
                            disabled={!this.state.selectedTag || !this.state.selectedTracker} />
                        </Box>
                    </Box>
                </Box> */}
                <StyledBox style={{ "min-width": "920px" }}>
                    {this.renderAssetsTable(this.props, canEdit, "")}
                </StyledBox>
            </Box>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    }
    renderAssetsTable(props, canEdit, sync) {

        const COLUMNS = [
            {
                property: 'assetId',
                header: 'Tracker ID',
                render: t => <Text>{t.assetId}</Text>
            },
            {
                property: 'lcAssetName',
                header: 'Tracker Name',
                render: t => <Text>{t.assetName}</Text>
            },
            {
                property: 'lcBeaconName',
                header: 'Tag Name',
                render: t => <Text>{t.beaconName}</Text>
            },
            {
                property: 'beaconId',
                header: 'Tag ID',
                render: t => <Text>{t.beaconId}</Text>
            },
            {
                property: 'mergedAt',
                header: 'Date Created',
                render: t => <Text>{t.created}</Text>
            },
            {
                property: 'id',
                header: '',
                primary: true,
                render: t => {
                    let deleteItem = {
                        assetId: t.assetId,
                        assetName: t.assetName,
                        beaconId: null,
                        beaconName: null
                    };
                    return !RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                        <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
                        : <Box direction='row' gap="xsmall">
                            <Button plain disabled={props.deletedAssociationId.includes(t.assetId)} onClick={evt => props.handleDelete("association", deleteItem)} icon={<Trash />} />
                        </Box>
                },
            },
        ];

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "assetId" || c.property === "lcAssetName" || c.property === "beaconId" || c.property === "lcBeaconName"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.associationsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'assetId', label: 'Tracker ID' },
                //    { col: 'lcAssetName', label: 'Tracker Name' },
                //    { col: 'lcBeaconName', label: 'Tag Name' },
                //    { col: 'beaconId', label: 'Tag ID' },
                //    { col: 'mergedAt', label: 'Date Created' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                //PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
            />
        );
    }

}

export default AssociationListTable;