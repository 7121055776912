import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/asset`;
const apiqueue = [];

const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof TrackerApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class TrackerApi {
    static async getAllTrackers() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}?$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    };

    static async saveTracker(trackerdata) {
        if (trackerdata.id && trackerdata.id != "0") {
            return fetch(`${SERVER_URL}/${trackerdata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(trackerdata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(trackerdata)
            }).then(response => response);
        }
    }

    static async deleteTracker(trackerId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${trackerId}/?${API_VERSION}`, options)
            .then(response => response);
    }

    static async getTracker(trackerId) {
        console.log('API:', trackerId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${trackerId}/?$expand=Model,Manufacturer,DeviceType&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

applyHocOnClass(TrackerApi);

export default TrackerApi;
