import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedGroupReducer = (state = initialState.selectedGroupReducer, action) => {
    switch(action.type) {

        case ActionType.GET_GROUP_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                groupdata: _.assign(action.groupdata)
            };
        }

        default: { return state; }
    }
};


export default selectedGroupReducer;