import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedAssetHistoryReducer = (state = initialState.selectedAssetHistoryReducer, action) => {
    switch (action.type) {

        case ActionType.GET_ASSET_HISTORY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                historydata: _.assign(action.historydata)
            };
        }

        default: { return state; }
    }
};


export default selectedAssetHistoryReducer;