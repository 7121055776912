import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedAssetReducer = (state = initialState.selectedAssetReducer, action) => {
    switch (action.type) {

        case ActionType.GET_ASSET_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                assetdata: _.assign(action.assetdata)
            };
        }

        default: { return state; }
    }
};


export default selectedAssetReducer;