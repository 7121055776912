import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedCategoryReducer = (state = initialState.selectedCategoryReducer, action) => {
    switch(action.type) {

        case ActionType.GET_CATEGORY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                categorydata: _.assign(action.categorydata)
            };
        }

        default: { return state; }
    }
};


export default selectedCategoryReducer;