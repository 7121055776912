import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;

export class DepartmentEditContainer extends React.Component {
    constructor() {
        super();
        this.parentfull = [];
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            departmentdataLoading: true,
            departmentsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    departmentName_err = '';

    ClearErrorMessages() {
        this.departmentName_err = '';
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.departmentid;
        this.props.action.getDepartmentAction(id)
            .then(response => this.setState({ departmentdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department Edit Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.departmentdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const departmentdefault = this.props.departmentdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (departmentdefault.name.trim().length == 0) {
            errorsno++;
            this.departmentName_err = "Department Name is required";
        }
        if (departmentdefault.name.length > 30) {
            errorsno++;
            this.departmentName_err = "Department Name can't be longer than 30 character";
        }
        if (this.props.departmentsdata[0] && this.props.departmentsdata[0].count != 0 && this.props.departmentsdata.filter(item => item.id != departmentdefault.id).map(t => t.name.toLowerCase()).includes(departmentdefault.name.toLowerCase().trim())) {
            errorsno++;
            this.departmentName_err = "Department Name already exists";
        }
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Save: OK', departmentdefault);
            this.props.action.saveDepartmentAction(departmentdefault)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/categories');
                }).catch(error => {
                    console.log('*DEBUG*-Save DepartmentEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.departmentdata)) {
            this.setState({ cancel: true });
        }
        else {
            const Id = this.props.departmentdata.id;
            this.props.action.getDepartmentAction(Id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgdepartments');
                        else
                            this.props.history.push('/orgdepartments');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-DepartmentEdit Error', error);
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgdepartments');
                    else
                        this.props.history.push('/orgdepartments');
                });
        }
    }
    updateNameValue(evt) {
        this.props.departmentdata.name = evt.target.value;
        if (evt.target.value.length > 30) {
            this.departmentName_err = "Department Name can't be longer than 30 character";
        }
        if (this.props.departmentsdata[0] && this.props.departmentsdata[0].count != 0 && this.props.departmentsdata.filter(item => item.id != this.props.departmentdata.id).map(t => t.name.toLowerCase()).includes(evt.target.value.toLowerCase().trim())) {
            this.departmentName_err = "Department Name already exists";
        }
        else {
            this.departmentName_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgdepartments');
            else
                this.props.history.push('/orgdepartments');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    render() {
        const { departmentdata } = this.props;
        const { departmentdataLoading, departmentsdataLoading } = this.state;
        console.log("asda1112233", this.props.departmentsdata)
        if (departmentdataLoading || departmentsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = departmentdata && /*(categorydefault.id != "0")*/ this.props.match.params.departmentid != 0 ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Department - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        <Box pad={{ vertical: "small" }}>
                            <Text size="large">Description</Text>
                            <Text>All fields marked with an * are mandatory.</Text>
                        </Box>
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                            <FormField label="Name *" error={this.departmentName_err}>
                                <TextInput defaultValue={departmentdata.name} placeholder="Department Name" onChange={evt => this.updateNameValue(evt)} />
                            </FormField>
                        </StyledBox>

                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const Id = ownProps.match.params.departmentid || '';
    if (state.selectedDepartmentReducer.departmentdata && Id == state.selectedDepartmentReducer.departmentdata.id) {
        start = { ...state.selectedDepartmentReducer.departmentdata };
        return {
            departmentdata: state.selectedDepartmentReducer.departmentdata,
            departmentsdata: state.departmentsReducer.departmentsdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...DepartmentAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepartmentEditContainer));