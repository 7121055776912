import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedPolicyReducer = (state = initialState.selectedPolicyReducer, action) => {
    switch(action.type) {

        case ActionType.GET_POLICY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                policydata: _.assign(action.policydata)
            };
        }

        default: { return state; }
    }
};


export default selectedPolicyReducer;