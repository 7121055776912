import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as SiteAction from '../action/SiteAction';
import * as MetaDataAction from '../action/MetaDataAction';
import MetaDataApi from '../api/MetaDataApi';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose, FormClock, Download, Dashboard, Upload, DocumentVerified, DocumentCsv, CircleInformation } from 'grommet-icons';
import { Grommet, Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Meter, RadioButton } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import Notification from './common/Notification';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import './TagCreateContainer.css';
import { hpe } from 'grommet-theme-hpe';

var start = undefined;
export class OrgMetadataCreateContainer extends React.Component {
    constructor() {
        super();
        this.validData = [];
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            metadataLoading: true,
            modelsdataLoading: true,
            typesdataLoading: true,
            manufacturersdataLoading: true,
            addType: 0,
            data: [],
            apiErrorCounter: 0,
            apiErrorList: [],
            typeSuggestions: [],
            modelSuggestions: [],
            manufacturerSuggestions: [],
            showIssuesOnly: false
        };
    }


    //Validating errors
    manufacturer_err = "";
    model_err = "";
    modelType_err = "";

    ClearErrorMessages() {
        this.manufacturer_err = "";
        this.model_err = "";
        this.modelType_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.metaid;
        
        this.props.action.getMetadatasAction()
            .then(response => this.setState({ metadatasdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
        this.props.action.getMetadataAction(id)
            .then(response => this.setState({ metadataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Asset Edit Error', error);
            });
        this.props.action.getModelsAction()
            .then(response => this.setState({ modelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getTypesAction()
            .then(response => {
                //console.log("TypesData Loaded");
                const { typesdata } = this.props;
                //console.log("typesdata in loading", typesdata);
                //this.setState({ typeSuggestions: typesdata });

                this.setState({ typesdataLoading: false });
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getManufacturersAction()
            .then(response => this.setState({ manufacturersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }
    componentWillUnmount() {
        this.historyUnblock();
    }

    //utility
    //function to sort strings by abc
    sortingByName(a,b) {
        const alc = a.name? a.name.toLowerCase(): '';
        const blc = b.name? b.name.toLowerCase(): '';
        if(alc < blc)
        {
          return -1;
        }
        else if(alc > blc)
        {
          return 1;
        }
        else {
          return 0;
        }
    }

    //utility
    //function to sort strings by abc
    //used for non-ASCII characters
    sortingByLocComp(a, b){
        return a.value.localeCompare(b.value);
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const { metadata, metadatas } = this.props;
        let errorsno = 0;
        //this.ClearErrorMessages();
        this.setState({ validating: false });
        if (this.manufacturer_err != "") {
            errorsno++;
        } else {
            let existingTriplet = metadatas.find(m =>
                m.manufacturerId != null &&
                metadata.manufacturerId != null &&
                m.manufacturer.name.toLowerCase() == metadata.manufacturer.name.toLowerCase() &&
                metadata.modelId == null &&
                m.modelId == null &&
                m.deviceTypeId == null &&
                metadata.deviceTypeId == null
            );
            if (existingTriplet) {
                this.manufacturer_err = "Combination already exists";
                errorsno++;
            }
        }
        if (this.model_err != "") {
            errorsno++;
        } else {
            let existingTriplet = metadatas.find(m =>
                m.manufacturerId == metadata.manufacturerId &&
                m.modelId != null &&
                metadata.modelId != null &&
                m.model.name.toLowerCase() == metadata.model.name.toLowerCase() &&
                m.deviceTypeId == null &&
                metadata.deviceTypeId == null);
            if (existingTriplet) {
                this.model_err = "Combination already exists";
                if (this.manufacturer_err == "Combination already exists") {
                    this.manufacturer_err = "";
                }
                errorsno++;
            }
        }
        if (this.modelType_err != "") {
            errorsno++;
        }
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            //this.saveNewCategory(true);

            console.log('Save: OK', metadata);
            this.props.action.addMetadataAction(metadata)
                .then((response) => {
                    if (/*response.ok*/true) {
                        this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    }
                    return response.json();
                })
                .then(data => { console.log("ojaj", data); })
                .catch(error => {
                    console.log('*DEBUG*-Save AssetEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.setState({ cancel: true });
        }
        else {
            const Id = this.props.metadata.id;
            this.props.action.getMetadataAction(Id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push({
                                pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                                state: { index: 1 }
                            });
                        else
                            this.props.history.push({
                                pathname: '/orgtrackers',
                                state: { index: 1 }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-AssetEdit Error', error);
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push({
                            pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                            state: { index: 1 }
                        });
                    else
                        this.props.history.push({
                            pathname: '/orgtrackers',
                            state: { index: 1 }
                        });
                });
        }
    }

    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 1 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 1 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    renderTable(data) {
        if (data.length != 0) {
            return (
                <Box>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((k, index) => (
                                    <th>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            {k}
                                            {index == 3 && <Box data-for="all" data-tip={this.state.showIssuesOnly ? "Switch off to show all entries." : "Switch on to show Issues only. Items with issues will not be added to the metadata table"}>
                                                <CheckBox toggle={true} label="Show Issues only" checked={this.state.showIssuesOnly} onChange={evt => this.setState({ showIssuesOnly: evt.target.checked })} />
                                            </Box>}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((info, idx) => {
                                if (!this.state.showIssuesOnly)
                                    return (
                                        <tr>
                                            {Object.keys(info).map((key, index) => (
                                                <td class="upload">
                                                    {info[key]}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                else {
                                    let isValid = this.validData.find(item => item.id == idx);
                                    if (!isValid)
                                        return (
                                            <tr>
                                                {Object.keys(info).map((key, index) => (
                                                    <td class="upload">
                                                        {info[key]}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                }
                            })}
                        </tbody>
                    </table>
                </Box>
            );
        }
        return;
    }
    async handleSaveBulk() {
        if (this.validData.length > 0) {
            // Ok, saving data
            //this.setState({ disabledButton: true });
            /*this.validData.forEach((element, idx) =>*/for (let i = 0; i < this.validData.length; i++) {
                let element = this.validData[i];
                    element.id = 0;
                    console.log("yay2",element);
                //this.props.action.addMetadataAction(element)
                await MetaDataApi.addMetadata(element)
                    .then((response) => {
                        if (i == this.validData.length - 1) {
                            this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                        }
                        return response.json();
                    })
                    .then(data => {
                        for (let x = 0; x < this.validData.length; x++) {
                            if (x > i) {
                                if (this.validData[x].manufacturerId == 0 && this.validData[x].manufacturer.name.toLowerCase() == data.manufacturer.name.toLowerCase()) {
                                    this.validData[x].manufacturerId = data.manufacturerId;
                                    this.validData[x].manufacturer.id = data.manufacturer.id;
                                }
                                if (this.validData[x].modelId == 0 && data.modelId && this.validData[x].model.name.toLowerCase() == data.model.name.toLowerCase()) {
                                    this.validData[x].modelId = data.modelId;
                                    this.validData[x].model.id = data.model.id;
                                }
                                if (this.validData[x].deviceTypeId == 0 && data.deviceTypeId && this.validData[x].deviceType.name.toLowerCase() == data.deviceType.name.toLowerCase()) {
                                    this.validData[x].deviceTypeId = data.deviceTypeId;
                                    this.validData[x].deviceType.id = data.deviceType.id;
                                }
                            }
                        }
                        console.log("API ERROR - \"ojaj\"", data);
                    })
                    .catch(error => {
                        console.log('*DEBUG*-Save Metadata Error', error);
                        this.setState({ disabledButton: false });
                    });
            }
            //);
        }
        else {
            this.setState({ validating: true });
        }
    }
    updateSite(evt) {
        this.props.assetdata.locationId = evt.option.id;
        this.site_err = "";
        this.setState({ validating: !this.state.validating });
    }
    updateManufacturerValue(name) {
        //console.log("manufacturer changed", name);

        const { metadata, manufacturersdata, metadatas } = this.props;
        this.manufacturer_err = "";
        if (name.trim().length > 0) {
            var manufacturerObj = manufacturersdata.find(m => m.name.toLowerCase() == name.toLowerCase().trim());

            //console.log("metadata", metadata);
            //console.log("manufdata", manufacturersdata);
            //console.log("metadatas", metadatas);

            if (!manufacturerObj) {//New
                metadata.manufacturer = { "name": name, "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                metadata.manufacturerId = 0;
                let manufacturerSuggestions = this.getManufacturerSuggestions();

                //console.log("man - suggestions - new", manufacturerSuggestions);

                this.setState({ modelSuggestions: [], manufacturerSuggestions });
            } else {//Existing
                metadata.manufacturer = { "name": manufacturerObj.name, "id": manufacturerObj.id, "organizationId": manufacturerObj.organizationId };
                metadata.manufacturerId = manufacturerObj.id;
                let modelSuggestions = metadatas.filter(m => m.manufacturerId == manufacturerObj.id && m.modelId).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);

                //console.log("mod - suggestions - existing", modelSuggestions);

                this.setState({ modelSuggestions });
            }
            if (name.length > 50) {
                this.manufacturer_err = "Manufacturer's name can't be longer than 50 character";
            }
        }
        else {
            metadata.manufacturerId = null;
            metadata.manufacturer = null;
            this.setState({ modelSuggestions: [], manufacturerSuggestions: [] });
        }
    }
    updateManufacturerSelect(suggestion) {
        //console.log("manufacturer selected", suggestion);

        const { metadata, metadatas } = this.props;
        metadata.manufacturer = suggestion.option;
        metadata.manufacturerId = suggestion.option.id;
        let modelSuggestions = metadatas.filter(m => m.manufacturerId == metadata.manufacturerId && m.modelId).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
        
        //console.log("modelSuggestions", modelSuggestions);
        
        this.setState({ modelSuggestions});
    }
    getManufacturerSuggestions() {
        //console.log("get manufacturer suggestion");

        const { metadata } = this.props;
        let data = [];
        if (metadata.manufacturer && metadata.manufacturer.name) {
            data = this.props.manufacturersdata.filter(m => m.name.toLowerCase().includes(metadata.manufacturer.name.toLowerCase()));
        }
        else {
            data = this.props.manufacturersdata;
        }
        return data.map(ms => {
            return {
                label: (
                    <Box pad="small" justify="between" direction="row">
                        {ms.name}
                    </Box>),
                value: ms.name,
                option: ms
            };
        });
    }
    updateModelValue(name) {
        //console.log("model changed", name);

        const { metadata, modelsdata, metadatas } = this.props;
        this.model_err = "";
        if (this.manufacturer_err = "Combination already exists") {
            this.manufacturer_err = "";
        }
        if (name.trim().length > 0) {
            var modelObj = modelsdata.find(m => m.name.toLowerCase() == name.toLowerCase().trim());
            if (!modelObj) {//New
                metadata.model = { "name": name, "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                metadata.modelId = 0;
            } else {//Existing
                metadata.model = { "name": modelObj.name, "id": modelObj.id, "organizationId": modelObj.organizationId };
                metadata.modelId = modelObj.id;
            }
        } else {
            metadata.modelId = null;
            metadata.model = null;
        }
        let existingTriplet = metadatas.find(m =>
            m.manufacturerId == metadata.manufacturerId &&
            m.modelId != null &&
            m.model.name.toLowerCase() == name.toLowerCase());

        if (/*existingTriplet*/false) {
            this.model_err = "Combination already exists";
        }
        else if (name.length > 50) {
            this.model_err = "Model's name can't be longer than 50 character";
        }

        this.setState({ update: true });

        this.updateTypeSuggestions();
    }
    updateModelSelect(suggestion) {
        //console.log("model selected", suggestion);

        const { metadata } = this.props;
        metadata.model = suggestion.option;
        metadata.modelId = suggestion.option.id;
        this.setState({ update: true });

        this.updateTypeSuggestions();
    }
    updateModelTypeValue(name) {
        //console.log("type changed", name);

        const { metadata, typesdata, metadatas } = this.props;

        //console.log("typesdata", typesdata);
        //console.log("typesdata suggestions", this.state.typeSuggestions);
        //console.log("name", name);

        this.modelType_err = "";
        if (this.model_err == "Combination already exists") {
            this.model_err = "";
        }
        if (this.manufacturer_err = "Combination already exists") {
            this.manufacturer_err = "";
        }
        if (name.trim() != "") {
            var typeObj = typesdata.find(m => m.name.toLowerCase() == name.toLowerCase().trim());
            if (!typeObj) {//New
                metadata.deviceType = { "name": name, "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                metadata.deviceTypeId = 0;
            } else {//Existing
                metadata.deviceType = { "name": typeObj.name, "id": typeObj.id, "organizationId": typeObj.organizationId };
                metadata.deviceTypeId = typeObj.id;
            }
            let existingTriplet = metadatas.find(m =>
                m.manufacturerId == metadata.manufacturerId &&
                m.modelId == metadata.modelId &&
                m.deviceTypeId != null &&
                m.deviceType.name.toLowerCase() == name.toLowerCase().trim());

            if (existingTriplet) {
                this.modelType_err = "Combination already exists";
            }
            else if (name.length > 50) {
                this.modelType_err = "Type's name can't be longer than 50 character";
            }
        }
        else {
            metadata.deviceType = null;
            metadata.deviceTypeId = null;
        }
        this.setState({ update: true });

        this.updateTypeSuggestions();
    }

    updateTypeSuggestions(){
        //console.log("update type suggestions");

        const {typesdata, metadata, metadatas} = this.props;

        //console.log("full data", metadatas);
        //console.log("metadata", metadata);

        let dataFiltered = [];
        dataFiltered = metadatas.filter( m =>
            m.manufacturerId != null &&
            m.manufacturerId == metadata.manufacturerId &&
            m.modelId != null &&
            m.modelId == metadata.modelId //&&
            //m.deviceTypeId != null &&
            //m.deviceType.name.toLowerCase().includes(metadata.deviceType.name.toLowerCase())
        );
        dataFiltered.sort(this.sortingByName);

        //console.log("filtered data", dataFiltered);

        let data = [];
        dataFiltered.forEach( fe =>{
            typesdata.forEach( tde=>{
                if(tde.id == fe.deviceTypeId){

                    if(metadata.deviceTypeId != null && metadata.deviceType.name != ""){

                        if( tde.name.toLowerCase().includes(metadata.deviceType.name.toLowerCase()) ){
                            data.push( tde );    
                        }

                    }
                    else{
                        data.push( tde );
                    }

                }
            })
        });

        //console.log("data", data);

        this.setState({ typeSuggestions: data });
    }
    createData(data) {
        const { manufacturersdata, metadatas, typesdata, modelsdata } = this.props;
        let validList = [];
        let validData = [];

        //console.log("createData raw data:", data);

        data.forEach((d, index) => {
            let message = null;
            let metadata = {id: index};

            //console.log("foreach d:", d);

            if (d["Manufacturer"]) {
                let modelSuggestions = [];
                let manufacturerObj = manufacturersdata.find(m => m.name.toLowerCase() == d["Manufacturer"].toLowerCase().trim());
                if (!manufacturerObj) {//New
                    metadata.manufacturer = { "name": d["Manufacturer"].trim(), "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                    metadata.manufacturerId = 0;
                } else {//Existing
                    metadata.manufacturer = { "name": manufacturerObj.name, "id": manufacturerObj.id, "organizationId": manufacturerObj.organizationId };
                    metadata.manufacturerId = manufacturerObj.id;
                    modelSuggestions = metadatas.filter(m => m.manufacturerId == manufacturerObj.id && m.modelId).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                }
                if (d["Model"]) {
                    let model = metadata.manufacturerId > 0 ? 
                        modelSuggestions.find(m => m.name.toLowerCase() == d["Model"].toLowerCase().trim()) : 
                        modelsdata.find(m => m.name.toLowerCase() == d["Model"].toLowerCase().trim());
                    if (!model) {
                        //New
                        metadata.model = { "name": d["Model"].trim(), "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                        metadata.modelId = 0;
                    } else {
                        metadata.model = { "name": model.name, "id": model.id, "organizationId": model.organizationId };
                        metadata.modelId = model.id;
                    }
                }
                else {
                    metadata.model = null;
                    metadata.modelId = null;
                }

                if (d["Model Type"]) {
                    let type = typesdata.find(m => m.name.toLowerCase() == d["Model Type"].toLowerCase().trim());
                    if (!type) {
                        metadata.deviceType = { "name": d["Model Type"].trim(), "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                        metadata.deviceTypeId = 0;
                    } else {
                        metadata.deviceType = { "name": type.name, "id": type.id, "organizationId": sessionStorage.getItem("organisationId") };
                        metadata.deviceTypeId = type.id;
                    }
                }
                else {
                    metadata.deviceType = null;
                    metadata.deviceTypeId = null;
                }

                let existingTriplet = metadatas.find(m =>
                    m.manufacturerId == metadata.manufacturerId &&
                    m.modelId == metadata.modelId &&
                    m.deviceTypeId == metadata.deviceTypeId);
                if (!existingTriplet) {
                    let value = (d["Manufacturer"].trim() + d["Model"].trim() + d["Model Type"].trim()).toLowerCase();
                    let isExisting = validData.find(item => value == (item["Manufacturer"].trim() + item["Model"].trim() + item["Model Type"].trim()).toLowerCase());
                    if (!isExisting) {
                        validData.push(d);
                        validList.push(metadata);
                    }
                    else
                        message = "Duplicate entry detected. This line will not be added to the metadata table";
                }
                else {
                    message = "Warning, this combination of metadata values already exists. This line will not be added to the metadata table";
                }
            }
            else {
                message = "Manufacturer is required. This line will not be added to the metadata table";
            }

            d.Issue = message;


        });
        this.validData = validList;
        console.log("mesh", data)
        return data;
    }

    render() {
        const { assetdata, assetlabelsdata, departmentsdata, sitesdata, metadata, modelsdata, typesdata, manufacturersdata } = this.props;
        const { parent, parents, options, isNewLabel, isNewCategory, isNewDepartment, metadataLoading, modelsdataLoading, typesdataLoading, manufacturersdataLoading } = this.state;

        if (metadataLoading || modelsdataLoading || typesdataLoading || manufacturersdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        
        const heading = this.state.addType != 2 ? 'Metadata - Add' : 'Bulk Upload Metadata';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    {this.state.addType == 2 ? <Box direction="row" gap="small">
                        <Heading level='2' >{heading}</Heading>
                        {/*<CircleInformation style={{ alignSelf: "center" }} data-for="bulk" data-tip="Add metadata in bulk using the template provided." />*/}
                        <ReactTooltip id="bulk" place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box> :
                        <Heading level='2' >{heading}</Heading>
                    }
                    {/*<Heading level='2' >{heading}</Heading>*/}
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={() => this.state.addType != 2 ? this.handleSave() : this.handleSaveBulk()} disabled={this.state.disabledButton || this.state.addType == 0 || (this.state.addType == 2 && this.validData.length == 0)} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        {this.state.addType == 0 &&
                            <Box>
                                {/*Add Type*/}
                                <StyledBox style1 direction="row" gap="xlarge" pad={{ "vertical": "small", "horizontal": "small" }}>
                                    <Box data-for="tag" data-tip="Add a single entry">
                                        <RadioButton
                                            label="Add Metadata"
                                            checked={this.state.addType == 1 ? true : false}
                                            onChange={() => this.setState({ addType: 1 })} />
                                    </Box>
                                    <Box data-for="tags" data-tip="Add multiple items in bulk">
                                        <RadioButton
                                            label="Add Multiple Items"
                                            checked={this.state.addType == 2 ? true : false}
                                            onChange={() => this.setState({ addType: 2 })} />
                                    </Box>
                                    <ReactTooltip id="tag" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                    <ReactTooltip id="tags" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                </StyledBox>
                            </Box>
                        }
                        {this.state.addType == 1 &&
                            <Box>
                                <Box pad={{ vertical: "small" }}>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    {/*<Text size="large">Description</Text>*/}
                                </Box>
                                {/* <form> */}
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                                    <FormField label="Manufacturer *" error={this.manufacturer_err}>
                                    <TextInput
                                        value={metadata.manufacturer ? metadata.manufacturer.name : ""}
                                        onChange={evt => this.updateManufacturerValue(evt.target.value)}
                                        onSuggestionSelect={evt => this.updateManufacturerSelect(evt.suggestion)}
                                        suggestions={metadata.manufacturer ? this.state.manufacturerSuggestions.sort(this.sortingByName) : this.props.manufacturersdata.sort(this.sortingByName).map(ms => {
                                            return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                        })}
                                        dropHeight="medium" />
                                    </FormField>
                                <FormField label="Model" error={this.model_err}>
                                    <TextInput
                                        disabled={metadata.manufacturerId == null}
                                        value={metadata.model? metadata.model.name : ""}
                                        onChange={evt => this.updateModelValue(evt.target.value)}
                                        onSuggestionSelect={evt => this.updateModelSelect(evt.suggestion)}
                                        dropHeight="medium"
                                        suggestions={this.state.modelSuggestions.sort(this.sortingByName).map(ms => {
                                            return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                        })} />
                                    </FormField>
                                    <FormField label="Model Type" error={this.modelType_err}>
                                    <TextInput
                                        disabled={metadata.manufacturerId == null}
                                        value={metadata.deviceType ? metadata.deviceType.name : ""}
                                        onChange={evt => this.updateModelTypeValue(evt.target.value)}
                                        onSuggestionSelect={evt => this.updateModelTypeValue(evt.suggestion.option.name)}
                                        dropHeight="medium"
                                        suggestions={this.state.typeSuggestions.sort(this.sortingByName).map(ms => {
                                            return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                        })} 
                                    />
                                    </FormField>
                                </StyledBox>
                            </Box>}

                        {this.state.addType == 2 &&
                            <Box gap="small">
                                <Text>Bulk upload metadata by browsing to a file on your device. A template is provided here if needed.<br />
                                Download the template file, add your metadata information and upload it.</Text>
                                <Box>
                                    <Text size="large">File Upload</Text>
                                    <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                                        {!this.state.file &&
                                            <Dropzone /*accept=".csv"*/ maxFiles={1} onDrop={acceptedFiles => {
                                                console.log(acceptedFiles);
                                                ///reader start
                                                acceptedFiles.forEach((file) => {
                                                    const reader = new FileReader();
                                                    reader.onprogress = (evt) => {
                                                        this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                                                    };
                                                    reader.onabort = () => console.log('file reading was aborted');
                                                    reader.onerror = () => console.log('file reading has failed');
                                                    reader.onload = () => {
                                                        // Do whatever you want with the file contents
                                                        const binaryStr = reader.result;
                                                        console.log(binaryStr);
                                                    }
                                                    reader.readAsArrayBuffer(file);
                                                })
                                                ///reader end


                                                this.setState({ file: acceptedFiles[0] });
                                                if (acceptedFiles[0].type == "application/vnd.ms-excel" || acceptedFiles[0].type == "text/csv") {
                                                    Papa.parse(acceptedFiles[0], {
                                                        delimiter: "",
                                                        header: true,
                                                        skipEmptyLines: "greedy",
                                                        //step: (row, parser) => {
                                                        //    console.log("Row data:", row.data);
                                                        //    console.log("Row errors:", row.errors);
                                                        //    this.setState(prevState => ({
                                                        //        data: [...prevState.data, row.data]
                                                        //    }));
                                                        //},
                                                        complete: (results) => {
                                                            this.setState({ data: this.createData(results.data) });
                                                        }
                                                    });
                                                }
                                            }}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="upload-container">
                                                        <div {...getRootProps()} className="drop-zone">
                                                            <Box align="center" margin={{ top: "10px" }}>
                                                                <Upload />
                                                            </Box>
                                                            <input {...getInputProps()} />
                                                            <p>Drag and drop or browse to your files</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        }
                                        {this.state.file &&
                                            <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                                                {this.state.progressValue == 100 ?
                                                    <DocumentCsv size="medium" />
                                                    :
                                                    <LoadingIndicator />
                                                }
                                                <Text>{this.state.file.name}</Text>
                                                <Box>
                                                    <Meter type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]} />
                                                    <Box justify="end" direction="row">
                                                        {/*<Text>30byte of 60byte</Text>*/}
                                                        <Text size="small">Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%</Text>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {/*<a href='/Images/1.jpg' download>
                            <Box direction="row" gap="small">
                                <Download />
                                Download Template                                   
                            </Box>
                            </a>*/}
                                <Box style={{ maxWidth: "600px" }} direction="row" align="center" gap="small">
                                    <CSVLink data={`Manufacturer,Model,Model Type`} filename="orgmetadata-template-file">
                                        {/*data is changed to simple string instead of astriks separated items*/}
                                        <Button plain icon={<Download color={constants.HPE_GREEN} />} data-for="Download Info" data-tip="Export to CSV" />
                                        {/*Download Template*/}
                                    </CSVLink>
                                    Download Template
                                </Box>
                            {this.state.data.length > 0 && <Box direction="row" justify="end">
                                <ReactTooltip id="all" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            </Box>}
                            {this.state.progressValue == 100 &&
                                <Box>
                                    {this.state.data.length > 0 ?
                                    <Box>
                                        {this.validData.length == 0 ? <Text color="status-critical" weight="bold">Data can not be saved as the file does not contain valid entry data.</Text> : null}
                                        {this.renderTable(this.state.data)}
                                    </Box>
                                    :
                                    <Text color="status-critical" weight="bold">Uploaded file is empty. It does not contain any data.</Text>}
                                </Box>
                            }
                        </Box>}
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    const Id = ownProps.match.params.metaid || '';
    if (state.selectedMetadataReducer.metadata && Id == state.selectedMetadataReducer.metadata.id) {
        start = { ...state.selectedMetadataReducer.metadata };
        //return {
        //    assetdata: state.selectedAssetReducer.assetdata,
        //    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
        //    departmentsdata: state.departmentsReducer.departmentsdata,
        //    categoriesdata: state.categoriesReducer.categoriesdata,
        //    locationsdata: state.locationsReducer.locationsdata,
        //    sitedata: state.selectedSiteReducer.sitedata
        //};
    }
    return {
        assetdata: state.selectedAssetReducer.assetdata,
        assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
        departmentsdata: state.departmentsReducer.departmentsdata,
        categoriesdata: state.categoriesReducer.categoriesdata,
        locationsdata: state.locationsReducer.locationsdata,
        sitedata: state.selectedSiteReducer.sitedata,
        sitesdata: state.sitesReducer.sitesdata,
        modelsdata: state.modelsReducer.modelsdata,
        typesdata: state.typesReducer.typesdata,
        manufacturersdata: state.manufacturersReducer.manufacturersdata,
        metadata: state.selectedMetadataReducer.metadata,
        metadatas: state.metadatasReducer.metadatas
    };
    //else {
    //    return {
    //        categoriesdata: state.categoriesReducer.categoriesdata,
    //        locationsdata: state.locationsReducer.locationsdata,
    //        sitedata: state.selectedSiteReducer.sitedata
    //    };
    //}
};

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetAction, ...AssetLabelsAction, ...DepartmentAction, ...CategoryAction, ...LocationAction, ...SiteAction, ...ModelAction, ...TypeAction, ...ManufacturerAction, ...MetaDataAction }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgMetadataCreateContainer));