import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/beacon`;
const API_NAME = "BeaconApi";
console.log('HOST:', SERVER_URL);

class BeaconApi {
    static async getAllBeacons() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?${API_VERSION}&$count=true&$top=0`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=Map($select=Name;$expand=Group($select=Name))& ${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async getBeacon(beaconId) {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${beaconId}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async saveBeacon(beacondata) {
        if (beacondata.id && beacondata.id != "0") {
            return fetch(`${SERVER_URL}/${beacondata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(beacondata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(beacondata)
            }).then(response => response);
        }
    }

}

//function handleFetchErrors(response) {
//    if (!response.ok) {
//        throw new Error(`${API_NAME}: ${response.statusText}`);
//    }
//    return response;
//}

export default BeaconApi;
