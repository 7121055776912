import { Box, Button, Text, } from 'grommet';
import { Link } from "react-router-dom";
import { StyledBox } from './StyledBox';
import constants from '../../constants';

const Card = ({ header, value, isViewable = false, route }) => {
    return (
        <StyledBox style1 height="200px" width="200px" style={{ position: "relative" }}
        /*margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}*/
        >
            <Box height="15%" margin={{ left: "small" }}>
                <Text>{header}</Text>
            </Box>
            <Box style={{
                height: "100%",
                position: "absolute",
                justifyContent: "center",
                width: "100%",
                alignItems: "center"
            }}>
                <Text size="4xl">
                    {value == "NaN" ? "N/A" : value}
                </Text>
            </Box>
            {isViewable && <Box style={{ height: "100%", justifyContent: "end", alignItems: "center", zIndex: 1 }}>
                <Link to={constants.getOrgLevelRoute() + '/appanalytics/' + route}>
                    <Button label="View" />
                </Link>
            </Box>}
        </StyledBox>
    );
}
export default Card;