import * as ActionType from './ActionType';
import QuickActionApi from '../api/QuickActionApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getQuickActionsResponse = quickactionsdata => ({
    type: ActionType.GET_QUICKACTIONS_RESPONSE,
    quickactionsdata
});

const queuedGetAllQuickActions = queueApiCall(QuickActionApi.getAllQuickActions);

export function getQuickActionsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return QuickActionApi.getAllQuickActions()
        return queuedGetAllQuickActions()
            .then(quickactionsdata => {
                dispatch(getQuickActionsResponse(quickactionsdata));
                return quickactionsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewQuickActionResponse = () => ({
    type: ActionType.ADD_NEW_QUICKACTION_RESPONSE
});

export const updateExistingQuickActionResponse = () => ({
    type: ActionType.UPDATE_EXISTING_QUICKACTION_RESPONSE
});

export function saveQuickActionAction(quickactiondraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the QA is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new QA that is being added, therefore add it
        return QuickActionApi.saveQuickAction(quickactiondraft)
            .then((response) => {
                if (quickactiondraft.id) {
                    dispatch(updateExistingQuickActionResponse());
                } else {
                    dispatch(addNewQuickActionResponse());
                }
                return response;
            }).then((response) => {
                //if (quickactiondraft.id == "0") {
                //    dispatch(getQuickActionAction(quickactiondraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getQuickActionResponse = quickactiondata => ({
    type: ActionType.GET_QUICKACTION_RESPONSE,
    quickactiondata
});

export function getQuickActionAction(quickactionId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return QuickActionApi.getQuickAction(quickactionId)
            .then(quickactiondata => {
                dispatch(getQuickActionResponse(quickactiondata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteQuickActionResponse = () => ({
    type: ActionType.DELETE_QUICKACTION_RESPONSE
});

const queuedDeleteQuickAction = queueApiCall(QuickActionApi.deleteQuickAction);

export function deleteQuickActionAction(quickactionId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return QuickActionApi.deleteQuickAction(quickactionId)
        return queuedDeleteQuickAction(quickactionId)
            .then((response) => {
                dispatch(deleteQuickActionResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getQuickActionsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}