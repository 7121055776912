import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MasterAppAction from '../action/MasterAppAction';
import * as LocationAction from '../action/LocationAction';
import validator from 'validator';
import constants from '../constants';
import { Blank, Add, OrderedList, Android, Apple, Trash, Upload, CircleInformation } from 'grommet-icons';
import { Grid, Box, Heading, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';


var start = undefined;
var base64 = null;
export class MasterAppEditContainer extends React.Component {


    constructor() {
        super();
        this.stamp = Date.now().toString();

        this.state = {
            isSaved: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            validating: false,
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            categoriesdataLoading: true,
            //locationsdataLoading: true,
            masterappdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating fields error handling
    name_err = "";
    andoridURI_err = "";
    androidStore_err = "";
    appleURI_err = "";
    appleStore_err = "";
    status_err = "";
    image_err = "";

    clearErrorMessages() {
        this.name_err = "";
        this.andoridURI_err = "";
        this.androidStore_err = "";
        this.appleURI_err = "";
        this.appleStore_err = "";
        this.status_err = "";
        this.image_err = "";
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === 'string') {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.appId;

        //this.props.action.getLocationsAction()
        //    .then(response => this.setState({ locationsdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*-AppEdit/Parent Error', error);
        //    });
        this.props.action.getMasterAppAction(id)
            .then(response => this.setState({ masterappdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-App Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.masterappdata && (!_.isEqual(start, this.props.masterappdata) || this.state.newimage)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/svg+xml" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/svg+xml" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }

    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                const base64data = btoa(data);
                base64 = base64data;
            };
            reader.readAsBinaryString(f);
        }
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "Too large file: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else { }
    }

    removeIcon() {
        this.props.masterappdata.appIconURL = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }

    updateAppName(v) {
        this.props.masterappdata.name = v.target.value;
        if (v.target.value.length > 250) {
            this.name_err = "Name can't be longer than 250 character";
        }
        else {
            this.name_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateAndroidURI(v) {
        this.props.masterappdata.playStoreUri = v.target.value;
        if (v.target.value.length > 125) {
            this.andoridURI_err = "URI can't be longer than 125 character";
        }
        else {
            this.andoridURI_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateAndroidStore(v) {
        this.props.masterappdata.playStoreUrl = v.target.value;
        if (v.target.value.length > 510) {
            this.androidStore_err = "URL can't be longer than 510 character";
        }
        else {
            this.androidStore_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateAppleURI(v) {
        this.props.masterappdata.appStoreUri = v.target.value;
        if (v.target.value.length > 125) {
            this.appleURI_err = "URI can't be longer than 125 character";
        }
        else {
            this.appleURI_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateAppleStore(v) {
        this.props.masterappdata.appStoreUrl = v.target.value;
        if (v.target.value.length > 510) {
            this.appleStore_err = "URL can't be longer than 510 character";
        }
        else {
            this.appleStore_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateStatus(v) {
        if (v.option == "Pending") {
            this.props.masterappdata.status = false;
        }
        if (v.option == "Available") {
            this.props.masterappdata.status = true;
        }
        this.setState({ validating: !this.state.validating });
    }

    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.masterappdata) || this.state.newimage)) {
            this.setState({ cancel: true });
        }
        else {
            const appId = this.props.masterappdata.id;
            this.props.action.getMasterAppAction(appId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push('/masterapps');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-AppEdit Error', error);
                    this.props.history.push('/masterapps');
                });
        }

    }

    handleSave() {
        const appdefault = this.props.masterappdata;
        if (this.state.newimage) {
            appdefault.iconURL = base64;
            base64 = null;
        }
        //console.log('*DEBUG*-Started saving:', appdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (appdefault.name.trim().length == 0) {
            errorsno++;
            this.name_err = "Name is required";
        }
        if (appdefault.name.length > 250) {
            errorsno++;
            this.name_err = "Name can't be longer than 250 character";
        }

        if (appdefault.playStoreUri.trim().length != 0 && !(constants.REGEX.URI.test(appdefault.playStoreUri))) {
            errorsno++;
            this.andoridURI_err = constants.URI_ERROR;
        }

        if (appdefault.playStoreUri.trim().length == 0) {
            errorsno++;
            this.andoridURI_err = constants.EMPTY_ERROR;
        }

        if (appdefault.playStoreUrl.trim().length != 0 && !(constants.REGEX.URL.test(appdefault.playStoreUrl))) {
            errorsno++;
            this.androidStore_err = constants.URL_ERROR;
        }

        if (appdefault.playStoreUrl.trim().length == 0) {
            errorsno++;
            this.androidStore_err = constants.EMPTY_ERROR;
        }

        if (appdefault.appStoreUri.trim().length != 0 && !(constants.REGEX.URI.test(appdefault.appStoreUri))) {
            errorsno++;
            this.appleURI_err = constants.URI_ERROR;
        }

        if (appdefault.appStoreUri.trim().length == 0) {
            errorsno++;
            this.appleURI_err = constants.EMPTY_ERROR;
        }

        if (appdefault.appStoreUrl.trim().length != 0 && !(constants.REGEX.URL.test(appdefault.appStoreUrl))) {
            errorsno++;
            this.appleStore_err = constants.URL_ERROR;
        }

        if (appdefault.appStoreUrl.trim().length == 0) {
            errorsno++;
            this.appleStore_err = constants.EMPTY_ERROR;
        }

        if (errorsno == 0) {
            //Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Saving:', appdefault);
            this.props.action.saveMasterAppAction(appdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });

                }).catch(error => {
                    console.log('*DEBUG*-Save AppEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        }
        else if (!this.state.isSaveSuccessful) {
        }
        else {
            this.props.history.push('/masterapps');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    render() {
        const { /*locationsdataLoading,*/ masterappdataLoading } = this.state;
        const appdefault = this.props.masterappdata;

        if (/*locationsdataLoading ||*/ masterappdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = appdefault && (appdefault.id != "0") ? 'Edit' : 'Create';

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>Master App - {heading}</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this icon. The icon associated with this app will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <Box>
                        <form>
                            <Box background="transparent" gap="medium">
                                <Box>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                </Box>

                                <StyledBox style1 pad={{ "horizontal": "small" }} width="25%">
                                    <FormField label="Name *" error={this.name_err}>
                                        <TextInput defaultValue={appdefault.name} onChange={v => this.updateAppName(v)} />
                                    </FormField>
                                </StyledBox>

                                <Box>
                                    <Box direction="row" margin={{ "bottom": "xsmall" }}>
                                        <Box margin={{ "right": "xsmall" }}>
                                            <Android size="35px" color="plain" />
                                        </Box>
                                        <Text alignSelf="center" >Android</Text>
                                    </Box>
                                    <Box direction="row" justify="between" gap="large">
                                        <StyledBox style1 pad={{ "horizontal": "small" }} width="25%">
                                            <FormField label="URI *" error={this.andoridURI_err}>
                                                <TextInput defaultValue={appdefault.playStoreUri} onChange={v => this.updateAndroidURI(v)} />
                                            </FormField>
                                        </StyledBox>
                                        <StyledBox style1 pad={{ "horizontal": "small" }} width="70%">
                                            <FormField label="Play Store URL *" error={this.androidStore_err}>
                                                <TextInput defaultValue={appdefault.playStoreUrl} onChange={v => this.updateAndroidStore(v)} />
                                            </FormField>
                                        </StyledBox>
                                    </Box>
                                </Box>

                                <Box>
                                    <Box direction="row" margin={{ "bottom": "xsmall" }}>
                                        <Box margin={{ "right": "xsmall" }}>
                                            <Apple size="35px" color="plain" />
                                        </Box>
                                        <Text alignSelf="center" >Apple</Text>
                                    </Box>
                                    <Box direction="row" justify="between" width="100%">
                                        <StyledBox style1 pad={{ "horizontal": "small" }} width="25%">
                                            <FormField label="URI *" error={this.appleURI_err}>
                                                <TextInput defaultValue={appdefault.appStoreUri} onChange={v => this.updateAppleURI(v)} />
                                            </FormField>
                                        </StyledBox>
                                        <StyledBox style1 pad={{ "horizontal": "small" }} width="70%">
                                            <FormField label="App Store URL *" error={this.appleStore_err}>
                                                <TextInput defaultValue={appdefault.appStoreUrl} onChange={v => this.updateAppleStore(v)} />
                                            </FormField>
                                        </StyledBox>
                                    </Box>
                                </Box>

                                <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "medium" }} width="25%">
                                    <FormField label="Status" error={this.status_err}>
                                        <Select options={["Pending", "Available"]} value={appdefault.status ? "Available" : "Pending"}
                                            onChange={v => this.updateStatus(v)} />
                                    </FormField>
                                </StyledBox>

                                <Box margin={{ "top": "medium" }} gap="small">
                                    <Box direction="row" gap="small">
                                        <Text size="large" weight="bold">App Icon</Text>
                                        <Box data-for="img_info" data-tip>
                                            <CircleInformation />
                                            <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Please upload app icon in SVG format.</span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    <Box direction="row" gap="small">
                                        <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "100px" }} fill={false} margin={{ "bottom": "small" }}>
                                            <FormField label="" error={this.image_err}>
                                                <Box direction="row" justify="center">
                                                    {this.state.newimage ?
                                                        <Box alignSelf="center">
                                                            {this.shortcut()}
                                                        </Box>
                                                        :
                                                        (appdefault.appIconURL == "" || appdefault.appIconURL == null) ?
                                                            <Box height="75px" justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/svg+xml" onChange={this.fileChangedHandler} />
                                                                <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                            </Box>
                                                            :
                                                            <Box justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/svg+xml" onChange={this.fileChangedHandler} />
                                                                <Image height="75" src={`${appdefault.appIconURL}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                    onClick={e => {
                                                                        const c = document.getElementById("real");
                                                                        c.click();
                                                                    }} />
                                                            </Box>
                                                    }
                                                </Box>
                                            </FormField>
                                        </StyledBox>
                                        <Box justify="center" margin={{ "bottom": "small" }}>
                                            <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={(appdefault.appIconURL == "" || appdefault.appIconURL == null) && !this.state.newimage}>
                                                <Trash />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {/*<Box width="150px" margin={{ "bottom": "medium" }}>*/}
                                    {/*    <input type="file" id="real" hidden="hidden" accept="image/svg+xml" onChange={this.fileChangedHandler} />*/}
                                    {/*    <StyledButton typeAdd label="Choose a file" onClick={e => {*/}
                                    {/*        const c = document.getElementById("real");*/}
                                    {/*        c.click();*/}
                                    {/*    }} />*/}
                                    {/*</Box>*/}
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const appId = ownProps.match.params.appId || '';
    if (state.selectedMasterAppReducer.masterappdata && appId == state.selectedMasterAppReducer.masterappdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedMasterAppReducer.masterappdata));
        return {
            //locationsdata: state.locationsReducer.locationsdata,
            masterappdata: state.selectedMasterAppReducer.masterappdata,
        };
    } else {
        return {

            //locationsdata: state.locationsReducer.locationsdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...MasterAppAction, ...LocationAction }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MasterAppEditContainer));