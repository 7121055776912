import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
//import * as PageAction from '../action/PageAction';
//import * as EventAction from '../action/EventAction';
//import * as CategoryAction from '../action/CategoryAction';
//import * as PlacemarkAction from '../action/PlacemarkAction';
import UserApi from '../api/UserApi';
import NavMenu from './NavMenu';
import NavMenuOrg from './NavMenuOrg';
import NavMenuAcc from './NavMenuAcc';
import { withRouter } from 'react-router-dom';
import constants from '../constants';
import RB from '../components/common/RBAC';
/*import { authContext, getToken, adalGetUser } from '../adal/adalConfig';*/
// eslint-disable-next-line
import { Box, Heading, Grommet, Grid, Text, Button, Image, RoutedAnchor, Anchor, RoutedButton, Select, DropButton } from 'grommet';
import { Blank, Cart, User, MailOption, SettingsOption, Organization, Logout, FormNext, More, Home, CircleQuestion } from 'grommet-icons';
import { grommet } from "grommet/themes";
import { hpe } from 'grommet-theme-hpe';
import { deepMerge } from "grommet/utils";
import PopUp from './common/PopUp';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import UIErrorBoundary, { SimulateError } from './common/UIErrorBoundary';
import { getLogonUserPermissions } from './common/RBAC';
import { Context } from '../components/Context';
import LoadingIndicator from './common/LoadingIndicator';
import { MsalContext } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AnalyticsContainer from '../components/AnalyticsContainer';
import styled, { css } from 'styled-components';

import { withOktaAuth } from '@okta/okta-react';

const locations = [
    { id: "be74a22ba0ff438c86ef8acd4ff2d8c4", label: "HPE Galway" },
    { id: "ffffbe74a22ba0f4ff2d8c4fffffffff", label: "HPE London 1ASQ" }
];
//const organisation= {id: "4c5b5f821c2045d1bf2b2248b3167cd9", label: "Hewlett Packard Enterprise"};

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        size: {
            xxsmall: '48px',
            xsmall: '96px',
            small: '192px',
            medium: '384px',
            large: '768px',
            xlarge: '1152px',
            xxlarge: '1536px',
            full: '100%',
        },
        colors: {
            focus: constants.HPE_GREEN,
        }
    },
    //tab: {
    //    margin: {
    //        horizontal: "5px",
    //    },
    //    pad: {
    //        top: "5px",
    //    },
    //    color: "#000000",
    //    active: {
    //        color: "#000000",
    //    },
    //    border: {
    //        color: "#000000",
    //        active: {
    //            color: constants.HPE_GREEN,
    //        },
    //    },
    //},
    heading: {
        level: {
            2: {
                //"font": {
                //    "weight": 700
                //},
                medium: {
                    "size": "44px",
                    "height": "50px",
                }
            }
        },
        //extend: ({ level }) => {
        //    const margins = {
        //        '2': 0
        //    };
        //    const margin = margins[level];
        //    return {
        //        margin
        //    };
        //}
        extend: ({ level }) =>
            level == 2 &&
            css` 
                margin: 0px
            `
    },
    //grid: {
    //    extend: ({ rows, areas }) =>
    //        areas &&
    //        areas.length == 3 &&
    //        //rows[0] == 'xsmall' &&
    //        //rows[1] == 'auto' &&
    //        css `grid-template-rows: auto auto !important`
    //        //css `color: red`
    //},
    box: {
        extend: ({ gridArea }) =>
            gridArea == 'header' &&
            css`padding: 0px 24px`
            ||
            gridArea == 'main' &&
            css`padding-top: 0px`
    }
});

const customThemeNoFocus = deepMerge(customTheme, {
    global: {
        colors: {
            focus: "transparent",
            background: constants.HEADER_COLOR,
        }
    },
    button: {
        disabled: {
            opacity: "1",
        }
    },
});

const customAnchorTheme = deepMerge(hpe, {
    anchor: {
        textDecoration: 'none',
        hover: {
            textDecoration: 'none',
        },
        extend: `
          &:focus {
            outline: none;
            box-shadow: none;
          }
        `,
    },
});

class SensitiveRoutedAnchor extends Component {
    render() {
        const { match, location, history, path, label, icon, disabled, sub } = this.props;
        let isBold = this.props.checkpath(path, location.pathname) ? "bold" : "normal";
        let isActive = this.props.checkpath(path, location.pathname);
        return (
            <StyledBox direction="row" justify="between">
                <Box width="100%">
                    <Grommet theme={customAnchorTheme}>
                        <Anchor path={path}
                            onClick={() => history.push(path)}
                            label={<Text weight={isBold}>{label}</Text>}
                            icon={icon}
                            disabled={disabled} />
                    </Grommet>
                </Box>
            </StyledBox>
        );
    }
};

class SensitiveAnchor extends Component {
    render() {
        const { location, label, boldpathes, ...rest } = this.props;
        let isBold = boldpathes.some(pe => location.pathname.startsWith(pe)) ? "bold" : "normal";
        return (
            <StyledBox>
                <Grommet theme={customAnchorTheme}>
                    <Anchor
                        label={<Text weight={isBold}>{label}</Text>}
                        {...rest}
                        />
                    </Grommet>
            </StyledBox>
        )
    }
}

const LocationSensitiveRoutedAnchor = withRouter(SensitiveRoutedAnchor);
const LocationSensitiveAnchor = withRouter(SensitiveAnchor);

export class Layout extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.locId = '';
        this.sitesfull = [];
        if (sessionStorage.getItem("locationId")) {
            this.locId = sessionStorage.getItem("locationId");
        }

        this.selected = 3;
        if (constants.PATH_TAB1.some(pe => this.props.history.location.pathname.startsWith(pe))) {
            this.selected = 1;
        }
        if (constants.PATH_TAB2.some(pe => this.props.history.location.pathname.startsWith(pe))) {
            this.selected = 2;
        }
        this.organisationname = '';
        this.nomenu = false;
        this.loaded = false;
        this.state = {
            tab: this.selected,
            locationId: this.locId,
            location: '',
            loaded: false,
            open: false,
            isHPEHeaderOpen: false,
            openManage: false,
            isMeridianEnabled: false,
            sitesdataLoading: true,
            organisationsdataLoading: true,
            usersdataLoading: true,
            userdataLoading: true,
            userdataState: { userRoles: [] },
        };
        sessionStorage.setItem("locationId", this.state.locationId);
    }

    orgIdSession = "";
    orgCodeSession = "";
    locIdSession = "";
    features = [];

    async componentDidMount() {
        window.addEventListener("resize", evt => {
            if (evt.returnValue) {
                this.setState({ updated: true })
            }
        }, false);
        await getLogonUserPermissions();
        const email = this.context.accounts.length != 0 ? this.context.accounts[0].username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "";
        if (/*this.context.accounts[0]*/email) { //This will not be true when no logged in user, Layout's did mount runs when Landing is loaded, long before we have a login
            //if (!sessionStorage.getItem("locationId") && this.state.tab == 1) {
            //    this.props.history.push('/sites');
            //}
            this.props.action.getSitesAction()
                .then(response => this.setState({ sitesdataLoading: false }))
                .catch(error => {
                    console.log('*DEBUG*- Sites List Error', error);
                });
            this.props.action.getOrganisationsAction()
                .then(response => this.setState({ organisationsdataLoading: false }))
                .catch(error => {
                    console.log('*DEBUG*- Organisations List Error', error);
                });
            this.props.action.getUsersAction(true)
                .then(response => this.setState({ usersdataLoading: false }))
                .catch(error => {
                    console.log("*DEBUG*-Users Error", error);
                });
            //this.props.action.getUserAllRolesAction(this.context.accounts[0].username)
            //    .then(response => this.setState({ userdataLoading: false }))
            //    .catch(error => {
            //        console.log("*DEBUG*-Users Error", error);
            //    });
            return UserApi.getUserAllRoles(/*this.context.accounts[0].username*/email)
                .then(userdata => {
                    this.setState({ userdataLoading: false, userdataState: userdata });
                })
                .catch(error => {
                    console.log("*DEBUG*-Users Error", error);
                    throw error;
                });
        }
    }
    callUserdataAPI() {
        const email = this.context.accounts.length != 0 ? this.context.accounts[0].username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "";
        return UserApi.getUserAllRoles(email)
            .then(userdata => {
                this.setState({ userdataLoading: false, userdataState: userdata });
            })
            .catch(error => {
                console.log("*DEBUG*-Users Error", error);
                throw error;
            });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitesdata && !this.state.loaded) {
            this.sitesfull = this.props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem('organisationId')).slice();
            this.sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            if (this.state.locationId) {
                const locat = this.sitesfull.find((o) => o.id == this.state.locationId);
                if (locat) {
                    this.setState({ location: locat.name, isMeridianEnabled: locat.meridianEnabled });
                };
            };
            this.setState({ loaded: true });
        };
        if (sessionStorage.getItem("locationId") && this.props.location.pathname.includes(sessionStorage.getItem("locationId")) && this.props.sitesdata && !this.props.sitesdata.find(s => s.id == sessionStorage.getItem("locationId")) && !this.state.sitesdataLoading) {
            this.setState({ sitesdataLoading: true }, () =>
                this.props.action.getSitesAction()
                    .then(response => this.setState({ sitesdataLoading: false }))
                    .catch(error => {
                        console.log('*DEBUG*- Sites List Error', error);
                    })
            );
        }
    }
    componentWillReceiveProps(props) {
        //console.log("cwrp",props.sitesdata, this.loaded);
        if (props.sitesdata && props.sitesdata.length > 0 && !this.loaded) {
            this.sitesfull = props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem('organisationId')).slice();
            this.sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            if (sessionStorage.getItem("locationId") != "") {
                const locat = this.sitesfull.find((o) => o.id == this.state.locationId);
                if (locat) {
                    this.setState({ location: locat.name/*, isMeridianEnabled: locat.meridianEnabled*/ });
                };
            };
            this.loaded = true;
        }
    }

    setPlacemarkEnable = (enabled) => {
        //console.log("setPlacemarkEnable",enabled);
        this.props.action.getSitesAction()
            .then(p => this.setState({ isMeridianEnabled: enabled }))
            .catch(error => {
                console.log('*DEBUG*- Sites List Error', error);
            });

    }
    updateLayout = () => {
        this.setState({ layoutUpdated: true });
    }

    LeftMenuDisplayText = (sitesfull) => {
        if (sitesfull.length == 0) {
            return "No Sites Configured";
        }
        else if (sitesfull.length == 1) {
            return "Go to Site";
        }
        else if (sitesfull.length > 1) {
            return "Sites";
        }
        else {
            return "ERROR";
        }
    };

    ClickApps = () => {
        this.props.history.push('/masterapps');
        this.setState({ tab: 4 });
    };

    ClickOrganisations = () => {
        this.setState({ tab: 2 });
        this.props.history.push('/orglist');
    };

    ClickLocations = (setIds) => {
        if (setIds) {
            sessionStorage.setItem("organisationId", this.orgIdSession);
            sessionStorage.setItem("organisationCode", this.orgCodeSession);
        }
        this.setState({ tab: 1 });
        this.props.history.push(constants.getOrgLevelRoute() + '/sites');
    };

    ClickDashboard = (setIds) => {
        if (setIds) {
            sessionStorage.setItem("organisationId", this.orgIdSession);
            sessionStorage.setItem("organisationCode", this.orgCodeSession);
            sessionStorage.setItem("locationId", this.locIdSession);
        }
        this.props.history.push(constants.getSiteLevelRoute() + '/home'); //site level
    };

    ClickOrgHome = (setIds) => {
        if (setIds) {
            sessionStorage.setItem("organisationId", this.orgIdSession);
            sessionStorage.setItem("organisationCode", this.orgCodeSession);
        }
        if (this.features.find(f => f.name == "mobilClient").isEnabled) {
            this.props.history.push(constants.getOrgLevelRoute() + '/appanalytics/all');
        }
        else if (this.features.find(f => f.name == "assetTracking").isEnabled) {
            this.props.history.push(`${constants.getOrgLevelRoute()}/orgassets`);
            //this.props.history.push('/orgassets');
        }
        else {
            if (this.orgIdSession)
                this.props.history.push(`${constants.getOrgLevelRoute()}/orgsettings`);
            else
                this.props.history.push(`${constants.getOrgLevelRoute()}/orgsettings`);
        }
    };

    ClickMyAccount = () => {
        //sessionStorage.setItem("locationId", '');
        this.setState({ tab: 3 });
        this.props.history.push('/account');
    };

    ClickManage = () => {
        //sessionStorage.setItem("locationId", '');
        this.setState({ openManage: !this.state.openManage });
    }

    ClickSuperUser = () => {
        //sessionStorage.setItem("locationId", '');
        this.setState({ tab: 3 });
        this.props.history.push('/manageusers');
    }

    ClickHome = (userdata) => {
        if (this.menuDisplay(userdata) == "Organizations") {
            this.ClickOrganisations();
        }
        else if (this.menuDisplay(userdata) == "Sites") {
            this.ClickLocations(true);
        }
        else if (this.menuDisplay(userdata) == "Dashboard") {
            this.ClickDashboard(true);
        }
        else if (this.menuDisplay(userdata) == "OrgHome") {
            this.ClickOrgHome(true);
        }
    }

    //isOrgAdmin(userdata) {
    //    if (userdata != null) {
    //        let roleLevels = userdata.userRoles.map(a => a.role.level);

    //        if (roleLevels.includes("Organization")) {
    //            return true;
    //        }
    //        else {
    //            return false;
    //        }
    //    }
    //    else {
    //        return false;
    //    }
    //}

    isCurrentOrgAdmin(userdata) {
        if (userdata != null) {

            let asd = userdata.userRoles.filter(o => o.organizationId == sessionStorage.getItem("organisationId"));
            let roleLevels = asd.map(a => a.role.level);

            if (roleLevels.includes("Organization")) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    menuDisplay(userdata) {
        const { sitesdata, organisationsdata } = this.props;

        if (userdata != null) {
            const distinct = (value, index, self) => {
                if (value != null) {
                    return self.indexOf(value) === index;
                }
            }

            //let allIds = userdata.userRoles.map(a => a.organizationId);
            //let orgs = allIds.filter(distinct);

            //let orgRoles = userdata.userRoles.filter(a => a.organizationId == sessionStorage.getItem("organisationId"));
            //let allSitesWithRole = orgRoles.map(a => a.locationId);
            //let allRoleLevels = orgRoles.map(a => a.role.level);
            //let nonDeletedSites = this.props.sitesdata.filter(s => s.deleted == false);
            //let sites = allSitesWithRole.filter(distinct);
            //if ((RB.isSuperUser() && this.props.organisationsdata.length > 1) || orgs.length > 1) {
            //    return "Organizations"
            //}
            //else if (sites.length > 1 || (allRoleLevels.includes("Organization") && nonDeletedSites.length > 1) || RB.isSuperUser() && nonDeletedSites.length > 1) {
            //    //has perm for more than 1 site OR orgadmin, and there is more than 1 site at that org OR SuperUser and there is more than 1 site at that org         
            //    return "Sites"
            //}
            //else if (sites.length == 1) {
            //    sessionStorage.setItem("locationId", sites[0]);
            //    return "Dashboard"
            //}
            //else if (allRoleLevels.includes("Organization") && nonDeletedSites.length == 1 || RB.isSuperUser() && nonDeletedSites.length == 1) {
            //    //orgadmin, but there is only 1 site at that org OR SuperUser, but there is only 1 site at that org 
            //    //sessionStorage.setItem("locationId", this.props.sitesdata[0].id);
            //    return "Dashboard"

            //}

            let sitesWithRoles = [];

            sitesdata.forEach((ev) => {
                let orgAdmin = false;
                orgAdmin = userdata.userRoles.find(r => r.roleId == "f028c85c16a148e8820da3537d74e9d9" && r.organizationId == ev.organizationId);
                if (userdata.userRoles.map(r => r.locationId).includes(ev.id) || orgAdmin) {
                    sitesWithRoles.push({
                        ...ev
                    });
                }
            })

            let orgsWithRoles = [];

            organisationsdata.forEach((ev) => {
                if (userdata.userRoles.map(r => r.organizationId).includes(ev.id)) {
                    orgsWithRoles.push({
                        ...ev
                    });
                }
            })

            console.log("US_RL_TST", sitesdata, sitesWithRoles, organisationsdata, orgsWithRoles, userdata);
            if (RB.isSuperUser()) { //Superuser
                if (organisationsdata.length > 1) { //Multiple orgs
                    return "Organizations";
                }
                else if (organisationsdata.length == 1 && sitesdata.length > 1) { //One org, multiple sites
                    this.orgIdSession = organisationsdata[0].id;
                    this.orgCodeSession = organisationsdata[0].code;
                    return "Sites";
                }
                else if (organisationsdata.length == 1 && sitesdata.length == 1) { //One org, one site
                    this.orgIdSession = organisationsdata[0].id;
                    this.orgCodeSession = organisationsdata[0].code;
                    this.locIdSession = sitesdata[0].id;
                    return "Dashboard";
                }
                else if (organisationsdata.length == 1 && sitesdata.length == 0) { //One org, no site site yet
                    this.orgIdSession = organisationsdata[0].id;
                    this.orgCodeSession = organisationsdata[0].code;
                    return "OrgHome";
                }
            }
            else if (!RB.isSuperUser()) {//Not a superuser
                if (orgsWithRoles.length > 1) { //Multiple orgs
                    return "Organizations";
                }
                else if (orgsWithRoles.length == 1 && sitesWithRoles.length > 1) { //One org, multiple sites
                    this.orgIdSession = orgsWithRoles[0].id;
                    this.orgCodeSession = orgsWithRoles[0].code;
                    return "Sites";
                }
                else if (orgsWithRoles.length == 1 && sitesWithRoles.length == 1) { //One org, one site
                    this.orgIdSession = orgsWithRoles[0].id;
                    this.orgCodeSession = orgsWithRoles[0].code;
                    this.locIdSession = sitesWithRoles[0].id;
                    return "Dashboard";
                }
                else if (orgsWithRoles.length == 1 && sitesWithRoles.length == 0) { //One org, no site site yet
                    this.orgIdSession = orgsWithRoles[0].id;
                    this.orgCodeSession = orgsWithRoles[0].code;
                    return "OrgHome";
                }
            }
        }
    }

    renderItems = (userdata, username) => /*adalGetUser() &&*/(
        <Box round="8px" >
            {/*<Text>{adalGetUser() ? adalGetUser().profile.unique_name : ''}</Text>*/}
            {/*
            <Anchor onClick={c => this.ClickOrganisations()} label={<Text weight="normal">Organizations</Text>} />
            <Anchor onClick={this.ClickMyAccount} label={<Text weight="normal">View Profile</Text>} />
            */}
            <Box border="bottom">
                <Box direction="row" pad="small" gap="small">
                    <LocationSensitiveAnchor
                        boldpathes={['/account']} label={username ? username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "Unknown"} onClick={this.ClickMyAccount} />
                </Box>
            </Box>
            <Box border="bottom">
                {/*<Box direction="row" align="center" pad="small" gap="small">*/}
                {/*    <Button icon={<MailOption />} label="Contact Support" plain*/}
                {/*        href="mailto://appservices.support@hpe.com?subject=I%20need%20support%20for...&body=To%20help%20diagnose%20the%20issue%20please%20describe%20the%20issue%20below" />*/}
                {/*</Box>*/}
                {/*Only option for super user*/}
                {RB.isSuperUser() && (
                    <Box direction="column" pad="small">
                        <Box width="95px" margin={{ "right": "105px" }}>
                            <LocationSensitiveAnchor icon={<SettingsOption />}
                                boldpathes={['/masterapps', '/masterapp', '/manageorgs', '/manageorg', '/managesites', '/managesite', '/orgsettings/0', '/manageusers', '/manageuser']}
                                label='Manage' onClick={this.ClickManage} open={this.state.openManage} />
                        </Box>
                        {this.state.openManage && (
                            <Box margin={{ left: "large", top: "xsmall" }}>
                                {/*<LocationSensitiveRoutedAnchor sub={this.state.openManage} path='/orgsettings/0' label='Create Organization'*/}
                                {/*    checkpath={(path, location) => path === location} />*/}
                                <LocationSensitiveRoutedAnchor sub path='/masterapps' label='Apps'
                                    checkpath={(path, location) => path === location || location.startsWith('/masterapp')} />
                                <LocationSensitiveRoutedAnchor sub path='/manageorgs' label='Organizations'
                                    checkpath={(path, location) => path === location || location.startsWith('/manageorg')} />
                                <LocationSensitiveRoutedAnchor sub path='/managesites' label='Sites'
                                    checkpath={(path, location) => path === location || location.startsWith('/managesite')} />
                                <LocationSensitiveRoutedAnchor sub path='/manageusers' label='Users'
                                    checkpath={(path, location) => path === location || location.startsWith('/manageuser')} />
                            </Box>
                        )}
                    </Box>
                )}

                {this.menuDisplay(userdata) == "Organizations" &&
                    <Box pad="small">
                        <LocationSensitiveAnchor icon={<Organization />}
                            boldpathes={['/orglist']} label='Organizations' onClick={c => this.ClickOrganisations()} />
                    </Box>}

                {this.menuDisplay(userdata) == "Sites" &&
                    <Box pad="small">
                        <LocationSensitiveAnchor icon={<Organization />}
                            boldpathes={['/sites']} label='Sites' onClick={c => this.ClickLocations(true)} />
                    </Box>}

                {this.menuDisplay(userdata) == "Dashboard" &&
                    <Box pad="small">
                        <LocationSensitiveAnchor icon={<Organization />}
                            boldpathes={['/home']} label='Dashboard' onClick={c => this.ClickDashboard(true)} />
                    </Box>}

                {this.menuDisplay(userdata) == "OrgHome" &&
                    <Box pad="small">
                        <LocationSensitiveAnchor icon={<Organization />}
                            boldpathes={['/appanalytics']} label='Dashboard' onClick={c => this.ClickOrgHome(true)} />
                    </Box>}
            </Box>

            <Box direction="row" justify="between">
                <Box direction="row" pad="small" gap="small">
                    <Button icon={<Logout />} alignSelf="start" label="Logout" plain onClick={() => {
                        if (username)
                            /*authContext.logOut()*/this.context.instance.logout();
                        else
                            this.props.oktaAuth.signOut('/');
                    }} />
                </Box>
                {!constants.release().Prod &&
                    <Box direction="row" pad="small" gap="small">
                        <Text color="status-critical">{constants.RELEASE_TYPE}</Text>
                    </Box>}
            </Box>
            <Box align="center" pad={{ "bottom": "xsmall" }}>
                <Text size="small">v. {constants.VERSION}</Text>
            </Box>
        </Box>
    );

    renderChild() {
        if (!this.context.accounts[0] && (!this.props.authState || (this.props.authState && !this.props.authState.isAuthenticated))) {
            sessionStorage.setItem("directUrl", this.props.location.pathname);
            this.props.history.push('/');
            return;
        }
        const paths = ["/sites", "/orglist", "/home", "/account", "/nouser"];
        let defaultPage = false;
        if (paths.includes(this.props.location.pathname))
            defaultPage = true;

        if (defaultPage) {
            return this.props.children;
        }
        else if (this.props.location.pathname == "/null") {
            this.props.history.push('/nopermission');
        }
        else if (this.props.location.pathname == "/nopermission") {
            return (<UIErrorBoundary>
                {this.props.children}
            </UIErrorBoundary>);
        }
        else {
            if (!(this.state.organisationsdataLoading || this.state.sitesdataLoading/* || usersdataLoading || userdataLoading*/) || (this.props.sitesdata && /*this.props.sitesdata.length > 0 &&*/ this.props.organisationsdata && this.props.organisationsdata.length)) {
                if (this.props.location.pathname.includes("appanalytics/")) {
                    let org = this.props.organisationsdata.find(o => this.props.location.pathname.includes(o.id));
                    if (org) {
                        constants.setInsightsId(org.appInsightsId);
                        constants.setInsightskey(org.appInsightsKey);
                        //sessionStorage.setItem("organisationId", org.id); //comment it out in order to make direct link work
                        sessionStorage.setItem("organisationCode", org.code);
                    }
                    return (
                        <UIErrorBoundary>
                            <AnalyticsContainer>
                                {this.props.children}
                            </AnalyticsContainer>
                        </UIErrorBoundary>);
                }
                return (<UIErrorBoundary>
                    {this.props.children}
                </UIErrorBoundary>);
            }
            else {
                return (<LoadingIndicator />);
            }
        }
    }

    render() {
        if (this.state.userdataLoading && (this.context.accounts.length != 0 || (this.props.authState && this.props.authState.isAuthenticated))) {
            this.callUserdataAPI();
        }
        const { location, locationId, tab, loaded, sitesdataLoading, organisationsdataLoading, usersdataLoading, userdataLoading } = this.state;
        const { sitesdata, organisationsdata, userdata, usersdata } = this.props;
        //if (sitesdataLoading || organisationsdataLoading || usersdataLoading || userdataLoading) {
        //    return (
        //        <Grommet theme={customTheme} style={{ height: "calc(100vh - 69px)" }}>
        //            <LoadingIndicator />
        //        </Grommet>
        //    );
        //}
        if (sessionStorage.getItem("locationId") == "" && sessionStorage.getItem("historylocationId") && this.props.history.location.pathname != ("/sites")) {
            sessionStorage.setItem("locationId", sessionStorage.getItem("historylocationId"));
            sessionStorage.setItem("historylocationId", "");
        }
        const locationid = sessionStorage.getItem("locationId");
        let locationname = "";
        let enabled = false;
        let orgMeridianEnabled = false;
        let chargifienabled = false;
        const site = sitesdata.find((o) => o.id == locationid);
        if (site) {
            locationname = site.name;
            enabled = /*this.state.isMeridianEnabled ||*/ site.meridianEnabled;
            constants.SET_ISMERIDIANVISIBLE(enabled);

            chargifienabled = site.chargifiEnabled;
            constants.SET_ISCHARGIFIVISIBLE(chargifienabled);
        }
        console.log("compaq", constants.ISMERIDIANVISIBLE, this.state.isMeridianEnabled, this.props);
        console.log("propi", this.props.sitesdata, this.props.organisationsdata);
        const organisationid = sessionStorage.getItem("organisationId");
        let organisationname = "";
        let organisationcode = "";
        const org = organisationsdata.find((o) => o.id == organisationid);
        if (org) {
            organisationname = org.name;
            organisationcode = org.code;
            orgMeridianEnabled = org.meridianEnabled;
        }
        console.log("orgi", org ? org.meridianEnabled : "nono");

        const noleftmenu = this.props.history.location.pathname.startsWith(constants.getOrgLevelRoute() + "/sites")
            || this.props.history.location.pathname.startsWith("/account")
            || this.props.history.location.pathname.startsWith("/noaccess")
            || this.props.history.location.pathname.startsWith("/nouser")
            || this.props.history.location.pathname.startsWith("/nopermission")
            || this.props.history.location.pathname.startsWith("/orglist")
            || this.props.history.location.pathname == ("/manageorgs")
            || this.props.history.location.pathname.startsWith("/manageorg/")
            || this.props.history.location.pathname == ("/managesites")
            || this.props.history.location.pathname.startsWith("/managesite/")
            || this.props.history.location.pathname == (constants.getOrgLevelRoute() + "/orgsettings/0")
            || this.props.history.location.pathname == ("/masterapps")
            || this.props.history.location.pathname.startsWith("/masterapp")
            || this.props.history.location.pathname.startsWith("/manageuser")
        //    || this.props.history.location.pathname == ("/home")
        //    || this.props.history.location.pathname == ("/meridianapp")
        //    || (tab == 1 && !locationid)

        const superUserLevel =
            this.props.history.location.pathname == ("/manageorgs")
            || this.props.history.location.pathname.startsWith("/manageorg/")
            || this.props.history.location.pathname == ("/managesites")
            || this.props.history.location.pathname.startsWith("/managesite/")
            || this.props.history.location.pathname == ("/orglist")
            || this.props.history.location.pathname == ("/account")
            || this.props.history.location.pathname.startsWith("/masterapp")
            || this.props.history.location.pathname.startsWith("/manageuser")

        //const ispagelanding = this.props.history.location.pathname.startsWith("/landing")
        const ispagelanding = this.props.history.location.pathname == "/"
        if (this.props.history.location.pathname === constants.getOrgLevelRoute() + "/orgsettings/0" || this.props.history.location.pathname === "/orglist") {
            this.nomenu = true;
        }
        else {
            this.nomenu = false;
        }

        Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el, nro) => {
            if ((this.props.history.location.pathname == ("/appanalytics") && nro < Array.from(document.getElementsByClassName("jvectormap-tip")).length - 1) || this.props.history.location.pathname != ("/appanalytics")) {
                el.style.display = "none";
                el.remove();
            }
        });

        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer').offsetHeight;
        var newWindowHeight = (window.innerHeight - headerHeight - footerHeight) + "px";

        if (!ispagelanding) {
            //if (document.getElementById('hpe_slim_header')) {
            //    document.getElementById('hpe_slim_header').remove();
            //}

            //if (document.getElementById('hpe_slim_footer')) {
            //    document.getElementById('hpe_slim_footer').remove();
            //}

            if (document.getElementById('body2')) { //remove HPE footer's and header's height from body
                //document.getElementById('body2').style.height = 'calc(100vh - 66px - 47px)'; old way, newWindowHeight should work fine, with no baked in heights
                document.getElementById('body2').style.height = newWindowHeight;
            }
            if (document.getElementById('gbody')) { //remove HPE footer's and header's height from grommet component
                document.getElementById('gbody').style.height = newWindowHeight;
            }
        }
        if (ispagelanding) {
            if (document.getElementById('body2')) { //remove HPE header's and footer's height
                document.getElementById('body2').style.height = newWindowHeight;
            }
        }

        // const sitesfull = sitesdata.filter(s => s.organizationId == constants.organisation.id && RB.canAccessLocation(s.id)).slice();
        const sitesfull = sitesdata.filter(s => s.organizationId == sessionStorage.getItem('organisationId') && RB.canAccessLocation(s.id)).slice();
        let reltab = 3;
        if (constants.PATH_TAB1.some(pe => this.props.history.location.pathname.startsWith(pe) || this.props.history.location.pathname.endsWith(pe) || this.props.history.location.pathname.includes(pe))) {
            reltab = 1;
        }
        if (constants.PATH_TAB2.some(pe => this.props.history.location.pathname.startsWith(pe) || this.props.history.location.pathname.endsWith(pe) || this.props.history.location.pathname.includes(pe))) {
            reltab = 2;
        }
        if (reltab == 2) {
            if (sessionStorage.getItem("locationId")) {
                sessionStorage.setItem("historylocationId", sessionStorage.getItem("locationId"));
            }
            sessionStorage.setItem("locationId", '');
        }
        if (constants.PATH_TAB3.some(pe => this.props.history.location.pathname.startsWith(pe))) {
            reltab = 4;
        }
        var adalinfo = this.context.accounts[0];
        let user = "";
        if (adalinfo) {
            //user = adalinfo.profile.given_name + " " + adalinfo.profile.family_name;
            user = this.context.accounts[0].name;
        }
        var grid_WithMenu = [
            { name: "hpeheader", start: [0, 0], end: [1, 0] },
            { name: "header", start: [0, 1], end: [1, 1] },
            { name: "sidebar", start: [0, 2], end: [0, 2] },
            { name: "main", start: [1, 2], end: [1, 2] },
            { name: "footermenu", start: [0, 3], end: [1, 3] },
            //{ name: "logo", start: [0, 1], end: [0, 1] },
        ]

        var grid_WithoutMenu = [
            { name: "hpeheader", start: [0, 0], end: [1, 0] },
            { name: "header", start: [0, 1], end: [1, 1] },
            //{ name: "sidebar", start: [0, 2], end: [0, 2] },
            { name: "main", start: [0, 2], end: [1, 2] },
            { name: "footermenu", start: [0, 3], end: [1, 3] },
            //{ name: "logo", start: [0, 1], end: [0, 1] },
        ]

        let feature = [];
        //SITE FEATURES
        let siteFeatures = [];
        if (site && org) {
            siteFeatures = [
                {
                    name: "trackSiteAssetTracking", //Enable Site Meridian Integration Asset Tracking
                    isEnabled: site.assetTrackingEnabled && org.assetTrackingEnabled,
                    path: []
                },
                {
                    name: "siteAssetTracking", //Meridian Tags (Assets, Trackers)
                    isEnabled: site.assetTrackingEnabled && org.assetTrackingEnabled && site.trackAssetsEnabled,
                    path: ["/:orgid?/:locid?/assets", "/:orgid?/:locid?/asset/:assetid", "/:orgid?/:locid?/collections", "/:orgid?/:locid?/collection/:collectionid", "/:orgid?/:locid?/departments", "/department/:departmentid", "/:orgid?/:locid?/tags", "/:orgid?/:locid?/tag/:tagid", "/:orgid?/:locid?/trackers", "/:orgid?/:locid?/tracker/:trackerid"]
                },
                {
                    name: "siteAssetTrackingHistory", //Meridian Tags (Asset History)
                    isEnabled: site.assetTrackingHistoryEnabled && org.assetTrackingHistoryEnabled,
                    path: []
                },
                {
                    name: "sitePersonTracking", //Meridian Tags (People)
                    isEnabled: site.personTrackingEnabled && org.personTrackingEnabled,
                    path: []
                },
                {
                    name: "siteNotification", //Push Notifications
                    isEnabled: site.notificationsEnabled && org.notificationsEnabled,
                    path: []
                },
                {
                    name: "siteVisitorNotification", //Visitor Notifications
                    isEnabled: site.meetAndGreetEnabled && org.meetAndGreetEnabled,
                    path: []
                },
                {
                    name: "siteMap", //Meridian Maps (Maps/Placemarks)
                    isEnabled: site.mapsEnabled && org.mapsEnabled,
                    path: ["/meridianmap", "/placemarks", "/placemark/:placemarkId?", "/mapping", "/:orgid?/:locid?/integrations/meridian"]
                },
                {
                    name: "siteWayFinding", //Meridian Bluedot (Beacons/Wayfinding)
                    isEnabled: (site.wayfindingEnabled && org.wayfindingEnabled) || (site.assetTrackingEnabled && site.trackAssetsEnabled),
                    path: ["/:orgid?/:locid?/beacons", "/:orgid?/:locid?/integrations/meridian"]
                },
                {
                    name: "roomBooking", //Book a Space - Rooms
                    isEnabled: site.roomBookingEnabled && org.roomBookingEnabled && org.bookARoomEnabled,
                    path: ["/:orgid?/:locid?/integrations/roombooking", "/mapping"]
                },
                {
                    name: "deskBooking", //Book a Space - Desks
                    isEnabled: site.deskBookingEnabled && org.deskBookingEnabled && org.deskBookingEnabled,
                    path: ["/:orgid?/:locid?/integrations/deskbooking", "/mapping"]
                },
                //{
                //    name: "event", //Events & News
                //    isEnabled: site.eventsEnabled && org.eventsEnabled,
                //    path: ["/eventstable", "/event/:eventId?"]
                //},
                {
                    name: "content", //Content
                    isEnabled: site.contentEnabled && org.contentEnabled,
                    path: ["/:orgid?/:locid?/pages", "/:orgid?/:locid?/pages/:tab?", "/:orgid?/:locid?/page/:pageId?", "/:orgid?/:locid?/app/:appId?", "/:orgid?/:locid?/form/:formId?", "/:orgid?/:locid?/actioncentre", "/:orgid?/:locid?/actioncentre/:id", "/eventstable", "/event/:eventId?"]
                },
                {
                    name: "siteSession", //Session
                    isEnabled: site.sessionsEnabled && org.sessionsEnabled,
                    path: [`/:orgid?/:locid?/sessions`]
                },
                {
                    name: "siteChargifi", //Chargifi
                    isEnabled: site.smartWirelessChargingEnabled && org.smartWirelessChargingEnabled,
                    path: [`/:orgid?/:locid?/integrations/chargifi`, "/:orgid?/:locid?/spots"]
                },
                {
                    name: "siteWellness", //Venue Management (Wellness/Cleaning)

                    isEnabled: site.venueManagementEnabled ? site.venueManagementEnabled && org.venueManagementEnabled : false,
                    path: [`/:orgid?/:locid?/lochealthpass`]
                },
                {
                    name: "siteFindAFriend", //Locate People
                    isEnabled: site.findAFriendEnabled && org.findAFriendEnabled,
                    path: []
                },
                {
                    name: "siteMobilClient", //Mobile Client
                    isEnabled: site.mobileAppEnabled && org.mobileAppEnabled,
                    path: []
                },
                {
                    name: "siteBookADeskIntegration",
                    isEnabled:
                        site.deskBookingEnabled && org.deskBookingEnabled && //Feaute check
                        (site.bookADeskEnabled && org.teemEnabled),  //Integration check teem
                           //|| site.graphEnabled && org.graphEnabled),   //Integration check graph
                    path: ["/:orgid?/:locid?/bookspacemap", "/:orgid?/:locid?/mybookings"]
                },
            ];
        }
        else {
            siteFeatures = [
                {
                    name: "trackSiteAssetTracking", //Enable Site Meridian Integration Asset Tracking
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteAssetTracking", //Meridian Tags (Assets, Trackers)
                    isEnabled: false,
                    path: ["/assets", "/asset/:id", "/collections", "/collection/:id", "/departments", "/department/:id", "/tags", "/tag/:id", "/trackers", "/tracker/:id"]
                },
                {
                    name: "siteAssetTrackingHistory", //Meridian Tags (Asset History)
                    isEnabled: false,
                    path: []
                },
                {
                    name: "sitePersonTracking", //Meridian Tags (People)
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteNotification", //Push Notifications
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteVisitorNotification", //Visitor Notifications
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteMap", //Meridian Maps (Maps/Placemarks)
                    isEnabled: false,
                    path: ["/meridianmap", "/placemarks", "/placemark/:placemarkId?", "/mapping", "/:orgid?/:locid?/integrations/meridian"]
                },
                {
                    name: "siteWayFinding", //Meridian Bluedot (Beacons/Wayfinding)
                    isEnabled: false,
                    path: ["/:orgid?/:locid?/beacons", "/:orgid?/:locid?/integrations/meridian"]
                },
                {
                    name: "roomBooking", //Book a Space - Rooms
                    isEnabled: false,
                    path: ["/:orgid?/:locid?/integrations/roombooking", "/mapping"]
                },
                {
                    name: "deskBooking", //Book a Space - Desks
                    isEnabled: false,
                    path: ["/:orgid?/:locid?/integrations/deskbooking", "/mapping"]
                },
                //{
                //    name: "event", //Events & News
                //    isEnabled: false,
                //    path: ["/eventstable", "/event/:eventId?"]
                //},
                {
                    name: "content", //Content
                    isEnabled: false,
                    path: ["/:orgid?/:locid?/pages", "/:orgid?/:locid?/pages/:tab?", "/:orgid?/:locid?/page/:pageId?", "/:orgid?/:locid?/app/:appId?", "/:orgid?/:locid?/form/:formId?", "/:orgid?/:locid?/actioncentre", "/:orgid?/:locid?/actioncentre/:id", "/eventstable", "/event/:eventId?"]
                },
                {
                    name: "siteSession", //Session
                    isEnabled: false,
                    path: [`/:orgid?/:locid?/sessions`]
                },
                {
                    name: "siteChargifi", //Chargifi
                    isEnabled: false,
                    path: [`/:orgid?/:locid?/integrations/chargifi`, "/:orgid?/:locid?/spots"]
                },
                {
                    name: "siteWellness", //Venue Management (Wellness/Cleaning)
                    isEnabled: false,
                    path: [`/:orgid?/:locid?/lochealthpass`]
                },
                {
                    name: "siteFindAFriend", //Locate People
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteMobilClient", //Mobile Client
                    isEnabled: false,
                    path: []
                },
                {
                    name: "siteBookADeskIntegration",
                    isEnabled: false,
                    path: []
                },
            ];
        }
        ///ORG FEATURES
        if (org) {
            feature = [
                {
                    name: "assetTracking", //Meridian Tags (Assets, Trackers)
                    isEnabled: org.assetTrackingEnabled && org.trackAssetsEnabled,
                    path: ["/:orgid?/orgassets", "/:orgid?/orgasset/:assetid", "/:orgid?/orgcollections", "/:orgid?/orgcollection/:collectionid", "/:orgid?/orgdepartments", "/:orgid?/orgdepartment/:departmentid", "/:orgid?/orgtags", "/orgtags", "/:orgid?/orgtag/:tagid", "/:orgid?/orcreategtag/:tagid", "/:orgid?/orgtrackers", "/:orgid?/orgtracker/:trackerid", "/:orgid?/orglabel/:labelid", "/:orgid?/orgmetadata/:metaid", "/:orgid?/orgcreatemetadata/:metaid"]
                },
                {
                    name: "assetTrackingHistory", //Meridian Tags (Asset History)
                    isEnabled: org.assetTrackingHistoryEnabled,
                    path: []
                },
                {
                    name: "personTracking", //Meridian Tags (People)
                    isEnabled: org.personTrackingEnabled,
                    path: []
                },
                {
                    name: "notification", //Push Notifications
                    isEnabled: org.notificationsEnabled,
                    path: ["/:orgid?/notification", "/:orgid?/notifications/:notificationId?"]
                },
                {
                    name: "visitorNotification", //Visitor Notifications
                    isEnabled: org.meetAndGreetEnabled,
                    path: []
                },
                {
                    name: "map", //Meridian Maps (Maps/Placemarks)  Meridian Bluedot (Beacons/Wayfinding)
                    isEnabled: org.mapsEnabled || org.wayfindingEnabled,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/meridian`]
                },
                {
                    name: "book", //Book a Space - Desks
                    isEnabled: (org.deskBookingEnabled && org.bookADeskEnabled) || (org.roomBookingEnabled && org.bookARoomEnabled),
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/teem`, `${constants.getOrgLevelRoute()}/orgintegrations/microsoft365`]
                },
                {
                    name: "buildingMNGMT",
                    isEnabled: org.venueManagementEnabled,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/buildingmanagement`]
                },
                {
                    name: "contentOrg", //Content
                    isEnabled: org.contentEnabled,
                    path: []
                },
                {
                    name: "chargifi", //Chargifi
                    isEnabled: org.smartWirelessChargingEnabled,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/chargifi`]
                },
                {
                    name: "wellness", //Venue Management (Wellness/Cleaning)
                    isEnabled: org.venueManagementEnabled ? org.venueManagementEnabled : false,
                    path: [constants.getOrgLevelRoute() + "/healthpass", constants.getOrgLevelRoute() + "/orgreport"]
                },
                {
                    name: "findAFriend", //Locate People
                    isEnabled: org.findAFriendEnabled,
                    path: []
                },
                {
                    name: "mobilClient", //Mobile Client
                    isEnabled: org.mobileAppEnabled,
                    path: [`${constants.getOrgLevelRoute()}/appdetails`, "/profiles", `${constants.getOrgLevelRoute()}/appanalytics`]
                },
                {
                    name: "session", //Session
                    isEnabled: org.sessionsEnabled,
                    path: []
                },
            ];
        }
        else {
            feature = [
                {
                    name: "assetTracking", //Meridian Tags (Assets, Trackers)
                    isEnabled: false,
                    path: ["/orgassets", "/orgasset/:id", "/orgcollections", "/orgcollection/:id", "/orgdepartments", "/orgdepartment/:id", "/orgtags", "/orgtag/:id", "/orcreategtag/:id", "/orgtrackers", "/orgtracker/:id", "/orglabel/:id"]
                },
                {
                    name: "assetTrackingHistory", //Meridian Tags (Asset History)
                    isEnabled: false,
                    path: []
                },
                {
                    name: "personTracking", //Meridian Tags (People)
                    isEnabled: false,
                    path: []
                },
                {
                    name: "notification", //Push Notifications
                    isEnabled: false,
                    path: ["/notification", "/notifications"]
                },
                {
                    name: "visitorNotification", //Visitor Notifications
                    isEnabled: false,
                    path: []
                },
                {
                    name: "map", //Meridian Maps (Maps/Placemarks)  Meridian Bluedot (Beacons/Wayfinding)
                    isEnabled: false,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/meridian`]
                },
                {
                    name: "book", //Book a Space - Desks
                    isEnabled: false,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/teem`, `${constants.getOrgLevelRoute()}/orgintegrations/microsoft365`]
                },
                {
                    name: "buildingMNGMT",
                    isEnabled: false,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/buildingmanagement`]
                },
                {
                    name: "contentOrg", //Content
                    isEnabled: false,
                    path: []
                },
                {
                    name: "chargifi", //Chargifi
                    isEnabled: false,
                    path: [`${constants.getOrgLevelRoute()}/orgintegrations/chargifi`]
                },
                {
                    name: "wellness", //Venue Management (Wellness/Cleaning)
                    isEnabled: false,
                    path: [constants.getOrgLevelRoute() + "/healthpass", constants.getOrgLevelRoute() + "/orgreport"]
                },
                {
                    name: "findAFriend", //Locate People
                    isEnabled: false,
                    path: []
                },
                {
                    name: "mobilClient", //Mobile Client
                    isEnabled: false,
                    path: [`${constants.getOrgLevelRoute()}/appdetails`, "/profiles", `${constants.getOrgLevelRoute()}/appanalytics`, `${constants.getOrgLevelRoute()}/appanalytics/all`]
                },
                {
                    name: "session", //Session
                    isEnabled: false,
                    path: []
                },
            ];
        }
        feature = feature.concat(siteFeatures);
        this.features = feature;
        let emailTemplate = "mailto://appservices.support@hpe.com?subject=I%20need%20support%20for%20HPE%20Intelligent%20Spaces%20"/*%20 + organisationname*/ + "&body=To%20help%20diagnose%20the%20issue%20please%20describe%20the%20issue%20below";

        return (
            <Context.Provider value={{ setPlacemarkEnable: this.setPlacemarkEnable, isMeridianEnabled: this.state.isMeridianEnabled || enabled, feature: feature, userdata: this.props.userdata, update: this.updateLayout, layoutUpdated: this.state.layoutUpdated }}>
                <Grommet theme={customTheme} full>
                    <PopUp open={this.state.open} onClose={() => this.setState({ open: false })} text="Please select an organization before selecting a location" header="Warning" />
                    {ispagelanding && (
                        this.props.children
                    )}

                    {!ispagelanding && (
                        <Grid
                            id="gbody"
                            fill
                            rows={[this.state.isHPEHeaderOpen ? "0px" : "0px", ["75px", "80px"], ["50%", "100%"], "0px"]}
                            columns={["auto", "flex"]}
                            areas={noleftmenu ? grid_WithoutMenu : grid_WithMenu}
                        >

                            {/*<Box //custom header*/}
                            {/*    gridArea="hpeheader"*/}
                            {/*    direction="row"*/}
                            {/*    align="center"*/}
                            {/*    justify="between"*/}
                            {/*    pad={{ "horizontal": "medium", "vertical": "small" }}*/}
                            {/*    background={constants.LAYOUT_COLOR}*/}
                            {/*    onClick={() => this.setState({ isHPEHeaderOpen: !this.state.isHPEHeaderOpen })}*/}
                            {/*>*/}
                            {/*    <Box direction="row" alignContent="start" height="100%" align="start">*/}
                            {/*        {this.state.isHPEHeaderOpen ?*/}
                            {/*            <Box pad={{ right: "large" }}>*/}
                            {/*                <Image width="120px" src="./Images/hpe_logo_black.png" />*/}
                            {/*            </Box>*/}
                            {/*            :*/}
                            {/*            <Box pad={{ right: "large" }}>*/}
                            {/*                <Image width="120px" src="./Images/hpe_logo.png" />*/}
                            {/*            </Box>*/}
                            {/*        }*/}

                            {/*    </Box>*/}

                            {/*    {this.state.isHPEHeaderOpen ?*/}
                            {/*        <Box direction="row" align="center">*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Cloud Services" plain href="https://www.hpe.com/us/en/cloud-services.html" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Software" plain href="https://www.hpe.com/us/en/ezmeral.html" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="IT Infrastructure" plain href="https://www.hpe.com/us/en/products.html" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Technology Services" plain href="https://www.hpe.com/us/en/services.html" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Support" plain href="https://support.hpe.com/hpesc/public/home" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Shop" plain href="https://buy.hpe.com/us/en/" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            <Box margin={{ horizontal: "small" }}>*/}
                            {/*                <Button alignSelf="center" label="Contact" plain href="https://www.hpe.com/us/en/contact-hpe.html" target="_blank" />*/}
                            {/*            </Box>*/}
                            {/*            */}{/*<Box margin={{ horizontal: "small" }}>*/}
                            {/*            */}{/*    <Button alignSelf="center" label="Products and Solutions" plain href="https://www.hpe.com/us/en/products.html" target="_blank" />*/}
                            {/*            */}{/*</Box>*/}
                            {/*            */}{/*<Box margin={{ horizontal: "small" }}>*/}
                            {/*            */}{/*    <Button alignSelf="center" label="Services" plain href="https://www.hpe.com/us/en/services.html" target="_blank" />*/}
                            {/*            */}{/*</Box>*/}
                            {/*            */}{/*<Box margin={{ horizontal: "small" }}>*/}
                            {/*            */}{/*    <Button alignSelf="center" label="Support" plain href="https://www.hpe.com/us/en/support.html" target="_blank" />*/}
                            {/*            */}{/*</Box>*/}
                            {/*            */}{/*<Box margin={{ horizontal: "small" }}>*/}
                            {/*            */}{/*    <Button alignSelf="center" label="News and Insights" plain href="https://www.hpe.com/us/en/news-insights-press.html" target="_blank" />*/}
                            {/*            */}{/*</Box>*/}
                            {/*            */}{/*<Box width="15px" align="center" margin={{ "left": "xsmall" }}>*/}
                            {/*            */}{/*    <Button alignSelf="center" fill icon={<Cart color="#333333" size="15px" />} plain href="https://www.hpe.com/us/en/buy-parts-products.html" target="_blank" />*/}
                            {/*            */}{/*</Box>*/}
                            {/*        </Box>*/}
                            {/*        :*/}
                            {/*        <Box>*/}
                            {/*            <More color="black" />*/}
                            {/*        </Box>*/}
                            {/*    }*/}
                            {/*</Box>*/}

                            <Box
                                gridArea="header"
                                direction="row"
                                align="center"
                                justify="between"
                                pad={{ "horizontal": "medium", "vertical": "small" }}
                                background={constants.HEADER_COLOR}
                                border={{ "side": "bottom", "color": "#DADADA", "size": "1px" }}
                            >
                                <Box size="small" direction="row" >
                                    <Box >
                                        <Text color="black" size="44px"><strong>HPE</strong> Intelligent Spaces</Text>
                                    </Box>
                                </Box>

                                <Box>
                                    {!superUserLevel &&
                                        <Grommet theme={customThemeNoFocus}>
                                            <Button disabled={!(RB.isSuperUser() || this.isCurrentOrgAdmin(this.state.userdataState))} plain onClick={() => { this.ClickOrgHome() }}>
                                                <Text size="19px"><strong>{organisationname}</strong></Text>
                                            </Button>
                                        </Grommet>}
                                </Box>

                                <Grommet theme={customThemeNoFocus}>
                                    <Box direction="row" gap="medium" align="center" margin={{ "right": "10px" }}>
                                        {/*{(RB.isSuperUser() || this.isCurrentOrgAdmin(userdata)) &&*/}
                                        {/*    <Grommet theme={customThemeNoFocus}>*/}
                                        {/*        <Button plain onClick={() => {*/}
                                        {/*            if (feature.find(f => f.name == "mobilClient").isEnabled) {*/}
                                        {/*                this.props.history.push('/appanalytics');*/}
                                        {/*            }*/}
                                        {/*            else if (feature.find(f => f.name == "assetTracking").isEnabled) {*/}
                                        {/*                this.props.history.push('/orgassets');*/}
                                        {/*            }*/}
                                        {/*            else {*/}
                                        {/*                if (org) {*/}
                                        {/*                    this.props.history.push(`/orgsettings/${org.id}`);*/}
                                        {/*                }*/}
                                        {/*            }*/}

                                        {/*        }}>*/}
                                        {/*            <Text size="19px"><strong>{organisationname}</strong></Text>*/}
                                        {/*        </Button>*/}
                                        {/*    </Grommet>}*/}
                                        {/*{!(RB.isSuperUser() || this.isCurrentOrgAdmin(userdata)) &&*/}
                                        {/*    <Box>*/}
                                        {/*        <Text size="19px"><strong>{organisationname}</strong></Text>*/}
                                        {/*    </Box>}*/}

                                        {/*{organisationname &&*/}
                                        {/*    <Box border={{ "side": "vertical", "color": "black", "size": "1px" }} />*/}
                                        {/*}*/}

                                        <Box pad={{ "top": "xsmall" }}>
                                            <DropButton plain dropContent={this.renderItems(this.state.userdataState, user)} dropAlign={{ top: "bottom" }} >
                                                <User />
                                                {/*<Text size="19px"><strong>{user}</strong></Text>*/}
                                            </DropButton>
                                        </Box>

                                        <Button icon={<CircleQuestion />} plain
                                            href={emailTemplate} />

                                        <Button icon={<Home />} plain onClick={evt => this.ClickHome(this.state.userdataState)} />
                                    </Box>
                                </Grommet>
                            </Box>

                            {!noleftmenu &&
                                <Box background={constants.LAYOUT_COLOR}
                                    style={{ minWidth: "250px" }}
                                    gridArea="sidebar"
                                    width="small"
                                    flex
                                    fill="horizontal"
                                    overflow="auto"
                                    pad={{ "right": "6px" }}
                                >
                                    <Box background={constants.LAYOUT_COLOR} fill style={{ "box-shadow": "2px 0px 5px 1px rgba(100, 100, 100, 0.35)" }}>
                                        {reltab == 1 && (//Site level
                                            <Box margin={{ "left": "medium", "top": "small", "bottom": "small" }} direction="row" justify="between" style={{ minHeight: "36px" }}>
                                                <Button onClick={c => this.ClickLocations(false)}>
                                                    <Text size="25px" style={{ "word-break": "break-all" }}>
                                                        {locationname}
                                                    </Text>
                                                </Button>
                                                <Button
                                                    margin={{ "left": "small" }}
                                                    style={{ color: "#01a982", fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain
                                                    onClick={c => this.ClickLocations(false)}
                                                    icon={<FormNext color="#01a982" size="30px" />}
                                                    reverse
                                                />
                                            </Box>
                                        )}
                                        {reltab == 2 && !this.nomenu && (//Org level
                                            <Box margin={{ "left": "medium", "top": "small", "bottom": "small" }} direction="row" justify="between" style={{ minHeight: "36px" }}>
                                                <Button onClick={c => sitesfull.length > 0 ? this.ClickLocations(false) : null} style={{ cursor: sitesfull.length == 0? "default" : "pointer"}}>
                                                    <Text size="25px" style={{ "word-break": "break-all" }}>
                                                        {this.LeftMenuDisplayText(sitesfull)}
                                                    </Text>
                                                </Button>
                                                <Button
                                                    margin={{ "left": "small" }}
                                                    style={{ color: "#01a982", fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain
                                                    onClick={c => this.ClickLocations(false)}
                                                    icon={<FormNext color="#01a982" size="30px" />}
                                                    reverse
                                                    disabled={sitesfull.length == 0}
                                                />
                                            </Box>
                                        )}
                                        {reltab == 1 && (
                                            (locationid != '') ? <NavMenu isMeridianEnabled={/*this.state.isMeridianEnabled*/enabled} isChargifiEnabled={chargifienabled} /> : null
                                        )}
                                        {reltab == 2 && !this.nomenu && (<NavMenuOrg isOrgMeridianEnabled={orgMeridianEnabled} />)}
                                        {reltab == 2 && this.nomenu && (<NavMenuAcc />)}
                                        {reltab == 3 && (<NavMenuAcc />)}
                                    </Box>
                                </Box>}
                            <Box id="main" gridArea="main" justify="start" align="start" overflow="auto" fill flex background={constants.BACKGROUND_COLOR} >
                                {this.renderChild()}
                            </Box>
                            {/*<Box*/}
                            {/*    gridArea="footermenu"*/}
                            {/*    direction="row"*/}
                            {/*    align="center"*/}
                            {/*    justify="between"*/}
                            {/*    pad={{ "horizontal": "medium", "vertical": "small" }}*/}
                            {/*    background={constants.LAYOUT_COLOR}*/}
                            {/*    border={{ "side": "top", "color": "#DADADA", "size": "1px" }}*/}
                            {/*>*/}
                            {/*    <Text size="small">&copy; {constants.COPYYEAR} Hewlett Packard Enterprise Development LP</Text>*/}
                            {/*    <Text size="small">Version {constants.VERSION}</Text>*/}
                            {/*    <Text size="small"><Button label="About" plain href="https://www.hpe.com/us/en/about.html" target="_blank" /> | <Button label="Terms" plain href="https://www.hpe.com/us/en/about/legal/terms-of-use.html" target="_blank" /> | <Button label="Privacy" plain href="https://hpe.sharepoint.com/teams/CorporateStdDMT/Approved/HPE002-03.pdf" target="_blank" /></Text>*/}
                            {/*</Box>*/}
                        </Grid>
                    )}
                </Grommet>
            </Context.Provider>
        );
    }

}

const mapStateToProps = state => ({
    sitesdata: state.sitesReducer.sitesdata,
    organisationsdata: state.organisationsReducer.organisationsdata,
    usersdata: state.usersReducer.usersdata,
    userdata: state.selectedUserReducer.userdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction, ...UserAction/*, ...PageAction, ...EventAction, ...PlacemarkAction, ...CategoryAction*/ }, dispatch)

});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Layout)));