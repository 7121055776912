import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import * as BuildingAction from '../action/BuildingAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import * as PolicyAction from '../action/PolicyAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash, Add, Alert } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Tabs, Tab, Layer, DataTable, TextArea } from 'grommet';
import { error } from 'util';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';

import AccessControl from './common/AccessControl';

const PaginatedDataTable = withPagination(DataTable);

var start = undefined;


class Option extends React.Component {
    render() {
        const { value, selected } = this.props;
        return (
            <Box direction="row" gap="small" align="center" pad="xsmall">
                <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
                {value}
            </Box>
        );
    }
}

export class RoleEditContainer extends React.Component {
    constructor(props) {
        super();
        this.dtwrapper = React.createRef();
        this.allRoles = [];
        this.allRolesSites = [];
        this.selectedTypes = [];
        this.state = {
            isSaved: false,
            disabledButton: false,
            open: false,
            openManageRoles: false,
            openManageType: "",
            manageRoleType: "",
            manageRoleSites: [],
            manageRoleRoles: [],
            cancel: false,
            isSaveSuccessful: false,
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],

            //policies: constants.GROUPS.find(g => g.id == props.match.params.roleId).policies,
            role: constants.ROLES.find(g => g.id == /*props.match.params.roleId*/0),
            group: constants.GROUPS.find(g => g.id == 0),
            usersdataLoading: true,
            buildingsdataLoading: true,
            assetlabelsdataLoading: true,
            departmentsdataLoading: true,
            collectionsdataLoading: true,
            policydataLoading: true,
            prevSelectedSite: "",

            selectedUsers: [],
            deleteUserId: [],

            tabIndex: 0
        };
    }

    //Validating errors
    /*group_err = '';*/
    email_err = '';
    username_err = '';
    showActiveMsg = false;
    name_err = '';
    description_err = '';
    permission_err = '';
    tracker_permission_err = '';
    tracker_site_err = '';
    tracker_building_err = '';
    tracker_name_err = '';

    ClearErrorMessages() {
        /*this.group_err = '';*/
        this.email_err = '';
        this.username_err = '';
        this.name_err = '';
        this.description_err = '';
        this.permission_err = '';
        this.tracker_permission_err = '';
        this.tracker_site_err = '';
        this.tracker_building_err = '';
        this.tracker_name_err = '';

        //this.entryerrors.map((item) => {
        //    item.locationId = "";
        //    item.role = "";
        //})
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.roleId;
        this.props.action.getPolicyAction(id)
            .then(response => this.setState({ policydataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-UserEdit Error", error);
            });
        this.props.action.getUsersAction()
            .then(response => this.setState({ usersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
        //this.props.action.getRolesAction()
        //    .then(response => this.setState({ rolesdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log("*DEBUG*-Role Error", error);
        //    });
        //this.props.action.getSitesAction()
        //    .then(response => this.setState({ sitesdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Sites List Error', error);
        //    });
        //this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
        //    .then(response => this.setState({ organisationdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
        //        console.log('*DEBUG*-Organisation Error', error);
        //    });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.policydata && !_.isEqual(start, this.props.policydata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        //const userdefault = this.props.userdata;
        //if (userdefault.id == "0") {
        //    userdefault.active = true;
        //}

        //const { selected, locationroles } = this.state;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (this.props.policydata.name.trim().length == 0) {
            errorsno++;
            this.name_err = "Name is required";
        }
        if (this.props.policydata.name.length > 50) {
            errorsno++;
            this.name_err = "Name can not be longer than 50 cahracter";
        }
        if (this.props.policydata.description.length > 200) {
            errorsno++;
            this.description_err = "Description can not be longer than 200 cahracter";
        }
        if (this.props.policydata.permissions.length > 0 && this.props.policydata.permissions.some(p=> p.level == "")) {
            errorsno++;
            this.permission_err = "Select a permission";
        }
        if (this.props.policydata.assetPermissions.length > 0) {
            this.props.policydata.assetPermissions.forEach(ap => {
                if (ap.foreignId  == "") {
                    errorsno++;
                    this.tracker_name_err = "Select a name";
                }
                if (ap.level == "") {
                    errorsno++;
                    this.tracker_permission_err = "Select a role";
                }
                if (ap.hasOwnProperty("foreignId") && !ap.foreignId) {
                    errorsno++;
                    this.tracker_site_err = "Select a site";
                }
                if (!this.state.buildingOption) {
                    errorsno++;
                    this.tracker_building_err = "Select a building";
                }
            });
        }
        //if (userdefault.username.trim().length == 0) {
        //    errorsno++;
        //    this.username_err = "Username is required";
        //}
        //if (userdefault.email.trim().length == 0) {
        //    errorsno++;
        //    this.email_err = "E-mail is required"
        //}
        //if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
        //    errorsno++;
        //    this.email_err = "E-mail is not correct";
        //}
        if (errorsno == 0) {
            this.props.policydata.assetPermissions.forEach(item => delete item.sn);
            const deepCopy = _.cloneDeep(this.props.policydata);
            this.setState({ disabledButton: true })
            //this.saveRoles();
            //console.log('Save: OK', userdefault);
            /*this.props.policydata.assetPermissions*/ let a = [
                {
                    id: "44dd2f971cb14923ae6839b2cb1df4a1",
                    level: "Admin",
                    //locationdId: "",  //Building, Floor, Label, + coll/dep?
                    type: "Site"
                },
                {
                    id: "5629499534213120",
                    level: "Admin",
                    locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                    type: "Building"
                },
                {
                    id: "5722646637445120",
                    level: "Admin",
                    locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                    type: "Floor"
                },
                {
                    id: "f9ab971f97bd4ed68dd0fb0ba09b3a11",
                    level: "Admin",
                    //locationdId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                    type: "Collection"
                },
                {
                    id: "704c1d5827434710bbf00c15885fddc0",
                    level: "Admin",
                    //locationdId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                    type: "Department"
                },
                {
                    id: "5190299200651264",
                    level: "Admin",
                    locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                    type: "Label"
                },
            ];
            this.props.action.savePolicyAction(/*this.props.policydata*/deepCopy)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false })
                });
            //if (this.props.match.params.roleId != "0" )
            //    constants.SET_ROLE(this.state.role);
            //else
            //    constants.CREATE_ROLE(this.state.role);

            //this.setState({ isSaved: true, isSaveSuccessful: true });
            //this.props.history.push({
            //    pathname: constants.getOrgLevelRoute() + '/users',
            //    state: { index: 1 }
            //});
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        //this.saveRoles();
        if (!this.state.cancel && !_.isEqual(start, this.props.policydata)) {
            this.setState({ cancel: true });
        }
        else {
            //const userId = this.props.userdata.id;
            //this.props.action.getUserAction(userId)
            //    .then(() => {
            //        if (this.nextLoc) {
            //            this.props.history.push(this.nextLoc);
            //        } else {
            //            //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            //            this.props.history.push({
            //                pathname: constants.getOrgLevelRoute() + '/users',
            //                state: { index: 2 }
            //            });
            //        }
            //    }).catch(error => {
            //        console.log('*DEBUG*-UserEdit Error', error);
            //        //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            //        this.props.history.push({
            //            pathname: constants.getOrgLevelRoute() + '/users',
            //            state: { index: 2 }
            //        });
            //    });
            if (this.nextLoc)
                this.props.history.push(this.nextLoc);
            else
                this.props.history.push({
                    pathname: constants.getOrgLevelRoute() + '/users',
                    state: { index: 1 }
                });
        }
    }

    onClose() {
        this.setState({ disabledButton: false, openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }

    onCloseSaving() {
        this.setState({ isSaved: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
           //this.props.history.push(constants.getOrgLevelRoute() + '/users');
            this.props.history.push({
                pathname: constants.getOrgLevelRoute() + '/users',
                state: { index: 1 }
            });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    getOptionsByType = (type, locId = "" ) => {
        const { sitesdata, mergedbuildingsdata, assetlabelsdata, departmentsdata, collectionsdata, mergedlevelsdata } = this.props;
        switch (type) {
            case "Site":
                return sitesdata.map(s => ({id: s.id, foreignId: s.id, name: s.name })).sort(constants.byPropertyCalled());
            case "Building":
                return mergedbuildingsdata.filter(l => l.meridianId /*&& l.locationId == locId*/).map(b => ({ foreignId: b.meridianId, name: b.name, locationId: b.locationId })).sort(constants.byPropertyCalled());
            case "BuildingFloor":
                return mergedbuildingsdata.filter(l => l.meridianId && l.locationId == locId).map(b => ({ id: b.id, name: b.name })).sort(constants.byPropertyCalled());
            case "Collection":
                return collectionsdata.map(b => ({ foreignId: b.id, name: b.name, locationId: b.locationId })).sort(constants.byPropertyCalled())/*.filter(l => l.locationId == locId)*/;
            case "Department":
                return departmentsdata.map(b => ({ foreignId: b.id, name: b.name, locationId: b.locationId })).sort(constants.byPropertyCalled())/*.filter(l => l.locationId == locId)*/;
            case "Label":
                return assetlabelsdata.map(b => ({ foreignId: b.id, name: b.name, locationId: b.locationId })).sort(constants.byPropertyCalled())/*.filter(l => l.locationId == locId)*/;
            case "Floor":
                return mergedlevelsdata.filter(l => l.meridianId).map(b => ({ foreignId: b.meridianId, name: b.name, locationId: b.locationId })).sort(constants.byPropertyCalled()); //[];
            default:
                return [];
        }
    }

    roleEntry = (policies) => {
        function createOptionsByType(type) {
            switch (type) {
                case "Beacons":
                    return ["Read"];
                    break;
                case "Collections":
                    return ["Admin", "Modify","Read"];
                    break;
                case "Departments":
                    return ["Admin", "Modify", "Read"];
                    break;
                case "Tags":
                    return ["Admin", "Modify", "Read"];
                    break;
                case "Trackers":
                    return ["Admin", "Modify" ,"Read"];
                    break;
                //case "User":
                //    return ["Admin","Read"];
                //    break;
                default:
                    return [];
            }
        }
        console.log("gig", policies);
        const groups = policies.reduce((groups, item) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), item]
        }), {});

        const types = ["Beacons", "Collections", "Departments", "Tags", "Trackers", /*"User",*/ ""];
        return (
            <Box gap="small">
                {policies.length == 0 &&
                    <StyledBox style1 pad="large" gap="small" direction="column" align="center">
                        <Alert color="#DADADA" size="100px" />
                        <Box align="center">
                            <Text>No permissions assigned to this role yet.</Text>
                            <Text>Please select the <b>Add New Type</b> button to assign permissions to this role.</Text>
                        </Box>
                    </StyledBox>
                }
                {policies.length != 0 && /*types*/Object.keys(groups).map((p, idx) => {
                    if (groups[p]) {
                        //this.selectedTypes.push(types.indexOf(p));
                                                return (
                            <StyledBox style1 pad={{ "horizontal": "small", bottom: "small" }} gap="small">
                                <Box direction="row" /*gap="small"*/>
                                    <Box /*width="260px"*/>
                                        <FormField label="Type" error={this.email_err}>
                                            <TextInput value={groups[p][0].type + "s"} readOnly />
                                        </FormField>
                                    </Box>
                                    <Box gap="small" /*direction="row"*/ width="100%">
                                        {groups[p].map((e, i) =>
                                            <Box direction="row" justify="between">
                                            <Grid pad={{ "horizontal": "small" }} /*direction="row"*/ gap="small" columns={{count: 1, size: "auto"}}>
                                                <Box /*width="260px"*/>
                                                    <FormField label={i == 0 ? "Permission *" : ""} error={e.level == "" ? this.permission_err : ""} margin="unset">
                                                        {/*<StyledBox style1>
                                                        <Box pad="small">*/}
                                                        <Select
                                                                disabled={e.type == "Site" ? [1]: []}
                                                            placeholder="Select Permission"
                                                            //options={["Admin", /*"Editor",*/ /*"Mobil Client"*/,"Read", "Read Only", "User"]}
                                                                options={createOptionsByType(groups[p][0].type + "s")}
                                                            value={e.level}
                                                                onChange={(evt) => {
                                                                    //this.permission_err = "";
                                                                    let selected = this.props.policydata.permissions.find(p => p.type == e.type);
                                                                    selected.level = evt.target.value;
                                                                    if (e.type == "Tracker" && this.props.policydata.assetPermissions && this.props.policydata.assetPermissions.length > 0) {
                                                                        this.props.policydata.assetPermissions.forEach(itm => { itm.level = evt.target.value; });
                                                                    }

                                                                this.setState(prevState => ({
                                                                    role: {
                                                                        ...prevState.role,
                                                                        //policies: {
                                                                        //    ...prevState.role.policies,
                                                                        //    //[prevState.role.policies[index].access]: e.target.value,
                                                                        //},
                                                                        policies: prevState.role.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { access: evt.target.value}) : obj))
                                                                    }
                                                                }
                                                                ))
                                                            }}
                                                        />
                                                        {/*</Box>
                                                    </StyledBox>*/}
                                                    </FormField>
                                                </Box>
                                                    {e.type == "Trackers" && <Box>
                                                        <Button label="Go to Trackers" onClick={() => this.setState({ tabIndex: 1 })} />
                                                    </Box>}
                                                {false && e.type != "Site" && <Box /*width="260px"*/ /*style={{ visibility: e.type !="Site" ? "visible" : "hidden"}}*/>
                                                    <FormField label={i == 0 ? "Site *" : ""} error={this.email_err} margin="unset">
                                                        <Select
                                                            placeholder={"Select Site"}
                                                            options={e.type == "Floor" ? this.getOptionsByType("Site") : [{ id: 0, name: "All" }].concat(this.getOptionsByType("Site"))}
                                                            defaultValue={e}
                                                            labelKey="name"
                                                            valueKey="name"
                                                                onChange={(evt) => {
                                                                    console.log("drill",evt);
                                                                    this.setState(prevState => ({
                                                                        role: {
                                                                            ...prevState.role,
                                                                            //policies: {
                                                                            //    ...prevState.role.policies,
                                                                            //    //[prevState.role.policies[index].access]: e.target.value,
                                                                            //},
                                                                            policies: prevState.role.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { name: evt.value.name }) : obj))
                                                                        }
                                                                    }
                                                                    ), () => console.log("drill1", this.state.role))
                                                                }}
                                                        //disabled={!e.type}
                                                        />
                                                    </FormField>
                                                </Box>}
                                                {false && <Box /*width="260px"*/>
                                                    <FormField label={i == 0 ? "Name *" : ""} error={this.email_err} margin="unset">
                                                        <Select
                                                            placeholder={"Select " + e.type}
                                                            options={this.getOptionsByType(e.type)}
                                                            //value={e.name}
                                                            labelKey="name"
                                                            disabled={!e.type}
                                                        />
                                                    </FormField>
                                                </Box>}
                                                </Grid>
                                                <Box width="31px" align="center" alignContent="center" alignSelf="center" margin={i == 0 ? { top: "32px" } : "unset"}>
                                                    <Button icon={<Trash />}
                                                        onClick={() => {
                                                            this.props.policydata.permissions = this.props.policydata.permissions.filter(p=> p.type != e.type);
                                                            this.setState(prevState => ({
                                                                role: {
                                                                    ...prevState.role,
                                                                    policies: prevState.role.policies.filter(po => po.type != (e.type + "s") )
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </Box>

                                            </Box>
                                        )}

                                    </Box>
                                </Box>
                                {false && <Box direction="row" gap="small">
                                    <StyledButton
                                        disabled={groups[p][0].type == ""}
                                        typeAdd
                                        label={"Add "/* + groups[p][0].type*/}
                                        alignSelf="start"
                                        onClick={() => this.setState(prevState => ({
                                            role: {
                                                ...prevState.role,
                                                policies: [...prevState.role.policies, {
                                                    id: prevState.role.policies.length,
                                                    name: "",
                                                    access: "",
                                                    type: groups[p][0].type
                                                }]
                                            }
                                        })
                                        )} />
                                    <StyledButton typeCancel label="Delete all"
                                        onClick={() => this.setState(prevState => ({
                                            role: {
                                                ...prevState.role,
                                                policies: prevState.role.policies.filter(po => po.type != groups[p][0].type)
                                            }
                                        }))} />
                                </Box>}
                            </StyledBox>
                        );
                    }
                })
                }
            </Box>
        );
    }

    renderOption(e) {
        if (this.state.buildingOption)
            return this.state.buildingOption;
        else if (e.foreignId) {
            let floor = this.props.mergedlevelsdata.find(l => l.meridianId == e.foreignId);
            if (floor && floor.buildingId && floor.buildingName)
                return { id: floor.buildingId, name: floor.buildingName };

        }
        return {};
    }

    renderRoleOptions() {
        if (this.props.policydata) {
            if (this.props.policydata.permissions && this.props.policydata.permissions.length > 0) {
                let tracker = this.props.policydata.permissions.find(p=> p.type == "Tracker");
                if (tracker) {
                    if (tracker.level == "Admin")
                        return ["Admin"];
                    if (tracker.level == "Modify")
                        return ["Modify"];
                    if (tracker.level == "Read")
                        return ["Admin", "Modify", "Read"];
                }
                return ["Read"];
            }
            return ["Read"];
        }
        return [];
    }

    insertNewElement(type) {
        if (this.props.policydata && this.props.policydata.assetPermissions.length > 0) {
            let lastIndex = this.props.policydata.assetPermissions.map(p => p.type).lastIndexOf(type);
            if (lastIndex > -1) {
                this.props.policydata.assetPermissions.splice(lastIndex + 1, 0, { foreignId: "", type: type, level: "" });
            }
            else
                this.props.policydata.assetPermissions.push({ foreignId: "", type: type, level: "" });
        }
        else {
            this.props.policydata.assetPermissions.push({ foreignId: "", type: type, level: ""});
        }
    }

    getAllMeridianSite() {
        let sites = this.props.sitesdata.filter(s => s.active && s.appID && s.appToken && s.assetTrackingEnabled);
        if (sites.length > 0)
            return sites.map(s => s.locationId);
        return [];
    }

    permEntry = (policies) => {
        let emptyPolicy = {
            id: policies.length,
            name: "",
            level: "",
            type: ""
        };
        this.selectedTypes = [];
        //const selectedTypes = [...new Set(policies.map(item => item.type))];
        let groups = policies.reduce((groups, item, currentIdx) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), Object.assign( item, { sn: currentIdx + 1})]
        }), {});
        console.log("gg", groups, policies, Object.keys(groups));
        const types = ["Site", "Building", "Floor", "Collection", "Department", "Label", ""];
        if (this.state.buildingOption === null) {
            this.tracker_building_err = "Select a building";
        }
        return (
            <Box gap="small">
                {policies.length == 0 &&
                    <StyledBox style1 pad="large" gap="small" direction="column" align="center">
                        <Alert color="#DADADA" size="100px" />
                        <Box align="center">
                            <Text>No permissions assigned to this role yet.</Text>
                            <Text>Please select the <b>Add New Type</b> button to assign permissions to this role.</Text>
                        </Box>
                    </StyledBox>
                }
                {policies.length != 0 && /*types*/Object.keys(groups).map((p, idx) => {
                    if (groups[p]) {
                        this.selectedTypes.push(types.indexOf(p));
                        return (
                            <StyledBox style1 pad={{ "horizontal": "small", bottom: "small" }} gap="small">
                                <Box direction="row" /*gap="small"*/>
                                    <Box /*width="260px"*/>
                                        <FormField label="Type" /*error={this.email_err}*/>
                                            <TextInput value={groups[p][0].type} readOnly />
                                        </FormField>
                                    </Box>
                                    <Box gap="small" /*direction="row"*/ width="100%">
                                        {groups[p].map((e, i) =>
                                            <Box direction="row" justify="between">
                                                <Grid pad={{ "horizontal": "small" }} /*direction="row"*/ gap="small" columns={{ count: 4, size: "auto" }}>
                                                    <Box /*width="260px"*/>
                                                        <FormField label={i == 0 ? "Roles *" : ""} error={!e.level? this.tracker_permission_err: ""} margin="unset">
                                                            {/*<StyledBox style1>
                                                        <Box pad="small">*/}
                                                            <Select
                                                                //disabled={e.type == "Site" ? [1] : []} //what do we want to make disabled?
                                                                placeholder="Select Permission"
                                                                //options={["Admin", "Modify"/*"Editor"*/, /*"Mobil Client"*/, "Read", /*"User"*/]/*.concat(constants.ROLES.filter(r => r.isDefault == false && r.id != 0).map(n => n.name))*/}
                                                                options={this.renderRoleOptions()}
                                                                value={e.level}
                                                                onChange={(evt) => {
                                                                    e.level = evt.option;
                                                                    this.setState(prevState => ({
                                                                        group: {
                                                                            ...prevState.group,
                                                                            //policies: {
                                                                            //    ...prevState.group.policies,
                                                                            //    //[prevState.group.policies[index].level]: e.target.value,
                                                                            //},
                                                                            policies: prevState.group.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { level: evt.target.value }) : obj))
                                                                        }
                                                                    }
                                                                    ))
                                                                }}
                                                            />
                                                            {/*</Box>
                                                    </StyledBox>*/}
                                                        </FormField>
                                                    </Box>
                                                    {e.type != "Site" && <Box /*width="260px"*/ /*style={{ visibility: e.type !="Site" ? "visible" : "hidden"}}*/>
                                                        <FormField label={i == 0 ? "Site *" : ""} error={!e.locationId ? this.tracker_site_err : ""} margin="unset">
                                                            <Select
                                                                placeholder={"Select Site"}
                                                                disabled={!e.type || !e.level}
                                                                options={(e.type == "Floor" || e.type == "Building" || e.type == "Label" ) ? this.getOptionsByType("Site").map(s => ({ locationId: s.id, name: s.name })) : [{ locationId: "0", name: "All" }].concat(this.getOptionsByType("Site").map(s => ({ locationId: s.id, name: s.name })))}
                                                                //options={[{ locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5", name: "GT" }] }
                                                                //value={e.locationId}
                                                                defaultValue={e.locationId ? e : { locationId: "0", name: "All" }}
                                                                labelKey="name"
                                                                valueKey="locationId"
                                                                onChange={evt => {
                                                                    if (evt.option.name != "All")
                                                                        e.locationId = evt.option.locationId;
                                                                    if (e.type == "Building" || e.type == "Floor" || e.type == "Label")
                                                                        e.foreignId = "";
                                                                    this.setState({
                                                                        validating: true,
                                                                        buildingOption: this.state.prevSelectedSite != evt.option.locationId ? null : this.state.buildingOption,
                                                                        prevSelectedSite: evt.option.locationId
                                                                    });
                                                                }}
                                                            //disabled={!e.type}
                                                            />
                                                        </FormField>
                                                    </Box>}
                                                    {e.type == "Floor" && <Box /*width="260px"*/ /*style={{ visibility: e.type == "Floor" ? "visible" : "hidden" }}*/>
                                                        <FormField label={i == 0 ? "Building *" : ""} error={this.tracker_building_err} margin="unset">
                                                            <Select
                                                                placeholder={"Select Building"}
                                                                disabled={!e.level || !e.locationId}
                                                                options={this.getOptionsByType("BuildingFloor", e.locationId)}
                                                                //value={e.name}
                                                                labelKey="name"
                                                                valueKey="id"
                                                                value={this.renderOption(e)}
                                                                //value={e.id ? this.props.mergedlevelsdata.find(l => l.meridianId == e.id).buildingName : {id: "02", name: "na"}}
                                                                onChange={evt => {
                                                                    this.tracker_building_err = "";
                                                                    this.setState({ buildingOption: evt.option });
                                                                }}
                                                            //disabled={!e.type}
                                                            />
                                                        </FormField>
                                                    </Box>}
                                                    <Box /*width="260px"*/>
                                                        <FormField label={i == 0 ? "Name *" : ""} error={!e.foreignId  ? this.tracker_name_err : ""} margin="unset">
                                                            <Select
                                                                placeholder={"Select " + e.type}
                                                                //options={e.type == "Site" ? [{ id: 0, name: "All" }].concat(this.getOptionsByType("Site")) : this.getOptionsByType(e.type)/*.map(t=> t.name)*/}
                                                                options={(e.locationId && e.locationId != "" && e.type != "Collection" && e.type != "Department" && e.type != "Site") ? this.getOptionsByType(e.type).filter(o => o.locationId == e.locationId) : this.getOptionsByType(e.type, /*e.locationId ? e.locationId : */"")}
                                                                //defaultValue={e}
                                                                labelKey="name"
                                                                //valueKey="id"
                                                                valueKey={{ key: 'foreignId', reduce: false }}
                                                                value={e}
                                                                disabled={!e.type || !e.level || (e.type != "Site" && e.type != "Collection" && e.type != "Department" && !e.locationId) || (e.type == "Floor" && (!e.foreignId && !this.state.buildingOption))}
                                                                onChange={(evt) => {
                                                                    console.log("drill", evt, e);
                                                                    e.foreignId = evt.option.foreignId;
                                                                    this.setState(prevState => ({
                                                                        group: {
                                                                            ...prevState.group,
                                                                            policies: prevState.group.policies.map(obj => (obj.id === e.id ? Object.assign(obj, { name: evt.value.name }) : obj))
                                                                        }
                                                                    }
                                                                    ), () => console.log("drill1", this.state.group))
                                                                }}
                                                            />
                                                        </FormField>
                                                    </Box>
                                                </Grid>
                                                <Box width="31px" align="center" alignContent="center" alignSelf="center" margin={i == 0 ? { top: "32px" } : "unset"}>
                                                    <Button icon={<Trash />}
                                                        onClick={() => {
                                                            console.log("della1", policies, e, i, p, idx);
                                                            //groups[p] = groups[p].filter((g,index)=> index != i );
                                                            this.props.policydata.assetPermissions = policies.filter(po => po.sn != e.sn);
                                                            this.setState(prevState => ({
                                                                group: {
                                                                    ...prevState.group,
                                                                    policies: prevState.group.policies.filter(po => po.id != e.id)
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </Box>

                                            </Box>
                                        )}

                                    </Box>
                                </Box>
                                <Box direction="row" gap="small">
                                    <StyledButton
                                        disabled={groups[p][0].type == ""}
                                        typeAdd
                                        label={"Add "/* + groups[p][0].type*/}
                                        alignSelf="start"
                                        onClick={() => {
                                            //let max = policies.reduce((acc, val) => (acc.sn > val.sn ? acc.sn : val.sn), 1);
                                            //console.log("della1", policies, max);
                                            //policies.push({ id: "", type: groups[p][0].type, level: ""/*, sn: max + 1 */});
                                            this.insertNewElement(groups[p][0].type);
                                            this.setState(prevState => ({
                                                group: {
                                                    ...prevState.group,
                                                    policies: [...prevState.group.policies, {
                                                        id: prevState.group.policies.length,
                                                        name: "",
                                                        level: "",
                                                        type: groups[p][0].type
                                                    }]
                                                }
                                            }));
                                        }} />
                                    <StyledButton typeCancel label="Delete all"
                                        onClick={() => {
                                            this.props.policydata.assetPermissions = policies.filter(po => po.type != groups[p][0].type);
                                            this.setState(prevState => ({
                                                group: {
                                                    ...prevState.group,
                                                    policies: prevState.group.policies.filter(po => po.type != groups[p][0].type)
                                                }
                                            }))
                                        }} />
                                </Box>
                            </StyledBox>
                        );
                    }
                })
                }
            </Box>
        );
    }

    onNameChange = (evt) => {
        this.props.policydata.name = evt.target.value;
        if (this.props.policydata.name.length > 50)
            this.name_err = "Name can not be longer than 50 cahracter";
        else
            this.name_err = "";
        this.setState({ validating: true });
    }

    onDescriptionChange = (evt) => {
        this.props.policydata.description = evt.target.value;
        if (this.props.policydata.description.length > 200)
            this.description_err = "Description can not be longer than 200 cahracter";
        else
            this.description_err = "";
        this.setState({ validating: true });
    }

    render() {
        const { options, selected, checked, loaded, userdataLoading, rolesdataLoading, sitesdataLoading, organisationdataLoading, policies, usersdataLoading, role, openUser, group,
            buildingsdataLoading, assetlabelsdataLoading, departmentsdataLoading, collectionsdataLoading, policydataLoading
        } = this.state;
        //const userdefault = this.props.userdata;
        const { usersdata, buildingsdata, assetlabelsdata, departmentsdata, collectionsdata, policydata } = this.props;
        /*const sitesdata = this.props.sitesdata;*/

        if (usersdataLoading /*|| rolesdataLoading || sitesdataLoading || organisationdataLoading*/
            /*|| buildingsdataLoading, assetlabelsdataLoading, departmentsdataLoading, collectionsdataLoading*/ || policydataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = this.props.match.params.roleId != "0" ? 'Edit' : 'Create';
        let selectedTypes = this.props.policydata.permissions.map(p=> (p.type + "s"))//[...new Set(role.policies.map(item => item.type))];
        console.log("gigo", policydata, selectedTypes);
        /*this.props.policydata.assetPermissions*/ let b = [
            {
                id: "44dd2f971cb14923ae6839b2cb1df4a1",
                level: "Admin",
                //locationId: "44dd2f971cb14923ae6839b2cb1df4a1",  //Building, Floor, Label, + coll/dep?
                type: "Site"
            },
            {
                id: "5629499534213120",
                level: "Admin",
                locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                type: "Building"
            },
            {
                id: "5722646637445120",
                level: "Admin",
                locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                type: "Floor"
            },
            {
                id: "f9ab971f97bd4ed68dd0fb0ba09b3a11",
                level: "Admin",
                //locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                type: "Collection"
            },
            {
                id: "704c1d5827434710bbf00c15885fddc0",
                level: "Admin",
                //locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                type: "Department"
            },
            {
                id: "5190299200651264",
                level: "Admin",
                locationId: "be74a22ba0ff438c86ef8acd4ff2d8c5",  //Building, Floor, Label, + coll/dep?
                type: "Label"
            },
        ];

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Role - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium", bottom: "medium" }} fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onCloseSaving()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <PopUpConfirm open={openUser} text={constants.confirmMessage(this.state.deleteUserId.length > 1 ? "users" : "user")} onClose={() => this.setState({ openUser: undefined })}
                        onDelete={() => this.setState(prevState => ({
                            role: {
                                ...prevState.role,
                                //users: prevState.role.users.filter(user => user.name != prevState.deleteUserId)
                                users: prevState.role.users.filter(user => !prevState.deleteUserId.includes(user.name ))
                                },
                            openUser: undefined,
                            deleteUserId: [],
                            selectedUsers: []
                    }))} />
                    <form>
                        <Box gap="medium">
                            <Box gap="small">
                                <Box>
                                    <Text size="large">General</Text>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                </Box>
                                <Box width="310px">
                                    <FormField label="Role Name *" error={this.name_err}>
                                        <TextInput defaultValue={policydata.name}
                                            //onChange={evt => { this.props.policydata.name = evt.target.value; this.setState(prevState => ({ role: { ...prevState.role, name: evt.target.value } })); }}
                                            onChange={this.onNameChange}
                                        />
                                    </FormField>
                                    <FormField label="Description" error={this.description_err}>
                                        <TextArea resize="vertical" defaultValue={policydata.description}
                                            //onChange={evt => { this.props.policydata.description = evt.target.value; this.setState(prevState => ({ role: { ...prevState.role, description: evt.target.value } })) }}
                                            onChange={this.onDescriptionChange}
                                        />
                                    </FormField>
                                </Box>
                                <Box width="60%">
                                    <Tabs justify="start" activeIndex={this.state.tabIndex} onActive={(idx) => this.setState({ tabIndex: idx })}>
                                        <Tab title="Management">
                                            <Box pad={{ "top": "small" }}>
                                                <Box>
                                                    <Text>This allows users to view menu items under "Manage" in the Portal.</Text>
                                                </Box>
                                                <Box margin={{ /*top: "small",*/ vertical: "small" }} direction="row" justify="end">
                                                    {selectedTypes.length != 5 && <Box /*border={{ side: "all", color: constants.HPE_GREEN, size: "small" }} style={{ "border-radius": "5px 5px 5px 5px" }}*/>
                                                        <Select
                                                            plain
                                                            options={["Beacons", "Collections", "Departments", "Tags", "Trackers"/*, "User"*/]}
                                                            disabled={selectedTypes}
                                                            value=""
                                                            onChange={evt => {
                                                                console.log("gigi", evt.option, role.policies);
                                                                policydata.permissions.unshift({ roleId: 0, type: evt.option.slice(0, -1), level: ""});
                                                                this.setState(prevState => ({
                                                                    role: {
                                                                        ...prevState.role,
                                                                        //policies: [{
                                                                        //    id: prevState.role.policies.length,
                                                                        //    name: "",
                                                                        //    access: "",
                                                                        //    type: evt.option
                                                                        //}].concat(prevState.role.policies)
                                                                        policies: [{
                                                                            id: prevState.role.policies.length,
                                                                            name: "",
                                                                            access: "",
                                                                            type: evt.option
                                                                        }, ...prevState.role.policies]
                                                                    }
                                                                })
                                                                );
                                                            }}
                                                            valueLabel={<Button primary label="Add New Type" />}
                                                            icon={null}
                                                        />
                                                    </Box>}
                                                </Box>
                                                <Box gap="small" /*pad={{ vertical: "small" }}*/>
                                                    {this.roleEntry(policydata.permissions)}
                                                </Box>
                                            </Box>
                                        </Tab>
                                        {/*role.policies && role.policies.find(p=> p.type == "Trackers")*/
                                            true && <Tab title="Permissions">
                                            <Box pad={{ "top": "small" }}>
                                                <Box>
                                                    <Text>This allows users to view placemarks and/or trackers based on defined attributes.</Text>
                                                </Box>
                                                <Box margin={{ /*top: "small",*/ vertical: "small" }} direction="row" justify="end">
                                                    {/*selectedTypes.length != 6 &&*/true &&  <Box /*border={{ side: "all", color: constants.HPE_GREEN, size: "small" }} style={{ "border-radius": "5px 5px 5px 5px" }}*/>
                                                        <Select
                                                            plain
                                                            options={["Site", "Building", "Floor", "Collection", "Department", "Label"]}
                                                            //disabled={selectedTypes} DO WE NEED DISABLED FIELDS HERE???
                                                            value=""
                                                            onChange={evt => {
                                                                //let max = this.props.policydata.assetPermissions.reduce((acc, val) => (acc.sn > val.sn ? acc.sn : val.sn), 1);
                                                                //this.props.policydata.assetPermissions.push({ type: evt.option, level: "", id: ""/*, sn: max + 1 */});
                                                                this.insertNewElement(evt.option);
                                                                this.setState(prevState => ({
                                                                    group: {
                                                                        ...prevState.group,
                                                                        //policies: [...prevState.group.policies, {
                                                                        //    id: prevState.group.policies.length,
                                                                        //    name: "",
                                                                        //    access: "",
                                                                        //    type: evt.option
                                                                        //}]
                                                                        policies: [{
                                                                            id: prevState.group.policies.length,
                                                                            name: "",
                                                                            access: "",
                                                                            type: evt.option
                                                                        }, ...prevState.group.policies]
                                                                    }
                                                                })
                                                                );
                                                            }
                                                            }
                                                            valueLabel={<Button primary label="Add New Type" />}
                                                            icon={null}
                                                        />
                                                    </Box>}
                                                </Box>
                                                    <Box gap="small" /*pad={{ vertical: "small" }}*/>
                                                        {this.permEntry(/*group.policies*/policydata.assetPermissions)}
                                                </Box>
                                            </Box>
                                        </Tab>}
                                        </Tabs>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const roleId = ownProps.match.params.roleId || '';
    if (/*state.selectedPolicyReducer.policydata && roleId == state.selectedPolicyReducer.policydata.id*/true) {
        if (state.selectedPolicyReducer.policydata && state.selectedPolicyReducer.policydata.assetPermissions) {
            state.selectedPolicyReducer.policydata.assetPermissions.sort(constants.byPropertyCalled("type"))
        }
        start = JSON.parse(JSON.stringify(state.selectedPolicyReducer.policydata));
        return {
            policydata: state.selectedPolicyReducer.policydata,
            //userdata: state.selectedUserReducer.userdata,
            usersdata: state.usersReducer.usersdata,
            //rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            buildingsdata: state.buildingsReducer.buildingsdata,
            assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
            departmentsdata: state.departmentsReducer.departmentsdata,
            collectionsdata: state.collectionsReducer.collectionsdata,
            usersdata: state.usersReducer.usersdata,

            mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
            mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            //sitesdata: state.sitesReducer.sitesdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction, ...OrganisationAction, ...BuildingAction, ...MergedBuildingAction, ...MergedLevelAction, ...AssetLabelsAction, ...DepartmentAction, ...CollectionAction, ...PolicyAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoleEditContainer));