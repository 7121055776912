import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, StatusGoodSmall, CircleInformation, FormClose, Download } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PrimaryFilter from "./common/PrimaryFilter";
import ReactTooltip from "react-tooltip";
import AnalyticsCards from './AnalyticsCards';
import AnalyticsFilter from './AnalyticsFilter';
import { withRouter } from 'react-router-dom';
import AppInsightsApi from '../api/AppInsightsApi';
import LoadingIndicator from './common/LoadingIndicator';
import { CSVLink } from "react-csv";


class AnalyticsContainer extends Component {

    constructor(props) {
        super(props);
        //let { path } = useRouteMatch();
        this.state = {
            search: "",
            isFilterOpen: false,
            category: "",
            status: "",
            CSVData: [],
            CSVheaders: [],
            CSVDataLoading: true
        };
    }

    componentDidMount() {
        AppInsightsApi.getAllData(sessionStorage.getItem("organisationCode"))
            .then(data => {
                var table = [];
                var headers = [];
                data.tables[0].columns.forEach(ev => {
                    headers.push({
                        label: ev.name,
                        key: ev.name
                    })
                });

                data.tables[0].rows.forEach(ev => {
                    var obj = {};
                    ev.forEach((vl, id) => {
                        if (id != 3) obj[headers[id].key] = vl;
                        else {
                            Object.entries(JSON.parse(vl)).forEach(cd => {
                                if (cd[0] != "Properties") obj["customDimensions_" + cd[0]] = cd[1];
                                else obj["customDimensions_" + cd[0]] = cd[1].split(",").join(";").split("\"").join("");
                            })
                        }
                    })
                    table.push(obj);
                });

                var finalHeaders = [];
                if (table.length != 0) {
                    Object.keys(table[0]).forEach(ev => {
                        finalHeaders.push({
                            label: ev,
                            key: ev
                        })
                    })
                }
                this.setState({ CSVData: table.length > 0 ? table : null, CSVheaders: finalHeaders, CSVDataLoading: false });
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getAllData Error', error);
            });
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Analytics</Heading>
                        <Box>
                            {this.state.CSVData &&
                            <CSVLink data={this.state.CSVData} headers={this.state.CSVheaders} filename="analytics-export" onClick={() => { return this.state.CSVDataLoading ? false : true;}}>
                                    <a data-for="Download" data-tip="Export to CSV">
                                        <StyledButton plain icon={<Download color={constants.BUTTON_LABEL_COLOR_3} />} alignSelf="center" disabled={this.state.CSVDataLoading} />
                                    </a>
                                    <ReactTooltip id="Download" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                </CSVLink>}
                            {!this.state.CSVData &&
                                <Box>
                                    <a data-for="Download" data-tip="There is no data to be exported">
                                        <StyledButton plain icon={<Download color={'#808080'} />} alignSelf="center" disabled={this.state.CSVDataLoading} />
                                    </a>
                                    <ReactTooltip id="Download" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                </Box>
                            }
                        </Box>
                    {/*<Box direction="row" gap="small">
                        <Heading level='2' >Analytics</Heading>
                        <CircleInformation style={{ alignSelf: "center" }} data-for="activitylog" data-tip="Review portal activity and see recent actions taken within your site." />
                        <ReactTooltip id="activitylog" place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>*/}
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    <AnalyticsFilter code={sessionStorage.getItem("organisationCode")}>
                        {/*<Outlet />*/}
                        {/*<Switch>
                            <Route path={`${this.props.location.pathname}/cards`}>
                                <AnalyticsCards />
                            </Route>
                            <Route exact path={this.props.location.pathname}>
                                <Link to={`${this.props.location.pathname}/cards`}>Components</Link>
                            </Route>
                        </Switch>*/}
                        {this.props.children}
                    </AnalyticsFilter>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }
}

export default withRouter(AnalyticsContainer);
