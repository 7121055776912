import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
//eslint-disable-next-line
import { Grid, Box, Heading, FormField, DataTable, TextInput, CheckBox, Text, Tab, Tabs } from 'grommet';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withPagination from './common/PaginatedDataTable';
import TextInputWithState from './common/TextInputWithState';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { isNumber } from 'util';
import LoadingIndicator from './common/LoadingIndicator';


const PaginatedDataTable = withPagination(DataTable);

var start = undefined;

export class OrgIntegrationChargifiEditContainer extends React.Component {


    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            index: 0,
            tabindex: 0,
            viewchargificlientsecret: false,
            viewchargifiapikey: false,
            organisationdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Error handling
    //TAB 1
    chargifiManagedServiceProviderId_err = "";
    chargifiOrgId_err = "";
    chargifiApiKey_err = "";
    chargifiSecret_err = "";

    //TAB 2

    eventDelete(event) {
        //const selectedId = this.state.selectedSite;

        //if (selectedId) {
        //    this.setState({ selectedSite: 0, open: undefined });
        //    this.props.action.deleteSiteAction(selectedId)
        //        .catch(error => {
        //            console.log('*DEBUG*- Site List Delete Error', error);
        //        });
        //}
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        const id = this.props.organisationdata.id;
        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Wireless Charging" }
                            });
    }

    handleNew(event) {

    }

    handleEdit(event, nro) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && _.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //TAB 1
        this.chargifiManagedServiceProviderId_err = "";
        this.chargifiOrgId_err = "";
        this.chargifiApiKey_err = "";
        this.chargifiSecret_err = "";

        //TAB 2
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data

        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (organisationdefault.chargifiMspId == null || organisationdefault.chargifiMspId == "") {
            errorsno++;
            errortab = 0;
            this.chargifiManagedServiceProviderId_err = constants.EMPTY_ERROR;
        }
        if (!Number.isInteger(organisationdefault.chargifiMspId) && organisationdefault.chargifiMspId != null && organisationdefault.chargifiMspId != "") {
            errorsno++;
            errortab = 0;
            this.chargifiManagedServiceProviderId_err = constants.NUMBER_ONLY_ERROR;
        }
        if (organisationdefault.chargifiMspId < -2147483648 || organisationdefault.chargifiMspId > 2147483647) {
            errorsno++;
            errortab = 0;
            this.chargifiManagedServiceProviderId_err = constants.INT_ERROR;
        }
        if (organisationdefault.chargifiOrgId == null || organisationdefault.chargifiOrgId == "") {
            errorsno++;
            errortab = 0;
            this.chargifiOrgId_err = constants.EMPTY_ERROR;
        }
        if (!Number.isInteger(organisationdefault.chargifiOrgId) && organisationdefault.chargifiOrgId != null && organisationdefault.chargifiOrgId != "") {
            errorsno++;
            errortab = 0;
            this.chargifiOrgId_err = constants.NUMBER_ONLY_ERROR;
        }
        if (organisationdefault.chargifiOrgId < -2147483648 || organisationdefault.chargifiOrgId > 2147483647) {
            errorsno++;
            errortab = 0;
            this.chargifiOrgId_err = constants.INT_ERROR;
        }
        if (organisationdefault.chargifiApiKey == null || organisationdefault.chargifiApiKey == "") {
            errorsno++;
            errortab = 0;
            this.chargifiApiKey_err = constants.EMPTY_ERROR;
        }
        if (organisationdefault.chargifiApiKey.trim().length > 50) {
            errorsno++;
            errortab = 0;
            this.chargifiApiKey_err = "Api Key can't be longer than 50 character";
        }
        if (organisationdefault.chargifiClientSecret == null || organisationdefault.chargifiClientSecret == "") {
            errorsno++;
            errortab = 0;
            this.chargifiSecret_err = constants.EMPTY_ERROR;
        }
        if (organisationdefault.chargifiClientSecret.trim().length > 100) {
            errorsno++;
            errortab = 0;
            this.chargifiSecret_err = "Client secret can't be longer than 100 character";
        }


        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
            this.props.sitesdata.map((location) => {
                if (location.organizationId == sessionStorage.getItem("organisationId")) {
                    this.props.action.saveSiteAction(location)
                        .then(() => {
                        }).catch(error => {
                            console.log('*DEBUG*-Save Sites Error', error);
                            this.setState({ disabledButton: false });
                        });
                }

            });
        } else {
            this.onActive(errortab);
            this.setState({});
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Wireless Charging" }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Wireless Charging" }
                            });
                });
        }
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }

    DisableChargifi(location) {
        if (location.chargifiEnabled) {
            location.chargifiEnabled = false;
        }
    }

    //Handling data
    updateChargifi = () => {
        this.props.organisationdata.chargifiEnabled = !this.props.organisationdata.chargifiEnabled;
        if (!this.props.organisationdata.chargifiEnabled) {
            this.props.sitesdata.map((location, i) => {
                this.DisableChargifi(location);
            });
        }
        this.setState({ update: !this.state.update });
    }
    updateManagedServiceProviderId = (e) => {
        if (e.target.value == "") {
            this.props.organisationdata.chargifiMspId = null;
        } else {
            this.props.organisationdata.chargifiMspId = Number.isInteger(+e.target.value) ? +e.target.value : e.target.value;
        }
    }
    updateChargifiOrgId = (e) => {
        if (e.target.value == "") {
            this.props.organisationdata.chargifiOrgId = null;
        } else {
            this.props.organisationdata.chargifiOrgId = isNumber(+e.target.value) ? +e.target.value : e.target.value;
        }
    }
    updateChargifiApiKey = (e) => {
        this.props.organisationdata.chargifiApiKey = e.target.value;
    }
    updateChargifiClientSecret = (e) => {
        this.props.organisationdata.chargifiClientSecret = e.target.value;
    }

    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const sitesdefault = this.props.sitesdata;
        const { organisationdataLoading } = this.state;

        if (organisationdataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Chargifi</Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" height="1200px">
                    <PopUp
                        header=""
                        open={this.state.isSaved}
                        text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                        onClose={() => this.onClose()}
                        createOrg={false}
                        orgName={organisationdefault.name}
                        error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="Configure">
                                <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Box width="75px">
                                        <FormField label="Active">
                                            <Box pad="small">
                                                <CheckBox checked={organisationdefault.chargifiEnabled} onChange={() => this.updateChargifi()} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    <FormField label='Managed service provider ID *' error={this.chargifiManagedServiceProviderId_err}>
                                        <TextInput defaultValue={organisationdefault.chargifiMspId} onChange={this.updateManagedServiceProviderId} />
                                    </FormField>
                                    <FormField label='Org ID *' error={this.chargifiOrgId_err}>
                                        <TextInput defaultValue={organisationdefault.chargifiOrgId} onChange={this.updateChargifiOrgId} />
                                    </FormField>
                                    <FormField label='API Key *' error={this.chargifiApiKey_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateChargifiApiKey} value={this.state.viewchargifiapikey ? organisationdefault.chargifiApiKey : organisationdefault.chargifiApiKey != "" ? "********" : ""} disabled={!this.state.viewchargifiapikey} plain type={this.state.viewchargifiapikey ? "text" : "password"} />
                                            <CheckBox label="View" checked={this.state.viewchargifiapikey} onChange={() => this.setState({ viewchargifiapikey: !this.state.viewchargifiapikey })} />
                                        </Box>
                                    </FormField>
                                    <FormField label='Client Secret *' error={this.chargifiSecret_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateChargifiClientSecret} value={this.state.viewchargificlientsecret ? organisationdefault.chargifiClientSecret : organisationdefault.chargifiClientSecret != "" ? "********" : ""} disabled={!this.state.viewchargificlientsecret} plain type={this.state.viewchargificlientsecret ? "text" : "password"} />
                                            <CheckBox label="View" checked={this.state.viewchargificlientsecret} onChange={() => this.setState({ viewchargificlientsecret: !this.state.viewchargificlientsecret })} />
                                        </Box>
                                    </FormField>
                                </StyledBox>
                            </Tab>
                            <Tab title="Documentation">
                                <StyledBox pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Text>
                                        Nothing to see here yet, Documentation coming soon
                                    </Text>
                                </StyledBox>
                            </Tab>
                        </Tabs>
                    </form>
                </Box>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || '';
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgIntegrationChargifiEditContainer));
