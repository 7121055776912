import * as ActionType from './ActionType';
import PlacemarkHistoryApi from '../api/PlacemarkHistoryApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getPagesResponse = placemarkhistoriesdata => ({
    type: ActionType.GET_PLACEMARKS_HISTORY_RESPONSE,
    placemarkhistoriesdata
});

const queuedGetAllPages = queueApiCall(PlacemarkHistoryApi.getAllPlacemarkHistories);

export function getPlacemarkHistoriesAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());
        return queuedGetAllPages()
            .then(pagesdata => {
                dispatch(getPagesResponse(pagesdata));
                return pagesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const getPageResponse = placemarkhistorydata => ({
    type: ActionType.GET_PLACEMARK_HISTORY_RESPONSE,
    placemarkhistorydata
});

export function getPlacemarkHistoryAction(pageId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return PlacemarkHistoryApi.getPlacemarkHistory(pageId)
            .then(pagedata => {
                dispatch(getPageResponse(pagedata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}