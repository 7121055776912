import * as ActionType from './ActionType';
import SessionApi from '../api/SessionApi';
import { ApiCallBeginAction } from './ApiAction';


export const getSessionsResponse = sessionsdata => ({
    type: ActionType.GET_SESSIONS_RESPONSE,
    sessionsdata
});

export function getSessionsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SessionApi.getSessions()
            .then(sessionsdata => {
                dispatch(getSessionsResponse(sessionsdata));
                return sessionsdata;
            }).catch(error => {
                throw error;
            });
    };
}