import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const rolesReducer = (state = initialState.rolesReducer, action) => {
    switch(action.type) {
        case ActionType.GET_ROLES_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                rolesdata: _.assign(action.rolesdata)
            };
        }


        default: { return state; }
    }
};



export default rolesReducer;