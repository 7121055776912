import * as ActionType from './ActionType';
import ModelApi from '../api/ModelApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getModelsResponse = modelsdata => ({
    type: ActionType.GET_MODELS_RESPONSE,
    modelsdata
});

const queuedGetAllModels = queueApiCall(ModelApi.getAllModels);

export function getModelsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllModels()
            .then(modelsdata => {
                dispatch(getModelsResponse(modelsdata));
                return modelsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewModelResponse = () => ({
    type: ActionType.ADD_NEW_MODEL_RESPONSE
});

export const updateExistingModelResponse = () => ({
    type: ActionType.UPDATE_EXISTING_MODEL_RESPONSE
});

export function saveModelAction(modeldata) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return ModelApi.saveModel(modeldata)
            .then((response) => {
                if (modeldata.id) {
                    dispatch(updateExistingModelResponse());
                } else {
                    dispatch(addNewModelResponse());
                }
                return response;
            }).then((response) => {
                if (modeldata.id == "0") {
                    dispatch(getModelAction(modeldata.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getModelResponse = modeldata => ({
    type: ActionType.GET_MODEL_RESPONSE,
    modeldata
});

export function getModelAction(modelId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return ModelApi.saveModel(modelId)
            .then(modeldata => {
                dispatch(getModelResponse(modeldata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteModelResponse = () => ({
    type: ActionType.DELETE_MODEL_RESPONSE
});

const queuedDeleteModel = queueApiCall(ModelApi.deleteModel);

export function deleteModelAction(modelId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteModel(modelId)
            .then((response) => {
                dispatch(deleteModelResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getModelAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}