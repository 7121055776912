import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
//eslint-disable-next-line
import { Grid, Box, Heading, DataTable, Text, Image } from 'grommet';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import withPagination from './common/PaginatedDataTable';
import { SERVER } from '../api/serverUrl';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { isNumber } from 'util';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';

const PaginatedDataTable = withPagination(DataTable);

var maintenanceApiResponse;
var start = undefined;

export class OrgIntegrationEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            firstValidation: false,
            index: 0,
            oauth: false,
            clearpass: false,
            showAuthenticationError: true,
            warningOauth2: false,
            warningClearpass: false,
            warningMeridian: false,
            warningTeem: false,
            warningBuildingManagement: false,
            warningChargifi: false,
            warningMicrosoft365: false,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    authentication_err = "";

    maintenanceApiCall() {
        var SERVER_URL = `${SERVER}/api/Maintenance`;

        const options = {
            method: 'get',
        }
        return fetch(SERVER_URL.concat("?", constants.API_VERSION), options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                maintenanceApiResponse = data;
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG* maintenanceApiCall', error);
            });
    }

    eventDelete(event) {
        //const selectedId = this.state.selectedSite;

        //if (selectedId) {
        //    this.setState({ selectedSite: 0, open: undefined });
        //    this.props.action.deleteSiteAction(selectedId)
        //        .catch(error => {
        //            console.log('*DEBUG*- Site List Delete Error', error);
        //        });
        //}
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
        }
    }

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/orgsite/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        sessionStorage.setItem("locationId", nro);
        const canread = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        console.log('*Switch* started on ', nro, canread);
        this.props.history.push(constants.getOrgLevelRoute() + '/orgsite/' + nro);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitesdata && !this.state.loaded) {
            this.setState({ loaded: true });
        };
    }
    componentWillReceiveProps() {
        if (this.props.organisationdata) {
            this.validateSettings("All");
            this.setState({ firstValidation: true })
        }
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const id = constants.organisation.id;
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        var index = this.props.location.state ? this.props.location.state.index : 0;

        this.maintenanceApiCall();
        this.setState({ index: index });

        if (this.props.location.state) {
            this.clickGoTo(this.props.location.state.detail);
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }
    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        this.setState({ showAuthenticationError: false });
        this.authentication_err = "";
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data
        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });

        //INTEGRATIONS
        if (organisationdefault.clearpassEnabled && organisationdefault.oauthEnabled) {
            errorsno++;
            errortab = 0;
            this.authentication_err = "Only one type of authentication can be turned on";
            this.setState({ showAuthenticationError: true });
        }

        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.onActive(errortab);
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations');
                });
        }
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }
    //Validation
    validateSettings(type) {
        const organisationdefault = this.props.organisationdata;
        let Oauth2Validation = this.state.warningOauth2;
        let ClearpassValidation = this.state.warningClearpass;
        let MeridianValidation = this.state.warningMeridian;
        let TeemValidation = this.state.warningTeem
        let BuildingManagementValidation = this.state.warningBuildingManagement
        let ChargifiValidation = this.state.warningChargifi
        let Microsoft365Validation = this.state.warningMicrosoft365;
        //Oauth2
        if (organisationdefault.oauthClientId.trim().length == 0 || organisationdefault.oauthRedirectURI.trim().length == 0 || organisationdefault.oauthDiscoveryURL.trim().length == 0) {
            Oauth2Validation = true
        }
        else {
            Oauth2Validation = false
        }
        //Clearpass
        if (organisationdefault.clearpassClientId.trim().length == 0 || organisationdefault.clearpassClientSecret.trim().length == 0 || organisationdefault.clearpassUrl.trim().length == 0) {
            ClearpassValidation = true
        }
        else {
            ClearpassValidation = false
        }
        //Meridian
        if (organisationdefault.appToken.trim().length == 0) {
            MeridianValidation = true
        }
        else {
            MeridianValidation = false
        }
        //Teem
        if (organisationdefault.teemClientId.trim().length == 0 || organisationdefault.teemClientSecret.trim().length == 0 || organisationdefault.teemOrgId == null || organisationdefault.teemOrgId == "") {
            TeemValidation = true
        }
        else {
            TeemValidation = false
        }
        //Building Management
        if (organisationdefault.buildingManagementURL.trim().length == 0 || organisationdefault.buildingManagementTemperature == null || organisationdefault.buildingManagementTemperature == ""
            || organisationdefault.buildingManagementAmbience == null || organisationdefault.buildingManagementAmbience == ""
            || organisationdefault.buildingManagementIntensity == null || organisationdefault.buildingManagementIntensity == "") {
            BuildingManagementValidation = true
        }
        else {
            BuildingManagementValidation = false
        }
        //Chargifi
        if (organisationdefault.chargifiMspId == null || organisationdefault.chargifiMspId == ""
            || organisationdefault.chargifiOrgId == null || organisationdefault.chargifiOrgId == ""
            || organisationdefault.chargifiApiKey == null || organisationdefault.chargifiApiKey == ""
            || organisationdefault.chargifiClientSecret == null || organisationdefault.chargifiClientSecret == "") {
            ChargifiValidation = true
        }
        else {
            ChargifiValidation = false
        }
        //Microsoft365
        if (organisationdefault.graphTenantId.trim().length == 0 || organisationdefault.graphClientId.trim().length == 0
            || organisationdefault.graphClientSecret.trim().length == 0 || organisationdefault.graphUserName.trim().length == 0
            || organisationdefault.graphPassword.trim().length == 0) {
            Microsoft365Validation = true
        }
        else {
            Microsoft365Validation = false
        }

        if (type == "Oauth2") {
            this.setState({ warningOauth2: Oauth2Validation });
        }
        else if (type == "Clearpass") {
            this.setState({ warningClearpass: ClearpassValidation });
        }
        else if (type == "Meridian") {
            this.setState({ warningMeridian: MeridianValidation });
        }
        else if (type == "Teem") {
            this.setState({ warningTeem: TeemValidation });
        }
        else if (type == "BuildingManagement") {
            this.setState({ warningBuildingManagement: BuildingManagementValidation });
        }
        else if (type == "Chargifi") {
            this.setState({ warningChargifi: ChargifiValidation });
        }
        else if (type == "Microsoft365") {
            this.setState({ warningMicrosoft365: Microsoft365Validation });
        }
        else if (type == "All") {
            this.setState({
                warningOauth2: Oauth2Validation, warningClearpass: ClearpassValidation, warningMeridian: MeridianValidation, warningTeem: TeemValidation,
                warningBuildingManagement: BuildingManagementValidation, warningChargifi: ChargifiValidation, warningMicrosoft365: Microsoft365Validation
            });
        }
    }
    //dealing with data
    /*
    updateOauth = (e) => {
        if (this.state.clearpass && !this.state.oauth) {
            this.props.organisationdata.clearpassEnabled = false;
            this.setState({ clearpass: false });
        }
        if (this.props.organisationdata.clearpassEnabled && !this.props.organisationdata.oauthEnabled) {
            this.props.organisationdata.clearpassEnabled = false;
            this.setState({ clearpass: false });
        }
        this.props.organisationdata.oauthEnabled = !this.props.organisationdata.oauthEnabled;
        this.setState({ oauth: !this.state.oauth });
        //Validation
        this.validateSettings("Oauth2");
    }
    updateClearpass = (e) => {
        if (this.state.oauth && !this.state.clearpass) {
            this.props.organisationdata.oauthEnabled = false;
            this.setState({ oauth: false });
        }
        if (this.props.organisationdata.oauthEnabled && !this.props.organisationdata.clearpassEnabled) {
            this.props.organisationdata.oauthEnabled = false;
            this.setState({ oauth: false });
        }
        this.props.organisationdata.clearpassEnabled = !this.props.organisationdata.clearpassEnabled;
        this.setState({ clearpass: !this.state.clearpass });
        //Validation
        this.validateSettings("Clearpass");
    }
    updateMeridian = (e) => {
        this.props.organisationdata.meridianEnabled = !this.props.organisationdata.meridianEnabled;
        if (!this.props.organisationdata.meridianEnabled) {
            this.props.sitesdata.map((location, i) => {
                this.DisableMeridian(location);
            });
        }
        this.setState({ update: !this.state.update });
        //Validation
        this.validateSettings("Meridian");
    }
    updateTeem = (e) => {
        this.props.organisationdata.teemEnabled = !this.props.organisationdata.teemEnabled;
        if (!this.props.organisationdata.teemEnabled) {
            this.props.sitesdata.map((location, i) => {
                //this.DisableBookADesk(location); //Book a Desk removed from the portal
                this.DisableBookARoom(location);
            });
        }
        this.setState({ update: !this.state.update });
        //Validation
        this.validateSettings("Teem");
    }
    updateBuildingManagement = (e) => {
        this.props.organisationdata.buildingManagementEnabled = !this.props.organisationdata.buildingManagementEnabled;
        this.setState({ update: !this.state.update });
        //Validation
        this.validateSettings("BuildingManagement");
    }
    updateChargifi = () => {
        this.props.organisationdata.chargifiEnabled = !this.props.organisationdata.chargifiEnabled;
        if (!this.props.organisationdata.chargifiEnabled) {
            this.props.sitesdata.map((location, i) => {
                this.DisableChargifi(location);
            });
        }
        this.setState({ update: !this.state.update });
        //Validation
        this.validateSettings("Chargifi");
    }
    updateMicrosoft365 = (e) => {
        //this.props.organisationdata.buildingManagementEnabled = !this.props.organisationdata.buildingManagementEnabled;
        this.setState({ update: !this.state.update });
        //Validation
        this.validateSettings("Microsoft365");
    }

    ResetQA(location, type) {
        location.quickActions.map((item, i) => {
            if (item.defaultType == type) {
                item.active = false;
                console.log('Reset ', item.defaultType, item.name);
            }
        });
    }
    DisableBookARoom(location) {
        if (location.bookARoomEnabled) {
            location.bookARoomEnabled = false;
            this.ResetQA(location, "BOOKSPACE"); //Book a Room
        }
    }
    DisableBookADesk(location) {
        if (location.bookADeskEnabled) {
            location.bookADeskEnabled = false;
            this.ResetQA(location, "Book a Desk");
        }
    }
    DisableMeridian(location) {
        if (location.meridianEnabled) {
            location.meridianEnabled = false;
            location.assetTrackingEnabled = false;
            this.ResetQA(location, "LOCATEPEOPLE");
        }
        if (location.assetTrackingEnabled) {
            location.assetTrackingEnabled = false;
        }
    }
    DisableChargifi(location) {
        if (location.chargifiEnabled) {
            location.chargifiEnabled = false;
        }
    }
    */
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        this.setState({ index: index });
    }

    clickSettingsOauth2() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/oauth2');
    }
    clickSettingsClearpass() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/clearpass');
    }
    clickSettingsMeridian() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/meridian');
    }
    clickSettingsTeem() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/teem');
    }
    clickSettingsBuildingManagement() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/buildingmanagement');
    }
    clickSettingsChargifi() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/chargifi');
    }
    clickSettingsMicrosoft365() {
        this.props.history.push(constants.getOrgLevelRoute() + '/orgintegrations/microsoft365');
    }

    clickGoTo(location) {
        if (document.getElementById(location))
            document.getElementById(location).scrollIntoView();
        //document.getElementById(location).scrollIntoView({ behavior: "smooth" });
    }

    renderCard(img_location, img_width, card_name, description, settings_onClick, active, warning) {
        return (
            <StyledBox style1 pad={{ "horizontal": "small", "top": "small", "bottom": "xsmall" }} direction="column" style={{ "min-width": "325px" }} height="270px" width="560px">
                {active &&
                    <Box direction="row" gap="xsmall" align="center" height="10%">
                        <Image width="30" height="30" src="./images/green.svg" title="Active" />
                        <Text size="large">ACTIVE</Text>
                    </Box>}
                {!active &&
                    <Box direction="row" gap="xsmall" align="center" height="10%">
                        <Image width="30" height="30" src="./images/yellow_grey.svg" style={{ "fill": "blue" }} title="Inactive" />
                        <Text size="large" color="#DADADA">INACTIVE</Text>
                    </Box>}
                <Box height="80%" direction="row">
                    <Box height="100%" width="40%" justify="center" align="center" pad={{ "left": "small", "right": "medium" }}>
                        <Image opacity={active ? "100%" : "100%"} src={img_location} width={img_width} />
                    </Box>
                    <Box height="100%" width="60%" direction="column" gap="small" >
                        <Box direction="column" height="60%">
                            <Text size="30px" color={active ? "" : ""} style={{ "font-weight": "600" }}>{card_name}</Text>
                            <Text size="medium" color={active ? "" : ""}>{description}</Text>
                        </Box>
                        <Box width="110px">
                            <StyledButton label="Settings" onClick={settings_onClick} typeAdd />
                        </Box>
                    </Box>
                </Box>
                {warning && active &&
                    <Box direction="row" height="10%" align="center">
                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                        <Text size="medium" color="status-critical">Not all settings are configured properly</Text>
                    </Box>}
            </StyledBox>
        )
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const { organisationdataLoading } = this.state;

        if (organisationdataLoading || maintenanceApiResponse == null) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} />
            );
        }

        const isThereActive = (
            this.context.feature.find(f => f.name == "mobilClient").isEnabled ||
            this.context.feature.find(f => f.name == "map").isEnabled ||
            this.context.feature.find(f => f.name == "book").isEnabled ||
            this.context.feature.find(f => f.name == "buildingMNGMT").isEnabled ||
            (this.context.feature.find(f => f.name == "chargifi").isEnabled && constants.release().Feature_chargifi)
        );

        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }
        if (!this.state.firstValidation) {
            this.setState({ firstValidation: true })
            this.validateSettings("All")
        }

        return (
            <Context.Consumer>
                {values => {
                    return (

                        <Grid fill={true}
                            rows={['xsmall', 'auto']}
                            columns={['100%', 'flex']}

                            areas={[
                                { name: 'header', start: [0, 0], end: [1, 0] },

                                { name: 'main', start: [0, 1], end: [0, 1] },
                                { name: 'side', start: [1, 0], end: [1, 1] },
                            ]}
                        >
                            <Box gridArea='header' direction="row"
                                align="center"
                                pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                                justify="between"
                                background={constants.BACKGROUND_COLOR}>
                                <Heading level='2' >Integrations</Heading>
                                {/*<Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>*/}
                            </Box>
                            <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" height="1200px">
                                <PopUp
                                    header=""
                                    open={this.state.isSaved}
                                    text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                                    onClose={() => this.onClose()}
                                    createOrg={false}
                                    orgName={organisationdefault.name}
                                    error={!this.state.isSaveSuccessful} />
                                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                                <form>
                                    <Box pad={{ "bottom": "medium" }}>
                                        <Box pad={{ vertical: "small" }}>
                                            {isThereActive ?
                                                <Text>Select the app integrations required by the application. Further configuration may also be required at a location configuration level.</Text> :
                                                <Text>There are no integrations available for this organization, please ensure the required features has been activated to configure your integrations.</Text>}
                                        </Box>
                                        <Box gap="medium">
                                            {values.feature.find(f => f.name == "mobilClient").isEnabled &&
                                                <Box gap="xsmall" id="Authentication">
                                                    <Text size="30px">Authentication</Text>
                                                    {this.state.showAuthenticationError && <Text color="status-critical">{this.authentication_err}</Text>}
                                                    <Box gap="medium" direction="row">
                                                        {
                                                            this.renderCard("./Images/org_integration/oauth2.png", "60%", "OAUTH2",
                                                                "",
                                                                this.clickSettingsOauth2.bind(this), organisationdefault.oauthEnabled, this.state.warningOauth2)
                                                        }
                                                        {true/*(constants.RELEASE_TYPE == "MTF" || constants.RELEASE_TYPE == "PROD-MTF")*/ &&
                                                            this.renderCard("./Images/org_integration/aruba.png", "100%", "Aruba Clearpass",
                                                                "",
                                                                this.clickSettingsClearpass.bind(this), organisationdefault.clearpassEnabled, this.state.warningClearpass)
                                                        }
                                                    </Box>
                                                </Box>}

                                            {values.feature.find(f => f.name == "map").isEnabled &&
                                                <Box gap="xsmall" id="Location Based Services">
                                                    <Text size="30px">Location Based Services</Text>
                                                    <Box gap="medium" direction="row">
                                                        {
                                                            this.renderCard("./Images/org_integration/meridian.png", "90%", "Aruba Meridian",
                                                                "",
                                                                this.clickSettingsMeridian.bind(this), organisationdefault.meridianEnabled, this.state.warningMeridian)
                                                        }
                                                        <Box height="270px" width="560px" />
                                                    </Box>
                                                </Box>
                                            }

                                            {values.feature.find(f => f.name == "book").isEnabled &&
                                                <Box gap="xsmall" id="Space Management">
                                                    <Text size="30px">Space Management</Text>
                                                    <Box gap="medium" direction="row">
                                                        {
                                                            this.renderCard("./Images/org_integration/teem.png", "80%", "Teem by iOFFICE",
                                                                "",
                                                                this.clickSettingsTeem.bind(this), organisationdefault.teemEnabled, this.state.warningTeem)
                                                        }
                                                        {
                                                            this.renderCard("./Images/org_integration/microsoft365.png", "100%", "Microsoft 365",
                                                                "",
                                                                this.clickSettingsMicrosoft365.bind(this), organisationdefault.graphEnabled, this.state.warningMicrosoft365)
                                                        }
                                                    </Box>
                                                </Box>
                                            }

                                            {values.feature.find(f => f.name == "buildingMNGMT").isEnabled &&
                                                <Box gap="xsmall" id="Building Management System">
                                                    <Text size="30px">Building Management System</Text>
                                                    <Box gap="medium" direction="row">
                                                        {
                                                            this.renderCard("./Images/org_integration/building.png", "50%", "Building Management",
                                                                "",
                                                                this.clickSettingsBuildingManagement.bind(this), organisationdefault.buildingManagementEnabled, this.state.warningBuildingManagement)
                                                        }
                                                        <Box height="270px" width="560px" />
                                                    </Box>
                                                </Box>
                                            }

                                            {values.feature.find(f => f.name == "chargifi").isEnabled && constants.release().Feature_chargifi &&
                                                <Box gap="xsmall" id="Wireless Charging">
                                                    <Text size="30px">Wireless Charging</Text>
                                                    <Box gap="medium" direction="row">
                                                        {
                                                            this.renderCard("./Images/org_integration/chargifi.png", "90%", "Chargifi",
                                                                "",
                                                                this.clickSettingsChargifi.bind(this), organisationdefault.chargifiEnabled, this.state.warningChargifi)
                                                        }
                                                        <Box height="270px" width="560px" />
                                                    </Box>
                                                </Box>}
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Grid>
                    );
                }}
            </Context.Consumer>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || ''; //from the path '/category/:categoryId'
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgIntegrationEditContainer));
