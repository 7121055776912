import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedPageReducer = (state = initialState.selectedPageReducer, action) => {
    switch(action.type) {

        case ActionType.GET_PAGE_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                pagedata: _.assign(action.pagedata)
            };
        }

        default: { return state; }
    }
};


export default selectedPageReducer;