import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const organisationsReducer = (state = initialState.organisationsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_ORGANISATIONS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                organisationsdata: _.assign(action.organisationsdata)
            };
        }


        default: { return state; }
    }
};



export default organisationsReducer;