import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, FormUp, FormDown, CircleInformation } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, DataTable, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea, Tab, Tabs } from 'grommet';
import { Filter, Edit, Close, Search, Trash } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import AppLocationListTable from './AppLocationListTable';
import withPagination from './common/PaginatedDataTable';
import { OrgSiteListContainer } from './OrgSiteListContainer';
import OrgSiteListTable from './OrgSiteListTable';
import { OrgSiteEditContainer } from './OrgSiteEditContainer';
import TextInputWithState from './common/TextInputWithState';
import { SERVER } from '../api/serverUrl';
import { myHeaders, myHeadersWithToken } from '../api/ApiUtils';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { isNumber } from 'util';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';
import ReactTooltip from "react-tooltip";


const PaginatedDataTable = withPagination(DataTable);

var maintenanceApiResponse;
var start = undefined;

export class OrgSettings extends React.Component {

    static contextType = Context;
    constructor() {
        super();
        //this.handleNew = this.handleNew.bind(this);
        //this.handleEdit = this.handleEdit.bind(this);
        //this.handleDelete = this.handleDelete.bind(this);
        //this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            index: 0,
            popupOpen: undefined,
            siteIDforDelete: "",
            deletedSiteID: "",
            organisationdataLoading: true,
            sitesLoading: true,
            organisationsdataLoading: true,
            openFeautresPopUp: false,
            openSaveWarning: false,
            featureChanged: false,
            apiErrorCounter: 0,
            apiErrorList: [],
            featureSwitch: false
        };
    }

    maintenanceApiCall() {
        var SERVER_URL = `${SERVER}/api/Maintenance`;

        const options = {
            method: 'get',
        }
        return fetch(SERVER_URL.concat("?", constants.API_VERSION), options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                maintenanceApiResponse = data;
            })
    }

    //handleDelete(event, nro) {
    //    this.setState({ popupOpen: true, siteIDforDelete: nro });
    //}

    //eventDelete(event) {
    //    const selectedId = this.state.selectedSite;

    //    if (selectedId) {
    //        this.setState({ selectedSite: 0, open: undefined });
    //        this.props.action.deleteSiteAction(selectedId)
    //            .catch(error => {
    //                console.log('*DEBUG*- Site List Delete Error', error);
    //            });
    //    }
    //}

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        if (this.props.match.params.orgid == 0) {
            this.props.organisationsdata.map((org) => {
                if (this.props.organisationdata.name.toLowerCase() == org.name.toLowerCase() && org.deleted != 1) {
                    sessionStorage.setItem("organisationId", org.id);
                    sessionStorage.setItem("organisationCode", org.code);
                    this.props.history.push(constants.getOrgLevelRoute() + '/orgsettings');
                }
            });
        }
    }
    //popupClose = () => this.setState({ popupOpen: undefined, deletedSiteID: "" });
    //popupDelete = () => {
    //    var selectedSite = this.props.sitesdata.find(item => item.id == this.state.siteIDforDelete);
    //    selectedSite.deleted = true;

    //    this.props.action.saveSiteAction(selectedSite)
    //        .then(() => {
    //            this.props.action.getSitesQAction()
    //                .then(() => {
    //                })
    //                .catch(error => {
    //                    console.log('*DEBUG*- Sites List Error', error);
    //                });
    //        }).catch(error => {
    //            this.setState({ disabledButton: false });
    //        });
    //    this.setState({ popupOpen: undefined, deletedSiteID: this.state.siteIDforDelete });
    //}

    //handleNew(event) {
    //    event.preventDefault();
    //    this.props.history.push('/orgsite/0');
    //}

    //handleEdit(event, nro) {
    //    event.preventDefault();
    //    sessionStorage.setItem("locationId", nro);
    //    const canread = RB.canRead(constants.PERMS.LOC_CONF_SITE);
    //    console.log('*Switch* started on ', nro, canread);
    //    this.props.history.push('/orgsite/' + nro);
    //}

    //Validating fields error handling
    name_err = '';
    code_err = '';
    twitter_err = '';
    linkedin_err = '';
    facebook_err = '';
    youtube_err = '';

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitesdata && !this.state.loaded) {
            this.setState({ loaded: true });
        };
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const id = constants.organisation.id;
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
                console.log('*DEBUG*- Organisations List Error', error);
            });
        var index = this.props.location.state ? this.props.location.state.index : 0;
        this.maintenanceApiCall();
        this.setState({ index: index });
    }

    componentWillReceiveProps({ organisationdata }) {
        if (!this.state.organisationdataLoading) {
            let isOn =
                organisationdata.deskBookingEnabled ||
                organisationdata.roomBookingEnabled ||
                organisationdata.smartWirelessChargingEnabled ||
                organisationdata.mobileAppEnabled ||
                organisationdata.contentEnabled ||
                organisationdata.mapsEnabled ||
                organisationdata.wayfindingEnabled ||
                organisationdata.findAFriendEnabled ||
                organisationdata.assetTrackingEnabled ||
                organisationdata.assetTrackingHistoryEnabled ||
                organisationdata.personTrackingEnabled ||
                organisationdata.notificationsEnabled ||
                organisationdata.venueManagementEnabled ||
                organisationdata.meetAndGreetEnabled ||
                organisationdata.sessionsEnabled;
            this.setState({ featureSwitch: isOn });
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }


    clearErrorMessages() {
        this.title_err = '';
        this.code_err = '';
        this.twitter_err = '';
        this.linkedin_err = '';
        this.facebook_err = '';
        this.youtube_err = '';

        //TAB2
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        const organisations = this.props.organisationsdata;
        //checking data
        if (!organisationdefault.twitter) { organisationdefault.twitter = '' };
        if (!organisationdefault.linkedIn) { organisationdefault.linkedIn = '' };
        if (!organisationdefault.facebook) { organisationdefault.facebook = '' };
        if (!organisationdefault.youTube) { organisationdefault.youTube = '' };
        organisationdefault.trackAssetsEnabled = organisationdefault.assetTrackingEnabled;
        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });
        if (organisationdefault.name.trim().length == 0) {
            errorsno++;
            this.name_err = 'Name is required';
            errortab = 0;
        }
        if (organisationdefault.name.length > 50) {
            errorsno++;
            this.name_err = "Name can't be longer than 50 character";
            errortab = 0;
        }
        organisations.map((org) => {
            if (organisationdefault.id != sessionStorage.getItem("organisationId") && organisationdefault.name.toLowerCase() == org.name.toLowerCase() && org.deleted != 1) {
                errorsno++;
                this.name_err = "This name is already being used for another organization";
                errortab = 0;
            }
        });
        if (organisationdefault.twitter) {
            if (organisationdefault.twitter.trim().length != 0 && !(validator.isURL(organisationdefault.twitter, constants.URL_OPTIONS))) {
                errorsno++;
                this.twitter_err = constants.URL_ERROR;
                errortab = 0;
            }
            if (organisationdefault.twitter.length > 1024) {
                errorsno++;
                this.twitter_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (organisationdefault.linkedIn) {
            if (organisationdefault.linkedIn.trim().length != 0 && !(validator.isURL(organisationdefault.linkedIn, constants.URL_OPTIONS))) {
                errorsno++;
                this.linkedin_err = constants.URL_ERROR;
                errortab = 0;
            }
            if (organisationdefault.linkedIn.length > 1024) {
                errorsno++;
                this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (organisationdefault.facebook) {
            if (organisationdefault.facebook.trim().length != 0 && !(validator.isURL(organisationdefault.facebook, constants.URL_OPTIONS))) {
                errorsno++;
                this.facebook_err = constants.URL_ERROR;
                errortab = 0;
            }
            if (organisationdefault.facebook.length > 1024) {
                errorsno++;
                this.facebook_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (organisationdefault.youTube) {
            if (organisationdefault.youTube.trim().length != 0 && !(validator.isURL(organisationdefault.youTube, constants.URL_OPTIONS))) {
                errorsno++;
                this.youtube_err = constants.URL_ERROR;
                errortab = 0;
            }
            if (organisationdefault.youTube.length > 1024) {
                errorsno++;
                this.youtube_err = constants.CONNECT_NOW_URL_ERR;
            }
        }

        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    if (response.ok) {
                        this.props.action.getOrganisationsAction()
                            .catch(error => {
                                console.log('*DEBUG*- Organisations List Error', error);
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
            this.props.sitesdata.map((location) => {
                if (location.organizationId == sessionStorage.getItem("organisationId")) {
                    this.props.action.saveSiteAction(location)
                        .then(() => {
                            //this.props.history.push('/appdetails');
                        }).catch(error => {
                            console.log('*DEBUG*-Save Sites Error', error);
                            this.setState({ disabledButton: false });
                        });
                }

            });
        } else {
            this.onActive(errortab);
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (this.props.match.params.orgid == 0) {
                            this.props.history.push('/orglist');
                        }
                        else {
                            this.props.history.push(constants.getOrgLevelRoute() + '/orgsettings');
                        }

                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/orgsettings');
                });
        }
    }

    updateGpsEnabledValue(evt) {
        this.props.organisationdata.gpsEnabled = evt.target.checked;
        this.setState({ cbgpsenabled: evt.target.checked });
    }

    updateLastSiteEnabledValue(evt) {
        this.props.organisationdata.lastSiteEnabled = evt.target.checked;
        this.setState({ updated: true });
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }

    //dealing with data
    updateNameValue(evt) {
        this.props.organisationdata.name = evt.target.value;
        if (evt.target.value.length > 50) {
            this.name_err = "Name can't be longer than 50 character";
        }
        else {
            this.name_err = "";
        }
        this.setState({ namechange: true });
    }
    updateTwitterValue(evt) {
        this.props.organisationdata.twitter = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.twitter_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.twitter_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateLinkedinValue(evt) {
        this.props.organisationdata.linkedIn = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.linkedin_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateFacebookValue(evt) {
        this.props.organisationdata.facebook = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.facebook_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.facebook_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateYoutubeValue(evt) {
        this.props.organisationdata.youTube = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.youtube_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.youtube_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }

    onCancelFeature() {
        this.setState({ openFeautresPopUp: false, openSaveWarning: false });
    }
    onDiscardFeature() {
        this.props.action.getOrganisationAction(this.props.match.params.orgid)
            .then(response => this.setState({ openFeautresPopUp: false, openSaveWarning: false }))
            .catch(error => {
                console.log('*DEBUG*-Organisation Error', error);
            });
    }
    openWarning() {
        if (this.state.openSaveWarning && this.state.featureChanged && !_.isEqual(start, this.props.organisationdata)) {
            return true;
        }
        return false;
    }

    renderFeature({ name = "", property = "", level = 0, parent = "", children = [], showInfo = false, featureInfo = "", place = "" } = {}) {
        const organisationdata = this.props.organisationdata;
        var marginLeft = level * 24 + "px";
        var paddingTop = level == 0 ? "small" : "xsmall";
        var paddingBottom = level == 0 && children.length == 0 ? "small" : "xsmall";

        function isDisabled() {
            if (!RB.isSuperUser()) {
                return true;
            }
            if (parent != "" && parent != "none" && !organisationdata[parent]) {
                return true;
            }
            else return false;
        }

        function changeBackground(set) {
            if (set) document.getElementById("box_id" + property + place).style.background = "#DADADA";
            else document.getElementById("box_id" + property + place).style.background = "transparent";
        }

        if (parent != "" && parent != "none" && !organisationdata[parent]) {
            organisationdata[property] = false;
        }

        return (
            <Box margin={{ "left": marginLeft }} direction="row" justify="between" pad={{ "top": paddingTop, "bottom": paddingBottom }} gap="large">
                <Box id={"box_id" + property + place} pad={{ "horizontal": "xsmall" }} round="5px" direction="row" gap="small" align="center">
                    <Text size="large">{name}</Text>
                    {showInfo &&
                        <Box data-for={"featureinfo" + property + place} data-tip={featureInfo} style={{ "height": "24px" }}>
                            <CircleInformation />
                            <ReactTooltip id={"featureinfo" + property + place} place="right" type="dark" effect="solid" disable={!showInfo} arrowColor="transparent" />
                        </Box>}
                </Box>
                <Box data-for={"disableinfo" + property} data-tip="Enable parent feature in order to use this feature." style={{ "height": "24px" }} onMouseEnter={() => changeBackground(true)} onMouseLeave={() => changeBackground(false)} >
                    <CheckBox toggle checked={organisationdata[property]} disabled={isDisabled()}
                        onChange={evt => (organisationdata[property] = !organisationdata[property], !organisationdata[property] ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                    <ReactTooltip id={"disableinfo" + property} place="right" type="dark" effect="solid" disable={!isDisabled()} arrowColor="transparent" />
                </Box>
            </Box>
        )
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const organisations = this.props.organisationsdata;
        const sitesdefault = this.props.sitesdata;
        const { organisationdataLoading, sitesdataLoading, organisationsdataLoading } = this.state;

        if (organisationdataLoading || sitesdataLoading || organisationsdataLoading || maintenanceApiResponse == null) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        // const organisationhpe = organisations.filter(o => o.code == "HPE").slice();
        // const organisationdefault = organisationhpe[0];
        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }
        //const date = organisationdefault.dateCreated.substr(0, 10);
        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                {/* <Prompt message={(location, action) => {
                    if (!_.isEqual(start, this.props.organisationdata)) {
                        return 'Are you sure you want to leave this page? Unsaved data on the form.';
                    }
                }} /> }
                {/* <Prompt message={this.handleBrowserBack.bind(this)} /> */}
                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >{this.props.match.params.orgid == 0 ? "Organization - Create" : "Configuration"}</Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={evt => this.state.featureChanged && !_.isEqual(start, this.props.organisationdata) ? this.setState({ openSaveWarning: true }) : this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium">
                    {/*<PopUpConfirm open={this.state.popupOpen} text={constants.confirmMessage("site") + " This will delete all placemarks, events and content."} onClose={() => this.popupClose()} onDelete={() => this.popupDelete()} />*/}
                    <PopUp
                        header={this.props.match.params.orgid == 0 ? "Create Organization" : ""}
                        open={this.state.isSaved}
                        text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                        onClose={() => this.onClose()}
                        createOrg={this.props.match.params.orgid == 0 ? true : false}
                        orgName={this.props.match.params.orgid == 0 ? organisationdefault.name : ""}
                        error={!this.state.isSaveSuccessful} />
                    <PopUpCancel text="Disabling features will remove all related items from the portal and client for this organization." SaveText="Save" CancelText="Cancel"
                        open={this.openWarning()} onCancel={() => this.onCancelFeature()} onSave={evt => this.handleSave()} onDiscard={() => this.onDiscardFeature()} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box margin={{ "bottom": "medium" }} gap="medium">
                            <Box>
                                {RB.isSuperUser() && maintenanceApiResponse &&
                                    <Text size="large" color="red" margin={{ "bottom": "small" }}>The portal is currently being republished. You might experience some issues until it is done.</Text>}

                                <Box gap="small">
                                    <Text size="large">Org Settings</Text>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label='Organization Name *' error={this.name_err}>
                                            <TextInput defaultValue={this.props.match.params.orgid != 0 ? organisationdefault.name : ""} onChange={evt => this.updateNameValue(evt)} plain />
                                        </FormField>
                                        {this.props.match.params.orgid != 0 &&
                                            <Box>
                                                <FormField label='Organization ID' error={this.code_err}>
                                                    <TextInput defaultValue={organisationdefault.id} readOnly disabled />
                                                </FormField>
                                                <FormField label='Date Created' error={this.date_err}>
                                                    <TextInput type='date' defaultValue={date} readOnly disabled onChange={evt => this.updateDateValue(evt)} />
                                                </FormField>
                                                {this.context.feature.find(f => f.name == "mobilClient").isEnabled &&
                                                    <Box>
                                                        <FormField label='GPS Enabled'>
                                                            <Box direction='row' pad="small" gap="xlarge">
                                                                <CheckBox label="App will locate the nearest organization location" checked={organisationdefault.gpsEnabled} onChange={evt => this.updateGpsEnabledValue(evt)} />
                                                            </Box>
                                                        </FormField>
                                                        <FormField label='Save Last Site'>
                                                            <Box direction='row' pad="small" gap="xlarge">
                                                                <CheckBox label="The app will save the last site used on the device." checked={organisationdefault.lastSiteEnabled} onChange={evt => this.updateLastSiteEnabledValue(evt)} />
                                                            </Box>
                                                        </FormField>
                                                    </Box>}
                                            </Box>}
                                    </StyledBox>
                                </Box>
                            </Box>

                            {RB.isSuperUser() &&
                                <Box gap="small">
                                    <Box direction="row" gap="large">
                                        <Box gap="xsmall">
                                            {/*<Text size="large">Client Features</Text>*/}
                                            <Box pad={{ "right": "small" }}>
                                                {/*{this.renderFeature({ name: "Client Features", property: "mobileAppEnabled", level: 0, parent: "none", children: ["notificationsEnabled", "contentEnabled", "roomBookingEnabled", "deskBookingEnabled", "venueManagementEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                                <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                                    <Text size="large">Features</Text>
                                                    <Box style={{ "height": "24px" }}>
                                                    <CheckBox toggle checked={/*organisationdefault.mobileAppEnabled*/ this.state.featureSwitch} disabled={!RB.isSuperUser()}
                                                        //onChange={evt => (organisationdefault.mobileAppEnabled = !organisationdefault.mobileAppEnabled, !organisationdefault.mobileAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))}
                                                        onChange={evt => {
                                                            if (evt.target.checked == false) {
                                                                organisationdefault.deskBookingEnabled = false;
                                                                organisationdefault.roomBookingEnabled = false;
                                                                organisationdefault.smartWirelessChargingEnabled = false;
                                                                organisationdefault.mobileAppEnabled = false;
                                                                organisationdefault.contentEnabled = false;
                                                                organisationdefault.mapsEnabled = false;
                                                                organisationdefault.wayfindingEnabled = false;
                                                                organisationdefault.findAFriendEnabled = false;
                                                                organisationdefault.assetTrackingEnabled = false;
                                                                organisationdefault.assetTrackingHistoryEnabled = false;
                                                                organisationdefault.personTrackingEnabled = false;
                                                                organisationdefault.notificationsEnabled = false;
                                                                organisationdefault.venueManagementEnabled = false;
                                                                organisationdefault.meetAndGreetEnabled = false;
                                                                organisationdefault.sessionsEnabled = false;
                                                            }
                                                            this.setState({ featureSwitch: evt.target.checked });
                                                        }}
                                                    />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                            {this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Chargifi", property: "smartWirelessChargingEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                            {this.renderFeature({ name: "Client", property: "mobileAppEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                            {this.renderFeature({ name: "Content", property: "contentEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Events", property: "sessionsEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "", children: ["assetTrackingEnabled", "personTrackingEnabled", "wayfindingEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Meridian Bluedot (Beacons & Wayfinding)", property: "wayfindingEnabled", level: 1, parent: "mapsEnabled", children: ["findAFriendEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Locate People", property: "findAFriendEnabled", level: 2, parent: "wayfindingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                            {this.renderFeature({ name: "Meridian Tags (People)", property: "personTrackingEnabled", level: 1, parent: "mapsEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Push Notifications", property: "notificationsEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Venue Management", property: "venueManagementEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {this.renderFeature({ name: "Visitor Notifications", property: "meetAndGreetEnabled", level: 0, parent: "", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                            {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                            {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                            </StyledBox>
                                        </Box>
                                        {false &&
                                            <Box gap="xsmall">
                                                {/*<Text size="large">Portal Features</Text>*/}
                                                <Box pad={{ "right": "small" }}>
                                                    {/*{this.renderFeature({ name: "Portal Features", property: "webAppEnabled", level: 0, parent: "none", children: ["meetingsEnabled", "deskBookingEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                                    <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                                        <Text size="large">Portal Features</Text>
                                                        <Box style={{ "height": "24px" }}>
                                                            <CheckBox toggle checked={organisationdefault.webAppEnabled} disabled={!RB.isSuperUser()}
                                                                onChange={evt => (organisationdefault.webAppEnabled = !organisationdefault.webAppEnabled, !organisationdefault.webAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                                    {/*{this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                    {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                    {/*{this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                    {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                    {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "webAppEnabled", children: ["assetTrackingEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                    {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                    {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                </StyledBox>
                                            </Box>}
                                    </Box>
                                </Box>}

                            {/*{RB.isSuperUser() && false &&*/}
                            {/*    <Box>*/}
                            {/*        <Box pad={{ vertical: "small" }}>*/}
                            {/*            <Text size="large">Org Features</Text>*/}
                            {/*        </Box>*/}
                            {/*        <StyledBox style1 pad={{ "horizontal": "small" }}>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Book a Space - Desks</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.deskBookingEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.deskBookingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "deskBookingEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.deskBookingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.deskBookingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.deskBookingEnabled = !organisationdefault.deskBookingEnabled, !organisationdefault.deskBookingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Book a Space - Rooms</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.roomBookingEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.roomBookingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "roomBookingEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.roomBookingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.roomBookingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.roomBookingEnabled = !organisationdefault.roomBookingEnabled, !organisationdefault.roomBookingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Chargifi</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.chargifiEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.chargifiEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "chargifiEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.chargifiEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.smartWirelessChargingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.smartWirelessChargingEnabled = !organisationdefault.smartWirelessChargingEnabled, !organisationdefault.smartWirelessChargingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Content</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.contentEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.contentEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "contentEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.contentEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.contentEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.contentEnabled = !organisationdefault.contentEnabled, !organisationdefault.contentEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            */}{/*<Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*            */}{/*    <Text size="large">Events & News</Text>*/}
                            {/*            */}{/*    */}{/*<CheckBox toggle checked={organisationdefault.eventsEnabled}*/}
                            {/*            */}{/*    */}{/*    onChange={evt => organisationdefault.eventsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "eventsEnabled" }) :*/}
                            {/*            */}{/*    */}{/*        (organisationdefault.eventsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*            */}{/*    <CheckBox toggle checked={organisationdefault.eventsEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*            */}{/*        onChange={evt => (organisationdefault.eventsEnabled = !organisationdefault.eventsEnabled, !organisationdefault.eventsEnabled ?*/}
                            {/*            */}{/*            this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            */}{/*</Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Locate People</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.findAFriendEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.findAFriendEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "findAFriendEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.findAFriendEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.findAFriendEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.findAFriendEnabled = !organisationdefault.findAFriendEnabled, !organisationdefault.findAFriendEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meeting Services</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.meetingsEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.meetingsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "meetingsEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.meetingsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.meetingsEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.meetingsEnabled = !organisationdefault.meetingsEnabled, !organisationdefault.meetingsEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meridian Bluedot (Beacons & Wayfinding)</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.wayfindingEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.wayfindingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "wayfindingEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.wayfindingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.wayfindingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.wayfindingEnabled = !organisationdefault.wayfindingEnabled, !organisationdefault.wayfindingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meridian Maps (Maps & Placemarks)</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.mapsEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.mapsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "mapsEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.mapsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.mapsEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.mapsEnabled = !organisationdefault.mapsEnabled, !organisationdefault.mapsEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meridian Tags (Assets & Trackers)</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.assetTrackingEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.assetTrackingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "assetTrackingEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.assetTrackingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.assetTrackingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.assetTrackingEnabled = !organisationdefault.assetTrackingEnabled, !organisationdefault.assetTrackingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meridian Tags (Asset History)</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.assetTrackingHistoryEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.assetTrackingHistoryEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "assetTrackingHistoryEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.assetTrackingHistoryEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.assetTrackingHistoryEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.assetTrackingHistoryEnabled = !organisationdefault.assetTrackingHistoryEnabled, !organisationdefault.assetTrackingHistoryEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Meridian Tags (People)</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.personTrackingEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.personTrackingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "personTrackingEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.personTrackingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.personTrackingEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.personTrackingEnabled = !organisationdefault.personTrackingEnabled, !organisationdefault.personTrackingEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Mobile Client</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.mobileAppEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.mobileAppEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "mobileAppEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.mobileAppEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.mobileAppEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.mobileAppEnabled = !organisationdefault.mobileAppEnabled, !organisationdefault.mobileAppEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Notifications</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.notificationsEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.notificationsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "notificationsEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.notificationsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.notificationsEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.notificationsEnabled = !organisationdefault.notificationsEnabled, !organisationdefault.notificationsEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*            <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                            {/*                <Text size="large">Venue Management</Text>*/}
                            {/*                */}{/*<CheckBox toggle checked={organisationdefault.venueManagementEnabled}*/}
                            {/*                */}{/*    onChange={evt => organisationdefault.venueManagementEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "venueManagementEnabled" }) :*/}
                            {/*                */}{/*        (organisationdefault.venueManagementEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser()} />*/}
                            {/*                <CheckBox toggle checked={organisationdefault.venueManagementEnabled} disabled={!RB.isSuperUser()}*/}
                            {/*                    onChange={evt => (organisationdefault.venueManagementEnabled = !organisationdefault.venueManagementEnabled, !organisationdefault.venueManagementEnabled ?*/}
                            {/*                        this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                            {/*            </Box>*/}
                            {/*        </StyledBox>*/}
                            {/*    </Box>}*/}

                            {/*Connect Now*/}
                            {this.context.feature.find(f => f.name == "mobilClient").isEnabled &&
                                <IconThemeContext.Extend value={galwayIconTheme}>
                                    <Box gap="small">
                                        <Box>
                                            <Text size="large">Connect Now</Text>
                                        </Box>
                                        <StyledBox style1 pad={{ "right": "small", "top": "xsmall" }} width="600px">
                                            <Box direction='row' align="start" >
                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                    <Twitter />
                                                </Box>
                                                <Box width="100%">
                                                    <FormField error={this.twitter_err} fill="horizontal" label="Twitter">
                                                        <TextInput defaultValue={organisationdefault.twitter} onChange={evt => this.updateTwitterValue(evt)} />
                                                    </FormField>
                                                </Box>
                                            </Box>

                                            <Box direction='row' align="start">
                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                    <LinkedinOption />
                                                </Box>
                                                <Box width="100%">
                                                    <FormField error={this.linkedin_err} fill="horizontal" label="LinkedIn">
                                                        <TextInput defaultValue={organisationdefault.linkedIn} onChange={evt => this.updateLinkedinValue(evt)} />
                                                    </FormField>
                                                </Box>
                                            </Box>

                                            <Box direction='row' align="start">
                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                    <FacebookOption />
                                                </Box>
                                                <Box width="100%">
                                                    <FormField error={this.facebook_err} fill="horizontal" label="Facebook">
                                                        <TextInput defaultValue={organisationdefault.facebook} onChange={evt => this.updateFacebookValue(evt)} />
                                                    </FormField>
                                                </Box>
                                            </Box>

                                            <Box direction='row' align="start">
                                                <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                    <Youtube />
                                                </Box>
                                                <Box width="100%">
                                                    <FormField error={this.youtube_err} fill="horizontal" label="Youtube">
                                                        <TextInput defaultValue={organisationdefault.youTube} onChange={evt => this.updateYoutubeValue(evt)} />
                                                    </FormField>
                                                </Box>
                                            </Box>
                                        </StyledBox>
                                    </Box>
                                </IconThemeContext.Extend>
                            }
                        </Box>
                    </form>
                </Box>
            </Grid>
        );
    }
}

//function renderSiteTable(props) {
//    console.log("PROP", props);
//    const COLUMNS = [
//        {
//            property: 'id',
//            header: '',
//            primary: true,
//            render: locations => <Button plain onClick={evt => this.handleEdit(evt, locations.id)} icon={<Edit />} />
//        },
//        {
//            property: 'lcname',
//            header: 'Site Name',
//            render: locations => <Text>{locations.name}</Text>
//        },
//        {
//            property: 'orgname',
//            header: 'Organization Name',
//            render: locations => <Text>{locations.orgname}</Text>
//        },
//        {
//            property: 'created',
//            header: 'Date Created',
//            render: locations => <Text>{locations.created}</Text>
//        },
//        {
//            property: 'test',
//            header: 'Test',
//            render: (locations) => <CheckBox checked={locations.test} disabled />
//        },
//        {
//            property: 'production',
//            header: 'Production',
//            render: locations => <CheckBox checked={locations.production} disabled />
//        },
//        {
//            property: 'active',
//            header: 'Active',
//            render: locations => <CheckBox checked={locations.active} disabled />
//        },
//    ];

//    let DATA = [];
//    if (props.sitesdata) {
//        const sitesfull = props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId")).slice();
//        sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
//        sitesfull.forEach(ev => {
//            //    location.forEach(ev => {
//            let orgname = (ev.organization && ev.organization.name) || '';
//            DATA.push({
//                ...ev,
//                lcname: ev.name.toLowerCase(),
//                //orgname: constants.organisation.label,
//                orgname: orgname,
//                created: ev.createdAt.substr(0, 10)
//                //test: ev.test,
//                //production: ev.production,
//                //lactive: ev.active.toString()
//            })
//        });
//    }

//    console.log('DATA:', DATA);

//    return (
//        <PaginatedDataTable
//            columns={COLUMNS.map(c => ({
//                ...c,
//                search: c.property === "lcname" || c.property === "orgname"
//            }))}
//            data={DATA}
//            //sortable
//            sortoptions={[
//                { col: 'lcname', label: 'Site Name' },
//                { col: 'orgname', label: 'Organization Name' },
//                { col: 'test', label: 'Test' },
//                { col: 'production', label: 'Production' },
//                { col: 'active', label: 'Active' },
//            ]}
//            resizeable={false}
//            displayrows={constants.PAGE_SIZE}
//        />
//    );
//}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || ''; //from the path '/category/:categoryId'
    //const organisationId = constants.organisation.id;
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            organisationsdata: state.organisationsReducer.organisationsdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgSettings));
