import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const buildingsReducer = (state = initialState.buildingsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_BUILDINGS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                buildingsdata: _.assign(action.buildingsdata)
            };
        }


        default: { return state; }
    }
};



export default buildingsReducer;