import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import { withRouter } from 'react-router';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, TextArea, Stack, Tabs, Tab } from 'grommet';
import { error } from 'util';
import TextInputWithState from './common/TextInputWithState';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { StyledFormField } from './common/StyledFormField';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;
var base64 = null;
var cleaningSchedule = [
    { id: "HOURS1", name: "Every 1 hour" },
    { id: "HOURS2", name: "Every 2 hours" },
    { id: "HOURS3", name: "Every 3 hours" },
    { id: "HOURS4", name: "Every 4 hours" },
    { id: "HOURS5", name: "Every 5 hours" },
    { id: "HOURS6", name: "Every 6 hours" },
    { id: "HOURS7", name: "Every 7 hours" },
    { id: "HOURS8", name: "Every 8 hours" },
    { id: "HOURS9", name: "Every 9 hours" },
    { id: "HOURS10", name: "Every 10 hours" },
    { id: "HOURS11", name: "Every 11 hours" },
    { id: "HOURS12", name: "Every 12 hours" },
    { id: "HOURS24", name: "Everyday" },
];

export class BuildingEditContainer extends React.Component {
    constructor() {
        super();
        this.load = true;
        this.stamp = Date.now().toString();
        this.state = {
            newimage: false,
            imagechanged: false,
            location: "",
            isSaved: false,
            loaded: false,
            file: "",
            binarydata: null,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            type: null,
            cleaningSchedule:  "",
            mergedbuildingdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    cleaningSchedule_err = '';

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.buildingId;
        this.props.action.getMergedBuildingAction(id)
            .then(response => this.setState({ mergedbuildingdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- MergedBuilding Edit Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }

        if (!this.state.cancel && this.props.mergedbuildingdata && (!_.isEqual(start, this.props.mergedbuildingdata))) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    ClearErrorMessages() {
        this.cleaningSchedule_err = "";
    }
    handleSave() {
        const sitedefault = this.props.sitesdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ disabledButton: true });
        //if (this.state.cleaningSchedule == "") {
        //    this.cleaningSchedule_err = constants.EMPTY_ERROR;
        //    errorsno++;
        //}
        if (errorsno == 0) {
            //this.props.sitesdata.logo = base64;
            console.log('Save: OK', this.props.mergedbuildingdata);
            this.props.action.saveMergedBuildingAction(this.props.mergedbuildingdata)
                .then((response) => {
                    this.setState({ isSaved: true, imagechanged: false, isSaveSuccessful: response.ok });
                    //this.props.history.push('/home');
                }).catch(error => {
                    console.log('*DEBUG*-Save Building Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ disabledButton: false });
        }

    }

    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.mergedbuildingdata) /*|| this.state.imagechanged*/)) {
            this.setState({ cancel: true });
        }
        else {
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                this.props.history.push(constants.getSiteLevelRoute() + '/configuration/' + 1);
            }
            //const siteId = this.props.sitesdata.id;
            //this.props.action.getSiteAction(siteId)
            //    .then(() => {
            //        if (this.nextLoc) {
            //            this.props.history.push(this.nextLoc);
            //        } else {
            //            this.props.history.push('/configuration/' + sessionStorage.getItem("locationId")+'/'+1);
            //        }
            //    }).catch(error => {
            //        console.log('*DEBUG*-ConfigurationEdit Error', error);
            //        this.props.history.push('/home');
            //    });
        }
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/configuration/' + 1);
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    convertCleaning(option) {
        let value = "";
        switch (option) {
            case "HOURS1":
                value = "Every 1 hour";
                break;
            case "HOURS2":
                value = "Every 2 hours";
                break;
            case "HOURS3":
                value = "Every 3 hours";
                break;
            case "HOURS4":
                value = "Every 4 hours";
                break;
            case "HOURS5":
                value = "Every 5 hours";
                break;
            case "HOURS6":
                value = "Every 6 hours";
                break;
            case "HOURS7":
                value = "Every 7 hours";
                break;
            case "HOURS8":
                value = "Every 8 hours";
                break;
            case "HOURS9":
                value = "Every 9 hours";
                break;
            case "HOURS10":
                value = "Every 10 hours";
                break;
            case "HOURS11":
                value = "Every 11 hours";
                break;
            case "HOURS12":
                value = "Every 12 hours";
                break;
            case "HOURS24":
                value = "Everyday";
                break;
        }
        return value;
    }
    convertCleaningBack(option) {
        let value = "";
        switch (option) {
            case "Every 1 hour":
                value = "HOURS1";
                break;
            case "Every 2 hours":
                value = "HOURS2";
                break;
            case "Every 3 hours":
                value = "HOURS3";
                break;
            case "Every 4 hours":
                value = "HOURS4";
                break;
            case "Every 5 hours":
                value = "HOURS5";
                break;
            case "Every 6 hours":
                value = "HOURS6";
                break;
            case "Every 7 hours":
                value = "HOURS7";
                break;
            case "Every 8 hours":
                value = "HOURS8";
                break;
            case "Every 9 hours":
                value = "HOURS9";
                break;
            case "Every 10 hours":
                value = "HOURS10";
                break;
            case "Every 11 hours":
                value = "HOURS11";
                break;
            case "Every 12 hours":
                value = "HOURS12";
                break;
            case "Everyday":
                value = "HOURS24";
                break;
        }
        return value;
    }
    render() {
        const { mergedbuildingdata } = this.props;
        const { mergedbuildingdataLoading } = this.state;

        if (mergedbuildingdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        //permissions
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONF_MAPS);
        const canedit4 = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);

        console.log("propi:", this.props);
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' > Building - Edit</Heading>
                    <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                        <StyledButton typeCancel label="Cancel" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium", "bottom": "medium" }}>
                    <form>
                    <Box pad={{ "bottom": "medium" }}>
                        <Box pad={{ "bottom": "small" }}>
                            <Text size="large">Description</Text>
                            <Text>All fields marked with an * are mandatory.</Text>
                        </Box>
                        <StyledBox style1 pad={{ "horizontal": "small" }} width="750px">
                                <FormField label="Name">
                                    <TextInput defaultValue={mergedbuildingdata.name} readOnly disabled/>
                            </FormField>
                                <FormField label="Current Occupancy" >
                                    <TextInput defaultValue={mergedbuildingdata.occupancy} readOnly disabled/>
                            </FormField>
                                <FormField label="Building Capacity" >
                                    <TextInput defaultValue={mergedbuildingdata.capacity} readOnly disabled/>
                            </FormField>
                        </StyledBox>
                        <Box pad={{ "vertical": "small" }} width="750px">
                            <Text size="large">Description</Text>
                            <Text>All spaces in the building should be cleaned within a defined period to ensure compliance with new Covid 19 regulations.
                                Spaces not cleaned according to the schedule will be flagged as cleaning overdue in the spaces list
                            </Text>
                        </Box>
                            <StyledBox style1 pad={{ "horizontal": "small" }} width="750px">
                                <FormField label="Cleaning Schedule" error={this.cleaningSchedule_err}>
                                    <Select options={cleaningSchedule.map(s=> s.name)} value={this.convertCleaning(mergedbuildingdata.cleaningSchedule)}
                                        onChange={evt => {
                                            mergedbuildingdata.cleaningSchedule = this.convertCleaningBack(evt.option);
                                            this.setState({ cleaningSchedule: evt.option });
                                        }
                                        } />
                            </FormField>
                        </StyledBox>
                        </Box>
                        </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const buildingId = ownProps.match.params.buildingId || '';
    if (state.selectedMergedBuildingReducer.mergedbuildingdata && buildingId == state.selectedMergedBuildingReducer.mergedbuildingdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedMergedBuildingReducer.mergedbuildingdata));
        return {
            mergedbuildingdata: state.selectedMergedBuildingReducer.mergedbuildingdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...MergedBuildingAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BuildingEditContainer));