import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as CategoryAction from '../action/CategoryAction';
import * as OrganisationAction from '../action/OrganisationAction';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// eslint-disable-next-line
import {
    Achievement, Action, Actions, Aid, Alarm, Alert, Analytics, Announce, Apps, Archive, Article, Atm, Attachment, Attraction, Baby, BarChart, Basket, Bike, Book, Bookmark, Briefcase, Bus, Cafeteria,
    Calendar, Camera, Car, Cart, Catalog, ChatOption, Chat, CircleInformation, CircleQuestion, Clear, Clipboard, Clock, Cloud, Compliance, Configure, Connect, ContactInfo, Contact, CreditCard,
    Dashboard, Deliver, Deploy, Desktop, Directions, DocumentText, Download, Edit, Favorite, Filter, Gallery, Gamepad, Gift, Group, Grow, Help, Home, Image as ImageIcon, InProgress, Inbox, Info, Launch, Like,
    Link, List, Location, Login, Logout, Lounge, MailOption, MapLocation, Microphone, Monitor, More, Multimedia, Music, Navigate, Note, Notification, Organization, Overview, PersonalComputer, Phone,
    PieChart, Pin, Plan, Print, Restaurant, Schedule, Scorecard, Secure, Shield, StatusWarning, Sync, Tag, Task, Train, Trash, Trophy, User, Validate, Video, Vend, View, Waypoint, Wheelchair, Wifi, Workshop,
    Blank, FormUp, FormDown, Mail, Currency, Money, Refresh
} from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, InfiniteScroll, Image as ImageComponent, Tab, Tabs, CheckBox, TextArea, Grommet } from 'grommet';
import { error } from 'util';
import TextInputWithState from './common/TextInputWithState';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import styled from 'styled-components';

var start = undefined;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const noBorderTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        }
    }
});

const Root = styled.div`
  //background-color: #01A982;
  //box-sizing: border-box;
  //padding: ${10 * 2}px;
  //min-height: 100vh;

  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const Column = styled.div`
  width: 100%;
  background-color: #FAFAFA;
  border-radius: 5px;
  padding: ${10 * 2}px;
  border: solid #DADADA 2px;
`;
export class ActionCentre extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.state = {
            isSaved: false,
            loaded: false,
            update: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            tabindex: 0,
            quickactions: [],
            promotedactions: [],
            /*            isMain: true,*/
            type: null,
            typeid: null,
            selectedQuickAction: {},
            /*disableName: false,*/
            openActionDeleted: false,
            deletedAction: "",
            sitedataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        this.onDragEndQA = this.onDragEndQA.bind(this);
        this.onDragEndPA = this.onDragEndPA.bind(this);
    }

    onDragEndQA(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId != result.destination.droppableId) {
            //Move item to PA
            if (result.destination.droppableId == "droppablePromoted") {

                //delete from QA
                let quickactions = Array.from(this.state.quickactions);
                const [removed] = quickactions.splice(result.source.index, 1);
                removed.promoted = true;

                //Add to PA
                let promotedactions = Array.from(this.state.promotedactions);
                promotedactions.splice(result.destination.index, 0, removed);
                //Set Priority
                quickactions = quickactions.map((item, index) => { item.priority = index + 1; return item; });
                promotedactions = promotedactions.map((item, index) => { item.priority = index + 1; return item; });
                //console.log("lol2", quickactions, promotedactions);
                this.setState({ quickactions, promotedactions });
            }
            else {
                //Move item to QA
                //delete from PA
                let promotedactions = Array.from(this.state.promotedactions);
                const [removed] = promotedactions.splice(result.source.index, 1);
                removed.promoted = false;

                //Add to QA
                let quickactions = Array.from(this.state.quickactions);
                quickactions.splice(result.destination.index, 0, removed);
                //Set Priority
                quickactions = quickactions.map((item, index) => { item.priority = index + 1; return item; });
                promotedactions = promotedactions.map((item, index) => { item.priority = index + 1; return item; });
                this.setState({ quickactions, promotedactions });
            }

        }
        else if (result.source.droppableId == "droppable") {
            let quickactions = reorder(
                this.state.quickactions,
                result.source.index,
                result.destination.index
            );
            //Set Priority
            quickactions = quickactions.map((item, index) => { item.priority = index + 1; return item; });
            this.setState({ quickactions });
        }
        else if (result.source.droppableId == "droppablePromoted") {
            let promotedactions = reorder(
                this.state.promotedactions,
                result.source.index,
                result.destination.index
            );
            //Set Priority
            promotedactions = promotedactions.map((item, index) => { item.priority = index + 1; return item; });
            this.setState({ promotedactions });
        }
    }
    onDragEndPA(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const promotedactions = reorder(
            this.state.promotedactions,
            result.source.index,
            result.destination.index
        );

        this.setState({ promotedactions });
        this.updatePriority();
    }
    updatePriority() {
        this.setState(prevState => ({
            quickactions: prevState.quickactions.map((item, index) => { item.priority = index + 1; return item; }),
            promotedactions: prevState.promotedactions.map((item, index) => { item.priority = index + 1; return item; })
        }));
    }
    handleEditQA(item) {
        //event.preventDefault();
        console.log('*Edit* started on ', item);
        this.props.history.push(constants.getSiteLevelRoute() + '/actioncentre/' + item.id);
    }
    handleEditPA(item) {
        //event.preventDefault();
        console.log('*Edit* started on ', item);
        this.props.history.push(constants.getSiteLevelRoute() + '/promotedaction/' + item.id);
    }
    handleNewQA() {
        //event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/actioncentre/0');
    }
    handleNewPA() {
        //event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/promotedaction/0');
    }
    handleRemove(ri) {
        this.state.quickactions.map((item, index) => {
            if (ri.id === item.id) { item.deleted = true; }
        });
        this.state.promotedactions.map((item, index) => {
            if (ri.id === item.id) { item.deleted = true; }
        });
        this.updatePriority();
        this.onCancelDelete();
        this.handleSave();
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getSiteAction(this.props.match.params.locid)
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        const id = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        if (this.props.location.state) {
            this.setState({ tabindex: this.props.location.state.tabindex });
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }

        if (!this.state.cancel && !_.isEqual(start, this.props.sitedata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }

    }
    componentWillReceiveProps(props) {
        console.log("noload", props, this.props.sitedata);
        if (props.sitedata) {
            this.setState({ quickactions: props.sitedata.quickActions.filter(q => !q.deleted && !q.promoted && this.QuickActionVisibility(q.defaultType, props.sitedata) === "block") });
            this.setState({ promotedactions: props.sitedata.quickActions.filter(q => !q.deleted && q.promoted && this.QuickActionVisibility(q.defaultType, props.sitedata) === "block") });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //if (this.props.sitedata && this.state.quickactions.length == 0 && this.props.sitedata.quickActions.length != 0) {
        //    //this.joinQuickActions();
        //    this.setState({ quickactions: this.props.sitedata.quickActions });
        //}
    }

    QuickActionVisibility(type, site = false) {
        const sitedata = site ? site : this.props.sitedata;
        let retvalue = "none";
        switch (type) {
            case "LOCATEPEOPLE":
                if (sitedata.meridianEnabled && this.context.feature.find(f => f.name == "siteFindAFriend").isEnabled) {
                    retvalue = "block";
                }
                break;
            case "REPORTISSUE":
                if (sitedata.reportIssueEnabled) {
                    retvalue = "block";
                }
                break;
            //case "Book a Desk": //My Bookings
            //    if (this.props.sitedata.bookADeskEnabled || this.props.sitedata.bookARoomEnabled) {
            //        retvalue = "block";
            //    }
            //    break;
            case "BOOKSPACE": //Book a Room
                if ((this.context.feature.find(f => f.name == "roomBooking").isEnabled || this.context.feature.find(f => f.name == "deskBooking").isEnabled) && (sitedata.bookARoomEnabled || sitedata.bookADeskEnabled)) {
                    retvalue = "block";
                }
                break;
            case "BOOKINGS":
                if ((this.context.feature.find(f => f.name == "roomBooking").isEnabled || this.context.feature.find(f => f.name == "deskBooking").isEnabled) && (sitedata.bookARoomEnabled || sitedata.bookADeskEnabled)) {
                    retvalue = "block";
                }
                break;
            case "MAP":
                if ((sitedata.disableArubaMeridian || sitedata.meridianEnabled) && this.context.feature.find(f => f.name == "siteMap").isEnabled) {
                    retvalue = "block";
                }
                break;
            case "ASSETS":
                if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled) {
                    retvalue = "block";
                }
                break;
            case "SERVICES":
                if (true) {
                    retvalue = "block";
                }
                break;
            case "SPACES":
                // Always available as per story 5618
                //if (this.props.sitedata.meridianEnabled && (this.context.feature.find(f => f.name == "siteMap").isEnabled || this.context.feature.find(f => f.name == "siteWayFinding").isEnabled)) {
                if (true) {
                    retvalue = "block";
                }
                break;
            case "INFO":
                if (true) {
                    retvalue = "block";
                }
                break;
            case "FACILITIES":
                // Always available as per story 5618
                //if (this.props.sitedata.meridianEnabled && (this.context.feature.find(f => f.name == "siteMap").isEnabled || this.context.feature.find(f => f.name == "siteWayFinding").isEnabled)) {
                if (true) {
                    retvalue = "block";
                }
                break;
            default:
                retvalue = "block";
        }
        return retvalue;
    }

    ClearErrorMessages() {
    }

    handleSave() {
        const sitedefault = this.props.sitedata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ disabledButton: true });
        if (errorsno == 0) {
            this.splitQuickActions();
            console.log('Save: OK', sitedefault);
            //this.QAValues();
            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/home');
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });
        }
    }

    handleCancel() {
        //this.splitQuickActions();

        if (!this.state.cancel && !_.isEqual(start, this.props.sitedata)) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitedata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
                            this.props.history.push(constants.getSiteLevelRoute() + '/overview');
                        else
                            this.props.history.push(constants.getSiteLevelRoute() + '/home');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-ConfigurationEdit Error', error);
                    if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
                        this.props.history.push(constants.getSiteLevelRoute() + '/overview');
                    else
                        this.props.history.push(constants.getSiteLevelRoute() + '/home');
                });
        }
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        }
        else {
            this.setState({ update: true });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onOpenDeleteConfirm(item) {
        this.setState({ deletedAction: item, openActionDeleted: true })
    }
    onCancelDelete() {
        this.setState({ openActionDeleted: false, deletedAction: "" });
    }

    onActive(idx) {
        this.setState({ tabindex: idx });
    }

    splitQuickActions() {
        this.props.sitedata.quickActions = [];
        var copyQA = [...this.state.quickactions];
        var copyPA = [...this.state.promotedactions];
        var copy = copyPA.concat(copyQA);
        //var copy = [...this.state.quickactions];

        //Validation needed

        //New or Modified if exists
        if (this.state.selectedQuickAction !== null) {
            if (this.state.selectedQuickAction.id === "0") {
                //copy.push(this.state.selectedQuickAction);
                var entry = { ...this.state.selectedQuickAction };
                entry.foreignId = this.state.typeid;
                console.log("FOREIGN", entry);
                copy.push(entry);
            }
            else {
                const index = copy.findIndex(item => item.id === this.state.selectedQuickAction.id);
                copy[index] = this.state.selectedQuickAction;
            }
        }

        copy.map((item, i) => {
            item.locationId = this.props.sitedata.id;
            this.props.sitedata.quickActions.push(item);
        });
        this.setState({ quickactions: copy.filter(qa => !qa.deleted && !qa.promoted), promotedactions: copy.filter(pa => !pa.deleted && pa.promoted) });
    }

    checkImageForPromotedActions(item) {
        if (item.useImage && item.imageUrl) {
            return !item.transparentImage ? true : false;
        }
        return false;
    }

    defaultOrderQAs() {
        let bookIndex = 0;
        this.state.quickactions.map((e, i) => {
            e.priority = i + 1;
            if (e.name == "Book a Space") bookIndex = i + 1;
        });
        this.state.quickactions.map((e, i) => {
            if (e.name == "My Bookings") {
                e.priority = bookIndex;
            }
        });
        this.state.quickactions.sort(function (a, b) { return a.priority - b.priority; });
        this.state.quickactions.map((e, i) => e.priority = 0);
        this.state.quickactions.map((e, i) => e.priority = i + 1);
    }

    renderQuickActionsTab() {
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        return (
            <Box>
                <Box pad={{ vertical: 'small' }} gap="small">
                    <Box direction="row" justify="end">
                        <Box>
                            <StyledButton typeSave label="Create New" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleNewQA.bind(this)} disabled={this.state.disabledButton} />
                        </Box>
                    </Box>
                </Box>
                <Box pad={{ "bottom": "small" }} >
                    <DragDropContext onDragEnd={this.onDragEndQA} >
                        <Root id="root">
                            <Column>
                                <Box direction="row">
                                    <b>{this.props.organisationdata.appType != "Campus" ? "Quick Action" : "Action"}</b>
                                    <Box justify="center" alignSelf="center" pad={{ "right": "15px" }} margin={{ "left": "10px" }} >
                                        <a data-for="qa-tooltip" data-tip >
                                            <CircleInformation color="#000000" />
                                        </a>
                                        <ReactTooltip id="qa-tooltip" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                            <div>
                                                Quick actions appear as a tile on the client homescreen. Mandatory quick actions can't be removed <br />
                                                but can be disabled. To change the order and therefore priorty, drag and drop as required.<br />
                                                The order shown below will be reflected in the client app for active actions only.<br />
                                                {this.props.organisationdata.appType != "Campus" ? "To promote your action, drag it horizonatally to the promoted action column" : null}
                                            </div>
                                        </ReactTooltip>
                                    </Box>
                                </Box>
                                <Droppable droppableId="droppable">
                                    {(provided) =>
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef} style={{ height: "100%" }} >
                                            {this.state.quickactions.map((item, index) =>
                                                item.name != "Report Issue" ?
                                                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!(canedit1 || canedit2 || canedit3)}>
                                                        {(provided, snapshot) =>
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <StyledBox style1={(snapshot.isDragging) ? false : true} style1gray={(snapshot.isDragging) ? true : false}
                                                                    pad={{ "horizontal": "small" }} margin={{ "bottom": "small" }}>
                                                                    <Grommet theme={noBorderTheme} >
                                                                        <FormField style={{display: this.QuickActionVisibility(item.defaultType)}} key={index} >
                                                                            <Box pad="xsmall" direction="row" justify="between">
                                                                                <Box direction="row" width="100%">
                                                                                    <Box alignSelf="center" pad="small" /*width="10%"*/>
                                                                                        {
                                                                                            item.type.toLowerCase() === "default" ? (
                                                                                                <Box direction="row" >
                                                                                                    <Button icon={<Edit color="#333333" />}/*style={{ cursor: 'inherit' }}*/ onClick={() => this.handleEditQA(item)} />
                                                                                                    <Box width="35px"></Box>
                                                                                                </Box>) :
                                                                                                (<Box direction="row">
                                                                                                    <Button icon={<Edit color="#333333" />} onClick={() => this.handleEditQA(item)} />
                                                                                                    <Button icon={<Trash color="#333333" />} onClick={() => this.onOpenDeleteConfirm(item)} style={{ visibility: (constants.DEFAULT_QUICK_ACTIONS.includes(item.type)) ? "hidden" : constants.visible(canedit1 || canedit2 || canedit3) }} />
                                                                                                </Box>)
                                                                                        }
                                                                                    </Box>
                                                                                    <Box width="85%" alignSelf="center">
                                                                                        <Text>{item.name}</Text>
                                                                                        <Box /*pad={{ horizontal: "60px" }}*/>
                                                                                            <Text>{item.description}</Text>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box alignSelf="center" width="5%" align="end">
                                                                                    <CheckBox checked={item.active} disabled />
                                                                                </Box>
                                                                            </Box>
                                                                        </FormField>
                                                                    </Grommet>
                                                                </StyledBox>
                                                            </div>
                                                        }
                                                    </Draggable>
                                                    :
                                                    null
                                            )}
                                        </div>
                                    }
                                </Droppable>
                            </Column>
                            {this.props.organisationdata.appType != "Campus" &&
                                <Column>
                                    <Box direction="row">
                                        <b>Promoted Action</b>
                                        <Box justify="center" alignSelf="center" pad={{ "right": "15px" }} margin={{ "left": "10px" }} >
                                            <a data-for="pa-tooltip" data-tip >
                                                <CircleInformation color="#000000" />
                                            </a>
                                            <ReactTooltip id="pa-tooltip" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                                <div>
                                                    Promoted actions appear as buttons at the bottom of the homescreen.<br />
                                                    Up to 3 and a minimum of 2 promoted actions can be displayed at one time.<br />
                                                    The order shown below will be reflected in the client app for active actions only.
                                                </div>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    <Droppable droppableId="droppablePromoted">
                                        {(provided) =>
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef} style={{ height: "100%" }} >
                                                {this.state.promotedactions.map((item, index) =>
                                                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!(canedit1 || canedit2 || canedit3)}>
                                                        {(provided, snapshot) =>
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <StyledBox style1={(snapshot.isDragging) ? false : true} style1gray={(snapshot.isDragging) ? true : false}
                                                                    pad={{ "horizontal": "small" }} margin={{ "bottom": "small" }}>
                                                                    <Grommet theme={noBorderTheme} >
                                                                        <FormField style={{display: this.QuickActionVisibility(item.defaultType)}} key={index} >
                                                                            <Box pad="xsmall" direction="row" justify="between">
                                                                                <Box direction="row" width="100%">
                                                                                    <Box alignSelf="center" pad="small" /*width="10%"*/>
                                                                                        {
                                                                                            item.type.toLowerCase() === "default" ? (
                                                                                                <Box direction="row" >
                                                                                                    <Button icon={<Edit color="#333333" />}/*style={{ cursor: 'inherit' }}*/ onClick={() => this.handleEditQA(item)} />
                                                                                                    <Box width="35px"></Box>
                                                                                                </Box>) :
                                                                                                (<Box direction="row">
                                                                                                    <Button icon={<Edit color="#333333" />} onClick={() => this.handleEditQA(item)} />
                                                                                                    <Button icon={<Trash color="#333333" />} onClick={() => this.onOpenDeleteConfirm(item)} style={{ visibility: (constants.DEFAULT_QUICK_ACTIONS.includes(item.type)) ? "hidden" : constants.visible(canedit1 || canedit2 || canedit3) }} />
                                                                                                </Box>)
                                                                                        }
                                                                                    </Box>
                                                                                    <Box width="85%" >
                                                                                        <Text>{item.name}</Text>
                                                                                        <Box /*pad={{ horizontal: "60px" }}*/>
                                                                                            <Text>{item.description}</Text>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                                {false && this.checkImageForPromotedActions(item) &&
                                                                                    <Box justify="center" alignSelf="center" pad={{ "top": "5px", "right": "15px" }} >
                                                                                        <a data-for="pa-warning" data-tip >
                                                                                            <CircleInformation color="red" />
                                                                                        </a>
                                                                                        <ReactTooltip id="pa-warning" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                                                                            <div>
                                                                                                A non transparent image has been chosen to display with this action.<br />
                                                                                                Promoted actions do not support this image type. Edit this action, choose either a default icon<br />
                                                                                                from the dropdown list or add a white transparent PNG to display as a custom icon.<br />
                                                                                                Failing to do this will result in a misconfiguration and may affect the client UI/UX
                                                                                            </div>
                                                                                        </ReactTooltip>
                                                                                    </Box>}
                                                                                <Box alignSelf="center" width="5%" align="end">
                                                                                    <CheckBox checked={item.active} disabled />
                                                                                </Box>
                                                                            </Box>
                                                                        </FormField>
                                                                    </Grommet>
                                                                </StyledBox>
                                                            </div>
                                                        }
                                                    </Draggable>
                                                )}
                                            </div>
                                        }
                                    </Droppable>
                                </Column>}
                        </Root>
                    </DragDropContext>
                </Box>
            </Box>
        )
    }

    renderPromotedActionsTab() {
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        return (
            <Box>
                <Box pad={{ vertical: 'small' }} gap="small">
                    <Box direction="row" justify="between">
                        {/*<Text size="large">Quick Actions</Text>*/}
                        <Text>
                            To change the order of your promoted actions, drag and drop them as required. This will determine their position on the home screen of the mobile client app.<br />
                            The top 3 active actions will be displayed on the promoted action bar of your client. There must be at least 1 promoted action active for this to appear in the client.
                        </Text>
                        <Box>
                            <StyledButton typeSave label="Create New" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleNewPA.bind(this)} disabled={this.state.disabledButton} />
                        </Box>
                    </Box>
                </Box>
                <Box pad={{ "bottom": "small" }} >
                    <DragDropContext onDragEnd={this.onDragEndPA} >
                        <Droppable droppableId="droppable">
                            {(provided) =>
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef} >
                                    {this.state.promotedactions.map((item, index) =>
                                        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!(canedit1 || canedit2 || canedit3)}>
                                            {(provided, snapshot) =>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <StyledBox style1={(snapshot.isDragging) ? false : true} style1gray={(snapshot.isDragging) ? true : false}
                                                        pad={{ "horizontal": "small", "top": "small" }} margin={{ "bottom": "small" }}>
                                                        <Grommet theme={noBorderTheme} >
                                                            <FormField style={{
                                                                display: this.QuickActionVisibility(item.defaultType)
                                                            }} key={index} >
                                                                <Box pad="small" direction="row">
                                                                    <Box alignSelf="center" pad="small" width="10%">
                                                                        {
                                                                            item.type.toLowerCase() === "default" ? (
                                                                                <Box direction="row" >
                                                                                    <Button /*style={{ cursor: 'inherit' }}*/ onClick={() => this.handleEditPA(item)} >
                                                                                        <Edit color="#333333" />
                                                                                    </Button>
                                                                                    <Box width="35px"></Box>
                                                                                </Box>) :
                                                                                (<Box direction="row" gap="small">
                                                                                    <Button onClick={() => this.handleEditPA(item)} >
                                                                                        <Edit color="#333333" />
                                                                                    </Button>
                                                                                    <Button onClick={() => this.onOpenDeleteConfirm(item)} style={{ visibility: (constants.DEFAULT_QUICK_ACTIONS.includes(item.type)) ? "hidden" : constants.visible(canedit1 || canedit2 || canedit3) }}>
                                                                                        <Trash color="#333333" />
                                                                                    </Button>
                                                                                </Box>)
                                                                        }
                                                                    </Box>
                                                                    <Box width="85%" >
                                                                        <Text>{item.name}</Text>
                                                                        <Box /*pad={{ horizontal: "60px" }}*/>
                                                                            <Text>{item.description}</Text>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box alignSelf="center" width="5%" align="end">
                                                                        <CheckBox checked={item.active} disabled />
                                                                    </Box>
                                                                </Box>
                                                            </FormField>
                                                        </Grommet>
                                                    </StyledBox>
                                                </div>
                                            }
                                        </Draggable>
                                    )}
                                </div>
                            }
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>
        )
    }

    render() {
        const { tabindex, sitedataLoading, organisationdataLoading } = this.state;

        if (sitedataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        //permissions
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (this.state.quickactions.some(s => s.priority == 0)) {
            this.defaultOrderQAs();
        }
        this.state.quickactions.sort(function (a, b) { return a.priority - b.priority; });
        this.state.promotedactions.sort(function (a, b) { return a.priority - b.priority; });

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                <PopUpConfirm open={this.state.openActionDeleted} text={constants.confirmMessage("action")} onClose={() => this.onCancelDelete()} onDelete={() => this.handleRemove(this.state.deletedAction)} />
                {/* Configuration Header Area */}

                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Box direction="row" gap="small">
                        <Heading level='2'>{window.navigator.language.toLowerCase() === "en-us" ? "Action Center" : "Action Centre"}</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} >
                            <a
                                data-for="qapa"
                                data-tip >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="qapa" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                {this.props.organisationdata.appType != "Campus" ?
                                    <div>
                                        The action center controls the Quick and Promoted actions for your client app <br />
                                        Create, edit or remove custom actions here.
                                    </div>
                                    :
                                    <div>
                                        The action center controls the Quick actions for your client app<br />
                                        Quick actions appear as a tile on the client home screen. Mandatory quick actions cannot be removed but they can be disabled.<br />
                                        You can create a new custom action by selecting the create new button on the right hand side. Custom actions can be edited or removed at any time.<br />
                                        To change the order of your quick actions, drag and drop them as required. This will determine their position on the home screen of the mobile client app
                                    </div>
                                }
                            </ReactTooltip>
                        </Box>
                    </Box>
                    <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                        <StyledButton typeCancel style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save All" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ "padding-top": "0px" }}>
                    <form>
                        <Box>
                            {this.renderQuickActionsTab()}
                        </Box>
                    </form>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const siteId = ownProps.match.params.locid || ''; //from the path '/category/:categoryId'
    if (state.selectedSiteReducer.sitedata && siteId == state.selectedSiteReducer.sitedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        //console.log('**Initial value', start);
        return {
            sitedata: state.selectedSiteReducer.sitedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...CategoryAction, ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActionCentre));