import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const policiesReducer = (state = initialState.policiesReducer, action) => {
    switch(action.type) {
        case ActionType.GET_POLICIES_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                policiesdata: _.assign(action.policiesdata)
            };
        }


        default: { return state; }
    }
};



export default policiesReducer;