import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';


//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);


class OrgListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }

    componentDidMount() {
        this.onDTUpdated();
    }


    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (true) {
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                            //    element.style.cursor = 'text';
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Organizations</Heading>
                    {/*<Box direction="row" gap="small" >

                        <StyledButton typeSave label='Create New' style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) }} alignSelf="center" onClick={this.props.handleNew} />
                    </Box> */}
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} margin={{ "horizontal": "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <div ref={this.dtwrapper}>
                            <StyledBox>
                                {this.renderOrganisationsTable(this.props)}
                            </StyledBox>
                        </div>
                    </IconThemeContext.Extend>

                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderOrganisationsTable(props) {

        const COLUMNS = [
            //{
            //    property: 'id1',
            //    header: '',
            //    render: orgdata => !RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) ? <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box> 
            //        : 
            //            (<Box direction='row'>
            //                <Button plain disabled={props.deletedOrgIds.includes(orgdata.id)} onClick={evt => props.handleEdit(evt, orgdata.id)} icon={<Edit color="#333333"/>} />
            //                {/*<Button plain disabled={props.deletedOrgIds.includes(orgdata.id)} onClick={evt => props.handleDelete(evt, orgdata.id)} icon={<Trash color="#333333"/>} />*/}
            //            </Box>)
            //            ,
            //},
            {
                property: 'lcname',
                header: 'Organization Name',
                render: orgdata => <Box pad={{ "vertical": "small" }}><Text>{orgdata.name}</Text></Box>
            },
            {
                property: 'lcorg',
                header: 'Organization / App',
                render: orgdata => <Text>{orgdata.name}</Text>
            },
            {
                property: 'created',
                header: <Box justify="center" height="100%">Date Created</Box>,
                render: orgdata => <Text>{orgdata.created}</Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: locations => <Text hidden>{locations.id}</Text>,
            },
            //{
            //    property: 'delete',
            //    header: '',
            //    primary: true,
            //    render: orgdata =>
            //        <Box align="center">
            //            <Button plain disabled={props.deletedOrgIds.includes(orgdata.id) || !RB.isSuperUser()} onClick={evt => props.handleDelete(evt, orgdata.id)} icon={<Trash />} />
            //        </Box>
            //},
        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        //if (!RB.isSuperUser()) { //If not superuser, than delete option is removed
        //    removeColumn("delete");
        //}

        let DATA = [];

        if (props.organisationsdata[0] && props.organisationsdata[0].count != 0) {
            props.organisationsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.organisationsdata.forEach(ev => {
                if (ev.deleted == false) {
                    DATA.push({
                        ...ev,
                        lcname: ev.name ? ev.name.toLowerCase() : '',
                        lcorg: ev.name ? ev.name.toLowerCase() : '',
                        created: ev.createdAt.substr(0, 10)
                    })
                }
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.created.toLowerCase().includes(search) 
            );
        }

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcorg"
                }))}
                data={DATA}
                noLoadingMsg={this.props.organisationsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Organization Name' },
                //    { col: 'lcorg', label: 'Organization / App' },
                //    { col: 'created', label: 'Created Date' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}

export default OrgListTable;
