import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as NotificationAction from '../action/NotificationAction';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import SessionApi from '../api/SessionApi';
import ExhibitorApi from '../api/ExhibitorApi';
import VersionApi from '../api/VersionApi';
import UserNotificationApi from '../api/UserNotificationApi';
// eslint-disable-next-line
import validator from 'validator';
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, CircleInformation, Trash, Upload, FormDown, FormUp } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, DateInput, Select, Image, CheckBox, Grommet, Collapsible, Tabs, Tab, Stack, Meter, CheckBoxGroup, SelectMultiple } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import CustomOption from './common/CustomOption';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactQuill, { Quill } from 'react-quill';
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register({ 'modules/htmlEditButton': htmlEditButton });
import MetricsUserListTable from './MetricsUserListTable';
const green2 = "#01a982";
const yellow2 = "#fbcc2d";
const red2 = "#ff454f";
const grey2 = "#cccccc";

var start = undefined;
var base64 = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none"
    }
});

const customTheme3 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
});

export class NotificationEditContainer extends React.Component {
    constructor() {
        super();
        this.stamp = Date.now().toString();
        this.d = new Date();
        this.d.setMinutes(this.d.getMinutes() + 1, 0, 0);
        this.lnow = this.d.toISOString();
        this.whenFormCreated = this.convertDateFromUTC(this.lnow);
        this.confirmMessage = "";
        this.isTagsCopied = false;
        this.state = {
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: true,
            isSaveSuccessful: false,
            showDate: false,
            showPopUpSent: false,
            showPopUpScheduled: false,
            scheduleValue: "Send Now",
            scheduleId: "",
            typeValue: "",
            showConfirmPopUp: false,
            selectedSites: [],
            isDateTimeUpdated: false,
            saveButtonText: "Send",
            notificationdataLoading: true,
            sitesdataLoading: true,
            gotProps: false,
            apiErrorCounter: 0,
            apiErrorList: [],
            isExpanded: false,
            exhibitorsdata: [],
            exhibitorsdataDefault: [],
            sessionsdata: [],
            sessionsdataDefault: [],
            index: 0,
            sessionsFetched: false,
            sponsorsFetched: false,
            versionsdata: [],
            metricsLoading: true
        };
        this.platforms = [
            { name: "Android", code: "android", type: "plt" },
            { name: "iOS", code: "ios", type: "plt" }
        ];
        this.classifications = [
            { name: "HPE", code: "hpe", type: "cls" },
            { name: "Partner", code: "partner", type: "cls" },
            { name: "Other", code: "other", type: "cls" },
        ];
        this.attendees = [
            { name: "HPE", code: "hpe", type: "att" },
            { name: "Partner MAP", code: "partnermap", type: "att" },
            { name: "Management", code: "management", type: "att" },
            { name: "Guest", code: "guest", type: "att" },
            { name: "Staff", code: "staff", type: "att" },
            { name: "Partner", code: "partner", type: "att" },
            { name: "Aruba", code: "aruba", type: "att" },
            { name: "Sponsor", code: "sponsor", type: "att" },
            { name: "Speaker", code: "speaker", type: "att" },
            { name: "Customer", code: "customer", type: "att" },
        ];
        this.expression = "";
    }

    editorURLs = [];
    //Validating errors
    categoryName_err = "";
    categoryParent_err = "";
    schedule_err = "";
    date_err = "";
    time_err = "";
    type_err = "";
    title_err = "";
    subtitle_err = "";
    detail_err = "";
    image_err = "";
    sponsor_err = "";
    session_err = "";
    expression_err = "";

    ClearErrorMessages() {
        this.categoryName_err = "";
        this.categoryParent_err = "";
        this.schedule_err = "";
        this.date_err = "";
        this.time_err = "";
        this.type_err = "";
        this.title_err = "";
        this.subtitle_err = "";
        this.detail_err = "";
        this.image_err = "";
        this.sponsor_err = "";
        this.session_err = "";
        this.expression_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.notificationId;
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getNotificationAction(id)
            .then(response => this.setState({ notificationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Notification Error', error);
            });
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        VersionApi.getVersions()
            .then(resp => this.setState({ versionsdata: resp.map(v => ({ name: v.version, code: v.version, type: "ver" })).sort(this.sortVersion()), versionsLoading: false }));
    }

    componentWillReceiveProps(props) {
        console.log("cwrp", props);
        const { notificationdata, sitesdata } = props;
        //if (props.notificationdata && !this.state.utcdate) {
        //    // convert date from UTC
        //    if (props.notificationdata.id === "0") {
        //        props.notificationdata.schedule = /*this.convertDateFromUTC(this.now)*/this.now;
        //        //this.props.notificationdata.date = this.now;
        //    }
        //    this.setState({ utcdate: true });
        //}
        if (notificationdata && notificationdata.schedule) {
            if (!this.gotProps) {
                this.gotProps = true;
                this.value = this.convertDateFromUTC(notificationdata.schedule);
            }
            notificationdata.schedule = this.value;
            this.setState({ showDate: true, scheduleValue: "Schedule for another time", saveButtonText: "Schedule" });
        }
        if (notificationdata && sitesdata && !this.isTagsCopied && this.state.versionsLoading == false) {
            let list = [];
            sitesdata.forEach(s => {
                if (notificationdata.tags.includes(`loc:${s.code.toLowerCase()}`)) {
                    s.type = "loc";
                    list.push(s);
                }
            });
            this.platforms.forEach(s => {
                if (notificationdata.tags.includes(`plt:${s.code.toLowerCase()}`)) {
                    list.push(s);
                }
            });
            this.classifications.forEach(s => {
                if (notificationdata.tags.includes(`cls:${s.code.toLowerCase()}`)) {
                    list.push(s);
                }
            });
            this.attendees.forEach(s => {
                if (notificationdata.tags.includes(`att:${s.code.toLowerCase()}`)) {
                    list.push(s);
                }
            });
            let versions = notificationdata.tags.split(" ").filter(s => s.includes("ver:"));
            versions.forEach(v => {
                let version = this.state.versionsdata.find(ve => ve.code == v.substring(4));
                if (version)
                    list.push(version);
            });
            //this.state.versionsdata.forEach(s => {
            //    if (notificationdata.tags.includes(`ver:${s.code.toLowerCase()}`)) {
            //        list.push(s);
            //    }
            //});
            this.expression = notificationdata.tags;
            this.setState({ selectedSites: list });
            this.isTagsCopied = true;
        }
        if (notificationdata && notificationdata.type == "Session" && this.state.sessionsdata.length == 0 && !this.sessions) {
            this.sessions = true;
            this.fetchSessions();
            //SessionApi.getSessions()
            //    .then(sessionsdata => {
            //        let DATA = [];
            //        const weekday = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            //        sessionsdata.forEach(ev => {
            //            if (ev.sessionTimes && ev.sessionTimes.length > 0) {
            //                let name = ev.code;
            //                ev.sessionTimes.forEach(t => {
            //                    let subtitle = t.room + " | " + t.startDateTime.substring(11, 16) + " - " + t.endDateTime.substring(11, 16);
            //                    name += " - " + t.startDateTime + " - " + ev.title + " - " + weekday[t.dayOfWeek];
            //                    DATA.push({
            //                        sessionTimeId: t.id,
            //                        sessionId: ev.id,
            //                        name: name,
            //                        title: ev.title,
            //                        room: t.room,
            //                        subtitle: subtitle,
            //                        description: ev.description,
            //                        code: ev.code,
            //                        type: ev.type,

            //                        //lccode: ev.code ? ev.code.toLowerCase() : '',
            //                        //lctitle: ev.title ? ev.title.toLowerCase() : '',
            //                        //lcdescription: ev.description ? ev.description.toLowerCase() : '',
            //                        //lcroom: t.room.toLowerCase(),
            //                        //lcname: name ? name.toLowerCase() : '',
            //                        //view: false,
            //                        //schedule: false,
            //                        //attend: false
            //                    });
            //                })
            //            }
            //            else {
            //                DATA.push({
            //                    sessionTimeId: null,
            //                    sessionId: ev.id,
            //                    name: ev.title,
            //                    title: ev.title,
            //                    room: '',
            //                    subtitle: "",
            //                    description: ev.description,
            //                    code: ev.code,
            //                    type: ev.type,

            //                    //lccode: ev.code ? ev.code.toLowerCase() : '',
            //                    //lctitle: ev.title ? ev.title.toLowerCase() : '',
            //                    //lcdescription: ev.description ? ev.description.toLowerCase() : '',
            //                    //lcroom: '',
            //                    //lcname: ev.title ? ev.title.toLowerCase() : '',
            //                    //view: false,
            //                    //schedule: false,
            //                    //attend: false
            //                });
            //            }
            //        });
            //        this.setState({ sessionsdata: DATA, sessionsFetched: true });
            //    })
        }
        else if (notificationdata && notificationdata.type == "Sponsor" && this.state.exhibitorsdata.length == 0 && !this.sponsor) {
            this.sponsor = true;
            ExhibitorApi.getExhibitors()
                .then(exhibitorsdata => this.setState({ exhibitorsdata, sponsorsFetched: true, exhibitorsdataDefault: exhibitorsdata }));
        }
        console.log("DATE1: ", this.state.scheduleValue);
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.notificationdata && !_.isEqual(start, this.props.notificationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleValidation() {
        let errorsno = 0;
        const notificationdefault = this.props.notificationdata;
        this.ClearErrorMessages();
        this.setState({ validating: false, notification: "" });
        let selected = new Date(this.props.notificationdata.schedule ? this.props.notificationdata.schedule : this.whenFormCreated);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        console.log("datecmp", current, "\n", selected);
        if (this.state.scheduleValue == "") {
            errorsno++;
            this.schedule_err = "Schedule is required";
        }
        if (this.state.scheduleId == 1 && notificationdefault.schedule.substr(11, 5) === "aaaaa") {
            errorsno++;
            this.date_err = "Date is required";
        }
        if (this.state.showDate && this.state.isDateTimeUpdated && selected < current) {
            //issue with the selected date
            this.date_err = "Can't select date from past";
            this.time_err = "Can't select time from past";
        }
        //} else if (selected < current) {
        //    this.date_err = "Can't select date from past";
        //}
        if (this.state.scheduleId == 1 && notificationdefault.schedule.substr(0, 10) === "aaaaaaaaaa") {
            errorsno++;
            this.time_err = "Time is required";
        }
        //} else if (selected < current) {
        //    this.time_err = "Can't select date from past";
        //}
        if (!notificationdefault.type || notificationdefault.type == "") {
            errorsno++;
            this.type_err = "Type is required";
        }
        if (notificationdefault.type == "Sponsor" && !notificationdefault.exhibitorId) {
            errorsno++;
            this.sponsor_err = "Select a Sponsor";
        }
        if (notificationdefault.type == "Session" && !notificationdefault.sessionTimeId) {
            errorsno++;
            this.session_err = "Select a Session";
        }
        if (notificationdefault.title.trim().length == 0) {
            errorsno++;
            this.title_err = "Title is required";
        }
        if (notificationdefault.title.length > 30) {
            errorsno++;
            this.title_err = "Title can't be longer than 30 character";
        }
        if (notificationdefault.subtitle.trim().length == 0) {
            errorsno++;
            this.subtitle_err = "Subtitle is required";
        }
        if (notificationdefault.subtitle.length > 50) {
            errorsno++;
            this.subtitle_err = "Subtitle can't be longer than 50 character";
        }
        if (notificationdefault.message.trim().length == 0) {
            errorsno++;
            this.detail_err = "Detail is required";
        }
        if (notificationdefault.message.includes("http://")) {
            errorsno++;
            this.detail_err = constants.DETAIL_HTTP_ERROR;
        }
        for (var i = 0; i < this.editorURLs.length; i++) {
            if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY) && !this.editorURLs[i].startsWith(this.props.organisationdata.appURI.split(':')[0])) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
        }
        if (notificationdefault.message.length > 5000 && !notificationdefault.message.includes("<img src=")) {
            errorsno++;
            this.detail_err = "Detail can't be longer than 5000 character";
        }
        if (this.expression.length > 1000) {
            errorsno++;
            this.expression_err = "Expression can't be longer than 1000 character";
        }
        if (errorsno == 0) {
            // Ok, saving data
            this.confirmMessage = this.getPopUpMessage();
            //notificationdefault.schedule = this.state.showDate ? selected : null;
            this.setState({ showConfirmPopUp: true });
            //this.setState({ disabledButton: true });
            //let locId = '';
            //if (sessionStorage.getItem('locationId')) {
            //    locId = sessionStorage.getItem('locationId');
            //}
            //categorydefault.locationId = locId;
            //console.log('Save: OK', categorydefault);
            //this.props.action.saveCategoryAction(categorydefault)
            //    .then((response) => {
            //        this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
            //        //this.props.history.push('/categories');
            //    }).catch(error => {
            //        console.log('*DEBUG*-Save CategoryEdit Error', error);
            //        this.setState({ disabledButton: false, notification: constants.DATA_ERROR });
            //    });
        }
        else {
            this.setState({ validating: true, /*notification: constants.FORM_ERROR*/ });
        }
    }
    handleSave() {
        //this.onClose();
        var notificationdefault = this.props.notificationdata;

        if (this.state.newimage) {
            notificationdefault.imageContent = base64;
            base64 = null;
        }

        let orgId = '';
        if (sessionStorage.getItem('organisationId')) {
            orgId = sessionStorage.getItem('organisationId');
        }
        //notificationdefault.locationId = orgId;
        var tags = "";
        this.state.selectedSites.forEach(item => {
            tags += `loc:${item.code} || `;
        });
        tags = tags.slice(0, -4);
        notificationdefault.tags = this.expression; //tags;
        let selected;
        if (notificationdefault.schedule) {
            selected = this.convertDateToUTC(notificationdefault.schedule);
        }

        notificationdefault.schedule = this.state.showDate ? selected : null;

        console.log('Save: OK', notificationdefault);
        this.props.action.saveNotificationAction(notificationdefault)
            .then((response) => {
                //this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                //this.props.history.push('/categories');
                return response.json()
                    .then((json) => {
                        if (response.ok) {
                            this.onClose(true);
                        }
                        else {
                            if (json.hasOwnProperty("modelState")) {
                                let keys = Object.keys(json.modelState);

                                if (keys.length > 0) {
                                    this.error_msg = json.modelState[keys[0]][0];
                                }
                            }
                            this.setState({ showConfirmPopUp: false, isSaved: true, isSaveSuccessful: false });
                        }
                    })
                //if (response.ok == false) {
                //    this.setState({ showConfirmPopUp: false, isSaved: true, isSaveSuccessful: false });
                //} else {
                //    this.onClose();
                //}
            }).catch(error => {
                console.log('*DEBUG*-Save NotificationEdit Error', error);
                this.setState({ disabledButton: false, /*notification: constants.DATA_ERROR*/ });
            });
    }
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.notificationdata.imageUrl = null;
        this.props.notificationdata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
            //return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.notificationdata) /*&& !(!this.state.isDateTimeUpdated && start.schedule != this.props.notificationdata.schedule)*/) {
            this.setState({ cancel: true });
        }
        else {
            //const Id = this.props.notificationdata.id;
            //console.log("Restoring:", Id);
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                this.props.history.push(constants.getOrgLevelRoute() + '/notifications');
            }
            //this.props.action.getNotificationAction(Id)
            //    .then(() => {
            //        if (this.nextLoc) {
            //            this.props.history.push(this.nextLoc);
            //        } else {
            //            this.props.history.push('/notifications');
            //        }
            //    }).catch(error => {
            //        console.log("*DEBUG*-NotificationEdit Error", error);
            //        this.props.history.push('/notifications');
            //    });
        }
    }
    updateSchedule(option) {
        console.log("updateSchedule", option.selected);
        let toShowDate = option.selected == 1 ? true : false;
        if (toShowDate) {
            //If send now we leave it as it is (null)
            this.props.notificationdata.schedule = this.whenFormCreated;
            this.setState({ saveButtonText: "Schedule" });
        } else {
            this.props.notificationdata.schedule = null;
            this.setState({ saveButtonText: "Send" });
        }

        this.schedule_err = "";
        this.date_err = "";
        this.time_err = "";
        this.setState({ showDate: toShowDate, scheduleValue: option.option, scheduleId: option.selected });
    }
    updateType(option) {
        let needToUpdateAudience = false;
        let copy = [];
        if (this.props.notificationdata.type == "Session" && option.target.value != "Session") {
            needToUpdateAudience = true;
            //remove attendee and classificaiton
            copy = [...this.state.selectedSites];
            copy = copy.filter(i => i.type !== "att" && i.type !== "cls" );

            this.expression = copy.reduce((acc, curr, currentIndex, array) => currentIndex != array.length - 1 ? (acc + `${curr.type}:${curr.code} OR `) : (acc + `${curr.type}:${curr.code}`), "");
            this.expression = this.expression.toLowerCase();
        }
        this.props.notificationdata.type = option.target.value;
        //Reset needed when you change from Session
        this.props.notificationdata.exhibitorId = null;
        this.props.notificationdata.sessionId = null;
        this.props.notificationdata.sessionTimeId = null;
        if (option.target.value == "Session" && this.state.sessionsdata.length == 0) {
            this.fetchSessions();
            //SessionApi.getSessions()
            //    .then(sessionsdata => {
            //        let DATA = [];
            //        const weekday = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            //        sessionsdata.forEach(ev => {
            //            if (ev.sessionTimes && ev.sessionTimes.length > 0) {
            //                let name = ev.code;
            //                ev.sessionTimes.forEach(t => {
            //                    let subtitle = t.room + " | " + t.startDateTime.substring(11, 16) + " - " + t.endDateTime.substring(11, 16);
            //                    name += " - " + t.startDateTime + " - " + ev.title + " - " + weekday[t.dayOfWeek];
            //                    DATA.push({
            //                        sessionTimeId: t.id,
            //                        sessionId: ev.id,
            //                        name: name,
            //                        title: ev.title,
            //                        room: t.room,
            //                        subtitle: subtitle,
            //                        description: ev.description,
            //                        code: ev.code,
            //                        type: ev.type,

            //                        //lccode: ev.code ? ev.code.toLowerCase() : '',
            //                        //lctitle: ev.title ? ev.title.toLowerCase() : '',
            //                        //lcdescription: ev.description ? ev.description.toLowerCase() : '',
            //                        //lcroom: t.room.toLowerCase(),
            //                        //lcname: name ? name.toLowerCase() : '',
            //                        //view: false,
            //                        //schedule: false,
            //                        //attend: false
            //                    });
            //                })
            //            }
            //            else {
            //                DATA.push({
            //                    sessionTimeId: null,
            //                    sessionId: ev.id,
            //                    name: ev.title,
            //                    title: ev.title,
            //                    room: '',
            //                    subtitle: "",
            //                    description: ev.description,
            //                    code: ev.code,
            //                    type: ev.type,

            //                    //lccode: ev.code ? ev.code.toLowerCase() : '',
            //                    //lctitle: ev.title ? ev.title.toLowerCase() : '',
            //                    //lcdescription: ev.description ? ev.description.toLowerCase() : '',
            //                    //lcroom: '',
            //                    //lcname: ev.title ? ev.title.toLowerCase() : '',
            //                    //view: false,
            //                    //schedule: false,
            //                    //attend: false
            //                });
            //            }
            //        });
            //        this.setState({ sessionsdata: DATA, sessionsFetched: true });
            //    })
        }
        if (option.target.value == "Sponsor" && this.state.exhibitorsdata.length == 0) {
            ExhibitorApi.getExhibitors()
                .then(exhibitorsdata => this.setState({ exhibitorsdata, sponsorsFetched: true, exhibitorsdataDefault: exhibitorsdata }));
        }
        this.setState({ typeValue: option.selected, selectedSites: needToUpdateAudience ? copy : this.state.selectedSites });
    }
    updateTitle(e) {
        this.title_err = "";
        this.props.notificationdata.title = e.target.value;
        if (e.target.value.length > 30) {
            this.title_err = "Title can't be longer than 30 character";
        }
        this.setState({ valdiation: true });
    }
    updateSubtitle(e) {
        this.subtitle_err = "";
        this.props.notificationdata.subtitle = e.target.value;
        if (e.target.value.length > 50) {
            this.subtitle_err = "Subtitle can't be longer than 50 character";
        }
        this.setState({ valdiation: true });
    }
    convertDateToUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() + (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("To UTC F:", date, "T:", udate);
        return udate;
    }
    createDateTime(date, time) {
        if (date.length != 10) {
            date = "aaaaaaaaaa";
        }
        if (time.length != 5) {
            time = "aaaaa";
        }
        // const newDateTime = date + "T" + time + ":00.000Z";
        const newDateTime = date + "T" + time + ":00.000Z";
        return newDateTime;
    }
    //updateDateValue(evt) {
    //    this.date_err = "";

    //    this.props.notificationdata.schedule = this.createDateTime(evt.target.value, this.props.notificationdata.schedule.substr(11, 5));
    //    console.log("DATE: 2B", evt.target.value," ||| " , this.props.notificationdata.schedule);
    //    let selected = new Date(this.props.notificationdata.schedule);
    //    let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
    //    selected = this.convertDateToUTC(selected);
    //    current = this.convertDateToUTC(current);
    //    console.log("datecmp", current, "\n", selected);
    //    if (selected < current) {
    //        this.date_err = "Can't select date from past";
    //    } else {
    //        this.time_err = "";
    //    }
    //    this.setState({ isDateTimeUpdated: true });
    //}
    updateDate(evt) {
        this.props.notificationdata.schedule = this.createDateTime(evt.value.substr(0, 10), this.props.notificationdata.schedule.substr(11, 5));
        console.log("DATE: 1A", evt.value.substr(0, 10), " ||| ", this.props.notificationdata.schedule);
        let selected = new Date(this.props.notificationdata.schedule);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        console.log("datecmp", current, "\n", selected);
        if (selected < current) {
            this.date_err = "Can't select date from past";
        } else {
            this.date_err = "";
        }
        this.setState({ isDateTimeUpdated: true });

    }
    updateTimeValue(evt) {
        this.time_err = "";
        this.props.notificationdata.schedule = this.createDateTime(this.props.notificationdata.schedule.substr(0, 10), evt.target.value);
        let selected = new Date(this.props.notificationdata.schedule);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        //console.log("datecmp", this.props.notificationdata.schedule, "\n", this.whenFormCreated.slice(0, -1));
        console.log("datecmp", current, "\n", selected);
        if (selected < current) {
            this.time_err = "Can't select time from past";
        } else {
            this.date_err = "";
        }
        this.setState({ isDateTimeUpdated: true });
    }
    getPopUpMessage() {
        console.log("getm");
        if (!this.props.notificationdata.schedule) {
            this.props.notificationdata.schedule = "";
        }
        const date = this.props.notificationdata.schedule.substr(0, 10);
        const time = this.props.notificationdata.schedule.substr(11, 5);
        const title = this.props.notificationdata.title;
        //const date = "<Date>";
        //const time = "<Time>";
        //const title = "<Title>";
        if (this.state.showDate) {
            if (this.props.notificationdata.type == "Session")
                return `Schedule this notification "${title}" to be sent on ${date} at ${time}. This notification will be sent to the users with the rights to view the session.`;
            else if (this.expression.length == 0)
                return `Schedule this notification "${title}" to be sent on ${date} at ${time}. This notification will be sent to all users.`;
            else
                return `Schedule this notification "${title}" to be sent on ${date} at ${time}. This notification will be sent to all users that meet the targeted audience criteria.`;

            //return `Schedule this notification ${title} to be sent on ${date} at ${time}. This notification will be sent to all employees.`;
            return `Schedule this notification "${title}" to be sent on ${date} at ${time}. This notification will be sent to ${this.state.selectedSites.filter(t => t.type == "loc").length == 0 ? "ALL users" : `all users in ${this.state.selectedSites.filter(t => t.type == "loc").map(s => { return " " + s.name; })}`}.`;
        } else {
            if (this.props.notificationdata.type == "Session")
                return `This notification "${title}" will be sent now. This notification will be sent to the users with the rights to view the session.`;
            else if (this.expression.length == 0)
                return `This notification "${title}" will be sent now. This notification will be sent to all users.`;
            else
                return `This notification "${title}" will be sent now. This notification will be sent to all users that meet the targeted audience criteria.`;


            return `This notification "${title}" will be sent now. This notification will be sent to ${this.state.selectedSites.filter(t => t.type == "loc").length == 0 ? "ALL users" : `all users in ${this.state.selectedSites.filter(t => t.type == "loc").map(s => { return " " + s.name; })}`}.`;
        }
    }
    convertDateFromUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() - (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("From UTC F:", date, "T:", udate);
        return udate;
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === "string") {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    onClose(redirect= false) {
        //this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (redirect) {
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                this.props.history.push(constants.getOrgLevelRoute() + '/notifications');
            }
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleValidation();
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.notificationdata.message = editor.getHTML();
        if (editor.getLength() > 5000) {
            this.detail_err = "Details can't be longer than 5000 character";
        }
        else {
            this.detail_err = "";
        }
        this.setState({ validating: !this.state.validating });
        //console.log("HTML_EDITOR", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getText()); // plain text
        //console.log("HTML_EDITOR", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }
    filterSites(enabled) {
        if (this.props.notificationdata.type == "Session" || this.props.notificationdata.type == "Sponsor") {
            return enabled;
        }
        return true;
    }

    onActive = (index) => {
        if (this.state.metricsLoading)
            UserNotificationApi.getInbox(this.props.notificationdata.id)
                .then(resp => this.setState({ metricsdata: resp, metricsLoading: false }));
        this.setState({ index: index });
    }

    fetchSessions() {
        SessionApi.getSessions()
            .then(sessionsdata => {
                let DATA = [];
                const weekday = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                sessionsdata.forEach(ev => {
                    if (ev.sessionTimes && ev.sessionTimes.length > 0) {
                        ev.sessionTimes.forEach(t => {
                            let name = ev.code;
                            let startDate = constants.convertDate(t.startDateTime, -t.utcOffset);
                            let subtitle = t.room + " | " + t.startDateTime.substring(11, 16) + " - " + t.endDateTime.substring(11, 16);
                            name += " - " + startDate + " - " + ev.title + " - " + weekday[t.dayOfWeek];
                            DATA.push({
                                sessionTimeId: t.id,
                                sessionId: ev.id,
                                name: name,
                                title: ev.title,
                                room: t.room,
                                subtitle: subtitle,
                                description: ev.description,
                                code: ev.code,
                                type: ev.type,
                                startDateTime: t.startDateTime
                                //lccode: ev.code ? ev.code.toLowerCase() : '',
                                //lctitle: ev.title ? ev.title.toLowerCase() : '',
                                //lcdescription: ev.description ? ev.description.toLowerCase() : '',
                                //lcroom: t.room.toLowerCase(),
                                //lcname: name ? name.toLowerCase() : '',
                                //view: false,
                                //schedule: false,
                                //attend: false
                            });
                        })
                    }
                    else {
                        DATA.push({
                            sessionTimeId: null,
                            sessionId: ev.id,
                            name: ev.title,
                            title: ev.title,
                            room: '',
                            subtitle: "",
                            description: ev.description,
                            code: ev.code,
                            type: ev.type,
                            startDateTime: ""
                            //lccode: ev.code ? ev.code.toLowerCase() : '',
                            //lctitle: ev.title ? ev.title.toLowerCase() : '',
                            //lcdescription: ev.description ? ev.description.toLowerCase() : '',
                            //lcroom: '',
                            //lcname: ev.title ? ev.title.toLowerCase() : '',
                            //view: false,
                            //schedule: false,
                            //attend: false
                        });
                    }
                });
                this.setState({ sessionsdata: DATA, sessionsFetched: true, sessionsdataDefault: DATA });
            });
    }

    updateSegmentAudience(s, type) {
        var copy = [...this.state.selectedSites];
        if (this.state.selectedSites.find(item => item.code == s.code && item.type == type)) {
            copy = copy.filter(i => i.type !== type || i.code !== s.code);
        } else {
            /////
            //classification mutually exclusive
            if (type == "cls") {
                //remove if selected
                copy = copy.filter(i => i.type !== "cls");
            }
            /////
            copy.push({ /*id: s.id,*/ name: s.name, code: s.code, type: type });
        }
        this.expression = copy.reduce((acc, curr, currentIndex, array) => currentIndex != array.length - 1 ? (acc + `${curr.type}:${curr.code} OR `) : (acc + `${curr.type}:${curr.code}`), "");
        this.expression = this.expression.toLowerCase();
        this.setState({ selectedSites: copy });
    }

    updateSegmentAudienceSelect(list, type) {
        var copy = [...this.state.selectedSites];
        //clear
        copy = copy.filter(i => i.type !== type);
        //rebuild
        list.forEach(s => {
            copy.push({ name: s.name, code: s.code, type: type });
        });
        this.expression = copy.reduce((acc, curr, currentIndex, array) => currentIndex != array.length - 1 ? (acc + `${curr.type}:${curr.code} OR `) : (acc + `${curr.type}:${curr.code}`), "");
        this.expression = this.expression.toLowerCase();
        this.setState({ selectedSites: copy });
    }

    renderGeneral() {
        const { sitesdata, notificationdata } = this.props;
        let selected = this.props.notificationdata.schedule ? new Date(this.props.notificationdata.schedule) : new Date();
        selected = this.convertDateToUTC(selected);
        const list = sitesdata.filter(f => f.notificationsEnabled && f.active && this.filterSites(f.sessionsEnabled));

        const sdate = notificationdata.schedule ? notificationdata.schedule.substr(0, 10) : "";
        const stime = notificationdata.schedule ? notificationdata.schedule.substr(11, 5) : "";
        let typeOptions = ["Notification", "News", "Security", "Meet And Greet", "Space"].concat(this.props.organisationdata.sessionsEnabled ? ["Session", "Sponsor"] : []);

        return (
            <Box>
                <Box pad={{ vertical: "small" }}>
                    <Text size="large">Content Details</Text>
                    <Text>All fields marked with an * are mandatory. When scheduling notifications, please select an appropriate time in UTC for all.</Text>
                </Box>
                <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }}>
                    <Box direction="row-responsive" gap="large">
                        <Box width="300px">
                            <FormField label="Schedule *" error={this.schedule_err}>
                                <Select options={["Send Now", "Schedule for another time"]} value={this.state.scheduleValue} onChange={option => this.updateSchedule(option)} />
                            </FormField>
                        </Box>

                        {this.state.showDate &&
                            <Box direction="row-responsive" gap="small">
                                <FormField label="Date" error={this.date_err}>
                                    {/*<TextInput type="date" onChange={e => this.updateDateValue(e)} defaultValue={sdate} />*/}
                                    <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} onChange={e => this.updateDate(e)} value={sdate} readOnly format="dd/mm/yyyy" />
                                </FormField>
                                <Box width={{ "max": "200px" }}>
                                    <FormField label="Time" error={this.time_err}>
                                        <TextInput style={{height: "34px"}} type="time" onChange={e => this.updateTimeValue(e)} defaultValue={stime} />
                                    </FormField>
                                </Box>
                                <FormField label="UTC">
                                    <TextInput value={this.convertDateToUTC(this.props.notificationdata.schedule).substr(0, 16).replace("T", " ")} readOnly />
                                </FormField>
                                <Box margin={{ "top": "medium" }} justify="center" align="center">
                                    <a data-for="info" data-tip>
                                        <CircleInformation />
                                    </a>
                                    <ReactTooltip id="info" place="top" type="dark" effect="solid" arrowColor="transparent">
                                        <span>Scheduled notifications will be sent to all relevant locations at this date/time in the UTC timezone</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>
                        }
                    </Box>

                    <Box direction="row-responsive" gap="large" align="start">
                        <Box width="300px">
                            <FormField label="Type *" error={this.type_err}>
                                <Select options={typeOptions} disabled={[3]} value={notificationdata.type} onChange={option => this.updateType(option)} />
                            </FormField>
                        </Box>
                        {notificationdata.type == "Sponsor" &&
                            <Box>
                            <FormField label="Sponsor *" error={this.sponsor_err}>
                                <Select
                                    disabled={!this.state.sponsorsFetched}
                                    placeholder="Select a Sponsor"
                                    options={this.state.exhibitorsdata.sort(constants.byPropertyCalled())}
                                    labelKey="name"
                                    value={this.state.exhibitorsdataDefault.find(e => e.id == notificationdata.exhibitorId)}
                                    onChange={v => {
                                        this.sponsor_err = "";
                                        notificationdata.exhibitorId = v.option.id;
                                        this.setState({ validating: true });
                                    }}
                                    onSearch={(text) => {
                                        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                        const exp = new RegExp(escapedText, 'i');
                                        this.setState({ exhibitorsdata: this.state.exhibitorsdataDefault.filter((o) => exp.test(o.name)) });
                                    }}
                                    onClose={() => this.setState({ exhibitorsdata: this.state.exhibitorsdataDefault })}
                                />
                                </FormField>
                            </Box>
                        }

                        {notificationdata.type == "Session" && <Box>
                            <FormField error={this.session_err} label={
                                <Box direction="row" gap="xsmall" align="center">
                                    <Box>Session *</Box>
                                    <Box data-for="session_info" data-tip>
                                        <CircleInformation size="small" />
                                        <ReactTooltip id="session_info" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                            <span>If a specific session is chosen, only users who have permissions to view that session will recieve the notification.</span>
                                        </ReactTooltip>
                                    </Box>
                                </Box>}>
                                <Select
                                    disabled={!this.state.sessionsFetched}
                                    placeholder="Select a Session"
                                    options={this.state.sessionsdata.sort(constants.multipleSortObject(["startDateTime", "title"]))}
                                    labelKey="name"
                                    /*valueKey={{ key: 'sessionTimeId', reduce: true }}*/
                                    value={this.state.sessionsdataDefault.find(s => s.sessionId == notificationdata.sessionId && s.sessionTimeId == notificationdata.sessionTimeId)}
                                    onChange={evt => {
                                        this.session_err = "";
                                        notificationdata.sessionTimeId = evt.option.sessionTimeId;
                                        notificationdata.sessionId = evt.option.sessionId;
                                        this.setState({ validating: true });
                                    }}
                                    onSearch={(text) => {
                                        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                        const exp = new RegExp(escapedText, 'i');
                                        this.setState({ sessionsdata: this.state.sessionsdataDefault.filter((o) => exp.test(o.name)) });
                                    }}
                                    onClose={() => this.setState({ sessionsdata: this.state.sessionsdataDefault })}
                                />
                            </FormField>
                        </Box>}
                    </Box>
                    <Box>
                        <FormField label="Title *" error={this.title_err}>
                            <TextInput onChange={e => this.updateTitle(e)} defaultValue={notificationdata.title} />
                        </FormField>
                    </Box>

                    <Box >
                        <FormField label="Subtitle *" error={this.subtitle_err}>
                            <TextInput onChange={e => this.updateSubtitle(e)} defaultValue={notificationdata.subtitle} />
                        </FormField>
                    </Box>

                    <Grommet theme={customTheme}>
                        <FormField label="Details *" error={this.detail_err} data-bounds="html_editor">
                            <ReactQuill
                                theme="snow"
                                bounds={`[data-bounds="html_editor"]`}
                                scrollingContainer="html"
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                        ['blockquote', 'code-block'],
                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                        [{ 'direction': 'rtl' }],                         // text direction
                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                        ['link', 'image'],
                                        ['clean']                                         // remove formatting button
                                    ],
                                    clipboard: {
                                        matchVisual: false,
                                    },
                                    htmlEditButton: {
                                        debug: true, // logging, default:false
                                        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                                        okText: "Ok", // Text to display in the OK button, default: Ok,
                                        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                                        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                                        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                                        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                                        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                                        editorModules: {} // The default mod
                                    }
                                }}
                                onChange={this.handleEditorChange}
                                value={notificationdata.message} />
                        </FormField>
                    </Grommet>
                    <Grommet theme={customTheme}>
                        <FormField label="Image" label={
                            <Box direction="row" gap="xsmall" align="center">
                                <Box>Image</Box>
                                <Box data-for="img_info" data-tip>
                                    <CircleInformation size="small" />
                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <span>Please select a brand approved image to display with your notification. <br />
                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>
                        } >
                            <Grommet theme={customTheme3}>
                                <Box direction="row" gap="small">
                                    <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                        <FormField label="" error={this.image_err}>
                                            <Box direction="row" justify="center" >
                                                {this.state.newimage ?
                                                    <Box alignSelf="center">
                                                        {this.shortcut()}
                                                    </Box>
                                                    :
                                                    (notificationdata.imageUrl == "" || notificationdata.imageUrl == null) ?
                                                        <Box height="75px" justify="center">
                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />}
                                                                onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                        </Box>
                                                        :
                                                        <Box justify="center">
                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                            <Image height="75" src={`${notificationdata.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                        </Box>
                                                }
                                            </Box>
                                        </FormField>
                                    </StyledBox>
                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={((notificationdata.imageUrl == "" || notificationdata.imageUrl == null) && !this.state.newimage)}>
                                            <Trash />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grommet>
                        </FormField>
                    </Grommet>

                    {false && <FormField contentProps={{ width: "fit-content" }} label={
                        <Box direction="row" gap="xsmall" align="center">
                            <Box>Tags *</Box>
                            <Box data-for="tags_info" data-tip>
                                <CircleInformation size="small" />
                                <ReactTooltip id="tags_info" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                    <span>Tag expressions can contain all boolean operator, such as AND (&&), OR (||) and NOT (!). They can also contain parentheses
                                        <br />
                                        Individual users can be specified with the usr tag or the eml tag, for example usr:partner or eml:partner@test.com
                                        <br />
                                        If a session is chosen then the tag expression is limited to 3 tag elements, otherwise it is limited to 5 tag elements.</span>
                                </ReactTooltip>
                            </Box>
                        </Box>}>
                        <Box direction="row" gap="medium">
                            {notificationdata.type == "Session" && <Box>
                                <Box direction="row" gap="medium">
                                    <FormField label="Attendee Type" contentProps={{ border: false }}>
                                        <Box direction="row">
                                            <Box>
                                                <CheckBox label="HPE" />
                                                <CheckBox label="Partner MAP" />
                                                <CheckBox label="Management" />
                                                <CheckBox label="Guest" />
                                                <CheckBox label="Staff" />
                                            </Box>
                                            <Box>
                                                <CheckBox label="Partner" />
                                                <CheckBox label="Aruba" />
                                                <CheckBox label="Sponsor" />
                                                <CheckBox label="Speaker" />
                                                <CheckBox label="Customer" />
                                            </Box>
                                        </Box>
                                    </FormField>
                                    <FormField label="Classification" contentProps={{ border: false }} >
                                        <CheckBox label="HPE" />
                                        <CheckBox label="Partner" />
                                    </FormField>
                                </Box>
                            </Box>}
                            <FormField label="Platform" contentProps={{ border: false }} >
                                <CheckBox label="Android" />
                                <CheckBox label="iOS" />
                            </FormField>
                            <FormField label="App Version" contentProps={{ border: false }} >
                                <CheckBox label="v1" />
                                <CheckBox label="v2" />
                                <CheckBox label="v3" />
                            </FormField>
                        </Box>
                    </FormField>}

                </StyledBox>

                {false && <Box pad={{ vertical: "small" }}>
                    <Text size="large">Target Audience</Text>
                    <Text>Target your notification to audiences that need to receive them using the tag options listed.</Text>
                    {/*<Text>If you don't specify a selection below, the notification will be sent to all client users.</Text> */}
                </Box>}

                <StyledBox style1 gap="small" pad={{ "horizontal": "small", top: "small", bottom: this.state.isExpanded? "small" : "0px"}} width="80%" margin={{ "bottom": "medium" }}>
                    <Box width="fit-content" onClick={evt => this.setState({ isExpanded: !this.state.isExpanded })}>
                        {this.state.isExpanded &&
                            <Box direction="row" gap="xsmall" align="center">
                                <FormDown />
                                <Text weight="bold">Segment Audience</Text>
                                <Box data-for="segment_info" data-tip>
                                    <CircleInformation size="small" />
                                    <ReactTooltip id="segment_info" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                        <span>Target your notification to audiences that need to receive them using the tag options available.</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>}
                        {!this.state.isExpanded &&
                            <Box direction="row" gap="xsmall" align="center">
                                <FormUp />
                                <Text weight="bold">Segment Audience</Text>
                                <Box data-for="segment_info" data-tip>
                                    <CircleInformation size="small" />
                                    <ReactTooltip id="segment_info" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                        <span>Target your notification to audiences that need to receive them using the tag options available.</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>}
                    </Box>
                    <Collapsible open={this.state.isExpanded}>
                        <Box>
                            <FormField width="100%" label={
                                <Box direction="row" gap="xsmall" align="center">
                                    <Box>Expression</Box>
                                    <Box data-for="exp_info" data-tip>
                                        <CircleInformation size="small" />
                                        <ReactTooltip id="exp_info" place="right" type="dark" effect="solid" arrowColor="transparent" >
                                            <span>Tag expressions support common Boolean operators such as AND (&&), OR (||), and NOT (!); they can also contain parentheses.
                                                <br />
                                                Tag expressions using only OR operators can reference 20 tags; expression with AND operators but no OR operators can reference 10 tags; otherwise, tag expressions are limited to 6 tags.
                                                <br />
                                                Individual users can be specified with the usr tag or the eml tag, for example usr:partner@test.com or eml:partner@test.com.
                                                {notificationdata.type == "Session" ? <span><br />If a session is chosen then custom tag expression cannot be used. </span> : ""}
                                            </span>
                                        </ReactTooltip>
                                    </Box>
                                </Box>} error={this.expression_err}>
                                <TextInput
                                    value={this.expression}
                                    onChange={evt => {
                                        this.expression = evt.target.value;
                                        if (this.expression.length > 1000)
                                            this.expression_err = "Expression can't be longer than 1000 character";
                                        else
                                            this.expression_err = "";

                                        this.setState({ validating: true })
                                    }}
                                    //readOnly
                                //value={this.state.selectedSites.reduce((acc, curr) => acc + `loc:${curr.code} OR `, "")}
                                /*onChange={e => this.updateTitle(e)} defaultValue={notificationdata.title}*/
                                />
                            </FormField>
                        </Box>
                        <Grid columns={{ count: 3, size: "auto" }} gap="small">
                            <FormField label="Site">
                                <SelectMultiple
                                    value={list.filter(p => this.state.selectedSites.filter(item => item.type == "loc").map(l => l.code).includes(p.code))}
                                    labelKey="name"
                                    valueKey="code"
                                    placeholder="Select Site"
                                    options={list/*.filter(f => f.notificationsEnabled && f.active && this.filterSites(f.sessionsEnabled))*/}
                                    onChange={({ value }) => this.updateSegmentAudienceSelect(value, "loc")}
                                />
                            </FormField>
                            <FormField label="Platform">
                                <SelectMultiple
                                    value={this.platforms.filter(p => this.state.selectedSites.filter(item => item.type == "plt").map(l => l.code).includes(p.code))}
                                    labelKey="name"
                                    valueKey="code"
                                    placeholder="Select Platform"
                                    options={this.platforms}
                                    onChange={({ value }) => this.updateSegmentAudienceSelect(value, "plt")}
                                />
                            </FormField>
                            <FormField label="App Version">
                                <SelectMultiple
                                    //showSelectedInline
                                    sortSelectedOnClose={false}
                                    value={this.state.versionsdata.filter(p => this.state.selectedSites.filter(item => item.type == "ver").map(l => l.code).includes(p.code))}
                                    labelKey="name"
                                    valueKey="code"
                                    placeholder="Select Version"
                                    options={this.state.versionsdata}
                                    onChange={({ value }) => this.updateSegmentAudienceSelect(value, "ver")}
                                />
                            </FormField>

                            {this.props.organisationdata.sessionsEnabled && <FormField label="Attendee Type" contentProps={{ border: false }}>
                                {this.attendees.map(s =>
                                    <CheckBox label={s.name} checked={this.state.selectedSites.find(item => item.code == s.code && item.type == "att")} onChange={evt => {
                                        //var copy = [...this.state.selectedSites];
                                        //if (this.state.selectedSites.find(item => item.code == s.code)) {
                                        //    copy = copy.filter(i => i.code !== s.code);
                                        //} else {
                                        //    copy.push({ name: s.name, code: s.code, type: "type" });
                                        //}
                                        //this.expression = copy.reduce((acc, curr, currentIndex, array) => currentIndex != array.length - 1 ? (acc + `${curr.type}:${curr.code} OR `) : (acc + `${curr.type}:${curr.code}`), "");
                                        //this.setState({ selectedSites: copy });
                                        this.updateSegmentAudience(s, "att");

                                    }} />
                                )}
                            </FormField>}

                            {this.props.organisationdata.sessionsEnabled && <FormField label="Classification" contentProps={{ border: false }}>
                                <CheckBoxGroup
                                    options={this.classifications}
                                    labelKey="name"
                                    valueKey="code"
                                    //value={this.state.selectedSites.filter(item => item.type == "hpe").map(h=> h.code)}
                                    value={this.state.selectedSites.map(item => (item.type == "cls" && item.code))}
                                    onChange={evt => {
                                        this.updateSegmentAudience(evt.option, "cls");
                                    }}
                                />
                                {/*this.classifications*/[].map(s =>
                                    <CheckBox label={s.name} checked={this.state.selectedSites.find(item => item.code == s.code)} onChange={evt => {
                                        //var copy = [...this.state.selectedSites];
                                        //if (this.state.selectedSites.find(item => item.code == s.code)) {
                                        //    copy = copy.filter(i => i.code !== s.code);
                                        //} else {
                                        //    copy.push({ name: s.name, code: s.code, type: "hpe" });
                                        //}
                                        //this.expression = copy.reduce((acc, curr, currentIndex, array) => currentIndex != array.length - 1 ? (acc + `${curr.type}:${curr.code} OR `) : (acc + `${curr.type}:${curr.code}`), "");
                                        //this.setState({ selectedSites: copy });
                                        this.updateSegmentAudience(s, "cls");
                                    }} />
                                )}
                            </FormField>}
                        </Grid>
                    </Collapsible>
                </StyledBox>
            </Box>
        );
    }

    renderCard(title, value, note, value2 = "") {
        let color = "R";
        if (title == "User Read") {
            if (value > 0 && value == value2)
                color = "G";
            else if (value == 0)
                color = "A";
        }
        else if (title == "Device Sent") {
            if (value > 0 && value == value2)
                color = "G";
            else if (value == 0)
                color = "A";
        }
        else {
            if (value > 0)
                color = "G";
            else if (value == 0)
                color = "A";
        }
        //let color = ((title != "User Read" && value > 0) || (title == "User Read" && value > 0 && value == value2)) ? "G" : value == 0 ? "A" : "R";
        return (
            <StyledBox style1 border={false} width="100%" direction="column">
                <Box border="bottom" direction="row" justify="between" height="35px">
                    <Box pad={{ "left": "small" }} justify="end">
                        <Text style={{ color: "grey" }} size="large">{title}</Text>
                    </Box>
                    <Box border={false} align="end" justify="center" pad={{ "right": "xsmall" }}>
                        {
                            color == "G" ?
                                (<Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />) :
                                color == "A" ?
                                    (<Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />)
                                    :
                                    (<Image width="30" height="30" src="./images/Red.svg" title={constants.RED_LABEL} />)
                        }
                    </Box>
                </Box>

                <Box border={false} align="center" justify="center" height="220px" pad={{ "top": "small", "bottom": "small" }}>
                    <Stack anchor="center" align="center" justify="center" fill>
                        <Box fill margin={{ "top": "none", "bottom": "none" }}>
                            <Meter type="circle" width="50%" style={{ "max-height": "210px" }} alignSelf="center" justify="center"
                                thickness="medium" background={false ? (red2) : (grey2)}
                                values={[
                                    {
                                        //value: value ? value : 75,
                                        value: 100,
                                        label: "Healthy",
                                        //color: value > 0 ? green2 : yellow2,
                                        color: color == "G" ? green2 : color == "A" ? yellow2 : red2,
                                    }
                                ]}
                            />
                        </Box>
                        <Box border={false} justify="center" align="center" pad={{ "bottom": "7px" }}>
                            <Text size="55px" weight="bold" textAlign="center">
                                {value > -1 ? value : "N/A"}
                            </Text>
                        </Box>
                    </Stack>
                </Box>
                {false && <Box border={false} align="left" height="45px" justify="end" pad={{ "left": "xsmall" }}>
                    <Text size="small">{note}</Text>
                </Box>}
                {false && <Box border="top" height="40px" justify="center" align="end">
                    <Stack>
                        <Button
                            margin={{ "top": "none", "bottom": "none", "left": "none", "right": "small" }}
                            style={{ color: green2, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                            size="large"
                            plain="true"
                            label="View"
                            //icon={<FormNext color="green" />}
                            reverse="true"
                        />
                    </Stack>
                </Box>}
            </StyledBox>

        );
    }

    sortVersion(property = "code", order = "asc") {
        return function (a, b) {
            let av = a[property] ? a[property].split(".") : [];
            let bv = b[property] ? b[property].split(".") : [];
            let diff = av.length - bv.length;
            if (diff >= 0) {
                let i = 0;
                for (i; i < bv.length; i++) {
                    if (av[i]*1 < bv[i]*1) {
                        return order == "asc" ? -1 : 1;
                    }
                    else if (av[i]*1 > bv[i]*1) {
                        return order == "asc" ? 1 : -1;
                    }
                    else {
                        //they are equal so check next round
                    }
                }
                if (i == bv.length)
                    return 1;
            }
            else {
                let i = 0;
                for (i; i < av.length; i++) {
                    if (av[i]*1 < bv[i]*1) {
                        return order == "asc" ? -1 : 1;
                    }
                    else if (av[i]*1 > bv[i]*1) {
                        return order == "asc" ? 1 : -1;
                    }
                    else {
                        //they are equal so check next round
                    }
                }
                if (i == av.length)
                    return -1;
            }
        };
    }

    render() {
        const { options, open, notification, sitesdataLoading, notificationdataLoading, sessionsFetched, sponsorsFetched } = this.state;
        const { sitesdata, notificationdata } = this.props;
        //notificationdata = this.props.notificationsdata.find(n => n.id == this.props.match.params.notificationId);
        if (sitesdataLoading || notificationdataLoading || !notificationdata) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        if (notificationdata && notificationdata.id != "0" && this.props.organisationdata && this.props.organisationdata.sessionsEnabled == false && (notificationdata.type == "Session" || notificationdata.type == "Sponsor")) {
            this.props.history.push(constants.getOrgLevelRoute() + '/notifications');
        }
        //else if (notificationdata && notificationdata.id != 0 && ((notificationdata.type == "Session" && !sessionsFetched) || (notificationdata.type == "Sponsor" && !sponsorsFetched))) {
        //    return (
        //        <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
        //    );
        //}
        let selected = this.props.notificationdata.schedule ? new Date(this.props.notificationdata.schedule) : new Date();
        selected = this.convertDateToUTC(selected);
        console.log("yayan", this.props, "\n UTC", this.lnow, "\n FROM UTC=Currenty", this.whenFormCreated, "\n Current TimeZone", new Date(this.whenFormCreated), "\n Prop", this.props.notificationdata.schedule, "\n Send", selected);
        const heading = notificationdata && (notificationdata.id != "0") ? 'Edit' : 'Create';

        const sdate = notificationdata.schedule ? notificationdata.schedule.substr(0, 10) : "";
        const stime = notificationdata.schedule ? notificationdata.schedule.substr(11, 5) : "";

        if (this.props.organisationdata) {
            Quill.import('formats/link').PROTOCOL_WHITELIST = ["http", "https", this.props.organisationdata.appURI.split(':')[0], "mailto"];
        }
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Notification - {heading} </Heading>
                    {(notificationdata && (notificationdata.id == "0" || this.state.index == 0)) && <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label={this.state.saveButtonText} onClick={this.handleValidation.bind(this)} disabled={this.state.disabledButton} />
                    </Box>}
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        {/*<Notification message={notification} />*/}
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : this.error_msg ? this.error_msg :"Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image."}
                            onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                        {/*220218<PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />*/}
                        {/*
                        <PopUpConfirm open={this.state.showPopUpScheduled} onClose={() => this.onClose()} header="Confirm Notification" text="Schedule this notification <Notification Title> to be sent on <Date> at <Time>. This notification will be sent to all employees." />
                        <PopUpConfirm open={this.state.showPopUpSent} onClose={() => this.onClose()} header="Confirm Notification" text="This notification <Notification Title> will be sent now. This notification will be sent to all employees." />
                        */}
                        <PopUpConfirm open={this.state.showConfirmPopUp} onClose={() => this.setState({ showConfirmPopUp: false })} header="Confirm Notification" text={this.confirmMessage} onDelete={() => {/*this.setState({ showConfirmPopUp: false });*/ this.handleSave(); }} />
                        {notificationdata && (notificationdata.id != "0") ?
                            <Tabs justify="start" activeIndex={this.state.index} onActive={this.onActive}>
                                <Tab title="General">
                                    {this.renderGeneral()}
                                </Tab>
                                <Tab title="Metrics">
                                    <Box pad={{ vertical: "small" }}>
                                        <Box gap="medium" width="100%" direction="row" alignContent="stretch">
                                            {this.renderCard("Device Audience", notificationdata.hasOwnProperty("device") ? notificationdata.device : -1, "Explanation text")}

                                            {this.renderCard("Device Sent", notificationdata.hasOwnProperty("success") ? notificationdata.success : -1, "Explanation text", notificationdata.device ? notificationdata.device : -1)}

                                            {this.renderCard("User Audience", notificationdata.hasOwnProperty("audience") ? notificationdata.audience : -1, "Explanation text")}

                                            {this.renderCard("User Read", notificationdata.hasOwnProperty("read") ? notificationdata.read : -1, "Explanation text", notificationdata.audience ? notificationdata.audience : -1)}

                                        </Box>
                                        {this.state.metricsLoading ?
                                            <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
                                            :
                                            <MetricsUserListTable
                                                metricsdata={this.state.metricsdata} //{[{ device: "N/A", version: "N/A", username: "fake", email: "fake@hpe.com", isRead: true }, { device: "N/A", version: "N/A", username: "fake2", email: "test@hpe.com", isRead: false }]}
                                            />}
                                    </Box>
                                </Tab>
                            </Tabs>
                            :
                            this.renderGeneral()
                        }
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const notificationId = ownProps.match.params.notificationId || ''; //from the path '/category/:categoryId'
    const organisationId = sessionStorage.getItem("organisationId");
    if ((state.selectedNotificationReducer.notificationdata && notificationId == state.selectedNotificationReducer.notificationdata.id) &&
        (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id)) {
        start = { ...state.selectedNotificationReducer.notificationdata };
        return {
            sitesdata: state.sitesReducer.sitesdata,
            notificationdata: state.selectedNotificationReducer.notificationdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
            //notificationsdata: state.notificationsReducer.notificationsdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...NotificationAction, ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationEditContainer));