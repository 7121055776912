import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as PlacemarkHistoryActionnn from '../action/PlacemarkHistoryAction';
import * as OrganisationAction from '../action/OrganisationAction';
import TestCenterApi from '../api/TestCenterApi';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, Video, Youtube, PhoneVertical, CircleInformation, Trash, Secure, Upload, MapLocation, Slack  } from 'grommet-icons';
import { Phone } from '../grommet-extras/PhoneIcon';
import { Yammer } from '../grommet-extras/YammerIcon';
// eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea, Tab, Tabs, Grommet } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PlacemarkHistoryListTable from './PlacemarkHistoryListTable';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import ReactQuill, { Quill } from 'react-quill';
import htmlEditButton from "quill-html-edit-button";
import { Deeplink } from './common/Deeplink';
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register({ 'modules/htmlEditButton': htmlEditButton });


var start = undefined;
var base64 = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none"
    }
});
const customTheme3 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
});

const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    //weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});
var cleaningSchedule = [
    { id: "HOURS1", name: "Every 1 hour" },
    { id: "HOURS2", name: "Every 2 hours" },
    { id: "HOURS3", name: "Every 3 hours" },
    { id: "HOURS4", name: "Every 4 hours" },
    { id: "HOURS5", name: "Every 5 hours" },
    { id: "HOURS6", name: "Every 6 hours" },
    { id: "HOURS7", name: "Every 7 hours" },
    { id: "HOURS8", name: "Every 8 hours" },
    { id: "HOURS9", name: "Every 9 hours" },
    { id: "HOURS10", name: "Every 10 hours" },
    { id: "HOURS11", name: "Every 11 hours" },
    { id: "HOURS12", name: "Every 12 hours" },
    { id: "HOURS24", name: "Everyday" },
];

export class PlacemarkEditContainer extends React.Component {

    static contextType = Context;
    constructor() {
        super();
        this.parentfull = [];
        this.categoryfull = [];
        this.moreinfo_err = [];
        for (let i = 0; i <= constants.MOREINFO_LINES; i += 1) this.moreinfo_err.push({ display: "", url: "" });
        this.newname = "";
        this.newparent = "";
        this.newparentId = "";
        this.newlocation = "";
        this.lastCleaningDate = new Date();
        this.lastCleaningDate.setHours(this.lastCleaningDate.getHours() - 1, 12, 10);
        this.uploadErrorText = "";

        this.state = {
            parents: [],
            options: [],
            value: "",
            parent: "",
            selimage: 0,
            isNewCategory: false,
            isSaved: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            isUploadError: false,
            occupancy: 0,
            frequency: "Every 2 hours",
            departments: "",
            amenities: [],
            categoriesdataLoading: true,
            locationsdataLoading: true,
            placemarkdataLoading: true,
            placemarkhistoriesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            coordinates: ""
        };
    }

    launchEnumOptions = [
        { label: 'External Browser', value: "External" },
        { label: 'Hosted In App', value: "InApp" },
        { label: 'In App Browser', value: "InAppBrowser" },
    ];

    editorURLs = [];

    //Validating fields error handling
    newname_err = "";
    parent_err = "";
    name_err = "";
    categoryid_err = "";
    email_err = "";
    phone_err = "";
    phone_help = '';
    twitter_err = '';
    linkedin_err = '';
    yammer_err = '';
    facebook_err = '';
    url_err = '';
    instagram_err = '';
    video_err = '';
    youtube_err = '';
    occupancy_err = '';
    image_err = '';
    slack_err = "";
    map_err = "";
    custom1_err = "";
    custom2_err = "";
    custom3_err = "";
    custom4_err = "";

    clearErrorMessages() {
        this.occupancy_err = "";
        this.newname_err = "";
        this.parent_err = "";
        this.name_err = "";
        this.categoryid_err = "";
        this.email_err = "";
        this.phone_err = "";
        this.phone_help = '';
        this.twitter_err = '';
        this.linkedin_err = '';
        this.yammer_err = '';
        this.facebook_err = '';
        this.url_err = '';
        this.instagram_err = '';
        this.video_err = '';
        this.youtube_err = '';
        this.image_err = "";
        this.slack_err = "";
        this.map_err = "";
        for (let i = 0; i <= this.moreinfo_err.length - 1; i += 1) {
            this.moreinfo_err[i].display = '';
            this.moreinfo_err[i].url = '';
        }
        for (let i = 0; i < 4; i += 1) {
            this[`custom${i + 1}_err`] = "";
        }
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === "string") {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    onUploadClose() {
        this.setState({ isUploadError: false });
    }

    componentDidUpdate(prevProps, prevState) {
        //Load categories
        if (this.props.categoriesdata && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
            //console.log(this.props.categoriesdata);
            this.categoryfull = this.props.categoriesdata.slice();
            //this.categoryfull = this.categoryfull.filter(item => item.level == 2);
            this.categoryfull.push({
                id: "0",
                name: "",
                parent: "",
                location: "",
                lpn: ""
            });
            var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
            var spi = this.categoryfull.indexOf(sp);
            this.categoryfull.splice(spi, 1);
            var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
            var i = this.categoryfull.indexOf(ri);
            this.categoryfull.splice(i, 1);
            var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
            var index = this.categoryfull.indexOf(info);
            this.categoryfull.splice(index, 1);
            var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
            var i2 = this.categoryfull.indexOf(ri2);
            this.categoryfull.splice(i2, 1);
            var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
            var index2 = this.categoryfull.indexOf(info2);
            this.categoryfull.splice(index2, 1);
            this.categoryfull.forEach(item => item.lpn = constants.APP_LPN(item.location, item.parent, item.name));
            this.categoryfull.sort((p1, p2) => { if (p1.lpn.toLowerCase() < p2.lpn.toLowerCase()) return -1; return 1; });
            //this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.categoryfull });
            //console.log("DidUpdate:", this.categoryfull);
        }
        //Load parent-location dictionary
        if (this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
            //console.log(this.props.locationsdata);
            this.parentfull = this.props.locationsdata.filter((item) => item.location != "").slice();
            this.parentfull.push({
                parent: "",
                location: ""
            });
            this.parentfull.forEach(function (item) {
                if (item.parent == "" && item.location == "") {
                    item.parentloc = "";
                }
                else if (item.parent != "" && item.location == "") {
                    item.parentloc = item.parent;
                }
                else if (item.parent == item.location) {
                    item.parentloc = item.location;
                }
                else {
                    item.parentloc = item.location + " / " + item.parent;
                }
            })
            this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
            this.setState({ parents: this.parentfull });
            //console.log("DidUpdate:", this.parentfull);
        }

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.placemarkId;
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PlacemarkEdit/Category Error', error);
            });
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PlacemarkEdit/Parent Error', error);
            });
        this.props.action.getPlacemarkAction(id)
            .then(response => this.setState({ placemarkdataLoading: false } , () => {
                if (this.props.placemarkdata && this.props.placemarkdata.name == "Testing Center") {
                    TestCenterApi.getTestCenter()
                        .then(color => this.setState({ color }, () => this.color = color));
                }
            }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Placemark Error', error);
            });
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getPlacemarkHistoriesAction()
            .then(response => this.setState({ placemarkhistoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemark History Error', error);
            });
        //fetch('https://hpepngmywpdemo.azurewebsites.net/tables/placemark?$apply=groupby((Department))')
        //    .then(response => response.json())
        //    .then(data => {
        //        this.setState({ departments: data.filter(f => f.department != null && f.department != "").map(d => d.department) })
        //    });
    }

    componentWillReceiveProps() {
        let amenities = [];
        if (this.props.placemarkdata) {
            amenities = this.props.placemarkdata.amenities.split("|").filter(a => a != "").sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            this.setState({ amenities: amenities });
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.placemarkdata && !_.isEqual(start, this.props.placemarkdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    updateNameValue(v) {
        this.props.placemarkdata.name = v.target.value;
    }
    updatePlacemarkTypeValue(v) {
        this.props.placemarkdata.placemarktype = v.target.value;
    }
    updateImageValue(v, nro, canReadOnly = false) {
        if (!canReadOnly) {
            v.preventDefault();
            this.props.placemarkdata.image = nro;
            this.setState({ selimage: nro })
        }
    }
    updateDetailValue(v) {
        this.props.placemarkdata.detail = v.target.value;
    }

    updateEmailValue(evt) { this.props.placemarkdata.email = evt.target.value };
    updatePhoneValue(evt) { this.props.placemarkdata.phone = evt.target.value };
    updateTwitterValue(evt) {
        this.props.placemarkdata.twitter = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.twitter_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.twitter_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateLinkedinValue(evt) {
        this.props.placemarkdata.linkedin = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.linkedin_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateYammerValue(evt) {
        this.props.placemarkdata.yammer = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.yammer_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.yammer_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateFacebookValue(evt) {
        this.props.placemarkdata.facebook = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.facebook_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.facebook_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateUrlValue(evt) {
        this.props.placemarkdata.url = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.url_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.url_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateInstagramValue(evt) {
        this.props.placemarkdata.instagram = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.instagram_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.instagram_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateVideoValue(evt) {
        this.props.placemarkdata.video = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.video_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.video_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateYoutubeValue(evt) {
        this.props.placemarkdata.youtube = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.youtube_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.youtube_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateMapValue(evt) {
        var coordinates = evt.target.value;
        if (coordinates != "") {
            if (coordinates != "" && !constants.REGEX.COORDINATES.test(coordinates)) {
                this.map_err = "Please ensure the coordinates are properly formed (ie: 53.2926521, -9.007333)";
            }
            else if (coordinates != "" && coordinates.length > 50) {
                this.map_err = constants.COORDINATES_LIMIT;
            }
            else {
                this.map_err = "";

                let lat = coordinates.substr(0, evt.target.value.indexOf(','));
                let p = lat.indexOf(".");
                if (p >= 0) {
                    let n = lat.substr(p + 1, lat.length).length;
                    lat = Number.parseFloat(lat).toFixed(n);
                }

                let lon = coordinates.split(" ")[1];
                let p2 = lon.indexOf(".");
                if (p2 >= 0) {
                    let n = lon.substr(p2 + 1, lon.length).length;
                    lon = Number.parseFloat(lon).toFixed(n);
                }

                this.props.placemarkdata.latitude = lat;
                this.props.placemarkdata.longitude = lon;
            }
        }
        else {
            this.map_err = "";
            this.props.placemarkdata.latitude = null;
            this.props.placemarkdata.longitude = null;
        }
        this.setState({ coordinates: coordinates });
    }
    updateSlackValue(evt) {
        this.props.placemarkdata.slack = evt.target.value;
        if (evt.target.value.length > 1024) {
            this.slack_err = constants.CONNECT_NOW_URL_ERR;
        }
        else {
            this.slack_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateCoordValue(v) {
        this.props.placemarkdata.coordinates = v.target.value;
        this.setState({ validating: !this.state.validating });
    }

    updateMoreInfoDisplayValue(evt, idx) {
        this.props.placemarkdata.moreinfo[idx].display = evt.target.value;
        if (evt.target.value.length > 30) {
            this.moreinfo_err[idx].display = "Dispay text can't be longer than 30 characters";
        }
        else {
            this.moreinfo_err[idx].display = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateMoreInfoUrlValue(evt, idx) {
        this.props.placemarkdata.moreinfo[idx].url = evt.target.value;
        if (this.props.placemarkdata.moreinfo[idx].url.length > 1024) {
            this.moreinfo_err[idx].url = constants.MORE_INFO_URL_ERR;
        }
        else this.moreinfo_err[idx].url = "";
        this.setState({ updated: true });
    }
    updateCategoryName(event) {
        this.props.placemarkdata.categoryid = this.state.options[event.selected].id;
        this.setState({
            value: event.option,
            selected: event.selected,
            options: this.categoryfull
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.placemarkdata.categoryid = (this.categoryfull.find((option) => option.name == event.option)).id;
        if (this.props.placemarkdata.category) {
            this.props.placemarkdata.category.id = this.props.placemarkdata.categoryid;
        };
        this.categoryid_err = "";
        if (event.option.length == 0) {
            this.categoryid_err = "Select an Option";
        }
    }
    updateNewCategoryName(event) {
        this.newname = event.target.value;
        if (this.newname.trim().length == 0) {
            this.newname_err = "Category Name is required";
        } else {
            this.newname_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateCategoryParent(event) {
        if (event.option == "No match found") {
            event.option = "";
        }
        this.newparent = this.parentfull[event.selected].parent;
        this.newparentId = this.parentfull[event.selected].id;
        this.newlocation = this.parentfull[event.selected].location;
        this.setState({
            parent: event.option,
            parents: this.parentfull
        });
        //this.newparent = (this.parentfull.find((option) => option.parentloc == event.option)).parent;
        //this.newparentId = (this.parentfull.find((option) => option.parentloc == event.option)).id;
        //this.newlocation = (this.parentfull.find((option) => option.parentloc == event.option)).location;
        this.parent_err = "";
        //if (event.option.trim().length == 0) {
        //    this.parent_err = "Select an Option";
        //}
    }

    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }

    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        //console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }
    }
    removeIcon() {
        this.props.placemarkdata.imageUrl = null;
        this.props.placemarkdata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }


    handleCancel() {
        this.saveNewCategory(false);
        if (!this.state.cancel && !_.isEqual(start, this.props.placemarkdata)) {
            this.setState({ cancel: true });
        }
        else {
            const placemarkId = this.props.placemarkdata.id;
            this.props.action.getPlacemarkAction(placemarkId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/placemarks');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-PlacemarkEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/placemarks');
                });
        }

    }

    handleSave() {
        const placemarkdefault = this.props.placemarkdata;
        //console.log('*DEBUG*-Started saving:', placemarkdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (!placemarkdefault.name) {
            placemarkdefault.name = "";
        }
        // if (placemarkdefault.name.trim().length == 0) {
        //     errorsno++;
        //     this.name_err = "Name is required";
        // }
        //newCategory
        if (this.state.isNewCategory) {
            if (this.newname.trim().length == 0) {
                errorsno++;
                this.newname_err = "Category Name is required"
            }
            if (this.props.categoriesdata.find(o => o.name.trim().toLowerCase() == this.newname.trim().toLowerCase())) {
                errorsno++;
                this.newname_err = "Category Name already exists";
            }
            //if (this.newparent.trim().length == 0) {
            //    errorsno++;
            //    this.parent_err = "Select an Option";
            //}
        }
        else {
            if (placemarkdefault.categoryid == 0 || !this.categoryfull.find((opt) => opt.id == placemarkdefault.categoryid)) {
                errorsno++;
                this.categoryid_err = "Select an Option";
            }
        }
        //if (placemarkdefault.detail.length > 1000) {
        //    errorsno++;
        //    this.detail_err = "Detail can't be longer than 1000 characters";
        //}
        if (placemarkdefault.detail.includes("http://")) {
            errorsno++;
            this.detail_err = constants.DETAIL_HTTP_ERROR;
        }
        for (var i = 0; i < this.editorURLs.length; i++) {
            if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY) && !this.editorURLs[i].startsWith(this.props.organisationdata.appURI.split(':')[0])) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
        }
        if (this.state.HTMLTextLenght > 5000) {
            errorsno++;
            this.detail_err = "Detail can't be longer than 5000 characters";
        }
        if (placemarkdefault.email) {
            let emails = placemarkdefault.email.split(",");
            if (emails.some(email => email.trim().length == 0 || !(validator.isEmail(email)))) {
                errorsno++;
                this.email_err = "E-mail is not correct";
            }
        }
        if (!placemarkdefault.phone) {
            placemarkdefault.phone = "";
        }
        if (placemarkdefault.phone) {
            if (placemarkdefault.phone.trim().length != 0 && !(constants.REGEX.PHONE.test(placemarkdefault.phone))) {
                errorsno++;
                this.phone_err = constants.PHONE_ERROR;
                this.phone_help = constants.PHONE_HELP;
            }
        }
        if (placemarkdefault.twitter) {
            if (placemarkdefault.twitter.trim().length != 0 && !(validator.isURL(placemarkdefault.twitter, constants.URL_OPTIONS))) {
                errorsno++;
                this.twitter_err = constants.URL_ERROR;
            }
            if (placemarkdefault.twitter.length > 1024) {
                errorsno++;
                this.twitter_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.linkedin) {
            if (placemarkdefault.linkedin.trim().length != 0 && !(validator.isURL(placemarkdefault.linkedin, constants.URL_OPTIONS))) {
                errorsno++;
                this.linkedin_err = constants.URL_ERROR;
            }
            if (placemarkdefault.linkedin.length > 1024) {
                errorsno++;
                this.linkedin_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.yammer) {
            if (placemarkdefault.yammer.trim().length != 0 && !(validator.isURL(placemarkdefault.yammer, constants.URL_OPTIONS))) {
                errorsno++;
                this.yammer_err = constants.URL_ERROR;
            }
            if (placemarkdefault.yammer.length > 1024) {
                errorsno++;
                this.yammer_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.facebook) {
            if (placemarkdefault.facebook.trim().length != 0 && !(validator.isURL(placemarkdefault.facebook, constants.URL_OPTIONS))) {
                errorsno++;
                this.facebook_err = constants.URL_ERROR;
            }
            if (placemarkdefault.facebook.length > 1024) {
                errorsno++;
                this.facebook_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.url) {
            if (placemarkdefault.url.trim().length != 0 && !(validator.isURL(placemarkdefault.url, constants.URL_OPTIONS))) {
                errorsno++;
                this.url_err = constants.URL_ERROR;
            }
            if (placemarkdefault.url.length > 1024) {
                errorsno++;
                this.url_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.instagram) {
            if (placemarkdefault.instagram.trim().length != 0 && !(validator.isURL(placemarkdefault.instagram, constants.URL_OPTIONS))) {
                errorsno++;
                this.instagram_err = constants.URL_ERROR;
            }
            if (placemarkdefault.instagram.length > 1024) {
                errorsno++;
                this.instagram_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.video) {
            if (placemarkdefault.video.trim().length != 0 && !(validator.isURL(placemarkdefault.video, constants.URL_OPTIONS))) {
                errorsno++;
                this.video_err = constants.URL_ERROR;
            }
            if (placemarkdefault.video.length > 1024) {
                errorsno++;
                this.video_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.youtube) {
            if (placemarkdefault.youtube.trim().length != 0 && !(validator.isURL(placemarkdefault.youtube, constants.URL_OPTIONS))) {
                errorsno++;
                this.youtube_err = constants.URL_ERROR;
            }
            if (placemarkdefault.youtube.length > 1024) {
                errorsno++;
                this.youtube_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (placemarkdefault.slack) {
            if (placemarkdefault.slack.trim().length != 0 && !(validator.isURL(placemarkdefault.slack, constants.URL_OPTIONS))) {
                errorsno++;
                this.slack_err = constants.URL_ERROR;
            }
            if (placemarkdefault.slack.length > 1024) {
                errorsno++;
                this.slack_err = constants.CONNECT_NOW_URL_ERR;
            }
        }
        if (this.state.coordinates != "" && !(constants.REGEX.COORDINATES.test(this.state.coordinates))) {
            errorsno++;
            this.map_err = constants.COORDINATES_ERROR;
        }
        else if (this.state.coordinates != "" && this.state.coordinates.length > 50) {
            errorsno++;
            this.map_err = constants.COORDINATES_LIMIT;
        }

        // more info validating 
        if (placemarkdefault.moreinfo) {
            placemarkdefault.moreinfo.map((info, idx) => {
                // soft-deleted lines not validating
                if (!info.deleted) {
                    if (info.display.trim().length == 0 && info.url.trim().length == 0) {
                        //OK, Save
                    }
                    else {
                        if (info.display.trim().length == 0) {
                            errorsno++;
                            this.moreinfo_err[idx].display = "Display Text is required";
                        }
                        if (info.display.length > 30) {
                            errorsno++;
                            this.moreinfo_err[idx].display = "Dispay text can't be longer than 30 characters";
                        }

                        if (info.url.substr(info.url.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(info.url)) {
                            errorsno++;
                            this.moreinfo_err[idx].url = constants.URL_ERROR;
                        }
                        if (info.url.substr(info.url.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(info.url)) {
                            errorsno++;
                            this.moreinfo_err[idx].url = constants.URL_ERROR_HTTPS_ONLY;
                        }
                        if (info.url.length > 1024) {
                            errorsno++;
                            this.moreinfo_err[idx].url = constants.MORE_INFO_URL_ERR;
                        }
                    }
                }
            });
            placemarkdefault.moreinfo = placemarkdefault.moreinfo.filter(info => info.display.trim().length != 0 || info.url.trim().length != 0);
        }

        for (let i = 0; i < 4; i++) {
            if (this.props.placemarkdata[`custom${i + 1}`] && this.props.placemarkdata[`custom${i + 1}`].length > 50) {
                errorsno++;
                this[`custom${i + 1}_err`] = "Custom Attribute can't be longer than 50 characters";
            }
        }

        if (errorsno == 0) {
            //Ok, saving data
            if (this.state.newimage) {
                this.props.placemarkdata.imageContent = base64;
                base64 = null;
            }

            this.setState({ disabledButton: true });
            this.saveNewCategory(true);
            let locId = '';
            if (sessionStorage.getItem('locationId')) {
                locId = sessionStorage.getItem('locationId');
            }
            placemarkdefault.locationId = locId;
            console.log('Saving:', placemarkdefault);
            this.props.action.savePlacemarkAction(placemarkdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    if (response.ok && this.color && this.state.color && (this.color != this.state.color)) {
                        TestCenterApi.saveTestCenter(this.state.color);
                    }
                    this.categoryfull = [];
                    this.props.action.getCategoriesAction().then(() => { /*this.props.history.push('/placemarks')*/ });
                    //this.props.history.push('/placemarks');
                }).catch(error => {
                    console.log('*DEBUG*-Save PlacemarkEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }
    saveNewCategory(isFromHandleSave) {
        const placemarkdefault = this.props.placemarkdata;
        if (this.state.isNewCategory) {
            // new category
            placemarkdefault.category = {};
            placemarkdefault.categoryid = "0";
            placemarkdefault.category.id = null;
            placemarkdefault.category.name = this.newname;
            placemarkdefault.category.parent = this.newparent;
            placemarkdefault.category.parentId = this.newparentId;
            placemarkdefault.category.location = this.newlocation;
            placemarkdefault.category.synced = false;
            placemarkdefault.category.deleted = false;
            placemarkdefault.category.locationId = sessionStorage.getItem("locationId");
            placemarkdefault.category.organizationId = sessionStorage.getItem("organisationId");
        } else {
            // selected category
            if (isFromHandleSave) {
                placemarkdefault.category = null;
            }

        }
    }
    handleAddItem(event) {
        event.preventDefault();
        if (!this.props.placemarkdata.moreinfo) {
            this.props.placemarkdata.moreinfo = [];
        }
        this.props.placemarkdata.moreinfo.push({ display: "", url: "", inApp: false });
        this.setState({ validating: !this.state.validating });
    }

    handleDelete(event, id) {
        this.props.placemarkdata.moreinfo.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }

    updateCheck(event, id) {
        this.props.placemarkdata.moreinfo[id].inApp = event.target.checked;
        this.setState({ validating: !this.state.validating });
    }

    updateAuthStatus(evt, idx) {
        this.props.placemarkdata.moreinfo[idx].urlAuth = !this.props.placemarkdata.moreinfo[idx].urlAuth;
        this.setState({ updated: true });
    };

    updateLaunchMode(evt, idx) {
        this.props.placemarkdata.moreinfo[idx].urlType = this.launchEnumOptions.find(e => e.label == evt.option).value;
        this.setState({ updated: true });
    };

    renderMoreInfo(infos) {
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        return (
            <Box gap="medium">
                {infos && infos.map((info, idx) => (
                    <Box direction="row" gap="small">
                        <StyledBox style1 key={idx} direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }}>
                            <Box width="250px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].display} label={<Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box>}>
                                        <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            <Box width="400px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].url} label={<Box direction="row" gap="xsmall" align="center"><Link />URL</Box>}>
                                        <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => this.updateMoreInfoUrlValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            {info.url.trim().substr(info.url.trim().length - 4).toLowerCase() == ".pdf" ?
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <Secure />
                                                Authentication Status:
                                            </Box>
                                            <Box>
                                                <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here .">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={["Authenticated", "Not Authenticated"]}
                                            value={info.urlAuth ? "Authenticated" : "Not Authenticated"}
                                            onChange={evt => this.updateAuthStatus(evt, idx)} />
                                    </FormField>
                                </Box>
                                :
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <PhoneVertical />
                                                Launch Mode:
                                            </Box>
                                            <Box>
                                                <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={this.launchEnumOptions.map(e => e.label)}
                                            defaultValue={!info.urlType ? info.urlType = "InAppBrowser" : ""}
                                            value={this.launchEnumOptions.find(e => e.value == info.urlType) ? this.launchEnumOptions.find(e => e.value == info.urlType).label : ""}
                                            onChange={evt => this.updateLaunchMode(evt, idx)} />
                                    </FormField>
                                </Box>}
                            <Box alignSelf="center" margin={{ "right": "small" }} >
                                {canedit1 &&
                                    <StyledButton icon={<Trash />} plain onClick={evt => this.handleDelete(evt, idx)} disabled={!canedit1} />}
                            </Box>
                        </StyledBox>
                    </Box>
                ))}
                <Box direction="row" gap="small">
                    <Box width="medium">
                        {canedit1 &&
                            <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={this.handleAddItem.bind(this)} disabled={!canedit1} />}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderMoreInfoV2(infos) {
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        return (
            <Box gap="medium" width="fit-content">
                {infos.length > 0 && <StyledBox style1 gap="small">
                    {infos && infos.map((info, idx) => (
                        <Box key={idx} direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }}>
                            <Box width="250px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].display} label={idx == 0 ? <Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box> : ""}>
                                        <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            <Box width="400px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].url} label={idx == 0 ? <Box direction="row" gap="xsmall" align="center"><Link />URL</Box> : ""}>
                                        <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => this.updateMoreInfoUrlValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            {info.url.trim().substr(info.url.trim().length - 4).toLowerCase() == ".pdf" ?
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        idx == 0 ?
                                            <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                <Box direction="row" gap="xsmall" justify="between">
                                                    <Secure />
                                                    Authentication Status:
                                                </Box>
                                                <Box>
                                                    <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here .">
                                                        <CircleInformation />
                                                    </a>
                                                    <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </Box> : ""}>
                                        <Select
                                            options={["Authenticated", "Not Authenticated"]}
                                            value={info.urlAuth ? "Authenticated" : "Not Authenticated"}
                                            onChange={evt => this.updateAuthStatus(evt, idx)} />
                                    </FormField>
                                </Box>
                                :
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        idx == 0 ?
                                            <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                <Box direction="row" gap="xsmall" justify="between">
                                                    <PhoneVertical />
                                                    Launch Mode:
                                                </Box>
                                                <Box>
                                                    <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                        <CircleInformation />
                                                    </a>
                                                    <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </Box> : ""}>
                                        <Select
                                            options={this.launchEnumOptions.map(e => e.label)}
                                            defaultValue={!info.urlType ? info.urlType = "InAppBrowser" : ""}
                                            value={this.launchEnumOptions.find(e => e.value == info.urlType) ? this.launchEnumOptions.find(e => e.value == info.urlType).label : ""}
                                            onChange={evt => this.updateLaunchMode(evt, idx)} />
                                    </FormField>
                                </Box>}
                            <Box alignSelf={idx == 0 ? "center" : ""} margin={{ "right": "small" }} >
                                {canedit1 &&
                                    <StyledButton icon={<Trash />} plain onClick={evt => this.handleDelete(evt, idx)} disabled={!canedit1} />}
                            </Box>
                        </Box>
                    ))}
                </StyledBox>}
                <Box direction="row" gap="small">
                    <Box width="medium">
                        {canedit1 &&
                            <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={this.handleAddItem.bind(this)} disabled={!canedit1} />}
                    </Box>
                </Box>
            </Box>
        )
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/placemarks');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.placemarkdata.detail = editor.getHTML();
        if (editor.getLength() - 1 > 5000) {
            this.detail_err = "Details can't be longer than 5000 characters";
        }
        else {
            this.detail_err = "";
        }
        this.setState({ HTMLTextLenght: editor.getLength() - 1 });
        //console.log("HTML_EDITOR", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getText()); // plain text
        //console.log("HTML_EDITOR", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }
    calculateNextCleaningDate(buildingSchedule, placemarkSchedule) {
        const buildingstart = 10;
        const buildingend = 18;
        const buildinghours = parseInt(buildingSchedule.match(/\d+/g).toString());
        const placemarkhours = parseInt(placemarkSchedule.match(/\d+/g).toString());
        var nextCleaningDate = new Date();
        var dif = nextCleaningDate.getHours() + 1 - buildingstart;
        if (buildinghours !== placemarkhours) {
            var toBeAdded = Math.ceil(dif / placemarkhours) * placemarkhours;
            if ((buildingstart + toBeAdded) > buildingend) {
                nextCleaningDate.setHours(24 + buildingstart, 0, 0, 0);
            } else {
                nextCleaningDate.setHours(buildingstart + toBeAdded, 0, 0, 0);
            }
        }
        else {
            var toBeAdded2 = Math.ceil(dif / buildinghours) * buildinghours;
            if ((buildingstart + toBeAdded2) > buildingend) {
                nextCleaningDate.setHours(24 + buildingstart, 0, 0, 0);
            } else {
                nextCleaningDate.setHours(buildingstart + toBeAdded2, 0, 0, 0);
            }
        }
        return nextCleaningDate;
    }
    calculateNextCleaningDatev2(placemarkSchedule, lastCleanDate) {
        const buildingstart = 10;
        const buildingend = 18;
        let endDate = new Date();
        endDate.setHours(buildingend, 0, 0, 0);
        let nextCleanDate = null;
        const placemarkhours = parseInt(placemarkSchedule.match(/\d+/g).toString());
        if (lastCleanDate) {
            var dummyDate = new Date(lastCleanDate);
            dummyDate.setHours(dummyDate.getHours() + placemarkhours);
            //console.log("dummy",dummyDate);
            if (dummyDate < endDate) {
                nextCleanDate = new Date(dummyDate);
            } else {
                //new day
                nextCleanDate = new Date();
                nextCleanDate.setHours(24 + buildingstart, lastCleanDate.getMinutes(), lastCleanDate.getSeconds(), 0);
            }
        } else {
            //start from now
            var dd = new Date();
            dd.setHours(dd.getHours() + placemarkhours);
            if (dd < endDate) {
                nextCleanDate = new Date(dd);
            } else {
                //new day
                nextCleanDate = new Date();
                nextCleanDate.setHours(24 + buildingstart, 0, 0, 0);
            }
        }
        return nextCleanDate;
    }
    convertCleaning(option) {
        let value = "";
        switch (option) {
            case "HOURS1":
                value = "Every 1 hour";
                break;
            case "HOURS2":
                value = "Every 2 hours";
                break;
            case "HOURS3":
                value = "Every 3 hours";
                break;
            case "HOURS4":
                value = "Every 4 hours";
                break;
            case "HOURS5":
                value = "Every 5 hours";
                break;
            case "HOURS6":
                value = "Every 6 hours";
                break;
            case "HOURS7":
                value = "Every 7 hours";
                break;
            case "HOURS8":
                value = "Every 8 hours";
                break;
            case "HOURS9":
                value = "Every 9 hours";
                break;
            case "HOURS10":
                value = "Every 10 hours";
                break;
            case "HOURS11":
                value = "Every 11 hours";
                break;
            case "HOURS12":
                value = "Every 12 hours";
                break;
            case "HOURS24":
                value = "Everyday";
                break;
        }
        return value;
    }
    convertCleaningBack(option) {
        let value = "";
        switch (option) {
            case "Every 1 hour":
                value = "HOURS1";
                break;
            case "Every 2 hours":
                value = "HOURS2";
                break;
            case "Every 3 hours":
                value = "HOURS3";
                break;
            case "Every 4 hours":
                value = "HOURS4";
                break;
            case "Every 5 hours":
                value = "HOURS5";
                break;
            case "Every 6 hours":
                value = "HOURS6";
                break;
            case "Every 7 hours":
                value = "HOURS7";
                break;
            case "Every 8 hours":
                value = "HOURS8";
                break;
            case "Every 9 hours":
                value = "HOURS9";
                break;
            case "Every 10 hours":
                value = "HOURS10";
                break;
            case "Every 11 hours":
                value = "HOURS11";
                break;
            case "Every 12 hours":
                value = "HOURS12";
                break;
            case "Everyday":
                value = "HOURS24";
                break;
        }
        return value;
    }
    updateDepartment(option) {
        this.props.placemarkdata.department = option.value;
        this.setState({ update: !this.state.update });
    }
    renderAmenities(label) {
        return (
            <Box direction="row" gap="xsmall" pad={{ "right": "medium", "bottom": "small" }}>
                <Button plain icon={<CheckBox checked disabled />} disabled />
                <Text>{label}</Text>
            </Box>
        )
    }
    renderOverview() {
        const { parent, parents, options, isNewCategory } = this.state;
        const placemarkdefault = this.props.placemarkdata;
        const categoriesdefault = this.props.categoriesdata;

        const heading = placemarkdefault && (placemarkdefault.id != "0") ? 'Edit' : 'Create';
        const vcategory = this.categoryfull.find((opt) => opt.id == placemarkdefault.categoryid);
        const vcategoryname = vcategory ? vcategory.name : "";
        const vcategoryparent = vcategory ? vcategory.parent : "";
        const vcategorylocation = vcategory ? vcategory.location : "";
        const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        var placemarkhistorydata = this.props.placemarkhistoriesdata.filter(h => h.mergedPlaceId == this.props.match.params.placemarkId);
        const canedit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        const canReadOnly = /*RB.canRead(constants.PERMS.LOC_CONTENT) && !canedit*/!RB.canEdit(constants.PERMS.LOC_CONTENT);
        const isDeepLinkURLAvailable = this.props.organisationdata.appURI && this.props.organisationdata.contentUriEnabled && placemarkdefault.id != "0";
        return (
            <Box gap="medium" margin={{ "bottom": "medium" }}>
                {/*Description*/}
                <Box>
                    <Box>
                        <Box direction="row" justify="between">
                            <Text size="large">Content Details</Text>
                            {placemarkdefault.name == "Testing Center" &&
                                <Select value={this.state.color} options={["Red", "Amber", "Green", "Grey"]}
                                    onChange={evt => {
                                        this.setState({ color: evt.option });
                                    }} />
                            }
                        </Box>
                        <Text>All fields marked with an * are mandatory.</Text>
                    </Box>
                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                        <FormField label="Name">
                            <TextInput readOnly disabled defaultValue={placemarkdefault.name} />
                        </FormField>
                        <FormField label="Placemark Type">
                            <TextInput readOnly disabled defaultValue={placemarkdefault.placemarktype} />
                        </FormField>
                        <FormField label="Detail" error={this.detail_err}>
                            {/*<TextArea defaultValue={placemarkdefault.detail} onChange={v => this.updateDetailValue(v)} resize="vertical" disabled={canReadOnly} />*/}
                            <ReactQuill
                                theme="snow"
                                bounds={`[data-bounds="html_editor"]`}
                                scrollingContainer="html"
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                        ['blockquote', 'code-block'],
                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                        [{ 'direction': 'rtl' }],                         // text direction
                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                        ['link'],
                                        ['clean']                                         // remove formatting button
                                    ],
                                    clipboard: {
                                        matchVisual: false,
                                    },
                                    htmlEditButton: {
                                        debug: true, // logging, default:false
                                        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                                        okText: "Ok", // Text to display in the OK button, default: Ok,
                                        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
                                        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                                        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                                        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                                        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                                        editorModules: {} // The default mod
                                    }
                                }}
                                onChange={this.handleEditorChange}
                                value={placemarkdefault.detail}
                                readOnly={canReadOnly} />
                        </FormField>
                        {(placemarkdefault.teemType == "room" || placemarkdefault.placemarktype == "Conference Room" || placemarkdefault.teemType == "desk" || placemarkdefault.placemarktype == "Desk") &&
                            <Box>
                                {/*<FormField label="Department" >
                                    <Select onChange={option => this.updateDepartment(option)} value={placemarkdefault.department}
                                    options={this.state.departments} />
                                </FormField>*/}
                                <FormField label="Capacity" >
                                    <TextInput defaultValue={placemarkdefault.capacity} disabled />
                                </FormField>
                                {/*<FormField label="Department" >
                                    <TextInput defaultValue={placemarkdefault.department} disabled />
                                </FormField>*/}
                                <FormField label="Calendar" >
                                    <TextInput defaultValue={placemarkdefault.graphUri} disabled />
                                </FormField>
                                <FormField label="Amenities" >
                                    <Box direction="row" wrap pad={{ "top": "small", "left": "small" }} style={{ "min-height": "35px" }}>
                                        {this.state.amenities.map(item =>
                                            this.renderAmenities(item)
                                        )}
                                    </Box>
                                </FormField>
                            </Box>}
                            {isDeepLinkURLAvailable && <Deeplink uri={this.props.organisationdata.appURI} path={this.props.location.pathname} />}

                            {/*Image*/}
                            {placemarkdefault.imageUrl && !placemarkdefault.image &&
                                <FormField
                                    contentProps={{ border: false }}
                                    label={
                                        <Box direction="row" gap="xsmall" align="center">
                                            <Box>Image</Box>
                                            <Box data-for="img_display_info" data-tip>
                                                <CircleInformation size="small" />
                                                <ReactTooltip id="img_display_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                    <span>The image displayed will be used in the client for this placemark.</span>
                                                </ReactTooltip>
                                            </Box>
                                        </Box>}
                                >
                                    <StyledBox style1 pad="xsmall" width="fit-content">
                                        <Box border={false ? { color: "brand", size: "medium" } : { size: "medium" }} width="100px">
                                            <Image src={placemarkdefault.imageUrl} />
                                        </Box>
                                    </StyledBox>
                                </FormField>
                            }
                            {(!placemarkdefault.imageUrl || Boolean(placemarkdefault.image)) &&
                                <Grommet theme={customTheme}>
                                    <FormField label="Image" label={
                                        <Box direction="row" gap="xsmall" align="center">
                                            <Box>Image</Box>
                                            <Box data-for="img_info" data-tip>
                                                <CircleInformation size="small" />
                                                <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                    <span>Please select a brand approved image to display with your content. <br />
                                                        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                                </ReactTooltip>
                                            </Box>
                                        </Box>} >
                                        <Grommet theme={customTheme3}>
                                            <Box direction="row" gap="small">
                                                <StyledBox style1 pad={{ "horizontal": "xsmall" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                    <FormField label="" error={this.image_err}>
                                                        <Box direction="row" justify="center">
                                                            {this.state.newimage ?
                                                                <Box alignSelf="center">
                                                                    {this.shortcut()}
                                                                </Box>
                                                                :
                                                                (placemarkdefault.imageUrl == "" || placemarkdefault.imageUrl == null) ?
                                                                    <Box height="75px" justify="center">
                                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                        <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit} onClick={e => {
                                                                            const c = document.getElementById("real");
                                                                            c.click();
                                                                        }} />
                                                                    </Box>
                                                                    :
                                                                    <Box justify="center">
                                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                        <Image height="75" src={`${placemarkdefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                            onClick={e => {
                                                                                const c = document.getElementById("real");
                                                                                c.click();
                                                                            }} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                    </FormField>
                                                </StyledBox>
                                                <Box justify="center" margin={{ "bottom": "small" }}>
                                                    <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit || ((placemarkdefault.imageUrl == "" || placemarkdefault.imageUrl == null) && !this.state.newimage)}>
                                                        <Trash />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grommet>
                                    </FormField>
                                </Grommet>
                            }
                    </StyledBox>
                </Box>

                {/*Categorise*/}
                <Box>
                    <Box><Text size="large">Categorise</Text></Box>
                    <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                        {!isNewCategory ? (
                            <Box direction="row" gap="small">
                                <Box fill>
                                    <FormField label="Category Name *" error={this.categoryid_err}>
                                        <Select
                                            placeholder="Select a Category"
                                            onClose={(() => this.setState({ options: this.categoryfull }))}
                                            onSearch={(searchText) => {
                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                if (filtered.length > 0) {
                                                    this.setState({ options: filtered });
                                                }
                                                else {
                                                    this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                }
                                            }}
                                            //value={vcategoryname}
                                            value={vcategoryparentpluslocation}
                                            onChange={event => this.updateCategoryName(event)}
                                            options={options.map((option) => option.lpn)}
                                            replace={false}
                                            disabled={canReadOnly}
                                        />
                                    </FormField>
                                </Box>
                                <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                    <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={canReadOnly} />
                                </Box>
                            </Box>
                        ) : (
                                //New Category
                                <Box>
                                    <Box fill direction="row" border="bottom" gap="small">
                                        <Box fill>
                                            <FormField label="Category Name *" error={this.newname_err}>
                                                <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} disabled={canReadOnly} />
                                            </FormField>
                                        </Box>
                                        <Box justify="start" pad={{ "top": "36px" }} height="95px">
                                            <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={canReadOnly} />
                                        </Box>
                                    </Box>
                                    <Box fill>
                                        <FormField label="App Location" error={this.parent_err}>
                                            <Select
                                                placeholder="App Location"
                                                onClose={() => this.setState({ parents: this.parentfull })}
                                                onSearch={(searchText) => {
                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    const filtered = this.parentfull.filter(o => o.parentloc.match(regexp));
                                                    if (filtered.length > 0) {
                                                        this.setState({ parents: filtered });
                                                    }
                                                    else {
                                                        this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                    }
                                                }}
                                                value={parent ? parent : '\u00A0'}
                                                //selected={selected}
                                                onChange={event => this.updateCategoryParent(event)}
                                                options={parents.map((option) => option.parentloc ? option.parentloc : '\u00A0')}
                                                replace={false}
                                                disabled={canReadOnly}
                                            />
                                        </FormField>
                                    </Box>
                                </Box>
                            )}
                    </StyledBox>
                </Box>

                {/*Connect Now*/}
                <IconThemeContext.Extend value={galwayIconTheme}>
                    <Box>
                        <Box>
                            <Text size="large">Connect Now</Text>
                        </Box>
                        {false && <StyledBox style1 gap="small" pad={{ "horizontal": "small" }} justify="between" direction="row">
                            <Box direction="column" width="20%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Mail />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.email_err} fill="horizontal" label='Email Address'>
                                            <TextInput defaultValue={placemarkdefault.email} onChange={v => this.updateEmailValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start" >
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        {/* <Image src="./Images/Phone_black.png" width="24px" height="24px" /> */}
                                        <Phone />
                                    </Box>
                                    <Box width="100%">
                                        <FormField help={this.phone_help} error={this.phone_err} fill="horizontal" label="Phone Number">
                                            <TextInput defaultValue={placemarkdefault.phone} onChange={evt => this.updatePhoneValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction="column" width="20%">
                                <Box direction="row" align="start" >
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Twitter />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.twitter_err} fill="horizontal" label="Twitter">
                                            <TextInput defaultValue={placemarkdefault.twitter} onChange={v => this.updateTwitterValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <LinkedinOption />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.linkedin_err} fill="horizontal" label="LinkedIn">
                                            <TextInput defaultValue={placemarkdefault.linkedin} onChange={v => this.updateLinkedinValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction="column" width="20%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        {/*<Image src="./Images/Yammer_black.png" width="24px" height="24px" />*/}
                                        <Yammer />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.yammer_err} fill="horizontal" label="Yammer">
                                            <TextInput defaultValue={placemarkdefault.yammer} onChange={v => this.updateYammerValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <FacebookOption />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.facebook_err} fill="horizontal" label="Facebook">
                                            <TextInput defaultValue={placemarkdefault.facebook} onChange={v => this.updateFacebookValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction="column" width="20%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Link />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.url_err} fill="horizontal" label='URL'>
                                            <TextInput defaultValue={placemarkdefault.url} onChange={v => this.updateUrlValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Instagram />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.instagram_err} fill="horizontal" label="Instagram">
                                            <TextInput defaultValue={placemarkdefault.instagram} onChange={v => this.updateInstagramValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction="column" width="20%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Video />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.video_err} fill="horizontal" label="Video">
                                            <TextInput defaultValue={placemarkdefault.video} onChange={evt => this.updateVideoValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Youtube />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.youtube_err} fill="horizontal" label="YouTube">
                                            <TextInput defaultValue={placemarkdefault.youtube} onChange={evt => this.updateYoutubeValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                        </StyledBox>}

                        <StyledBox style1 gap="small" pad={{ "horizontal": "small", "top": "small" }} justify="between" direction="column">
                            <Grid columns={{ count: 4, size: "auto" }}>
                                <Box direction="row" align="start" width="100%">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Mail />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.email_err} fill="horizontal" label='Email Address'>
                                            <TextInput defaultValue={placemarkdefault.email} onChange={v => this.updateEmailValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start" >
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Twitter />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.twitter_err} fill="horizontal" label='Twitter'>
                                            <TextInput defaultValue={placemarkdefault.twitter} onChange={v => this.updateTwitterValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        {/*<Image src="./Images/Yammer_black.png" width="24px" height="24px" />*/}
                                        <Yammer />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.yammer_err} fill="horizontal" label='Yammer'>
                                            <TextInput defaultValue={placemarkdefault.yammer} onChange={v => this.updateYammerValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Link />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.url_err} fill="horizontal" label='URL'>
                                            <TextInput defaultValue={placemarkdefault.url} onChange={v => this.updateUrlValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>

                                <Box direction="row" align="start" >
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        {/* <Image src="./Images/Phone_black.png" width="24px" height="24px" /> */}
                                        <Phone />
                                    </Box>
                                    <Box width="100%">
                                        <FormField help={this.phone_help} error={this.phone_err} fill="horizontal" label="Phone Number">
                                            <TextInput defaultValue={placemarkdefault.phone} onChange={evt => this.updatePhoneValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <LinkedinOption />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.linkedin_err} fill="horizontal" label='LinkedIn'>
                                            <TextInput defaultValue={placemarkdefault.linkedin} onChange={v => this.updateLinkedinValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <FacebookOption />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.facebook_err} fill="horizontal" label='Facebook'>
                                            <TextInput defaultValue={placemarkdefault.facebook} onChange={v => this.updateFacebookValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Instagram />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.instagram_err} fill="horizontal" label='Instagram'>
                                            <TextInput defaultValue={placemarkdefault.instagram} onChange={v => this.updateInstagramValue(v)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>

                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Slack />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.slack_err} fill="horizontal" label="Slack">
                                            <TextInput defaultValue={placemarkdefault.slack} onChange={evt => this.updateSlackValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>

                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Video />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.video_err} fill="horizontal" label="Video">
                                            <TextInput defaultValue={placemarkdefault.video} onChange={evt => this.updateVideoValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Youtube />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.youtube_err} fill="horizontal" label="YouTube">
                                            <TextInput defaultValue={placemarkdefault.youtube} onChange={evt => this.updateYoutubeValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <MapLocation />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.map_err} fill="horizontal" label="Maps">
                                            <TextInput
                                                placeholder="Latitude, Longitude"
                                                defaultValue={(placemarkdefault.latitude != null && placemarkdefault.longitude != null) ? (placemarkdefault.latitude + ", " + placemarkdefault.longitude) : ""}
                                                onChange={evt => this.updateMapValue(evt)} disabled={canReadOnly} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Grid>
                        </StyledBox>
                    </Box>

                    {/*Custom Attributes*/}
                    {this.renderCustomInputs()}

                    {/*More Information*/}
                    <Box pad={{ "top": "medium" }}>
                        <Box>
                            <Text size="large">More Information</Text>
                            {this.renderMoreInfoV2(this.props.placemarkdata.moreinfo)}
                        </Box>
                    </Box>
                </IconThemeContext.Extend>
            </Box>
        );
    }

    renderCustomInputs(num = 4) {
        return (
            <Box pad={{ "top": "medium" }}>
                <Box direction="row" gap="small">
                    <Text size="large">Custom Attributes</Text>
                    <Box data-for="custom_info" data-tip>
                        <CircleInformation />
                        <ReactTooltip id="custom_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                            <span>Set attributes here to allow custom integrations.</span>
                        </ReactTooltip>
                    </Box>
                </Box>
                <StyledBox style1 pad="small">
                    <Grid columns={{ count: num, size: "auto" }} gap="small">
                        {[...Array(num)].map((e, i) =>
                            <FormField label={`Custom Attribute ${i + 1}`} error={this[`custom${i + 1}_err`]}>
                                <TextInput id={i} defaultValue={this.props.placemarkdata[`custom${i + 1}`]}
                                    onChange={evt => {
                                        this.props.placemarkdata[`custom${i + 1}`] = evt.target.value;
                                        if (evt.target.value.length > 50)
                                            this[`custom${i + 1}_err`] = "Custom Attribute can't be longer than 50 characters";
                                        else
                                            this[`custom${i + 1}_err`] = ""; 
                                        this.setState({ validating: true });
                                    }} />
                            </FormField>
                        )}
                    </Grid>
                </StyledBox>
            </Box>
        );
    }

    render() {
        const { categoriesdataLoading, locationsdataLoading, placemarkdataLoading, placemarkhistoriesdataLoading, organisationdataLoading } = this.state;
        const placemarkdefault = this.props.placemarkdata;
        const categoriesdefault = this.props.categoriesdata;

        if (categoriesdataLoading || locationsdataLoading || placemarkdataLoading || placemarkhistoriesdataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        const heading = placemarkdefault && (placemarkdefault.id != "0") ? 'Edit' : 'Create';
        const vcategory = this.categoryfull.find((opt) => opt.id == placemarkdefault.categoryid);
        const vcategoryname = vcategory ? vcategory.name : "";
        const vcategoryparent = vcategory ? vcategory.parent : "";
        const vcategorylocation = vcategory ? vcategory.location : "";
        //const vcategoryparentpluslocation = vcategoryparent && vcategorylocation ? vcategorylocation + " / " + vcategoryparent : "";
        const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        //console.log('EDIT:', placemarkdefault.categoryid, JSON.stringify(vcategory));
        var placemarkhistorydata = this.props.placemarkhistoriesdata.filter(h => h.mergedPlaceId == this.props.match.params.placemarkId);
        const canedit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        const canReadOnly = !RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (this.props.organisationdata) {
            Quill.import('formats/link').PROTOCOL_WHITELIST = ["http", "https", this.props.organisationdata.appURI.split(':')[0], "mailto"];
        }

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="column"
                    //align="center"
                    pad={{ horizontal: "medium" }}
                    //pad="medium"
                    //justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Box direction="row" align="center" pad={{ vertical: "medium" }} justify="between">
                        <Heading level='2'>{placemarkdefault.name} </Heading>
                        <Box direction="row" gap="small">
                            <StyledButton typeCancel label={canReadOnly ? "Back" : "Cancel"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                            {!canReadOnly &&
                                <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                            }
                        </Box>
                    </Box>
                    Update your placemark description, category, image and social links. Add cleaning notes and view history quickly and easily in one place.
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUp open={this.state.isUploadError} onClose={() => this.onUploadClose()} text={this.uploadErrorText} header="File Upload Error" error={this.state.isUploadError} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The image associated with this event will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                    <form>
                        {this.context.feature.find(f => f.name == "siteWellness").isEnabled && constants.release().Feature_citrix && (placemarkdefault.teemType == "room" || placemarkdefault.placemarktype == "Conference Room" || placemarkdefault.teemType == "desk" || placemarkdefault.placemarktype == "Desk") &&
                            <Tabs justify="start" /*activeIndex={this.state.index} onActive={this.onActive.bind(this)}*/>
                                <Tab title="Overview" margin={{ "bottom": "small" }}>
                                    {this.renderOverview()}
                                </Tab>

                                <Tab title="Cleaning" margin={{ "bottom": "small" }}>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label="Next Cleaning Date/Time">
                                            <TextInput readOnly disabled
                                                defaultValue={placemarkdefault.nextCleaningDue != null ? customDateFormatter.format(new Date(placemarkdefault.nextCleaningDue)) : ""}
                                            //defaultValue={customDateFormatter.format(this.calculateNextCleaningDatev2(this.convertCleaning(placemarkdefault.cleaningSchedule), /*this.lastCleaningDate*/ null))}
                                            />
                                        </FormField>
                                    </StyledBox>
                                </Tab>

                                <Tab title="History" margin={{ "bottom": "small" }}>
                                    <PlacemarkHistoryListTable placemarkhistorydata={placemarkhistorydata} />
                                </Tab>

                                <Tab title="Settings" margin={{ "bottom": "small" }}>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label="Cleaning Schedule">
                                            <Select
                                                options={cleaningSchedule.map(s => s.name)}
                                                value={this.convertCleaning(placemarkdefault.cleaningSchedule)}
                                                onChange={evt => {
                                                    placemarkdefault.cleaningSchedule = this.convertCleaningBack(evt.option);
                                                    this.setState({ frequency: evt.option });
                                                }}
                                                disabled={canReadOnly}
                                            />
                                        </FormField>
                                        {/*<FormField label="Calendar">
                                        <TextInput readOnly={!canedit} />
                                    </FormField> */}
                                    </StyledBox>
                                </Tab>
                            </Tabs>}

                        {!(this.context.feature.find(f => f.name == "siteWellness").isEnabled && constants.release().Feature_citrix && (placemarkdefault.teemType == "room" || placemarkdefault.placemarktype == "Conference Room" || placemarkdefault.placemarktype == "Desk")) &&
                            this.renderOverview()}
                    </form>
                </Box>
                {/*<Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>*/}

            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const placemarkId = ownProps.match.params.placemarkId || ''; //from the path '/event/:eventId'
    //console.log('*DEBUG*-EV1- MapStatetoProps',JSON.stringify(state));
    //console.log('*DEBUG*-EV2- MapStatetoProps',JSON.stringify(ownProps));
    if (state.selectedPlacemarkReducer.placemarkdata && placemarkId == state.selectedPlacemarkReducer.placemarkdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedPlacemarkReducer.placemarkdata));
        return {
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            placemarkdata: state.selectedPlacemarkReducer.placemarkdata,
            placemarkhistoriesdata: state.placemarksHistoryReducer.placemarkhistoriesdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    }

    else {
        return {
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...PlacemarkAction, ...LocationAction, ...PlacemarkHistoryActionnn, ...OrganisationAction }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlacemarkEditContainer));