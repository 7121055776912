import * as ActionType from './ActionType';
import PolicyApi from '../api/PolicyApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getPoliciesResponse = policiesdata => ({
    type: ActionType.GET_POLICIES_RESPONSE,
    policiesdata
});

const queuedGetAllPolicies = queueApiCall(PolicyApi.getAllPolicies);

export function getPoliciesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllPolicies()
            .then(policiesdata => {
                dispatch(getPoliciesResponse(policiesdata));
                return policiesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewPolicyResponse = () => ({
    type: ActionType.ADD_NEW_POLICY_RESPONSE
});

export const updateExistingPolicyResponse = () => ({
    type: ActionType.UPDATE_EXISTING_POLICY_RESPONSE
});

export function savePolicyAction(policydraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return PolicyApi.savePolicy(policydraft)
            .then((response) => {
                if (policydraft.id) {
                    dispatch(updateExistingPolicyResponse());
                } else {
                    dispatch(addNewPolicyResponse());
                }
                return response;
            }).then((response) => {
                if (policydraft.id == "0") {
                    dispatch(getPolicyAction(policydraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getPolicyResponse = policydata => ({
    type: ActionType.GET_POLICY_RESPONSE,
    policydata
});

export function getPolicyAction(policyId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return PolicyApi.getPolicy(policyId)
            .then(policydata => {
                dispatch(getPolicyResponse(policydata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deletePolicyResponse = () => ({
    type: ActionType.DELETE_POLICY_RESPONSE
});

const queuedDeletePolicy = queueApiCall(PolicyApi.deletePolicy);

export function deletePolicyAction(policyId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeletePolicy(policyId)
            .then((response) => {
                dispatch(deletePolicyResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getPoliciesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}