import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text } from 'grommet';
import { Filter, Edit, Close, Search, Trash, ThemeContext as IconThemeContext } from 'grommet-icons';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class UserPreferencesListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }

    render() {
        return (
            <StyledBox >
                {this.renderProfilesTable(this.props)}
            </StyledBox>
        );
    }

    renderProfilesTable(props) {

        const COLUMNS = [
            //{
            //    property: 'lcusername',
            //    header: 'Username',
            //    render: users => <Text>{users.username}</Text>
            //},
            {
                property: 'lcemail',
                header: 'Email',
                render: users => <Text>{users.id}</Text>
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: users =>
                    <Box width="24px" direction='row'>
                        <Button plain disabled={props.deletedProfileIds.includes(users.id)} onClick={evt => props.handleDelete(evt, users.id)} icon={<Trash />} />
                    </Box>,
            },
        ];

        let DATA = [];

        if (props.profilesdata[0] && props.profilesdata[0].count != 0) {
            props.profilesdata.forEach(ev => {
                DATA.push({ ...ev, /*lcusername: ev.username.toLowerCase(),*/ lcemail: ev.id.toLowerCase() })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.id.toLowerCase().includes(search)
            );
        }

        //console.log('DATA:',DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcemail"
                }))}
                data={DATA}
                noLoadingMsg={this.props.profilesdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcemail', label: 'Email' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
export default UserPreferencesListTable;
