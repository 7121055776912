import * as ActionType from './ActionType';
import OverviewApi from '../api/OverviewApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getOverviewsResponse = overviewsdata => ({
    type: ActionType.GET_OVERVIEWS_RESPONSE,
    overviewsdata
});

export function getOverviewsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return OverviewApi.getAllOverviews()
            .then(overviewsdata => {
                dispatch(getOverviewsResponse(overviewsdata));
                return overviewsdata;
            }).catch(error => {
                throw error;
            });
    };
}