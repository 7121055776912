import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as CalendarHistoryAction from '../action/CalendarHistoryAction';
import * as SiteAction from '../action/SiteAction';
import * as CalendarAction from '../action/CalendarAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Layer, Heading, Text, Grommet, Calendar, Tabs, Tab, Stack, DataTable, TextInput, FormField } from 'grommet';
import { Plan, StatusGoodSmall } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { grommet } from 'grommet/themes';
import { SketchPicker } from 'react-color';
import _ from 'lodash';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';


var start = undefined;

const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //minute: 'numeric',
    //second: 'numeric'
});
class LocHealthPass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPickColor: false,
            color: "",
            cancel: false,
            isSaved: false,
            isSaveSuccessful: false,
            disabledButton: false,
            index: 0,
            sitedataLoading: true,
            organisationdataLoading: true,
            calendarsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        this.selectedDate = new Date().toISOString();
    }
    retention_err = "";

    ClearErrorMessages() {
        this.retention_err = "";
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const orgid = constants.organisation.id;
        const orgid = sessionStorage.getItem("organisationId");
        const siteId = sessionStorage.getItem("locationId");
        this.props.action.getSiteAction(siteId)
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getCalendarsAction()
            .then(response => this.setState({ calendarsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Calendars Error', error);
            });
        var index = this.props.location.state ? this.props.location.state.index : 0;
        this.setState({ index: index });
    }
    componentWillUnmount() {
        this.historyUnblock();
    }
    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.sitedata && !_.isEqual(start, this.props.sitedata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    handleSave() {
        const sitedefault = this.props.sitedata;
        let errorsno = 0;
        var errortab = -1;
        this.ClearErrorMessages();
        //validation
        if (!constants.REGEX.NUMBER.test(sitedefault.formRetentionDays)) {
            errorsno++;
            this.retention_err = "Value must be a number between 0 and 21";
            errortab = 1;
        }
        if (sitedefault.formRetentionDays < 0 || sitedefault.formRetentionDays > 21) {
            errorsno++;
            this.retention_err = "Value must be a number between 0 and 21";
            errortab = 1;
        }
        if (errorsno === 0) {
            this.setState({ disabledButton: true });
            console.log('Save: OK', sitedefault);
            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/home');
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });

        } else {
            this.onActive(errortab);
            this.setState({ validating: true });
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.sitedata)) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitedata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/lochealthpass');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Organisation Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/lochealthpass');
                });
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/orgsites');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }
    isToday(someDate) {
        if (someDate == null)
            return false;
        const today = new Date();
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear();
    }
    updateRetention(evt) { this.props.sitedata.formRetentionDays = evt.target.value; }
    
    render() {
        const { organisationdata, calendarsdata, sitedata } = this.props;
        const { sitedataLoading, organisationdataLoading, calendarsdataLoading } = this.state;

        if (sitedataLoading || organisationdataLoading || calendarsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        var today = calendarsdata.find(e => e.day == sitedata.currentDate);
        today = today ? today : { hexColor: "white", day: "No colour selected for today" };

        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2'>Health Pass</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={() => this.handleCancel()} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={() => this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <Tabs justify="start">
                        <Tab title="Health Pass">
                            <Box pad={{ vertical: "small" }} gap="small">
                                <Text>Today's health pass. Only employees who present this upon entry can proceed</Text>
                                <Box width="small" height="small" background={today.hexColor} round="10px" />
                                <Text weight="bold">{today.hexColor != "white" ? customDateFormatter.format(new Date(today.day)) : today.day.toString()}</Text>
                            </Box>
                        </Tab>
                        <Tab title="Configure">
                            <Box pad={{ vertical: "small" }}>
                                <Text>Apply health report of the day colour to ensure the effective management of return to work protocols. Each employee will need to present their approved return to work pass to gain entry to the building.</Text>
                                <Text>A different colour should be applied for each day to ensure strict compliance and enforcement</Text>

                                <Heading level="4" margin={{ bottom: "none" }}>Return to work form retention</Heading>
                                <Text>Select the number of days the wellness forms should be retained for your records. Maximum allowed is 21 days</Text>
                                <Box width="350px">
                                    <FormField error={this.retention_err}>
                                        <StyledBox style1 width="350px">
                                            <TextInput plain type="number" min="0" max="21" defaultValue={sitedata.formRetentionDays ? sitedata.formRetentionDays : organisationdata.formRetentionDays} onChange={evt => this.updateRetention(evt)}/>
                                        </StyledBox>
                                    </FormField>
                                </Box>
                            </Box>
                        </Tab>
                    </Tabs>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    //const organisationId = constants.organisation.id;
    const organisationId = sessionStorage.getItem("organisationId");
    if (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        return {
            sitedata: state.selectedSiteReducer.sitedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            calendarsdata: state.calendarsReducer.calendarsdata
        };
    } else {
        return {};
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction, ...CalendarAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocHealthPass));
