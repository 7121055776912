import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as CollectionAction from '../action/CollectionAction';
import * as SiteAction from '../action/SiteAction';
import * as AssociationAction from '../action/AssociationAction';
import * as MetaDataAction from '../action/MetaDataAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import AssociationApi from '../api/AssociationApi';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, Grommet, CheckBox } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "none",
            error: {
                color: "none"
            }
        }
    }
});

var start = undefined;


export class OrgMetadataEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.state = {
            isSaved: false,
            open: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            metadataLoading: true,
            modelsdataLoading: true,
            typesdataLoading: true,
            manufacturersdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            typeSuggestions: [],
        };
    }

    //Validating errors
    manufacturer_err = "";
    model_err = "";
    modelType_err = "";

    //utility
    //function to sort strings by abc
    sortingByName(a,b) {
        const alc = a.name? a.name.toLowerCase(): '';
        const blc = b.name? b.name.toLowerCase(): '';
        if(alc < blc)
        {
          return -1;
        }
        else if(alc > blc)
        {
          return 1;
        }
        else {
          return 0;
        }
    }

    //utility
    //Filter array items based on search criteria (query)
    filterArrayItems(arr, query) {
        //console.log("filer array",query, arr);
        return arr.filter(function(el) {
            return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        })
    }

    updateTypeSuggestions(){
        //console.log("update type suggestions");

        const {typesdata, metadata, metadatas} = this.props;

        //console.log("full data", metadatas);
        //console.log("metadata", metadata);

        let dataFiltered = [];
        dataFiltered = metadatas.filter( m =>
            m.manufacturerId != null &&
            m.manufacturerId == metadata.manufacturerId &&
            m.modelId != null &&
            m.modelId == metadata.modelId //&&
            //m.deviceTypeId != null &&
            //m.deviceType.name.toLowerCase().includes(metadata.deviceType.name.toLowerCase())
        );
        dataFiltered.sort(this.sortingByName);

        //console.log("filtered data", dataFiltered);

        let data = [];
        dataFiltered.forEach( fe =>{
            typesdata.forEach( tde=>{
                if(tde.id == fe.deviceTypeId){

                    if(metadata.deviceTypeId != null && metadata.deviceType.name != ""){

                        if( tde.name.toLowerCase().includes(metadata.deviceType.name.toLowerCase()) ){
                            data.push( tde );    
                        }

                    }
                    else{
                        data.push( tde );
                    }

                }
            })
        });

        //console.log("data", data);

        this.setState({ typeSuggestions: data });
    }

    ClearErrorMessages() {
        this.manufacturer_err = "";
        this.model_err = "";
        this.modelType_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.metaid;

        this.props.action.getMetadatasAction()
            .then(response => this.setState({ metadatasdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
        this.props.action.getMetadataAction(id)
            .then(response => this.setState({ metadataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Asset Edit Error', error);
            });
        this.props.action.getModelsAction()
            .then(response => this.setState({ modelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getTypesAction()
            .then(response => this.setState({ typesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getManufacturersAction()
            .then(response =>{ 
                this.setState({ manufacturersdataLoading: false });
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const metadata = this.props.metadata;
        const metadatas = this.props.metadatas;
        let errorsno = 0;
        //this.ClearErrorMessages();
        //this.setState({ validating: false });

        let isExisting = false;
        if (metadata.manufacturerId && metadata.modelId && metadata.deviceTypeId) {
            isExisting = metadatas.some(m =>
                m.id != metadata.id && //do not check itselft

                m.manufacturerId == metadata.manufacturerId &&
                m.modelId == metadata.modelId &&
                m.deviceTypeId == metadata.deviceTypeId
            );
            if (isExisting)
                this.modelType_err = "Combination already exists";
        }
        else if (metadata.manufacturerId && metadata.modelId) {
            isExisting = metadatas.some(m =>
                m.id != metadata.id && //do not check itselft

                m.manufacturerId == metadata.manufacturerId &&
                m.modelId == metadata.modelId &&
                m.deviceTypeId == metadata.deviceTypeId
            );
            if (isExisting)
                this.model_err = "Combination already exists";
        }
        else if (metadata.manufacturerId && metadata.deviceTypeId) {
            isExisting = metadatas.some(m =>
                m.id != metadata.id && //do not check itselft

                m.manufacturerId == metadata.manufacturerId &&
                m.deviceTypeId == metadata.deviceTypeId &&
                m.modelId == metadata.modelId
            );
            if (isExisting)
                this.modelType_err = "Combination already exists";
        }
        else if (metadata.manufacturerId) {
            isExisting = metadatas.some(m =>
                m.id != metadata.id && //do not check itselft

                m.manufacturerId == metadata.manufacturerId &&
                m.deviceTypeId == metadata.deviceTypeId &&
                m.modelId == metadata.modelId
            );
            if (isExisting)
                this.manufacturer_err = "Combination already exists";
        }

        if (this.manufacturer_err != "") {
            errorsno++;
        }

        if (this.model_err != "") {
            errorsno++;
        }

        if (this.modelType_err != "") {
            errorsno++;
        } else if (metadata.deviceType) {

        }

        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Save: OK', metadata);

            //trim
            if (metadata.manufacturerId != null)
                metadata.manufacturer.name = metadata.manufacturer.name.trim();
            if (metadata.modelId != null)
                metadata.model.name = metadata.model.name.trim();
            if (metadata.deviceTypeId != null)
                metadata.deviceType.name = metadata.deviceType.name.trim();

            this.props.action.saveMetadataAction(metadata)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    return response.json();
                })
                .catch(error => {
                    console.log('*DEBUG*-Save MetadataEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            console.log('No Save', metadata, isExisting)
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.metadata.id;
            this.props.action.getMetadataAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push({
                                pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                                state: { index: 1 }
                            });
                        else
                            this.props.history.push({
                                pathname: '/orgtrackers',
                                state: { index: 1 }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-TrackerEdit Error', error);
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push({
                            pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                            state: { index: 1 }
                        });
                    else
                        this.props.history.push({
                            pathname: '/orgtrackers',
                            state: { index: 1 }
                        });
                });
        }
    }

    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 1 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 1 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    updateManufacturerValue(name) {
        const { metadata, metadatas } = this.props;
        let manu = this.isExistingManufacturer(name.trim());
        if (manu) {
            //this.manufacturer_err = "Name already exists";
            metadata.manufacturer = manu;
            metadata.manufacturerId = manu.id;
        }
        else {
            //If it's a new, make sure we keep the old id
            let oldMeta = metadatas.find(m => m.id == metadata.id);
            metadata.manufacturerId = oldMeta.manufacturerId;
            metadata.manufacturer = oldMeta.manufacturer;
            metadata.manufacturer.name = name;
        }


        if (name.length > 50) {
            this.manufacturer_err = "Manufacturer's name can't be longer than 50 character";
        }
        else if (name.trim().length == 0) {
            this.manufacturer_err = "Please add Manufacturer name";
        }
        else {
            this.manufacturer_err = "";
        }

        this.setState({ update: true });

        this.updateTypeSuggestions();
    }
    manufacturerSuggestions(name) {
        let suggs = this.props.manufacturersdata;
            //.filter(m => m.manufacturerId == this.props.metadata.manufacturer.id && m.modelId)
            //.map(x => x.model)
            //.filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index); //distinct
        if (name.length == 0)
            return suggs;
        if (suggs.some(m => m.name.toLowerCase() == name.toLowerCase().trim()))
            return suggs;
        return suggs.filter(m => m.name.toLowerCase().includes(name.toLowerCase().trim()));
    }

    updateModelValue2(name) {
        const { metadata, metadatas } = this.props;
        this.model_err = "";
        let oldMeta = _.cloneDeep(metadatas.find(m => m.id == metadata.id));
        if (oldMeta.modelId) { //Update model
            let model = this.isExistingModel(name.trim());
            if (model) {
                metadata.model = model;
                metadata.modelId = model.id;
            }
            else {
                if (name.length > 50) {
                    this.model_err = "Model's name can't be longer than 50 character";
                }
                else if (name.length == 0) {
                    metadata.modelId = null;
                    metadata.model = null;
                }
                else {
                    metadata.modelId = oldMeta.modelId;
                    metadata.model = oldMeta.model;
                    metadata.model.name = name;
                }
            }
        }
        else { //Create new object
            let model = this.isExistingModel(name.trim());
            if (model) {
                metadata.model = model;
                metadata.modelId = model.id;
            }
            else {
                if (name.length > 50) {
                    this.model_err = "Model's name can't be longer than 50 character";
                }
                else if (name.length == 0) {
                    metadata.modelId = null;
                    metadata.model = null;
                }
                else {
                    metadata.model = { "name": name, "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                    metadata.modelId = 0;
                }
            }
        }
        this.setState({ update: true });
    }
    updateModelValue(name) {
        const { metadata, modelsdata, metadatas } = this.props;
        let model = this.isExistingModel(name.trim());
        if (model) {
            //this.model_err = "Name already exists";
            metadata.model = model;
            metadata.modelId = model.id;
        } else {
            //If it's a new, make sure we keep the old id
            let oldMeta = _.cloneDeep(metadatas.find(m => m.id == metadata.id));
            metadata.modelId = oldMeta.modelId;
            metadata.model = oldMeta.model;
            metadata.model.name = name;
        }

        if (name.length > 50) {
            this.model_err = "Model's name can't be longer than 50 character";
        }
        else if (name.trim().length == 0) {
            //this.model_err = "Please add Model name";
            this.model_err = "";
            metadata.modelId = null;
            metadata.model.id = null; //dont set the whole object, because the filed would become disabled
        }
        else {
            this.model_err = "";
        }

        this.setState({ update: true });
    }

    modelSuggestions(name) {
        let suggs = this.props.metadatas
            .filter(m => m.manufacturerId == this.props.metadata.manufacturer.id && m.modelId)
            .map(x => x.model)
            .filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index); //distinct
        if (name.length == 0)
            return suggs;
        if (suggs.some(m => m.name.toLowerCase() == name.toLowerCase().trim()))
            return suggs;
        return suggs.filter(m => m.name.toLowerCase().includes(name.toLowerCase().trim()));
    }
    updateModelTypeValue2(name) {
        const { metadata, metadatas } = this.props;
        this.modelType_err = "";
        let oldMeta = _.cloneDeep(metadatas.find(m => m.id == metadata.id));
        if (oldMeta.deviceTypeId) { //Update type
            let type = this.isExistingType(name.trim());
            if (type) {
                metadata.deviceType = type;
                metadata.deviceTypeId = type.id;
            }
            else {
                if (name.length > 50) {
                    this.modelType_err = "Type's name can't be longer than 50 character";
                }
                else if (name.length == 0) {
                    metadata.deviceTypeId = null;
                    metadata.deviceType = null;
                }
                else {
                    metadata.deviceTypeId = oldMeta.deviceTypeId;
                    metadata.deviceType = oldMeta.deviceType;
                    metadata.deviceType.name = name;
                }
            }
        }
        else { //Create new object
            let type = this.isExistingType(name.trim());
            if (type) {
                metadata.deviceType = type;
                metadata.deviceTypeId = type.id;
            }
            else {
                if (name.length > 50) {
                    this.modelType_err = "Type's name can't be longer than 50 character";
                }
                else if (name.length == 0) {
                    metadata.deviceTypeId = null;
                    metadata.deviceType = null;
                }
                else {
                    metadata.deviceType = { "name": name, "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
                    metadata.deviceTypeId = 0;
                }
            }
        }
        this.setState({ update: true });
    }
    updateModelTypeValue(name) {
        const { metadata, metadatas } = this.props;
        let type = this.isExistingType(name.trim());
        if (type) {
            //this.modelType_err = "Name already exists";
            metadata.deviceType = type;
            metadata.deviceTypeId = type.id;
        } else {
            //If it's a new, make sure we keep the old id
            let oldMeta = _.cloneDeep(metadatas.find(m => m.id == metadata.id));
            metadata.deviceTypeId = oldMeta.deviceTypeId;
            metadata.deviceType = oldMeta.deviceType;
            metadata.deviceType.name = name;
        }

        if (name.length > 50) {
            this.modelType_err = "Type's name can't be longer than 50 character";
        }
        else if (name.trim().length == 0) {
            //this.modelType_err = "Please add Type name";
            this.modelType_err = "";
            metadata.deviceTypeId = null;
            metadata.deviceType.id = null; //dont set the whole object, because the filed would become disabled
        }
        else {
            this.modelType_err = "";
        }

        this.setState({ update: true });
    }
    typeSuggestions(name) {
        let suggs = this.props.metadatas
            .filter(m => m.manufacturerId == this.props.metadata.manufacturer.id && m.deviceTypeId && m.modelId == this.props.metadata.modelId)
            .map(x => x.deviceType)
            .filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index); //distinct
        if (name.length == 0)
            return suggs;
        if (suggs.some(m => m.name.toLowerCase() == name.toLowerCase().trim()))
            return suggs;
        return suggs.filter(m => m.name.toLowerCase().includes(name.toLowerCase().trim()));
    }

    isExistingManufacturer(name) {
        let manu = this.props.manufacturersdata.find(m => m.name.toLowerCase() == name.toLowerCase());
        if (manu) {
            //return manu.id != this.props.metadata.manufacturerId;
            return manu;
        }
        else
            return false;
    }
    isExistingModel(name) {
        let model = this.props.modelsdata.find(m => m.name.toLowerCase() == name.toLowerCase());
        if (model) {
            //return model.id != this.props.metadata.modelId;
            return model;
        }
        else
            return false;
    }
    isExistingType(name) {
        let type = this.props.typesdata.find(t => t.name.toLowerCase() == name.toLowerCase());
        if (type) {
            //return type.id != this.props.metadata.deviceTypeId;
            return type;
        }
        else
            return false;
    }
    render() {
        const { metadataLoading, modelsdataLoading, typesdataLoading, manufacturersdataLoading } = this.state;
        const { metadata, modelsdata, typesdata, manufacturersdata, typeSuggestions } = this.props;

        if (metadataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        const heading = metadata && this.props.match.params.metaid != 0 ? 'Edit' : 'Create';

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Metadata - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                            <FormField label="Manufacturer *" error={this.manufacturer_err}>
                                <TextInput 
                                    value={metadata.manufacturer ? metadata.manufacturer.name : ""} 
                                    onChange={evt => this.updateManufacturerValue(evt.target.value)} 
                                    disabled={!metadata.manufacturerId} 
                                    onSuggestionSelect={ e => this.updateManufacturerValue(e.suggestion.value) }
                                    suggestions={
                                        //this.filterArrayItems(manufacturersdata, metadata.manufacturer.name)
                                        this.manufacturerSuggestions(metadata.manufacturer.name)
                                            .sort(this.sortingByName).map(ms => {
                                        return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                     })}
                                     dropHeight="medium"
                                />
                            </FormField>
                            <FormField label="Model" error={this.model_err}>
                                <TextInput 
                                    value={metadata.model ? metadata.model.name : ""}
                                    onChange={evt => this.updateModelValue2(evt.target.value)}
                                    //disabled={!metadata.model}
                                    onSuggestionSelect={e => this.updateModelValue2(e.suggestion.value) }
                                    suggestions={
                                        //this.filterArrayItems(modelsdata, metadata.model ? metadata.model.name : "")
                                        this.modelSuggestions(metadata.model ? metadata.model.name : "")
                                        .sort(this.sortingByName).map(ms => {
                                        return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                     })}
                                     dropHeight="medium"
                                />
                            </FormField>
                            <FormField label="Model Type" error={this.modelType_err}>
                                <TextInput 
                                    value={metadata.deviceType ? metadata.deviceType.name : ""} 
                                    onChange={evt => this.updateModelTypeValue2(evt.target.value)} 
                                    //disabled={!metadata.deviceType}
                                    onSuggestionSelect={ e => this.updateModelTypeValue2(e.suggestion.value) }
                                    suggestions={
                                        //this.state.typeSuggestions
                                        this.typeSuggestions(metadata.deviceType ? metadata.deviceType.name: "")
                                            .sort(this.sortingByName).map(ms => {
                                        return {
                                                label: (
                                                    <Box pad="small" justify="between" direction="row">
                                                        {ms.name}
                                                    </Box>),
                                                value: ms.name,
                                                option: ms
                                            };
                                     })}
                                     dropHeight="medium"
                                />
                            </FormField>
                        </StyledBox>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.metaid || '';
    if (state.selectedMetadataReducer.metadata && id == state.selectedMetadataReducer.metadata.id) {
        start = { ...state.selectedMetadataReducer.metadata };
        return {
            modelsdata: state.modelsReducer.modelsdata,
            typesdata: state.typesReducer.typesdata,
            manufacturersdata: state.manufacturersReducer.manufacturersdata,
            metadata: state.selectedMetadataReducer.metadata,
            metadatas: state.metadatasReducer.metadatas
        };
    }
    else {
        return {
        };
    }
};

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...ModelAction, ...TypeAction, ...ManufacturerAction, ...MetaDataAction }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgMetadataEditContainer));