import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Add, Android, Apple, Edit, Trash, Checkmark, Secure, CircleInformation } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea, TableBody, TableRow, TableCell, Table, TableHeader, Tab, Tabs, Layer, Grommet } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, Radial, StatusGoodSmall, Upload } from 'grommet-icons';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpConfirm from './common/PopUpConfirm';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { ThemeContext } from 'styled-components';
import RB from '../components/common/RBAC';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";

var start = undefined;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        },
        margin: "none",
        pad: "none",
        error: {
            margin: "none",
        }
    },
    global: {
        focus: {
            outline: {
                color: "brand"
            },
            border: {
                color: "brand"
            }
        }
    }
});

const ffTheme = deepMerge(hpe, {
    formField: {
        border: {
            color: "none",
        },
    },
    box: {
        extend: {
            "border-radius": "5px",
        }
    }
});

export class AppDetails extends React.Component {
    constructor() {
        super();
        this.newdom = "";
        this.state = {
            validating: false,
            newdomain: true,
            selected: -1,
            domAddKey: Math.random(),
            isSaved: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            index: 0,
            privacyenabled: undefined,
            inputfordomain: "",
            domainforedit: "",
            domaineditover: true,
            domainerrors: 0,
            organisationdataLoading: true,
            organisationsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        this.handleAddDomain = this.handleAddDomain.bind(this);
    }

    editorURLs = [];

    //Tab 0
    appuri_err = '';
    domains_err = '';
    domainedit_err = '';
    iosurl_err = '';
    androidurl_err = '';
    deeplink_err = '';

    //Tab 1
    text_err = '';
    privacyurl_err = '';
    termsText_err = '';
    termsofuseurl_err = '';

    //Tab 2
    supportEmail_err = "";

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const orgid = constants.organisation.id;
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Organisations List Error', error);
            });
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    componentWillReceiveProps() {
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //Tab 0
        //this.appuri_err = '';
        this.domains_err = '';
        this.domainedit_err = '';
        this.iosurl_err = '';
        this.androidurl_err = '';
        //this.deeplink_err = '';

        //Tab 1
        this.text_err = '';
        this.privacyurl_err = '';
        this.termsText_err = '';
        this.termsofuseurl_err = '';

        //Tab 2
        this.supportEmail_err = "";
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;

        // Check missing fields
        //if (!organisationdefault.appURI) { organisationdefault.appURI = '' };
        //if (!organisationdefault.privacyURL) { organisationdefault.privacyURL = '' };
        //if (!organisationdefault.termsOfUseURL) { organisationdefault.termsOfUseURL = '' };
        //if (!organisationdefault.domains) { organisationdefault.domains = '' };

        var errortab = -1;
        let errorsno = 0;
        if (this.domainedit_err) {
            errorsno = 1;
            errortab = 0;
        }
        if (this.domains_err) {
            if (this.newdom.length > 0) {
                errorsno = 1;
                errortab = 0;
            }
            else
                this.domains_err = "";
        }
        if (this.state.selected != -1) {
            console.log("jeezSelect", this.state.selected);
            this.domainedit_err = "Please finish editing before saving the page";
            errorsno++;
            errortab = 0;
        }
        if (this.newdom != "") {
            this.domains_err = "Please finish creating before saving the page";
            errorsno++;
            errortab = 0;
        }
        //Settings
        //if (organisationdefault.appURI.trim().length !== 0 && !(validator.isURL(organisationdefault.appURI, constants.URL_OPTIONS))) {
        //    errorsno++;
        //    this.appuri_err = constants.URL_ERROR;
        //    errortab = 0;
        //}
        if (this.appuri_err) {
            errorsno++;
            errortab = 0;
        }
        if (this.validateDomains(organisationdefault.domains) !== '') {
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.iosDownloadURL.trim() != "" && !(this.validateDownloadURL(organisationdefault.iosDownloadURL, "ios") && constants.REGEX.URI.test(organisationdefault.iosDownloadURL))) {
            errorsno++;
            this.iosurl_err = "Please ensure the URL is properly formed (include http://, https://, itms:// or itms-apps:// as required)";
            errortab = 0;
        }
        if (organisationdefault.androidDownloadURL.trim() != "" && !(this.validateDownloadURL(organisationdefault.androidDownloadURL, "android") && constants.REGEX.URI.test(organisationdefault.androidDownloadURL))) {
            errorsno++;
            this.androidurl_err = "Please ensure the URL is properly formed (include http://, https:// or market:// as required)";
            errortab = 0;
        }
        if (this.deeplink_err) {
            errorsno++;
            errortab = 0;
        }

        //Legal
        if (organisationdefault.privacyText.length > 50) {
            errorsno++;
            this.text_err = "Display Text can't be longer than 50 characters";
            errortab = 1;
        }
        if (organisationdefault.privacyURL.trim().length != 0 && organisationdefault.privacyURL.substr(organisationdefault.privacyURL.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(organisationdefault.privacyURL)) {
            errorsno++;
            this.privacyurl_err = constants.URL_ERROR;
            errortab = 1;
        }
        if (organisationdefault.privacyURL.trim().length != 0 && organisationdefault.privacyURL.substr(organisationdefault.privacyURL.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(organisationdefault.privacyURL)) {
            errorsno++;
            this.privacyurl_err = constants.URL_ERROR_HTTPS_ONLY;
            errortab = 1;
        }
        if (organisationdefault.privacyURL.length > 511) {
            errorsno++;
            this.privacyurl_err = "URL can't be longer than 511 characters";
            errortab = 1;
        }
        if (organisationdefault.termsOfUseText.length > 50) {
            errorsno++;
            this.termsText_err = "Display Text can't be longer than 50 characters";
            errortab = 1;
        }
        if (organisationdefault.termsOfUseURL.trim().length !== 0 && organisationdefault.termsOfUseURL.substr(organisationdefault.termsOfUseURL.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(organisationdefault.termsOfUseURL)) {
            errorsno++;
            this.termsofuseurl_err = constants.URL_ERROR;
            errortab = 1;
        }
        if (organisationdefault.termsOfUseURL.trim().length !== 0 && organisationdefault.termsOfUseURL.substr(organisationdefault.termsOfUseURL.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(organisationdefault.termsOfUseURL)) {
            errorsno++;
            this.termsofuseurl_err = constants.URL_ERROR_HTTPS_ONLY;
            errortab = 1;
        }
        if (organisationdefault.termsOfUseURL.length > 511) {
            errorsno++;
            this.termsofuseurl_err = "URL can't be longer than 511 characters";
            errortab = 1;
        }

        //Support tab
        if (organisationdefault.supportEmailAddress) {
            if (organisationdefault.supportEmailAddress.length > 255) {
                errorsno++;
                this.supportEmail_err = "E-mail address can't be longer than 255 characters";
                errortab = 2;
            }
        }
        if (!validator.isEmail(organisationdefault.supportEmailAddress) && organisationdefault.supportEmailAddress.length != 0) {
            errorsno++;
            this.supportEmail_err = constants.EMAIL_ERROR;
            errortab = 2;
        }

        //Saving
        if (errorsno === 0) {
            this.setState({ disabledButton: true });
            console.log('Saving: ', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok, imagechanged: false });
                    //this.props.history.push('/orgsites');
                }).catch(error => {
                    console.log('*DEBUG*-Save AppDetails Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.onActive(errortab);
        }
    }

    handleCancel() {
        // window.location.reload();
        if (!this.state.cancel && (!_.isEqual(start, this.props.organisationdata) || this.state.imagechanged)) {
            this.setState({ cancel: true });
        }
        else {
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getOrgLevelRoute() + '/appdetails');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Organisation Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/appdetails');
                });
        }
    }

    validateDownloadURL(url, type) {
        if (type == "ios") {
            if (url.trim().startsWith("http://") || url.trim().startsWith("https://") || url.trim().startsWith("itms://") || url.trim().startsWith("itms-apps://")) {
                return true;
            }
            else return false;
        }
        else if (type == "android") {
            if (url.trim().startsWith("http://") || url.trim().startsWith("https://") || url.trim().startsWith("market://")) {
                return true;
            }
            else return false;
        }
        return false;
    }

    validateDomains(domstr) {
        let domerrors = '';
        if (domstr) {
            domstr.split(',').forEach(dom => {
                let gooddom = dom.startsWith('@') && validator.isFQDN(dom.substr(1));
                domerrors = gooddom ? domerrors : domerrors.concat(', ', `Error with '${dom}'`)
            });
        }
        return domerrors;
    }
    isValidDomain(dom) {
        return (dom.startsWith('@') && validator.isFQDN(dom.substr(1)));
    }

    isUniqueDomain(domainlist, domain) {
        if (domainlist.flat().includes(domain)) {
            return false
        }
        else if (!domainlist.flat().includes(domain)) {
            return true
        }
    }

    updateDomain(domain, id) {
        let dom = [...this.props.organisationdata.domains.split(',')];
        dom[id] = domain;
        this.setState({ inputfordomain: dom[id] });
        if (this.isValidDomain(dom[id]) && this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), dom[id])) {
            let domstr = dom.join(',');
            this.props.organisationdata.domains = domstr;
        }
    }
    isOccuredOnlyOnce(str, sub) {
        let first = str.indexOf(sub);
        let last = str.lastIndexOf(sub);
        return first == last && first != -1;
    }
    updateAppURI(evt) {
        this.props.organisationdata.appURI = evt.target.value;
        if (this.props.organisationdata.appURI.length > 0 && (!constants.REGEX.URI2.test(this.props.organisationdata.appURI) || !this.isOccuredOnlyOnce(this.props.organisationdata.appURI, "://"))) {
            this.appuri_err = constants.URI_ERROR +" .This should only appear once and be placed at the end of the URI name";
        } else {
            this.appuri_err = "";
        }
        this.deeplink_err = /*!this.props.organisationdata.appURI*/ (!constants.REGEX.URI2.test(this.props.organisationdata.appURI) || !this.isOccuredOnlyOnce(this.props.organisationdata.appURI, "://")) && this.props.organisationdata.contentUriEnabled ? "Deeplinks can only be active when there is a valid App URI" : "";
        this.setState({update: true});
    }
    updatePrivacyURL(evt) {
        this.props.organisationdata.privacyURL = evt.target.value;
    }
    minAppAndroid(evt) {
        this.props.organisationdata.androidMinAppVersion = evt.target.value;
    }
    minAppIOS(evt) {
        this.props.organisationdata.iosMinAppVersion = evt.target.value;
    }
    urlAndroid(evt) {
        this.props.organisationdata.androidDownloadURL = evt.target.value;
    }
    urlIOS(evt) {
        this.props.organisationdata.iosDownloadURL = evt.target.value;
    }
    minOSversionAndroid(evt) {
        this.props.organisationdata.androidMinOSVersion = evt.target.value;
    }
    minOSversionIOS(evt) {
        this.props.organisationdata.iosMinOSVersion = evt.target.value;
    }

    handleAddDomain(evt) {
        if (this.newdom.length > 0) {
            if (this.isValidDomain(this.newdom) && this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.newdom) && this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.newdom)) {
                let domains = this.props.organisationdata.domains ? this.props.organisationdata.domains : '';
                domains = domains.length > 0 ? domains.concat(',', this.newdom) : this.newdom;
                this.newdom = '';
                this.props.organisationdata.domains = domains;
                this.domains_err = "";
                this.state.domainerrors = 0;
                this.setState({ domAddKey: Math.random() });
            }
            else if (!this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.newdom) &&
                this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.newdom)) {
                this.domains_err = constants.DOMAIN_NOT_UNIQUE_ERROR;
                this.setState({});
            }
            else if (!this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.newdom)) {
                this.domains_err = constants.DOMAIN_THIS_ORG_ERROR;
                this.setState({});
            }
            else {
                this.domains_err = constants.DOMAIN_INVALID_ERROR;
                this.state.domainerrors++;
                this.setState({});
            }
        }
        else if (this.newdom.length == 0) {
            this.domains_err = constants.DOMAIN_INVALID_ERROR;
            this.state.domainerrors = 0;
            this.setState({});
        }
    }

    updatePrivacyTextValue(evt) {
        this.props.organisationdata.privacyText = evt.target.value;
        if (evt.target.value.length > 50) {
            this.text_err = "Display Text can't be longer than 50 characters";
        }
        else {
            this.text_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateTermsTextValue(evt) {
        this.props.organisationdata.termsOfUseText = evt.target.value
        if (evt.target.value.length > 50) {
            this.termsText_err = "Display Text can't be longer than 50 characters";
        }
        else {
            this.termsText_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    updateThirdPartyTextValue(evt) {
        this.props.organisationdata.thirdPartyNoticesText = evt.target.value
    };

    updatePrivacyUrlValue(evt) {
        this.props.organisationdata.privacyURL = evt.target.value;
        if (this.props.organisationdata.privacyURL.length > 511) {
            this.privacyurl_err = "URL can't be longer than 511 characters";
        }
        else this.privacyurl_err = "";
        this.setState({ updated: true });
    };

    updateTermsUrlValue(evt) {
        this.props.organisationdata.termsOfUseURL = evt.target.value;
        if (this.props.organisationdata.termsOfUseURL.length > 511) {
            this.termsofuseurl_err = "URL can't be longer than 511 characters";
        }
        else this.termsofuseurl_err = "";
        this.setState({ updated: true });
    };

    updatePrivacyAuthStatus(evt) {
        this.props.organisationdata.privacyURLAuth = !this.props.organisationdata.privacyURLAuth;
        this.setState({ updated: true });
    };

    updateTermsAuthStatus(evt) {
        this.props.organisationdata.termsOfUseURLAuth = !this.props.organisationdata.termsOfUseURLAuth;
        this.setState({ updated: true });
    };

    updateSupportEmailAddress(evt) {
        this.props.organisationdata.supportEmailAddress = evt.target.value
        if (evt.target.value.length > 255) {
            this.supportEmail_err = "E-mail address can't be longer than 255 characters";
        }
        else {
            this.supportEmail_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };

    handleAddDomainChange = evt => {
        this.domains_err = "";
        //this.setState({ domainerrors: 0 });
        this.newdom = evt.target.value;
        this.setState({});
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/orgsites');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        this.setState({ index: index });
    }
    onDeepLinksChange = () => {
        this.props.organisationdata.contentUriEnabled = !this.props.organisationdata.contentUriEnabled;
        if (!this.props.organisationdata.contentUriEnabled)
            this.deeplink_err = "";
        if (this.props.organisationdata.contentUriEnabled && this.appuri_err != "")
            this.deeplink_err = "Deeplinks can only be active when there is a valid App URI";
        this.setState({ update: true });
    }
    render() {
        const organisationdefault = this.props.organisationdata;
        const { organisationdataLoading, organisationsdataLoading } = this.state;

        if (organisationdataLoading, organisationsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        let domarray = this.props.organisationdata.domains ? this.props.organisationdata.domains.split(',') : [];

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >App Config</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Tabs justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="Settings">
                                <Box>
                                    <Box pad={{ vertical: "small" }}>
                                        {/*<Text>All fields marked with an * are mandatory.</Text>*/}
                                        <Text size="large">App Details</Text>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label='App URI' error={this.appuri_err}>
                                            <TextInput defaultValue={organisationdefault.appURI} onChange={evt => this.updateAppURI(evt)} disabled /*readOnly disabled*/ />
                                        </FormField>
                                        <Box direction="row">
                                            <FormField contentProps={{ width: "50px" }} label='DeepLinks' error={this.deeplink_err} data-for="deeplink_checkbox" data-tip>
                                                <CheckBox
                                                    checked={organisationdefault.contentUriEnabled}
                                                    disabled={!organisationdefault.appURI && !organisationdefault.contentUriEnabled}
                                                    onChange={this.onDeepLinksChange}
                                                />
                                            </FormField>
                                            <Box margin={{ "left": "10px" }} pad={{ "top": "45px" }}>
                                                <CircleInformation data-for="deeplink_info" data-tip />
                                            </Box>
                                            
                                            <ReactTooltip id="deeplink_checkbox" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Check to activate deeplinking in your client</span>
                                            </ReactTooltip>
                                            <ReactTooltip id="deeplink_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>When enabled, a deeplink will be automaticaly generated for any content pages or<br />
                                                    placemarks added for your site. Deeplinks can only be active when there is a valid App URI.
                                                </span>
                                            </ReactTooltip>
                                        </Box>

                                        <Grommet theme={ffTheme} >
                                            <FormField label='Domains' contentProps={{ width: "60%" }}>
                                                <Box border="all" gap="xxsmall">
                                                    {domarray.map((domain, idx) => (
                                                        <Box direction="row" key={idx} pad={{ "left": "xxsmall", "top": "xxsmall" }} justify="between">
                                                            <Box height="100%" width="80%">
                                                                <Grommet theme={customTheme} >
                                                                    <FormField error={this.state.selected == domain ? this.domainedit_err : ""}>
                                                                        <TextInputWithState
                                                                            value={domain} plain={!(this.state.selected == domain)}
                                                                            disabled={!(this.state.selected == domain)}
                                                                            onInput={evt => this.setState({ inputfordomain: evt.target.value })}
                                                                        />
                                                                    </FormField>
                                                                </Grommet>
                                                            </Box>

                                                            <Box direction="row">
                                                                {this.state.selected == domain ? (
                                                                    <Button icon={<Checkmark />}
                                                                        onClick={() => {
                                                                            let duplicationList = this.props.organisationdata.domains.split(",");
                                                                            duplicationList.splice(idx, 1);
                                                                            if (this.isValidDomain(this.state.inputfordomain) &&
                                                                                (this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.state.inputfordomain) ||
                                                                                this.state.inputfordomain == this.state.domainforedit) &&
                                                                                this.isUniqueDomain(duplicationList, this.state.inputfordomain)) {
                                                                                this.setState({ selected: -1, domaineditover: true });
                                                                                this.domainedit_err = "";
                                                                                this.updateDomain(this.state.inputfordomain, idx);
                                                                                this.state.domainerrors = 0;
                                                                            }
                                                                            else if (!this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.state.inputfordomain) &&
                                                                                this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.state.inputfordomain) &&
                                                                                this.state.inputfordomain != this.state.domainforedit) {
                                                                                this.domainedit_err = constants.DOMAIN_NOT_UNIQUE_ERROR;
                                                                                this.setState({ selected: domain });
                                                                            }
                                                                            else if (!this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.state.inputfordomain) &&
                                                                                this.state.inputfordomain != this.state.domainforedit) {
                                                                                this.setState({ selected: domain });
                                                                                this.domainedit_err = constants.DOMAIN_THIS_ORG_ERROR;
                                                                            }
                                                                            else {
                                                                                this.domainedit_err = constants.DOMAIN_INVALID_ERROR;
                                                                                //this.state.domainerrors++;
                                                                                this.setState({ selected: domain });
                                                                            }
                                                                        }} />
                                                                ) : (
                                                                    <Button icon={<Edit />}
                                                                        onClick={() => {
                                                                            let dom = [...this.props.organisationdata.domains.split(',')];
                                                                            if (this.state.domaineditover) {
                                                                                this.setState({ selected: domain, domainforedit: dom[idx], inputfordomain: dom[idx], domaineditover: false });
                                                                                //this.state.domainerrors++;
                                                                            }
                                                                        }} />
                                                                )}
                                                                <Button icon={<Trash />}
                                                                    onClick={() => {
                                                                        let data = domarray;
                                                                        data.splice(idx, 1)
                                                                        console.log(data);
                                                                        // this.setState({ domains: data });
                                                                        this.props.organisationdata.domains = data.join(',');
                                                                        if (domain == this.state.selected) {
                                                                            this.domainedit_err = "";
                                                                            this.setState({ selected: -1, domaineditover: true });
                                                                        }
                                                                        else
                                                                            this.setState({ update: true});
                                                                    }} />
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                    <Box margin={{ "vertical": "xxsmall" }}>
                                                        <Box direction="row" margin={{ "left": "xxsmall" }} justify="between">
                                                            <Box height="100%" width="80%">
                                                                <Grommet theme={customTheme} >
                                                                    <FormField error={this.domains_err}>
                                                                        <TextInputWithState key={this.state.domAddKey} placeholder="New Domain" value={this.newdom} plain onInput={this.handleAddDomainChange} />
                                                                    </FormField>
                                                                </Grommet>
                                                            </Box>
                                                            <Box margin={{ "right": "small" }}>
                                                                <StyledButton typeAdd label="Add" onClick={this.handleAddDomain} margin={{ "vertical": "xsmall" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </FormField>
                                        </Grommet>
                                    </StyledBox>
                                    <Box width="100%" margin={{ "bottom": "small" }}>
                                        <Box pad={{ vertical: 'small' }} >
                                            <Box pad={{ vertical: "small" }}><Text size="large">App Release Matrix</Text></Box>
                                            <StyledBox style1 pad="small">
                                                <Table>
                                                    <TableHeader>
                                                        <TableRow>
                                                            <TableCell > </TableCell>
                                                            <TableCell align="center" ><Apple /><Text>IOS</Text></TableCell>
                                                            <TableCell align="center"><Android /><Text>ANDROID</Text></TableCell>
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell border="all" align="center"><Text>Min App Version</Text></TableCell>
                                                            <TableCell border="all"><TextInput plain defaultValue={organisationdefault.iosMinAppVersion} onChange={evt => this.minAppIOS(evt)} /></TableCell>
                                                            <TableCell border="all"><TextInput plain defaultValue={organisationdefault.androidMinAppVersion} onChange={evt => this.minAppAndroid(evt)} /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell border="all" align="center"><Text>Download URL</Text></TableCell>
                                                            <TableCell border="all">
                                                                <Grommet theme={customTheme} >
                                                                    <FormField error={this.iosurl_err} plain>
                                                                        <TextInput plain defaultValue={organisationdefault.iosDownloadURL} onChange={evt => this.urlIOS(evt)} />
                                                                    </FormField>
                                                                </Grommet>
                                                            </TableCell>
                                                            <TableCell border="all">
                                                                <Grommet theme={customTheme} >
                                                                    <FormField error={this.androidurl_err} plain>
                                                                        <TextInput plain defaultValue={organisationdefault.androidDownloadURL} onChange={evt => this.urlAndroid(evt)} />
                                                                    </FormField>
                                                                </Grommet>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell border="all" align="center"><Text>Min OS Version</Text></TableCell>
                                                            <TableCell border="all">
                                                                <TextInput plain defaultValue={organisationdefault.iosMinOSVersion} onChange={evt => this.minOSversionIOS(evt)} />
                                                            </TableCell>
                                                            <TableCell border="all"><TextInput plain defaultValue={organisationdefault.androidMinOSVersion} onChange={evt => this.minOSversionAndroid(evt)} /></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </StyledBox>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title="Legal">
                                <Box gap="small" pad={{ "bottom": "medium" }}>
                                    <Box pad={{ "top": "small" }}>
                                        <Text size="large">Privacy Policy</Text>
                                        <Text>When populated, the privacy policy will be displayed on the app about page.</Text>
                                    </Box>
                                    <Box direction="row">
                                        <StyledBox style1 direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }} flex="shrink">
                                            <Box width="400px">
                                                <FormField error={this.text_err} label={<Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box>}>
                                                    <TextInput defaultValue={organisationdefault.privacyText} onChange={evt => this.updatePrivacyTextValue(evt)} />
                                                </FormField>
                                            </Box>
                                            <Box width="900px">
                                                <FormField error={this.privacyurl_err} label={<Box direction="row" gap="xsmall" align="center"><Link />URL</Box>}>
                                                    <TextInput defaultValue={organisationdefault.privacyURL} onChange={evt => this.updatePrivacyUrlValue(evt)} />
                                                </FormField>
                                            </Box>
                                            {organisationdefault.privacyURL.trim().substr(organisationdefault.privacyURL.trim().length - 4).toLowerCase() == ".pdf" &&
                                                <Box direction="row" style={{ "minWidth": "200px" }}>
                                                    <FormField label={
                                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                            <Box direction="row" gap="xsmall" justify="between">
                                                                <Secure />
                                                                Authentication Status:
                                                                </Box>
                                                            <Box>
                                                                <a data-for="privacy_auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here.">
                                                                    <CircleInformation />
                                                                </a>
                                                                <ReactTooltip id="privacy_auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Box>
                                                        </Box>}>
                                                        <Select
                                                            options={["Authenticated", "Not Authenticated"]}
                                                            value={organisationdefault.privacyURLAuth ? "Authenticated" : "Not Authenticated"}
                                                            onChange={evt => this.updatePrivacyAuthStatus(evt)} />
                                                    </FormField>
                                                </Box>}
                                        </StyledBox>
                                    </Box>
                                    <Box pad={{ "top": "small" }}>
                                        <Text size="large">Terms of Use</Text>
                                        <Text>The Terms of use will be displayed on the app about page. Please update as required and include the URL to the full terms of use policy.</Text>
                                    </Box>
                                    <Box direction="row">
                                        <StyledBox style1 direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }} flex="shrink">
                                            <Box width="400px">
                                                <FormField error={this.termsText_err} label={<Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box>}>
                                                    <TextInput defaultValue={organisationdefault.termsOfUseText} onChange={evt => this.updateTermsTextValue(evt)} />
                                                </FormField>
                                            </Box>
                                            <Box width="900px">
                                                <FormField error={this.termsofuseurl_err} label={<Box direction="row" gap="xsmall" align="center"><Link />URL</Box>}>
                                                    <TextInput defaultValue={organisationdefault.termsOfUseURL} onChange={evt => this.updateTermsUrlValue(evt)} />
                                                </FormField>
                                            </Box>
                                            {organisationdefault.termsOfUseURL.trim().substr(organisationdefault.termsOfUseURL.trim().length - 4).toLowerCase() == ".pdf" &&
                                                <Box direction="row" style={{ "minWidth": "200px" }}>
                                                    <FormField label={
                                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                                            <Box direction="row" gap="xsmall" justify="between">
                                                                <Secure />
                                                                Authentication Status:
                                                                </Box>
                                                            <Box>
                                                                <a data-for="terms_auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here.">
                                                                    <CircleInformation />
                                                                </a>
                                                                <ReactTooltip id="terms_auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Box>
                                                        </Box>}>
                                                        <Select
                                                            options={["Authenticated", "Not Authenticated"]}
                                                            value={organisationdefault.termsOfUseURLAuth ? "Authenticated" : "Not Authenticated"}
                                                            onChange={evt => this.updateTermsAuthStatus(evt)} />
                                                    </FormField>
                                                </Box>}
                                        </StyledBox>
                                    </Box>
                                </Box>
                            </Tab>
                            {RB.isSuperUser() &&
                                <Tab title="Support">
                                    <Box pad={{ "top": "small", "bottom": "medium" }}>
                                        <Text size="large">Support</Text>
                                        <Text>You can change the e-mail address here</Text>
                                        <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "small" }} width="350px">
                                            <FormField label="E-mail Address" error={this.supportEmail_err} >
                                                <TextInput disabled={RB.isSuperUser() ? false : true} defaultValue={organisationdefault.supportEmailAddress} onChange={evt => this.updateSupportEmailAddress(evt)} />
                                            </FormField>
                                        </StyledBox>
                                    </Box>
                                </Tab>}
                        </Tabs>
                    </form>
                </Box>

            </Grid>

        );
    }
}


const mapStateToProps = (state, ownProps) => {
    //const organisationId = constants.organisation.id;
    const organisationId = sessionStorage.getItem("organisationId");
    if (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            organisationsdata: state.organisationsReducer.organisationsdata
        };
    } else {
        return {};
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction }, dispatch),
    action: bindActionCreators(OrganisationAction, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppDetails));
