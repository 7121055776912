import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/MergedLevel`;
const SERVER_URL_PUT = `${SERVER}/tables/MergedLevelCapacity`;
console.log('HOST:', SERVER_URL);

class MergedLevelApi {
    static async getAllLevels() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }
    static async getLevel(id) {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${id}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
    static async saveMergedLevel(mergedleveldata) {
        if (mergedleveldata.id && mergedleveldata.id != "0") {
            return fetch(`${SERVER_URL_PUT}/${mergedleveldata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(mergedleveldata)
            }).then(response => response);
        }
    }
}

export default MergedLevelApi;
