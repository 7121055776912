import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Chart, Meter, Diagram, Stack, Node } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';


class NoUser extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box>
                <Box>
                    <Text>404 NOT FOUND</Text>
                </Box>
            </Box>
        );
    }
}


export default connect()(NoUser);
