import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const assetsOverviewReducer = (state = initialState.assetsOverviewReducer, action) => {
    switch(action.type) {
        case ActionType.GET_ASSETS_OVERVIEW_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                assetsoverviewdata: _.assign(action.assetsoverviewdata)
            };
        }

        default: { return state; }
    }
};


export default assetsOverviewReducer;