import { useState, useEffect, useContext } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField } from 'grommet';
import { Edit, CircleInformation, Next, FormNext, FormPrevious, Download } from 'grommet-icons';
import AppInsightsApi from '../api/AppInsightsApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import ReactTooltip from "react-tooltip";
import { Bar, Pie, Line } from 'react-chartjs-2';
import LoadingIndicator from './common/LoadingIndicator';
import { worldMill, worldMerc } from '@react-jvectormap/world';
import { VectorMap } from "@react-jvectormap/core";


const AnalyticsCountries = () => {
    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const [countryChart, setCountryChart] = useState([]);
    const [mapDataLoading, setMapDataLoading] = useState(true);
    const [mapData, setMapData] = useState([]);
    const [countriesCount, setCountriesCount] = useState();

    const [sessionsCount, setSessionsCount] = useState();
    const [sessionsAVG, setSessionsAVG] = useState();
    const [sessionsLengthAVG, setSessionsLengthAVG] = useState();

    const [eventsCount, setEventsCount] = useState();
    const [eventsAVG, setEventsAVG] = useState();
    const [errorsCount, setErrorsCount] = useState();
    const [handledErrorsCount, setHandledErrorsCount] = useState();
    const [unhandledErrorsCount, setUnhandledErrorsCount] = useState();

    const { time, site, targetOS, version, count, axesColor } = useContext(AnalyticsContext);
    console.log("AnalyticsContext", site, targetOS, version, count);


    const getTimespan=()=> {

        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    const cmp = (a, b) => {
        const alc = a.value;
        const blc = b.value;
        if (alc < blc) {
            return 1;
        }
        else if (alc > blc) {
            return -1;
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        AppInsightsApi.getWorldUsers(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let countryIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "client_CountryOrRegion") {
                        countryIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        countryIdx = 1;
                        valueIdx = 0;
                    }
                    let map = {};
                    var list = [];
                    data.tables[0].rows.forEach(row => {
                        list.push({
                            name: row[countryIdx].toUpperCase(),
                            value: row[valueIdx]
                        });
                        map[row[countryIdx].toUpperCase()] = row[valueIdx];
                    });
                    console.log("hitView",map, list);

                    setMapData(map);
                    setCountryChart(list.sort(cmp));
                    setMapDataLoading(false);
                }
            })
            .catch(error => {
                //var errorArray = this.state.apiErrorList.concat(error);
                //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getWorldUsers Error', error);
            });
        AppInsightsApi.getCountryCount(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setCountriesCount(constants.convertToK(data.tables[0].rows[0][0]));
            });
    }, [count])

    const axisMaxValue=(maxValue)=> {
        if (maxValue <= 5) { return 5 }
        else if (maxValue <= 10) { return 10 }
        else if (maxValue <= 200) { return Math.ceil(maxValue / 10) * 10 }
        else if (maxValue <= 2000) { return Math.ceil(maxValue / 100) * 100 }
        else if (maxValue <= 20000) { return Math.ceil(maxValue / 1000) * 1000 }
        else { return Math.ceil(maxValue / 10000) * 10000 }
    }

    const dataMaxValue=(data)=> {
        if (data) {
            if (data.length > 1) {
                const max = data.reduce((prev, current) => (prev.value > current.value) ? prev : current)
                return max.value
            }
            else {
                return 0
            }
        }
    }

    return (
        <StyledBox style1
            direction="column"
            //margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
            
            height="350px">
            <Box direction="row-responsive" gap="small" height="100%" border={{ "side": "bottom" }} >
                <Box justify="between"
                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                    
                    round="xsmall"
                    basis="50%">
                    <Box height="20%" direction="column-responsive" justify="between" margin={{ "bottom": "none" }}>
                        <Heading level='3' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                            Installs
                        </Heading>
                        <Text weight="bolder" size="5xl">
                            {constants.convertToK(countryChart.reduce((acc, curr) => acc + curr.value, 0))}
                        </Text>
                    </Box>
                    <Box height="85%"  margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                        {mapDataLoading ? <LoadingIndicator plain /*error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}*/ /> :
                            <VectorMap
                                map={worldMill}
                                backgroundColor="transparent"
                                containerStyle={{
                                    width: '100%',
                                    height: '90%'
                                }}
                                regionStyle={{
                                    initial: {
                                        fill: "#e4e4e4",
                                        "fill-opacity": 0.9,
                                        stroke: "none",
                                        "stroke-width": 0,
                                        "stroke-opacity": 0
                                    },
                                    hover: {
                                        "fill-opacity": 0.8
                                    },
                                    selectedHover: {}
                                }}
                                series={{
                                    regions: [
                                        {
                                            values: mapData,
                                            scale: ["#32DAC8"],
                                            normalizeFunction: "polynomial"
                                        }
                                    ],
                                }}
                                zoomOnScroll={true}
                                onRegionTipShow={(function (e, el, code) {
                                    el.html(el.html() + " (" + (mapData[code] ? mapData[code] : "0") + ")");

                                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el, nro) => {
                                        if (nro < Array.from(document.getElementsByClassName("jvectormap-tip")).length - 1) {
                                            el.style.display = "none";
                                            el.remove();
                                        }
                                    });

                                }).bind(this)
                                }
                            />
                        }
                    </Box>
                </Box>
                <Box background="transparent"
                    
                    justify="between"
                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                    round="xsmall"
                    basis="50%">
                    <Box height="15%" direction="column-responsive" justify="between">
                        <Heading level='3' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                            <Box direction="column-responsive" gap="xsmall">
                                Countries
                                <Box justify="center" height="25px" alignSelf="center">
                                    <a data-for="Top countries" data-tip="The top countries by user installs.<br />In the world map the countries with the darker shades are the ones with the most active users">
                                        <CircleInformation size="medium" color="#444444" />
                                    </a>
                                    <ReactTooltip id="Top countries" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                </Box>
                            </Box>
                        </Heading>
                        <Text weight="bolder" size="5xl">
                            {countriesCount}
                        </Text>
                    </Box>
                    <Box height="85%" 
                        margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                        {mapDataLoading ? <LoadingIndicator plain /*error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}*/ /> :
                            <Bar
                                data={{
                                    labels: countryChart.map(item => `${item.name}`),
                                    datasets: [
                                        {
                                            label: "Top Countries",
                                            data: countryChart.map(item => item.value),
                                            borderWidth: 0,
                                            backgroundColor: "#32DAC8",
                                            hoverBackgroundColor: "#32DAC8"
                                        }
                                    ]
                                }}
                                options={{
                                    legend: {
                                        display: false
                                    },
                                    scales: {
                                        yAxes: [{
                                            gridLines: {
                                                drawOnChartArea: false,
                                                color: axesColor
                                            },
                                            ticks: {
                                                min: 0,
                                                max: axisMaxValue(dataMaxValue(countryChart)),
                                                stepSize: axisMaxValue(dataMaxValue(countryChart)) / 5
                                            }
                                        }],
                                        xAxes: [{
                                            barPercentage: 0.4,
                                            gridLines: {
                                                drawOnChartArea: false,
                                                color: axesColor
                                            },
                                        }]
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />}
                    </Box>
                </Box>
            </Box>
        </StyledBox>
    );
}

export default AnalyticsCountries;