import * as ActionType from './ActionType';
import AssetOverviewApi from '../api/AssetOverviewApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getAssetsOverviewResponse = assetsoverviewdata => ({
    type: ActionType.GET_ASSETS_OVERVIEW_RESPONSE,
    assetsoverviewdata
});

export function getAssetsOverviewAction(DAYS=7, BUILDINGID="", LABELID="") {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AssetOverviewApi.getAllAssets(DAYS, BUILDINGID, LABELID)
            .then(assetsoverviewdata => {
                dispatch(getAssetsOverviewResponse(assetsoverviewdata));
                return assetsoverviewdata;
            }).catch(error => {
                throw error;
            });
    };
}