import React, { Component } from 'react';


function withCorrectedSearch(GrommetDataTable) {
    return class extends Component {
          render() {
            const { data, ...restprops } = this.props;
            const DATA = data;

            class ServedDataTable extends Component {
                state = { data: DATA };

                escapeRegexp = (string) => {
                    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
                }

                customSearch = search => {
                    let fdata;
                    if (search) {
                        const searchExpressions = Object.keys(search).map(property => ({
                            property,
                            exp: new RegExp(this.escapeRegexp(search[property]), 'i')
                        }));
                        fdata = DATA.filter(
                            d => !searchExpressions.some(e => !e.exp.test(d[e.property]))
                        );
                    } else {
                        fdata = DATA;
                    }
                    this.setState({ data: fdata });
                };

                render () {
                    return <GrommetDataTable data={this.state.data} onSearch={this.customSearch} {...restprops} />
                }
            }
            return <ServedDataTable />
        }
    }
};

export default withCorrectedSearch;




