//This is to ensure that we can see the entirety of the store

export default {
    quickActionsReducer: {
        quickactionsdata: []
    },

    selectedQuickActionReducer: {
        quickactiondata: null
    },

    eventsReducer: {
        eventsdata: []
    },

    selectedEventReducer: {
        eventdata: null
    },

    categoriesReducer: {
        categoriesdata: []
    },

    selectedCategoryReducer: {
        categorydata: null
    },

    pagesReducer: {
        pagesdata: []
    },

    selectedPageReducer: {
        pagedata: null
    },

    usersReducer: {
        usersdata: []
    },

    selectedUserReducer: {
        userdata: null
    },

    rolesReducer: {
        rolesdata: []
    },

    selectedRoleReducer: {
        roledata: null
    },

    placemarksReducer: {
        placemarksdata: []
    },

    selectedPlacemarkReducer: {
        placemarkdata: null
    },

    locationsReducer: {
        locationsdata: []
    },

    organisationsReducer: {
        organisationsdata: []
    },

    selectedOrganisationReducer: {
        organisationdata: null
    },

    sitesReducer: {
        sitesdata: []
    },

    selectedSiteReducer: {
        sitedata: null
    },

    buildingsReducer: {
        buildingsdata: []
    },

    spacesReducer: {
        spacesdata: []
    },

    levelsReducer: {
        levelsdata: []
    },

    apiReducer: {
        apiCallsInProgress: 0
    },

    beaconsReducer: {
        beaconsdata: []
    },

    spotsReducer: {
        spotsdata: []
    },

    overviewsReducer: {
        overviewsdata: []
    },

    timeZonesReducer: {
        timezonesdata: []
    },

    profilesReducer: {
        profilesdata: []
    },

    selectedProfileReducer: {
        profiledata: null
    },

    appsReducer: {
        appsdata: []
    },

    selectedAppReducer: {
        appdata: null
    },

    masterAppsReducer: {
        masterAppsdata: []
    },

    selectedMasterAppReducer: {
        masterappdata: null
    },

    formsReducer: {
        formsdata: []
    },

    selectedFormReducer: {
        formdata: null
    },

    user: {
        username: null
    },

    usersPreferencesReducer: {
        userspreferencesdata: []
    },
    selectedUserPreferencesReducer: {
        userpreferencesdata: null
    },
    assetsReducer: {
        assetsdata: []
    },
    assetsOverviewReducer: {
        assetsoverviewdata: []
    },
    assetLabelsReducer: {
        assetlabelsdata: []
    },
    selectedAssetLabelReducer: {
        assetlabeldata: {}
    },
    selectedAssetReducer: {
        assetdata: null
    },
    assetsHistoryReducer: {
        historiesdata: []
    },
    selectedAssetHistoryReducer: {
        historydata: null
    },
    notificationsReducer: {
        notificationsdata: []
    },
    selectedNotificationReducer: {
        notificationdata: null
    },
    mergedlevelsReducer: {
        mergedlevesdata: []
    },
    selectedMergedLevelReducer: {
        mergedleveldata: {}
    },
    mergedBuildingsReducer: {
        mergedbuildingsdata: []
    },
    selectedMergedBuildingReducer: {
        mergedbuildingdata: {}
    },
    placemarksHistoryReducer: {
        placemarkhistoriesdata: []
    },
    selectedPlacemarkHistoryReducer: {
        placemarkhistorydata: null
    },
    calendarHistoryReducer: {
        calendarhistoriesdata: []
    },
    departmentsReducer: {
        departmentsdata: []
    },
    selectedDepartmentReducer: {
        departmentdata: null
    },
    trackersReducer: {
        trackersdata: []
    },
    selectedTrackerReducer: {
        trackerdata: null
    },
    calendarsReducer: {
        calendarsdata: []
    },
    selectedCalendarReducer: {
        calendardata: null
    },
    collectionsReducer: {
        collectionsdata: []
    },
    selectedCollectionReducer: {
        collectiondata: null
    },
    associationsReducer: {
        associationsdata: []
    },
    manufacturersReducer: {
        manufacturersdata: []
    },
    selectedManufacturerReducer: {
        manufacturerdata: null
    },
    modelsReducer: {
        modelsdata: []
    },
    selectedModelReducer: {
        modeldata: null
    },
    typesReducer: {
        typesdata: []
    },
    selectedTypeReducer: {
        typedata: null
    },
    metadatasReducer: {
        metadatas: []
    },
    selectedMetadataReducer: {
        metadata: null
    },
    policiesReducer: {
        policiesdata: []
    },
    selectedPolicyReducer: {
        policydata: null
    },
    groupsReducer: {
        groupsdata: []
    },
    selectedGroupReducer: {
        groupdata: null
    },
    exhibitorsReducer: {
        exhibitorsdata: []
    },
    sessionsReducer: {
        sessionsdata: []
    },
};
