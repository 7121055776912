import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const assetLabelsReducer = (state = initialState.assetLabelsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_ASSET_LABELS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                assetlabelsdata: _.assign(action.assetlabelsdata)
            };
        }

        default: { return state; }
    }
};


export default assetLabelsReducer;