import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Tabs, Tab } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Tree } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { Context } from './Context';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);
const PaginatedDataTableR = withPagination(DataTable);

class UserRolesListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }

    render() {
        return (
            <StyledBox>
                {this.renderRolesTable(this.props)}
            </StyledBox>
        );
    }

    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'lcrole',
                header: 'Role',
                render: roles => <Text>{roles.name}</Text>
            },
            {
                property: 'lcpermissiondetail',
                header: 'Permissions',
                render: roles => <Text>{roles.detail}</Text>
            },
            {
                property: 'count',
                header: 'User Count',
            },
        ];

        let DATA = [];

        function roleRank(name) {
            if (name == "Organisation Admin") {
                return 1
            }
            else if (name == "Site Admin") {
                return 2
            }
            else if (name == "Content Admin") {
                return 3
            }
            else if (name == "User") {
                return 4
            }
        }

        props.rolesdata.forEach(ev => {
            DATA.push({
                ...ev, lcrole: ev.name.toLowerCase(),
                /* lcpermission: ev.permission.toLowerCase()*/
                //permission: ArrayToString(ev.permissions),
                lcpermissiondetail: ev.detail,
                count: ev.userCount.toString(),
                rank: roleRank(ev.name),
            })
        });

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.detail.toLowerCase().includes(search) ||
                d.count.toString().toLowerCase().includes(search)
            );
        }

        if (!this.context.feature.find(f => f.name == "contentOrg").isEnabled) {
            DATA = DATA.filter(d => d.name != "Content Admin");
        }

        DATA.sort((p1, p2) => { if (p1.rank < p2.rank) return -1; return 1; });

        /*console.log('DATA:', DATA);*/

        return (
            <PaginatedDataTableR
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcrole" || c.property === "lcpermissiondetail" || c.property === "count"
                }))}
                data={DATA}
                noLoadingMsg={this.props.rolesdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcrole', label: 'Role' },
                //    { col: 'lcpermissiondetail', label: 'Permission' },
                //    { col: 'userCount', label: 'User Count' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}


export default UserRolesListTable;
