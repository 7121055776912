import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedUserReducer = (state = initialState.selectedUserReducer, action) => {
    switch(action.type) {

        case ActionType.GET_USER_RESPONSE: {
            // '...' spread operator clones the state
            if (action.userdata) {
                return {
                    ...state,
                    userdata: _.assign(action.userdata)
                };
            }
            else {
                return {
                    ...state,
                    userdata: initialState.selectedUserReducer.userdata
                };
            }

        }

        default: { return state; }
    }
};


export default selectedUserReducer;