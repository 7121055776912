import React from 'react';

import { Blank } from 'grommet-icons';


export const MapCustom = props => (
    <Blank viewBox='0 0 24 24' a11yTitle='MapCustom' {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d='M8.3334999,23.9501991L0,20.6630993V0.05371l8.3095999,3.1367199L15.6669998,0.03711L24,3.6088901v20.2915096
		l-8.3086004-2.8525009L8.3334999,23.9501991z M2,19.3017998l5.3334999,2.1035004V4.9594998L2,2.94629V19.3017998z
		 M9.3334999,4.9271998v16.4780998l5.3334999-2.1035004V2.6415999L9.3334999,4.9271998z M16.6669998,19.2686005L22,21.0995998
		V4.9271998l-5.3330002-2.2855999V19.2686005z' strokeWidth="0.25" strokeMiterlimit="10" display="inline"/>
    </Blank>
); 