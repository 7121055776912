import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Context } from '../components/Context';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { Spinning } from 'grommet-controls';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField } from 'grommet';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import { VectorMap } from "@react-jvectormap/core";
import { worldMill, worldMerc } from '@react-jvectormap/world';
import AppInsightsApi from '../api/AppInsightsApi';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import SingleChart from './SingleChart';
import { CSVLink } from "react-csv";
import { Edit, CircleInformation, Next, FormNext, FormPrevious, Download } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import RB from './common/RBAC';

import AnalyticsCards from './AnalyticsCards';
import AnalyticsFilter from './AnalyticsFilter';

const PaginatedDataTable = withPagination(DataTable);

var monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
var pieColor = ["#0D5265", "#01A982", "#32DAC8", "#7FF9E2", "#7630EA", "#C140FF", "#FF8300", "#FEC901",
    "#FF8300", "#C140FF", "#7630EA", "#7FF9E2", "#32DAC8", "#01A982", "#0D5265",
    "#01A982", "#32DAC8", "#7FF9E2", "#7630EA", "#C140FF", "#FF8300", "#FEC901",
    "#FF8300", "#C140FF", "#7630EA", "#7FF9E2", "#32DAC8", "#01A982", "#0D5265",
    "#01A982", "#32DAC8",
];

var axesColor = "F8F8F8";

const Card = ({ header, value, isViewable = false, route = "" }) =>
    <StyledBox style1 height="200px" width="200px" style={{ position: "relative" }}
        /*margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}*/
    >
    <Box height="15%" margin={{ left: "small" }}>
        <Text>{header}</Text>
    </Box>
    <Box style={{
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        width: "100%",
        alignItems: "center"
    }}>
        <Text size="4xl">
            {value}
        </Text>
    </Box>
    {route || isViewable && <Box style={{ height: "100%", justifyContent: "end", alignItems: "center" }}>
        <Button label="View" disabled={true} />
    </Box>}
</StyledBox>

export class ChartPage extends React.Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
            selectedLocation: "All",
            locationFilter: "All",
            timeSpan: "Last 30 Days",
            selectedOS: "All",
            selectedVersion: "All",
            locationsList: [],
            versionsList: null,
            mapData: null,
            countryChart: [],
            userChart: [],
            sessionChart: null,
            eventTable: null,
            eventTableExtended: null,
            singleFeature: null,
            CSVData: null,
            CSVheaders: null,
            viewMoreCountries: false,
            viewMoreFeatures: false,
            viewMoreActive: false,
            viewMoreTotal: false,
            showBorder: false,
            CSVDataLoading: true,
            mapDataLoading: true,
            userChartLoading: true,
            sessionChartLoading: true,
            eventTableLoading: true,
            eventTableExtendedLoading: true,
            locationsListLoading: true,
            versionsListLoading: true,
            singleFeatureDataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

        this.getChartData = this.getChartData.bind(this);
        this.getTimespan = this.getTimespan.bind(this);
        this.updateEventTable = this.updateEventTable.bind(this);
        this.getSingleFeatureData = this.getSingleFeatureData.bind(this);
        this.backButtonPressed = this.backButtonPressed.bind(this);
    }

    eventData = [];
    eventDataExtended = [];

    componentDidMount() {
        const orgid = sessionStorage.getItem("organisationId");
        if (!(this.props.organisationdata && this.props.organisationdata.id == this.props.match.params.orgid && this.props.match.params.orgid == orgid)) {
            this.props.action.getOrganisationAction(orgid)
                .then(response => this.setState({ organisationdataLoading: false }, () => {
                    constants.setInsightsId(this.props.organisationdata.appInsightsId);
                    constants.setInsightskey(this.props.organisationdata.appInsightsKey);
                    sessionStorage.setItem("organisationCode", this.props.organisationdata.code);
                    this.getAll();
                    this.getChartData();
                }
                ))
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*-Organisation Error', error);
                });
        }
        else {
            constants.setInsightsId(this.props.organisationdata.appInsightsId);
            constants.setInsightskey(this.props.organisationdata.appInsightsKey);
            this.setState({ organisationdataLoading: false }, () => {
                this.getAll();
                this.getChartData();
            });
        }


        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });

        this.onDTUpdated();
        //let node = this.dtwrapper.current;
        //if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
        //    let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        //    [...trs].forEach((elem, idx) => {
        //        elem.addEventListener("click", (evt) => this.clickEventListener(evt, elem.cells[0].textContent, elem.cells[1].textContent));
        //        elem.addEventListener("mouseover", function (evt) {
        //            evt.preventDefault();
        //            elem.style.background = "#E6E6E6";
        //            elem.style.cursor = 'pointer';
        //        });
        //        elem.addEventListener("mouseout", function (evt) {
        //            evt.preventDefault();
        //            elem.style.background = "white";
        //            elem.style.cursor = 'default';
        //        });
        //    });
        //}
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node && node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            [...trs].forEach((elem, idx) => {
                elem.addEventListener("click", (evt) => this.clickEventListener(evt, elem.cells[0].textContent, elem.cells[1].textContent, elem.cells[2].textContent));
                elem.addEventListener("mouseover", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "#E6E6E6";
                    elem.style.cursor = 'pointer';
                });
                elem.addEventListener("mouseout", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "white";
                    elem.style.cursor = 'default';
                });
            })
        }
    }

    clickEventListener(evt, name, type, apploc) {
        evt.preventDefault();
        /*this.props.history.push({
            pathname: '/singlechart/' + item.name.toLowerCase(),
            state: { 
                item,
                selectedFilters:{
                    location: this.state.selectedLocation,
                    timeSpan: this.state.timeSpan,
                    version: this.state.selectedVersion,
                    os: this.state.selectedOS
                },
                versionsList: this.state.versionsList
            }
        });*/

        //var item = this.eventData.find(e => e.name === name);
        var item = this.eventDataExtended.find(e => e.name === name && e.type == type && e.loc == apploc);
        if (!item) {
            item = { name: "", type: "", value: "" }
        }

        this.setState({
            singleFeature: {
                item: item,
                filters: {
                    location: this.state.selectedLocation,
                    timeSpan: this.state.timeSpan,
                    version: this.state.selectedVersion,
                    os: this.state.selectedOS
                }
            }
        },
            () => this.getSingleFeatureData()
        );

    }

    componentDidUpdate() {
        this.onDTUpdated();
        //let node = this.dtwrapper.current;
        //if (node && node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
        //    let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
        //    [...trs].forEach((elem, idx) => {
        //        elem.addEventListener("click", (evt) => this.clickEventListener(evt, elem.cells[0].textContent, elem.cells[1].textContent));
        //        elem.addEventListener("mouseover", function (evt) {
        //            evt.preventDefault();
        //            elem.style.background = "#E6E6E6";
        //            elem.style.cursor = 'pointer';
        //        });
        //        elem.addEventListener("mouseout", function (evt) {
        //            evt.preventDefault();
        //            elem.style.background = "white";
        //            elem.style.cursor = 'default';
        //        });
        //    });
        //}
    }
    getAll() {
        AppInsightsApi.getAllData(sessionStorage.getItem("organisationCode"))
            .then(data => {
                var table = [];
                var headers = [];
                data.tables[0].columns.forEach(ev => {
                    headers.push({
                        label: ev.name,
                        key: ev.name
                    })
                });

                data.tables[0].rows.forEach(ev => {
                    var obj = {};
                    ev.forEach((vl, id) => {
                        if (id != 3) obj[headers[id].key] = vl;
                        else {
                            Object.entries(JSON.parse(vl)).forEach(cd => {
                                if (cd[0] != "Properties") obj["customDimensions_" + cd[0]] = cd[1];
                                else obj["customDimensions_" + cd[0]] = cd[1].split(",").join(";").split("\"").join("");
                            })
                        }
                    })
                    table.push(obj);
                });

                var finalHeaders = [];
                if (table.length != 0) {
                    Object.keys(table[0]).forEach(ev => {
                        finalHeaders.push({
                            label: ev,
                            key: ev
                        })
                    })
                }
                this.setState({ CSVData: table.length > 0 ? table : null, CSVheaders: finalHeaders, CSVDataLoading: false });
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getAllData Error', error);
            });
    }

    getChartData() {
        if (this.state.singleFeature) {
            this.getSingleFeatureData();
            return;
        }
        this.setState({ userChart: [], sessionChart: null, mapData: null, countryChart: [], mapDataLoading: true, userChartLoading: true, sessionChartLoading: true });

        this.getDropDownOptions();

        AppInsightsApi.getWorldUsers(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let countryIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "client_CountryOrRegion") {
                        countryIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        countryIdx = 1;
                        valueIdx = 0;
                    }
                    let map = {};
                    var list = [];
                    data.tables[0].rows.forEach(row => {
                        list.push({
                            name: row[countryIdx].toUpperCase(),
                            value: row[valueIdx]
                        });
                        map[row[countryIdx].toUpperCase()] = row[valueIdx];
                    });
                    this.setState(
                        {
                            mapData: map,
                            countryChart: list.sort(this.cmp),
                            mapDataLoading: false
                        }
                    );
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getWorldUsers Error', error);
            });

        AppInsightsApi.getUsersCount(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let dateIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "timestamp") {
                        dateIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        dateIdx = 1;
                        valueIdx = 0;
                    }
                    this.setState({
                        userChart: data.tables[0].rows.map(row => {
                            return {
                                date: new Date(row[dateIdx]),
                                value: row[valueIdx]
                            };
                        }),
                        userChartLoading: false,
                    });
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getUsersCount Error', error);
            });

        AppInsightsApi.getAvarageSessionsCount(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let dateIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "timestamp") {
                        dateIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        dateIdx = 1;
                        valueIdx = 0;
                    }
                    this.setState({
                        sessionChart: data.tables[0].rows.map(row => {
                            return {
                                date: new Date(row[dateIdx]),
                                value: row[valueIdx]
                            }
                        }).sort(this.cmp),
                        sessionChartLoading: false,
                    });
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getAvarageSessionsCount Error', error);
            });

        this.updateEventTable();
        this.updateEventExtended();


        //CARDS
        let isSuperUser = RB.isSuperUser()
        AppInsightsApi.getSessionsCount(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ sessionsCount: data.tables[0].rows[0][0]});
            });
        AppInsightsApi.getAvarageSessionByDay(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ sessionsAVG: this.isNumber(data.tables[0].rows[0][0]) ? Number.parseFloat(data.tables[0].rows[0][0]).toFixed(2) : "N/A" });
            });
        AppInsightsApi.getAvarageSessionLength(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.selectedLocation, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0]) 
                    this.setState({ sessionsLengthAVG: data.tables[0].rows[0][0] ? data.tables[0].rows[0][0].substring(0, 8) : "N/A" });
            });
        AppInsightsApi.getEventsCount(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.selectedLocation, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ eventsCount: data.tables[0].rows[0][0] });
            });
        AppInsightsApi.getAvarageEventsPerUser(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.selectedLocation, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ eventsAVG: this.isNumber(data.tables[0].rows[0][0]) ? Number.parseFloat(data.tables[0].rows[0][0]).toFixed(2) : "N/A" });
            });
        AppInsightsApi.getErrorsCount(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS)
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ errorsCount: data.tables[0].rows[0][0] });
            });
        //AppInsightsApi.getErrorsByName(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS)
        //    .then(data => {
        //        console.log("imper", data);
        //        if (data.tables[0].rows.length > 0)
        //            this.setState({ handledErrorsCount: data.tables[0].rows[0][1], unhandledErrorsCount: data.tables[0].rows[1][1] });
        //    });
        
        AppInsightsApi.getErrorsBySpecificName(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, "HandledErrorLog")
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ handledErrorsCount: data.tables[0].rows[0][0] });
            });
        AppInsightsApi.getErrorsBySpecificName(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, "UnhandledErrorLog")
            .then(data => {
                if (data.tables[0].rows[0])
                    this.setState({ unhandledErrorsCount: data.tables[0].rows[0][0] });
            });
    }

    isNumber(value) {
        return typeof value === 'number' && isFinite(value);
    }

    getTimespan() {
        switch (this.state.timeSpan) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    updateEventTable() {
        this.eventData = [];
        this.setState({ eventTable: null, eventTableLoading: true });
        AppInsightsApi.getEventsTable(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.selectedLocation, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let nameIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "name") {
                        nameIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        nameIdx = 1;
                        valueIdx = 0;
                    }
                    this.setState({
                        eventTable: data.tables[0].rows.map(row => {
                            return {
                                name: row[nameIdx],
                                value: row[valueIdx]
                            }
                        }).sort(this.cmp),
                        eventTableLoading: false,
                    });
                    this.eventData = data.tables[0].rows.map(row => {
                        return {
                            name: row[nameIdx],
                            value: row[valueIdx]
                        }
                    }).sort(this.cmp)
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getEventsTable Error', error);
            });
    }

    updateEventExtended() {
        this.eventDataExtended = [];
        this.setState({ eventTableExtended: null, eventTableExtendedLoading: true });
        AppInsightsApi.getEventsTableExtended(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.selectedLocation, sessionStorage.getItem("organisationCode"), RB.isSuperUser())
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let nameIdx, typeIdx, locIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "event") {
                        nameIdx = 0;
                        typeIdx = 1;
                        locIdx = 2;
                        valueIdx = 3;
                    }
                    else {
                        nameIdx = 3;
                        typeIdx = 2;
                        locIdx = 1;
                        valueIdx = 0;
                    }
                    this.setState({
                        eventTableExtended: data.tables[0].rows.map(row => {
                            return {
                                name: row[nameIdx],
                                type: row[typeIdx],
                                loc: row[locIdx],
                                value: row[valueIdx],
                            }
                        }).sort(this.cmp),
                        eventTableExtendedLoading: false,
                    });
                    this.eventDataExtended = data.tables[0].rows.map(row => {
                        return {
                            name: row[nameIdx],
                            type: row[typeIdx],
                            loc: row[locIdx],
                            value: row[valueIdx],
                        }
                    }).sort(this.cmp)
                    this.setState({ eventTableExtended: this.eventDataExtended })
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getEventsTable Error', error);
            });

    }

    getDropDownOptions() {
        AppInsightsApi.getLocations(this.getTimespan(), sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    this.setState({
                        locationsList: data.tables[0].rows.map(row => this.getSiteName(row[0])).sort(),
                        locationsListLoading: false,
                    });
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getLocations Error', error);
            });


        AppInsightsApi.getVersions(this.getTimespan(), sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    this.setState({
                        versionsList: this.sortVersions(data.tables[0].rows.map(row => row[0])),
                        versionsListLoading: false,
                    });
                }
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getVersions Error', error);
            });
    }

    sortVersions(versionList) {
        var sortedVersions = versionList.map(v => v.split('.').map(n => +n + 10000000).join('.')).sort().reverse();
        sortedVersions = sortedVersions.map(v => v.split('.').map(n => +n - 10000000).join('.'));

        return sortedVersions;
    }

    getSiteCode(siteName) {
        var siteCode = siteName;
        if (this.props.sitesdata.find(s => s.name == siteName)) {
            siteCode = this.props.sitesdata.find(s => s.name == siteName).code;
        }
        return siteCode;
    }

    getSiteName(siteCode) {
        var siteName = siteCode;
        if (this.props.sitesdata.find(s => s.code == siteCode)) {
            siteName = this.props.sitesdata.find(s => s.code == siteCode).name;
        }
        return siteName;
    }

    updateTip(code, text) {
        let val = this.state.mapData[code];
        return val ? text.concat(" ", val) : text;
    }

    backButtonPressed() {
        //console.log(this.state);
        if (this.state.singleFeature) {
            this.setState(prevState => {
                return {
                    selectedOS: prevState.singleFeature.filters.os,
                    selectedLocation: prevState.singleFeature.filters.location,
                    timeSpan: prevState.singleFeature.filters.timeSpan,
                    selectedVersion: prevState.singleFeature.filters.version,
                    singleFeature: null
                }
            }, () => this.getChartData());
        }
    }

    getSingleFeatureData() {
        this.setState({ singleFeatureData: null, singleFeatureDataLoading: true });
        AppInsightsApi.getIndividualEvent(this.getTimespan(), this.state.selectedVersion, this.state.selectedOS, this.state.singleFeature.item.name, this.state.singleFeature.item.type, this.state.singleFeature.item.loc, this.state.selectedLocation, sessionStorage.getItem("organisationCode"))
            .then(data => {
                this.setState({
                    singleFeatureData: data,
                    singleFeatureDataLoading: false,
                })
            })
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getIndividualEvent Error', error);
            });
    }
    cmp = (a, b) => {
        const alc = a.value;
        const blc = b.value;
        if (alc < blc) {
            return 1;
        }
        else if (alc > blc) {
            return -1;
        }
        else {
            return 0;
        }
    }

    axisMaxValue(maxValue) {
        if (maxValue <= 5) { return 5 }
        else if (maxValue <= 10) { return 10 }
        else if (maxValue <= 200) { return Math.ceil(maxValue / 10) * 10 }
        else if (maxValue <= 2000) { return Math.ceil(maxValue / 100) * 100 }
        else if (maxValue <= 20000) { return Math.ceil(maxValue / 1000) * 1000 }
        else { return Math.ceil(maxValue / 10000) * 10000 }
    }

    dataMaxValue(data) {
        if (data) {
            if (data.length > 1) {
                const max = data.reduce((prev, current) => (prev.value > current.value) ? prev : current)
                return max.value
            }
            else {
                return 0
            }
        }
    }

    formatLabelName(name) {
        if (name.startsWith("https://")) {
            name = name.slice(8, name.length)
        }
        if (name.startsWith("http://")) {
            name = name.slice(7, name.length)
        }
        if (name.startsWith("www.")) {
            name = name.slice(4, name.length)
        }
        if (name.length > 15) {
            name = name.slice(0, 15) + "..."
        }

        return name
    }

    decodeUTF8URL(url) {
        return url;
    }

    render() {
        const { sitesdataLoading, organisationdataLoading } = this.state;

        if (sitesdataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        let DATA = [];

        //if (this.state.eventTable && this.state.eventTable.length != 0) {
        //    this.state.eventTable.forEach(ev => {
        //        DATA.push({
        //            ...ev,
        //        })
        //    });
        //}

        if (this.state.eventTableExtended && this.state.eventTableExtended.length != 0) {
            this.state.eventTableExtended.forEach(ev => {
                //if (ev.name.trim() != "" && ev.type != "SiteSelection" ) { //SiteSelection filtered in AppInsightsApi
                if (ev.name.trim() != "") {
                    DATA.push({
                        ...ev,
                        decodedName: this.decodeUTF8URL(ev.name),
                    })
                }
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.type.toLowerCase().includes(search) ||
                d.loc.toLowerCase().includes(search) ||
                d.value.toString().toLowerCase().includes(search)
            );
        }

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header'
                    direction="row"
                    align="center"
                    justify="between"
                    pad={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}
                    margin={{ "top": "none", "bottom": "none", "left": "small", "right": "small" }}
                    border={this.state.showBorder}
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Analytics </Heading>
                    <Box>
                        {this.state.CSVDataLoading ?
                            <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} errorSize="24px" showErrorMsg={false}/>
                            :
                            <Box>
                                {this.state.CSVData &&
                                    <CSVLink data={this.state.CSVData} headers={this.state.CSVheaders} filename="analytics-export">
                                        <a data-for="Download" data-tip="Export to CSV">
                                            <StyledButton plain icon={<Download color={constants.BUTTON_LABEL_COLOR_3} />} alignSelf="center" />
                                        </a>
                                        <ReactTooltip id="Download" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                    </CSVLink>}
                                {!this.state.CSVData &&
                                    <Box>
                                        <a data-for="Download" data-tip="There is no data to be exported">
                                            <StyledButton plain icon={<Download color={'#808080'} />} alignSelf="center" />
                                        </a>
                                        <ReactTooltip id="Download" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} fill="vertical" border={this.state.showBorder}
                    pad={{ "top": "none", "bottom": "medium", "left": "medium", "right": "medium" }}>
                    <form>
                        <Box direction="row" gap="none" justify="between" border={this.state.showBorder}
                            margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}>
                            <Box direction="row" gap="small" border={this.state.showBorder}>
                                <Box direction="row" alignSelf="center" border={this.state.showBorder}>
                                    <Text> Time:</Text>
                                </Box>
                                <StyledBox style1 alignContent="end" width="230px" border>
                                    <Select
                                        plain
                                        options={["Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 6 Months", "Last 12 Months", "Last 2 Years"]}
                                        value={this.state.timeSpan}
                                        onChange={({ option }) => this.setState({ timeSpan: option, singleFeatureData: null, singleFeatureDataLoading: true }, () => this.getChartData())}
                                    />
                                </StyledBox>
                            </Box>
                            <Box direction="row" gap="small" border={this.state.showBorder}>
                                <Box direction="row" alignSelf="center" border={this.state.showBorder}>
                                    <Text> Site:</Text>
                                </Box>
                                <StyledBox style1 alignContent="end" width="230px" border>
                                    <Select
                                        plain
                                        options={["All"].concat(this.state.locationsList)}
                                        value={this.state.locationFilter}
                                        onChange={option => this.setState({ locationFilter: option.value, selectedLocation: this.getSiteCode(option.value) }, () => this.getChartData())}
                                    />
                                </StyledBox>
                            </Box>
                            <Box direction="row" gap="small" border={this.state.showBorder}>
                                <Box direction="row" alignSelf="center" border={this.state.showBorder}>
                                    <Text> Version:</Text>
                                </Box>
                                <StyledBox style1 alignContent="end" width="230px" border>
                                    {this.state.versionsList &&
                                        <Select
                                            plain
                                            options={["All"].concat(this.state.versionsList)}
                                            //value={this.state.selectedVersion}
                                        onChange={option => this.setState({ selectedVersion: option.value == "All" ? "All" : `| where application_Version == "${option.value}"` }, () => this.getChartData())}
                                        />}
                                </StyledBox>
                            </Box>
                            <Box direction="row" gap="small" border={this.state.showBorder}>
                                <Box direction="row" alignSelf="center" width="xsmall" border={this.state.showBorder}>
                                    <Text > Target OS:</Text>
                                </Box>
                                <StyledBox style1 alignContent="end" width="230px" border>
                                    <Select
                                        plain
                                        options={["All", "Android", "iOS"]}
                                        value={this.state.selectedOS}
                                        onChange={option => this.setState({ selectedOS: option.value }, () => this.getChartData())}
                                    />
                                </StyledBox>
                            </Box>
                        </Box>
                        {
                            !this.state.singleFeature ?
                                <React.Fragment>
                                    <Grid
                                        margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
                                        columns='200px'
                                        gap="xsmall"
                                    >
                                        <Card header="Installs" value={this.state.countryChart.reduce((acc, curr) => acc + curr.value, 0)} isViewable={true} />
                                        <Card header="Countries" value={this.state.countryChart.length} isViewable={true} />
                                        <Card header="Active Users" value={this.state.userChart.reduce((acc, curr) => acc + curr.value, 0)} isViewable={true} />

                                        <Card header="Sessions" value={this.state.sessionsCount} />
                                        <Card header="Average session per day" value={this.state.sessionsAVG} />
                                        <Card header="Average Session Length" value={this.state.sessionsLengthAVG} />

                                        <Card header="Events" value={this.state.eventsCount} isViewable={true} />
                                        <Card header="Average number of Events" value={this.state.eventsAVG} />
                                        <Card header="Errors" value={this.state.errorsCount} />

                                        <Card header="Handled Errors" value={this.state.handledErrorsCount} />
                                        <Card header="Unhandled Errors" value={this.state.unhandledErrorsCount} />
                                    </Grid>
                                    {this.state.selectedLocation == "All" && !this.state.viewMoreCountries &&
                                        !this.state.viewMoreFeatures && !this.state.viewMoreActive && !this.state.viewMoreTotal &&
                                        <StyledBox style1
                                            direction="column"
                                            margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
                                            border={this.state.showBorder}
                                            height="350px">
                                            <Box direction="row-responsive" gap="small" height="100%" border={{ "side": "bottom" }} >
                                                <Box justify="between"
                                                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    border={this.state.showBorder}
                                                    round="xsmall"
                                                    basis="50%">
                                                    <Box height="15%" border={this.state.showBorder} margin={{ "bottom": "none" }}>
                                                        <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                            Installs Per Country
                                                        </Heading>
                                                    </Box>
                                                    <Box height="85%" border={this.state.showBorder} margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                        {this.state.mapDataLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                            <VectorMap
                                                                map={worldMill}
                                                                backgroundColor="transparent"
                                                                containerStyle={{
                                                                    width: '100%',
                                                                    height: '90%'
                                                                }}
                                                                regionStyle={{
                                                                    initial: {
                                                                        fill: "#e4e4e4",
                                                                        "fill-opacity": 0.9,
                                                                        stroke: "none",
                                                                        "stroke-width": 0,
                                                                        "stroke-opacity": 0
                                                                    },
                                                                    hover: {
                                                                        "fill-opacity": 0.8
                                                                    },
                                                                    selectedHover: {}
                                                                }}
                                                                series={{
                                                                    regions: [
                                                                        {
                                                                            values: this.state.mapData,
                                                                            scale: ["#32DAC8"],
                                                                            normalizeFunction: "polynomial"
                                                                        }
                                                                    ],
                                                                }}
                                                                zoomOnScroll={true}
                                                                onRegionTipShow={(function (e, el, code) {
                                                                    el.html(el.html() + " (" + (this.state.mapData[code] ? this.state.mapData[code] : "0") + ")");

                                                                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el, nro) => {
                                                                        if (nro < Array.from(document.getElementsByClassName("jvectormap-tip")).length - 1) {
                                                                            el.style.display = "none";
                                                                            el.remove();
                                                                        }
                                                                    });

                                                                }).bind(this)
                                                                }
                                                            />
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box background="transparent"
                                                    border={this.state.showBorder}
                                                    justify="between"
                                                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    round="xsmall"
                                                    basis="50%">
                                                    <Box height="15%" border={this.state.showBorder} direction="column-responsive" justify="between" >
                                                        <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                            Top Countries
                                                </Heading>
                                                        <Box justify="center" border={this.state.showBorder} height="25px" alignSelf="center">
                                                            <a data-for="Top countries" data-tip="The top countries by user installs.<br />In the world map the countries with the darker shades are the ones with the most active users">
                                                                <CircleInformation size="medium" color="#444444" />
                                                            </a>
                                                            <ReactTooltip id="Top countries" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                        </Box>
                                                    </Box>
                                                    <Box height="85%" border={this.state.showBorder}
                                                        margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                        {this.state.mapDataLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                            <Bar
                                                                data={{
                                                                    labels: this.state.countryChart.map(item => `${item.name}`),
                                                                    datasets: [
                                                                        {
                                                                            label: "Top Countries",
                                                                            data: this.state.countryChart.map(item => item.value),
                                                                            borderWidth: 0,
                                                                            backgroundColor: "#32DAC8",
                                                                            hoverBackgroundColor: "#32DAC8"
                                                                        }
                                                                    ]
                                                                }}
                                                                options={{
                                                                    legend: {
                                                                        display: false
                                                                    },
                                                                    scales: {
                                                                        yAxes: [{
                                                                            gridLines: {
                                                                                drawOnChartArea: false,
                                                                                color: axesColor
                                                                            },
                                                                            ticks: {
                                                                                min: 0,
                                                                                max: this.axisMaxValue(this.dataMaxValue(this.state.countryChart)),
                                                                                stepSize: this.axisMaxValue(this.dataMaxValue(this.state.countryChart)) / 5
                                                                            }
                                                                        }],
                                                                        xAxes: [{
                                                                            barPercentage: 0.4,
                                                                            gridLines: {
                                                                                drawOnChartArea: false,
                                                                                color: axesColor
                                                                            },
                                                                        }]
                                                                    },
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                }}
                                                            />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "xsmall", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="View Details"
                                                    onClick={() => this.setState({ viewMoreCountries: true })}
                                                    icon={<FormNext color={constants.BUTTON_LABEL_COLOR_3} />}
                                                    reverse="true"
                                                />
                                            </Box>
                                        </StyledBox>}
                                    {!this.state.viewMoreCountries && !this.state.viewMoreFeatures &&
                                        !this.state.viewMoreActive && !this.state.viewMoreTotal &&
                                        <StyledBox style1
                                            height="350px"
                                            border={this.state.showBorder}
                                            justify="between"
                                            margin="small">
                                            <Box border={{ side: "bottom" }}
                                                height="100%"
                                                width="100%"
                                                justify="between"
                                                pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                <Box border={this.state.showBorder} height="15%" direction="column-responsive" justify="between">
                                                    <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                        Top 10 Events
                                                    </Heading>
                                                    <Box border={this.state.showBorder} justify="center" height="25px" alignSelf="center">
                                                        <a data-for="Top events" data-tip="The top 10 events used in the app by it's active users.">
                                                            <CircleInformation size="medium" color="#444444" />
                                                        </a>
                                                        <ReactTooltip id="Top events" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                    </Box>
                                                </Box>
                                                <Box border={this.state.showBorder} height="85%"
                                                    margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                    {this.state.eventTableLoading || this.state.eventTableExtendedLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                        <Bar
                                                            data={{
                                                                labels: DATA.map(item => this.formatLabelName(item.name)).slice(0, 10),
                                                                hoverLabels: DATA.map(item => item.name).slice(0, 10),
                                                                //labels: ["Book a Room", "Maps", "About", "Events", "Report Issue", "Spaces", "Skype", "HPE Home", "Locate People"], //dummy
                                                                datasets: [
                                                                    {
                                                                        order: 1,
                                                                        label: "Views",
                                                                        //data: [5,2,3,1,5,7,5,6,2], //dummy
                                                                        data: DATA.map(item => item.value).slice(0, 10),
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#7630EA",
                                                                        hoverBackgroundColor: "#7630EA"
                                                                    }
                                                                ]
                                                            }}
                                                            options={{
                                                                tooltips: {
                                                                    mode: "label",
                                                                    callbacks: {
                                                                        title: function (tooltipItem, data) {
                                                                            return data['hoverLabels'][tooltipItem[0]['index']];
                                                                        },
                                                                    },
                                                                },
                                                                legend: {
                                                                    display: false
                                                                },
                                                                scales: {
                                                                    yAxes: [{
                                                                        gridLines: {
                                                                            drawOnChartArea: false,
                                                                            color: axesColor
                                                                        },
                                                                        ticks: {
                                                                            min: 0,
                                                                            max: this.axisMaxValue(this.dataMaxValue(DATA)),
                                                                            stepSize: this.axisMaxValue(this.dataMaxValue(DATA)) / 5
                                                                        }
                                                                    }],
                                                                    xAxes: [{
                                                                        barPercentage: 0.4,
                                                                        gridLines: {
                                                                            drawOnChartArea: false,
                                                                            color: axesColor
                                                                        },
                                                                    }]
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                        />}
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "xsmall", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="View All Events"
                                                    onClick={() => this.setState({ viewMoreFeatures: true })}
                                                    icon={<FormNext color={constants.BUTTON_LABEL_COLOR_3} />}
                                                    reverse="true"
                                                />
                                            </Box>
                                        </StyledBox>}
                                    {!this.state.viewMoreCountries && !this.state.viewMoreFeatures &&
                                        !this.state.viewMoreActive && !this.state.viewMoreTotal &&
                                        <StyledBox style1
                                            height="350px"
                                            border={this.state.showBorder}
                                            justify="between"
                                            margin={{ "top": "small", "bottom": "medium", "horizontal": "small" }}>
                                            <Box border={{ side: "bottom" }}
                                                height="100%"
                                                width="100%"
                                                justify="between"
                                                pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                <Box border={this.state.showBorder} height="15%" direction="column-responsive" justify="between">
                                                    <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                        Active Users
                                                    </Heading>
                                                    <Box border={this.state.showBorder} justify="center" height="25px" alignSelf="center">
                                                        <a data-for="Active users" data-tip="The number of active users who have used the app over the selected time period">
                                                            <CircleInformation size="medium" color="#444444" />
                                                        </a>
                                                        <ReactTooltip id="Active users" place="left" type="dark" effect="solid" arrowColor="transparent">
                                                            <span>The number of active users who have used the app over the selected time period</span>
                                                        </ReactTooltip>
                                                    </Box>
                                                </Box>
                                                <Box border={this.state.showBorder} height="85%"
                                                    margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                    {this.state.userChartLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                        <Line
                                                            data={{
                                                                labels: this.state.userChart.map(item => `${monthNames[item.date.getMonth()]} ${item.date.getDate()}`),
                                                                datasets: [
                                                                    {
                                                                        label: "Active Users",
                                                                        data: this.state.userChart.map(item => item.value),
                                                                        backgroundColor: "transparent",
                                                                        borderColor: "#C140FF",
                                                                        lineTension: 0,

                                                                        pointHoverBackgroundColor: "#C140FF",
                                                                        pointHoverBorderColor: "#C140FF",
                                                                        pointHoverBorderWidth: 3,
                                                                        pointHoverRadius: 5,
                                                                        pointRadius: 5,
                                                                        pointBackgroundColor: "transparent",
                                                                        pointBorderWidth: 0,
                                                                        pointBorderColor: "transparent"
                                                                    }
                                                                ]
                                                            }}
                                                            options={{
                                                                legend: {
                                                                    display: false
                                                                },
                                                                scales: {
                                                                    yAxes: [{
                                                                        gridLines: {
                                                                            drawOnChartArea: false,
                                                                            color: axesColor
                                                                        },
                                                                        ticks: {
                                                                            min: 0,
                                                                            max: this.axisMaxValue(this.dataMaxValue(this.state.userChart)),
                                                                            stepSize: this.axisMaxValue(this.dataMaxValue(this.state.userChart)) / 5
                                                                        }
                                                                    }],
                                                                    xAxes: [{
                                                                        barPercentage: 0.4,
                                                                        gridLines: {
                                                                            drawOnChartArea: false,
                                                                            color: axesColor
                                                                        },
                                                                    }]
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                        />}
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "xsmall", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="View Details"
                                                    onClick={() => this.setState({ viewMoreActive: true })}
                                                    icon={<FormNext color={constants.BUTTON_LABEL_COLOR_3} />}
                                                    reverse="true"
                                                />
                                            </Box>
                                        </StyledBox>}
                                    {this.state.viewMoreCountries &&
                                        <Box background="transparent"
                                            direction="row-responsive"
                                            margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
                                            border={this.state.showBorder}
                                            round="xsmall">
                                            <Box direction="column" gap="small" border={this.state.showBorder} justify="between" width="100%">
                                                <StyledBox style1
                                                    justify="between"
                                                    pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    border={this.state.showBorder}
                                                    height="500px"
                                                    width="100%">
                                                    <Box height="15%" border={this.state.showBorder} >
                                                        <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                            Installs Per Country
                                                        </Heading>
                                                    </Box>
                                                    <Box height="85%" border={this.state.showBorder} margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                        {this.state.mapDataLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                            <VectorMap
                                                                map={worldMill}
                                                                backgroundColor="transparent"
                                                                containerStyle={{
                                                                    width: '100%',
                                                                    height: '90%'
                                                                }}
                                                                regionStyle={{
                                                                    initial: {
                                                                        fill: "#e4e4e4",
                                                                        "fill-opacity": 0.9,
                                                                        stroke: "none",
                                                                        "stroke-width": 0,
                                                                        "stroke-opacity": 0
                                                                    },
                                                                    hover: {
                                                                        "fill-opacity": 0.8
                                                                    },
                                                                    selectedHover: {}
                                                                }}
                                                                series={{
                                                                    regions: [
                                                                        {
                                                                            values: this.state.mapData,
                                                                            scale: ["#32DAC8"],
                                                                            normalizeFunction: "polynomial"
                                                                        }
                                                                    ],
                                                                }}
                                                                zoomOnScroll={true}
                                                                onRegionTipShow={(function (e, el, code) {
                                                                    el.html(el.html() + " (" + (this.state.mapData[code] ? this.state.mapData[code] : "0") + ")");

                                                                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el, nro) => {
                                                                        if (nro < Array.from(document.getElementsByClassName("jvectormap-tip")).length - 1) {
                                                                            el.style.display = "none";
                                                                            el.remove();
                                                                        }
                                                                    });

                                                                }).bind(this)
                                                                }
                                                            />}
                                                    </Box>
                                                </StyledBox>
                                                <StyledBox style1
                                                    border={this.state.showBorder}
                                                    justify="between"
                                                    pad={{ "top": "none", "bottom": "none", "left": "none", "right": "smnoneall" }}
                                                    margin={{ "top": "none", "bottom": "medium", "left": "none", "right": "none" }}
                                                    height="500px"
                                                    width="100%">
                                                    <Box border={{ side: "bottom" }} height="100%"
                                                        pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                        <Box height="15%" border={this.state.showBorder} direction="column-responsive" justify="between"
                                                            margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                            <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                                Top Countries
                                                        </Heading>
                                                            <Box justify="center" border={this.state.showBorder} height="25px" alignSelf="center">
                                                                <a data-for="Top countries" data-tip="The top countries by user installs">
                                                                    <CircleInformation size="medium" color="#444444" />
                                                                </a>
                                                                <ReactTooltip id="Top countries" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Box>
                                                        </Box>
                                                        <Box height="85%" border={this.state.showBorder}
                                                            margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                            {this.state.mapDataLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                                <Bar
                                                                    data={{
                                                                        labels: this.state.countryChart.map(item => `${item.name}`),
                                                                        datasets: [
                                                                            {
                                                                                label: "Top Countries",
                                                                                data: this.state.countryChart.map(item => item.value),
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#32DAC8",
                                                                                hoverBackgroundColor: "#32DAC8"
                                                                            }
                                                                        ]
                                                                    }}
                                                                    options={{
                                                                        legend: {
                                                                            display: false
                                                                        },
                                                                        scales: {
                                                                            yAxes: [{
                                                                                gridLines: {
                                                                                    drawOnChartArea: false,
                                                                                    color: axesColor
                                                                                },
                                                                                ticks: {
                                                                                    min: 0,
                                                                                    max: this.axisMaxValue(this.dataMaxValue(this.state.countryChart)),
                                                                                    stepSize: this.axisMaxValue(this.dataMaxValue(this.state.countryChart)) / 5
                                                                                }
                                                                            }],
                                                                            xAxes: [{
                                                                                barPercentage: 0.4,
                                                                                gridLines: {
                                                                                    display: false,
                                                                                    lineWidth: 3,
                                                                                    color: '#425563'
                                                                                },
                                                                            }]
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                    }}
                                                                />}
                                                        </Box>
                                                    </Box>
                                                    <Box align="end" border={this.state.showBorder}
                                                        margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                                                        pad={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                        <Button
                                                            margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                            style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                            size="large"
                                                            plain="true"
                                                            label="Back"
                                                            onClick={() => {
                                                                this.setState({ viewMoreCountries: false });
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    left: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                            icon={<FormPrevious color={constants.BUTTON_LABEL_COLOR_3} />}
                                                        />
                                                    </Box>
                                                </StyledBox>
                                            </Box>
                                        </Box>}
                                    {this.state.viewMoreFeatures &&
                                        <StyledBox
                                            border={this.state.showBorder}
                                            justify="between"
                                            margin={{ "top": "small", "horizontal": "small", "bottom": "medium" }}>
                                            <Box
                                                height="100%"
                                                width="100%"
                                                justify="between"
                                                pad={{ "top": "small" }}
                                                margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                <Box border={this.state.showBorder} height="40px" direction="column-responsive" justify="between">
                                                    <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                        Events
                                                    </Heading>
                                                    <Box border={this.state.showBorder} justify="center" height="25px" alignSelf="center">
                                                        <a data-for="Top features" data-tip="All events used in the app by it's active users">
                                                            <CircleInformation size="medium" color="#444444" />
                                                        </a>
                                                        <ReactTooltip id="Top features" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                    </Box>
                                                </Box>
                                                <Box border={this.state.showBorder} >

                                                    {this.state.eventTableLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                        <Box border={this.state.showBorder}>
                                                            <div ref={this.dtwrapper}>
                                                                <PaginatedDataTable
                                                                    bg={constants.BOX_BACKGROUND_COLOR_1}
                                                                    columns={[
                                                                        {
                                                                            property: 'decodedName',
                                                                            header: 'Name',
                                                                            primary: true,
                                                                            size: "",
                                                                            render: eventdata => <Box><Text>{eventdata.decodedName}</Text></Box>
                                                                        },
                                                                        {
                                                                            property: 'type',
                                                                            header: 'Event Type',
                                                                            primary: false,
                                                                            size: "300px",
                                                                            render: eventdata => <Box><Text truncate>{eventdata.type}</Text></Box>
                                                                        },
                                                                        {
                                                                            property: 'loc',
                                                                            header: 'Location',
                                                                            size: "",
                                                                            render: eventdata => <Box><Text>{eventdata.loc}</Text></Box>
                                                                        },
                                                                        {
                                                                            property: 'value',
                                                                            header: 'Count',
                                                                            align: 'end',
                                                                            size: "60px",
                                                                            render: eventdata => <Box><Text>{eventdata.value}</Text></Box>
                                                                        },
                                                                    ]}
                                                                    data={DATA}
                                                                    noLoadingMsg={this.state.eventTable && this.state.eventTable.length != 0}
                                                                    sortable
                                                                    //sortable
                                                                    //sortoptions={[
                                                                    //    { col: 'name', label: 'Name' },
                                                                    //    { col: 'count', label: 'Count' },
                                                                    //]}
                                                                    resizeable={false}
                                                                    displayrows={constants.PAGE_SIZE}
                                                                    onUpdated={() => this.onDTUpdated()}
                                                                    styled

                                                                    PFplaceholder="Search"
                                                                    PFsearch={this.state.search}
                                                                    PFonChange={e => this.setState({ search: e.target.value })}
                                                                    PFonClear={e => this.setState({ search: "" })}

                                                                    PFcsvdata={[]}

                                                                    PFsearchable={true}
                                                                    PFfilterable={false}
                                                                    PFdownloadable={0}
                                                                />
                                                            </div>
                                                        </Box>}
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="Back"
                                                    onClick={() => {
                                                        this.setState({ viewMoreFeatures: false });
                                                        window.scrollTo({
                                                            top: 0,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        });
                                                    }}
                                                    icon={<FormPrevious color={constants.BUTTON_LABEL_COLOR_3} />}
                                                />
                                            </Box>
                                        </StyledBox>}
                                    {this.state.viewMoreActive &&
                                        <StyledBox style1
                                            justify="between"
                                            pad={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                            margin={{ "top": "none", "bottom": "medium", "left": "small", "right": "small" }}
                                            border={this.state.showBorder}
                                            height="500px">
                                            <Box border={{ side: "bottom" }} height="100%"
                                                pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Box height="15%" direction="column-responsive" justify="between" border={this.state.showBorder}
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                    <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                        Active Users
                                            </Heading>
                                                    <Box justify="center" border={this.state.showBorder} height="25px" alignSelf="center">
                                                        <a data-for="Active users" data-tip="The number of active users who have used the app over the selected time period">
                                                            <CircleInformation size="medium" color="#444444" />
                                                        </a>
                                                        <ReactTooltip id="Active users" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                    </Box>
                                                </Box>
                                                <Box height="85%" border={this.state.showBorder}
                                                    margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                    {this.state.userChartLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                        <Line
                                                            data={{
                                                                labels: this.state.userChart.map(item => `${monthNames[item.date.getMonth()]} ${item.date.getDate()}`),
                                                                datasets: [
                                                                    {
                                                                        label: "Active Users",
                                                                        data: this.state.userChart.map(item => item.value),
                                                                        backgroundColor: "transparent",
                                                                        borderColor: "#C140FF",
                                                                        lineTension: 0,

                                                                        pointHoverBackgroundColor: "#C140FF",
                                                                        pointHoverBorderColor: "#C140FF",
                                                                        pointHoverBorderWidth: 3,
                                                                        pointHoverRadius: 5,
                                                                        pointRadius: 5,
                                                                        pointBackgroundColor: "transparent",
                                                                        pointBorderWidth: 0,
                                                                        pointBorderColor: "transparent"
                                                                    }
                                                                ]
                                                            }}
                                                            options={{
                                                                legend: {
                                                                    display: false
                                                                },
                                                                scales: {
                                                                    yAxes: [{
                                                                        gridLines: {
                                                                            drawOnChartArea: false,
                                                                            color: axesColor
                                                                        },
                                                                        ticks: {
                                                                            min: 0,
                                                                            max: this.axisMaxValue(this.dataMaxValue(this.state.userChart)),
                                                                            stepSize: this.axisMaxValue(this.dataMaxValue(this.state.userChart)) / 5
                                                                        }
                                                                    }],
                                                                    xAxes: [{
                                                                        barPercentage: 0.4,
                                                                        gridLines: {
                                                                            display: false,
                                                                            lineWidth: 3,
                                                                            color: '#425563'
                                                                        },
                                                                    }]
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                        />}
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="Back"
                                                    onClick={() => {
                                                        this.setState({ viewMoreActive: false });
                                                        window.scrollTo({
                                                            top: 0,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        });
                                                    }}
                                                    icon={<FormPrevious color={constants.BUTTON_LABEL_COLOR_3} />}
                                                />
                                            </Box>
                                        </StyledBox>}
                                    {this.state.viewMoreTotal &&
                                        <StyledBox style1
                                            justify="between"
                                            pad={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                            margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
                                            border={this.state.showBorder}
                                            height="500px">
                                            <Box border={{ side: "bottom" }}
                                                pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Box height="15%" direction="column-responsive" justify="between" border={this.state.showBorder}
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                                                    <Heading level='4' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                                                        Total Active Users
                                                    </Heading>
                                                    <Box justify="center" border={this.state.showBorder} height="25px" alignSelf="center">
                                                        <a data-for="Active users" data-tip="The number of active users who have used the app over the selected time period">
                                                            <CircleInformation size="medium" color="#444444" />
                                                        </a>
                                                        <ReactTooltip id="Active users" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                    </Box>
                                                </Box>
                                                <Box height="85%" border={this.state.showBorder}
                                                    margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                                                    {this.state.userChartLoading ? <LoadingIndicator plain error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} /> :
                                                        <Pie
                                                            data={{
                                                                labels: this.state.userChart.map(item => `${monthNames[item.date.getMonth()]} ${item.date.getDate()}`),
                                                                datasets: [{
                                                                    label: "Total Active Users",
                                                                    data: this.state.userChart.map(item => item.value),
                                                                    borderWidth: 1,
                                                                    borderColor: "#ffffff",
                                                                    backgroundColor: pieColor,
                                                                    hoverBackgroundColor: pieColor
                                                                }]
                                                            }}
                                                            options={{
                                                                rotation: 0,
                                                                legend: {
                                                                    display: false,
                                                                    labels: true
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                        />}
                                                </Box>
                                            </Box>
                                            <Box align="end" border={this.state.showBorder}
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button
                                                    margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    style={{ color: constants.BUTTON_LABEL_COLOR_3, fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="Back"
                                                    onClick={() => {
                                                        this.setState({ viewMoreTotal: false });
                                                        window.scrollTo({
                                                            top: 0,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        });
                                                    }}
                                                    icon={<FormPrevious color={constants.BUTTON_LABEL_COLOR_3} />}
                                                />
                                            </Box>
                                        </StyledBox>}
                                </React.Fragment>
                                :
                                <SingleChart back={this.backButtonPressed} item={this.state.singleFeature.item} location={this.state.singleFeature.filters.location} timeSpan={this.getTimespan()} data={this.state.singleFeatureData} singleFeatureDataLoading={this.state.singleFeatureDataLoading} />
                        }
                    </form>
                </Box>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    sitesdata: state.sitesReducer.sitesdata,
    organisationdata: state.selectedOrganisationReducer.organisationdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...SiteAction }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);