import React from "react";
// eslint-disable-next-line
import { Box, Layer, Button, Text, Heading, Header, Anchor, TextInput, CheckBox, DropButton, Grommet } from 'grommet';
// eslint-disable-next-line
import { Close, FormClose, Filter, Download, Blank, Columns } from 'grommet-icons';
import constants from '../../constants';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';
import styled, { css } from 'styled-components';
import FilterLayer from './FilterLayer';
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import { hpe } from "grommet-theme-hpe";

export default class PrimaryFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            /*search: "",*/
            /*isFilterOpen: *//*this.props.open*//*false*/
        }
    }

    render() {
        let { placeholder, search, onChange, onClear, open, isClearVisible, onResetFilter, onClose, onClick, csvdata, searchable, filename, filterable, downloadable, filterablecolumns, columns, onChangeColumns, extraComponent } = this.props;
        return (
            <Grommet theme={hpe}>
            <Box width="100%" direction="row" margin={{ bottom: "small", "top": "small" }} justify="between" align="center" gap="medium">
                <Box direction="row" width="60%" height="35px" justify="between">
                    {searchable &&
                        <Box direction="row" flex>
                            <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                <TextInput focusIndicator={false} plain placeholder={placeholder} value={search} onChange={e => onChange(e)} />
                                {(search != "" && search != undefined) ?
                                    <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={search == ""} onClick={e => onClear(e)} />
                                    :
                                    <Button icon={<Blank />} disabled />}
                            </StyledBox>
                        </Box>}

                    {filterable &&
                        <FilterLayer
                            onClick={() => onClick()}
                            open={open}
                            onClose={() => onClose()}
                            onResetFilter={() => onResetFilter()}
                            isClearVisible={isClearVisible}
                        >
                            {this.props.children}
                        </FilterLayer>}
                    </Box>
                

                <Box direction="row" gap="small" align="center">
                    {filterablecolumns &&
                        <Box justify="center" height="26px">
                            <DropButton
                                data-for="editColumns" data-tip="Edit columns"
                                icon={<Columns color={constants.HPE_GREEN} />}
                                plain
                                dropAlign={{ top: "bottom" }}
                                dropContent={
                                    //<form>
                                    <Box pad="small" gap="small" style={{ "width": "250px" }}/*style={{ overflowY: "auto",overflowX: "hidden"}}*/>
                                        {columns.map(item =>
                                            <Box direction="row" gap="small" flex="false" align="center">
                                                <CheckBox
                                                    checked={item.isActive}
                                                    disabled={item.isFixed}
                                                    onChange={() => {
                                                        item.isActive = !item.isActive;
                                                        onChangeColumns(columns);
                                                    }} />
                                                <Text>
                                                    {item.header}
                                                </Text>
                                            </Box>)}
                                    </Box>
                                    //</form>
                                }>
                            </DropButton>
                            <ReactTooltip id="editColumns" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>}
                    {downloadable == 1 &&
                        <Box justify="center">
                            <CSVLink data={csvdata ? csvdata : []} filename={filename ? filename : "Exported Datatable"}>
                                <Box justify="center">
                                    <Button icon={<Download color={constants.HPE_GREEN} />} plain data-for="Download Info" data-tip="Export to CSV" />
                                    <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                                </Box>
                            </CSVLink>
                        </Box>}
                    {downloadable == 2 &&
                        <Box justify="center">
                            <Box justify="center">
                                <Button icon={<Download color={'#808080'} />} plain data-for="Download Info" data-tip="There is no data to be exported" />
                                <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                            </Box>
                        </Box>}
                    {extraComponent}
                </Box>
                </Box>
            </Grommet>
        );
    }
}