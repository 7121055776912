import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const exhibitorsReducer = (state = initialState.exhibitorsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_EXHIBITORS_RESPONSE: {
            return {
                ...state, 
                exhibitorsdata: _.assign(action.exhibitorsdata)
            };
        }
        default: { return state; }
    }
};

export default exhibitorsReducer;