import React from "react";
import { MsalContext } from "@azure/msal-react";
import { pca } from '../../src/indexApp';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LoadingIndicator from '../components/common/LoadingIndicator';
import constants from '../constants';
import telemetry from '../telemetry';
import RB from '../components/common/RBAC';

function handleResponse(resp) {
    if (resp !== null) {
        //accountId = resp.account.homeAccountId;
        pca.setActiveAccount(resp.account);
        console.log("respo",resp,pca);
        //showWelcomeMessage(resp.account);
    } else {
        // need to call getAccount here?
        const currentAccounts = pca.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            pca.setActiveAccount(activeAccount);
            //accountId = activeAccount.homeAccountId;
            //showWelcomeMessage(activeAccount);
        }
    }
}
const myWithAdalLogin = () => {
    // eslint-disable-next-line
    console.log("infunction");
    return function (WrappedComponent, renderLoading, renderError) {
        return class extends React.Component {
            static contextType = MsalContext;
            constructor(props) {
                super(props);
                this.state = {
                    logged: false,
                    error: null,
                };

                //pca.handleRedirectPromise().then(handleResponse).catch(err => {
                //    console.error("jaja",err);
                //});
                console.log("cmonInit", pca, pca.getAllAccounts(), "halo:", window.location);
                //this.log();
                console.log("msalCTOR");
                if (pca.getAllAccounts().length > 0) {
                    var request = {
                        account: pca.getAllAccounts()[0],
                        scopes: constants.scopes()
                    };
                    pca.acquireTokenSilent(request).then(tokenResponse => {
                        // Do something with the tokenResponse
                        if (tokenResponse.accessToken) {
                            if (this.mounted) {
                                this.setState({ logged: true });
                            } else {
                                this.todoSetState = { logged: true };
                            }
                        }
                        //return tokenResponse.accessToken;
                    }).catch(error => {
                        return pca.acquireTokenRedirect(request);
                        //if (error instanceof InteractionRequiredAuthError) {
                        //    // fallback to interaction when silent call fails
                        //    return myMSALObj.acquireTokenRedirect(request)
                        //}
                    });
                }
                //else if (pca.interactionInProgress() /*=== "login"*/) {
                //    //wait?
                //    console.log("cmonINTERA");
                //    setTimeout(this.setState({ logged: true }), 3000);
                //    if (this.mounted) {
                //        this.setState({ logged: true });
                //    } else {
                //        this.todoSetState = { logged: true };
                //    }
                //}
                else {
                    let oktaUser = localStorage.getItem("okta-token-storage");
                    if (oktaUser && !_.isEmpty(oktaUser)) {
                        oktaUser = JSON.parse(oktaUser);

                        if (oktaUser && oktaUser.accessToken) {
                            if (this.mounted) {
                                this.setState({ logged: true });
                            } else {
                                this.todoSetState = { logged: true };
                            }
                        }
                    }
                    else {
                        console.log("cmon2", pca, window.location.hash);
                        //pca.loginPopup()
                        //    .then(() => this.props.history.push("/orglist"))
                        //    .catch(error => console.log(error));
                        pca.loginRedirect({
                            scopes: constants.scopes()
                        });
                    //if (!sessionStorage.getItem("try")) {
                    //    sessionStorage.setItem("try", 1);
                    //    pca.loginRedirect();
                    //} else {
                    //    sessionStorage.setItem("try", sessionStorage.getItem("try") + 2);
                    //}

                    //setTimeout(() => pca.loginRedirect(), 0);
                    //if (window.location.hash.includes("#"))
                    //    pca.loginRedirect();
                    }
                }
            }
            componentDidMount = () => {
                console.log("msalCDM");
                this.mounted = true;
                if (this.todoSetState) {
                    this.setState(this.todoSetState);
                }
            };
            componentDidUpdate(prevProps, prevState) {
                if (!prevState.logged && this.state.logged) {
                    let route = this.props.match.path;
                    let page = "";
                    route.split("/").forEach((segment) => {
                        if ((segment.length > 0) && (segment.indexOf(":") !== 0))
                            page = page.concat("/").concat(segment);
                    });
                    if (page.length === 0)
                        page = "/";
                    telemetry.trackPageView({
                        name: page,
                        uri: this.props.match.url,
                        startTime: new Date(),
                        properties: {
                            superUser: RB.isSuperUser()
                        }
                    }, this.props.match.params);
                }
            }
            componentWillUnmount = () => {
                console.log("msalCWUM");
                this.mounted = false;
            };
            log() {
                console.log("clog", this.context, pca.getAllAccounts());
            }
            render() {
                console.log("cmon", this);
                const { logged, error } = this.state;
                if (logged) {
                    if (pca.getAllAccounts().length > 0)
                        return <AuthenticatedTemplate><WrappedComponent {...this.props} /></AuthenticatedTemplate>;
                    else
                        return <React.Fragment><WrappedComponent {...this.props} /></React.Fragment>;
                } else
                    return <UnauthenticatedTemplate><LoadingIndicator /></UnauthenticatedTemplate>;
                //if (logged && pca.getAllAccounts().length > 0) return <AuthenticatedTemplate><WrappedComponent {...this.props} /></AuthenticatedTemplate>;
                //if (logged) return <React.Fragment><WrappedComponent {...this.props} /></React.Fragment>;
                //if (!logged) return <UnauthenticatedTemplate><LoadingIndicator /></UnauthenticatedTemplate>;
                //if (true) return <p>No Logged</p>;
                if (error) return typeof renderError === 'function' ? renderError(error) : null;
                return typeof renderLoading === 'function' ? renderLoading() : null;
            }
        };
    };
};

// export const withOnlyAdalLogin = withAdalLogin(authContext, adalConfig.clientId);
export const msalLogin = myWithAdalLogin();