import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
import BookingListTable from './BookingListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PopUpConfirm from './common/PopUpConfirm';
import { getPortalToken } from '../api/ApiUtils';
import { MsalContext } from "@azure/msal-react";
import { withOktaAuth } from '@okta/okta-react';

export class BookingListContainer extends React.Component {
    static contextType = MsalContext;
    constructor() {
        super();
        this.isBookViaGraph = "";
        this.state = {
            selectedDepartment: 0,
            deletedDepartmentIds: [],
            open: undefined,
            bookingsdataLoading: true,
            bookingsdata: []
        };

    }

    async getReservationViaGrahp() {
        //Fetch graph
        const options = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
            },
        };
        let url = 'https://hpepngdemo.azure-api.net/graph/me/events?%24top=100';
        let now = new Date().toISOString();
        let d = new Date(now)
        d.setDate(d.getDate() + 17);
        d.setHours(23, 59, 59, 0);
        let end = d.toISOString();
        fetch(url, options)
            .then(response => {
                if (response.status == 200) {
                    //Success, refresh the map, desk should be booked as red
                    return response.json();
                }
                return [];
            })
            .then(response => {
                let init = [];
                if (response.hasOwnProperty("value"))
                    init = response.value.filter(item => {
                        if (item.attendees.length > 0) {
                            return item.attendees.some(attendee => attendee.type == "resource" && attendee.status && attendee.status.response && attendee.status.response == "accepted");
                        }
                        return false;
                    });
                //init = response.value.filter(item => item.attendees && item.attendees[0] && item.attendees[0].type == "resource" && item.attendees[0].status.response == "accepted");
                this.setState({ bookingsdata: init, bookingsdataLoading: false })
            })
            .catch(error => { console.log("graph-error", error); this.setState({ bookingsdata: [], bookingsdataLoading: true }) });
    }

    async getReservations(campusID, teemOrgID) {
        let d = new Date()
        d.setDate(d.getDate() + 7);
        d.setHours(23, 59, 59, 0);
        let start = Math.floor(new Date().getTime() / 1000);
        let end = Math.floor(d.getTime() / 1000);
        const options = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getPortalToken(),
                'X-LocationID': campusID,
                'X-OrgID': teemOrgID
            },
        };
        //let u = "inherit_settings=0&include[]=devices&include[]=buildings&include[]=campuses&include[]=floors&include[]=licenses&include[]=room_resource_categories&include[]=room_resources&include[]=calendars&bookable=true&capacity=1&floor_id=159753&inherit_settings=1&ordering=name&page=1&per_page=20&range_end_at=1639520400&range_start_at=1639519500"
        return fetch(`https://myworkplace-itg.intelligentspaces.int.hpe.com/connectors/teem/calendars/reservations/?include[]=participants`, options)
            .then(response => {
                if (response.status == 200) {
                    //Success, refresh the map, desk should be booked as red
                    return response.json();
                }
                return [];
            })
            .then(resp => {
                let user = this.context.accounts.length != 0 ? this.context.accounts[0].username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "";
                let reservetions = resp.reservations;
                if (user) {
                    reservetions = reservetions.filter(r => {
                        let start = r.description.indexOf("[");
                        let end = r.description.indexOf("]");
                        let email = r.description.substring(start + 1, end);
                        r.description = r.description.substring(0,start);
                        return email == user;
                    });
                }
                this.setState({ bookingsdata: /*resp.reservations*/reservetions, bookingsdataLoading: false });
            })
            .catch(error => { console.log("graph-error", error); this.setState({ bookingsdata: [], bookingsdataLoading: false }) });
    }

    async componentDidMount() {
        //Original code, using constants.BOOKVIAGRAPH, line:178 check from backend
        if (false) {
            if (constants.BOOKVIAGRAPH) {
                //Fetch graph
                const options = {
                    method: 'get',
                    headers: {
                        //'X-AppName': 'myWorkplace Demo',
                        //'X-LocationID': '134040',
                        //'X-OrgID': '11574',
                        'Content-Type': 'application/json',
                        'Authorization': await getPortalToken(),
                        //'X-ZUMO-AUTH': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJlbXBsb3llZUBocGVwbmdhbHdheS5vbm1pY3Jvc29mdC5jb20iLCJ1bmlxdWVfbmFtZSI6ImVtcGxveWVlQGhwZXBuZ2Fsd2F5Lm9ubWljcm9zb2Z0LmNvbSIsIm5hbSI6IkVtcGxveWVlIFVzZXIiLCJ0ZW4iOiI1MWQ1NzE3NzBkOWY0MjVkOWY4NzEyYjVmMTY4NzYzMyIsIm9yZyI6Ik1PT0ciLCJ2ZXIiOiIzIiwiaXNzIjoiaHR0cHM6Ly9ocGVwbmdteXdwZGVtby5henVyZXdlYnNpdGVzLm5ldC8iLCJhdWQiOiJodHRwczovL2hwZXBuZ215d3BkZW1vLmF6dXJld2Vic2l0ZXMubmV0LyIsImV4cCI6MTYyNjIyODc3NiwibmJmIjoxNjI2MTk5OTc2fQ.Jac4rQ1kkjHPxFo90_YAjyPt3WV3xgHEiSzSbMrZsXg',
                    },
                };
                let url = 'https://hpepngdemo.azure-api.net/graph/me/events?%24top=100';
                let now = new Date().toISOString();
                let d = new Date(now)
                d.setDate(d.getDate() + 17);
                d.setHours(23, 59, 59, 0);
                let end = d.toISOString();
                //Not working links
                //let link = `https://hpepngdemo.azure-api.net/graph/me/events?startdatetime=${now}&enddatetime=${end}`;
                //let link2 = `https://hpepngdemo.azure-api.net/graph/me/events?startDateTime=2021-12-26T19:00:00`;
                //let link3 = "https://hpepngdemo.azure-api.net/graph/me/events?$filter=startsWith(startDateTime,\'2021-12-26T19:00:00-08:00\')";
                //let link4 ="https://hpepngdemo.azure-api.net/graph/me/events?$filter=start/dateTime ge '2021-11-25T00:00:00'";
                fetch(url, options)
                    .then(response => {
                        if (response.status == 200) {
                            //Success, refresh the map, desk should be booked as red
                            return response.json();
                        }
                        return [];
                    })
                    .then(response => {
                        let init = [];
                        if (response.hasOwnProperty("value"))
                            init = response.value.filter(item => {
                                if (item.attendees.length > 0) {
                                    return item.attendees.some(attendee => attendee.type == "resource" && attendee.status && attendee.status.response && attendee.status.response == "accepted");
                                }
                                return false;
                            });
                        //init = response.value.filter(item => item.attendees && item.attendees[0] && item.attendees[0].type == "resource" && item.attendees[0].status.response == "accepted");
                        this.setState({ bookingsdata: init, bookingsdataLoading: false })
                    })
                    .catch(error => { console.log("graph-error", error); this.setState({ bookingsdata: [], bookingsdataLoading: true }) });
            }
            else {
                if (this.props.sitedata)
                    this.getReservations(this.props.sitedata.campusId, this.props.sitedata.teemOrgId);
                else if (this.props.sitesdata) {
                    let site = this.props.sitesdata.find(s => s.id == sessionStorage.getItem("locationId"));
                    if (site)
                        this.getReservations(site.campusId, site.teemOrgId);
                }
            }
        }


        if (this.props.sitedata) {
            if (constants.BOOKVIAGRAPH && this.props.sitedata.graphEnabled) {
                this.isBookViaGraph = true;
                this.getReservationViaGrahp();
            }
            else {
                this.isBookViaGraph = false;
                this.getReservations(this.props.sitedata.campusId, this.props.sitedata.teemOrgId);
            }
        }
        else if (this.props.sitesdata) {
            let site = this.props.sitesdata.find(s => s.id == sessionStorage.getItem("locationId"));
            if (site) {
                if (constants.BOOKVIAGRAPH && site.graphEnabled) {
                    this.isBookViaGraph = true;
                    this.getReservationViaGrahp();
                }
                else {
                    this.isBookViaGraph = false;
                    this.getReservations(site.campusId, site.teemOrgId);
                }
            }
        }
    }


    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });


    render() {
        const { departmentsdata } = this.props;
        const { open, selectedDepartment, deletedDepartmentIds, bookingsdataLoading, /*bookingsdata*/ } = this.state;

        let bookingsdata =[
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAieagZw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJ_QcmAAA=",
                "createdDateTime": "2021-07-14T14:17:26.3918554Z",
                "lastModifiedDateTime": "2021-07-15T12:21:18.7300369Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAieagZw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E0080000000096B7C9F8BA78D70100000000000000001000000058DE3A6382D4C545850C7E7F00DC2600",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 1",
                "bodyPreview": "My Workplace Booking - Desk 1 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJ%2BQcmAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 1 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-07-14T14:17:26.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-07-14T22:55:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 1",
                    "locationUri": "Desk1@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "9f573001-080b-4c29-b340-d3a374889b48",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 1",
                        "locationUri": "Desk1@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "9f573001-080b-4c29-b340-d3a374889b48",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "none",
                            "time": "0001-01-01T00:00:00Z"
                        },
                        "emailAddress": {
                            "name": "Desk 1",
                            "address": "Desk1@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAieZcCw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJa6SCAAA=",
                "createdDateTime": "2021-07-13T14:40:13.2768046Z",
                "lastModifiedDateTime": "2021-07-14T12:05:52.5150491Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAieZcCw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E00800000000ED0E1AFDF477D7010000000000000000100000008ABFC7F0FA3DEE47BBE6B3F1257D62E3",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 15",
                "bodyPreview": "My Workplace Booking - Desk 15 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJa6SCAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 15 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-07-13T14:40:12.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-07-13T22:55:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 15",
                    "locationUri": "Desk15@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "b8561f03-67df-4f8c-9d3f-52fa874b9d6d",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 15",
                        "locationUri": "Desk15@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "b8561f03-67df-4f8c-9d3f-52fa874b9d6d",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "accepted",
                            "time": "2021-07-13T14:40:16.8732019Z"
                        },
                        "emailAddress": {
                            "name": "Desk 15",
                            "address": "Desk15@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAiVm9Iw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJa6SBAAA=",
                "createdDateTime": "2021-07-13T11:48:31.6246197Z",
                "lastModifiedDateTime": "2021-07-13T12:25:29.6555521Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAiVm9Iw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E0080000000075AED600DD77D7010000000000000000100000008A6ADCFA504D6542B0DD6397F2F832B1",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 1",
                "bodyPreview": "My Workplace Booking - Desk 1 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AACJa6SBAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 1 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-07-13T11:48:31.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-07-13T11:49:35.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 1",
                    "locationUri": "Desk1@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "9f573001-080b-4c29-b340-d3a374889b48",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 1",
                        "locationUri": "Desk1@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "9f573001-080b-4c29-b340-d3a374889b48",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "none",
                            "time": "0001-01-01T00:00:00Z"
                        },
                        "emailAddress": {
                            "name": "Desk 1",
                            "address": "Desk1@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7eDw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABV9xecAAA=",
                "createdDateTime": "2021-04-29T13:54:02.7319479Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.8406588Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7eDw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E008000000007836BF1CFF3CD7010000000000000000100000007CDB03B2E4613D4494D5EC8A2724E6E1",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "My Workplace Booking - Chrishroom",
                "bodyPreview": "My Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "busy",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABV9xecAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-29T13:54:03.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-29T13:54:13.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Chrishroom",
                    "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Chrishroom",
                        "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "declined",
                            "time": "2021-04-29T13:54:15.1188157Z"
                        },
                        "emailAddress": {
                            "name": "Chrishroom",
                            "address": "Chrishroom@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7eDQ==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABV9xebAAA=",
                "createdDateTime": "2021-04-27T14:00:27.2188532Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.7630003Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7eDQ==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E008000000002EC217AD6D3BD701000000000000000010000000F2457DB27B110B43B39172BF1B1C142A",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 11",
                "bodyPreview": "My Workplace Booking - Desk 11 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABV9xebAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 11 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-27T14:00:27.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-27T22:55:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 11",
                    "locationUri": "Desk11@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "a5d4a398-ef38-4124-a678-3cf6073ff5a1",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 11",
                        "locationUri": "Desk11@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "a5d4a398-ef38-4124-a678-3cf6073ff5a1",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "accepted",
                            "time": "2021-04-27T14:00:30.8829566Z"
                        },
                        "emailAddress": {
                            "name": "Desk 11",
                            "address": "Desk11@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7d/w==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfIAAA=",
                "createdDateTime": "2021-04-22T10:09:03.5076457Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.0929409Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7d/w==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E008000000002ACDB0855F37D7010000000000000000100000002B52DF07D6B1CD4FA441F077AEDDF281",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 14",
                "bodyPreview": "My Workplace Booking - Desk 14 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfIAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 14 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-22T10:08:17.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-22T10:22:33.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 14",
                    "locationUri": "Desk14@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "7f92eec6-f670-47e7-9dc7-bc0ece5c4785",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 14",
                        "locationUri": "Desk14@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "7f92eec6-f670-47e7-9dc7-bc0ece5c4785",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "declined",
                            "time": "2021-04-22T10:22:35.8923237Z"
                        },
                        "emailAddress": {
                            "name": "Desk 14",
                            "address": "Desk14@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7d/Q==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfHAAA=",
                "createdDateTime": "2021-04-22T10:08:10.6089362Z",
                "lastModifiedDateTime": "2021-05-14T14:07:14.9993522Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7d/Q==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E008000000006DF228665F37D7010000000000000000100000008EE31E2A032ED5489CC410832CED2593",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 12",
                "bodyPreview": "My Workplace Booking - Desk 12 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfHAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 12 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-22T10:07:24.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-22T10:22:09.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 12",
                    "locationUri": "Desk12@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "275db535-ae90-47fe-a69a-915c941f41d1",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 12",
                        "locationUri": "Desk12@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "275db535-ae90-47fe-a69a-915c941f41d1",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "declined",
                            "time": "2021-04-22T10:22:12.6762184Z"
                        },
                        "emailAddress": {
                            "name": "Desk 12",
                            "address": "Desk12@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7eCQ==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfGAAA=",
                "createdDateTime": "2021-04-22T10:01:10.5836084Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.6017087Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7eCQ==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E00800000000F451CE6B5E37D701000000000000000010000000E1C2B2354E9D124CB0DB83AAA2E0593F",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "My Workplace Booking - Chrishroom",
                "bodyPreview": "My Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "busy",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfGAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-23T11:00:00.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-23T11:30:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Chrishroom",
                    "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Chrishroom",
                        "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "tentativelyAccepted",
                            "time": "2021-04-22T10:01:14.4783899Z"
                        },
                        "emailAddress": {
                            "name": "Chrishroom",
                            "address": "Chrishroom@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7eBw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfFAAA=",
                "createdDateTime": "2021-04-22T09:52:31.9647585Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.5140922Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7eBw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E008000000001D5DAF365D37D701000000000000000010000000977856D8BD67FC4A90EE8031F5647931",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "My Workplace Booking - Chrishroom",
                "bodyPreview": "My Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "busy",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfFAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Meeting in Chrishroom (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-23T10:00:00.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-23T10:30:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Chrishroom",
                    "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Chrishroom",
                        "locationUri": "Chrishroom@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "c655f234-0887-42b7-a7de-49e2169913f3",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "tentativelyAccepted",
                            "time": "2021-04-22T09:52:36.1163558Z"
                        },
                        "emailAddress": {
                            "name": "Chrishroom",
                            "address": "Chrishroom@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            },
            {
                "@odata.etag": "W/\"ibrH1UYOckOHSBToHTNVeAAAYG7eAw==\"",
                "id": "AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy_XXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfEAAA=",
                "createdDateTime": "2021-04-22T09:45:49.9366672Z",
                "lastModifiedDateTime": "2021-05-14T14:07:15.328904Z",
                "changeKey": "ibrH1UYOckOHSBToHTNVeAAAYG7eAw==",
                "categories": [],
                "transactionId": null,
                "originalStartTimeZone": "UTC",
                "originalEndTimeZone": "UTC",
                "iCalUId": "040000008200E00074C5B7101A82E00800000000CCBE0E475C37D701000000000000000010000000D9598573B9D3784FB59F26B808B7606B",
                "reminderMinutesBeforeStart": 15,
                "isReminderOn": true,
                "hasAttachments": false,
                "subject": "Desk Booking - Desk 13",
                "bodyPreview": "My Workplace Booking - Desk 13 (reserved via My Workplace by Employee User).",
                "importance": "normal",
                "sensitivity": "normal",
                "isAllDay": false,
                "isCancelled": false,
                "isOrganizer": true,
                "responseRequested": true,
                "seriesMasterId": null,
                "showAs": "free",
                "type": "singleInstance",
                "webLink": "https://outlook.office365.com/owa/?itemid=AAMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAAAAADNwoAnxLCtR5Ogwy%2BXXxEnBwCJusfVRg5yQ4dIFOgdM1V4AAAAAAENAACJusfVRg5yQ4dIFOgdM1V4AABTKpfEAAA%3D&exvsurl=1&path=/calendar/item",
                "onlineMeetingUrl": null,
                "isOnlineMeeting": false,
                "onlineMeetingProvider": "unknown",
                "allowNewTimeProposals": true,
                "occurrenceId": null,
                "isDraft": false,
                "hideAttendees": false,
                "responseStatus": {
                    "response": "organizer",
                    "time": "0001-01-01T00:00:00Z"
                },
                "body": {
                    "contentType": "html",
                    "content": "<html>\r\n<head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n<meta content=\"text/html; charset=us-ascii\">\r\n</head>\r\n<body>\r\nMy Workplace Booking - Desk 13 (reserved via My Workplace by Employee User).\r\n</body>\r\n</html>\r\n"
                },
                "start": {
                    "dateTime": "2021-04-22T09:45:17.0000000",
                    "timeZone": "UTC"
                },
                "end": {
                    "dateTime": "2021-04-22T22:55:00.0000000",
                    "timeZone": "UTC"
                },
                "location": {
                    "displayName": "Desk 13",
                    "locationUri": "Desk13@hpepngalway.onmicrosoft.com",
                    "locationType": "conferenceRoom",
                    "uniqueId": "ccca5bcb-1a84-41b9-bdf9-21aa363c5b8e",
                    "uniqueIdType": "locationStore",
                    "address": {
                        "street": "",
                        "city": "",
                        "state": "",
                        "countryOrRegion": "",
                        "postalCode": ""
                    },
                    "coordinates": {}
                },
                "locations": [
                    {
                        "displayName": "Desk 13",
                        "locationUri": "Desk13@hpepngalway.onmicrosoft.com",
                        "locationType": "conferenceRoom",
                        "uniqueId": "ccca5bcb-1a84-41b9-bdf9-21aa363c5b8e",
                        "uniqueIdType": "locationStore",
                        "address": {
                            "street": "",
                            "city": "",
                            "state": "",
                            "countryOrRegion": "",
                            "postalCode": ""
                        },
                        "coordinates": {}
                    }
                ],
                "recurrence": null,
                "attendees": [
                    {
                        "type": "resource",
                        "status": {
                            "response": "accepted",
                            "time": "2021-04-22T09:45:53.8509729Z"
                        },
                        "emailAddress": {
                            "name": "Desk 13",
                            "address": "Desk13@hpepngalway.onmicrosoft.com"
                        }
                    }
                ],
                "organizer": {
                    "emailAddress": {
                        "name": "Employee User",
                        "address": "employee@hpepngalway.onmicrosoft.com"
                    }
                },
                "onlineMeeting": null,
                "calendar@odata.associationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')/$ref",
                "calendar@odata.navigationLink": "https://hpepngdemo.azure-api.net/graph/users('employee@hpepngalway.onmicrosoft.com')/calendars('AQMkADBmNDAyZTY5LTA1NmMtNGQ1ZS05N2U5LTQ2MDJlNzY1Y2IwZgBGAAADzcKAJ8SwrUeToMMvl18RJwcAibrH1UYOckOHSBToHTNVeAAAAgEGAAAAibrH1UYOckOHSBToHTNVeAAAAl6DAAAA')"
            }
        ];

        if (bookingsdataLoading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <BookingListTable bookingsdata={this.state.bookingsdata} isBookViaGraph={/*this.isBookViaGraph*/constants.BOOKVIAGRAPH}/>
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    departmentsdata: state.departmentsReducer.departmentsdata,
    sitedata: state.selectedSiteReducer.sitedata,
    sitesdata: state.sitesReducer.sitesdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...DepartmentAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(BookingListContainer));
