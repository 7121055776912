import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';


//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);


class MasterAppListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (/*nro != 0 || !RB.isSuperUser()*/true) {
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <StyledBox ref={this.dtwrapper}>
                {this.renderMasterAppsTable(this.props)}
            </StyledBox>
        );
    }

    renderMasterAppsTable(props) {
        const COLUMNS = [
            {
                property: 'lcname',
                header: <Box justify="center" height="100%">Application Name</Box>,
                render: masterappdata => <Text>{masterappdata.name}</Text>
            },
            {
                property: 'lcstatus',
                header: <Box justify="center" height="100%">Status</Box>,
                render: masterappdata => masterappdata.status ? <Text>Available</Text> : <Text>Pending</Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: masterappdata => <Text hidden>{masterappdata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: masterappdata => !RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, masterappdata.id)} icon={<Blank />} /></Box>
                    //: masterappdata.orphaned ?
                    //    <Box direction='row' gap="xsmall">
                    //        {/*<Button plain disabled={props.deletedAppIds.includes(masterappdata.id)} onClick={evt => props.handleEdit(evt, masterappdata.id)} icon={<Edit color="#f04953" />} />*/}
                    //        <Button plain disabled={props.deletedAppIds.includes(masterappdata.id)} onClick={evt => props.handleDelete(evt, masterappdata.id)} icon={<Trash color="#f04953" />} />
                    //    </Box> :
                    //    <Box direction='row' gap="xsmall">
                    //        {/*<Button plain disabled={props.deletedAppIds.includes(masterappdata.id)} onClick={evt => props.handleEdit(evt, masterappdata.id)} icon={<Edit color="#333333" />} />*/}
                    //        <Button plain disabled={props.deletedAppIds.includes(masterappdata.id)} onClick={evt => props.handleDelete(evt, masterappdata.id)} icon={<Trash color="#333333" />} />
                    //    </Box>
                    :
                    <Box width="24px" align="center">
                        <Button plain disabled={props.deletedAppIds.includes(masterappdata.id)} onClick={evt => props.handleDelete(evt, masterappdata.id)} icon={<Trash color="#333333" />} />
                    </Box>
            }
        ];

        let DATA = [];

        if (props.masterappsdata[0] && props.masterappsdata[0].count != 0) {
            props.masterappsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.masterappsdata.forEach(ev => {
                DATA.push({
                    ...ev,
                    lcname: ev.name ? ev.name.toLowerCase() : '',
                    lcstatus: ev.status ? "Available" : "Pending",
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                ("available".includes(search) && d.status) ||
                ("pending".includes(search) && !d.status)
            );
        }

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcstatus"
                }))}
                data={DATA}
                noLoadingMsg={this.props.masterappsdata.length != 0}
                sortable
                //sortoptions={[
                //    {col: 'lcname', label: 'Application Name'},
                //    {col: 'lcstatus', label: 'Status'},
                //    ]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
export default MasterAppListTable;
