import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

const telemetry = new ApplicationInsights({
  config: {
    connectionString: "InstrumentationKey=252b7096-5ad2-479d-abb9-4a4b689e35ce;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=9141ef19-3151-4208-82d3-eaf2f87cc44d",
    autoTrackPageVisitTime: false,
    disableAjaxTracking: true,
    disableCookiesUsage: true,
    disableFetchTracking: true,
    disableIkeyDeprecationMessage: true,
    enableAutoRouteTracking: false
  }
});
telemetry.loadAppInsights();

export default telemetry;
