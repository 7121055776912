import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Tabs, Tab } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

const PaginatedDataTable = withPagination(DataTable);

class LocUserListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);

        return (
            <StyledBox ref={this.dtwrapper} style={{ "min-width": "1060px" }}>
                {this.renderUsersTable(this.props, canEdit)}
            </StyledBox>
        );
    }

    ArrayToString(arr) {
        var string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.role.name;
            }
            else {
                string += item.role.name;
                string += ", ";
            }

        })
        return string;
    }

    renderUsersTable(props, canEdit) {

        const COLUMNS = [
            {
                property: 'lcusername',
                header: 'Username',
                render: users => <Text>{users.username}</Text>
            },
            {
                property: 'lcemail',
                header: 'Email',
                render: users => <Text>{users.email}</Text>
            },
            {
                property: 'lcrole',
                header: 'Role',
                render: users => <Text>{users.role}</Text>
            }, {
                property: 'lcorgadmin',
                header: <Box justify="center" height="100%">Org Admin</Box>,
                size: "85px",
                render: users => <CheckBox readOnly checked={users.lcorgadmin} disabled />
            },
            {
                property: 'lcactive',
                header: <Box justify="center" height="100%">Active</Box>,
                size: "70px",
                render: users => <CheckBox readOnly checked={users.lcactive} disabled />
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: users => <Text hidden>{users.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: users => (canEdit && !users.lcorgadmin) ?
                    <Box direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedUserIds.includes(users.id)} onClick={evt => props.handleEdit(evt, users.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedUserIds.includes(users.id)} onClick={evt => props.handleDelete(evt, users.id)} icon={<Trash />} />
                    </Box>
                    :
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, users.id)} icon={<Blank />} /></Box>,
            },
        ];

        let DATA = [];
        const locationid = sessionStorage.getItem("locationId");
        //console.log(props.usersdata, locationid);

        function isActive(user) {
            var active = false;
            if (!user.userRoles || user.userRoles.length == 0) {
                return active;
            }
            if (user.userRoles.filter(r => r.active).length != 0) {
                active = true;
            }
            return active;
        }

        function isOrgAdmin(user) {
            if (user.userRoles.find(r => r.roleId == "f028c85c16a148e8820da3537d74e9d9")) {
                return true;
            }
            else return false;
        }

        if (props.usersdata[0] && props.usersdata[0].count != 0) {
            props.usersdata.forEach(ev => {
                const roles = ev.userRoles.filter(r => r.locationId == locationid).slice();
                if (ev.userRoles.length != 0 && ev.userRoles.filter(r => !(!r.active && r.roleId == "f028c85c16a148e8820da3537d74e9d9")).length != 0) {
                    DATA.push({
                        ...ev, lcusername: ev.username.toLowerCase(),
                        lcemail: ev.email.toLowerCase(),
                        role: this.ArrayToString(roles),
                        lcrole: this.ArrayToString(roles).toLowerCase(),
                        lcactive: isActive(ev),
                        lcorgadmin: isOrgAdmin(ev),
                    })
                }
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.username.toLowerCase().includes(search) ||
                d.email.toLowerCase().includes(search) ||
                d.role.toLowerCase().includes(search) ||
                ("active".includes(search) && d.lcactive) ||
                ("org admin".includes(search) && d.lcorgadmin) ||
                ("orgadmin".includes(search) && d.lcorgadmin)
            );
        }

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.usersdata.length != 0 && notFilteredData.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcrole', label: 'Role' },
                //    { col: 'lactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled
                line1="No users added yet."
                line2={<div>Add one now via the <b>Create New</b> button</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

}

export default LocUserListTable;
