import React from "react";
// eslint-disable-next-line
import { Box, Layer, Button, Text, Heading, Header, Anchor, Grommet } from 'grommet';
// eslint-disable-next-line
import { Close, FormClose, Filter } from 'grommet-icons';
import constants from '../../constants';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';
import styled, { css } from 'styled-components';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

const FilterButton = styled(Button)`
        height: 48px;
        border-radius: 5px 5px 5px 5px;`;


export default class FilterSidePanel extends React.Component {
    constructor(props) {
        super(props);
        //this.state = { open: this.props.open };
    }
    //componentDidUpdate(prevProps) {
    //    if (this.props.open != prevProps.open) {
    //        this.setState({ open: this.props.open });
    //    }
    //}

    render() {
        var headerHeight = "0px";
        if (document.getElementById('header')) {
            headerHeight = document.getElementById('header').offsetHeight + "px";
        }

        let { open, isClearVisible } = this.props;
        return (
            <Box direction="row" style={{ width: "fit-content" }}>
                {
                    open &&
                    <Layer
                        position={"right"}
                        margin={{ "top": headerHeight }}
                        full={"vertical"}
                        modal
                        //onClickOutside={() => this.props.onClose()}
                        //onEsc={() => this.props.onClose()}
                    >
                        <Box
                            background={constants.BOX_BACKGROUND_COLOR_1}
                            width={{ min: 'medium' }}
                            pad={{ horizontal: 'medium', vertical: 'medium' }}
                            gap="small"
                            fill="vertical"
                            round="5px"
                            flex
                            overflow="auto"
                        >
                            <Header>
                                <Heading as="p" color="text-strong" margin="none">
                                    {this.props.header || "Assign"}
                                </Heading>
                                <Box /*pad={{ right: "small" }}*/ >
                                    <Button plain
                                        icon={<FormClose color={constants.BUTTON_LABEL_COLOR_3} size="large" />}
                                        onClick={() => this.props.onClose()}
                                    />
                                </Box>
                            </Header>
                            <Box id="components" /*style={{ "minHeight" :"auto" }}*/ style={{ height: "100%", justifyContent: "space-between" }} width={{ min: 'medium', max: 'medium' }}>
                                {/*All Filter Components will be rendered here*/}
                                <Box>
                                {this.props.description}
                                    {this.props.children}
                                </Box>
                                <Box justify="between" direction="row">
                                    <StyledButton typeAdd label="Assign" onClick={() => this.props.onAssign()} />
                                </Box>
                            </Box>
                        </Box>
                    </Layer>
                }
            </Box>);
    }
}

