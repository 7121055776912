import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import constants from '../constants';
// eslint-disable-next-line
import { Box, Heading, Grommet, Grid, Text, Button, Image, RoutedAnchor, Anchor, RoutedButton, Select, DropButton, TextInput, FormField, Keyboard } from 'grommet';
import { Blank, Cart, User } from 'grommet-icons';
import { grommet } from "grommet/themes";
import { hpe } from 'grommet-theme-hpe';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import validator from 'validator';
import { authContext } from '../adal/adalConfig';
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AuthenticationService from '../msal/MsalConfig';
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import LoadingIndicator from './common/LoadingIndicator';
import './Landing.css';
import { withOktaAuth } from '@okta/okta-react';

function setAuthContextConfig(type) {
    authContext.config = Object.assign(authContext.config, constants.multiConfig[type]);
}


class Landing extends Component {
    static contextType = MsalContext;
    constructor() {
        super();
        this.state = {
            loaded: false,
            loginProcessOn: false,
        };
        this.oktaLogin = this.oktaLogin.bind(this);
        this.oktaLogout = this.oktaLogout.bind(this);
    }

    mail_err = "";
    mail = "";
    domains = [];
    domains = ["@hpe.com"]

    componentDidMount() {
    }

    componentWillMount() {
        setTimeout(() => {
            this.setState({ loginProcessOn: true });
        }, sessionStorage.getItem("loginProcessStarted") ? 5000 : 2000);
    }

    ClearErrorMessages() {
        this.mail_err = "";
    }

    ClickLoginWithValidation(param) {
        sessionStorage.setItem("loginProcessStarted", true);
        sessionStorage.setItem("Login_Client_Type", param);
        setAuthContextConfig(param);
        this.setState({ loaded: true });
        if (this.mail.trim().length != 0) sessionStorage.setItem("mail", this.mail);
        if (sessionStorage.getItem("path")) {
            this.props.history.push(sessionStorage.getItem("path"));
        }

        //Validation
        let errorsno = 0;
        this.ClearErrorMessages();
        if (this.mail.trim().length == 0) {
            errorsno++;
            this.mail_err = constants.EMPTY_ERROR;
        }
        if (!validator.isEmail(this.mail.trim()) && this.mail.trim().length != 0) {
            errorsno++;
            this.mail_err = constants.EMAIL_ERROR;
        }
        if (!this.domains.includes(this.mail.trim().slice(this.mail.indexOf("@"))) && validator.isEmail(this.mail.trim()) && this.mail.trim().length != 0) {
            errorsno++;
            this.mail_err = "This e-mail address is not supported";
        }

        if (errorsno == 0) {
            if (this.context.accounts.length == 0)
                this.context.instance.loginRedirect({
                    scopes: constants.scopes(),
                    loginHint: this.mail,
                });
            //if (this.context.accounts.length == 0)
            //    this.context.instance.loginPopup()
            //        .then(() => this.props.history.push("/orglist"))
            //        .catch(error=> console.log(error));
            //else {
            //    this.props.history.push("/orglist");
            //}
            //this.props.history.push("/orglist");
        }
    };

    ClickLoginNoValidation = (param) => {
        //setAuthContextConfig(param);
        sessionStorage.setItem("loginProcessStarted", true);
        sessionStorage.setItem("Login_Client_Type", param);
        sessionStorage.removeItem("mail");
        //this.setState({ loaded: true });
        //if (this.mail.trim().length != 0) sessionStorage.setItem("mail", this.mail);
        if (sessionStorage.getItem("path")) {
            this.props.history.push(sessionStorage.getItem("path"));
        }
        console.log("Auth", this.context);
        //if (this.context.accounts.length == 0)
        //    this.context.instance.loginPopup()
        //        .then(() => this.props.history.push("/orglist"))
        //        .catch(error => console.log(error));
        //else {
        //    this.props.history.push("/orglist");
        //}
        if (this.context.accounts.length == 0)
            this.context.instance.loginRedirect({ scopes: constants.scopes() });
        //this.props.history.push("/orglist");
    };

    async oktaLogin() {
        sessionStorage.setItem("loginProcessStarted", true);
        sessionStorage.setItem("Login_Client_Type", "MULTI_TENANT");
        sessionStorage.removeItem("mail");
        await this.props.oktaAuth.signInWithRedirect();
    }

    async oktaLogout() {
        await this.props.oktaAuth.signOut('/');
    }

    render() {
        if (this.props.authState && this.props.authState.isAuthenticated) {
            this.props.history.push("/orglist");
        }
        if (this.context.accounts.length != 0) {
            this.props.history.push("/orglist");
        }
        if (!this.state.loginProcessOn) {
            return (
                <LoadingIndicator />
            );
        }
        console.log("AuthR", this.context);
        const { loaded } = this.state;

        return (
            <Grommet theme={hpe} full={true}>
                <Grid
                    fill
                    rows={["auto", "flex"]}
                    columns={["50%", "flex"]}
                    areas={[
                        { name: "main", start: [0, 0], end: [1, 1] }
                    ]}
                >
                    {(constants.RELEASE_TYPE == "HPE-MTF" || constants.RELEASE_TYPE == "PROD") &&
                        <Box theme={hpe} background={constants.LAYOUT_COLOR}
                            gridArea="main" align="center" justify="center"
                        >
                            <Box gap="medium">
                                <Box pad={{ "horizontal": "medium" }}>
                                    <Image src="./Images/Login_Logo_Centered.png" width="295px" />
                                </Box>

                                <Box width="100%" align="center" border pad="medium">
                                    <Box margin={{ "bottom": "medium" }}>
                                        <Text size="large">
                                            Sign into your account
                                        </Text>
                                    </Box>

                                    <Box direction="column" width="100%" >
                                        <Box direction="column">
                                            <FormField error={this.mail_err}>
                                                <Keyboard onEnter={evt => this.ClickLoginWithValidation("HPE_ONLY")}>
                                                    <TextInput placeholder="Enter Email Address" plain fill onChange={evt => this.mail = evt.target.value} />
                                                </Keyboard>
                                            </FormField>
                                            <Box height="46px">
                                                <StyledButton typeSave label="Sign in" fill onClick={evt => this.ClickLoginWithValidation("HPE_ONLY")} />
                                            </Box>
                                            <Box alignSelf="start" width="110px">
                                                <Button label="Support" style={{ color: "#01a982" }} plain fill={true}
                                                    href="mailto://appservices.support@hpe.com?subject=I%20need%20support%20for%20My%20Workplace&body=To%20help%20diagnose%20the%20issue%20please%20describe%20the%20issue%20below"/>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}

                    {(constants.RELEASE_TYPE == "MTF" || constants.RELEASE_TYPE == "PROD-MTF" || constants.RELEASE_TYPE == "PROD-MTF-STG") && false &&
                        <Box theme={hpe} background={constants.LAYOUT_COLOR}
                            gridArea="main" align="center" justify="center"
                        >
                            <Box gap="medium">
                                <Box pad={{ "horizontal": "medium" }}>
                                    <Image src="./Images/Login_Logo_Centered.png" width="295px" />
                                </Box>

                                <Box width="100%" align="center" border pad="medium">
                                    <Box margin={{ "bottom": "medium" }}>
                                        <Text size="large">
                                            Sign into your account
                                        </Text>
                                    </Box>

                                    <Box direction="column" width="100%" >
                                        <Box height="46px" margin={{ "bottom": "small" }}>
                                            <StyledButton typeAdd label="Sign in with Microsoft" fill onClick={evt => this.ClickLoginNoValidation("MULTI_TENANT")} />
                                        </Box>

                                        <Box width="100%" align="center" margin={{ "vertical": "large" }} border="top">
                                            <Text size="medium">
                                                or
                                            </Text>
                                        </Box>

                                        <Box direction="column">
                                            <FormField error={this.mail_err}>
                                                <Keyboard onEnter={evt => this.ClickLoginWithValidation("HPE_ONLY")}>
                                                    <TextInput placeholder="Enter Email Address" plain fill onChange={evt => this.mail = evt.target.value} />
                                                </Keyboard>
                                            </FormField>
                                            <Box height="46px">
                                                <StyledButton typeSave label="Sign in" fill onClick={evt => this.ClickLoginWithValidation("HPE_ONLY")} />
                                            </Box>
                                            <Box alignSelf="start" width="110px">
                                                <Button label="Support" style={{ color: "#01a982" }} plain fill={true}
                                                    href="mailto://appservices.support@hpe.com?subject=I%20need%20support%20for%20HPE%20Intelligent%20Spaces&body=To%20help%20diagnose%20the%20issue%20please%20describe%20the%20issue%20below" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}

                    {(constants.RELEASE_TYPE == "MTF" || constants.RELEASE_TYPE == "PROD-MTF" || constants.RELEASE_TYPE == "PROD-MTF-STG") &&
                        <Box theme={hpe} background={constants.LAYOUT_COLOR}
                            gridArea="main" align="center" justify="center" style={{marginBottom: "116px"}}
                        >
                            <Box gap="medium">
                                <Box pad={{ "horizontal": "medium" }}>
                                    <Image src="./Images/Login_Logo_Centered.png" width="295px" />
                                </Box>

                                <Box width="100%" align="center" border pad="medium">
                                <Box margin={{ "bottom": "medium" }}>
                                    <Text size="large" weight="bolder" color="#444">
                                            Sign In
                                        </Text>
                                    </Box>

                                    <Box direction="column" width="100%" >
                                        <Box height="46px">
                                            <StyledButton typeAdd label="Sign In with Microsoft" fill onClick={evt => this.ClickLoginNoValidation("MULTI_TENANT")} />
                                        </Box>

                                        <Box width="100%" align="center" margin={{ "vertical": "medium" }} >
                                            <div class="or"><span>or</span></div>
                                        </Box>
                                        <Box direction="column">
                                            <Box height="46px" margin={{ "bottom": "medium" }}>
                                                <StyledButton typeAdd label="Sign In with HPE Auth" fill onClick={this.oktaLogin} />
                                            </Box>
                                            <Box alignSelf="center">
                                                <Button label="Support" style={{ color: "#01a982" }} plain fill={true}
                                                    href="mailto://appservices.support@hpe.com?subject=I%20need%20support%20for%20HPE%20Intelligent%20Spaces&body=To%20help%20diagnose%20the%20issue%20please%20describe%20the%20issue%20below" />
                                            </Box>
                                        {false&&<Box height="46px" margin={{ "bottom": "medium" }}>
                                                <StyledButton typeAdd label="Logout" fill onClick={this.oktaLogout} />
                                            </Box>}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}
                </Grid>
            </Grommet>
        );
    }

}


//export default Landing;
export default withRouter(withOktaAuth(Landing));