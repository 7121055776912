import * as ActionType from './ActionType';
import AssociationApi from '../api/AssociationApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';

export const getAssociationsResponse = associationsdata => ({
    type: ActionType.GET_ASSOCIATIONS_RESPONSE,
    associationsdata
});

const queuedGetAllAssociations = queueApiCall(AssociationApi.getAllAssociations);

export function getAssociationsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllAssociations()
            .then(associationsdata => {
                dispatch(getAssociationsResponse(associationsdata));
                return associationsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewAssociationResponse = () => ({
    type: ActionType.ADD_NEW_ASSOCIATION_RESPONSE
});

export const updateExistingAssociationResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ASSOCIATION_RESPONSE
});

export function saveAssociationAction(associationdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return AssociationApi.saveAssociation(associationdraft)
            .then((response) => {
                //if (associationdraft.id) {
                //    dispatch(updateExistingAssociationResponse());
                //} else {
                //    dispatch(addNewAssociationResponse());
                //}
                dispatch(addNewAssociationResponse());
                return response;
            }).then((response) => {
                //if (associationdraft.id == "0") {
                //    dispatch(getAssociationAction(associationdraft.id));
                //}
                dispatch(getAssociationsAction());
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getAssociationResponse = associationdata => ({
    type: ActionType.GET_ASSOCIATION_RESPONSE,
    associationdata
});

export function getAssociationAction(associationId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AssociationApi.getAssociation(associationId)
            .then(associationdata => {
                dispatch(getAssociationResponse(associationdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteAssociationResponse = () => ({
    type: ActionType.DELETE_ASSOCIATION_RESPONSE
});

const queuedDeleteAssociation = queueApiCall(AssociationApi.deleteAssociation);

export function deleteAssociationAction(associationId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteAssociation(associationId)
            .then((response) => {
                dispatch(deleteAssociationResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getAssociationsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}