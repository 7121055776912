import * as ActionType from './ActionType';
import LocationApi from '../api/LocationApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getLocationsResponse = locationsdata => ({
    type: ActionType.GET_LOCATIONS_RESPONSE,
    locationsdata
});

export function getLocationsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return LocationApi.getAllLocations()
            .then(locationsdata => {
                dispatch(getLocationsResponse(locationsdata));
                return location;
            }).catch(error => {
                throw error;
            });
    };
}