import { useState, useEffect, useContext } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField } from 'grommet';
import AppInsightsApi from '../api/AppInsightsApi';
import NotificationApi from '../api/NotificationApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
import AnalyticsFilter from './AnalyticsFilter';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import { Context } from './Context';
import { useHistory } from "react-router";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import Card from './common/Card';



const AnalyticsCards = () => {
    //const [time, setTime] = useState("Last 30 Days");
    //const [site, setSite] = useState({ name: "All", code: "" });
    //const [version, setVersion] = useState("All");
    //const [targetOS, setTargetOS] = useState("All");

    const [usersCount, setUsersCount] = useState();
    const [countriesCount, setCountriesCount] = useState();
    const [ActiveUsersCount, setActiveUsersCount] = useState();

    const [sessionsCount, setSessionsCount] = useState();
    const [sessionsAVG, setSessionsAVG] = useState();
    const [sessionsLengthAVG, setSessionsLengthAVG] = useState();

    const [eventsCount, setEventsCount] = useState();
    const [eventsAVG, setEventsAVG] = useState();
    const [errorsCount, setErrorsCount] = useState();
    const [handledErrorsCount, setHandledErrorsCount] = useState();
    const [unhandledErrorsCount, setUnhandledErrorsCount] = useState();

    const [notificationsdata, setNotificationsdata] = useState([]);

    const { time, site, targetOS, version, count, versions } = useContext(AnalyticsContext);
    console.log("AnalyticsContext", site, targetOS, version, count);
    const { feature } = useContext(Context);

    const isNumber=(value)=> {
        return typeof value === 'number' && isFinite(value);
    }

    function getTimespan() {

        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }


    useEffect(() => {
        //CARDS
        let isSuperUser = RB.isSuperUser();
        AppInsightsApi.getUsersCountNew(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setUsersCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getCountryCount(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setCountriesCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getSessionsCount(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setSessionsCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getAvarageSessionByDay2(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setSessionsAVG(isNumber(data.tables[0].rows[0][0]) ? Number.parseFloat(data.tables[0].rows[0][0]).toFixed(2) : "N/A");
            });
        AppInsightsApi.getAvarageSessionLength(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0])
                    setSessionsLengthAVG(data.tables[0].rows[0][0] ? data.tables[0].rows[0][0].substring(0, 8) : "N/A");
            });
        AppInsightsApi.getEventsCount(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0])
                    setEventsCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getAvarageEventsPerUser(getTimespan(), version, targetOS, site, sessionStorage.getItem("organisationCode"), isSuperUser)
            .then(data => {
                if (data.tables[0].rows[0])
                    setEventsAVG(isNumber(data.tables[0].rows[0][0]) ? Number.parseFloat(data.tables[0].rows[0][0]).toFixed(2) : "N/A");
            });
        AppInsightsApi.getErrorsCount(getTimespan(), version, targetOS)
            .then(data => {
                if (data.tables[0].rows[0])
                    setErrorsCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getErrorsBySpecificName(getTimespan(), version, targetOS, "HandledErrorLog")
            .then(data => {
                if (data.tables[0].rows[0])
                    setHandledErrorsCount(data.tables[0].rows[0][0]);
            });
        AppInsightsApi.getErrorsBySpecificName(getTimespan(), version, targetOS, "UnhandledErrorLog")
            .then(data => {
                if (data.tables[0].rows[0])
                    setUnhandledErrorsCount(data.tables[0].rows[0][0]);
            });
        NotificationApi.getNotifications()
            .then(data => setNotificationsdata(data.filter(n => n.createdAt && new Date(n.createdAt) < new Date() )))
            .catch(err => console.log("err"));
    }, [count])

    const bySiteVersionTarget = (item) => {
        let isDateOK = true;
        //Check Date
        if (item.createdAt) {
            let now = Date.now();
            let d = getTimespan().slice(0, -1);
            let from = new Date(now - 1000 * 60 * 60 * 24 * d);
            let notiDate = new Date(item.createdAt);
            isDateOK = from < notiDate ? true : false;
        }
        else {
            isDateOK = false;
        }


        if (item.tags) {
            let isSiteOK = true;
            let isVersionOK = true;
            let isTargetOK = true;

            let tags = item.tags;

            //Check Site
            if (tags.includes("loc:")) {
                if (site == "" || site == "All")
                    isSiteOK = true;
                else {
                    let sites = tags.split(" ").filter(s => s.includes("loc:"));
                    let selectedSite = sites.find(s => s.substring(4) == site);
                    isSiteOK = selectedSite ? true : false;
                }

            }
            //Check Version
            if (tags.includes("ver:")) {
                if (versions.length == 0)
                    isVersionOK = true;
                else {
                    let selectedVersions = tags.split(" ").filter(s => s.includes("ver:"));
                    let atLeastOne = false;
                    selectedVersions.forEach(sv => {
                        if (versions.some(v => v == sv)) {
                            atLeastOne = true;
                            //break;
                        }
                    });
                    isVersionOK = atLeastOne;
                }
            }
            //Check Target
            if (tags.includes("plt:")) {
                if (
                    (tags.includes("plt:android") && (targetOS == "All" || targetOS == "Android")) ||
                    (tags.includes("plt:ios") && (targetOS == "All" || targetOS == "iOS"))
                ) {
                    isTargetOK = true;

                } else {
                    isTargetOK = false;
                }
            }
            return isDateOK && isSiteOK && isVersionOK && isTargetOK;
        }
        else {
            return isDateOK;
        }
    }

    return (
        <Grid
            //margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
            columns='200px'
            gap="xsmall"
        >

            <Card header="Active Users" value={usersCount} isViewable={true} route="activeusers"/>
            <Card header="Countries" value={countriesCount} isViewable={true} route="countries"/>

            <Card header="Sessions" value={sessionsCount} />
            <Card header="Average session per day" value={sessionsAVG} />
            <Card header="Average Session Length" value={sessionsLengthAVG} />

            <Card header="Events" value={eventsCount} isViewable={true} route="events"/>
            <Card header="Average number of Events" value={eventsAVG} />
            <Card header="Errors" value={errorsCount} />
            <Card header="Handled Errors" value={handledErrorsCount} />
            <Card header="Unhandled Errors" value={unhandledErrorsCount} />

            {feature.find(f => f.name == "notification").isEnabled && <Card header="Device Audience" value={notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.device, 0)} />}
            {feature.find(f => f.name == "notification").isEnabled && <Card header="Device Sent" value={notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.success, 0)} />}
            {feature.find(f => f.name == "notification").isEnabled && <Card header="User Audience" value={notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.audience, 0)} />}
            {feature.find(f => f.name == "notification").isEnabled && <Card header="User Read" value={notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.read, 0)} />}


        </Grid>
    );
}

export default AnalyticsCards;