import constants from '../constants';

//const baseUrl = "https://api.applicationinsights.io/v1/apps/70d5549c-61c9-4481-a017-2a76bf9fe2a1/query?query="; //itg MTF HPE-MTF
//const baseUrl = "https://api.applicationinsights.io/v1/apps/cc1a64bd-eac0-4303-8aa2-ed3f13262aad/query?query="; //prod
const baseUrl = constants.release().Insightsurl;

const options =
{
    method: "get",
    headers:
    {
        //"x-api-key": "j4uepuugt6mrb7wg3ugvg6gzg8ebpd6xlrh2hnry"  //itg MTF HPE-MTF
        //"x-api-key": "qjfkkoqhe21pknp4wv4do2buuka8qbeyfbcznmq0" //prod
        "x-api-key": constants.release().Insightskey
    }
}

function getBaseURL() {
    let baseUrl1 = constants.InsightsId ? "https://api.applicationinsights.io/v1/apps/" + constants.InsightsId + "/query?query=" : constants.release().Insightsurl;
    return baseUrl1;
}

function getOptions() {
     return {
        method: "get",
        headers:
        {
            "x-api-key": constants.Insightskey ? constants.Insightskey : constants.release().Insightskey
        }
    };
}

export default class AppInsightsApi {
    static baseUrl = constants.InsightsId ? "https://api.applicationinsights.io/v1/apps/" + constants.InsightsId + "/query?query=" : constants.release().Insightsurl;
    static options = {
        method: "get",
        headers:
        {
            "x-api-key": constants.Insightskey ? constants.Insightskey : constants.release().Insightskey
        }
    };

    static trackKingExtension(orgcode) {
        if (orgcode == "TrackKing") {
            return "| where customDimensions.AppNamespace=='com.mytrackking.app'";
        }
        return "";
    }
    static async getLocations(timespan, orgcode) {
        const locationsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | extend value = extractjson("$.Project", tostring(customDimensions.Properties))\
        | distinct value`;
        return fetch(`${getBaseURL()}${encodeURIComponent(locationsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getVersions(timespan, orgcode) {
        const versionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | distinct application_Version`;
        return fetch(`${getBaseURL()}${encodeURIComponent(versionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getWorldUsers(timespan, version, os, orgcode) {
        version = version !== "All" ? version : ""; //or application_Version == "2.0.6"
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const worldUsersTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(user_Id) by client_CountryOrRegion`;
        return fetch(`${getBaseURL()}${encodeURIComponent(worldUsersTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getUsersCount(timespan, version, os, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const usersCountTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(user_Id) by bin(timestamp, 1d)\
        | order by timestamp asc`;
        return fetch(`${getBaseURL()}${encodeURIComponent(usersCountTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageSessionsCount(timespan, version, os, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = round(todouble(dcount(session_Id)) / todouble(dcount(user_Id)), 3) by bin(timestamp, 1d)\
        | order by timestamp asc`;
        return fetch(`${getBaseURL()}${encodeURIComponent(avgSessionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsTable(timespan, version, os, location, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = count() by name`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsTableExtended(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize value = count() by event, name, loc
        | sort by value`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getIndividualEvent(timespan, version, os, eventName, eventType, eventLoc, location, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        const individualEventTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | extend event = iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))
        | where event == "${eventName}"\
        | where name == "${eventType}"\
        | where loc == "${eventLoc}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        ${version}\
        ${os}\
        | summarize value = count() by  location = extractjson("$.Project", tostring(customDimensions.Properties)),  bin(timestamp, 1d)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(individualEventTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAllData(orgcode) {
        const query = `customEvents\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""`;
        return fetch(`${getBaseURL()}${encodeURIComponent(query)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getDevices(timespan,orgcode) {
        const query = `customEvents\
        ${this.trackKingExtension(orgcode)}
        | where timestamp > ago(${timespan})\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | project client_Model, client_Type, timestamp
        | distinct client_Type, client_Model
        | order by client_Model asc`;
        return fetch(`${getBaseURL()}${encodeURIComponent(query)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getSessionsCount(timespan, version, os, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = count_distinct(session_Id)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(avgSessionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getSessionsByDay(timespan, version, os, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(session_Id) by bin(timestamp, 1d)
        | order by timestamp asc`;
        return fetch(`${getBaseURL()}${encodeURIComponent(avgSessionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageSessionByDay(timespan, version, os, orgcode) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(session_Id) by bin(timestamp, 1d)
        | summarize avg = avg(value)`;
        //not in use
        return fetch(`${getBaseURL()}${encodeURIComponent(avgSessionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageSessionByDay2(timespan, version, os, orgcode) {
        let number = parseInt(timespan) + 1;
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize avg = todouble(count_distinct(session_Id)) / todouble(${number})`;
        return fetch(`${getBaseURL()}${encodeURIComponent(avgSessionsTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageEventsByDay(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize c = count() by event, name, loc, bin(timestamp, 1d)
        | summarize v = avg(c) by event, name, loc
        | sort by v, event`;
        //not in use
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsCount(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize c = count() by event, name, loc
        | summarize v = count()`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsClickedCount(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize c = count() by event, name, loc, bin(timestamp, 1d)
        | summarize v = sum(c)
        | sort by v`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsByUser(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize c = count() by event, name, loc, user_Id
        | summarize events = count(c) by user_Id`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageEventsPerUser(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | extend event=iff(isempty(extractjson("$.Event", tostring(customDimensions.Properties))),extractjson("$.ItemName", tostring(customDimensions.Properties)), extractjson("$.Event", tostring(customDimensions.Properties))), loc = extractjson("$.AppLocation", tostring(customDimensions.Properties))\
        | summarize c = count() by event, name, loc, user_Id
        | summarize events = count(c) by user_Id
        | summarize avg = avg(events)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getErrorsCount(timespan, version, os) {
        //customDimensions.Properties DOES NOT CONTAIN Project or Organization
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${version}\
        ${os}\
        | where name contains "ErrorLog"
        | summarize  c = count()`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getErrorsByName(timespan, version, os) {
        //customDimensions.Properties DOES NOT CONTAIN Project or Organization
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${version}\
        ${os}\
        | where name contains "ErrorLog"
        | summarize  c = count() by name`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getErrorsBySpecificName(timespan, version, os, err) {
        //customDimensions.Properties DOES NOT CONTAIN Project or Organization
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${version}\
        ${os}\
        | where name == "${err}"\
        | summarize  c = count()`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }


    static async getAvarageSessionLength(timespan, version, os, location, orgcode, isSuperUser) {
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | summarize duration = max(timestamp) -  min(timestamp) by session_Id , user_Id
        | extend d =  duration / time(1s)
        | where d >= 0.5
        | where session_Id != "00000000-0000-0000-0000-000000000000"
        | summarize avg(duration)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageSessionLength2(timespan, version, os, location, orgcode, isSuperUser) {
        let number = parseInt(timespan) + 1;
        version = version !== "All" ? version : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        let filterOutPerf = isSuperUser ? "" : `| where name !endswith "Perf"`;
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'\
        ${filterOutPerf}\
        ${version}\
        ${os}\
        | summarize duration = max(timestamp) -  min(timestamp) by session_Id , user_Id
        | extend d =  duration / time(1s)
        | where d >= 0.5
        | where session_Id != "00000000-0000-0000-0000-000000000000"
        | summarize avg = sum(duration)/ ${number}`;
        return fetch(`${getBaseURL()}${encodeURIComponent(eventsTableTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }


    static async getUsersCountNew(timespan, version, os, orgcode) {
        version = version !== "All" ? version : ""; //or application_Version == "2.0.6"
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const worldUsersTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(user_Id)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(worldUsersTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getCountryCount(timespan, version, os, orgcode) {
        version = version !== "All" ? version : ""; //or application_Version == "2.0.6"
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const worldUsersTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(client_CountryOrRegion)`;
        return fetch(`${getBaseURL()}${encodeURIComponent(worldUsersTemplate)}`, getOptions())
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

}
