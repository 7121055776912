import styled, { css } from 'styled-components';

export const galwayIconTheme = {

    extend: css`
    color: #333333;
    fill: #333333;
    stroke: #333333;
    `,
};

