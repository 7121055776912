import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text, TextInput, Image, Meter, Stack, Paragraph } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import _ from 'lodash';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class AssetUtilizationListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meterHover: false,
        };
    }

    render() {
        return (
                <StyledBox>
                    {this.renderAssetUtilizationTable(this.props)}
                </StyledBox>
        );
    }

    
    renderAssetUtilizationTable(props) {
        console.log("asd", props);
        const COLUMNS = [
            {
                property: '',
                header: 'Asset',
                size: 'large',
                render: assetsoverviewdata => <Box height="25px" fill="horizontal" justify="center">
                       <Text size="small" truncate>
                            {assetsoverviewdata.name.length > 55 ?assetsoverviewdata.name.substring(0,25).concat("...") : assetsoverviewdata.name}
                        </Text> 
                    </Box>
            },
            {
                property: '',
                align: "start",
                header: 'Utilization',
                size: "small",
                render: assetsoverviewdata => 
                    <Box fill="horizontal" border height="25px" align="end">
                        <Meter width="100%" background={props.backgroundcolor} values={[{
                            value: assetsoverviewdata.available,
                            onHover: over => this.setState({meterHover: over ? true : false}),
                            color: assetsoverviewdata.available < 20 ? props.colorred : assetsoverviewdata.available <= 40 ? props.coloryellow : props.colorgreen
                            }]} />
                       {/*<Stack anchor="center" align="center" justify="center" fill="true">
                            <Meter width="300px" background={props.backgroundcolor} values={[{
                            value: assetsoverviewdata.available,
                            onHover: over => this.setState({meterHover: over ? true : false}),
                            color: assetsoverviewdata.available < 20 ? props.colorred : assetsoverviewdata.available <= 40 ? props.coloryellow : props.colorgreen
                            }]} />
                            this.state.meterHover && (<Box> <Text>{assetsoverviewdata.available}%</Text> </Box>)
                        </Stack>*/}
                    </Box>
            } 
        ];

        let DATA = [];
        if (props.assetsoverviewdata.assets[0] && props.assetsoverviewdata.assets[0].count != 0) {        
            DATA = _.cloneDeep(props.assetsoverviewdata.assets);
        }

        if (props.descendant) {
            DATA = DATA.sort((p1, p2) => { if (p1.available < p2.available) return 1; return -1; });
        }

        if (!props.descendant) {
            DATA = DATA.sort((p1, p2) => { if (p1.available > p2.available) return 1; return -1; });
        }

        console.log('DATA:', DATA);

        return (
                <DataTable
                bg = {constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({...c,
                }))}
                data={DATA.slice(0,6)}
            />
        );
    }
}
export default AssetUtilizationListTable;
