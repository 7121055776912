import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const usersPreferencesReducer = (state = initialState.usersPreferencesReducer, action) => {
    switch (action.type) {

        case ActionType.GET_USERS_PREFERENCES_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                userspreferencesdata: _.assign(action.userspreferencesdata)
            };
        }

        default: { return state; }
    }
};


export default usersPreferencesReducer;