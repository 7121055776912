import { useState, useEffect, useContext } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField } from 'grommet';
import { Edit, CircleInformation, Next, FormNext, FormPrevious, Download } from 'grommet-icons';
import AppInsightsApi from '../api/AppInsightsApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import ReactTooltip from "react-tooltip";
import { Bar, Pie, Line } from 'react-chartjs-2';
import LoadingIndicator from './common/LoadingIndicator';


const AnalyticsUsers = () => {
    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const [userChart, setUserChart] = useState([]);
    const [userChartLoading, setUserChartLoading] = useState(true);
    const [usersCount, setUsersCount] = useState();
    const [ActiveUsersCount, setActiveUsersCount] = useState();

    const [sessionsCount, setSessionsCount] = useState();
    const [sessionsAVG, setSessionsAVG] = useState();
    const [sessionsLengthAVG, setSessionsLengthAVG] = useState();

    const [eventsCount, setEventsCount] = useState();
    const [eventsAVG, setEventsAVG] = useState();
    const [errorsCount, setErrorsCount] = useState();
    const [handledErrorsCount, setHandledErrorsCount] = useState();
    const [unhandledErrorsCount, setUnhandledErrorsCount] = useState();

    const { time, site, targetOS, version, count, axesColor } = useContext(AnalyticsContext);
    console.log("AnalyticsContext", site, targetOS, version, count);


    const getTimespan=()=> {

        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    useEffect(() => {
        AppInsightsApi.getUsersCount(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let dateIdx, valueIdx;
                    if (data.tables[0].columns[0].name === "timestamp") {
                        dateIdx = 0;
                        valueIdx = 1;
                    }
                    else {
                        dateIdx = 1;
                        valueIdx = 0;
                    }
                    setUserChart(data.tables[0].rows.map(row => {
                        return {
                            date: new Date(row[dateIdx]),
                            value: row[valueIdx]
                        };
                    }));
                    setUserChartLoading(false);
                    //this.setState({
                    //    userChart: data.tables[0].rows.map(row => {
                    //        return {
                    //            date: new Date(row[dateIdx]),
                    //            value: row[valueIdx]
                    //        };
                    //    }),
                    //    userChartLoading: false,
                    //});
                }
            })
            .catch(error => {
                //var errorArray = this.state.apiErrorList.concat(error);
                //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getUsersCount Error', error);
            });
        AppInsightsApi.getUsersCountNew(getTimespan(), version, targetOS, sessionStorage.getItem("organisationCode"))
            .then(data => {
                if (data.tables[0].rows[0])
                    setUsersCount(constants.convertToK(data.tables[0].rows[0][0]));
            });
    }, [count])

    const axisMaxValue=(maxValue)=> {
        if (maxValue <= 5) { return 5 }
        else if (maxValue <= 10) { return 10 }
        else if (maxValue <= 200) { return Math.ceil(maxValue / 10) * 10 }
        else if (maxValue <= 2000) { return Math.ceil(maxValue / 100) * 100 }
        else if (maxValue <= 20000) { return Math.ceil(maxValue / 1000) * 1000 }
        else { return Math.ceil(maxValue / 10000) * 10000 }
    }

    const dataMaxValue=(data)=> {
        if (data) {
            if (data.length > 1) {
                const max = data.reduce((prev, current) => (prev.value > current.value) ? prev : current)
                return max.value
            }
            else {
                return 0
            }
        }
    }

    return (
        <StyledBox style1
            height="350px"
            //margin="small"
            justify="between"
        //margin={{ "top": "small", "bottom": "medium", "horizontal": "small" }}
        >
            <Box border={{ side: "bottom" }}
                height="100%"
                width="100%"
                justify="between"
                pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                <Box height="15%" justify="between" direction="column-responsive">
                    <Box direction="column-responsive">
                        <Heading level='3' margin={{ "top": "xsmall", "bottom": "xlarge", "left": "none", "right": "none" }}>
                            <Box direction="column-responsive" gap="xsmall">
                                Active Users
                                <Box justify="center" height="25px" alignSelf="center">
                                    <a data-for="Active users" data-tip="The number of active users who have used the app over the selected time period">
                                        <CircleInformation size="medium" color="#444444" />
                                    </a>
                                    <ReactTooltip id="Active users" place="left" type="dark" effect="solid" arrowColor="transparent">
                                        <span>The number of active users who have used the app over the selected time period</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>
                        </Heading>
                    </Box>
                    <Text weight="bolder" size="5xl">
                        {usersCount}
                    </Text>
                </Box>
                <Box  height="85%"
                    margin={{ "top": "none", "bottom": "none", "left": "medium", "right": "medium" }}>
                    {userChartLoading ? <LoadingIndicator plain /*error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}*/ /> :
                        <Line
                            data={{
                                labels: userChart.map(item => `${monthNames[item.date.getMonth()]} ${item.date.getDate()}`),
                                datasets: [
                                    {
                                        label: "Active Users",
                                        data: userChart.map(item => item.value),
                                        backgroundColor: "transparent",
                                        borderColor: "#C140FF",
                                        lineTension: 0,

                                        pointHoverBackgroundColor: "#C140FF",
                                        pointHoverBorderColor: "#C140FF",
                                        pointHoverBorderWidth: 3,
                                        pointHoverRadius: 5,
                                        pointRadius: 5,
                                        pointBackgroundColor: "transparent",
                                        pointBorderWidth: 0,
                                        pointBorderColor: "transparent"
                                    }
                                ]
                            }}
                            options={{
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        gridLines: {
                                            drawOnChartArea: false,
                                            color: axesColor
                                        },
                                        ticks: {
                                            min: 0,
                                            max: axisMaxValue(dataMaxValue(userChart)),
                                            stepSize: axisMaxValue(dataMaxValue(userChart)) / 5
                                        }
                                    }],
                                    xAxes: [{
                                        barPercentage: 0.4,
                                        gridLines: {
                                            drawOnChartArea: false,
                                            color: axesColor
                                        },
                                    }]
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />}
                </Box>
            </Box>
        </StyledBox>
    );
}

export default AnalyticsUsers;