import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedCollectionReducer = (state = initialState.selectedCollectionReducer, action) => {
    switch(action.type) {

        case ActionType.GET_COLLECTION_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                collectiondata: _.assign(action.collectiondata)
            };
        }

        default: { return state; }
    }
};


export default selectedCollectionReducer;