import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const spotsReducer = (state = initialState.spotsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_SPOTS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                spotsdata: _.assign(action.spotsdata)
            };
        }


        default: { return state; }
    }
};



export default spotsReducer;