import { FormField, Box, CheckBox } from 'grommet';
import React from 'react';
import TextInputWithState from './TextInputWithState';

export const Deeplink = (props) => {
    const [checked, setChecked] = React.useState(false);
    const dlu = props.uri && props.path ? props.uri + props.path.substring(1) : "";
    return (
        <FormField label="Deep Link URL">
            <Box direction="row" pad={{ "right": "small" }}>
                <TextInputWithState value={checked ? dlu : "********"} type={checked ? "text" : "password"} plain disabled />
                <CheckBox label="View" checked={checked} onChange={() => setChecked(!checked)} />
            </Box>
        </FormField>
    );
};