import React from 'react';
import { Grid, Box, Heading, Select, Text, Button } from 'grommet';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { BarChart, Line, HorizontalBar } from 'react-chartjs-2';
import { Spinning } from 'grommet-controls';
import AppInsightsApi from '../api/AppInsightsApi';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;
var axesColor = "F8F8F8";

export default class SingleChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBorder: false
        }
    }
    render() {
        var { singleFeatureDataLoading } = this.props;
        let data, allLocations, locations;
        if (this.props.data && !singleFeatureDataLoading) {
            allLocations = this.props.location === "All";
            if (allLocations) {
                locations = new Array();
            }
            data = new Array();
            let days = parseInt(this.props.timeSpan);
            for (let i = days; i >= 0; --i) {
                data.push({ date: new Date().addDays(-i), value: 0 })
            }
            for (let i = 0; i < this.props.data.tables[0].rows.length; ++i) {
                let row = (this.props.data.tables[0].rows[i]);
                let item = data.find(e => e.date.getMonth() === new Date(row[1]).getMonth() && e.date.getDate() === new Date(row[1]).getDate())
                if (item)
                    item.value += row[2];
                if (allLocations) {
                    let loc = locations.find(e => e.name === row[0]);
                    if (loc)
                        loc.value += row[2];
                    else 
                        locations.push({ name: row[0], value: row[2] })
                }
            }
        }

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gap="small" /*height="1000px"*/ background='transparent'
                    //margin={{ "top": "none", "bottom": "medium", "left": "small", "right": "small" }}
                    pad={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}>
                    <Box gridArea='main' background='transparent' pad="none" fill="vertical" border={this.state.showBorder} >
                        <form>
                            <StyledBox style1 border={this.state.showBorder} height="500px"
                                margin={{ "top": "none", "bottom": "small", "left": "none", "right": "none" }}>
                                <Box pad={{ "top": "none", "bottom": "none", "left": "small", "right": "none" }}
                                    direction="row"
                                    align="center"
                                    justify="between"
                                    background='transparent'
                                    height="50px"
                                    border={{ "side": "bottom" }}>
                                    <Heading level='4' >{this.props.item.name}  </Heading>
                                </Box>
                                {singleFeatureDataLoading || !data ? <LoadingIndicator plain /> :
                                    <React.Fragment>
                                        <Box border={this.state.showBorder} height="50px" justify="center"
                                            pad={{ "top": "none", "bottom": "none", "left": "small", "right": "none" }}>
                                            <Text size="medium" weight="bold" >Total Count: {data.reduce((total, item) => total + item.value, 0)} </Text>
                                        </Box>
                                        <Box background="transparent" border={{ "side": "bottom" }} height="400px" pad="medium"
                                        /*margin={{ "top": "none", "bottom": "small", "left": "medium", "right": "small" }}*/>
                                            <Line
                                                data={{
                                                    labels: data.map(item => `${item.date.getMonth() + 1}.${item.date.getDate()}`),
                                                    datasets: [
                                                        {
                                                            label: `${this.props.item.name}`,
                                                            data: data.map(item => item.value),
                                                            borderWidth: 0,
                                                            fill: false,
                                                            lineTension: 0,
                                                            backgroundColor: "#7630EA",
                                                            borderColor: "#7630EA",
                                                        }
                                                    ]
                                                }}
                                                options={{
                                                    legend: {
                                                        display: false,
                                                        labels: true
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            gridLines: {
                                                                drawOnChartArea: false,
                                                                color: axesColor
                                                            },
                                                            ticks: {
                                                                beginAtZero: true
                                                            }
                                                        }],
                                                        xAxes: [{
                                                            gridLines: {
                                                                drawOnChartArea: false,
                                                                color: axesColor
                                                            },
                                                        }],
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: false
                                                }}
                                            />
                                        </Box>
                                        {!(allLocations && locations.length > 0) &&
                                            <Box border={this.state.showBorder} alignContent="center" justify="between" background="transparent" align="end"
                                                margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                                <Button margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                                    style={{ color: "#01a982", fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                                    size="large"
                                                    plain="true"
                                                    label="Back to Table"
                                                    onClick={this.props.back}
                                                />
                                            </Box>
                                        }
                                    </React.Fragment>}
                            </StyledBox>
                            {(allLocations && locations.length > 0) &&
                                <StyledBox style1 border={this.state.showBorder} height="500px" margin={{ "bottom": "medium" }}>
                                    <Box height="100%" pad="medium" border={{ "side": "bottom" }}>
                                        {singleFeatureDataLoading ? <LoadingIndicator plain /> :
                                            <HorizontalBar
                                                data={{
                                                    labels: locations.map(item => item.name),
                                                    datasets: [
                                                        {
                                                            label: `${this.props.item.name}`,
                                                            data: locations.map(item => item.value),
                                                            borderWidth: 0,
                                                            backgroundColor: "#7630EA",
                                                            hoverBackgroundColor: "#7630EA"
                                                        }
                                                    ]
                                                }}
                                                options={{
                                                    legend: {
                                                        display: false,
                                                        labels: true
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            barPercentage: 0.4,
                                                            gridLines: {
                                                                drawOnChartArea: false,
                                                                color: axesColor
                                                            },
                                                            ticks: {
                                                                beginAtZero: true
                                                            }
                                                        }],
                                                        xAxes: [{
                                                            gridLines: {
                                                                drawOnChartArea: false,
                                                                color: axesColor
                                                            },
                                                            ticks: {
                                                                beginAtZero: true
                                                            }
                                                        }]
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                }}
                                            />}
                                    </Box>
                                    <Box border={this.state.showBorder} alignContent="center" justify="between" background="transparent" align="end"
                                        margin={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}>
                                        <Button margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}
                                            style={{ color: "#01a982", fontFamily: "Arial", fontSize: "18px", fontWeight: "750", letterSpacing: "0.1px" }}
                                            size="large"
                                            plain="true"
                                            label="Back to Table"
                                            onClick={this.props.back}
                                        />
                                    </Box>
                                </StyledBox>
                            }
                        </form>
                    </Box>
                </Box>
            </Grid>

        );
    }
}

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
