import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Image, Tabs, Tab } from 'grommet';
import { Filter, Edit, Close, Search, Trash } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import * as SpaceAction from '../action/SpaceAction';
import LoadingIndicator from './common/LoadingIndicator';


const PaginatedDataTableS = withPagination(DataTable);


class SpaceMappingListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            searchSpaces: "",
            spacesdataLoading: true
        }
    }
    componentDidMount() {
        this.props.action.getSpacesAction()
            .then(response => this.setState({ spacesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Spaces List Error', error);
            });
    }

    render() {
        if (this.state.spacesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Space Mapping </Heading>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    <form>
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <Box>
                            Ensure correct mapping of all spaces from external sources. <br />
                            For any space where the status is not green, review the external source system and configure as required.
                        </Box>
                        <StyledBox style={{ "min-width": "865px" }}>
                            {this.renderSpacesTable(this.props.spacesdata)}
                        </StyledBox>
                        </IconThemeContext.Extend>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderSpacesTable(spaces) {
        const COLUMNS = [
            {
                property: 'statusRAG',
                header: <Box justify="center" height="100%">Status</Box>,
                render: s =>
                    <Box alignContent="center">
                        {(s.status == 'G' && s.published &&
                            <Box data-for="green" data-tip>
                                <Image width="30" height="30" src="./images/green.svg" />
                                <ReactTooltip id="green" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>Items are valid</span>
                                </ReactTooltip>
                            </Box>
                        )}

                        {(s.status == 'G' && !s.published &&
                            <Box data-for="green_unpublished" data-tip>
                                <Image width="30" height="30" src="./images/green.svg" />
                                <ReactTooltip id="green_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>This space is located on an unpublished map. Please publish the map to ensure correct mapping.</span>
                                </ReactTooltip>
                            </Box>
                        )}

                        {(s.status == 'A' && s.published &&
                            <Box data-for="yellow" data-tip>
                                <Image width="30" height="30" src="./images/yellow.svg" />
                                <ReactTooltip id="yellow" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>Items is missing</span>
                                </ReactTooltip>
                            </Box>
                        )}

                        {(s.status == 'A' && !s.published &&
                            <Box data-for="yellow_unpublished" data-tip>
                                <Image width="30" height="30" src="./images/yellow.svg" />
                                <ReactTooltip id="yellow_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>This space is located on an unpublished map. Please publish the map to ensure correct mapping.</span>
                                </ReactTooltip>
                            </Box>
                        )}

                        {(s.status == 'R' && s.published &&
                            <Box data-for="red" data-tip>
                                <Image width="30" height="30" src="./images/red.svg" />
                                <ReactTooltip id="red" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>Items don't match</span>
                                </ReactTooltip>
                            </Box>
                        )}

                        {(s.status == 'R' && !s.published &&
                            <Box data-for="red_unpublished" data-tip>
                                <Image width="30" height="30" src="./images/red.svg" />
                                <ReactTooltip id="red_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                    <span>This space is located on an unpublished map. Please publish the map to ensure correct mapping.</span>
                                </ReactTooltip>
                            </Box>
                        )}
                    </Box>
            },
            {
                property: 'lctype',
                header: 'Type',
                render: s => <Text>{s.type}</Text>
            },
            {
                property: 'lcplacemark',
                header: <Box>
                    <a data-for="Placemark Name" data-tip="Placemark Names are configured in Meridian">
                        Placemark Name
                        </a>
                    <ReactTooltip id="Placemark Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.meridianName}</Text>
            },
            {
                property: 'lcspace',
                header: <Box>
                    <a data-for="Space Name" data-tip="Space Names are configured in Teem">
                        Space Name
                        </a>
                    <ReactTooltip id="Space Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.teemName}</Text>
            },
            {
                property: 'lcspotname',
                header: <Box>
                    <a data-for="Smart Space Name" data-tip="Smart Space Names are configured in Chargifi">
                        Spot Name
                        </a>
                    <ReactTooltip id="Smart Space Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.chargifiName}</Text>
            },
            {
                property: 'lclevel',
                header: "Level",
                render: s => <Text>{s.mergedLevelName}</Text>
            },
            {
                property: 'lcpublished',
                header: "Published",
                render: s => <CheckBox checked={s.published} disabled />
            }
        ];

        if (!(this.context.feature.find(f => f.name == "siteChargifi").isEnabled) || !constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            COLUMNS.splice(4, 1);
        }

        let SPACES = [];
        console.log(spaces);

        if (spaces[0] && spaces[0].count != 0) {
            spaces.forEach(s => {
                //if (s.type=="room") {
                SPACES.push({
                    ...s,
                    lctype: s.type !== null ? s.type.toLowerCase() : "",
                    lcplacemark: s.meridianName !== null ? s.meridianName.toLowerCase() : "",
                    lcspace: s.teemName !== null ? s.teemName.toLowerCase() : "",
                    lcspotname: s.chargifiName ? s.chargifiName !== null ? s.chargifiName.toLowerCase() : "" : "",
                    lclevel: s.mergedLevelName !== null ? s.mergedLevelName.toLowerCase() : "",
                    lcpublished: s.published,
                    statusRAG: this.convertRAG(s.status, s.published, s.valid)
                })
                //}
            });
            SPACES.sort((p1, p2) => { if (p1.lcname < p2.lcname) return -1; return 1; });
        }

        if (this.state.searchSpaces) {
            var search = this.state.searchSpaces.toLowerCase();
            SPACES = SPACES.filter(d =>
                d.type.toLowerCase().includes(search) ||
                d.meridianName.toLowerCase().includes(search) ||
                d.teemName.toLowerCase().includes(search) ||
                d.chargifiName.toLowerCase().includes(search) ||
                d.mergedLevelName.toLowerCase().includes(search) ||
                ("published".includes(search) && d.published)
            );
        }

        console.log('SPACES: ', SPACES);

        let SORT_SPACES = [
            { col: 'statusRAG', label: 'Status' },
            { col: 'lcplacemark', label: 'Placemark Name' },
            { col: 'lcspace', label: 'Space Name' },
            { col: 'lcspotname', label: 'Spot Name' },
            { col: 'lclevel', label: 'Level' },
            { col: 'lcpublished', label: 'Published' }
        ]

        if (!constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            SORT_SPACES.splice(3, 1)
        }

        return (
            <PaginatedDataTableS
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctype" || c.property === "lcname" || c.property === "lcplacemark" || c.property === "lcspace" || c.property === "lclevel" || c.property === "lcspotname"
                }))}
                data={SPACES}
                noLoadingMsg={spaces.length != 0}
                sortable
                //sortoptions={SORT_SPACES}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.searchSpaces}
                PFonChange={e => this.setState({ searchSpaces: e.target.value })}
                PFonClear={e => this.setState({ searchSpaces: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    convertRAG(char, published, valid) {
        if (char == "R" && valid) {
            return published ? 5 : 6;
        }
        if (char == "A" && valid) {
            return published ? 3 : 4;
        }
        if (char == "G" && valid) {
            return published ? 1 : 2;
        }
        return 7;
    }
}
const mapStateToProps = state => ({
    spacesdata: state.spacesReducer.spacesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SpaceAction }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceMappingListTable);
