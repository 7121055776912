import React, { Component, CreateRef } from 'react';
import { connect } from 'react-redux';
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Chart, Meter, Diagram, Stack, Node } from 'grommet';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

const DEFAULT_OPTIONS = [];
for (let i = 1; i <= 20; i += 1) DEFAULT_OPTIONS.push({option: `option ${i}`, parent: `parent ${i}`});
const OPTIONS = DEFAULT_OPTIONS.map(item => item.option);

class ToDo extends Component {
    state = {
    optlist: DEFAULT_OPTIONS,
    options: OPTIONS,
    value: "option 10",
    selvalue: ""
  };

  handleItem(item) {
    this.setState({value: item.option});
    const found = DEFAULT_OPTIONS.find(function(element) {return element.option == item.option});
    this.setState({selvalue: found.parent});
}


  render() {
    const { options, optlist, value, selvalue } = this.state;
    console.log('Render:', options, optlist);
    return (
      <Box>
        <Select
          size="medium"
          placeholder="Select"
          value={value}
          options={options}
          //onChange={({ option }) => this.setState({ value: option })}
          onChange={({ option }) => this.handleItem({option})}
          onClose={() => this.setState({ options: OPTIONS })}
          onSearch={text => {
            const exp = new RegExp(text, "i");
            this.setState({
              options: OPTIONS.filter(o => exp.test(o))
            });
          }}
        />
        <TextInput value={selvalue} disabled="disabled" />
      </Box>
    );
  }
}

export default connect()(ToDo);
