import * as ActionType from './ActionType';
import NotificationApi from '../api/NotificationApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';
import initialState from '../reducer/initialState';

export const getNotificationsResponse = notificationsdata => ({
    type: ActionType.GET_NOTIFICATIONS_RESPONSE,
    notificationsdata
});

const queuedGetAllNotifications = queueApiCall(NotificationApi.getNotifications);

export function getNotificationsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());
        return queuedGetAllNotifications()
            .then(notificationsdata => {
                dispatch(getNotificationsResponse(notificationsdata));
                return notificationsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewNotificationResponse = () => ({
    type: ActionType.ADD_NEW_NOTIFICATION_RESPONSE
});

export const updateExistingNotificationResponse = () => ({
    type: ActionType.UPDATE_EXISTING_NOTIFICATION_RESPONSE
});

export function saveNotificationAction(notificationdata) {
    return function (dispatch) {
        dispatch(ApiCallBeginAction());
        //if Id exists, it means that the user is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new user that is being added, therefore add it
        return NotificationApi.saveNotification(notificationdata)
            .then((response) => {
                if (notificationdata.id) {
                    dispatch(updateExistingNotificationResponse());
                } else {
                    dispatch(addNewNotificationResponse());
                }
                return response;
            }).then((response) => {
                //if (notificationdata.id == "0") {
                //    dispatch(getNotificationAction(notificationdata.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getNotificationResponse = notificationdata => ({
    type: ActionType.GET_NOTIFICATION_RESPONSE,
    notificationdata
});

export function getNotificationAction(notificationId) {
    return (dispatch) => {

        dispatch(getNotificationResponse(initialState.selectedNotificationReducer.notificationdata));
        dispatch(ApiCallBeginAction());

        return NotificationApi.getNotification(notificationId)
            .then(notificationdata => {
                dispatch(getNotificationResponse(notificationdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteNotificationResponse = () => ({
    type: ActionType.DELETE_NOTIFICATION_RESPONSE
});

const queuedDeleteNotification = queueApiCall(NotificationApi.deleteNotification);

export function deleteNotificationAction(notificationId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteNotification(notificationId)
            .then((response) => {
                dispatch(deleteNotificationResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getNotificationsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}