import * as ActionType from './ActionType';
import BeaconApi from '../api/BeaconApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getBeaconsResponse = beaconsdata => ({
    type: ActionType.GET_BEACONS_RESPONSE,
    beaconsdata
});

export const updateExistingBeaconResponse = () => ({
    type: ActionType.UPDATE_EXISTING_BEACON_RESPONSE
});

export const getBeaconResponse = beacondata => ({
    type: ActionType.GET_BEACON_RESPONSE,
    beacondata
});


export function getBeaconsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return BeaconApi.getAllBeacons()
            .then(beaconsdata => {
                dispatch(getBeaconsResponse(beaconsdata));
                return beaconsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export function saveBeaconAction(beacondata) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return BeaconApi.saveBeacon(beacondata)
            .then((response) => {
                if (beacondata.id) {
                    dispatch(updateExistingBeaconResponse());
                } else {
                    //dispatch(addNewCollectionResponse());
                }
                return response;
                //}).then((response) => {
                //    if (beacondata.id == "0") {
                //        dispatch(getCollectionAction(beacondata.id));
                //    }
                //    return response;
            }).catch(error => {
                //dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export function getBeaconAction(beaconId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return BeaconApi.getBeacon(beaconId)
            .then(formdata => {
                dispatch(getBeaconResponse(beacondata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}