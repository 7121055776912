import React, { Component } from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
//import { LinkContainer } from 'react-router-bootstrap';
//import './NavMenu.css';
// eslint-disable-next-line
import { withRouter } from 'react-router-dom';
import RB from '../components/common/RBAC';
import constants from '../constants';
import { Box, Grid, RoutedAnchor, Anchor, Text, Grommet } from 'grommet';
import { Actions, Iteration, Blank, Overview, List, Multiple, Configure, Notification, SettingsOption, Calendar, Tag, Connect, Location, Book, Catalog, Workshop, Plan, AppsRounded, Schedules, Announce } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { BeaconCustom } from '../grommet-extras/BeaconCustom';
import { MapCustom } from '../grommet-extras/MapCustomIcon';
import { ChargifiCustom } from '../grommet-extras/ChargifiCustom';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
//border = { isActive? { side: "left", color: "white", size: "medium" } : ""}
import { Context } from '../components/Context';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        }
    }
});

class SensitiveRoutedAnchor extends Component {
    render() {
        const { match, location, history, path, label, icon, disabled, sub, subsub } = this.props;
        let isBold = this.props.checkpath(path, location.pathname) ? "bold" : "normal";
        let isActive = this.props.checkpath(path, location.pathname);
        let padd = subsub ? "large" : "medium";
        return (
            <StyledBox direction="row" justify="between" pad={{ "left": padd, "top": "xsmall" }} leftborderthick={isActive}>
                <Box width="91%" margin={sub ? { left: "small" } : ""} justify="evenly">
                    <Anchor path={path}
                        onClick={()=> history.push(path)}
                        label={<Text weight={isBold}>{label}</Text>}
                        icon={icon}
                        disabled={disabled} />
                </Box>
            </StyledBox>
        );
    }
};

class SensitiveAnchor extends Component {
    render() {
        const { location, label, boldpathes, open, sub, subsub, ...rest } = this.props;
        let isBold = boldpathes.some(pe => location.pathname.startsWith(pe) || location.pathname.includes(pe)) ? "bold" : "normal";
        let padd = subsub ? "large" : "medium";
        return (
            <StyledBox pad={{ "left": padd, "top": "xsmall" }} leftborderthick={isBold == "bold" ? (!open ? true : false) : false} justify="center">
                <Box margin={sub ? { "left": "small" } : ""} justify="center" width="91%">
                    <Anchor
                        label={<Text weight={isBold}>{label}</Text>}
                        {...rest}
                    />
                </Box>
            </StyledBox>
        )
    }
}

const LocationSensitiveRoutedAnchor = withRouter(SensitiveRoutedAnchor);
const LocationSensitiveAnchor = withRouter(SensitiveAnchor);

class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openContent: false,
            openAdmin: false,
            openServices: false,
            open: false,
            openWellness: false,
            openBooking: false,
            openAppContent: false
        };

        this.state.openContent = this.props.history && ['/categories', '/category', '/page', '/placemark', '/eventstable', '/event', '/actioncentre', '/actioncentre/:id', '/app', '/form'].some(pe => this.props.history.location.pathname.startsWith(pe));
        this.state.openAdmin = this.props.history && ['/spacemapping','/mapping', '/locuser', '/locroles', '/tags', '/tag', '/createtag', '/edittags', '/label', '/trackers', '/tracker', '/link', '/collections', 'departments', '/beacons', '/editbeacons', '/integrations'].some(pe => this.props.history.location.pathname.startsWith(pe) || this.props.history.location.pathname.includes(pe));
        this.state.openServices = this.props.history && ['/spots', '/beacons', '/meridianmap', '/assets', '/asset'].some(pe => this.props.history.location.pathname.startsWith(pe));
        this.state.openBooking = this.props.history && ['/mybookings', '/bookspacemap'].some(pe => this.props.history.location.pathname.startsWith(pe) || this.props.history.location.pathname.includes(pe));
        this.state.openAppContent = this.props.history && ['/actioncentre', 'category', '/categories', '/page', '/app', '/form', '/session', '/event', '/placemark'].some(pe => this.props.history.location.pathname.startsWith(pe) || this.props.history.location.pathname.endsWith(pe) || this.props.history.location.pathname.includes(pe));
    };

    //state = { openContent: false, openAdmin: false, openServices: false, open: false }
    ClickContent = () => this.setState({ openContent: !this.state.openContent });
    ClickAdmin = () => this.setState({ openAdmin: !this.state.openAdmin });
    ClickServices = () => this.setState({ openServices: !this.state.openServices });
    clickWellness = () => this.setState({ openWellness: !this.state.openWellness });
    ClickBooking = () => this.setState({ openBooking: !this.state.openBooking });
    ClickAppContent = () => this.setState({ openAppContent: !this.state.openAppContent });

    componentWillReceiveProps(props) {
        if (props.history.location.pathname.includes("/tags") && props.history.location.state && props.history.location.state.filter && !this.state.openAdmin) {
            this.ClickAdmin();
        }
        if (props.history.location.pathname.includes("book") && !this.state.openBooking) {
            this.ClickBooking();
        }
    }

    render() {
        const { open, openContent, openAdmin, openServices, openWellness, openBooking, openAppContent  } = this.state;
        const locationid = sessionStorage.getItem("locationId");
        const orgId = sessionStorage.getItem("organisationId");
        return (
            <Context.Consumer>
                {values => {
                    console.log("navloc", values);
                    return (
                        <Grommet theme={customTheme}>
                            <Box fill={true} pad='none' background={constants.LAYOUT_COLOR}>
                                <Box width="100%">
                                    {RB.canEdit(constants.PERMS.LOC_CONTENT) && <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                        <LocationSensitiveRoutedAnchor path={`/${orgId}/${locationid}/home`} label='Insights' icon={<Overview />}
                                            checkpath={(path, location) => path === location} disabled={!RB.canEdit(constants.PERMS.LOC_CONTENT)} />
                                    </Box>}
                                    {constants.RELEASE_TYPE == "HPE-MTF" && 
                                        //values.feature.find(f => f.name == "siteMap").isEnabled && values.feature.find(f => f.name == "siteBookADeskIntegration").isEnabled &&
                                        !RB.canEdit(constants.PERMS.LOC_CONTENT) && <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                        <LocationSensitiveRoutedAnchor path={`/${orgId}/${locationid}/overview`} label='Overview' icon={<AppsRounded />}
                                            checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                    </Box>}
                                    {constants.RELEASE_TYPE == "HPE-MTF" &&
                                        //values.feature.find(f => f.name == "siteMap").isEnabled && values.feature.find(f => f.name == "siteBookADeskIntegration").isEnabled &&
                                        <Box border="bottom" pad={{ "vertical": "xsmall" }}>
                                            <LocationSensitiveAnchor
                                                boldpathes={['/mybookings', '/bookspacemap']} open={openBooking}
                                                label='Booking Tools' icon={<Schedules />} onClick={this.ClickBooking} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                            {openBooking && (
                                                <Box>
                                                    <Box pad={{ "vertical": "xsmall" }}>
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/bookspacemap`} label='Book a Space' /*icon={<Blank />}*/ icon={<Workshop />}
                                                            checkpath={(path, location) => path === location || location.includes('/bookspacemap')} /*disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)}*/ />
                                                    </Box>
                                                    <Box pad={{ "vertical": "xsmall" }}>
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/mybookings`} label='My Bookings' /*icon={<Blank />}*/ icon={<Plan />}
                                                            checkpath={(path, location) => path === location || location.includes('/mybookings')} /*disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)}*/ />
                                                    </Box>
                                                </Box>)}

                                        </Box>
                                    }

                                    {/*------------*/}
                                    {(values.feature.find(f => f.name == "siteMap").isEnabled && this.props.isMeridianEnabled) &&
                                        <Box border="bottom">
                                                <Box>
                                                    <Box pad={{ "vertical": "xsmall" }}>
                                                        <LocationSensitiveRoutedAnchor path={`/${orgId}/${locationid}/meridianmap`} label='Live Map' icon={<MapCustom />}
                                                            checkpath={(path, location) => path === location || location.includes('/meridianmap')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    </Box>
                                                    {values.feature.find(f => f.name == "siteAssetTracking").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                                        <LocationSensitiveRoutedAnchor path={`/${orgId}/${locationid}/assets`} label='Assets' icon={<Tag />}
                                                    checkpath={(path, location) => path === location || location.includes('/asset') || location.endsWith('/assets') || location.includes('/asset')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    </Box>}
                                                </Box>

                                        </Box>}
                                    {/*------------*/}

                                    {RB.canEdit(constants.PERMS.LOC_CONTENT) &&
                                        ((values.feature.find(f => f.name == "content").isEnabled && values.feature.find(f => f.name == "siteMobilClient").isEnabled) ||
                                            (((values.feature.find(f => f.name == "siteMap").isEnabled && values.feature.find(f => f.name == "siteMobilClient").isEnabled) || values.feature.find(f => f.name == "content").isEnabled) && RB.canEdit(constants.PERMS.LOC_CONTENT)) ||
                                            values.feature.find(f => f.name == "content").isEnabled ||
                                            (values.feature.find(f => f.name == "siteMap").isEnabled && this.props.isMeridianEnabled)) &&
                                        <Box border="bottom" pad={{ "vertical": "xsmall" }}>
                                            <LocationSensitiveAnchor
                                                boldpathes={['/actioncentre', '/categories', '/category', '/pages', '/page', '/app', '/form', '/eventstable', '/event', '/placemarks', '/placemark']} open={openAppContent}
                                                label='App Content' icon={<AppsRounded />} onClick={this.ClickAppContent} /*disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)}*/ />
                                            {openAppContent && (
                                                <Box>
                                                    {(values.feature.find(f => f.name == "content").isEnabled && values.feature.find(f => f.name == "siteMobilClient").isEnabled) &&
                                                        <Box pad={{ "vertical": "xsmall" }}>
                                                            <LocationSensitiveRoutedAnchor sub size={"small"} path={`/${orgId}/${locationid}/actioncentre`} label={window.navigator.language.toLowerCase() === "en-us" ? "Action Center" : "Action Centre"} icon={<Actions />}
                                                                checkpath={(path, location) => path === location || location.includes('/actioncentre')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                        </Box>}
                                                    {(((values.feature.find(f => f.name == "siteMap").isEnabled && values.feature.find(f => f.name == "siteMobilClient").isEnabled) || values.feature.find(f => f.name == "content").isEnabled) && RB.canEdit(constants.PERMS.LOC_CONTENT)) &&
                                                        <Box pad={{ "vertical": "xsmall" }}>
                                                            <LocationSensitiveRoutedAnchor sub size={"small"} path={`/${orgId}/${locationid}/categories`} label='Categories' icon={<List />}
                                                                checkpath={(path, location) => path === location || location.includes('/category') || location.endsWith('/category')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                        </Box>}
                                                    {values.feature.find(f => f.name == "content").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                                            <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/pages`} label='Content' icon={<Multiple />}
                                                                checkpath={(path, location) => path === location || location.includes('/page') /*|| location.startsWith('/placemark')*/ || location.includes('/app') || location.includes('/form')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    </Box>}
                                                    {values.feature.find(f => f.name == "siteSession").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/sessions`} label='Sessions' icon={<Calendar />}
                                                            checkpath={(path, location) => path === location || location.includes('/session')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    </Box>}
                                                    {values.feature.find(f => f.name == "content").isEnabled && <Box pad={{ "vertical": "xsmall" }} /*border="bottom"*/>
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/eventstable`} label='Spotlight' icon={<Announce />}
                                                            checkpath={(path, location) => path === location || location.includes('/event')} disabled={!RB.canRead(constants.PERMS.LOC_EVENTS)} />
                                                    </Box>}
                                                    {values.feature.find(f => f.name == "siteMap").isEnabled && this.props.isMeridianEnabled &&
                                                        <Box pad={{ "vertical": "xsmall" }}>
                                                            <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/placemarks`} label='Placemarks' icon={<Location />}
                                                                checkpath={(path, location) => path === location || location.includes('/placemark') || location.includes('/placemark')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                        </Box>}


                                                </Box>)}
                                        </Box>}
                                    {/*------------*/}
                                    {RB.canRead(constants.PERMS.LOC_ADMINISTRATION) &&
                                        <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                            <LocationSensitiveAnchor
                                                boldpathes={['/mapping', '/locusers', '/locuser', '/locroles', '/integrations', '/beacons', '/spots', '/lochealthpass', '/tags', '/tag', '/createtag', '/edittag', '/tracker', '/link', '/trackers', '/label', '/departments', '/department', '/collections']} open={openAdmin}
                                                label='Manage' icon={<SettingsOption />} onClick={this.ClickAdmin} disabled={!RB.canRead(constants.PERMS.LOC_ADMINISTRATION)} />
                                            {openAdmin && (
                                                <Box>
                                                {values.feature.find(f => f.name == "siteWayFinding").isEnabled && <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/beacons`} label='Beacons' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes(`/editbeacons`)} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}

                                                {values.feature.find(f => f.name == "siteAssetTracking").isEnabled && <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/collections`} label='Collections' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes('/collection')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}

                                                {values.feature.find(f => f.name == "siteAssetTracking").isEnabled && <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/departments`} label='Departments' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes('/department')} disabled={!RB.canRead(constants.PERMS.LOC_ADMINISTRATION)} />}

                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/integrations`} label='Integrations' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes('/integrations')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    {values.feature.find(f => f.name == "siteMap").isEnabled &&
                                                    (values.feature.find(f => f.name == "roomBooking").isEnabled ||
                                                        values.feature.find(f => f.name == "deskBooking").isEnabled) &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/mapping`} label='Location Mapping' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes('/mapping')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}
                                                {values.feature.find(f => f.name == "siteSession").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/spacemapping`} label='Space Mapping' icon={<Blank />}
                                                        checkpath={(path, location) => path === location || location.includes('/spacemapping')} disabled={!RB.canRead(constants.PERMS.LOC_ADMINISTRATION)} />}
                                                {values.feature.find(f => f.name == "siteChargifi").isEnabled && constants.release().Feature_chargifi && this.props.isChargifiEnabled &&
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/spots`} label='Spots' icon={<Blank />}
                                                            checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}
                                                {values.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/tags`} label='Tags' icon={<Blank />}
                                                            checkpath={(path, location) => path === location || location.includes('/tag') || location.startsWith('/createtag') || location.startsWith('/edittag') || location.startsWith('/label') || location.includes("/tag") || location.includes("/label") || location.includes("/createtag") || location.includes("/edittag")} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}
                                                {values.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/trackers`} label='Trackers' icon={<Blank />}
                                                            checkpath={(path, location) => path === location || location.includes('/tracker') || location.startsWith('/link') || location.includes('/tracker') || location.includes('/link')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}
                                                        <LocationSensitiveRoutedAnchor sub path={`/${orgId}/${locationid}/locusers`} label='Users' icon={<Blank />}
                                                            checkpath={(path, location) => path === location || location.includes('/locuser')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                    {values.feature.find(f => f.name == "siteWellness").isEnabled && constants.release().Feature_citrix &&
                                                        <LocationSensitiveAnchor
                                                            boldpathes={['/lochealthpass']} sub open={openWellness}
                                                            label='Wellness' icon={<Blank />} onClick={this.clickWellness} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />}
                                                    {openWellness && constants.release().Feature_citrix && (
                                                        <Box>
                                                        <LocationSensitiveRoutedAnchor subsub path={`/${orgId}/${locationid}/lochealthpass`} label='Health Pass' icon={<Blank />}
                                                                checkpath={(path, location) => path === location || location.includes('/lochealthpass')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                                        </Box>)}
                                                </Box>
                                            )}
                                        </Box>}
                                    {/*------------*/}
                                    <Box pad={{ "vertical": "xsmall" }}>
                                        <LocationSensitiveRoutedAnchor path={`/${orgId}/${locationid}/configuration`} label='Config' icon={<Configure />}
                                            checkpath={(path, location) => location.includes('/configuration') || location.includes('/building/') || location.includes('/level/')} disabled={!RB.canRead(constants.PERMS.LOC_CONTENT)} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grommet>
                    );
                }}
            </Context.Consumer>
        );
    }
}
export default withRouter(NavMenu);
