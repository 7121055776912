import { Box, Text} from 'grommet';
import React from 'react';
import { Alert } from 'grommet-icons';
import { StyledBox } from './StyledBox';

export const NoContent = (props) => {
    return (
        <Box>
            {props.styled &&
                <StyledBox style1 margin={{ "top": "36px" }} pad="large" gap="small" direction="column" align="center">
                    <Alert color="#DADADA" size="100px" />
                    <Box align="center">
                        <Text>{props.line1 || "There is no content at this time"}</Text>
                        <Text>{props.line2 || "Please check back again soon"}</Text>
                    </Box>
                </StyledBox>
            }
            {!props.styled &&
                <StyledBox pad="large" gap="small" direction="column" align="center">
                    <Alert color="#DADADA" size="100px" />
                    <Box align="center">
                        <Text>{props.line1 || "There is no content at this time"}</Text>
                        <Text>{props.line2 || "Please check back again soon"}</Text>
                    </Box>
                </StyledBox>
            }
        </Box>
    );
};