import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import * as SiteAction from '../action/SiteAction';
import * as AssetAction from '../action/AssetAction';
import * as CollectionAction from '../action/CollectionAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as MetaDataAction from '../action/MetaDataAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, Close, FormClose, StatusGoodSmall, Filter, Expand, Contract, Technology, FormNext } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, SelectMultiple, CheckBox, Text, Calendar, Image, TextArea, Anchor, Header, Layer, Grommet, Stack, Collapsible, CheckBoxGroup } from 'grommet';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import * as MeridianSDK from "@meridian/web-sdk";
import './Meridian.css';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import RB from './common/RBAC';
import styled, { css } from 'styled-components';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}>
    layer: {
        extend: {
            "zIndex": 10
        }
    }
});

const placemarkToggle = deepMerge(hpe, {
    formField: {
        //border: {
        //    size: "0px",
        //    side: "none !important",
        //    color: "none",
        //},
        extend: ({ noBorder }) =>
            noBorder &&
            css`
        > label + div {
          box-shadow: none;
          border: none;
        }
      `
        //pad: "none"
    }
});

const FilterButton = styled(Button)`
        height: 48px;
        border-radius: 5px 5px 5px 5px;`;


let tagsFromStream = [];
let tagsByLocation = [];
let tagsByLocationFull = [];

export class MeridianMap extends React.Component {

    static contextType = Context;
    constructor() {
        super();
        this.mix = [];
        this.target = React.createRef();
        this.HeaderInfo = "";
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            selectedBuilding: "",
            selectedFloor: "",
            floors: [],
            showPlacemarks: true,
            showTags: true,
            showControlTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            selectedFloorId: "",
            selectedAsset: "",
            assetid: "",
            assetList: [],
            control: "All",
            mapVisible: false,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            sitedataLoading: true,
            assetsdataLoading: true,
            placemarksdataLoading: true,
            selectedCollections: [],
            showUnlinkedTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            assetsOnMap: [],
            selectedDepartment: [],
            isFullScreen: false,
            isDestroyed: false,
            selectedStatus: "",
            unlinks: [],
            placemarkid: "",
            apiErrorCounter: 0,
            apiErrorList: [],
            selectedManufacturer: [],
            selectedModel: [],
            selectedDeviceType: [],
            siteSearch: "",
            showSidePanel: true,
            isAllFetched: false,

            modelSuggestions: [],
            typeSuggestions: []
        };
    }

    emptyBuildingArr = [];
    headerHeight = 0;
    //Validating fields error handling
    summary_err = '';
    text_err = '';
    url_err = '';
    componentWillReceiveProps({ assetsdata, placemarksdata, levelsdata, sitedata, associationsdata }) {
        //if (assetsdata && assetsdata.length > 0) {
        //    assetsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //}
        //console.log("nct", assetsdata);
        //this.setState({ assetList: assetsdata });

        if (sitedata && !this.state.sitedataLoading && !this.isRendered) {
            if (sitedata.mapID) {
                this.isRendered = true;
                const myApi = new MeridianSDK.API({
                    environment: this.props.sitedata.eUorNon ? "eu" : "production",
                    token: this.props.sitedata.appToken
                });
                let ya = myApi.fetchTagsByLocation(sitedata.appID)
                    .then(res => {
                        tagsByLocationFull = res;
                        tagsByLocation = res.map(t => t.mac);
                        console.log("myApi0", res);
                        this.setState({ isAllFetched: true });
                    });
                //let ye = myApi.fetchTagsByFloor(sitedata.appID, sitedata.mapID).then(res => console.log("myApi1", res));
                const that = this;
                const newSocket = myApi.openStream({
                    locationID: sitedata.appID,
                    floorID: sitedata.mapID,
                    onInitialTags: function (tags) {
                        tagsFromStream = tags.map(t => t.mac);
                        that.setState({ isSidePanelLoading: false });
                    },
                    //onTagUpdate: function (tags) {
                    //    console.log("onTagUpdate", tags);
                    //},
                    //onException: function (err) {
                    //    console.log("onException", err);
                    //},
                    //onClose: function () {
                    //    console.log("onClose");
                    //},
                });
                this.setState({ selectedFloorId: sitedata.mapID }, () => {
                    if (this.state.selectedFloorId) {
                        setTimeout(() => this.renderMap(this.state.selectedFloorId), 4500);
                    }
                });
            } else if (levelsdata && !this.state.levelsdataLoading) {
                this.isRendered = true;
                let level = levelsdata.find(l => l.meridianId && l.published == true);
                if (level) {
                    this.setState({ selectedFloorId: level.meridianId }, () => {
                        if (this.state.selectedFloorId) {
                            setTimeout(() => this.renderMap(this.state.selectedFloorId), 4500);
                        }
                    });
                } else {
                    //No available floor
                    this.HeaderInfo = "There are no floors published. Please contact your site admin.";
                }
            }
        }

        if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            (assetsdata && placemarksdata && associationsdata) && ((assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) && (placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count"))) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count"))))) {
            console.log("cwrp", assetsdata, placemarksdata);
            let filteredPlacemarks = placemarksdata.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published && m.showOnMap)
                    return true;
                return false;
            });
            let a = [];
            assetsdata.forEach(itm => {
                let obj = { ...itm };
                let association = associationsdata.find(a => a.beaconId == itm.id && a.assetId);
                obj.name = association ? association.assetName : obj.name;
                a.push(obj);
            });
            const mix = a.concat(filteredPlacemarks).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            associationsdata && assetsdata && assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) {
            let a = [];
            assetsdata.forEach(itm => {
                let obj = { ...itm };
                let association = associationsdata.find(a => a.beaconId == itm.id && a.assetId);
                obj.name = association ? association.assetName : obj.name;
                a.push(obj);
            });
            const mix = a.sort(constants.byPropertyCalled("name"));
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (placemarksdata && placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count")) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count")))) {
            const mix = placemarksdata.filter(p => p.levelId && p.levelId != "" && p.levelId != null).sort(constants.byPropertyCalled("name"));
            this.mix = mix.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published && m.showOnMap)
                    return true;
                return false;
            });
            this.setState({ assetList: this.mix });
        }
        //paramID
        //if (this.mix.length > 0 && this.props.match.params.id) {
        //    const element = this.mix.find(e => e.id == this.props.match.params.id)
        //    if (element) {
        //        if (element.hasOwnProperty("isControlTag")) {
        //            //Tag or Asset
        //            const assetid = element.id;
        //            const mapid = element.map.id;
        //            this.setState({ selectedFloorId: mapid, assetid: assetid, placemarkid: "", selectedAsset: { name: element.name } }, () => this.renderMap(mapid));
        //        }
        //        else {
        //            //Placemark
        //            const placemarkid = element.meridianId;
        //            const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
        //            const mapid = floor.meridianId;
        //            this.setState({ selectedFloorId: mapid, placemarkid: placemarkid, assetid: "", selectedAsset: { name: element.name } }, () => this.renderMap(mapid));
        //        }
        //    }
        //}

        let withNoBuilding = this.props.levelsdata.filter(l => (l.mergedBuildingId == null || l.mergedBuildingName == "") && l.published);
        if (withNoBuilding.length > 0) {
            var obj = Object.assign({}, this.props.buildingsdata[0]);
            for (var key of Object.keys(obj)) {
                obj[key] = null;
            }
            obj.meridianName = "Unassigned";
            this.emptyBuildingArr = [obj];

            //const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == null);
            //this.setState({ floors: floors });
        }
    }
    componentDidMount() {
        //this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        let isControlTagsIncluded = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) ? "" : false;
        this.props.action.getMappedAssetsAction(isControlTagsIncluded, RB.canEdit(constants.PERMS.LOC_ADMINISTRATION))
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- MappedAssets List Error', error);
            });
        this.props.action.getCollectionsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getAssociationsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assocation List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getTrackersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemarks List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
        this.props.action.getMetadatasAction()
            .then(response => this.setState({ metadatasdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
    }

    componentWillUnmount() {
        //this.historyUnblock();
        if (this.map) {
            this.map.destroy();
        }
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    renderMap(floorid) {
        let id = this.state.isFullScreen ? "meridian-map2" : "meridian-map1";
        if (this.map && !this.map.isDestroyed) {
            this.map.update({ floorID: floorid });
            tagsFromStream = tagsByLocationFull.filter(t => t.map_id == floorid).map(t=> t.mac)
            this.setState({update: true});
        }
        else {
            const macid = ["CC78ABC6CB63", "CC78AB9D9845"];
            const api = new MeridianSDK.API({
                environment: this.props.sitedata.eUorNon ? "eu" : "production",
                //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                token: this.props.sitedata.appToken
            });
            var element = document.getElementById(id);
            if (element) {
                console.log("propiMP", floorid);
                element.innerHTML = "";
                //console.log("element", element);
                //console.log("mapelement", this.props.sitedata.appID, floorid);
                this.map = MeridianSDK.createMap(
                    //document.getElementById("meridian-map"),
                    element,
                    {
                        api: api,
                        //locationID: "5198682008846336",
                        //loorID: "5755685136498688"
                        locationID: this.props.sitedata.appID,
                        floorID: floorid,
                        shouldMapPanZoom: function restrictedPanZoom(event) {
                            if (event.type === "wheel" && !event.altKey) {
                                return false;
                            } else if (event.type === "touchstart") {
                                return event.touches.length >= 2;
                            }
                            return true;
                        },
                        onFloorChange: floor => {
                            this.setState({ selectedFloorId: floor.id, isSidePanelLoading: true }, () => {
                                const that = this;
                                const newSocket = api.openStream({
                                    locationID: this.props.sitedata.appID,
                                    floorID: floor.id,
                                    onInitialTags: function (tags) {
                                        tagsFromStream = tags.map(t => t.mac);
                                        that.setState({ isSidePanelLoading: false});
                                    },
                                });
                            })
                        },
                        onFloorsUpdate: floors => {
                            floors.forEach(item => {
                                if (item.published) {
                                    let level = this.props.levelsdata.find(l => l.meridianId == item.id);
                                    item.published = level ? level.published : false;
                                }
                            });
                        },
                        showFloorsControl: true, //this.state.showMapControls,
                        showTagsControl: false, //this.state.showMapControls,
                        showSearchControl: false,


                        //height: "650px", //650px for Keith's DEMO
                        height: this.state.isFullScreen ? "calc(100vh - 100px - 77px)" : "calc(100vh - 300px - 70px)",
                        //height: this.state.isFullScreen ? (window.innerHeight - this.headerHeight - 100) + "px" : "450px",
                        placemarks: {
                            filter: p => {
                                if (this.state.showPlacemarks) {
                                    if (this.state.assetid)
                                        return false;
                                    if (this.state.placemarkid)
                                        return p.id == this.state.placemarkid;
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                        },

                        tags: {
                            updateInterval: 10000,
                            filter: t => {
                                if (!this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled) {
                                    //if its not enabled then we dont show anything.
                                    return false;
                                }
                                //console.log("Tags", t);
                                //t.image_url = "https://hpepngdemostor.blob.core.windows.net/images/00FFFF.png";
                                //return this.state.tags.includes(t.mac);

                                //if assetsOnMap.length == 0 && selectedDepartment then it means that we dont have asset(s) with that department, therefore array should be empty.
                                //let assets = this.state.assetsOnMap.length > 0 ? this.state.assetsOnMap : this.state.selectedDepartment ? [] : this.props.assetsdata;
                                let assets = [];
                                let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);

                                if (this.state.selectedDepartment.length > 0 && this.state.selectedStatus.length > 0)
                                    assets = this.state.departments.filter(d => this.state.statuses.includes(d));
                                else if (this.state.selectedStatus.length > 0 && !this.showUnlinkedTags)
                                    assets = this.state.statuses.filter(d => unl.includes(d));
                                else if (this.state.selectedStatus.length > 0 && this.showUnlinkedTags)
                                    assets = this.state.statuses.filter(d => unl.includes(d));
                                else if (this.state.selectedDepartment.length > 0)
                                    assets = this.state.departments;
                                else {
                                    if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                        //Dont have links && only show links
                                        assets = [];
                                    }
                                    else {
                                        assets = unl;
                                    }
                                }


                                assets = this.filterDataBySelections();
                                if (!assets/*.map(a => a.id)*/.includes(t.mac)) return false;
                                if (!t.is_control_tag) {
                                    if (this.state.showTags) {
                                        if (this.state.placemarkid)
                                            return false;
                                        if (this.state.assetid) {
                                            let labels = t.tags.map(l => l.name);
                                            let value = false;
                                            if (this.state.selectedCollections.length == 0) {
                                                value = true;
                                            }
                                            else {
                                                //value = this.state.selectedCollections.some(e => labels.includes(e.labelName));
                                                //if (!value) {
                                                //    //Check if tag has no collection && no collection option is active
                                                //    let assetHasCollection = this.props.collectionsdata.some(c => labels.includes(c.labelName));
                                                //    if (!assetHasCollection && this.state.selectedCollections.some(c => c.id == null))
                                                //        value = true;
                                                //}
                                                let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                                                if (tracker && tracker.collectionId) {
                                                    value = this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                                                }
                                                else {
                                                    //Asset does not have coll
                                                    value = this.state.selectedCollections.some(e => e.id == null);
                                                }
                                            }
                                            return t.mac == this.state.assetid && value;
                                        } else {
                                            if (this.state.selectedCollections.length == 0) {
                                                return true;
                                            }
                                            else {
                                                //let labels = t.tags.map(l => l.name);
                                                //let value = false;
                                                //value = this.state.selectedCollections.some(e => labels.includes(e.labelName));
                                                //if (!value) {
                                                //    //Check if tag has no collection && no collection option is active
                                                //    let assetHasCollection = this.props.collectionsdata.some(c => labels.includes(c.labelName));
                                                //    if (!assetHasCollection && this.state.selectedCollections.some(c => c.id == null))
                                                //        value = true;
                                                //}
                                                //return value;

                                                let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                                                if (tracker && tracker.collectionId) {
                                                    return this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                                                }
                                                else {
                                                    //Asset does not have coll
                                                    return this.state.selectedCollections.some(e => e.id == null);
                                                }
                                            }
                                        }
                                    } else {
                                        if (this.state.showUnlinkedTags && !this.props.associationsdata.find(f => f.beaconId == t.id).assetId) {
                                            return true;
                                        }
                                        return false;
                                    }
                                }
                                else {
                                    if (this.state.showControlTags) {
                                        if (this.state.placemarkid)
                                            return false;
                                        if (this.state.assetid) {
                                            let value = false;
                                            if (this.state.selectedCollections.length == 0) {
                                                value = true;
                                            }
                                            else {
                                                let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                                                if (tracker && tracker.collectionId) {
                                                    value = this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                                                }
                                                else {
                                                    //Asset does not have coll
                                                    value = this.state.selectedCollections.some(e => e.id == null);
                                                }
                                            }
                                            return t.mac == this.state.assetid && value;
                                        } else {
                                            if (this.state.selectedCollections.length == 0) {
                                                return true;
                                            }
                                            else {
                                                //let labels = t.tags.map(l => l.name);
                                                //let value = false;
                                                //value = this.state.selectedCollections.some(e => labels.includes(e.labelName));
                                                //if (!value) {
                                                //    //Check if tag has no collection && no collection option is active
                                                //    let assetHasCollection = this.props.collectionsdata.some(c => labels.includes(c.labelName));
                                                //    if (!assetHasCollection && this.state.selectedCollections.some(c => c.id == null))
                                                //        value = true;
                                                //}
                                                //return value;
                                                let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                                                if (tracker && tracker.collectionId) {
                                                    return this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                                                }
                                                else {
                                                    //Asset does not have coll
                                                    return this.state.selectedCollections.some(e => e.id == null);
                                                }
                                            }
                                        }
                                    } else {
                                        return false;
                                    }

                                }
                                //if (this.state.selectedCollections.length == 0) {
                                //    return true;
                                //}
                                //else {
                                //    let labels = t.tags.map(l => l.name);
                                //    let value = false;
                                //    this.state.selectedCollections.forEach(e => {
                                //        if (labels.includes(e.name)) {
                                //            value = true;
                                //            return true;
                                //        }
                                //    });
                                //    return value;
                                //}

                                //if (this.state.assetid && this.state.showTags && !t.is_control_tag) {
                                //    return t.mac == this.state.assetid;
                                //}
                                //else if (this.state.assetid && this.state.showControlTags && t.is_control_tag) {
                                //    return t.mac == this.state.assetid;
                                //}
                                //else if (this.state.showTags && /*this.state.control == "All"*/ this.state.showControlTags) {
                                //    return true;
                                //}
                                //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showControlTags) {
                                //    return t.is_control_tag;
                                //}
                                //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showTags) {
                                //    return !t.is_control_tag;
                                //}
                                //return this.state.showTags;
                            },
                            showControlTags: this.state.showControlTags/*this.state.control !== "Tags"*/
                        }
                    }
                );
                element.style.visibility = "visible";
                //$('.meridian-tag-label-Chair').css('background', 'yellow');
                //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];
                if (this.props.collectionsdata && this.props.collectionsdata[0] && !this.props.collectionsdata[0].hasOwnProperty("count")) {
                    let rules = [];
                    this.props.collectionsdata.forEach(item => {
                        //this.addCSSclass([
                        //    {
                        //        selector: `.meridian-tag-label-${item.name.replace(" ", "-")}`,
                        //        rule: `background-color:${item.hexColor};background-image:none`
                        //    }
                        //]);
                        //this.toggleClass(element, `meridian-tag-label-${item.name.replace(" ", "-")}`, false);
                        rules.push(
                            {
                                selector: `.meridian-tag-label-${item.labelName.replace(" ", "-")}`,
                                rule: `background-color:${item.hexColor};background-image:none`
                            }
                        );
                    });
                    this.addCSSclass2(rules);
                }
                this.setState({ mapVisible: true });
            }
        }
    }
    getOption(token, method = 'get') {
        //let token = "6c09d265b1413fc768f4e08447ea95c8923a9c78"
        let bearer = `Token ${token}`;
        let headers = new Headers();
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        var option = {
            method: method,
            headers: headers
        };
        return option;
    }
    toggleClass(elem, theClass, newState) {
        var matchRegExp = new RegExp('(?:^|\\s)' + theClass + '(?!\\S)', 'g');
        var add = (arguments.length > 2 ? newState : (elem.className.match(matchRegExp) === null));

        elem.className = elem.className.replace(matchRegExp, ''); // clear all
        if (add) elem.className += ' ' + theClass;
    }

    addCSSclass(rules) {
        var style = document.createElement("style");
        style.setAttribute(`${rules[0].selector}`, `${rules[0].selector}`);
        style.appendChild(document.createTextNode("")); // WebKit hack :(
        document.head.appendChild(style);
        var sheet = style.sheet;

        rules.forEach((rule, index) => {
            try {
                if ("insertRule" in sheet) {
                    sheet.insertRule(rule.selector + "{" + rule.rule + "}", index);
                } else if ("addRule" in sheet) {
                    sheet.addRule(rule.selector, rule.rule, index);
                }
            } catch (e) {
                // firefox can break here          
            }

        });
    }
    addCSSclass2(rules) {
        var style = document.createElement("style");
        style.setAttribute('id', "coloured");
        let innerHTML = '';
        rules.forEach((rule, index) => {
            innerHTML += `${rule.selector} {
                ${rule.rule}
            }`;
        });
        style.innerHTML = innerHTML;
        document.head.appendChild(style);
    }
    componentWillUnmount() {
        let cs = document.querySelectorAll("[id='coloured']");
        cs.forEach(item => item.remove());
    }
    renderValue() {
        return this.state.selectedCollections.length == 1 ?
            <Box pad="small" direction="row" gap="small">
                {/*<StatusGoodSmall color={this.state.selectedCollections[0].hexColor} />*/}
                <Box round height="23px" background={this.state.selectedCollections[0].hexColor} width="23px" border style={{ /*"border-color": "red",*/ "border-width": "2px" }} />
                {this.state.selectedCollections[0].name}
            </Box>
            :
            this.state.selectedCollections.length == 0 ?
                <Box pad="small">All Collections</Box>
                :
                <Box pad="small">Multiple Collections</Box>;
    }
    resetFilter = () => {
        let isSiteAssetTrackingEnabled = this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled;
        let isSiteMapEnabled = this.context.feature.find(f => f.name == "siteMap").isEnabled;
        this.setState({
            showPlacemarks: false,
            showTags: false,
            showControlTags: isSiteAssetTrackingEnabled && !RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            //control: "All",
            //mapVisible: true,
            selectedCollections: [],
            assetsOnMap: [],
            showUnlinkedTags: isSiteAssetTrackingEnabled && !RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            selectedDepartment: [],
            selectedStatus: "",
            statuses: [],
            departments: [],
            unlinks: [],
            selectedManufacturer: [],
            selectedModel: [],
            selectedDeviceType: []
        }, () => {
            if (this.state.selectedFloorId)
                this.renderMap(this.state.selectedFloorId);
        });
    }

    filterDataBySelections() {
        const { selectedDepartment, selectedManufacturer, selectedModel, selectedDeviceType, selectedStatus } = this.state;
        let assets = [];
        let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);
        if (!(selectedDepartment.length == 0 && selectedManufacturer.length == 0 && selectedModel.length == 0 && selectedDeviceType.length == 0 && selectedStatus.length == 0)) {
            let trackers = this.props.trackersdata.filter(t =>
                //((selectedDepartment && selectedDepartment.id == t.departmentId) || selectedDepartment == "") &&
                ((selectedDepartment.length > 0 && selectedDepartment.some(d => d.id == t.departmentId)) || selectedDepartment.length == 0) &&
                //((selectedManufacturer && selectedManufacturer.id == t.manufacturerId) || selectedManufacturer == "") &&
                ((selectedManufacturer.length > 0 && selectedManufacturer.some(m=> m.id == t.manufacturerId)) || selectedManufacturer.length == 0) &&
                //((selectedModel && selectedModel.id == t.modelId) || selectedModel == "") &&
                ((selectedModel.length > 0 && selectedModel.some(m=> m.id == t.modelId)) || selectedModel.length == 0) &&
                //((selectedDeviceType && selectedDeviceType.id == t.deviceTypeId) || selectedDeviceType == "")
                ((selectedDeviceType.length > 0 && selectedDeviceType.some(d=> d.id == t.deviceTypeId)) || selectedDeviceType.length == 0)
            )
            console.log("dirr", trackers)
            if (trackers) {
                //check if we have linked tag(s) for that tracker(s)

                //assets = trackers.map(t => t.assetBeaconId);

                //let links = this.props.associationsdata.filter(a => trackers.map(t => t.id).includes(a.assetId) && a.beaconId);
                //if (links) {
                //    assets = [];
                //    links.forEach(l => {
                //        l.id = l.beaconId;
                //        assets.push(l.beaconId);
                //    });
                //}

                let links = this.props.assetsdata.filter(a => trackers.map(t => t.assetBeaconId).includes(a.id));
                if (selectedStatus.length > 0) {
                    assets = links.filter(a => selectedStatus.includes(a.serviceStateId)).map(a => a.id);
                } else {
                    assets = links.map(a => a.id);
                }
            }
        }
        else {
            if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) && !this.state.showTags && !this.state.showControlTags) {
                //Dont have links && only show links
                assets = [];
            }
            else if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) && this.state.showTags) {
                //assets = this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId);
                let linked = this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId)
                assets = this.props.assetsdata.filter(at => linked.includes(at.id) || at.isControlTag).map(aa => aa.id);
            }
            else {
                assets = unl;
            }
        }
        console.log("avaF", assets);
        return assets;
    }

    renderActionBar(toFullScreen = true) {
        let buttonId = toFullScreen ? "open" : "fullOpen";
        let searchBox = toFullScreen ? "searchBox" : "searchBoxFull";
        let isClearVisible =
            this.state.showPlacemarks == false &&
                this.state.showTags == false &&
                this.state.showControlTags != RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedCollections.length == 0 &&
                //this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags != RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedDepartment.length == 0 &&
                this.state.selectedStatus == "" &&
                this.state.selectedManufacturer.length == 0 &&
                this.state.selectedModel.length == 0 &&
                this.state.selectedDeviceType.length == 0
                ? false : true;
        let filterSize = 0;
        let isSiteWllnessEnabled = this.context.feature.find(f => f.name == "siteWellness").isEnabled;
        let isSiteAssetTrackingEnabled = this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled;
        let isSiteMapEnabled = this.context.feature.find(f => f.name == "siteMap").isEnabled;
        filterSize += isSiteAssetTrackingEnabled && this.state.selectedDepartment.length > 0 ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && this.state.selectedCollections.length > 0 ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && this.state.selectedManufacturer.length > 0 ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && this.state.selectedModel.length > 0 ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && this.state.selectedDeviceType.length > 0 ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && isSiteWllnessEnabled && this.state.selectedStatus.length > 0 ? 1 : 0;
        filterSize += isSiteMapEnabled && this.state.showPlacemarks == true ? 1 : 0;
        filterSize += isSiteAssetTrackingEnabled && (this.state.showTags || this.state.showControlTags || this.state.showUnlinkedTags) ? 1 : 0;
        //filterSize += (this.state.showTags && this.state.showControlTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) && this.state.showUnlinkedTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) > 0 ? 1 : 0;

        return (
            <Grommet theme={hpe}>
                <Box id="actionbar" direction="row" >
                    <FormField width="100%" height="50px" id={searchBox}>
                        <Box direction="row">
                            <TextInput
                                disabled={!this.state.isAllFetched}
                                plain
                                focusIndicator={false}
                                placeholder="Search for items on all floors"
                                //dropTarget={this.target.current}
                                onClose={() => this.setState({ assetList: this.mix })}
                                value={this.state.selectedAsset ? this.state.selectedAsset.name : ""}
                                onChange={evt => {
                                    //console.log("rara", evt.target.value);
                                    let searchText = evt.target.value;
                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                    const filtered = this.mix.filter(o => o.name.match(regexp));
                                    this.setState({ assetList: filtered, selectedAsset: { name: evt.target.value } });
                                }}
                                onSuggestionSelect={evt => {
                                    this.setState({ selectedAsset: evt.suggestion.option });
                                    console.log("raraS", evt);
                                    let element = evt.suggestion.option;
                                    if (element) {
                                        if (element.hasOwnProperty("isControlTag")) {
                                            //Tag or Asset
                                            const assetid = element.id;
                                            const buildingid = element.map.group.id;
                                            const mapid = element.map.id;
                                            const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                            const floorName = this.props.levelsdata.find(l => l.meridianId == mapid);
                                            const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                            console.log("floors", floors);
                                            this.setState({ selectedFloorId: mapid, selectedFloor: floorName, selectedBuilding: buildingName, floors: floors, assetid: assetid, placemarkid: "" }, () => this.renderMap(mapid));
                                        }
                                        else {
                                            //Placemark
                                            const placemarkid = element.meridianId;
                                            //const buildingid = element.map.group.id;
                                            //const mapid = element.meridianId;
                                            //const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                            const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
                                            const mapid = floor.meridianId;
                                            //const buildingName = floor.mergedBuildingName;
                                            const buildingName = this.emptyBuildingArr.concat(this.props.buildingsdata).find(b => b.mergedId == floor.mergedBuildingId);
                                            const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                            console.log("floors", floors);
                                            this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));
                                        }
                                    }
                                }}
                                suggestions={this.state.assetList
                                    .filter(o => o.hasOwnProperty("isControlTag") == false || tagsByLocation.includes(o.id))
                                    .map((option, index, list) => {
                                    let info = "";
                                    let name = "";
                                    if (option.hasOwnProperty("isControlTag")) {
                                        //Tag or Asset
                                        if (RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                            if (this.props.associationsdata && this.props.associationsdata.length > 0 && !this.props.associationsdata[0].hasOwnProperty("count") &&
                                                this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(option.id)) {
                                                //Linked
                                                info = "Tracker";
                                                //Get the Tracker Name
                                                let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                                let tracker = this.props.trackersdata.find(t => t.id == association.assetId);
                                                name = tracker ? tracker.name : option.name;
                                            }
                                            else {
                                                //Not Linked, isControlTag will determine it
                                                info = option.isControlTag ? "Control Tag" : "Unlinked Tag";
                                                name = option.name;
                                            }
                                        }
                                        else {
                                            //Only Linked
                                            info = "Tracker";
                                            //Get the Tracker Name
                                            let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                            let tracker = this.props.association ? this.props.trackersdata.find(t => t.id == association.assetId) : "";
                                            name = tracker ? tracker.name : option.name;
                                        }
                                    } else {
                                        //Placemark
                                        info = "Placemark";
                                        if (option.placemarktype == "Desk" || option.teemType == "desk")
                                            info = "Desk";
                                        else if (option.placemarktype == "Conference Room" || option.teemType == "room")
                                            info = "Room";
                                        name = option.name;
                                    }
                                    let sb = document.getElementById(searchBox);
                                    let w = "auto"
                                    if (sb) {
                                        w = sb.offsetWidth + "px";
                                    }
                                    return {
                                        label: (
                                            <Box pad="small" justify="between" direction="row" width={w}>
                                                <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                    {name}
                                                </Box>
                                                <Box style={{ color: "#6f6f6f" }} flex={false}>{info}</Box>
                                            </Box>),
                                        value: name,
                                        option: option

                                    };
                                })
                                }
                            />
                            <Button
                                icon={this.state.selectedAsset && this.state.selectedAsset.name ? < FormClose color={constants.HPE_GREEN} /> : <Blank />}
                                disabled={this.state.selectedAsset ? false : true}
                                onClick={() => this.setState({ selectedAsset: "", assetid: "", placemarkid: "", assetList: this.mix }, () => {
                                    if (this.state.selectedFloorId) {
                                        this.renderMap(this.state.selectedFloorId);
                                    }
                                })}
                            />
                        </Box>
                    </FormField>
                    <Box justify="between" direction="row" width="100%">
                        <Box id="filter" direction="row" gap="small" height="48px">
                            {filterSize > 0 &&
                                <Stack anchor="top-right">
                                    <StyledBox style1 alignSelf="center" focusIndicator={true} style={{ "margin-left": "5px", "margin-top": "5px", border: "rgba(100, 100, 100, 0.35) 1px solid", "height": "40px" }}>
                                        <FilterButton icon={<Filter />} onClick={() => this.setState({ showFilter: true })} />
                                    </StyledBox>
                                    <Box
                                        background="brand"
                                        pad={{ horizontal: 'xsmall' }}
                                        round
                                    >
                                        {filterSize}
                                    </Box>
                                </Stack>
                            }
                            {filterSize == 0 &&
                                <StyledBox style1 alignSelf="center" focusIndicator={true} style={{ "margin-left": "5px", border: "rgba(100, 100, 100, 0.35) 1px solid", "height": "40px" }}>
                                    <FilterButton icon={<Filter />} onClick={() => this.setState({ showFilter: true })} />
                                </StyledBox>
                            }
                            <Box style={{ visibility: isClearVisible ? "visible" : "hidden" }} alignSelf="center">
                                <Button label={<Text size="small">Clear filters</Text>} onClick={this.resetFilter} />
                            </Box>
                        </Box>
                        {this.state.mapVisible && <FormNext id={buttonId} color='brand' onClick={evt => {
                            this.setState({ showSidePanel: !this.state.showSidePanel }, () => {
                                let o = document.getElementById(buttonId);
                                let v = !this.state.showSidePanel ? 180 : 0;
                                //o.style.transition = '0.4s ease';
                                o.style.borderRadius = this.state.showSidePanel ? "5px 0px 0px 5px" : "0px 5px 5px 0px";
                                o.style.transform = !this.state.showSidePanel ? 'translate(400px, 0px) rotate(' + v + 'deg)' : 'rotate(' + v + 'deg)';
                                setTimeout(() => this.map ? this.map.centerMap() : null, 201); //animation is 200ms
                            });
                        }}
                            style={{
                                position: "fixed",
                                right: "424px",
                                top: "600px",
                                zIndex: 1,
                                //right: "300px",
                                //transform: "translate(-260px, 0px)",
                                transition: "0.3s ease",
                                cursor: "pointer",
                                borderRadius: "5px 0px 0px 5px",
                                border: "solid 1px #CCCCCC",
                                background: "white"
                            }} />}
                        <Box id={toFullScreen ? "expand" : "close"} alignSelf="center" alignContent="end" data-for={toFullScreen ? "seefull" : "closefull"} data-tip={toFullScreen ? "See Fullscreen" : "Close Fullscreen"}>
                            <Button icon={toFullScreen ? <Expand /> : <Contract />}
                                focusIndicator={this.state.isFullScreen}
                                onClick={() => {
                                    let o = document.getElementById("open");
                                    let isOpen = o && o.style.borderRadius == "5px 0px 0px 5px" ? true : false;
                                    this.setState({ isFullScreen: toFullScreen, showSidePanel: toFullScreen ? true : isOpen }, () => {
                                        if (this.map) {
                                            this.map.destroy();
                                            if (this.state.selectedFloorId) {
                                                this.setState({ isDestroyed: true }, () =>
                                                    this.renderMap(this.state.selectedFloorId));
                                            }
                                        }
                                    })
                                }}
                                plain />
                            <ReactTooltip id={toFullScreen ? "seefull" : "closefull"} place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>
                </Box>
            </Grommet>
        );
    }

    globalFilters(t) {
        if (t.hasOwnProperty("isControlTag") && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled) {
            let assets = [];
            let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);

            if (this.state.selectedDepartment.length > 0 && this.state.selectedStatus.length > 0)
                assets = this.state.departments.filter(d => this.state.statuses.includes(d));
            else if (this.state.selectedStatus.length > 0 && !this.showUnlinkedTags)
                assets = this.state.statuses.filter(d => unl.includes(d));
            else if (this.state.selectedStatus.length > 0 && this.showUnlinkedTags)
                assets = this.state.statuses.filter(d => unl.includes(d));
            else if (this.state.selectedDepartment.length > 0)
                assets = this.state.departments;
            else {
                if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                    //Dont have links && only show links
                    assets = [];
                }
                else {
                    assets = unl;
                }
            }

            assets = this.filterDataBySelections();
            if (!assets/*.map(a => a.id)*/.includes(t.id)) return false;
            if (!t.isControlTag) {
                if (this.state.showTags) {
                    if (this.state.placemarkid)
                        return false;
                    //if (this.state.selectedCollections.length == 0) {
                    //    return true;
                    //}
                    //else {
                    //    let labels = t.assetBeaconTags.map(l => l.tag.name);
                    //    let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                    //    let value = false;
                    //    if (tracker && tracker.collectionId) {
                    //        return this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                    //    }
                    //}

                    if (this.state.assetid) {
                        let labels = t.assetBeaconTags.map(l => l.tag.name);
                        let value = false;
                        if (this.state.selectedCollections.length == 0) {
                            value = true;
                        }
                        else {
                            let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                            if (tracker && tracker.collectionId) {
                                value = this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                            }
                            else {
                                //Asset does not have coll
                                value = this.state.selectedCollections.some(e => e.id == null);
                            }
                        }
                        return t.id == this.state.assetid && value;
                    } else {
                        if (this.state.selectedCollections.length == 0) {
                            return true;
                        }
                        else {
                            let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                            if (tracker && tracker.collectionId) {
                                return this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                            }
                            else {
                                //Asset does not have coll
                                return this.state.selectedCollections.some(e => e.id == null);
                            }
                        }
                    }
                } else {
                    if (this.state.showUnlinkedTags && !this.props.associationsdata.find(f => f.beaconId == t.id).assetId) return true;
                    return false;
                }
            }
            else {
                if (this.state.showControlTags) {
                    if (this.state.placemarkid)
                        return false;
                    if (this.state.assetid) {
                        let value = false;
                        if (this.state.selectedCollections.length == 0) {
                            value = true;
                        }
                        else {
                            let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                            if (tracker && tracker.collectionId) {
                                value = this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                            }
                            else {
                                //Asset does not have coll
                                value = this.state.selectedCollections.some(e => e.id == null);
                            }
                        }
                        return t.id == this.state.assetid && value;
                    } else {
                        if (this.state.selectedCollections.length == 0) {
                            return true;
                        }
                        else {
                            let tracker = this.props.trackersdata.find(tr => tr.assetBeaconId == t.id);
                            if (tracker && tracker.collectionId) {
                                return this.state.selectedCollections.some(e => e.id == tracker.collectionId);
                            }
                            else {
                                //Asset does not have coll
                                return this.state.selectedCollections.some(e => e.id == null);
                            }
                        }
                    }
                } else {
                    return false;
                }
            }
        }
        else if (t.hasOwnProperty("isControlTag") == false && this.context.feature.find(f => f.name == "siteMap").isEnabled) {
            console.log("pla1", t, this.state.showPlacemarks);
            //Placemark
            if (this.state.showPlacemarks) {
                if (this.state.assetid)
                    return false;
                if (this.state.placemarkid)
                    return t.meridianId.includes(this.state.placemarkid);
                return true;
            }
            else {
                return false;
            }
        }
        else
            return false;
    }
    onCloseFullScreen() {
        let o = document.getElementById("open");
        let isOpen = o && o.style.borderRadius == "5px 0px 0px 5px" ? true : false;
        this.setState({ isFullScreen: false, showSidePanel: isOpen }, () => {
            if (this.map) {
                this.map.destroy();
                if (this.state.selectedFloorId) {
                    this.setState({ isDestroyed: true }, () =>
                        this.renderMap(this.state.selectedFloorId));
                }
            }
        });
    }


    render() {
        const { assetsdata, /*collectionsdata,*/ departmentsdata, trackersdata, associationsdata, placemarksdata } = this.props;
        //const { buildingsdata, levelsdata } = this.props;
        const { levelsdataLoading, buildingsdataLoading, sitedataLoading, assetsdataLoading, placemarksdataLoading, siteSearch } = this.state;

        if (levelsdataLoading || buildingsdataLoading || sitedataLoading || assetsdataLoading || placemarksdataLoading) {
            return (
                <Box width="100%">
                <Grid fill={true}
                    rows={['xsmall', "auto"]}
                    columns={['100%', 'flex']}
                    areas={[
                        { name: 'header', start: [0, 0], end: [1, 0] },

                        { name: 'main', start: [0, 1], end: [1, 1] }, ,
                        { name: 'side', start: [1, 0], end: [1, 1] },
                    ]}
                >
                    <Box gridArea='header' direction="row"
                        align="center"
                        pad="medium"
                        justify="between"
                        background={constants.BACKGROUND_COLOR}>
                        <Heading level='2' >Live Map</Heading>
                        {!this.state.selectedBuilding && <ReactTooltip id="selectbuilding" place="bottom" type="dark" effect="solid" arrowColor="transparent" />}
                    </Box>
                    <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} margin={this.state.mapVisible ? { "bottom": "157px" } : ""} height="100%">
                        {this.renderActionBar(true)}

                        <Box direction="row" margin={{ "top": "medium" }}>
                            <Box width={"100%"}>
                                <div id="meridian-map1" className="map" style={{ visibility: "hidden", border: "solid 1px #CCCCCC" }} />
                                {!this.state.mapVisible &&
                                    <Box background="#fafafa" /*height="450px"*/ align="center">
                                        <Heading>
                                            {this.HeaderInfo == "" ?
                                                (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                                :
                                                (this.HeaderInfo)
                                            }
                                        </Heading>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Box>
            );
        }
        //e = document.getElementsByClassName('meridian-tag-label-CT-Human');
        //if (e && e.length > 0) {
        //    console.log("halo1", e, e[0]);
        //    for (let i = 0; i < e.length; i++) {
        //        e[i].style.backgroundColor = "red";
        //        e[i].style.backgroundImage = "none";
        //    }
        //}
        //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];

        if (document.getElementById('header')) {
            this.headerHeight = document.getElementById('header').offsetHeight;
        }

        let collectionsdata = this.props.collectionsdata.filter(c => !c.hasOwnProperty("count"));
        const isSiteAdmin = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        console.log("propi", this.props);
        this.props.buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });
        this.props.levelsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });

        let isClearVisible =
            this.state.showPlacemarks == true &&
                this.state.showTags == true &&
                this.state.showControlTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedCollections.length == 0 &&
                this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedDepartment == "" &&
                this.state.selectedStatus == "" &&
                this.state.selectedManufacturer == "" &&
                this.state.selectedModel == "" &&
                this.state.selectedDeviceType == ""
                ? false : true;
        let list = this.mix.filter(al => {
            if (al.meridianId && al.meridianId.includes(this.state.selectedFloorId) || (al.map && al.map.id && al.map.id.includes(this.state.selectedFloorId) && tagsFromStream.includes(al.id))) {
                if (siteSearch) {
                    const regexp = new RegExp(this.state.siteSearch.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                    if (al.name.match(regexp))
                        return true;
                    return false;
                }
                return true;
            }
            return false;
        }).filter(t => this.globalFilters(t));

        let tagOptions = [];
        if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && isSiteAdmin)
            tagOptions = ["Tags", "Control Tags", "Unlinked Tags"];
        else if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled)
            tagOptions = ["Tags"];
        if (this.context.feature.find(f => f.name == "siteMap").isEnabled)
            tagOptions.unshift("Placemarks");

        let selectedTagOptions = [];
        if (this.state.showPlacemarks)
            selectedTagOptions.push("Placemarks");
        if (this.state.showTags)
            selectedTagOptions.push("Tags");
        if (this.state.showControlTags)
            selectedTagOptions.push("Control Tags");
        if (this.state.showUnlinkedTags)
            selectedTagOptions.push("Unlinked Tags");

        return (
            <Box width="100%">
                <Grid fill={true}
                    rows={['xsmall', "auto"]}
                    columns={['100%', 'flex']}
                    areas={[
                        { name: 'header', start: [0, 0], end: [1, 0] },

                        { name: 'main', start: [0, 1], end: [1, 1] }, ,
                        { name: 'side', start: [1, 0], end: [1, 1] },
                    ]}
                >
                    <Box gridArea='header' direction="row"
                        align="center"
                        pad="medium"
                        justify="between"
                        background={constants.BACKGROUND_COLOR}>
                        <Heading level='2' >Live Map</Heading>
                        {!this.state.selectedBuilding && <ReactTooltip id="selectbuilding" place="bottom" type="dark" effect="solid" arrowColor="transparent" />}
                    </Box>
                    <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} margin={this.state.mapVisible ? { "bottom": "157px" } : ""} height="100%">
                        {this.renderActionBar(true)}

                        <Box direction="row" margin={{ "top": "medium" }}>
                            <Box width={this.state.showSidePanel && this.state.mapVisible ? "calc(100% - 400px)" : "100%"}>
                                <div id="meridian-map1" className="map" style={{ visibility: "hidden", border: "solid 1px #CCCCCC" }} />
                                {!this.state.mapVisible &&
                                    <Box background="#fafafa" /*height="450px"*/ align="center">
                                        <Heading>
                                            {this.HeaderInfo == "" ?
                                                (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                                :
                                                (this.HeaderInfo)
                                            }
                                        </Heading>
                                    </Box>
                                }
                            </Box>

                            <Collapsible direction="horizontal" open={this.state.mapVisible && this.state.showSidePanel}>
                                {!this.state.isSidePanelLoading ?
                                    <Box id="sdPnl" /*width="medium"*/ style={{ width: "400px", borderTop: "solid 1px #CCCCCC", borderRight: "solid 1px #CCCCCC", borderBottom: "solid 1px #CCCCCC" }} height={"calc(100vh - 300px - 67px)"}>
                                    <Box /*direction="row"*/ flex={false} pad="small" border={{ color: "#CCCCCC", style: "solid", size: "1px", side: "bottom" }}>
                                        <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                            <TextInput focusIndicator={false} plain placeholder="Search items" value={siteSearch}
                                                onChange={e => {
                                                    //let searchText = e.target.value;
                                                    //const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    //const filtered = this.mix.filter(o => o.name.match(regexp));
                                                    this.setState({ siteSearch: e.target.value });
                                                }} />
                                            {(siteSearch != "" && siteSearch != undefined) ?
                                                <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={siteSearch == ""} onClick={() => this.setState({ siteSearch: "" })} />
                                                :
                                                <Button icon={<Blank />} disabled />}
                                        </StyledBox>
                                        <Box><b>{list.length} {list.length > 1 ? "items" : "item"}</b></Box>
                                    </Box>
                                    {list.length > 0 && <Box style1 style={{ "overflow-y": "auto", "display": "block", cursor: "pointer", background: "none", border: "none" }} /*height={{ max: '600px' }}*/ height="100%">
                                        {list.map(option => {
                                            console.log("ava", option);
                                            let info = "";
                                            let name = "";
                                            if (option.hasOwnProperty("isControlTag")) {
                                                //Tag or Asset
                                                if (RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                                    if (this.props.associationsdata && this.props.associationsdata.length > 0 && !this.props.associationsdata[0].hasOwnProperty("count") &&
                                                        this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(option.id)) {
                                                        //Linked
                                                        info = "Tracker";
                                                        //Get the Tracker Name
                                                        let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                                        let tracker = this.props.trackersdata.find(t => t.id == association.assetId);
                                                        name = tracker ? tracker.name : option.name;
                                                    }
                                                    else {
                                                        //Not Linked, isControlTag will determine it
                                                        info = option.isControlTag ? "Control Tag" : "Unlinked Tag";
                                                        name = option.name;
                                                    }
                                                }
                                                else {
                                                    //Only Linked
                                                    info = "Tracker";
                                                    //Get the Tracker Name
                                                    let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                                    let tracker = this.props.association ? this.props.trackersdata.find(t => t.id == association.assetId) : "";
                                                    name = tracker ? tracker.name : option.name;
                                                }
                                            } else {
                                                //Placemark
                                                info = "Placemark";
                                                if (option.placemarktype == "Desk" || option.teemType == "desk")
                                                    info = "Desk";
                                                else if (option.placemarktype == "Conference Room" || option.teemType == "room")
                                                    info = "Room";
                                                name = option.name;
                                            }
                                            return (
                                                <Box pad="small" justify="between" direction="row"
                                                    background={(option.id == this.state.assetid || option.meridianId == this.state.placemarkid) ? 'brand' : undefined}
                                                    focusIndicator={false}
                                                    onClick={() => {
                                                        if (option.id == this.state.assetid || option.meridianId == this.state.placemarkid) {
                                                            this.setState({ selectedAsset: "", assetid: "", placemarkid: "" }, () => this.renderMap(this.state.selectedFloorId));
                                                        }
                                                        else {
                                                            //this.setState({ selectedAsset: option });
                                                            let element = option;
                                                            if (element) {
                                                                if (element.hasOwnProperty("isControlTag")) {
                                                                    //Tag or Asset
                                                                    const assetid = element.id;
                                                                    const buildingid = element.map.group.id;
                                                                    const mapid = element.map.id;
                                                                    const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                                                    const floorName = this.props.levelsdata.find(l => l.meridianId == mapid);
                                                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                                                    console.log("floors", floors);
                                                                    this.setState({ selectedFloorId: mapid, selectedFloor: floorName, selectedBuilding: buildingName, floors: floors, assetid: assetid, placemarkid: "" }, () => this.renderMap(mapid));
                                                                }
                                                                else {
                                                                    //Placemark
                                                                    const placemarkid = element.meridianId;
                                                                    //const buildingid = element.map.group.id;
                                                                    //const mapid = element.meridianId;
                                                                    //const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                                                    const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
                                                                    const mapid = floor.meridianId;
                                                                    //const buildingName = floor.mergedBuildingName;
                                                                    const buildingName = this.emptyBuildingArr.concat(this.props.buildingsdata).find(b => b.mergedId == floor.mergedBuildingId);
                                                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                                                    console.log("floors", floors);
                                                                    this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                        {name}
                                                    </Box>
                                                    <Box style={{ color: "#6f6f6f" }} flex={false}>{info}</Box>
                                                </Box>);
                                        }
                                        )}
                                    </Box>}
                                    {list.length == 0 &&
                                        <Box>
                                            <Text>
                                                Not found
                                            </Text>
                                        </Box>
                                    }
                                </Box>
                                    :
                                    <Box id="sdPnlLoad" /*width="medium"*/ style={{ width: "400px", borderTop: "solid 1px #CCCCCC", borderRight: "solid 1px #CCCCCC", borderBottom: "solid 1px #CCCCCC" }} height={"calc(100vh - 300px - 67px)"}>
                                        <LoadingIndicator/>
                                    </Box>}
                            </Collapsible>
                        </Box>
                    </Box>
                </Grid>

                {
                    this.state.isFullScreen &&
                    <Grommet theme={customTheme}><Layer
                        //position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        margin={{ "top": this.headerHeight + "px" }}
                        full
                        modal
                        onClickOutside={() => this.onCloseFullScreen()}
                        onEsc={() => this.onCloseFullScreen()}
                    >
                        <Box /*gridArea='main'*/ background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium", top: "medium" }} height="100%" fill="vertical">
                            {this.renderActionBar(false)}
                            <Box direction="row" margin={{ "top": "medium" }}>
                                <Box width={this.state.showSidePanel && this.state.mapVisible ? "calc(100% - 400px)" : "100%"}>
                                    <div id="meridian-map2" className="map" style={{ visibility: "hidden", border: "solid 1px #CCCCCC" }} />
                                    {!this.state.mapVisible &&
                                        <Box background="#fafafa" /*height="450px"*/ align="center">
                                            <Heading>
                                                {this.HeaderInfo == "" ?
                                                    (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                                    :
                                                    (this.HeaderInfo)
                                                }
                                            </Heading>
                                        </Box>
                                    }
                                </Box>


                                <Collapsible direction="horizontal" open={this.state.mapVisible && this.state.showSidePanel}>
                                    {!this.state.isSidePanelLoading ? <Box /*width="medium"*/ style={{ width: "400px", borderTop: "solid 1px #CCCCCC", borderRight: "solid 1px #CCCCCC", borderBottom: "solid 1px #CCCCCC" }} height={"calc(100vh - 100px - 75px)"}>
                                        <Box /*direction="row"*/ flex={false} pad="small" border={{ color: "#CCCCCC", style: "solid", size: "1px", side: "bottom" }}>
                                            <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                <TextInput focusIndicator={false} plain placeholder="Search items" value={siteSearch}
                                                    onChange={e => {
                                                        //let searchText = e.target.value;
                                                        //const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        //const filtered = this.mix.filter(o => o.name.match(regexp));
                                                        this.setState({ siteSearch: e.target.value });
                                                    }} />
                                                {(siteSearch != "" && siteSearch != undefined) ?
                                                    <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={siteSearch == ""} onClick={() => this.setState({ siteSearch: "" })} />
                                                    :
                                                    <Button icon={<Blank />} disabled />}
                                            </StyledBox>
                                            <Box><b>{list.length} {list.length > 1 ? "items" : "item"}</b></Box>
                                        </Box>
                                        {/*this.state.assetList*/list.length > 0 && <Box style1/*height={{ max: 'medium' }}*/ style={{ "overflow-y": "auto", "display": "block", cursor: "pointer", border: "none", background: "none" }} height="100%" /*fill="vertical"*/>
                                            {list.map(option => {
                                                console.log("ava", option);
                                                let info = "";
                                                let name = "";
                                                if (option.hasOwnProperty("isControlTag")) {
                                                    //Tag or Asset
                                                    if (RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                                        if (this.props.associationsdata && this.props.associationsdata.length > 0 && !this.props.associationsdata[0].hasOwnProperty("count") &&
                                                            this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(option.id)) {
                                                            //Linked
                                                            info = "Tracker";
                                                            //Get the Tracker Name
                                                            let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                                            let tracker = this.props.trackersdata.find(t => t.id == association.assetId);
                                                            name = tracker ? tracker.name : option.name;
                                                        }
                                                        else {
                                                            //Not Linked, isControlTag will determine it
                                                            info = option.isControlTag ? "Control Tag" : "Unlinked Tag";
                                                            name = option.name;
                                                        }
                                                    }
                                                    else {
                                                        //Only Linked
                                                        info = "Tracker";
                                                        //Get the Tracker Name
                                                        let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                                        let tracker = this.props.association ? this.props.trackersdata.find(t => t.id == association.assetId) : "";
                                                        name = tracker ? tracker.name : option.name;
                                                    }
                                                } else {
                                                    //Placemark
                                                    info = "Placemark";
                                                    if (option.placemarktype == "Desk" || option.teemType == "desk")
                                                        info = "Desk";
                                                    else if (option.placemarktype == "Conference Room" || option.teemType == "room")
                                                        info = "Room";
                                                    name = option.name;
                                                }
                                                return (
                                                    <Box pad="small" justify="between" direction="row"
                                                        background={(option.id == this.state.assetid || option.meridianId == this.state.placemarkid) ? 'brand' : undefined}
                                                        focusIndicator={false}
                                                        onClick={() => {
                                                            if (option.id == this.state.assetid || option.meridianId == this.state.placemarkid) {
                                                                this.setState({ selectedAsset: "", assetid: "", placemarkid: "" }, () => this.renderMap(this.state.selectedFloorId));
                                                            }
                                                            else {
                                                                //this.setState({ selectedAsset: option });
                                                                let element = option;
                                                                if (element) {
                                                                    if (element.hasOwnProperty("isControlTag")) {
                                                                        //Tag or Asset
                                                                        const assetid = element.id;
                                                                        const buildingid = element.map.group.id;
                                                                        const mapid = element.map.id;
                                                                        const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                                                        const floorName = this.props.levelsdata.find(l => l.meridianId == mapid);
                                                                        const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                                                        console.log("floors", floors);
                                                                        this.setState({ selectedFloorId: mapid, selectedFloor: floorName, selectedBuilding: buildingName, floors: floors, assetid: assetid, placemarkid: "" }, () => this.renderMap(mapid));
                                                                    }
                                                                    else {
                                                                        //Placemark
                                                                        const placemarkid = element.meridianId;
                                                                        //const buildingid = element.map.group.id;
                                                                        //const mapid = element.meridianId;
                                                                        //const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                                                        const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
                                                                        const mapid = floor.meridianId;
                                                                        //const buildingName = floor.mergedBuildingName;
                                                                        const buildingName = this.emptyBuildingArr.concat(this.props.buildingsdata).find(b => b.mergedId == floor.mergedBuildingId);
                                                                        const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                                                        console.log("floors", floors);
                                                                        this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                            {name}
                                                        </Box>
                                                        <Box style={{ color: "#6f6f6f" }} flex={false}>{info}</Box>
                                                    </Box>);
                                            }
                                            )}
                                        </Box>}
                                        {list.length == 0 &&
                                            <Box>
                                                <Text>
                                                    Not found
                                                </Text>
                                            </Box>
                                        }
                                    </Box>
                                        :
                                        <Box /*width="medium"*/ style={{ width: "400px", borderTop: "solid 1px #CCCCCC", borderRight: "solid 1px #CCCCCC", borderBottom: "solid 1px #CCCCCC" }} height={"calc(100vh - 100px - 75px)"}>
                                            <LoadingIndicator />
                                        </Box>
                                        }
                                </Collapsible>

                            </Box>
                        </Box>
                        {!this.state.selectedBuilding && <ReactTooltip id="selectbuildingfull" place="bottom" type="dark" effect="solid" arrowColor="transparent" />}
                    </Layer></Grommet>
                }



                {this.state.showFilter && (
                    <Layer margin={{ "top": this.headerHeight + "px" }}
                        position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        full={/*size !== 'small' ? 'vertical' : true*/"vertical"}
                        modal
                        onClickOutside={() => this.setState({ showFilter: false })}
                        onEsc={() => this.setState({ showFilter: false })}
                    >
                        <Box
                            flex
                            overflow="auto"
                            width={{ min: 'medium' }}
                            pad={{ horizontal: 'medium', vertical: 'medium' }}
                            //gap="small"
                            fill="vertical"
                        >
                            <Header>
                                <Heading as="p" color="text-strong" margin="none">
                                    Filters
                                </Heading>
                                <Box>
                                    <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ showFilter: false })}
                                    />
                                </Box>
                            </Header>
                            <form>
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Department">
                                            {departmentsdata.length > 0 &&
                                                <SelectMultiple
                                                    placeholder="Select"
                                                    options={departmentsdata.filter(d => !d.hasOwnProperty("count")).sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0).concat([{ id: null, name: "", labelName: "" }])}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    value={this.state.selectedDepartment}
                                                    onChange={evt => {
                                                        let trackers = trackersdata.filter(t => evt.value.some(d => d.id == t.departmentId));
                                                        if (trackers) {
                                                            this.setState({ departments: trackers.map(l => l.assetBeaconId) }, () => {
                                                                if (this.state.selectedFloorId) {
                                                                    this.renderMap(this.state.selectedFloorId);
                                                                }
                                                            });
                                                        } else {
                                                            this.setState({ departments: [] });
                                                        }
                                                        this.setState({ selectedDepartment: evt.value });
                                                    }}
                                                    sortSelectedOnClose={false}
                                                    showSelectedInline
                                                />}
                                            {departmentsdata.length == 0 &&
                                                <Select
                                                    placeholder="Select"
                                                    labelKey="name"
                                                    valueKey="id"
                                                    options={[]}
                                                    value={this.state.selectedDepartment}
                                                />}
                                            </FormField>
                                        </Grommet>
                                        {this.state.selectedDepartment.length > 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedDepartment: "", departments: [] }, () => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId);
                                                }
                                            })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Collection">
                                                {collectionsdata.length > 0 &&
                                                    <SelectMultiple
                                                        placeholder="Select"
                                                        labelKey="name"
                                                        valueKey="id"
                                                        options={collectionsdata.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0).concat([{ id: null, name: "", labelName: null }])}
                                                        value={this.state.selectedCollections}
                                                        onChange={o => {
                                                            let tmp = o.value;
                                                            //let tmp = [...this.state.selectedCollections];
                                                            //if (o.option.length > 1) {
                                                            //    if (tmp.length > 0) {
                                                            //        tmp = [];
                                                            //    } else if (tmp.length == 0) {
                                                            //        tmp = o.option;
                                                            //    }
                                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                                            //} else {
                                                            //    tmp.push(o.option);
                                                            //}
                                                            this.setState({ selectedCollections: tmp }, () => {
                                                                if (this.state.selectedFloorId) {
                                                                    this.renderMap(this.state.selectedFloorId);
                                                                }
                                                            });
                                                        }}
                                                        sortSelectedOnClose={false}
                                                        showSelectedInline
                                                    />}
                                                {collectionsdata.length == 0 &&
                                                    <Select
                                                        placeholder="Select"
                                                        labelKey="name"
                                                        valueKey="id"
                                                        options={collectionsdata}
                                                        value={this.state.selectedCollections}
                                                    />}
                                            </FormField>
                                        </Grommet>
                                        {!this.state.selectedCollections.length == 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedCollections: [] })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }

                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Manufacturer">
                                                <SelectMultiple
                                                    placeholder="Select"
                                                    value={this.state.selectedManufacturer}
                                                    options={this.props.manufacturersdata.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])}
                                                    onChange={evt => {
                                                        let modelSuggestions = this.props.metadatas.filter(m => evt.value.some(v => v.id == m.manufacturerId) && m.modelId).sort(constants.byPropertyCalled).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                        let typeSuggestions = this.props.metadatas.filter(m => {
                                                            if (evt.value.some(v => v.id == m.manufacturerId) && m.deviceTypeId) {
                                                                if (this.state.modelId) {
                                                                    if (m.modelId == this.state.modelId)
                                                                        return m;
                                                                    return;
                                                                }
                                                                else {
                                                                    if (m.modelId == null)
                                                                        return m;
                                                                    return;
                                                                }
                                                                return;
                                                            }
                                                            return;
                                                        })
                                                            .sort(constants.byPropertyCalled)
                                                            .map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                        this.setState({ selectedManufacturer: evt.value, modelSuggestions, typeSuggestions }, () => {
                                                            if (this.state.selectedFloorId) {
                                                                this.renderMap(this.state.selectedFloorId);
                                                            }
                                                        });
                                                    }}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    sortSelectedOnClose={false}
                                                    showSelectedInline
                                                />
                                            </FormField>
                                        </Grommet>
                                        {this.state.selectedManufacturer.length > 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedManufacturer: [], selectedModel: [], selectedDeviceType: [], modelSuggestions: [], typeSuggestions: [] })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Model">
                                            {this.state.modelSuggestions.length > 0 &&
                                                <SelectMultiple
                                                    placeholder="Select"
                                                    labelKey="name"
                                                    valueKey="id"
                                                    value={this.state.selectedModel}
                                                    options={this.state.modelSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.modelsdata*/}
                                                    onChange={o => {
                                                        let tmp = o.value;
                                                        let typeSuggestions = this.props.metadatas.filter(m =>
                                                            //m.manufacturerId == this.state.manufacturerId && m.modelId == o.option.id
                                                            this.state.selectedManufacturer.some(v => v.id == m.manufacturerId) && tmp.some(v => v.id == m.modelId)
                                                            && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                                        this.setState({ selectedModel: tmp, typeSuggestions, selectedDeviceType: [] }, () => {
                                                            if (this.state.selectedFloorId) {
                                                                this.renderMap(this.state.selectedFloorId);
                                                            }
                                                        });
                                                    }}
                                                    sortSelectedOnClose={false}
                                                    showSelectedInline
                                                />}
                                            {this.state.modelSuggestions.length == 0 &&
                                                <Select placeholder="Select" options={[]}
                                                />}
                                            </FormField>
                                        </Grommet>
                                        {this.state.selectedModel.length > 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedModel: [], selectedDeviceType: [] })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Model Type">
                                            {this.state.typeSuggestions.length > 0 &&
                                                <SelectMultiple
                                                    placeholder="Select"
                                                    labelKey="name"
                                                    valueKey="id"
                                                    placeholder="Select"
                                                    value={this.state.selectedDeviceType}
                                                    options={this.state.typeSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.typesdata*/}
                                                    onChange={o => {
                                                        let tmp = o.value;
                                                        this.setState({ selectedDeviceType: tmp }, () => {
                                                            if (this.state.selectedFloorId) {
                                                                this.renderMap(this.state.selectedFloorId);
                                                            }
                                                        });
                                                    }}
                                                    sortSelectedOnClose={false}
                                                    showSelectedInline
                                                />}
                                            {this.state.typeSuggestions.length == 0 &&
                                                <Select placeholder="Select" options={[]} />}
                                            </FormField>
                                        </Grommet>
                                        {this.state.selectedDeviceType.length > 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedDeviceType: [] })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }

                            {this.context.feature.find(f => f.name == "siteWellness").isEnabled && this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Status">
                                            <CheckBoxGroup
                                                value={this.state.selectedStatus}
                                                labelKey="name"
                                                valueKey="id"
                                                options={constants.ASSET_STATUS}
                                                onChange={evt => {
                                                    let list = this.props.assetsdata.filter(a => evt.value.includes(a.serviceStateId));
                                                    this.setState({ selectedStatus: evt.value, assetsOnMap: list, statuses: list.map(l => l.id) }, () => {
                                                        if (this.state.selectedFloorId) {
                                                            this.renderMap(this.state.selectedFloorId);
                                                        }
                                                    });
                                                }}
                                            />
                                            </FormField>
                                        </Grommet>
                                        {this.state.selectedStatus.length > 0 && <Button
                                            style={{ "margin-top": "30px" }}
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedStatus: [], statuses: [] }, () => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId);
                                                }
                                            })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }

                            {/*this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled*/ tagOptions.length > 0 &&
                                <StyledBox>
                                    <Box direction="row" justify="between">
                                        <Grommet theme={hpe} style={{width: "80%"}}>
                                            <FormField label="Map Markers">
                                                <CheckBoxGroup
                                                    options={tagOptions}
                                                    value={selectedTagOptions}
                                                    onChange={evt => {
                                                        if (evt.option.label == "Placemarks") {
                                                            this.setState({ showPlacemarks: !this.state.showPlacemarks }, () => {
                                                                if (this.state.selectedFloorId)
                                                                    this.renderMap(this.state.selectedFloorId);
                                                            });
                                                        }
                                                        if (evt.option.label == "Tags") {
                                                            this.setState({ showTags: !this.state.showTags }, () => {
                                                                if (this.state.selectedFloorId)
                                                                    this.renderMap(this.state.selectedFloorId);
                                                            });
                                                        }
                                                        else if (evt.option.label == "Control Tags") {
                                                            this.setState({ showControlTags: !this.state.showControlTags }, () => {
                                                                if (this.state.selectedFloorId)
                                                                    this.renderMap(this.state.selectedFloorId);
                                                            });
                                                        }
                                                        else if (evt.option.label == "Unlinked Tags") {
                                                            let list = [];
                                                            if (evt.target.checked) {
                                                                list = this.props.assetsdata;
                                                            }
                                                            else {
                                                                list = this.props.assetsdata.filter(at => this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(at.id) || at.isControlTag);
                                                            }
                                                            console.log("unlink", list, this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId));
                                                            this.setState({ showUnlinkedTags: !this.state.showUnlinkedTags, assetsOnMap: list, unlinks: list.map(l => l.id) }, () => {
                                                                if (this.state.selectedFloorId)
                                                                    this.renderMap(this.state.selectedFloorId);
                                                            });
                                                        }
                                                    }}
                                                />
                                            </FormField>
                                        </Grommet>
                                    </Box>
                                </StyledBox>
                            }

                            {false && <Box width="80%" >
                                <Grommet theme={placemarkToggle}>
                                    <FormField noBorder label={
                                        <Box direction="row" justify="between" align="center">
                                            Show Placemarks
                                            <CheckBox checked={this.state.showPlacemarks} toggle onChange={() =>
                                                this.setState({ showPlacemarks: !this.state.showPlacemarks },
                                                    () => {
                                                        if (this.state.selectedFloorId)
                                                            this.renderMap(this.state.selectedFloorId);
                                                    }
                                                )} />
                                        </Box>
                                    } />
                                </Grommet>
                            </Box>}
                            <Box justify="between" direction="row" margin={{top: "6px"}}>
                                <StyledButton typeAdd label="Reset Filter" onClick={this.resetFilter} />
                            </Box>
                                </form>
                        </Box>
                    </Layer>
                )}
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
    sitedata: state.selectedSiteReducer.sitedata,
    assetsdata: state.assetsReducer.assetsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    associationsdata: state.associationsReducer.associationsdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    trackersdata: state.trackersReducer.trackersdata,
    placemarksdata: state.placemarksReducer.placemarksdata,
    manufacturersdata: state.manufacturersReducer.manufacturersdata,
    modelsdata: state.modelsReducer.modelsdata,
    typesdata: state.typesReducer.typesdata,
    metadatas: state.metadatasReducer.metadatas
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...LevelAction, ...BuildingAction, ...SiteAction, ...AssetAction, ...CollectionAction, ...AssociationAction, ...DepartmentAction, ...TrackerAction, ...PlacemarkAction, ...ManufacturerAction, ...ModelAction, ...TypeAction, ...MetaDataAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeridianMap));