import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedMetadataReducer = (state = initialState.selectedMetadataReducer, action) => {
    switch(action.type) {
        case ActionType.GET_METADATA_RESPONSE: {
            return {
                ...state,
                metadata: _.assign(action.metadata)
            };
        }

        default: { return state; }
    }
};


export default selectedMetadataReducer;