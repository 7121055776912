import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactiveCheckBoxes from './common/CheckBoxFilters';

const PaginatedDataTable = withPagination(DataTable);
//const CorrectedDataTable = withCorrectedSearch(DataTable);

class SessionListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.timeRanges = {
            boxes: ['Current', 'Upcoming', 'Past'],
            default: ['Current', 'Upcoming', 'Past'],
        };
        this.state = {
            timeFilters: this.timeRanges.default,
            search: "",
        };
    }

    timeFilterEvent(event) {
        if (this.state.timeFilters.length === 3) {
            return true;
        }
        const n = new Date();
        const now = new Date(this.timeFilterConversion(n));
        const start = new Date(event.startdate.slice(0, -1));
        const end = new Date(event.enddate.slice(0, -1));
        const filters = this.state.timeFilters;
        let validDate = false;
        if (filters.includes("Past")) {
            validDate = validDate || end < now;
        }
        if (filters.includes("Current")) {
            validDate = validDate || (start <= now && now <= end);
        }
        if (filters.includes("Upcoming")) {
            validDate = validDate || now < start;
        }
        return validDate;
    }

    timeFilterConversion(date) {
        const d = date.toLocaleString('en-GB', { timeZone: this.props.sitedata.ianaTimeZone });
        const ld = d.split(", ")[0];
        const lt = d.split(", ")[1];
        return ld.split("/")[2] + "-" + ld.split("/")[1] + "-" + ld.split("/")[0] + "T" + lt;
    }

    //componentDidMount() {
    //    this.onDTUpdated();
    //}
    //componentDidUpdate() {
    //    this.onDTUpdated();
    //}

    //externalFunc = (evt) => {
    //    let element = evt.target;
    //    //Search for the TR (table-row) in the hierarchy to get the item's ID
    //    while (element.nodeName != "TR") {
    //        if (element.parentElement) {
    //            element = element.parentElement;
    //        } else {
    //            //we have problem
    //            break;
    //        }
    //    }
    //    let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
    //    if (id != null) {
    //        let hE = this.props.handleEdit;
    //        hE(evt, id);
    //    }
    //}

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    renderEventsTable(props, timeRanges, timeFilterEvent) {

        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        //const siteLocalTimeZone = this.props.sitedata.timeZone ? this.props.sitedata.timeZone.replace("Standard Time", "ST") : "UTC ST";

        const COLUMNS = [
            {
                property: 'lccode',
                header: 'Code',
                render: sessiondata => <Text>{sessiondata.code}</Text>
            },
            {
                property: 'lctitle',
                header: 'Title',
                render: sessiondata => <Text>{sessiondata.title}</Text>
            },
            {
                property: 'lcname',
                header: 'Name',
                //size: "xsmall",
                render: sessiondata => <Text>{sessiondata.name}</Text>
            },
            {
                property: 'lcdescription',
                header: 'Description',
                size: "medium",
                render: sessiondata => <Text>{sessiondata.description}</Text>
            },
            {
                property: 'type',
                header: 'Type',
                render: sessiondata => <Text>{sessiondata.type}</Text>
            },
            {
                property: 'lcroom',
                header: 'Room',
                render: sessiondata => <Text>{sessiondata.room/*sessiondata.sessionTimes ? sessiondata.sessionTimes[0].room: "" */}</Text>
            },
            {
                property: 'view',
                header: 'Can View',
                render: sessiondata => <CheckBox checked={sessiondata.view} />
            },
            {
                property: 'schedule',
                header: 'Can Schedule',
                render: sessiondata => <CheckBox checked={sessiondata.schedule} />
            },
            {
                property: 'attend',
                header: 'Can Attend',
                render: sessiondata => <CheckBox checked={sessiondata.attend} />
            },
            //{
            //    property: 'startdate',
            //    header: <Box justify="center" height="100%">{`Start (${siteLocalTimeZone})`}</Box>,
            //    render: sessiondata => customDateFormatter.format(Date.parse(sessiondata.startdate.slice(0, -1))),
            //},
            //{
            //    property: 'enddate',
            //    header: <Box justify="center" height="100%">{`End (${siteLocalTimeZone})`}</Box>,
            //    render: sessiondata => customDateFormatter.format(Date.parse(sessiondata.enddate.slice(0, -1))),
            //},
            //{
            //    property: 'idtag',
            //    header: '',
            //    primary: true,
            //    size: "0px",
            //    plain: true,
            //    render: sessiondata => <Text hidden>{sessiondata.id}</Text>,
            //},
            //{
            //    sortable: false,
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    size: "24px",
            //    plain: true,
            //    render: sessiondata => !RB.canEdit(constants.PERMS.LOC_EVENTS) ?
            //        <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, sessiondata.id)} icon={<Blank />} /></Box>
            //        : <Box width="24px" direction='row' gap="xsmall">
            //            {/*<Button plain disabled={props.deletedEventIds.includes(sessiondata.id)} onClick={evt => props.handleEdit(evt, sessiondata.id)} icon={<Edit />} />*/}
            //            <Button plain disabled={props.deletedEventIds.includes(sessiondata.id)} onClick={evt => props.handleDelete(evt, sessiondata.id)} icon={<Trash />} />
            //        </Box>,
            //},
        ];

        let DATA = [];
        const weekday = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        if (props.sessionsdata[0] && props.sessionsdata[0].count != 0) {
            props.sessionsdata/*.filter(timeFilterEvent)*/.forEach(ev => {
                if (ev.sessionTimes && ev.sessionTimes.length > 0) {
                    ev.sessionTimes.forEach(t => {
                        let name = ev.code;
                        let startDate = constants.convertDate(t.startDateTime, -t.utcOffset);
                        let subtitle = t.room + " | " + t.startDateTime.substring(11, 16) + " - " + t.endDateTime.substring(11, 16);
                        name += " - " + startDate + " - " + ev.title + " - " + weekday[t.dayOfWeek];
                        DATA.push({
                            id: t.id,
                            sid: ev.id,
                            name: name,
                            title: ev.title,
                            room: t.room,
                            subtitle: subtitle,
                            description: ev.description,
                            code: ev.code,
                            type: ev.type,
                            startDateTime: t.startDateTime,

                            lccode: ev.code ? ev.code.toLowerCase() : '',
                            lctitle: ev.title ? ev.title.toLowerCase() : '',
                            lcdescription: ev.description ? ev.description.toLowerCase() : '',
                            lcroom: t.room.toLowerCase(),
                            lcname: name ? name.toLowerCase() : '',
                            view: false,
                            schedule: false,
                            attend: false

                        });
                    })
                }
                else {
                    DATA.push({
                        id: null,
                        sid: ev.id,
                        name: ev.title,
                        title: ev.title,
                        room: '',
                        subtitle: "",
                        description: ev.description,
                        code: ev.code,
                        type: ev.type,
                        startDateTime: "",

                        lccode: ev.code ? ev.code.toLowerCase() : '',
                        lctitle: ev.title ? ev.title.toLowerCase() : '',
                        lcdescription: ev.description ? ev.description.toLowerCase() : '',
                        lcroom: '',
                        lcname: ev.title ? ev.title.toLowerCase() : '',
                        view: false,
                        schedule: false,
                        attend: false
                    });
                }



                //DATA.push({
                //    ...ev,
                //    lccode: ev.code ? ev.code.toLowerCase() : '',
                //    lctitle: ev.title ? ev.title.toLowerCase() : '',
                //    lcdescription: ev.description ? ev.description.toLowerCase() : '',
                //    lcroom: ev.sessionTimes[0].room ? ev.sessionTimes[0].room.toLowerCase() : '',
                //    //tags: (ev.tagfeatured ? 'Featured ' : ev.tagalert ? 'Alert' : ev.tagbreaking ? 'Breaking' : '') + (ev.tagemployee ? 'Employee Only' : '')
                //})
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.lccode.includes(search) ||
                d.type.toLowerCase().includes(search) ||
                d.lctitle.includes(search) ||
                d.lcdescription.includes(search) ||
                d.lcroom.includes(search) ||
                d.lcname.includes(search)
                //customDateFormatter.format(Date.parse(d.startdate.slice(0, -1))).toString().toLowerCase().includes(search) ||
                //customDateFormatter.format(Date.parse(d.enddate.slice(0, -1))).toString().toLowerCase().includes(search) ||
                //("featured".includes(search) && d.tagfeatured) ||
                //("employee only".includes(search) && d.tagemployee)
            );
        }

        console.log('DATA:', DATA);

        //if (DATA) {
        //    DATA.sort((a, b) => {
        //        return new Date(b.enddate) - new Date(a.enddate);
        //    });
        //}

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctitle" || c.property === "lcorganiser" || c.property === "lclocation"
                }))}
                data={DATA.sort(constants.multipleSortObject(["startDateTime", "title"]))}
                //data={DATA.sort(constants.multipleSortObject(["code", "title", { name: "startDateTime", order: "asc" }], "desc")/*.doubleSort("startDateTime", "title")*/)}
                noLoadingMsg={this.props.sessionsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lctitle', label: 'Title' },
                //    { col: 'lcorganiser', label: 'Organiser' },
                //    { col: 'lclocation', label: 'Location' },
                //    { col: 'startdate', label: 'Start Date' },
                //    { col: 'enddate', label: 'End Date' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={1}
                PFcsvdata={DATA.map(d =>
                ({
                    Code: d.code,
                    Title: d.title,
                    Name: d.name,
                    Description: d.description,
                    Type: d.type,
                    Room: d.room,
                    "Can View": d.view ? "TRUE" : "FALSE",
                    "Can Schedule": d.schedule ? "TRUE" : "FALSE",
                    "Can Attend": d.attend ? "TRUE" : "FALSE"
                }))}
                PFcsvfilename="sessions-export"
            />
        );
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Sessions </Heading>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "870px" }}>
                            {this.renderEventsTable(this.props, this.timeRanges, this.timeFilterEvent.bind(this))}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }
}

export default SessionListTable;
