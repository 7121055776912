import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Grid, Box, Heading, DataTable } from 'grommet';
import AppInsightsApi from '../api/AppInsightsApi';
import NotificationApi from '../api/NotificationApi';
import RB from './common/RBAC';
import { AnalyticsContext } from './AnalyticsFilter';
import { Context } from './Context';
import { StyledBox } from './common/StyledBox';
import Card from './common/Card';
import constants from '../constants';

const AnalyticsNotifications = () => {
    const [notificationsdata, setNotificationsdata] = useState([]);

    const { time, site, targetOS, version, count, versions } = useContext(AnalyticsContext);
    const { feature } = useContext(Context);

    const isNumber = (value) => {
        return typeof value === 'number' && isFinite(value);
    }

    function getTimespan() {

        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    useEffect(() => {
        //CARDS
        let isSuperUser = RB.isSuperUser();
        NotificationApi.getNotifications()
            .then(data => {
                setNotificationsdata(data.filter(n => n.createdAt && new Date(n.createdAt) < new Date()));
            })
            .catch(err => console.log("err"));
    }, [])

    const bySiteVersionTarget = (item) => {
        let isDateOK = true;
        //Check Date
        if (item.createdAt) {
            let now = Date.now();
            let d = getTimespan().slice(0, -1);
            let from = new Date(now - 1000 * 60 * 60 * 24 * d);
            let notiDate = new Date(item.createdAt);
            isDateOK = from < notiDate ? true : false;
        }
        else {
            isDateOK = false;
        }


        if (item.tags) {
            let isSiteOK = true;
            let isVersionOK = true;
            let isTargetOK = true;

            let tags = item.tags;

            //Check Site
            if (tags.includes("loc:")) {
                if (site == "" || site == "All")
                    isSiteOK = true;
                else {
                    let sites = tags.split(" ").filter(s => s.includes("loc:"));
                    let selectedSite = sites.find(s => s.substring(4) == site);
                    isSiteOK = selectedSite ? true : false;
                }

            }
            //Check Version
            if (tags.includes("ver:")) {
                if (versions.length == 0)
                    isVersionOK = true;
                else {
                    let selectedVersions = tags.split(" ").filter(s => s.includes("ver:"));
                    let atLeastOne = false;
                    selectedVersions.forEach(sv => {
                        if (versions.some(v => v == sv)) {
                            atLeastOne = true;
                            //break;
                        }
                    });
                    isVersionOK = atLeastOne;
                }
            }
            //Check Target
            if (tags.includes("plt:")) {
                if (
                    (tags.includes("plt:android") && (targetOS == "All" || targetOS == "Android")) ||
                    (tags.includes("plt:ios") && (targetOS == "All" || targetOS == "iOS"))
                ) {
                    isTargetOK = true;

                } else {
                    isTargetOK = false;
                }
            }
            return isDateOK && isSiteOK && isVersionOK && isTargetOK;
        }
        else {
            return isDateOK;
        }
    }

    const customDateFormatter = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });

    return (
        <React.Fragment>
            {feature.find(f => f.name == "notification").isEnabled &&
                <StyledBox style1>
                    <Box justify="between"
                        pad={{ "top": "small", "bottom": "small", "left": "small", "right": "small" }}
                        margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }}

                        round="xsmall"
                        basis="50%">
                        <Box height="15%" direction="column-responsive" justify="between" margin={{ "bottom": "none" }}>
                            <Heading level='3' margin={{ "top": "xsmall", "left": "none", "right": "none" }}>
                                Notifications
                            </Heading>
                        </Box>
                        <Box height="85%" margin={{ "top": "none", "bottom": "none", "left": "none", "right": "none" }} gap="small">
                            <Grid
                                //margin={{ "top": "none", "bottom": "small", "left": "small", "right": "small" }}
                                columns='200px'
                                gap="xsmall"
                            >
                                <Card header="Device Audience" value={constants.convertToK(notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.device, 0))} />
                                <Card header="Device Sent" value={constants.convertToK(notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.success, 0))} />
                                <Card header="User Audience" value={constants.convertToK(notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.audience, 0))} />
                                <Card header="User Read" value={constants.convertToK(notificationsdata.filter(n => bySiteVersionTarget(n)).reduce((acc, curr) => acc + curr.read, 0))} />
                            </Grid>
                            <StyledBox style1 direction="row" pad={{ top: "small" }}>
                                <Box width="100%">
                                    <Box /*height="15%"*/ direction="column-responsive" justify="between" margin={{ "left": "small" }}>
                                        <Heading level='3' margin={{ "top": "xsmall", "left": "none", "right": "none" }}>
                                            Top 5 Sent
                                        </Heading>
                                    </Box>
                                    <Box height="85%">
                                        <DataTable
                                            border={top}
                                            columns={[
                                                {
                                                    property: 'title',
                                                    header: <b>Title</b>,
                                                },
                                                {
                                                    property: 'device',
                                                    header: <b>Devices</b>,
                                                },
                                                {
                                                    primary: true,
                                                    property: 'createdAt',
                                                    header: <b>Date & Time (UTC)</b>,
                                                    render: d => {
                                                        let date = d.schudule ? d.schudule : d.createdAt;
                                                        return customDateFormatter.format(Date.parse(date.slice(0, -1)))
                                                    }
                                                },
                                            ]}
                                            data={notificationsdata
                                                .filter(n => bySiteVersionTarget(n))
                                                .sort((a, b) => {
                                                    const nameA = a.device;
                                                    const nameB = b.device;
                                                    if (nameA < nameB) {
                                                        return 1;
                                                    }
                                                    if (nameA > nameB) {
                                                        return -1;
                                                    }
                                                    //equals
                                                    if (a.createdAt < b.createdAt)
                                                        return 1;
                                                    if (a.createdAt > b.createdAt)
                                                        return -1;
                                                    return 0;
                                                })
                                                .slice(0, 5)
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box id="devider" margin={{ horizontal: "small" }} style={{ "border-left": "1px solid #ccc" }} />
                                <Box width="100%">
                                    <Box /*height="15%"*/ direction="column-responsive" justify="between" margin={{ "left": "small" }}>
                                        <Heading level='3' margin={{ "top": "xsmall", "left": "none", "right": "none" }}>
                                            Top 5 Read
                                        </Heading>
                                    </Box>
                                    <Box height="85%">
                                        <DataTable
                                            border={top}
                                            columns={[
                                                {
                                                    property: 'title',
                                                    header: <b>Title</b>,
                                                },
                                                {
                                                    property: 'read',
                                                    header: <b>Users</b>,
                                                },
                                                {
                                                    primary: true,
                                                    property: 'createdAt',
                                                    header: <b>Date & Time (UTC)</b>,
                                                    render: d => {
                                                        let date = d.schudule ? d.schudule : d.createdAt;
                                                        return customDateFormatter.format(Date.parse(date.slice(0, -1)))
                                                    }
                                                },
                                            ]}
                                            data={
                                                notificationsdata
                                                    .filter(not => bySiteVersionTarget(not))
                                                    .sort((a, b) => {
                                                        const nameA = a.read;
                                                        const nameB = b.read;
                                                        if (nameA < nameB) {
                                                            return 1;
                                                        }
                                                        if (nameA > nameB) {
                                                            return -1;
                                                        }
                                                        //equals
                                                        if (a.createdAt < b.createdAt)
                                                            return 1;
                                                        if (a.createdAt > b.createdAt)
                                                            return -1;
                                                        return 0;
                                                    })
                                                    .slice(0, 5)
                                            }
                                        />
                                    </Box>
                                </Box>
                            </StyledBox>
                        </Box>
                    </Box>
                </StyledBox>
            }
        </React.Fragment>
    );
}

export default AnalyticsNotifications;