import { useState, useEffect, createContext, useRef } from 'react';
import { Select, Box, Heading, Grid, Button, DataTable, Tabs, Tab, Text, FormField, SelectMultiple } from 'grommet';
import AppInsightsApi from '../api/AppInsightsApi';
import SiteApi from '../api/SiteApi';
import * as SiteAction from '../action/SiteAction';
import { StyledBox } from './common/StyledBox';
import constants from '../constants';
//import AnalyticsContext from './AnalyticsContext';

export const AnalyticsContext = createContext({ time: "Last 30 Days", site: { name: "All", code: "" }, targetOS: "All", version: "All", count: "All", axesColor: "", versions: []});
const AnalyticsFilter = (props) => {
    const [time, setTime] = useState("Last 30 Days");
    const [siteLoading, setSiteLoading] = useState(true);
    const [site, setSite] = useState({ name: "All", code: "" });
    const [versionsList, setVersionsList] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [version, setVersion] = useState([]);
    const [targetOS, setTargetOS] = useState("All");
    const [count, setCount] = useState("All")
    console.log("inveo",version);
    function getTimespan() {
        switch (time) {
            case "Last 3 Days":
                return "3d";
            case "Last 7 Days":
                return "7d";
            case "Last 30 Days":
                return "30d";
            case "Last 90 Days":
                return "90d";//new Date().getMonth()
            case "Last 6 Months":
                return "182d";//new Date().getMonth()
            case "Last 12 Months":
                return "365d";//new Date().getMonth()
            case "Last 2 Years":
                return "730d";
        }
    }

    const convertVersion = () => {
        if (Array.isArray(version)) {
            if (version.length == 0)
                return "All";
            if (version.length == versionsList)
                return "All";
            //else {
            //    let line = "";
            //    version.forEach((v, i) => {
            //        //line += v;
            //        if (i != version.length - 1)
            //            line +=" or application_Version ==";
            //    });
            //    return line;
            //}
            else {
                let line = "| where ";
                version.forEach((v, i) => {
                    if (i != version.length - 1)
                        line += `application_Version == "${v}" or `;
                    else
                        line += `application_Version == "${v}"`;
                });
                return line;
            }
        }
        else
            return version;
    }

    const getVersionList = () => {
        if (Array.isArray(version)) {
            if (version.length == 0)
                return [];
            if (version.length == versionsList)
                return [];
            else {
                return version;
            }
        }
        else
            return [version];
    }

    useEffect(() => {
        //Only DidMount or need to recall

        AppInsightsApi.getLocations(getTimespan(), props.code)
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let l = data.tables[0].rows.map(row => row[0]);
                    SiteApi.getAllSites()
                        .then(sitesdata => {
                            let list = l.map(item => {
                                let site = sitesdata.find(s => s.code == item);
                                if (site) {
                                    return ({name: site.name, code: site.code});
                                }
                                return ({ name: item, code: item });
                            });
                            //setLocationsList(data.tables[0].rows.map(row => row[0]).sort());
                            setLocationsList([{ name: "All", code: "" }].concat(list.sort(constants.byPropertyCalled())));
                        })
                        .catch(error => { });

                    //this.setState({
                    //    locationsList: data.tables[0].rows.map(row => this.getSiteName(row[0])).sort(),
                    //    locationsListLoading: false,
                    //});
                }
            })
            .catch(error => {
                //var errorArray = this.state.apiErrorList.concat(error);
                //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getLocations Error', error);
            });


        AppInsightsApi.getVersions(getTimespan(), props.code)
            .then(data => {
                if (data.tables[0].name === "PrimaryResult") {
                    let l = data.tables[0].rows.map(row => row[0])
                    let  sortedVersions = l.map(v => v.split('.').map(n => +n + 10000000).join('.')).sort().reverse();
                    sortedVersions = sortedVersions.map(v => v.split('.').map(n => +n - 10000000).join('.'));
                    setVersionsList(/*["All"].concat*/(sortedVersions));
                    //this.setState({
                    //    versionsList: this.sortVersions(data.tables[0].rows.map(row => row[0])),
                    //    versionsListLoading: false,
                    //});
                }
            })
            .catch(error => {
                //var errorArray = this.state.apiErrorList.concat(error);
                //this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- getVersions Error', error);
            });
    }, [])

    return (
        <Box>
            <Box direction="row" gap="none" justify="between"
                margin={{ "top": "none", "bottom": "small" }} style={{ minHeight: "fit-content" }}>
                <Box direction="row" gap="small" >
                    <Box direction="row" alignSelf="center" >
                        <Text> Time:</Text>
                    </Box>
                    <StyledBox style1 alignContent="end" width="230px" border>
                        <Select
                            plain
                            options={["Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 6 Months", "Last 12 Months", "Last 2 Years"]}
                            value={time}
                            onChange={({ option }) => {
                                setTime(option);
                                setCount(count + 1);
                            }}
                        />
                    </StyledBox>
                </Box>
                <Box direction="row" gap="small" >
                    <Box direction="row" alignSelf="center" >
                        <Text> Site:</Text>
                    </Box>
                    <StyledBox style1 alignContent="end" width="230px" border>
                        <Select
                            plain
                            options={locationsList}
                            value={site}
                            onChange={({ option }) => {
                                setSite(option);
                                setCount(count + 1);
                            }}
                        />
                    </StyledBox>
                </Box>
                <Box direction="row" gap="small" >
                    <Box direction="row" alignSelf="center" >
                        <Text> Version:</Text>
                    </Box>
                    <StyledBox style1 alignContent="end" width="230px" border>
                        {versionsList.length > 0 &&
                            <SelectMultiple
                            sortSelectedOnClose={false}
                            plain
                            options={versionsList}
                            value={version}
                            onChange={({ option }) => {
                                let tmp = [...version];
                                if (Array.isArray(option)) {
                                    if (tmp.length > 0) {
                                        tmp = [];
                                    } else if (tmp.length == 0) {
                                        tmp = option;
                                    }
                                } else if (tmp.includes(option)) {
                                    tmp = tmp.filter(t => t != option);
                                } else {
                                    tmp.push(option);
                                }

                                setVersion(tmp);
                                setCount(count + 1);
                            }}
                            />}
                        {versionsList.length == 0 &&
                            <Select plain options={[]}/>}
                    </StyledBox>
                </Box>
                <Box direction="row" gap="small" >
                    <Box direction="row" alignSelf="center" width="xsmall" >
                        <Text > Target OS:</Text>
                    </Box>
                    <StyledBox style1 alignContent="end" width="230px" border>
                        <Select
                            plain
                            options={["All", "Android", "iOS"]}
                            value={targetOS}
                            onChange={({ option }) => {
                                setTargetOS(option);
                                setCount(count + 1);
                            }}
                        />
                    </StyledBox>
                </Box>
            </Box>
            <AnalyticsContext.Provider value={{ time, site: site.code, version: convertVersion()/*version.length == 0 ? "All" : version*/, targetOS, count, axesColor: "F8F8F8", versions: getVersionList() }}>
            {props.children}
            </AnalyticsContext.Provider>
        </Box>
    );
}

//const mapStateToProps = state => ({
//    sitesdata: state.sitesReducer.sitesdata,
//    organisationdata: state.selectedOrganisationReducer.organisationdata
//});

//const mapDispatchToProps = dispatch => ({
//    action: bindActionCreators({ ...OrganisationAction, ...SiteAction }, dispatch)

//});

//export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsFilter);
export default AnalyticsFilter;