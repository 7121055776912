import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const calendarsReducer = (state = initialState.calendarsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_CALENDARS_RESPONSE: {
            return {
                ...state, 
                calendarsdata: _.assign(action.calendarsdata)
            };
        }
        default: { return state; }
    }
};



export default calendarsReducer;