import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, SelectMultiple, CheckBox, FormField, Grommet, Stack, CheckBoxGroup } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PrimaryFilter from "./common/PrimaryFilter";
import { Context } from './Context';
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";


const PaginatedDataTable = withPagination(DataTable);
class AssetListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.state = {
            flag: [],
            search: "",
            status: "",
            statusId: "",
            level: "",
            levelId: "",
            control: "All",
            floors: [],
            selectedBuilding: "",
            selectedBuildingId: "",
            isFilterOpen: false,
            selectedCollections: [],
            department: [],
            departmentId: "",
            manufacturerId: "",
            manufacturer: [],
            modelId: "",
            model: [],
            deviceTypeId: "",
            deviceType: [],
            zone: "",
            zoneId: "",
            class: "",
            type: "",
            selectedLabels: [],
            modelSuggestions: [],
            typeSuggestions: []
        }
    }
    componentWillReceiveProps(props) {
        if (props.flag && this.state.flag.length == 0) {
            this.setState({ flag: [props.flag], isFilterOpen: true });
        }
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            //const sitesfull = this.props.sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId") && RB.canAccessLocation(s.id)).slice();
            //sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            let handleEdit = this.props.handleEdit;
            // console.log('<tr> s:', trs);
            [...trs].forEach((elem, idx) => {
                console.log('Add click item:', elem, idx);
                if (elem.getElementsByTagName('th')[0]) {
                    let tid = elem.getElementsByTagName('th')[0].textContent;
                    console.log('Add click item:', tid);
                    //elem.addEventListener("click", function (evt) {
                    //    evt.preventDefault();
                    //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                    //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                    //});
                    elem.removeEventListener("click", this.externalFunc);
                    elem.addEventListener("click", this.externalFunc);
                }
                elem.addEventListener("mouseover", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "#E6E6E6";
                    elem.style.cursor = 'pointer';
                });
                elem.addEventListener("mouseout", function (evt) {
                    evt.preventDefault();
                    elem.style.background = "white";
                });
            });
        }
    }
    getData(data) {
        //"Orphans", "Healthy", "Untracable", "Low Charge"
        //var data = this.props.assetsdata;
        //var flag = this.props.flag ? this.props.flag : this.state.flag;
        //switch (this.state.flag) {
        //    case "Orphans":
        //        data = data.filter(d => /*d.assetBeaconTags.length == 0*/ !d.isAssigned);
        //        break;
        //    case "Healthy":
        //        data = data.filter(d => d.traceable && d.isAssigned);
        //        break;
        //    case "Untraceable":
        //        data = data.filter(d => d.traceable != true);
        //        break;
        //    case "Low Charge":
        //        data = data.filter(d => d.batteryLevel < 20);
        //        break;
        //    default:
        //        break;
        //}
        if (this.state.flag.length > 0) {
            data = data.filter(d =>
                //(this.state.flag.includes("Orphans") && !d.isAssigned) ||
                (this.state.flag.includes("Healthy") && d.traceable && d.isAssigned) ||
                (this.state.flag.includes("Untraceable") && d.traceable != true) ||
                (this.state.flag.includes("Low Charge") && d.batteryLevel < 20)
            );
        }
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.name.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.name.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.group.name.toLowerCase().includes(search) ||
                d.lcCollectionName.includes(search) ||
                d.lcDepartmentName.includes(search) ||
                d.lcZone.includes(search) ||
                d.lcManufacturerName.includes(search) ||
                d.lcModelName.includes(search) ||
                d.lcDeviceTypeName.includes(search) ||
                d.st.toLowerCase().includes(search) ||
                d.externalId.toLowerCase().includes(search) ||
                d.lastheard.includes(search) ||
                d.lcType.includes(search) ||
                d.lcClass.includes(search)
            );
        }
        //if (this.state.status) {
        //    data = data.filter(d => d.serviceStateId == this.state.statusId);
        //}
        if (this.state.status.length > 0) {
            data = data.filter(d => this.state.status.includes(d.serviceStateId));
        }
        if (this.state.selectedBuildingId) {
            data = data.filter(d => d.map && d.map.group && d.map.group.id == this.state.selectedBuildingId);
        }
        if (this.state.levelId) {
            data = data.filter(d => d.mapId == this.state.levelId);
        }
        if (this.state.zone) {
            data = data.filter(d => d.lcZone == this.state.zone.toLowerCase());
        }
        if (this.state.control === "Control Tags") {
            data = data.filter(d => d.isControlTag == true);
        }
        else if (this.state.control === "Tags") {
            data = data.filter(d => d.isControlTag == false);
        }
        if (this.state.selectedLabels.length > 0) {
            data = data.filter(d => {
                if (d.assetBeaconTags.length == 0) {
                    return this.state.selectedLabels.some(l => l.id == null);
                }
                else {
                    let i = 0;
                    while (i < this.state.selectedLabels.length) {
                        if (d.assetBeaconTags.find(l => l.tag && l.tag.name == this.state.selectedLabels[i].name))
                            return true;
                        i++;
                    }
                    return false;
                }
            });
        }
        if (this.state.selectedCollections.length > 0) {
            data = data.filter(d => this.state.selectedCollections.map(e => e.id).includes(d.collectionId));
        }
        if (this.state.department.length > 0) {
            data = data.filter(d => this.state.department.map(e => e.id).includes(d.departmentId));
        }
        if (this.state.class == "Asset") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.class == "Person") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.manufacturer.length > 0) {
            data = data.filter(d => this.state.manufacturer.some(m => m.id == d.manufacturerId));
        }
        if (this.state.model.length > 0) {
            data = data.filter(d => this.state.model.some(m => m.id == d.modelId));
        }
        if (this.state.deviceType.length > 0) {
            data = data.filter(d => this.state.deviceType.some(m => m.id == d.deviceTypeId));
        }
        if (this.state.type) {
            data = data.filter(d => d.type == this.state.type);
        }
        return data;
    }
    renderFilters() {
        let labelOptions = this.props.assetlabelsdata.sort(constants.byPropertyCalled());
        labelOptions = labelOptions.length > 0 ? labelOptions.concat([{ id: null, name: "" }]) : [];

        let collectionOption = this.props.collectionsdata.sort(constants.byPropertyCalled());
        collectionOption = collectionOption.length > 0 ? collectionOption.concat([{ id: null, name: "" }]) : [];

        let departmentOption = this.props.departmentsdata.sort(constants.byPropertyCalled());
        departmentOption = departmentOption.length > 0 ? departmentOption.concat([{ id: null, name: "" }]) : [];

        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} /*gap="small"*/>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Building">
                                <Select placeholder="Select" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}
                                    onChange={o => {
                                        const buildingid = this.props.buildingsdata[o.selected].mergedId;
                                        const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;
                                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");
                                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, level: "", levelId: "", zone: "", zoneId: "" });
                                    }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedBuilding && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "", zone: "", zoneId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Floor">
                                <Select placeholder="Select" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId, zone: "", zoneId: "" })} labelKey="meridianName">
                                    {option => {
                                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {
                                            return (
                                                <Box pad="small">
                                                    <b>
                                                        {option.meridianName}
                                                    </b>
                                                </Box>);
                                        }
                                        return (
                                            <Box pad="small">
                                                {option.meridianName}
                                            </Box>);
                                    }}
                                </Select>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.level && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ level: "", levelId: "", zone: "", zoneId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Zone">
                                <Select placeholder="Select" value={this.state.zone}
                                    options={[...new Set(this.props.assetsdata.filter(f => f.zoneId && f.map && f.map.group && f.map.group.id == this.state.selectedBuildingId && (this.state.level == "" || (this.state.level && this.state.level.meridianId == f.mapId))).map(zn => zn.zone.name).sort())]}
                                    onChange={evt => this.setState({ zone: evt.option, zoneId: this.props.assetsdata.filter(f => f.zoneId).find(fi => fi.zone.name == evt.option).zone.name })}>
                                    {option => {
                                        if (this.props.assetsdata.find(a => a.zoneId == option.zoneId)) {
                                            return (
                                                <Box pad="small">
                                                    <b>
                                                        {option}
                                                    </b>
                                                </Box>);
                                        }
                                        return (
                                            <Box pad="small">
                                                {option}
                                            </Box>);
                                    }}
                                </Select>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.zone && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ zone: "", zoneId: "" })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Label">
                                {this.props.assetlabelsdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={labelOptions}
                                        value={this.state.selectedLabels}
                                        onChange={o => {
                                            let tmp = o.value;
                                            //let tmp = [...this.state.selectedLabels];
                                            //if (o.option.length > 1) {
                                            //    if (tmp.length > 0) {
                                            //        tmp = [];
                                            //    } else if (tmp.length == 0) {
                                            //        tmp = o.option;
                                            //    }
                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                            //} else {
                                            //    tmp.push(o.option);
                                            //}
                                            this.setState({ selectedLabels: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.assetlabelsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.assetlabelsdata}
                                        value={this.state.selectedLabels}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedLabels.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedLabels: [] })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Collection">
                                {this.props.collectionsdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={collectionOption}
                                        value={this.state.selectedCollections}
                                        onChange={o => {
                                            let tmp = o.value;
                                            //let tmp = [...this.state.selectedCollections];
                                            //if (o.option.length > 1) {
                                            //    if (tmp.length > 0) {
                                            //        tmp = [];
                                            //    } else if (tmp.length == 0) {
                                            //        tmp = o.option;
                                            //    }
                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                            //} else {
                                            //    tmp.push(o.option);
                                            //}
                                            this.setState({ selectedCollections: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.collectionsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.collectionsdata}
                                        value={this.state.selectedCollections}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedCollections.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedCollections: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Department">
                                {this.props.departmentsdata.length > 0 &&
                                <SelectMultiple placeholder="Select"
                                    value={this.state.department}
                                    options={departmentOption}
                                    labelKey="name"
                                    valueKey="id"
                                    onChange={o => {
                                        let tmp = o.value;
                                        //let tmp = [...this.state.department];
                                        //if (o.option.length > 1) {
                                        //    if (tmp.length > 0) {
                                        //        tmp = [];
                                        //    } else if (tmp.length == 0) {
                                        //        tmp = o.option;
                                        //    }
                                        //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                        //    tmp = tmp.filter(t => t.id != o.option.id);
                                        //} else {
                                        //    tmp.push(o.option);
                                        //}
                                        this.setState({ department: tmp });
                                    }}
                                    sortSelectedOnClose={false}
                                    showSelectedInline
                                />}
                                {this.props.departmentsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.departmentsdata}
                                        value={this.state.department}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.department.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ department: [] })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Class">
                                <Select placeholder="Select" value={this.state.class} options={this.context.feature.find(f => f.name == "sitePersonTracking").isEnabled ? ["Asset", "Person"] : ["Asset"]} onChange={o => { this.setState({ class: o.option, type: "" }); }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.class && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ class: "", type: "" })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Type">
                                <Select placeholder="Select" value={this.state.type} options={constants.getTypeByClass(this.state.class)} onChange={o => { this.setState({ type: o.option }); }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.type && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ type: "" })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Manufacturer">
                                {this.props.manufacturersdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        value={this.state.manufacturer}
                                        options={this.props.manufacturersdata.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])}
                                        onChange={o => {
                                            let tmp = o.value;
                                            //let tmp = [...this.state.manufacturer];
                                            //if (Array.isArray(o.option)) {
                                            //    if (tmp.length > 0) {
                                            //        tmp = [];
                                            //    } else if (tmp.length == 0) {
                                            //        tmp = o.option;
                                            //    }
                                            //} else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            //    tmp = tmp.filter(t => t.id != o.option.id);
                                            //} else {
                                            //    tmp.push(o.option);
                                            //}

                                            let modelSuggestions = this.props.metadatas.filter(m => tmp.some(v => v.id == m.manufacturerId)/*m.manufacturerId == o.option.id*/ && m.modelId).sort(constants.byPropertyCalled).map(x => x.model).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            let typeSuggestions = this.props.metadatas.filter(m => {
                                                if (/*m.manufacturerId == o.option.id*/tmp.some(v => v.id == m.manufacturerId) && m.deviceTypeId) {
                                                    if (this.state.modelId) {
                                                        if (m.modelId == this.state.modelId)
                                                            return m;
                                                        return;
                                                    }
                                                    else {
                                                        if (m.modelId == null)
                                                            return m;
                                                        return;
                                                    }
                                                    return;
                                                }
                                                return;
                                            })
                                                .sort(constants.byPropertyCalled)
                                                .map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            //this.setState({ manufacturerId: o.option.id, manufacturer: o.option, modelSuggestions, typeSuggestions });
                                            //console.log("luc", o, modelSuggestions);
                                            this.setState({ manufacturer: tmp, modelSuggestions, typeSuggestions, modelId: "", model: [], deviceTypeId: "", deviceType: [] });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.manufacturersdata.length == 0 &&
                                    <Select placeholder="Select" options={[]}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.manufacturer.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ manufacturerId: "", manufacturer: [], modelId: "", model: [], deviceTypeId: "", deviceType: [], modelSuggestions: [], typeSuggestions: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Model">
                                {this.state.modelSuggestions.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        value={this.state.model}
                                        options={this.state.modelSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.modelsdata*/}
                                        onChange={o => {
                                            let tmp = o.value;
                                            let typeSuggestions = this.props.metadatas.filter(m =>
                                                //m.manufacturerId == this.state.manufacturerId && m.modelId == o.option.id
                                                this.state.manufacturer.some(v => v.id == m.manufacturerId) && tmp.some(v => v.id == m.modelId)
                                                && m.deviceTypeId).map(x => x.deviceType).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
                                            this.setState({ modelId: o.option.id, model: tmp, typeSuggestions, deviceTypeId: "", deviceType: [] });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.state.modelSuggestions.length == 0 &&
                                    <Select placeholder="Select" options={[]}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.model.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ modelId: "", model: [], deviceTypeId: "", deviceType: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Model Type">
                                {this.state.typeSuggestions.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        placeholder="Select"
                                        value={this.state.deviceType}
                                        options={this.state.typeSuggestions.sort(constants.byPropertyCalled()).concat([{ id: null, name: "" }])/*this.props.typesdata*/}
                                        onChange={o => {
                                            let tmp = o.value;
                                            this.setState({ deviceTypeId: o.option.id, deviceType: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.state.typeSuggestions.length == 0 &&
                                    <Select placeholder="Select" options={[]} />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.deviceType.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ deviceTypeId: "", deviceType: [] })}
                    />}
                </Box>

                {this.context.feature.find(f => f.name == "siteWellness").isEnabled && <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Status">
                                <CheckBoxGroup
                                    value={this.state.status}
                                    labelKey="name"
                                    valueKey="id"
                                    options={constants.ASSET_STATUS}
                                    onChange={evt => this.setState({ status: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.status.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ status: [], statusId: "" })}
                    />}
                </Box>}
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Device Flag">
                                <CheckBoxGroup
                                    options={["Healthy", "Low Charge", /*"Orphans",*/ "Untraceable"]}
                                    value={this.state.flag}
                                    onChange={evt => this.setState({ flag: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.flag.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ flag: [] })}
                    />}
                </Box>
            </Box>
        )
    }
    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.props.assetsdata.map(e => e.batteryLevel == 999 ? e.batteryLevel = 0 : null);
        const locationid = sessionStorage.getItem("locationId");
        //console.log(props.usersdata, locationid);
        if (this.props.assetsdata[0] && this.props.assetsdata[0].count != 0) {
            this.props.assetsdata.forEach(ev => {
                var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
                statusName = statusName ? statusName.name : "";

                let tracker = this.props.associationsdata && this.props.associationsdata[0] && this.props.associationsdata[0].count != 0 ? this.props.associationsdata.find(a => a.beaconId == ev.id) : false;
                let isAssigned = tracker ? true : false;
                let collection = tracker && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId) : "";
                let collectionId = collection ? collection.collectionId : null;
                let department = tracker && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId) : "";
                let departmentId = department ? department.departmentId : "";
                let collectionName = collectionId && this.props.collectionsdata && this.props.collectionsdata[0] && this.props.collectionsdata[0].count != 0 ? this.props.collectionsdata.find(c => c.id == collectionId) : "";
                collectionName = collectionName ? collectionName.name : "";
                let departmentName = departmentId && this.props.departmentsdata && this.props.departmentsdata[0] && this.props.departmentsdata[0].count != 0 ? this.props.departmentsdata.find(d => d.id == departmentId) : "";
                departmentName = departmentName ? departmentName.name : "";
                let manufacturerId = department ? department.manufacturerId : "";
                let manufacturerName = manufacturerId && this.props.manufacturersdata ? this.props.manufacturersdata.find(d => d.id == manufacturerId) : "";
                manufacturerName = manufacturerName ? manufacturerName.name : "";
                let modelId = department ? department.modelId : "";
                let modelName = modelId && this.props.modelsdata ? this.props.modelsdata.find(d => d.id == modelId) : "";
                modelName = modelName ? modelName.name : "";
                let deviceTypeId = department ? department.deviceTypeId : "";
                let deviceTypeName = deviceTypeId && this.props.typesdata ? this.props.typesdata.find(d => d.id == deviceTypeId) : "";
                deviceTypeName = deviceTypeName ? deviceTypeName.name : "";
                if (ev.map) {
                    if (ev.map.group)
                        ev.map.group.name = ev.map && ev.map.group && ev.map.group.name != null && ev.traceable ? ev.map.group.name : "";
                    ev.map.name = ev.map && ev.map.name && ev.traceable ? ev.map.name : "";
                }
                if (ev.zone)
                    ev.zone.name = ev.zoneId && ev.zone && ev.traceable ? ev.zone.name : "";
                let aName = tracker ? tracker.assetName : ev.name ? ev.name : "";
                let trackerClass = department ? department.class : "";
                let trackerType = department ? department.type : "";
                this.DATA.push({
                    ...ev,
                    name: aName,
                    lcname: aName.toLowerCase(),//ev.name != null ? ev.name.toLowerCase() : "",
                    lcbuilding: ev.map.group != null ? ev.map.group.name.toLowerCase() : "",
                    lcfloor: ev.map.name != null ? ev.map.name.toLowerCase() : "",
                    st: statusName,
                    collectionId: collectionId,
                    departmentId: departmentId,
                    collectionName: collectionName,
                    lcCollectionName: collectionName.toLowerCase(),
                    departmentName: departmentName,
                    lcDepartmentName: departmentName.toLowerCase(),
                    lcZone: ev.zoneId && ev.zone ? ev.zone.name.toLowerCase() : "",
                    manufacturerId: manufacturerId,
                    modelId: modelId,
                    deviceTypeId: deviceTypeId,
                    manufacturerName: manufacturerName,
                    lcManufacturerName: manufacturerName.toLowerCase(),
                    modelName: modelName,
                    lcModelName: modelName.toLowerCase(),
                    deviceTypeName: deviceTypeName,
                    lcDeviceTypeName: deviceTypeName.toLowerCase(),
                    lastheard: ev.timestamp ? constants.formatDate(ev.timestamp) : "",
                    isAssigned: isAssigned,
                    lcClass: trackerClass ? trackerClass.toLowerCase() : "",
                    class: trackerClass,
                    lcType: trackerType ? trackerType.toLowerCase() : "",
                    type: trackerType,
                    labels: ev.assetBeaconTags.length,


                    //department: ev.id == "546C0E032A87" ? "Radiology" : ev.id == "A0E6F83810BB" ? "Maternity" : ev.id == "546C0E082AFB" ? "Emergency " : "",
                    //txtble: this.address(ev.id),
                    //mapfloor: ev.map && (ev.map.name != null) ? ev.map.name : "",
                    //lcfloor: ev.map && (ev.map.name != null) ? ev.map.name.toLowerCase() : "",
                    //txtlast: ev.timestamp.substr(0, 10),
                    ////txtcreated: ev.created.substr(0, 10),
                    ////txtmodified: ev.modified.substr(0, 10),
                    //firmware_a: ev.firmwareAVersion,
                    //firmware_b: ev.firmwareBVersion,
                    //hwtype: ev.hwType
                });
            });
        }
        this.DATA = this.getData(this.DATA);
        //this.DATA.sort((p1, p2) => {
        //    if (p1.lcbuilding == "aruba hq" || p2.lcbuilding == "aruba hq") {
        //        if (p2.lcbuilding == "aruba hq" && p1.lcbuilding == "aruba hq") {
        //            return !(p1.isControlTag || p2.isControlTag);
        //        }
        //        return -1;
        //    }
        //    return 1;
        //});
        this.DATA.sort((p1, p2) => {
            if (p1.lcbuilding > p2.lcbuilding)
                return -1;
            if (p1.lcbuilding == p2.lcbuilding) {
                //if (!p1.isControlTag && !p2.isControlTag) {
                //    console.log("hela",p1.lcname, p2.lcname);
                //    return p1.lcname > p2.lcname;
                //}
                return p1.isControlTag - p2.isControlTag;
            }
            return 1;
        });
        this.DATA.sort(constants.byPropertyCalled());
        console.log('DATA:', this.DATA, this.props);
        //var sync = this.props.syncTime ? this.props.syncTime.replace("T", " ").replace("Z", "") : "";
        var sync = "";
        if (this.props.syncTime) {
            var date = new Date(this.props.syncTime);
            sync =
                ("00" + date.getDate()).slice(-2) + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                date.getFullYear() +
                " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2);
            //":" + ("00" + date.getSeconds()).slice(-2);
            sync.replace("T", " ").replace("Z", "")
        }
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Asset</Heading>
                    {/*<StyledButton typeSave label='Export to CSV' alignSelf="center" onClick={evt => this.handleExport(evt)} />*/}
                    {/*<Text>
                        Last updated at: {sync}
                    </Text>*/}
                    <Box width="100px">
                        <StyledButton typeCancel label="Back"
                            onClick={() => this.props.handleBack()} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    {/*<IconThemeContext.Extend value={galwayIconTheme}>*/}
                    <div ref={this.dtwrapper}>
                        <Box style={{ "min-width": "max-content"}}>
                            {/*<Box width="100%" direction="row" justify="between" pad={{ bottom: "medium" }}>*/}
                            {/*    <StyledBox style1 width="90%">*/}
                            {/*        <TextInput placeholder="Search Asset" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />*/}
                            {/*    </StyledBox>*/}
                            {/*    <Box width="100px" alignSelf="center">*/}
                            {/*        <StyledButton typeAdd width="100px" label="Filter" onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })} />*/}
                            {/*    </Box>*/}
                            {/*</Box>*/}
                            {this.state.isFilterOpen && null
                                //<Box direction="row" justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">
                                //    <Box width="90%">
                                //        <Grid columns={{ count: 5, size: "auto" }} gap="medium" >
                                //            <StyledBox style1>
                                //                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}
                                //                    onChange={o => {
                                //                        const buildingid = this.props.buildingsdata[o.selected].mergedId;
                                //                        const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;
                                //                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");
                                //                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                                //                    }} />
                                //            </StyledBox>
                                //            <StyledBox style1>
                                //                <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">
                                //                    {option => {
                                //                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {
                                //                            return (
                                //                                <Box pad="small">
                                //                                    <b>
                                //                                        {option.meridianName}
                                //                                    </b>
                                //                                </Box>);
                                //                        }
                                //                        return (
                                //                            <Box pad="small">
                                //                                {option.meridianName}
                                //                            </Box>);
                                //                    }}
                                //                </Select>
                                //            </StyledBox>
                                //            {/*<StyledBox style1>
                                //                <Select placeholder="Select Control" value={this.state.control} options={["All", "Control Tags", "Tags"]}
                                //                    onChange={o => {
                                //                        this.setState({ control: o.option });
                                //                    }} />
                                //            </StyledBox>*/}
                                //            <StyledBox style1>
                                //                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />
                                //            </StyledBox>
                                //            <StyledBox style1>
                                //                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata/*.map(d=> d.name)*/} labelKey="name"
                                //                    onChange={o => {
                                //                        this.setState({ departmentId: o.option.id, department: o.option });
                                //                    }} />
                                //            </StyledBox>
                                //            {/*<StyledBox style1>
                                //                <Select placeholder="Select Device Flag" value={this.state.flag} options={["Healthy", "Untraceable", "Low Charge", "Orphans"]}
                                //                    onChange={(opt) => {
                                //                        this.setState({ flag: opt.option });
                                //                    }} />
                                //            </StyledBox> */}
                                //            <StyledBox style1>
                                //                <Select placeholder="Select Status" value={this.state.status} options={constants.ASSET_STATUS} onChange={evt => { this.setState({ status: evt.option, statusId: evt.option.id }); console.log("status", evt.option) }} labelKey="name">
                                //                    {option =>
                                //                        <Box pad="small">
                                //                            {option.name}
                                //                        </Box>
                                //                    }
                                //                </Select>
                                //            </StyledBox>
                                //        </Grid>
                                //    </Box>
                                //    <Box alignSelf="center" width="100px">
                                //        <StyledButton typeCancel label="Clear" /*icon={<FormClose />}*/ onClick={() => this.setState({ flag: "", status: "", selectedBuilding: "", selectedBuildingId: "", level: "", levelId: "", control: "All", floors: [], collectionId: "", departmentId: "", collection: "", department: "" })}
                                //            disabled={
                                //                this.state.selectedBuilding == "" &&
                                //                this.state.selectedBuildingId == "" &&
                                //                this.state.level == "" &&
                                //                this.state.levelId == "" &&
                                //                this.state.control == "All" &&
                                //                this.state.flag == "" &&
                                //                this.state.status == "" &&
                                //                this.state.collection == "" &&
                                //                this.state.collectionId == "" &&
                                //                this.state.department == "" &&
                                //                this.state.departmentId == ""
                                //            } />
                                //    </Box>
                                //</Box>
                            }
                            {/*<PrimaryFilter*/}
                            {/*    placeholder="Search by Asset name, ID, building, floor, collection or department"*/}
                            {/*    search={this.state.search}*/}
                            {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                            {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                            {/*    open={this.state.isFilterOpen}*/}
                            {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                            {/*    onResetFilter={() => this.setState({*/}
                            {/*        selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",*/}
                            {/*        collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""*/}
                            {/*    })}*/}
                            {/*    isClearVisible={(*/}
                            {/*        this.state.selectedBuilding == "" &&*/}
                            {/*        this.state.level == "" &&*/}
                            {/*        this.state.collection == "" &&*/}
                            {/*        this.state.department == "" &&*/}
                            {/*        this.state.status == "") ? false : true}*/}
                            {/*    csvdata={[]}*/}
                            {/*    downloadable={false}*/}
                            {/*>*/}
                            {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                            {/*        <Box direction="row" justify="between">*/}
                            {/*            <StyledBox style1>*/}
                            {/*                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                            {/*                    onChange={o => {*/}
                            {/*                        const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                            {/*                        const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;*/}
                            {/*                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                            {/*                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                            {/*                    }} />*/}

                            {/*            </StyledBox>*/}
                            {/*            {this.state.selectedBuilding && <Button*/}
                            {/*                icon={<FormClose />}*/}
                            {/*                onClick={() => this.setState({ selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "" })}*/}
                            {/*            />}*/}
                            {/*        </Box>*/}
                            {/*        <Box direction="row" justify="between">*/}
                            {/*            <StyledBox style1>*/}
                            {/*                <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                            {/*                    {option => {*/}
                            {/*                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {*/}
                            {/*                            return (*/}
                            {/*                                <Box pad="small">*/}
                            {/*                                    <b>*/}
                            {/*                                        {option.meridianName}*/}
                            {/*                                    </b>*/}
                            {/*                                </Box>);*/}
                            {/*                        }*/}
                            {/*                        return (*/}
                            {/*                            <Box pad="small">*/}
                            {/*                                {option.meridianName}*/}
                            {/*                            </Box>);*/}
                            {/*                    }}*/}
                            {/*                </Select>*/}
                            {/*            </StyledBox>*/}
                            {/*            {this.state.level && <Button*/}
                            {/*                icon={<FormClose />}*/}
                            {/*                onClick={() => this.setState({ level: "", levelId: "" })}*/}
                            {/*            />}*/}
                            {/*        </Box>*/}
                            {/*        <Box direction="row" justify="between">*/}
                            {/*            <StyledBox style1>*/}
                            {/*                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                            {/*            </StyledBox>*/}
                            {/*            {this.state.collection && <Button*/}
                            {/*                icon={<FormClose />}*/}
                            {/*                onClick={() => this.setState({ collection: "", collectionId: "" })}*/}
                            {/*            />}*/}
                            {/*        </Box>*/}
                            {/*        <Box direction="row" justify="between">*/}
                            {/*            <StyledBox style1>*/}
                            {/*                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} labelKey="name"*/}
                            {/*                    onChange={o => {*/}
                            {/*                        this.setState({ departmentId: o.option.id, department: o.option });*/}
                            {/*                    }} />*/}
                            {/*            </StyledBox>*/}
                            {/*            {this.state.department && <Button*/}
                            {/*                icon={<FormClose />}*/}
                            {/*                onClick={() => this.setState({ departmentId: "", department: "" })}*/}
                            {/*            />}*/}
                            {/*        </Box>*/}
                            {/*        <Box direction="row" justify="between">*/}
                            {/*            <StyledBox style1>*/}
                            {/*                <Select placeholder="Select Status" value={this.state.status} options={constants.ASSET_STATUS} onChange={evt => { this.setState({ status: evt.option, statusId: evt.option.id }); console.log("status", evt.option) }} labelKey="name">*/}
                            {/*                    {option =>*/}
                            {/*                        <Box pad="small">*/}
                            {/*                            {option.name}*/}
                            {/*                        </Box>*/}
                            {/*                    }*/}
                            {/*                </Select>*/}
                            {/*            </StyledBox>*/}
                            {/*            {this.state.status && <Button*/}
                            {/*                icon={<FormClose />}*/}
                            {/*                onClick={() => this.setState({ status: "", statusId: "" })}*/}
                            {/*            />}*/}
                            {/*        </Box>*/}
                            {/*    </Box>*/}
                            {/*</PrimaryFilter>*/}
                            <StyledBox /*style={{ "min-width": "1280px" }}*/>
                                {this.renderAssetsTable(this.props, canEdit, sync)}
                            </StyledBox>
                            <Text>Last updated at: {sync}</Text>
                        </Box>
                    </div>
                    {/*</IconThemeContext.Extend>*/}
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    };
    renderBatteryIcon(level) {
        if (0 < level && level < 20) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <BatteryRed color="#FF4040" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (20 <= level && level <= 60) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <BatteryAmber color="#FFAA15" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (60 < level && level <= 100) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <Battery color="#01a982" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else {
            return (
                <Box direction="row">
                    <BatteryUnknown color="#CCCCCC" style={{ width: "34.365", height: "18" }} />
                </Box>);
        }

    }

    renderAssetsTable(props, canEdit, sync) {

        let COLUMNS = [
            //{
            //    property: 'imageUrl',
            //    header: 'Image',
            //    render: s => (s.imageUrl != "" ?
            //        <Image width="30" height="30" src={`${s.imageUrl}`} /> :
            //        <Tag />
            //    )
            //},
            {
                property: 'lcname',
                header: 'Name',
                render: s => <Text>{this.props.trackersdata.find(f => f.assetBeaconId == s.id).name}</Text>,
                isFixed: true
            },
            {
                property: 'id',
                header: 'ID',
                render: s => <Text>{s.id}</Text>,
                isFixed: true
            },
            {
                property: 'externalId',
                header: 'External ID',
                render: s => <Text>{s.externalId}</Text>,
                isActive: false,
            },
            {
                property: 'lastheard',
                header: 'Last Known',
                render: s => s.mapId ? <Text>{s.lastheard}</Text> : null,
                isActive: false,
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: s => <Text>{s.map.group ? s.map.group.name : ""}</Text>
            },
            {
                property: 'lcfloor',
                header: 'Floor',
                render: s => <Text>{s.map.name}</Text>
            },
            {
                property: 'lcZone',
                header: 'Zone',
                render: s => <Text>{s.zoneId && s.zone ? s.zone.name : ""}</Text>
            },
            {
                property: 'lcCollectionName',
                header: 'Collection',
                render: s => <Text >{s.collectionName}</Text>
            },
            {
                property: 'lcDepartmentName',
                header: 'Department',
                render: s => <Text >{s.departmentName}</Text>
            },
            {
                property: 'serviceStateId',
                header: 'Status',
                render: s =>
                    < Text > {s.st}</Text >
            },
            //{
            //    property: 'isControlTag',
            //    header: 'Control Tag',
            //    render: s => <CheckBox checked={s.isControlTag} disabled />
            //},
            {
                property: 'lcManufacturerName',
                header: 'Manufacturer',
                render: s => <Text>{s.manufacturerName}</Text>,
                isActive: false,
            },
            {
                property: 'lcModelName',
                header: 'Model',
                render: s => <Text>{s.modelName}</Text>,
                isActive: false,
            },
            {
                property: 'lcDeviceTypeName',
                header: 'Model Type',
                render: s => <Text>{s.deviceTypeName}</Text>,
                isActive: false,
            },
            {
                property: 'batteryLevel',
                header: 'Battery',
                //primary: true,
                render: s => this.renderBatteryIcon(s.batteryLevel)
            },
            {
                property: 'lcClass',
                header: 'Class',
                render: s => <Text>{s.class}</Text>,
                isActive: false,
            },
            {
                property: 'lcType',
                header: 'Type',
                render: s => <Text>{s.type}</Text>,
                isActive: false,
            },
            {
                property: 'labels',
                header: <Box justify="center" height="100%">Labels</Box>,
                size: "70px",
                render: s =>
                    s.assetBeaconTags.length > 0 ?
                        < Box direction="row" /*border="all"*/ /*pad={{ vertical: "small" }}*/ alignContent="center" >
                            <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                <Stack anchor="top-right" data-for={s.id} style={{ width: constants.STACKWIDTH }}
                                    data-tip>
                                    <Tag
                                        size="large"
                                        //color="#F04953"
                                        style={{ height: "24px" }}
                                    />
                                    <Box
                                        background="brand"
                                        pad={{ horizontal: 'xsmall' }}
                                        round
                                    >
                                        {s.assetBeaconTags.length}
                                    </Box>
                                </Stack>
                                <ReactTooltip id={s.id} place="left" type="light" effect="solid" arrowColor="transparent" multiline >
                                    <StyledBox style1 border="all" gap="small" pad="small">
                                        {s.assetBeaconTags && s.assetBeaconTags.map((l) => (
                                            <Text>
                                                {l.tag.name}
                                            </Text>
                                        ))}
                                    </StyledBox>
                                </ReactTooltip>

                            </Box>
                        </Box >
                        :
                        <Box />,
                isActive: false,
            },

            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            //{
            //    property: 'timestamp',
            //    header: 'Last Heard',
            //    render: s => <Text>{s.timestamp ? s.timestamp.replace("T", " ").replace("Z", "") : ""}</Text>
            //},

        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        if (!(this.context.feature.find(f => f.name == "siteWellness").isEnabled)) {
            removeColumn("serviceStateId");
        }

        // this.DATA = [];
        // const locationid = sessionStorage.getItem("locationId");
        // //console.log(props.usersdata, locationid);
        // props.beaconsdata.forEach(ev => {
        //         this.DATA.push({
        //             ...ev,
        //             lcname: ev.name != null ? ev.name.toLowerCase() : "",
        //             lcfloor: ev.map.name != null ? ev.map.name.toLowerCase(): "",
        //         })
        // });
        //if (this.DATA.length == 0 && this.getData()[0] && this.getData()[0].count != 0) {
        //    return (
        //        <LoadingIndicator/>
        //    );
        //}
        if (props.loading) {
            return (
                <LoadingIndicator error={props.error_counter} errorList={props.error_List} />
            );
        }

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
        }

        let filterCount = 0;
        filterCount += this.state.selectedBuilding == "" ? 0 : 1;
        filterCount += this.state.level == "" ? 0 : 1;
        filterCount += this.state.selectedLabels.length == 0 ? 0 : 1;
        filterCount += this.state.selectedCollections.length == 0 ? 0 : 1;
        filterCount += this.state.zone == "" ? 0 : 1;
        filterCount += this.state.department.length == 0 ? 0 : 1;
        filterCount += this.state.status.length == 0 ? 0 : 1;
        filterCount += this.state.manufacturer.length == 0 ? 0 : 1;
        filterCount += this.state.model.length == 0 ? 0 : 1;
        filterCount += this.state.deviceType.length == 0 ? 0 : 1;
        filterCount += this.state.class == "" ? 0 : 1;
        filterCount += this.state.type == "" ? 0: 1;
        filterCount += this.state.flag.length == 0 ? 0 : 1;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "batteryLevel"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.assetsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Name' },
                //    { col: 'lcbuilding', label: 'Building' },
                //    { col: 'lcfloor', label: 'Floor' },
                //    { col: 'lcdeparment', label: 'Department' },
                //    { col: 'st', label: 'Status' },
                //    { col: 'id', label: 'ID' },
                //    //{ col: 'isControlTag', label: 'Control Tag' },
                //    { col: 'batteryLevel', label: 'Battery Level' },
                //    //{ col: 'timestamp', label: 'Last Heard' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({
                    selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "",
                    selectedCollections: [], selectedLabels: [], zone: "", zoneId: "", departmentId: "", department: "", status: "", statusId: "",
                    manufacturerId: "", manufacturer: "", modelId: "", model: "", deviceTypeId: "", deviceType: "", flag: "", type: "", class: ""
                })}
                PFisClearVisible={filterCount}
                PFcsvdata={[]}
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={0}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
            //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
            />
        );
    }

}

export default AssetListTable;
