import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
//eslint-disable-next-line
import { Grid, Box, Heading, FormField, DataTable, CheckBox, Text, Tab, Tabs, TextInput, TableBody, TableRow, TableCell, Table, Grommet, Button } from 'grommet';
import { Edit, Trash, Checkmark } from 'grommet-icons';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withPagination from './common/PaginatedDataTable';
import TextInputWithState from './common/TextInputWithState';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { isNumber } from 'util';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';


const PaginatedDataTable = withPagination(DataTable);
var start = undefined;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        },
        margin: "none",
        pad: "none",
        error: {
            margin: "none",
        }
    },
    global: {
        focus: {
            outline: {
                color: "brand"
            },
            border: {
                color: "brand"
            }
        }
    }
});

const ffTheme = deepMerge(hpe, {
    formField: {
        border: {
            color: "none",
        },
    },
    box: {
        extend: {
            "border-radius": "5px",
        }
    }
});

export class OrgIntegrationOauthEditContainer extends React.Component {


    constructor() {
        super();
        this.newURI = "";
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            index: 0,
            tabindex: 0,
            viewOauthClientId: false,
            viewOauthClientSecret: false,
            viewOauthDiscoveryURL: false,
            viewOauthDiscoveryURL: false,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],

            inputforURI: "",
            URIforedit: "",
            URIeditover: true,
            selected: -1
        };
        this.isChargifiOpen = false;
    }

    //Error handling
    //TAB 1
    oauthClientId_err = "";
    oauthClientSecret_err = "";
    oauthRedirectURI_err = "";
    oauthDiscoveryURL_err = "";
    loginHint_err = "";
    attributes_err = "";

    URIs_err = '';
    URIedit_err = '';

    //TAB 2

    eventDelete(event) {
        //const selectedId = this.state.selectedSite;

        //if (selectedId) {
        //    this.setState({ selectedSite: 0, open: undefined });
        //    this.props.action.deleteSiteAction(selectedId)
        //        .catch(error => {
        //            console.log('*DEBUG*- Site List Delete Error', error);
        //        });
        //}
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        const id = this.props.organisationdata.id;
        this.props.history.push({
            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
            state: { detail: "Authentication" }
        });
    }

    handleNew(event) {

    }

    handleEdit(event, nro) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //TAB 1
        this.oauthClientId_err = "";
        this.oauthClientSecret_err = "";
        this.oauthRedirectURI_err = "";
        this.oauthDiscoveryURL_err = "";
        this.loginHint_err = "";
        this.attributes_err = "";
        this.scope_err = "";
        this.email_err = "";
        this.fullName_err = "";
        this.firstName_err = "";

        this.URIs_err = "";
        this.URIedit_err = "";

        //TAB 2
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data

        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });


        let uris = organisationdefault.oauthRedirectURI ? organisationdefault.oauthRedirectURI.split(';') : [];
        let errorUris = uris.reduce((prev, curr, idx) => {
            if (curr.length > 255) {
                return [...prev, curr]
            }
            return prev;
        }, []);

        if (organisationdefault.oauthClientId.trim().length == 0) {
            errorsno++;
            this.oauthClientId_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.oauthClientId.trim().length > 50) {
            errorsno++;
            this.oauthClientId_err = "This field can't be longer than 50 character";
            errortab = 0;
        }
        //if (organisationdefault.oauthClientSecret.trim().length == 0) {
        //    errorsno++;
        //    this.oauthClientSecret_err = constants.EMPTY_ERROR;
        //    errortab = 0;
        //}
        if (organisationdefault.oauthClientSecret.trim().length == 0) {
            errorsno++;
            this.oauthClientSecret_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.oauthClientSecret.trim().length > 100) {
            errorsno++;
            this.oauthClientSecret_err = "Client Secret can't be longer than 100 character";
            errortab = 0;
        }
        if (organisationdefault.oauthRedirectURI.trim().length == 0) {
            errorsno++;
            this.URIs_err = constants.EMPTY_ERROR;
            this.oauthRedirectURI_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (this.state.selected != -1) {
            this.URIedit_err = "Please finish editing before saving the page";
            errorsno++;
            errortab = 0;
        }
        if (this.newURI != "") {
            this.URIs_err = "Please finish creating before saving the page";
            errorsno++;
            errortab = 0;
        }
        if (errorUris.length > 0) {
            this.setState({ selected: errorUris[0], inputforURI: errorUris[0]});
            this.URIedit_err = "This field can't be longer than 255 character";
        }
        if (organisationdefault.oauthRedirectURI.trim().length >= 56324) {
            errorsno++;
            this.oauthRedirectURI_err = "This field can't be longer than 56323 character";
            this.max = "This field can't be longer than 56323 character";
            errortab = 0;
        }
        if (organisationdefault.oauthRedirectURI.trim().length != 0 && !(constants.REGEX.URI.test(organisationdefault.oauthRedirectURI))) {
            errorsno++;
            this.oauthRedirectURI_err = constants.URI_ERROR;
            errortab = 0;
        }
        if (organisationdefault.oauthDiscoveryURL.trim().length == 0) {
            errorsno++;
            this.oauthDiscoveryURL_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.oauthDiscoveryURL.trim().length > 511) {
            errorsno++;
            this.oauthDiscoveryURL_err = "This field can't be longer than 511 character";
            errortab = 0;
        }
        if (organisationdefault.oauthDiscoveryURL.trim().length != 0 && !(validator.isURL(organisationdefault.oauthDiscoveryURL, constants.URL_OPTIONS))) {
            errorsno++;
            this.oauthDiscoveryURL_err = constants.URL_ERROR;
            errortab = 0;
        }
        if (organisationdefault.oauthScope.trim().length > 50) {
            errorsno++;
            this.scope_err = "Scope can't be longer than 50 character";
            errortab = 0;
        }
        if (organisationdefault.oauthEmailClaim.trim().length > 10) {
            errorsno++;
            this.email_err = "Email can't be longer than 10 character";
            errortab = 0;
        }
        if (organisationdefault.oauthFullNameClaim.trim().length > 30) {
            errorsno++;
            this.fullName_err = "Full Name can't be longer than 30 character";
            errortab = 0;
        }
        if (organisationdefault.oauthFirstNameClaim.trim().length > 30) {
            errorsno++;
            this.firstName_err = "First Name can't be longer than 30 character";
            errortab = 0;
        }


        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            console.log('Not ok:', organisationdefault.oauthRedirectURI);
            this.onActive(errortab);
            //this.setState({});
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Authentication" }
                        });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push({
                        pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                        state: { detail: "Authentication" }
                    });
                });
        }
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }

    //Handling data
    updateOauth = (e) => {
        this.props.organisationdata.oauthEnabled = !this.props.organisationdata.oauthEnabled;

        if (this.props.organisationdata.oauthEnabled && this.props.organisationdata.clearpassEnabled) {
            this.props.organisationdata.clearpassEnabled = false;
        }

        this.setState({ updated: !this.state.updated });
    }
    updateoauthClientId = (e) => {
        this.props.organisationdata.oauthClientId = e.target.value;
    }
    updateoauthClientSecret = (e) => {
        this.props.organisationdata.oauthClientSecret = e.target.value;
    }
    updateoauthRedirectURI = (e) => {
        this.props.organisationdata.oauthRedirectURI = e.target.value;
    }

    isValidURI(uri) {
        let isValid = constants.REGEX.URI.test(uri);
        return isValid;
    }

    isUniqueURI(urilist, uri) {
        if (urilist.flat().includes(uri)) {
            return false
        }
        else if (!urilist.flat().includes(uri)) {
            return true
        }
    }
    updateURI(uri, id) {
        let list = [...this.props.organisationdata.oauthRedirectURI.split(';')];
        list[id] = uri;
        this.setState({ inputforURI: list[id] });
        let uriStr = list.join(';');
        this.props.organisationdata.oauthRedirectURI = uriStr;
        //if (this.isValidURI(list[id]) && this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), list[id])) {
        //    let uriStr = list.join(',');
        //    this.props.organisationdata.domains = uriStr;
        //}
    }
    handleAddURIChange = evt => {
        this.URIs_err = "";
        this.newURI = evt.target.value;
        this.setState({});
    }
    handleAddURI = () => {
        this.max = "";
        if (this.newURI.length > 0) {
            let isValid = this.isValidURI(this.newURI);
            let isUnique = this.isUniqueURI(this.props.organisationdata.oauthRedirectURI.split(";"), this.newURI);
            if (isValid &&
                //this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.newURI) &&
                isUnique && this.newURI.length < 256 && this.props.organisationdata.oauthRedirectURI.trim().length + this.newURI.length < 56324) {
                let URIs = this.props.organisationdata.oauthRedirectURI ? this.props.organisationdata.oauthRedirectURI : '';
                URIs = URIs.length > 0 ? URIs.concat(';', this.newURI) : this.newURI;
                this.newURI = '';
                this.props.organisationdata.oauthRedirectURI = URIs;
                this.URIs_err = "";
                this.setState({ domAddKey: Math.random() });
            }
            //else if (!this.isUniqueDomain(this.props.organisationsdata.map(item => item.domains.split(",")), this.newURI) &&
            //    this.isUniqueDomain(this.props.organisationdata.domains.split(","), this.newURI)) {
            //    this.URIs_err = constants.DOMAIN_NOT_UNIQUE_ERROR;
            //    this.setState({});
            //}
            else if (!isUnique) {
                this.URIs_err = constants.URI_THIS_ORG_ERROR;
                this.setState({});
            }
            else if (!(this.newURI.length < 256)) {
                this.URIs_err = "This field can't be longer than 255 character";
                this.setState({});
            }
            else if (this.props.organisationdata.oauthRedirectURI.trim().length + this.newURI.length  >= 56324) {
                this.max = "This field can't be longer than 56323 character";
                this.setState({});
            }
            else {
                this.URIs_err = constants.URI_ERROR;
                this.setState({});
            }
        }
        else if (this.newURI.length == 0) {
            this.URIs_err = constants.URI_INVALID_ERROR;
            this.setState({});
        }
    }

    updateoauthDiscoveryURL = (e) => {
        this.props.organisationdata.oauthDiscoveryURL = e.target.value;
    }

    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const { organisationdataLoading } = this.state;

        if (organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }
        let oauthRedirectURIs = this.props.organisationdata.oauthRedirectURI ? this.props.organisationdata.oauthRedirectURI.split(';') : [];

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Oauth2</Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" height="1200px">
                    <PopUp
                        header=""
                        open={this.state.isSaved}
                        text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                        onClose={() => this.onClose()}
                        createOrg={false}
                        orgName={organisationdefault.name}
                        error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box margin={{ "bottom": "medium" }}>
                            <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                                <Tab title="Configure">
                                    <Box gap="medium" margin={{ "top": "small" }}>
                                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                                            <Box width="75px">
                                                <FormField label="Active">
                                                    <Box pad="small">
                                                        <CheckBox checked={organisationdefault.oauthEnabled} onChange={() => this.updateOauth()} />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                            <Text>All fields marked with an * are mandatory.</Text>
                                            <FormField label="Client ID *" error={this.oauthClientId_err}>
                                                <Box direction="row" justify="between" pad={{ right: "small" }}>
                                                    <TextInputWithState onInput={this.updateoauthClientId} value={this.state.viewOauthClientId ? organisationdefault.oauthClientId : organisationdefault.oauthClientId != "" ? "********" : ""} disabled={!this.state.viewOauthClientId} plain type={this.state.viewOauthClientId ? "text" : "password"} />
                                                    <CheckBox label="View" checked={this.state.viewOauthClientId} onChange={() => this.setState({ viewOauthClientId: !this.state.viewOauthClientId })} />
                                                </Box>
                                            </FormField>
                                            <FormField label="Client Secret *" error={this.oauthClientSecret_err}>
                                                <Box direction="row" justify="between" pad={{ right: "small" }}>
                                                    <TextInputWithState onInput={this.updateoauthClientSecret} value={this.state.viewOauthClientSecret ? organisationdefault.oauthClientSecret : organisationdefault.oauthClientSecret != "" ? "********" : ""} disabled={!this.state.viewOauthClientSecret} plain type={this.state.viewOauthClientSecret ? "text" : "password"} />
                                                    <CheckBox label="View" checked={this.state.viewOauthClientSecret} onChange={() => this.setState({ viewOauthClientSecret: !this.state.viewOauthClientSecret })} />
                                                </Box>
                                            </FormField>
                                            {/*<FormField label="Redirect URI" error={this.oauthRedirectURI_err}>*/}
                                            {/*    <Box direction="row" justify="between" pad={{ right: "small" }}>*/}
                                            {/*        <TextInputWithState onInput={this.updateoauthRedirectURI} value={this.state.viewOauthRedirectURI ? organisationdefault.oauthRedirectURI : organisationdefault.oauthRedirectURI != "" ? "********" : ""} disabled={!this.state.viewOauthRedirectURI} plain />*/}
                                            {/*        <CheckBox label="View" checked={this.state.viewOauthRedirectURI} onChange={() => this.setState({ viewOauthRedirectURI: !this.state.viewOauthRedirectURI })} />*/}
                                            {/*    </Box>*/}
                                            {/*</FormField>*/}
                                            {/*<FormField label="Discovery URL" error={this.oauthDiscoveryURL_err}>*/}
                                            {/*    <Box direction="row" justify="between" pad={{ right: "small" }}>*/}
                                            {/*        <TextInputWithState onInput={this.updateoauthDiscoveryURL} value={this.state.viewOauthDiscoveryURL ? organisationdefault.oauthDiscoveryURL : organisationdefault.oauthDiscoveryURL != "" ? "********" : ""} disabled={!this.state.viewOauthDiscoveryURL} plain />*/}
                                            {/*        <CheckBox label="View" checked={this.state.viewOauthDiscoveryURL} onChange={() => this.setState({ viewOauthDiscoveryURL: !this.state.viewOauthDiscoveryURL })} />*/}
                                            {/*    </Box>*/}
                                            {/*</FormField>*/}
                                            {false && <FormField label="Redirect URI *" error={this.oauthRedirectURI_err}>
                                                <Box direction="row" justify="between" pad={{ right: "small" }}>
                                                    <TextInputWithState onInput={this.updateoauthRedirectURI} value={organisationdefault.oauthRedirectURI} plain />
                                                </Box>
                                            </FormField>}
                                            {true && <Grommet theme={ffTheme} >
                                                <FormField label='Redirect URI *' contentProps={{ width: "60%" }} error={this.max}>
                                                    <Box border="all" gap="xxsmall">
                                                        {oauthRedirectURIs.map((uri, idx) => (
                                                            <Box direction="row" key={idx} pad={{ "left": "xxsmall", "top": "xxsmall" }} justify="between">
                                                                <Box height="100%" width="80%">
                                                                    <Grommet theme={customTheme} >
                                                                        <FormField error={this.state.selected == uri ? this.URIedit_err : ""}>
                                                                            <TextInputWithState
                                                                                value={uri} plain={!(this.state.selected == uri)}
                                                                                disabled={!(this.state.selected == uri)}
                                                                                onInput={evt => this.setState({ inputforURI: evt.target.value })}
                                                                            />
                                                                        </FormField>
                                                                    </Grommet>
                                                                </Box>

                                                                <Box direction="row">
                                                                    {this.state.selected == uri ? (
                                                                        <Button icon={<Checkmark />}
                                                                            onClick={() => {
                                                                                this.max = "";
                                                                                let list = [...this.props.organisationdata.oauthRedirectURI.split(';')];
                                                                                list[idx] = this.state.inputforURI;
                                                                                let uriStr = list.join(';');

                                                                                let duplicationList = this.props.organisationdata.oauthRedirectURI.split(";");
                                                                                duplicationList.splice(idx, 1);
                                                                                if (this.isValidURI(this.state.inputforURI) && this.state.inputforURI.length < 256 &&
                                                                                    uriStr.length < 56324 &&
                                                                                    //(this.isUniqueURI(this.props.organisationsdata.map(item => item.domains.split(",")), this.state.inputforURI) ||
                                                                                        //this.state.inputforURI == this.state.URIforedit &&
                                                                                    this.isUniqueURI(duplicationList, this.state.inputforURI)) {
                                                                                    this.setState({ selected: -1, URIeditover: true });
                                                                                    this.URIedit_err = "";
                                                                                    this.updateURI(this.state.inputforURI, idx);
                                                                                }
                                                                                //else if (/*!this.isUniqueURI(this.props.organisationsdata.map(item => item.domains.split(",")), this.state.inputforURI) &&*/
                                                                                //    this.isUniqueURI(this.props.organisationdata.oauthRedirectURI.split(","), this.state.inputforURI) &&
                                                                                //    this.state.inputforURI != this.state.URIforedit) {
                                                                                //    this.URIedit_err = constants.DOMAIN_NOT_UNIQUE_ERROR;
                                                                                //    this.setState({ selected: uri });
                                                                                //}
                                                                                else if (!this.isUniqueURI(this.props.organisationdata.oauthRedirectURI.split(";"), this.state.inputforURI) &&
                                                                                    this.state.inputforURI != this.state.URIforedit) {
                                                                                    this.URIedit_err = constants.URI_THIS_ORG_ERROR;
                                                                                    this.setState({ selected: uri });
                                                                                }
                                                                                else if (!(this.state.inputforURI.length < 256)) {
                                                                                    this.URIedit_err = "This field can't be longer than 255 character";
                                                                                    this.setState({ selected: uri });
                                                                                }
                                                                                else if (uriStr.length >= 56324) {
                                                                                    this.max = "This field can't be longer than 56323 character";
                                                                                    this.setState({});
                                                                                }
                                                                                else {
                                                                                    this.URIedit_err = constants.URI_ERROR;
                                                                                    //this.URIedit_err = "O-O"
                                                                                    this.setState({ selected: uri });
                                                                                }
                                                                            }} />
                                                                    ) : (
                                                                        <Button icon={<Edit />}
                                                                            onClick={() => {
                                                                                let list = [...this.props.organisationdata.oauthRedirectURI.split(';')];
                                                                                if (this.state.URIeditover) {
                                                                                    this.setState({ selected: uri, URIforedit: list[idx], inputforURI: list[idx], URIeditover: false });
                                                                                }
                                                                            }} />
                                                                    )}
                                                                    <Button icon={<Trash />}
                                                                        onClick={() => {
                                                                            let data = oauthRedirectURIs;
                                                                            data.splice(idx, 1)
                                                                            this.props.organisationdata.oauthRedirectURI = data.join(';');
                                                                            if (uri == this.state.selected) {
                                                                                this.URIedit_err = "";
                                                                                this.setState({ selected: -1, URIeditover: true });
                                                                            }
                                                                            else
                                                                                this.setState({ update: true });
                                                                        }} />
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                        <Box margin={{ "vertical": "xxsmall" }}>
                                                            <Box direction="row" margin={{ "left": "xxsmall" }} justify="between">
                                                                <Box height="100%" width="80%">
                                                                    <Grommet theme={customTheme} >
                                                                        <FormField error={this.URIs_err}>
                                                                            <TextInputWithState key={this.state.domAddKey} placeholder="New URI" value={this.newURI} plain onInput={this.handleAddURIChange} />
                                                                        </FormField>
                                                                    </Grommet>
                                                                </Box>
                                                                <Box margin={{ "right": "small" }}>
                                                                    <StyledButton typeAdd label="Add" onClick={this.handleAddURI} margin={{ "vertical": "xsmall" }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </FormField>
                                            </Grommet>}
                                            <FormField label="Discovery URL *" error={this.oauthDiscoveryURL_err}>
                                                <Box direction="row" justify="between" pad={{ right: "small" }}>
                                                    <TextInputWithState onInput={this.updateoauthDiscoveryURL} value={organisationdefault.oauthDiscoveryURL} plain />
                                                </Box>
                                            </FormField>
                                            <Box width="60px">
                                                <FormField label="Login Hint" error={this.loginHint_err}>
                                                    <Box pad="small" align="center">
                                                        <a data-for="hint" data-tip="Applying a login hint enhances the login experience as the user's email address is passed as a login hint,<br />
                                                            on behalf of the user, to ensure the user does not have to type in their login/email address twice.<br />
                                                            This is enabled, by default, but can be turned off for testing purposes. It is recommended to have this setting enabled in Production">
                                                            <CheckBox checked={organisationdefault.loginHintEnabled} onChange={() => (organisationdefault.loginHintEnabled = !organisationdefault.loginHintEnabled, this.setState({ updated: true }))} />
                                                        </a>
                                                        <ReactTooltip id="hint" place="right" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                        </StyledBox>

                                        <Box gap="xsmall" width="500px">
                                            <Text size="large">Attributes</Text>
                                            <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                <FormField label="Scope" error={this.scope_err}>
                                                    <Box direction="row" justify="between">
                                                        <TextInput onChange={evt => { organisationdefault.oauthScope = evt.target.value, this.setState({ updated: true }) }} value={organisationdefault.oauthScope} plain />
                                                    </Box>
                                                </FormField>
                                                <FormField label="Email" error={this.email_err}>
                                                    <Box direction="row" justify="between">
                                                        <TextInput onChange={evt => { organisationdefault.oauthEmailClaim = evt.target.value, this.setState({ updated: true }) }} value={organisationdefault.oauthEmailClaim} plain />
                                                    </Box>
                                                </FormField>
                                                <FormField label="Full Name" error={this.fullName_err}>
                                                    <Box direction="row" justify="between">
                                                        <TextInput onChange={evt => { organisationdefault.oauthFullNameClaim = evt.target.value, this.setState({ updated: true }) }} value={organisationdefault.oauthFullNameClaim} plain />
                                                    </Box>
                                                </FormField>
                                                <FormField label="First Name" error={this.firstName_err}>
                                                    <Box direction="row" justify="between">
                                                        <TextInput onChange={evt => { organisationdefault.oauthFirstNameClaim = evt.target.value, this.setState({ updated: true }) }} value={organisationdefault.oauthFirstNameClaim} plain />
                                                    </Box>
                                                </FormField>
                                            </StyledBox>
                                        </Box>
                                    </Box>
                                </Tab>
                                <Tab title="Documentation">
                                    <StyledBox pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                        <Text>
                                            Nothing to see here yet, Documentation coming soon
                                    </Text>
                                    </StyledBox>
                                </Tab>
                            </Tabs>
                        </Box>
                    </form>
                </Box>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || '';
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgIntegrationOauthEditContainer));
