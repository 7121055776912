import * as ActionType from './ActionType';
import ManufacturerApi from '../api/ManufacturerApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getManufacturersResponse = manufacturersdata => ({
    type: ActionType.GET_MANUFACTURERS_RESPONSE,
    manufacturersdata
});

const queuedGetAllManufacturers = queueApiCall(ManufacturerApi.getAllManufacturers);

export function getManufacturersAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllManufacturers()
            .then(manufacturersdata => {
                dispatch(getManufacturersResponse(manufacturersdata));
                return manufacturersdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewManufacturerResponse = () => ({
    type: ActionType.ADD_NEW_MANUFACTURER_RESPONSE
});

export const updateExistingManufacturerResponse = () => ({
    type: ActionType.UPDATE_EXISTING_MANUFACTURER_RESPONSE
});

export function saveManufacturerAction(manufacturerdata) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return ManufacturerApi.saveManufacturer(manufacturerdata)
            .then((response) => {
                if (manufacturerdata.id) {
                    dispatch(updateExistingManufacturerResponse());
                } else {
                    dispatch(addNewManufacturerResponse());
                }
                return response;
            }).then((response) => {
                if (manufacturerdata.id == "0") {
                    dispatch(getManufacturerAction(manufacturerdata.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getManufacturerResponse = manufacturerdata => ({
    type: ActionType.GET_MANUFACTURER_RESPONSE,
    manufacturerdata
});

export function getManufacturerAction(manufacturerId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return ManufacturerApi.saveManufacturer(manufacturerId)
            .then(manufacturerdata => {
                dispatch(getManufacturerResponse(manufacturerdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteManufacturerResponse = () => ({
    type: ActionType.DELETE_MANUFACTURER_RESPONSE
});

const queuedDeleteManufacturer = queueApiCall(ManufacturerApi.deleteManufacturer);

export function deleteManufacturerAction(manufacturerId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteManufacturer(manufacturerId)
            .then((response) => {
                dispatch(deleteManufacturerResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getManufacturerAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}