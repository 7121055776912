import { adalGetUser, authContext } from '../../adal/adalConfig';
import UserApi from '../../api/UserApi';
import RoleApi from '../../api/RoleApi';
import { pca } from '../../../src/indexApp';
import { useOktaAuth } from '@okta/okta-react';

let logonuser;
let userperms = [];

let locPerms;
let orgPerms;


let extenralResolve;
let externalReject;

function addPermissionToList(scope, ptype, map) {
    map[scope] = map[scope] || [];
    map[scope].push(ptype);
}

let userPermsStatus = new Promise((resolve, reject) => {
    extenralResolve = resolve;
    externalReject = reject;
});
userPermsStatus.done = false;


export { userPermsStatus };

userPermsStatus.then(() => console.log('********** User rights resolved. **********'));


export async function getLogonUserPermissions() {
    console.log('**********  getLogonUserPermissions started.... **********');
    //let user = adalGetUser();
    let oktaUser = localStorage.getItem("okta-token-storage");
    if (oktaUser) {
        oktaUser = JSON.parse(oktaUser);
        oktaUser = !_.isEmpty(oktaUser) ? oktaUser : null;
    }
    else
        oktaUser = null;
    let user = pca.getAllAccounts()[0];
    if (!user && !oktaUser) {
        console.log('**********  getLogonUserPermissions returned because there is no user logged in.... **********');
        return;
    }
    //const username = user.profile.name;
    //const email = user.profile.unique_name;
    // const email = 'ks@academia.org';
    // const email = 'ns@hpe.com';
    // const email = 'ivbela@history.hu';
    console.log("naaaaaaaaaaaaaaR", user, oktaUser);
    //const email = "bence.koppany@hpe.com";
    const email = user ? user.username : (oktaUser.accessToken && oktaUser.accessToken.claims) ? oktaUser.accessToken.claims.sub : "Unknown";

    // if location already set, save and clear it
    let savedlocid = sessionStorage.getItem("locationId");
    if (savedlocid) {
        sessionStorage.removeItem("locationId");
    }

    let locOrgId = sessionStorage.getItem('organisationId') ? sessionStorage.getItem('organisationId') : null;

    await UserApi.getUserAllRoles(email)
        .then(userdata => {
            logonuser = userdata;
            console.log('logonuser: ', logonuser);
            return userdata;
        })
        .then(appuser => {
            if (appuser) {
                let rolesPromises = appuser.userRoles.map(role => RoleApi.getRole(role.roleId, true).then(roledef => {
                    roledef.locationId = role.locationId;
                    roledef.orgid = role.organizationId;
                    return roledef;
                }));
                return Promise.all(rolesPromises).then(roles => {
                    console.log('AllUserRoles: ', roles);
                    let permissions = [];
                    roles.forEach(role => {
                        permissions = permissions.concat(role.permissions.map(permsdef => {
                            permsdef.locationId = role.locationId;
                            permsdef.orgid = role.orgid;
                            permsdef.level = role.level;
                            return permsdef;
                        }));
                    });
                    return permissions;
                })
            }
            return [];
        })
        .then(permissions => {
            userperms = permissions;
            console.log('UserPerms: ', userperms);
            let locpm = {};
            let orgpm = {};
            userperms.forEach(perm => {
                if (perm.level === 'Organization') {
                    addPermissionToList(perm.orgid, perm.type, orgpm);
                } else if (perm.level === 'Location') {
                    addPermissionToList(perm.locationId, perm.type, locpm);
                } else {
                    console.log('***** Permission error, invalid permission level: ', perm.level);
                }
            });

            for (let scope in locpm) {
                locpm[scope] = [...new Set(locpm[scope])];
            }
            locPerms = locpm;

            for (let scope in orgpm) {
                orgpm[scope] = [...new Set(orgpm[scope])];
            }
            orgPerms = orgpm;

            // set orgID from user roles...
            //if (!sessionStorage.getItem('organisationId') && !isSuperUser() && userperms.length > 0) {
            //    sessionStorage.setItem('organisationId', userperms[0].orgid);
            //}

            // console.log('userperms: ', userperms);
            // console.log('logonuser: ', logonuser);
            console.log('locPerms', locPerms);
            console.log('orgPerms', orgPerms);

        })
        .catch(error => {
            console.error('Error get allusers:', error);
            externalReject(error);
        });

    if (savedlocid) {
        sessionStorage.setItem("locationId", savedlocid);
    }
    extenralResolve(42);
    userPermsStatus.done = true;
    console.log('**********  getLogonUserPermissions ended.... **********');
};



// permission types:
//     Administration_Write, Content_Read, Content_Write, Events_Read, Events_Write, Integration_Write, LocationServices_Read
//     Maps_Write, Notifications_Read, Notifications_Write, SiteSettings_Read, SiteSettings_Write


function checkPermission(object, type, scope, permsissionMap) {
    if (!permsissionMap || !(scope in permsissionMap)) {
        return false;
    }
    let permissionType = object.concat(`_${type}`);
    return permsissionMap[scope].includes(permissionType);
}

function isSuperUser() {
    if (logonuser) {
        return logonuser.superUser && logonuser.active;
    } else {
        return false;
    }
}

export default {
    canRead: function (object) {
        let locId = sessionStorage.getItem("locationId");
        let orgId = sessionStorage.getItem("organisationId");
        let canread = checkPermission(object, "Write", orgId, orgPerms)
            || checkPermission(object, "Read", orgId, orgPerms)
            || checkPermission(object, "Write", locId, locPerms)
            || checkPermission(object, "Read", locId, locPerms);
        // console.log(`canRead: ptype:${object} canRead: ${canread}`);
        return isSuperUser() || canread && (logonuser ? logonuser.active : false);
    },

    canEdit: function (object) {
        let locId = sessionStorage.getItem("locationId");
        let orgId = sessionStorage.getItem("organisationId");
        let canwrite = checkPermission(object, "Write", orgId, orgPerms)
            || checkPermission(object, "Write", locId, locPerms);
        // console.log(`canWrite: ptype:${object} canWrite: ${canwrite}`);
        return isSuperUser() || canwrite && (logonuser ? logonuser.active : false);
    },

    canAccessLocation: function (scope) {
        let locId = sessionStorage.getItem("locationId");
        let orgId = sessionStorage.getItem("organisationId");
        scope = scope || locId;
        if (isSuperUser()) {
            return true;
        }
        else if (logonuser && !logonuser.active) {
            return false;
        }
        else {
            return (orgId in (orgPerms || [])) || (scope in (locPerms || []));
        }
        //return (orgId in (orgPerms || [])) || (scope in (locPerms || [])) && (logonuser ? logonuser.active : false);
    },

    isSuperUser,

};
