import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

const PaginatedDataTable = withPagination(DataTable);

class MetricsUserListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }

    renderMetricsTable(props) {
        const COLUMNS = [
            //{
            //    property: 'lcdevice',
            //    header: 'Device',
            //    render: metricsdata => <Text>{metricsdata.device}</Text>
            //},
            //{
            //    property: 'lcversion',
            //    header: 'OS Version',
            //    render: metricsdata => <Text>{metricsdata.osVersion}</Text>
            //},
            {
                property: 'lcusername',
                header: 'User',
                render: metricsdata => <Text>{metricsdata.userId}</Text>
            },
            {
                property: 'lcemail',
                header: 'Email',
                //size: "700px",
                render: metricsdata => <Text>{metricsdata.userId}</Text>
            },
            {
                property: 'isRead',
                header: 'Read',
                render: metricsdata => <CheckBox disabled checked={metricsdata.isRead} />
            },
        ];

        let DATA = [];
        if (props.metricsdata[0] && props.metricsdata[0].count != 0) {
            props.metricsdata.forEach(ev => {
                DATA.push({
                    ...ev,
                    lcdevice: ev.device ? ev.device.toLowerCase() : '',
                    lcversion: ev.osVersion ? ev.osVersion.toLowerCase() : '',
                    lcusername: ev.userId ? ev.userId.toLowerCase() : '',
                    lcemail: ev.userId ? ev.userId.toLowerCase() : '',
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.lcdevice.includes(search) ||
                d.lcversion.includes(search) ||
                d.lcusername.includes(search) ||
                d.lcemail.includes(search) ||
                ("read".includes(search) && d.isRead)
            );
        }

        console.log('DATA:', DATA);


        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctitle" || c.property === "lcorganiser" || c.property === "lclocation"
                }))}
                data={DATA}
                noLoadingMsg={this.props.metricsdata.length != 0}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={1}
                PFcsvdata={DATA.map(d => ({ User: d.userId, Email: d.userId, Read: d.isRead ? "TRUE" : "FALSE" }))}
                PFcsvfilename="metrics_user-export"
            />
        );
    }

    render() {
        return (
            <Box>
                <Box direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    //pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Users </Heading>
                </Box>


                <Box  background={constants.BACKGROUND_COLOR} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <Box style={{ "min-width": "870px" }}>
                            {this.renderMetricsTable(this.props)}
                        </Box>
                    </IconThemeContext.Extend>
                </Box>
            </Box>
        );
    }
}

export default MetricsUserListTable;
