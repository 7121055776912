import React from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import RB, { userPermsStatus } from './RBAC';
import { Text } from 'grommet';
import { adalGetUser } from '../../adal/adalConfig';
import { Context } from '../Context';
import constants from '../../constants';
import LoadingIndicator from './LoadingIndicator';
import { MsalContext } from "@azure/msal-react";
import { getOktaToken } from '../../api/ApiUtils';
import { withOktaAuth } from '@okta/okta-react';


class ProtectedRoute extends React.Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = { rerender: Math.random() };
        this.isComputedMatch = false;
    }

    componentWillMount() {
        if (this.props.computedMatch.params.orgid && sessionStorage.getItem("organisationId") != this.props.computedMatch.params.orgid) {
            this.isComputedMatch = true;
            sessionStorage.setItem("organisationId", this.props.computedMatch.params.orgid);
            sessionStorage.setItem("organisationCode", "");
            if (this.props.computedMatch.params.locid)
                sessionStorage.setItem("locationId", this.props.computedMatch.params.locid);
            else
                sessionStorage.setItem("locationId", "");
        }
        if (this.props.computedMatch.params.orgid && sessionStorage.getItem("organisationId") == this.props.computedMatch.params.orgid) {
            if (this.props.computedMatch.params.locid)
                sessionStorage.setItem("locationId", this.props.computedMatch.params.locid);
        }
    }

    componentDidMount() {
        if (this.context.accounts.length == 0) {
            if (/*!getOktaToken*/!this.props.authState || (this.props.authState && !this.props.authState.isAuthenticated))
                this.props.history.push('/');
        }
        if (!userPermsStatus.done) {
            userPermsStatus.then(() => {
                this.setState({ rerender: Math.random() });
            });
        }
    }

    getRoleLevel(permtype) {
        return Object.keys(constants.PERMS).find(k => constants.PERMS[k] == permtype).substr(0, 3);
    }

    render() {
        let { component: Component, objprinc: permtype, ...rest } = this.props;
        // console.log('permtype', permtype);
        // console.log('userPermsStatus.done', userPermsStatus.done);
        this.getRoleLevel(permtype);
        if (userPermsStatus.done) {
            const isReadable = RB.canRead(permtype);
            return (
                <Switch>
                    <Route
                        {...rest}
                        render={props => {
                            if (isReadable &&
                                !(this.props.path.includes("placemark") && !constants.ISMERIDIANVISIBLE) &&
                                !(this.props.path.includes("meridianmap") && !constants.ISMERIDIANVISIBLE) &&
                                !(this.props.path.includes("spot") && !constants.ISCHARGIFIVISIBLE)) {
                                
                                return (<Context.Consumer>
                                    {values => {
                                        if (this.isComputedMatch && !values.layoutUpdated) {
                                            values.update();
                                        }
                                        let features = values.feature.filter(f => f.path.includes(this.props.path) /*|| this.props.path.includes(f.path)*/);
                                        let isAtLeastOneEnabled = false;
                                        features.forEach(f => {
                                            if (f.isEnabled) {
                                                isAtLeastOneEnabled = true;
                                                return;
                                            }
                                        });
                                        //let feature = values.feature.find(f => f.path.includes(this.props.path));
                                        //console.log("fifi", feature, this.props.path, values.feature);
                                        if (features.length > 0) {
                                            if (isAtLeastOneEnabled || !values.layoutUpdated) {
                                                return <Component {...props} />
                                            }
                                            else {
                                                if (this.getRoleLevel(permtype) != "SUP" && (!sessionStorage.getItem("organisationId") || sessionStorage.getItem("organisationId") == "")) {
                                                    return (
                                                        <Redirect to={{ pathname: "/orglist" }} />
                                                    );
                                                }
                                                if (this.getRoleLevel(permtype) == "LOC" && (!sessionStorage.getItem("locationId") || sessionStorage.getItem("locationId") == "")) {
                                                    return (
                                                        <Redirect to={{ pathname: constants.getOrgLevelRoute() + "/sites" }} />
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <Redirect to={{ pathname: "/noaccess" }} />
                                                    );
                                                }
                                            }
                                        }
                                        else {
                                            //If we dont find that feature, it means that it is a default one, visible all the time (can't hide)
                                            if (this.getRoleLevel(permtype) != "SUP" && (!sessionStorage.getItem("organisationId") || sessionStorage.getItem("organisationId") == "")) {
                                                return (
                                                    <Redirect to={{ pathname: "/orglist" }} />
                                                );
                                            }
                                            if (this.getRoleLevel(permtype) == "LOC" && (!sessionStorage.getItem("locationId") || sessionStorage.getItem("locationId") == "")) {
                                                return (
                                                    <Redirect to={{ pathname: constants.getOrgLevelRoute() + "/sites" }} />
                                                );
                                            }
                                            else {
                                                return <Component {...props} />
                                            }
                                        }
                                    }

                                    }
                                </Context.Consumer>);
                            }
                            else {
                                if (this.getRoleLevel(permtype) != "SUP" && (!sessionStorage.getItem("organisationId") || sessionStorage.getItem("organisationId") == "")) {
                                    return (
                                        <Redirect to={{ pathname: "/orglist" }} />
                                    );
                                }
                                if (this.getRoleLevel(permtype) == "LOC" && (!sessionStorage.getItem("locationId") || sessionStorage.getItem("locationId") == "")) {
                                    return (
                                        <Redirect to={{ pathname: constants.getOrgLevelRoute() + "/sites" }} />
                                    );
                                }
                                else {
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: "/nouser"
                                            }}
                                        />
                                    );
                                }
                            }
                        }}
                    />
                </Switch>
            );
        }
        else {
            return (
                <LoadingIndicator message="Loading User Permissions" />
            );
        }
    }
}
export default withRouter(withOktaAuth(ProtectedRoute));
