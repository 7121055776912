import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/api/TimeZones`;

class TimeZoneApi {
    static async getTimeZones() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

export default TimeZoneApi;
