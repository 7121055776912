import * as ActionType from './ActionType';
import PlacemarkApi from '../api/PlacemarkApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import initialState from '../reducer/initialState';


export const getPlacemarksResponse = placemarksdata => ({
    type: ActionType.GET_PLACEMARKS_RESPONSE,
    placemarksdata
});

export function getPlacemarksAction(deleteStore = true) {
    return (dispatch) => {
        if (deleteStore) dispatch(getPlacemarksResponse(initialState.placemarksReducer.placemarksdata));
        dispatch(ApiCallBeginAction());

        return PlacemarkApi.getAllPlacemarks()
            .then(placemarksdata => {
                dispatch(getPlacemarksResponse(placemarksdata));
                return placemarksdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewPlacemarkResponse = () => ({
    type: ActionType.ADD_NEW_PLACEMARK_RESPONSE
});

export const updateExistingPlacemarkResponse = () => ({
    type: ActionType.UPDATE_EXISTING_PLACEMARK_RESPONSE
});

export function savePlacemarkAction(placemarkdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the placemark is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new placemark that is being added, therefore add it
        return PlacemarkApi.savePlacemark(placemarkdraft)
            .then((response) => {
                if (placemarkdraft.id) {
                    dispatch(updateExistingPlacemarkResponse());
                } else {
                    dispatch(addNewPlacemarkResponse());
                }
                return response;
            }).then((response) => {
                //if (placemarkdraft.id == "0") {
                //    dispatch(getPlacemarkAction(placemarkdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getPlacemarkResponse = placemarkdata => ({
    type: ActionType.GET_PLACEMARK_RESPONSE,
    placemarkdata
});

export function getPlacemarkAction(placemarkId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return PlacemarkApi.getPlacemark(placemarkId)
            .then(placemarkdata => {
                dispatch(getPlacemarkResponse(placemarkdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deletePlacemarkResponse = () => ({
    type: ActionType.DELETE_PLACEMARK_RESPONSE
});

export function deletePlacemarkAction(placemarkId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return PlacemarkApi.deletePlacemark(placemarkId)
            .then((response) => {
                dispatch(deletePlacemarkResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getPlacemarksAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}