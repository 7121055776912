import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const pagesReducer = (state = initialState.pagesReducer, action) => {
    switch(action.type) {
        case ActionType.GET_PAGES_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                pagesdata: _.assign(action.pagesdata)
            };
        }


        default: { return state; }
    }
};



export default pagesReducer;