import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import CategoryListTable from './CategoryListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, Grid, CheckBox, Layer, Text } from 'grommet';
import AppListTable from './AppListTable';
import * as AppAction from '../action/AppAction';
import RB from './common/RBAC';
import constants from '../constants';
import MasterAppListTable from './MasterAppListTable';
import * as MasterAppAction from '../action/MasterAppAction';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

export class MasterAppListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        // this.state = { selectedCategory: 0, deletedCategoryId: 0, open: undefined };
        this.state = {
            selectedApp: 0,
            deletedAppIds: [],
            open: undefined,
            masterappsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    componentDidMount() {
        sessionStorage.setItem("locationId", "");
        sessionStorage.setItem("organisationId", "");
        this.props.action.getMasterAppsAction()
            .then(response => this.setState({ masterappsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedApp: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedApp;

        if (selectedId) {
            this.setState({ selectedApp: 0, open: undefined, deletedAppIds: this.state.deletedAppIds.concat([selectedId]) });
            this.props.action.deleteMasterAppAction(selectedId)
                .catch(error => {
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                    console.log('*DEBUG*- Category List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push('/masterapp/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/masterapp/' + nro);
    }

    render() {
        const { masterappsdata } = this.props;
        const { open, selectedApp, deletedAppIds, masterappsdataLoading } = this.state;

        if (masterappsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Master Apps </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeSave label="Create New" style={{ visibility: constants.visible(RB.isSuperUser()) }} alignSelf="center" onClick={this.handleNew} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium", "bottom": "medium" }} fill="vertical">
                    <Box flex justify='between' fill>
                        <form>
                            {open && (
                                <Layer
                                    position="center"
                                    modal={true}
                                    onClickOutside={this.onClose}
                                    onEsc={this.onClose}
                                >
                                    <Box pad="medium" gap="small" width="medium" >
                                        <Heading level={3} margin="none">
                                            Confirm
                            </Heading>
                                        <Text>{constants.confirmMessage("app")} This will delete the app from all sites and users will no longer see it as an option in the client app.</Text>
                                        <Box
                                            tag="footer"
                                            gap="small"
                                            direction="row"
                                            align="center"
                                            justify="end"
                                            pad={{ top: "medium", bottom: "small" }}
                                        >
                                            <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                            <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                        </Box>
                                    </Box>
                                </Layer>
                            )}

                            <MasterAppListTable masterappsdata={masterappsdata} handleNew={this.handleNew}
                                handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedAppIds={deletedAppIds} />
                        </form>
                    </Box>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    masterappsdata: state.masterAppsReducer.masterappsdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators(MasterAppAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterAppListContainer);