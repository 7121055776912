import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, SelectMultiple, CheckBox, Stack, FormField, Grommet, CheckBoxGroup } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose, CircleInformation, Aggregate, Download } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import FilterLayer from "./common/FilterLayer";
import PrimaryFilter from "./common/PrimaryFilter";
import { hpe } from 'grommet-theme-hpe';

const PaginatedDataTable = withPagination(DataTable);
class OrgTagListTable extends Component {

    constructor(props) {
        super(props);
        this.count = 0;
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            flag: [],
            search: "",
            status: "",
            statusId: "",
            level: "",
            levelId: "",
            control: [],
            floors: [],
            selectedBuilding: "",
            selectedBuildingId: "",
            isFilterOpen: false,
	        select: [],
            site: "",
            siteId: "",
            selectedCollections: [],
            department: [],
            departmentId: "",
            link: [],
            zone: "",
            zoneId: "",
            selectedLabels: [],
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            // console.log('<tr> s:', trs);
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (nro != 0) { //this is for the select function
                        if (elem.getElementsByTagName('th')[0]) {
                            //console.log('Add click item:', elem.getElementsByTagName('th')[0]);
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //console.log('Add click item:', tid);
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(trs[idx].getElementsByTagName('th')[0].textContent);
                            //});

                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    renderFilters() {
        let labelOptions = this.props.assetlabelsdata.sort(constants.byPropertyCalled());
        labelOptions = labelOptions.length > 0 ? labelOptions.concat([{ id: null, name: "" }]) : [];

        let collectionOption = this.props.collectionsdata.sort(constants.byPropertyCalled());
        collectionOption = collectionOption.length > 0 ? collectionOption.concat([{ id: null, name: "" }]) : [];

        let departmentOption = this.props.departmentsdata.sort(constants.byPropertyCalled());
        departmentOption = departmentOption.length > 0 ? departmentOption.concat([{ id: '', name: "" }]) : [];

        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} /*gap="small"*/>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Site">
                                <Select
                                    placeholder="Select"
                                    value={this.state.site}
                                    options={this.props.sitesdata}
                                    labelKey="name"
                                    onChange={evt => this.setState({ site: evt.option, siteId: this.props.sitesdata[evt.selected].id, selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "", zone: "", zoneId: "" })}
                                />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.site && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ site: "", siteId: "", selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "", zone: "", zoneId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Building">
                                <Select placeholder="Select" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId && m.locationId == this.state.siteId).map(b => b.name)}
                                    onChange={o => {
                                        const buildingid = this.props.buildingsdata.filter(m => m.meridianId && m.locationId == this.state.siteId)[o.selected].id;
                                        const buildingmeridianid = this.props.buildingsdata.filter(m => m.meridianId && m.locationId == this.state.siteId)[o.selected].meridianId;
                                        const floors = this.props.levelsdata.filter(f => f.buildingId == buildingid && f.meridianId != "");
                                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, level: "", levelId: "", zone: "", zoneId: "" });
                                    }} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedBuilding && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "", zone: "", zoneId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Floor">
                                <Select placeholder="Select" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId, zone: "", zoneId: "" })} labelKey="name">
                                    {option => {
                                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {
                                            return (
                                                <Box pad="small">
                                                    <b>
                                                        {option.name}
                                                    </b>
                                                </Box>);
                                        }
                                        return (
                                            <Box pad="small">
                                                {option.name}
                                            </Box>);
                                    }}
                                </Select>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.level && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ level: "", levelId: "", zone: "", zoneId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Zone">
                                <Select placeholder="Select" value={this.state.zone}
                                    options={[...new Set(this.props.assetsdata.filter(f => f.zoneId && f.locationId == this.state.siteId && f.map && f.map.group && f.map.group.id == this.state.selectedBuildingId && (this.state.level == "" || (this.state.level && this.state.level.meridianId == f.mapId))).map(zn => zn.zone.name).sort())]}
                                    onChange={evt => this.setState({ zone: evt.option, zoneId: this.props.assetsdata.filter(f => f.zoneId).find(fi => fi.zone.name == evt.option).zone.name })}>
                                    {option => {
                                        if (this.props.assetsdata.find(a => a.zoneId == option.zoneId)) {
                                            return (
                                                <Box pad="small">
                                                    <b>
                                                        {option}
                                                    </b>
                                                </Box>);
                                        }
                                        return (
                                            <Box pad="small">
                                                {option}
                                            </Box>);
                                    }}
                                </Select>
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.zone && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ zone: "", zoneId: "" })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Label">
                                {this.props.assetlabelsdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={labelOptions}
                                        value={this.state.selectedLabels}
                                        onChange={o => {
                                            let tmp = [...this.state.selectedLabels];
                                            if (o.option.length > 1) {
                                                if (tmp.length > 0) {
                                                    tmp = [];
                                                } else if (tmp.length == 0) {
                                                    tmp = o.option;
                                                }
                                            } else if (tmp.map(c => c.id).includes(o.option.id)) {
                                                tmp = tmp.filter(t => t.id != o.option.id);
                                            } else {
                                                tmp.push(o.option);
                                            }
                                            this.setState({ selectedLabels: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.assetlabelsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.assetlabelsdata}
                                        value={this.state.selectedLabels}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedLabels.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedLabels: [] })}
                    />}
                </Box>

                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Collection">
                                {this.props.collectionsdata.length > 0 &&
                                    <SelectMultiple
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={collectionOption}
                                        value={this.state.selectedCollections}
                                        onChange={o => {
                                            let tmp = [...this.state.selectedCollections];
                                            if (o.option.length > 1) {
                                                if (tmp.length > 0) {
                                                    tmp = [];
                                                } else if (tmp.length == 0) {
                                                    tmp = o.option;
                                                }
                                            } else if (tmp.map(c => c.id).includes(o.option.id)) {
                                                tmp = tmp.filter(t => t.id != o.option.id);
                                            } else {
                                                tmp.push(o.option);
                                            }
                                            this.setState({ selectedCollections: tmp });
                                        }}
                                        sortSelectedOnClose={false}
                                        showSelectedInline
                                    />}
                                {this.props.collectionsdata.length == 0 &&
                                    <Select
                                        placeholder="Select Collection"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.collectionsdata}
                                        value={this.state.selectedCollections}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.selectedCollections.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedCollections: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Department">
                                {this.props.departmentsdata.length > 0 &&
                                <SelectMultiple placeholder="Select"
                                    value={this.state.department}
                                    options={departmentOption}
                                    labelKey="name"
                                    valueKey="id"
                                    onChange={o => {
                                        let tmp = [...this.state.department];
                                        if (o.option.length > 1) {
                                            if (tmp.length > 0) {
                                                tmp = [];
                                            } else if (tmp.length == 0) {
                                                tmp = o.option;
                                            }
                                        } else if (tmp.map(c => c.id).includes(o.option.id)) {
                                            tmp = tmp.filter(t => t.id != o.option.id);
                                        } else {
                                            tmp.push(o.option);
                                        }
                                        this.setState({ department: tmp });
                                    }}
                                    sortSelectedOnClose={false}
                                    showSelectedInline
                                />}
                                {this.props.departmentsdata.length == 0 &&
                                    <Select
                                        placeholder="Select"
                                        labelKey="name"
                                        valueKey="id"
                                        options={this.props.departmentsdata}
                                        value={this.state.department}
                                    />}
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.department.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ department: "", departmentId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Control">
                                <CheckBoxGroup
                                    options={["Control Tags", "Tags"]}
                                    value={this.state.control}
                                    onChange={evt => this.setState({ control: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.control.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ control: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Link Status">
                                <CheckBoxGroup
                                    options={["Linked", "Unlinked"]}
                                    value={this.state.link}
                                    onChange={evt => this.setState({ link: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.link.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ link: [] })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox width="80%">
                        <Grommet theme={hpe}>
                            <FormField label="Device Flag">
                                <CheckBoxGroup
                                    options={["Healthy", "Low Charge", "Orphans", "Untraceable"]}
                                    value={this.state.flag}
                                    onChange={evt => this.setState({ flag: evt.value })} />
                            </FormField>
                        </Grommet>
                    </StyledBox>
                    {this.state.flag.length > 0 && <Button
                        style={{ "margin-top": "26px" }}
                        icon={<FormClose />}
                        onClick={() => this.setState({ flag: [] })}
                    />}
                </Box>
            </Box>
        )
    }
    getData(data) {
        //"Orphans", "Healthy", "Untracable", "Low Charge"
        //var data = this.props.assetsdata;

        /*
            Healthy: All tags are linked and traceable
            Untraceable: Tags that are not transmitting a location
            Low Charge: Tags that have a battery level below 20%
            Orphans: Tags that are not currently linked
        */
        if (this.state.flag.length > 0) {
            data = data.filter(d =>
                (this.state.flag.includes("Orphans") && !d.isAssigned) ||
                (this.state.flag.includes("Healthy") && d.traceable && d.isAssigned) ||
                (this.state.flag.includes("Untraceable") && d.traceable != true) ||
                (this.state.flag.includes("Low Charge") && d.batteryLevel < 20)
            );
        }
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.id.toLowerCase().includes(search) ||
                d.externalId.toLowerCase().includes(search) ||
                d.lastheard.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.name.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.group.name.toLowerCase().includes(search) ||
                d.lcCollectionName.includes(search) ||
                d.lcDepartmentName.includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                ("control tag".includes(search) && d.isControlTag) ||
                ("linked".includes(search) && d.isAssigned) ||
                d.lcZone.includes(search)
            );
        }
        if (this.state.status) {
            data = data.filter(d => d.serviceStateId == this.state.statusId);
        }
        if (this.state.siteId) {
            data = data.filter(d => d.locationId == this.state.siteId);
        }
        if (this.state.selectedBuildingId) {
            data = data.filter(d => d.map && d.map.group && d.map.group.id == this.state.selectedBuildingId);
        }
        if (this.state.levelId) {
            data = data.filter(d => d.mapId == this.state.levelId);
        }
        if (this.state.zone) {
            data = data.filter(d => d.lcZone == this.state.zone.toLowerCase());
        }
        if (this.state.control.length == 1 && this.state.control.includes("Control Tags")) {
            data = data.filter(d => d.isControlTag == true);
        }
        else if (this.state.control.length == 1 && this.state.control.includes("Tags")) {
            data = data.filter(d => d.isControlTag == false);
        }
        if (this.state.selectedLabels.length > 0) {
            data = data.filter(d => {
                if (d.assetBeaconTags.length == 0) {
                    return this.state.selectedLabels.some(l => l.id == null);
                }
                else {
                    let i = 0;
                    while (i < this.state.selectedLabels.length) {
                        if (d.assetBeaconTags.find(l => l.tag && l.tag.name == this.state.selectedLabels[i].name/*l.assetLabelId == this.state.selectedLabels[i].id*/))
                            return true;
                        i++;
                    }
                    return false;
                }
            });
        }
        if (this.state.selectedCollections.length > 0) {
            data = data.filter(d => this.state.selectedCollections.map(e => e.id).includes(d.collectionId));
        }
        //if (this.state.departmentId) {
        //    data = data.filter(d => d.departmentId == this.state.departmentId);
        //}
        if (this.state.department.length > 0) {
            data = data.filter(d => this.state.department.map(e => e.id).includes(d.departmentId));
        }
        if (this.state.link.length == 1 && this.state.link.includes("Linked")) {
            data = data.filter(d => d.isAssigned == true);
        }
        else if (this.state.link.length == 1 && this.state.link.includes("Unlinked")) {
            data = data.filter(d => d.isAssigned == false);
        }
        return data;
    }
    formatDate(utc) {
        var date = new Date(utc);
        var strDate =
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
            + "-" + ("00" + date.getDate()).slice(-2)
            + " "
            + ("00" + date.getHours()).slice(-2) + ":"
            + ("00" + date.getMinutes()).slice(-2)
            + ":" + ("00" + date.getSeconds()).slice(-2);
        return strDate;
    }

    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.Exp_Data = [];
        this.props.assetsdata.map(e => e.batteryLevel == 999 ? e.batteryLevel = 0 : null);
        if (this.props.assetsdata[0] && this.props.assetsdata[0].count != 0) {
            this.props.assetsdata.forEach(ev => {
                var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
                statusName = statusName ? statusName.name : "";

                let tracker = this.props.associationsdata && this.props.associationsdata[0] && this.props.associationsdata[0].count != 0 ? this.props.associationsdata.find(a => a.beaconId == ev.id) : false;
                let isAssigned = tracker ? true : false;
                let collectionId = tracker && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId).collectionId : null;
                let departmentId = tracker && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId).departmentId : "";             
                let collection = collectionId && this.props.collectionsdata && this.props.collectionsdata.length > 0 && this.props.collectionsdata[0] && this.props.collectionsdata[0].count != 0 ? this.props.collectionsdata.find(c => c.id == collectionId): "";
                let collectionName = collection ? collection.name : "";
                let department = departmentId && this.props.departmentsdata && this.props.departmentsdata.length > 0 && this.props.departmentsdata[0] && this.props.departmentsdata[0].count != 0 ? this.props.departmentsdata.find(d => d.id == departmentId): "";
                let departmentName = department? department.name : "";
                tracker = tracker ? tracker.assetName : "";

                let site = ev.locationId && this.props.sitesdata ? this.props.sitesdata.find(s => s.id == ev.locationId) : "";
                let siteName = site ? site.name : "";
                this.DATA.push({
                    ...ev,
                    lcname: ev.name != null ? ev.name.toLowerCase() : "",
                    lcbuilding: ev.map.group != null ? ev.map.group.name.toLowerCase() : "",
                    lcfloor: ev.map.name != null ? ev.map.name.toLowerCase() : "",
                    st: statusName,
                    lastheard: ev.timestamp ? this.formatDate(ev.timestamp) : "",
                    siteName: siteName,
                    isAssigned: isAssigned,
                    trackerName: tracker,
                    lcTrackerName: tracker.toLowerCase(),
                    Labels: ev.assetBeaconTags.length,
                    collectionId: collectionId,
                    departmentId: departmentId,
                    collectionName: collectionName,
                    lcCollectionName: collectionName.toLowerCase(),
                    departmentName: departmentName,
                    lcDepartmentName: departmentName.toLowerCase(),
                    no: ev.id,
                    lcZone: ev.zoneId && ev.zone ? ev.zone.name.toLowerCase() : ""
                })
                this.Exp_Data.push({
                    "Battery": ev.batteryLevel,
                    "Name": ev.name,
                    "ID": ev.id,
                    "External ID": ev.externalId,
                    "Last Heard At (UTC)": ev.timestamp && ev.mapId ? (ev.timestamp) : "",
                    "Site": siteName,
                    "Building": ev.map.group ? ev.map.group.name : "",
                    "Floor": ev.map.name,
                    "Zone": ev.zoneId && ev.zone ? ev.zone.name : "",
                    "Collection": collectionName,
                    "Department": departmentName,
                    "Control Tag": ev.isControlTag,
                    "Linked": isAssigned,
                    "Number of Labels": ev.assetBeaconTags.length,
                    "Labels": ev.assetBeaconTags.map(l => l.tag.name).toString()
                });
            });
        }
        this.DATA = this.getData(this.DATA);
        //this.DATA.sort((p1, p2) => {
        //    if (p1.lcbuilding == "aruba hq" || p2.lcbuilding == "aruba hq") {
        //        if (p2.lcbuilding == "aruba hq" && p1.lcbuilding == "aruba hq") {
        //            return !(p1.isControlTag || p2.isControlTag);
        //        }
        //        return -1;
        //    }
        //    return 1;
        //});
        this.DATA.sort((p1, p2) => {
            if (p1.lcbuilding > p2.lcbuilding)
                return -1;
            if (p1.lcbuilding == p2.lcbuilding) {
                //if (!p1.isControlTag && !p2.isControlTag) {
                //    console.log("hela",p1.lcname, p2.lcname);
                //    return p1.lcname > p2.lcname;
                //}
                return p1.isControlTag - p2.isControlTag;
            }
            return 1;
        });
        this.DATA.sort((i1, i2) => {
            if (i1.name < i2.name) return -1;
            if (i1.name == i2.name) { return 0; }
            else return 1;
        });
        this.Exp_Data.sort((i1, i2) => {
            if (i1.Name < i2.Name) return -1;
            if (i1.Name == i2.Name) { return 0; }
            else return 1;
        });
        this.count = this.DATA.length;
        //var sync = this.props.syncTime ? this.props.syncTime.replace("T", " ").replace("Z", "") : "";
        var sync = "";
        if (this.props.syncTime) {
            var date = new Date(this.props.syncTime);
            sync =
                ("00" + date.getDate()).slice(-2) + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                date.getFullYear() +
                " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2);
            //":" + ("00" + date.getSeconds()).slice(-2);
            sync.replace("T", " ").replace("Z", "")
        }
        return (
            <Box style={{ "min-width": "max-content" }}>
                {/*<Box width="100%" direction="row" justify="between" pad={{ bottom: "medium", "top": "small" }}>*/}
                {/*    <StyledBox style1 width="80%">*/}
                {/*        <TextInput placeholder="Search by Tag name, ID or classification" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />*/}
                {/*    </StyledBox>*/}
                {/*    <Box width="100px" alignSelf="center">*/}
                {/*        <StyledButton typeAdd width="100px" label="Filter" onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })} />*/}
                {/*    </Box>*/}
                {/*    <Box width="100px" alignSelf="center" align="center">*/}
                {/*        <CSVLink data={this.Exp_Data} filename="Exported Datatable.csv">*/}
                {/*            <a data-for="Download Info" data-tip="Export to CSV" >*/}
                {/*                <Button plain icon={<Download color={constants.HPE_GREEN} />} alignSelf="center" />*/}
                {/*            </a>*/}
                {/*            <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />*/}
                {/*        </CSVLink>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                {/*{this.state.isFilterOpen &&*/}
                {/*    <Box direction="row" justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                {/*        <Box width="90%">*/}
                {/*            <Grid columns={{ count: 7, size: "auto" }} gap="medium" >*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select*/}
                {/*                        placeholder="Select Site"*/}
                {/*                        value={this.state.site}*/}
                {/*                        options={this.props.sitesdata}*/}
                {/*                        labelKey="name"*/}
                {/*                        onChange={evt => this.setState({ site: evt.option, siteId: this.props.sitesdata[evt.selected].id })}*/}
                {/*                    />*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                {/*                        onChange={o => {*/}
                {/*                            const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                {/*                            const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;*/}
                {/*                            const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                {/*                            this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                {/*                        }} />*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                {/*                        {option => {*/}
                {/*                            if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {*/}
                {/*                                return (*/}
                {/*                                    <Box pad="small">*/}
                {/*                                        <b>*/}
                {/*                                            {option.meridianName}*/}
                {/*                                        </b>*/}
                {/*                                    </Box>);*/}
                {/*                            }*/}
                {/*                            return (*/}
                {/*                                <Box pad="small">*/}
                {/*                                    {option.meridianName}*/}
                {/*                                </Box>);*/}
                {/*                        }}*/}
                {/*                    </Select>*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select Control" value={this.state.control} options={["Control Tags", "Tags"]} onChange={o => { this.setState({ control: o.option }); }} />*/}
                {/*                </StyledBox>*/}
                {/*                <StyledBox style1>*/}
                {/*                    <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />*/}
                {/*                </StyledBox>*/}
                {/*                */}{/*<StyledBox style1>*/}{/*
                */}{/*                                <Select placeholder="Select Device Flag" value={this.state.flag} options={["Healthy", "Untraceable", "Low Charge", "Orphans"]}*/}{/*
                */}{/*                                    onChange={(opt) => {*/}{/*
                */}{/*                                        this.setState({ flag: opt.option });*/}{/*
                */}{/*                                    }} />*/}{/*
                */}{/*                            </StyledBox>*/}{/*
                */}{/*                            <StyledBox style1>*/}{/*
                */}{/*                                <Select placeholder="Select Status" value={this.state.status} options={constants.ASSET_STATUS} onChange={evt => { this.setState({ status: evt.option, statusId: evt.option.id }); console.log("status", evt.option) }} labelKey="name">*/}{/*
                */}{/*                                    {option =>*/}{/*
                */}{/*                                        <Box pad="small">*/}{/*
                */}{/*                                            {option.name}*/}{/*
                */}{/*                                        </Box>*/}{/*
                */}{/*                                    }*/}{/*
                */}{/*                                </Select>*/}{/*
                */}{/*                            </StyledBox> */}
                {/*            </Grid>*/}
                {/*        </Box>*/}
                {/*        <Box alignSelf="center" width="100px">*/}
                {/*            <StyledButton typeCancel label="Clear" onClick={() => this.setState({ flag: "", status: "", selectedBuilding: "", selectedBuildingId: "", level: "", levelId: "", control: "", floors: [], siteId: "", site: "", collectionId: "", departmentId: "", collection: "", department: "", link: "" })}*/}
                {/*                disabled={*/}
                {/*                    this.state.selectedBuilding == "" &&*/}
                {/*                    this.state.selectedBuildingId == "" &&*/}
                {/*                    this.state.level == "" &&*/}
                {/*                    this.state.levelId == "" &&*/}
                {/*                    this.state.control == "" &&*/}
                {/*                    this.state.flag == "" &&*/}
                {/*                    this.state.status == "" &&*/}
                {/*                    this.state.siteId == "" &&*/}
                {/*                    this.state.collection == "" &&*/}
                {/*                    this.state.collectionId == "" &&*/}
                {/*                    this.state.department == "" &&*/}
                {/*                    this.state.departmentId == "" &&*/}
                {/*                    this.state.link == ""*/}
                {/*                } />*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*}*/}
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Tag name, ID or classification"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                {/*    open={this.state.isFilterOpen}*/}
                {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                {/*    onResetFilter={() => this.setState({*/}
                {/*        site: "", siteId: "", selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",*/}
                {/*        collection: "", collectionId: "", department: "", departmentId: "", control: "", link: ""*/}
                {/*    })}*/}
                {/*    isClearVisible={(this.state.site == "" &&*/}
                {/*        this.state.selectedBuilding == "" &&*/}
                {/*        this.state.level == "" &&*/}
                {/*        this.state.collection == "" &&*/}
                {/*        this.state.department == "" &&*/}
                {/*        this.state.control == "" &&*/}
                {/*        this.state.link == "") ? false : true}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable={true}*/}
                {/*>*/}
                {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select*/}
                {/*                    placeholder="Select Site"*/}
                {/*                    value={this.state.site}*/}
                {/*                    options={this.props.sitesdata}*/}
                {/*                    labelKey="name"*/}
                {/*                    onChange={evt => this.setState({ site: evt.option, siteId: this.props.sitesdata[evt.selected].id })}*/}
                {/*                />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.site && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ site: "", siteId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                {/*                    onChange={o => {*/}
                {/*                        const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                {/*                        const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;*/}
                {/*                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                {/*                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                {/*                    }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.selectedBuilding && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                {/*                    {option => {*/}
                {/*                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {*/}
                {/*                            return (*/}
                {/*                                <Box pad="small">*/}
                {/*                                    <b>*/}
                {/*                                        {option.meridianName}*/}
                {/*                                    </b>*/}
                {/*                                </Box>);*/}
                {/*                        }*/}
                {/*                        return (*/}
                {/*                            <Box pad="small">*/}
                {/*                                {option.meridianName}*/}
                {/*                            </Box>);*/}
                {/*                    }}*/}
                {/*                </Select>*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.level && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ level: "", levelId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.collection && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ collection: "", collectionId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.department && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ department: "", departmentId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select Control" value={this.state.control} options={["Control Tags", "Tags"]} onChange={o => { this.setState({ control: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.control != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ control: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1>*/}
                {/*                <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.link != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ link: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox ref={this.dtwrapper} /*style={{ "min-width": "1500px" }}*/>
                    {this.renderAssetsTable(this.props, canEdit, sync)}
                </StyledBox>
                <Text>Last updated at: {sync}</Text>
            </Box>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    };
    renderBatteryIcon(level) {
        if (0 < level && level < 20) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <BatteryRed color="#FF4040" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (20 <= level && level <= 60) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <BatteryAmber color="#FFAA15" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (60 < level && level <= 100) {
            return (
                <Box direction="row" gap="xsmall" align="center">
                    <Battery color="#01a982" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else {
            return (
                <Box direction="row">
                    <BatteryUnknown color="#CCCCCC" style={{ width: "34.365", height: "18" }} />
                </Box>);
        }

    }

    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    renderAssetsTable(props, canEdit, sync) {
        let COLUMNS = [
            //{
            //    property: 'imageUrl',
            //    header: 'Image',
            //    render: s => (s.imageUrl != "" ?
            //        <Image width="30" height="30" src={`${s.imageUrl}`} /> :
            //        <Tag />
            //    )
            //},
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: t => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ? <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
            //        : <Box direction='row' gap="xsmall">
            //            <Button plain disabled={props.deletedAssetIds.includes(t.id)} onClick={evt => props.handleEdit(t.id)} icon={<Edit />} />
            //            <Button plain disabled={props.deletedAssetIds.includes(t.id)} onClick={evt => props.handleDelete("asset", t.id)} icon={<Trash />} />
            //        </Box>,
            //},
            {
                property: 'batteryLevel',
                header: 'Battery',
                //primary: true,
                size: "70px",
                render: s => this.renderBatteryIcon(s.batteryLevel),
                isFixed: true
            },
            {
                property: 'lcname',
                header: 'Name',
                render: s => <Text>{s.name.length <= 20 ? s.name : s.name.substring(0, 20) + "..."}</Text>,
                isFixed: true
            },
            {
                property: 'no',
                header: 'ID',
                render: s => <Text>{s.id}</Text>,
                isFixed: true
            },
            {
                property: 'externalId',
                header: 'External ID',
                render: s => <Text>{s.externalId}</Text>,
                isActive: false
            },
            //{
            //    property: 'id',
            //    header: 'Tracker Name',
            //    render: t => constants.ASSOCIATIONS.find(a => a.tagId == t.id) ? <Text> {constants.ASSOCIATIONS.find(a => a.tagId == t.id).trackerName}</Text> : <Text></Text>
            //},
            {
                property: 'lastheard',
                header: 'Last Heard',
                render: s => s.mapId ? <Text>{s.timestamp ? this.formatDate(s.timestamp) : ""}</Text> : null
            },
            {
                property: 'modified',
                header: 'Modified',
                render: s => s.mapId ? <Text>{s.modified ? this.formatDate(s.modified) : ""}</Text> : null,
                isActive: false
            },
            {
                property: 'created',
                header: 'Created',
                render: s => s.mapId ? <Text>{s.created ? this.formatDate(s.created) : ""}</Text> : null
            },
            {
                property: 'siteName',
                header: 'Site',
                render: s => <Text>{s.siteName}</Text>
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: s => <Text>{s.map.group ? s.map.group.name : ""}</Text>
            },
            {
                property: 'lcfloor',
                header: 'Floor',
                render: s => <Text>{s.map.name}</Text>
            },
            {
                property: 'lcZone',
                header: 'Zone',
                render: s => <Text>{s.zoneId && s.zone ? s.zone.name : ""}</Text>
            },
            {
                property: 'lcCollectionName',
                header: 'Collection',
                render: s => <Text >{s.collectionName}</Text>
            },
            {
                property: 'lcDepartmentName',
                header: 'Department',
                render: s => <Text >{s.departmentName}</Text>
            },
            //{
            //    property: 'serviceStateId',
            //    header: 'Status',
            //    render: s =>
            //        < Text > {s.st}</Text >
            //},
            {
                property: 'isControlTag',
                header: 'Control Tag',
                render: s => <CheckBox checked={s.isControlTag} disabled />
            },
            {
                property: 'isAssigned',
                header: 'Linked',
                size: "65px",
                render: s => <CheckBox checked={s.isAssigned} disabled />
            },
            {
                property: 'Labels',
                header: <Box justify="center" height="100%">Labels</Box>,
                size: "70px",
                render: s =>
                    s.assetBeaconTags.length > 0 ?
                        < Box direction="row" /*border="all"*/ /*pad={{ vertical: "small" }}*/ alignContent="center" >
                            <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                <Stack anchor="top-right" data-for={s.id} style={{ width: constants.STACKWIDTH }}
                                    data-tip>
                                    <Tag
                                        size="large"
                                        //color="#F04953"
                                        style={{ height: "24px" }}
                                    />
                                    <Box
                                        background="brand"
                                        pad={{ horizontal: 'xsmall' }}
                                        round
                                    >
                                        {s.assetBeaconTags.length}
                                    </Box>
                                </Stack>
                                <ReactTooltip id={s.id} place="left" type="light" effect="solid" arrowColor="transparent" multiline >
                                    <StyledBox style1 border="all" gap="small" pad="small">
                                        {s.assetBeaconTags && s.assetBeaconTags.map((l) => (
                                            <Text>
                                                {l.tag.name}
                                            </Text>
                                        ))}
                                    </StyledBox>
                                </ReactTooltip>

                            </Box>
                        </Box >
                        :
                        <Box />

            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            {
                sortable: false,
                property: 'delete',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: t => <Box width="24px"><Button plain disabled={props.deletedAssetIds.includes(t.id)} onClick={evt => props.handleDelete("asset", t.id)} icon={<Trash />} /></Box>
            },
        ];

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
            }

        let filterCount = 0;
        filterCount += this.state.site == "" ? 0 : 1;
        filterCount += this.state.selectedBuilding == "" ? 0 : 1;
        filterCount += this.state.level == "" && this.state.levelId == "" ? 0 : 1;
        filterCount += this.state.zone == "" && this.state.zoneId == "" ?  0 : 1;
        filterCount += this.state.selectedLabels.length == 0 ? 0 : 1;
        filterCount += this.state.selectedCollections.length == 0 ? 0 : 1;
        filterCount += this.state.department.length == 0 ? 0 : 1;
        filterCount += this.state.control.length == 0 ? 0 : 1;
        filterCount += this.state.link.length == 0 ? 0 : 1;
        filterCount += this.state.flag.length == 0 ? 0 : 1;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "batteryLevel"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.assetsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'batteryLevel', label: 'Battery Level' },
                //    { col: 'lcname', label: 'Name' },
                //    { col: 'id', label: 'ID' },
                //    { col: 'timestamp', label: 'Last Heard' },
                //    { col: 'lcbuilding', label: 'Building' },
                //    { col: 'lcfloor', label: 'Floor' },
                //    { col: 'isControlTag', label: 'Control Tag' },
                //    { col: 'isAssigned', label: 'Linked' },
                //    //{ col: 'st', label: 'Status' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                select={this.props.select}
                onSelect={list => { this.props.onChangeSelect(list, this.count), this.setState({ select: list }) }}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({
                    site: "", siteId: "", selectedBuildingId: "", selectedBuilding: "", floors: [], level: "", levelId: "",
                    selectedCollections: [], selectedLabels: [], department: "", departmentId: "", control: "", flag: "", link: "", zone: "", zoneId: ""
                })}
                PFisClearVisible={filterCount}
                PFcsvdata={this.Exp_Data}
                PFcsvfilename="orgtags-export"
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
            />
        );
    }

}

export default OrgTagListTable;