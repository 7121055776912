import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, Calendar, Location, Projects, Alert } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PrimaryFilter from "./common/PrimaryFilter";

var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();

const PaginatedDataTable = withPagination(DataTable);

class BookingListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }

    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                //d.id.toLowerCase().includes(search) ||
                d.lcsubject.includes(search) ||
                d.lclocation.includes(search) ||
                d.start.includes(search) ||
                d.end.includes(search)
            );
        }
        return data;
    }

    renderBookingTable(props) {
        const COLUMNS = [
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: departmentdata => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ? <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
            //        : <Box direction='row' gap="xsmall">
            //            <Button plain disabled={props.deletedDepartmentIds.includes(departmentdata.id)} onClick={evt => props.handleEdit(evt, departmentdata.id)} icon={<Edit />} />
            //            <Button plain disabled={props.deletedDepartmentIds.includes(departmentdata.id)} onClick={evt => props.handleDelete(evt, departmentdata.id)} icon={<Trash />} />
            //        </Box>,
            //},
            {
                property: 'lcsubject',
                header: 'Subject',
                render: bookingsdata => <Text>{bookingsdata.subject}</Text>
            },
            {
                property: 'lclocation',
                header: 'Location',
                render: bookingsdata => <Text>{bookingsdata.location.displayName}</Text>
            },
            {
                property: 'start',
                header: 'Start Date',
                render: bookingsdata => <Text>{bookingsdata.start}</Text>
            },
            {
                property: 'end',
                header: 'End Date',
                render: bookingsdata => <Text>{bookingsdata.end}</Text>
            },
            //{
            //    property: 'created',
            //    header: 'Created',
            //    render: bookingsdata => <Text>{bookingsdata.created}</Text>
            //},
            //{
            //    property: 'updated',
            //    header: 'Updated',
            //    render: bookingsdata => <Text>{bookingsdata.updated}</Text>
            //}
        ];

        let DATA = [];
        if (props.bookingsdata[0] && props.bookingsdata[0].count != 0) {
            props.bookingsdata.forEach((ev, id) => {
                DATA.push({
                    ...ev,
                    lcsubject: ev.subject ? ev.subject.toLowerCase() : "",
                    lclocation: ev.location.displayName ? ev.location.displayName.toLowerCase() : "",
                    start: ev.start.dateTime ? constants.formatDate(ev.start.dateTime) : "",
                    end: ev.end.dateTime ? constants.formatDate(ev.end.dateTime) : "",

                    lcname: ev.name ? ev.name.toLowerCase() : "",
                    created: ev.createdAt ? constants.formatDate(ev.createdAt) : "",
                    updated: ev.updatedAt ? constants.formatDate(ev.updatedAt) : ""
                });
            });
        }
        DATA = this.filterData(DATA);
        DATA.sort(constants.byPropertyCalled("name"));
        console.log("DepDA",DATA);
        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcparent" || c.property === "lclocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.bookingsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Department Name' },
                //    { col: 'created', label: 'Created' },
                //    { col: 'updated', label: 'Updated' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled={true}

                PFplaceholder="Search by Subject, Location"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                //PFcsvdata={[]}
                //PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={false}
            />
        );
    }
    calendarItem(book) {
        let endTime = new Date(book.end.dateTime).getHours();
        let AMorPM = endTime <= 12 ? "AM" : "PM"
        return (
            <StyledBox style1 direction="row" border="all" pad="small" justify="between" /*width="600px"*/>
                <Box direction="row" gap="small">
                <Box alignSelf="center">
                    <Image src="./Images/desk.jpg" /*width="80px"*/ style={{ height: "fit-content", width: "auto" }} />
                </Box>
                <Box gap="small">
                    <Box direction="row" gap="small">
                        <Text weight="bold">{book.subject}</Text>
                    </Box>
                    <Box direction="row" gap="small">
                        <Calendar />
                        <Text>{new Date(book.start.dateTime).toDateString()}</Text>
                        <Text>{" | "}</Text>
                        <Text>{new Date(book.start.dateTime).getHours()}AM - {endTime}{AMorPM} {abbs}</Text>
                    </Box>
                    <Box direction="row" gap="small">
                        <Location />
                        <Text>{book.location.displayName}</Text>
                        <Text>{" | "}</Text>
                        <Text>First Floor, ILOO1</Text>
                    </Box>
                    <Box direction="row" gap="small">
                        <Projects />
                        <Text>Space Type: Desk</Text>
                    </Box>
                    </Box>
                </Box>
                <Box direction="row" gap="small">
                    <Edit/>
                    <Trash/>
                </Box>
            </StyledBox>
            );
    }
    teemCalendarItem(book) {
        let endTime = new Date(book.ends_at * 1000).getHours();
        let AMorPM = endTime <= 12 ? "AM" : "PM";
        endTime = endTime <= 12 ? endTime : endTime - 12;
        return (
            <StyledBox style1 direction="row" border="all" pad="small" justify="between" /*width="600px"*/>
                <Box direction="row" gap="small">
                    <Box alignSelf="center">
                        <Image src="./Images/desk.jpg" /*width="80px"*/ style={{ height: "fit-content", width: "auto" }} />
                    </Box>
                    <Box gap="small">
                        <Box direction="row" gap="small">
                            <Text weight="bold">{book.title}</Text>
                        </Box>
                        <Box direction="row" gap="small">
                            <Calendar />
                            <Text>{new Date(book.starts_at*1000).toDateString()}</Text>
                            <Text>{" | "}</Text>
                            <Text>{new Date(book.starts_at*1000).getHours()}AM - {endTime}{AMorPM} {abbs}</Text>
                        </Box>
                        <Box direction="row" gap="small">
                            <Location />
                            <Text>{book.description}</Text>
                        </Box>
                        <Box direction="row" gap="small">
                            <Projects />
                            <Text>Space Type: Desk</Text>
                        </Box>
                    </Box>
                </Box>
                <Box direction="row" gap="small">
                    <Edit />
                    <Trash />
                </Box>
            </StyledBox>
        );
    }
    calendarList(list) {
        //list.sort(constants.byPropertyCalled("start.dateTime"))
        let now = new Date().toISOString();
        if (list.length == 0) {
            return (
                <StyledBox pad="large" gap="small" direction="column" align="center">
                    <Alert color="#DADADA" size="100px" />
                    <Box align="center">
                        <Text>There is no content at this time</Text>
                        <Text>Please check back again soon</Text>
                    </Box>
                </StyledBox>
            );
        }
        return (
            <Box gap="small">
                {list.filter(item => item.end.dateTime > now).map(item => this.calendarItem(item))}
            </Box>
            );
    }
    calendarList1(list, isBookViaGraph) {
        console.log("rock", list, isBookViaGraph);
        let now = new Date().toISOString();
        if (list.length == 0) {
            return (
                <StyledBox pad="large" gap="small" direction="column" align="center">
                    <Alert color="#DADADA" size="100px" />
                    <Box align="center">
                        <Text>There is no content at this time</Text>
                        <Text>Please check back again soon</Text>
                    </Box>
                </StyledBox>
            );
        }
        const today = new Date();
        return (
            <Box gap="small">
                {Array.from(Array(8), (_, i) => {
                    let nextday = new Date(today)
                    let nexti = /*7 - i*/i;
                    nextday.setDate(nextday.getDate() + nexti);
                    let nextday_dateString = nextday.toDateString();
                    let header = nextday_dateString;
                    switch (nexti) {
                        case 0:
                            header = "Today";
                            break;
                        case 1:
                            header = "Tomorrow";
                            break;
                        default:
                            break;
                    }
                    let nextday_list = list.filter(item => {
                        let dateString = "";
                        if (isBookViaGraph) {
                            dateString = new Date(item.start.dateTime).toDateString();
                        }
                        else {
                            dateString = new Date(item.starts_at * 1000).toDateString();
                        }
                        console.log("date",dateString == nextday_dateString);
                        return dateString == nextday_dateString;
                    });
                    return (
                        <Box gap="small">
                            <Text weight="bold">{header}</Text>
                            {nextday_list.length == 0 ?
                                <Text>No Bookings</Text>
                                :
                                nextday_list.map(item => {
                                    if (isBookViaGraph) {
                                        return this.calendarItem(item);
                                    } else {
                                        return this.teemCalendarItem(item);
                                    }
                                }
                             )}
                        </Box>
                    );
                })}
            </Box>
        );
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >My Bookings </Heading>
                    {/*<Box direction="row" gap="small" >
                        <StyledButton typeSave label='Create New' alignSelf="center" onClick={this.props.handleNew} />
                    </Box>*/}
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    {/*<PrimaryFilter*/}
                    {/*    placeholder="Search by Department name"*/}
                    {/*    search={this.state.search}*/}
                    {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                    {/*>*/}
                    {/*</PrimaryFilter>*/}
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox style={{ "min-width": "650px" }}>
                            {this.calendarList1(this.props.bookingsdata, this.props.isBookViaGraph)}
                            {/*this.renderBookingTable(this.props)*/}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }


}

export default BookingListTable;
