import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { MapCustom } from '../grommet-extras/MapCustomIcon';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();

class AssetHistoryListTable extends Component {

    constructor(props) {
        super(props);
        this.customWarningMessage = "There is no location history to display for this asset"
    }

    renderPagesTable(historydata) {
        const COLUMNS = [
            {
                property: 'mapId',
                header: 'Map',
                //render: s => <Box style={{ cursor: "pointer" }}><MapCustom /></Box>
                render: s => <Button plain icon={<MapCustom color={constants.HPE_GREEN} />} onClick={() => this.props.handleClick(s.id, s.mapId, s.x, s.y)} />
            },
            {
                property: 'localTime',
                header: `Last Heard (${abbs})`,
                render: s => <Text>{s.localTime}</Text>
            },
            {
                property: 'syncTime',
                header: `TimeStamp (${abbs})`,
                render: s => <Text>{s.syncTime}</Text>
            },
            {
                property: 'lcBuilding',
                header: 'Meridian Building Name',
                render: s => <Text>{s.MeridianBuildingName}</Text>
            },
            {
                property: 'lcFloor',
                header: 'Floor',
                render: s => <Text>{s.FloorNumber}</Text>
            },
            {
                property: 'lcZone',
                header: 'Zone',
                render: s => <Text>{s.ZoneName}</Text>
            },
            {
                property: 'id',
                header: 'ID',
                render: s => <Text>{s.id}</Text>,
            }
        ];

        let DATA = [];
        if (historydata[0] && historydata[0].count != 0) {
            historydata.forEach((ev, index) => {
                DATA.push({
                    ...ev,
                    lcBuilding: ev.MeridianBuildingName ? ev.MeridianBuildingName.toLowerCase() : "",
                    lcFloor: ev.FloorNumber ? ev.FloorNumber.toLowerCase() : "",
                    lcZone: ev.ZoneName ? ev.ZoneName.toLowerCase() : "",
                    id: ev.id,
                    //name: statusName,
                    //start: ev.startTime.replace("T", " ").replace("Z", ""),
                    //date: date,
                    //time: time.slice(0, dot),
                })
            });
        }

        DATA.sort((p1, p2) => { if (p1.time.toLowerCase() > p2.time.toLowerCase()) return -1; return 1; });

        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                }))}
                data={DATA}
                noLoadingMsg={historydata.length != 0}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                PFcsvdata={[]}
                PFsearchable={false}
                PFfilterable={false}
                PFdownloadable={0}
                customWarning={this.customWarningMessage}
            />
        );
    }

    render() {
        return (
            <StyledBox>
                {this.renderPagesTable(this.props.historydata)}
            </StyledBox>
        );
    }
}

export default AssetHistoryListTable;