import * as ActionType from './ActionType';
import CalendarHistoryApi from '../api/CalendarHistoryApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';

export const getCalendarHistoriesResponse = calendarhistoriesdata => ({
    type: ActionType.GET_CALENDARHISTORIES_RESPONSE,
    calendarhistoriesdata
});

export function getCalendarHistoriesAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());
        return CalendarHistoryApi.getAllCalendarHistories()
            .then(calendarhistoriesdata => {
                dispatch(getCalendarHistoriesResponse(calendarhistoriesdata));
                return calendarhistoriesdata;
            }).catch(error => {
                throw error;
            });
    };
}