import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import LoadingIndicator from './common/LoadingIndicator';
import PrimaryFilter from "./common/PrimaryFilter";

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class OrgLabelListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.Exp_Data = [];
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }

    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    groupClick = (e) => {
        let path = e.composedPath();
        let tr = path.find(i => i.nodeName == "TR");
        let btn = tr.getElementsByTagName('button')[0];
        btn.click();
        this.onDTUpdated();
    }

    multiEdit = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let name = element.getElementsByTagName('td')[1] ? element.getElementsByTagName('td')[1].textContent : null;
        let id = element.getElementsByTagName('td')[4] ? element.getElementsByTagName('td')[4].textContent : null;
        if (id != null && name != null) {
            let hE = this.props.handleEditLabels;
            hE(id,name);
        }
    }

    expnad = e => {
        let path = e.composedPath();
        let tr = path.find(i => i.nodeName == "TR");
        let btn = tr.getElementsByTagName('button')[0];
        btn.click();
        this.onDTUpdated();
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('thead')[0]) {
            let headerRow = node.getElementsByTagName('thead')[0];
            let headerExpandButton = headerRow.getElementsByTagName('button')[0];
            if (headerExpandButton) {
                headerExpandButton.removeEventListener("click", this.expnad);
                headerExpandButton.addEventListener("click", this.expnad);
            }
        }
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    if (elem.getElementsByTagName('button')[0] && !elem.getElementsByTagName('th')[0]) {
                        //Group Rows
                        if (nro == 0) {
                            element.removeEventListener("click", this.groupClick);
                            element.addEventListener("click", this.groupClick);
                        }
                        else if (nro != insideElements.length-1) {
                            element.removeEventListener("click", this.multiEdit);
                            element.addEventListener("click", this.multiEdit);
                        }
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.lcName.includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.created.toLowerCase().includes(search)
            );
        }
        return data;
    }

    renderTrackerTable(props) {
        const COLUMNS = [
            {
                property: 'lcName',
                header: 'Name',
                render: labeldata => <Text>{labeldata.name}</Text>
            },
            {
                property: 'siteName',
                header: 'Site',
                render: labeldata => <Text>{labeldata.siteName}</Text>
            },
            {
                property: 'created',
                header: 'Created',
                render: labeldata => <Text>{labeldata.created}</Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: labeldata => <Text hidden>{labeldata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: labeldata => (!RB.canEdit(constants.PERMS.LOC_CONTENT) || !labeldata.id) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, labeldata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedLabelIds.includes(labeldata.id)} onClick={evt => props.handleEdit(labeldata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedLabelIds.includes(labeldata.id)} onClick={evt => props.handleDelete("label", labeldata)} icon={<Trash />} />
                    </Box>,
                aggregate: 'sum'
            },
        ];
        let DATA = [];
        this.Exp_Data = [];
        if (props.assetlabelsdata[0] && props.assetlabelsdata[0].count != 0) {
            //props.assetlabelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.assetlabelsdata.forEach(ev => {
                let site = ev.locationId && props.sitesdata && props.sitesdata[0] && props.sitesdata[0].count != 0 ? props.sitesdata.find(s => s.id == ev.locationId) : "";
                let siteName = site ? site.name : "";
                DATA.push({
                    ...ev,
                    lcName: ev.name ? ev.name.toLowerCase() : '',
                    siteName: siteName,
                    //siteName: (ev.locationId && props.sitesdata && props.sitesdata[0] && props.sitesdata[0].count != 0) ? props.sitesdata.find(s => s.id == ev.locationId).name : "",
                    //created: ev.createdAt ? constants.formatDate(ev.createdAt) : ""
                    created: ev.modified ? constants.formatDate(ev.modified) : ""
                });
                this.Exp_Data.push({
                    "ID": ev.id,
                    "Label Name": ev.name,
                    "Site Name": siteName,
                    //"Site Name": ev.locationId && props.sitesdata && props.sitesdata[0] && props.sitesdata[0].count != 0 ? props.sitesdata.find(s => s.id == ev.locationId).name : "",
                    //"Created At (UTC)": ev.createdAt
                    "Created At (UTC)": ev.modified
                });
            });
            DATA.splice(0, 1);
        }
        DATA.sort((p1, p2) => {
            //if (p1.siteName.toLowerCase() < p2.siteName.toLowerCase()) return -1;
            //if (p1.siteName.toLowerCase() > p2.siteName.toLowerCase()) return 1;
            //They are equal, so sort them by Name
            if (p1.lcName < p2.lcName) return -1; return 1;
        });
        this.Exp_Data.sort((p1, p2) => {
            //if (p1["Site Name"].toLowerCase() < p2["Site Name"].toLowerCase()) return -1;
            //if (p1["Site Name"].toLowerCase() > p2["Site Name"].toLowerCase()) return 1;
            //They are equal, so sort them by Name
            if (p1["Label Name"] < p2["Label Name"]) return -1; return 1;
        });
        DATA = this.filterData(DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.assetlabelsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcName', label: 'Label Name' },
                //    { col: 'created', label: 'Created' }
                //]}
                resizeable={false}
                displayrows={DATA.length}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                groupBy="name"
                //groupBy={{
                //    "property": "siteName",
                //    "expand": [/*"Portland", "Santa Clara"*/]
                //}}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                /*PFFileters={this.renderFilters()}*/
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={DATA.length > 0 ? 1 : 2}
            />
        );
    }

    render() {
        return (
            <Box>
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Label name, Site"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox ref={this.dtwrapper}>
                    {this.renderTrackerTable(this.props)}
                </StyledBox>
            </Box>
        );
    }
}

export default OrgLabelListTable;