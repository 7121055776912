import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as SiteAction from '../action/SiteAction';
import * as UserAction from '../action/UserAction';
import OrgListTable from './OrgListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import PopUp from './common/PopUp';
import organisationsReducer from '../reducer/organisationsReducer';
import { authContext, getToken, adalGetUser, adalConfig } from '../adal/adalConfig';
import constants from '../constants';
import RB from '../components/common/RBAC';
import { getLogonUserPermissions } from './common/RBAC';
import _ from 'lodash';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { MsalContext } from "@azure/msal-react";
import { withOktaAuth } from '@okta/okta-react'


export class OrgListContainer extends React.Component {
    static contextType = MsalContext;
    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.handleInfo = this.handleInfo.bind(this);
        this.state = {
            selectedOrgForDelete: 0,
            deletedOrgIds: [],
            open: undefined,
            info: undefined,
            updated: false,
            organisationsdataLoading: true,
            usersdataLoading: true,
            userdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    async componentDidMount() {
        sessionStorage.setItem("locationId", "");
        sessionStorage.setItem("organisationId", "");
        sessionStorage.setItem("organisationCode", "");

        console.log("saxOLRBAC");
        await getLogonUserPermissions();
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Organisations List Error', error);
            });
        this.props.action.getUsersAction()
            .then(response => this.setState({ usersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Users Error", error);
            });

        //let oktaUser = localStorage.getItem("okta-token-storage");
        //if (oktaUser && !_.isEmpty(oktaUser)) {
        //    oktaUser = JSON.parse(oktaUser);
        //}
        //console.log(oktaUser,"oktaUser");
        //const email = this.context.accounts && this.context.accounts[0] ? this.context.accounts[0].username : (oktaUser.accessToken && oktaUser.accessToken.claims) ? oktaUser.accessToken.claims.sub : "Unknown";
        const email = this.context.accounts.length != 0 ? this.context.accounts[0].username : (this.props.authState && this.props.authState.isAuthenticated) ? this.props.authState.accessToken.claims.sub : "Unknown";
        if (email == "Unknown")
            this.props.history.push('/');
        this.props.action.getUserAllRolesAction(/*adalGetUser().profile.unique_name*/ /*"bence.koppany@hpe.com"*/email)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Users Error", error);
            });
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    ArrayToString(arr) {
        let string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.name;
            } else {
                string += item.name + ", ";
            }

        })
        return string;
    }
    RolesToString(arr) {
        let string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.role.name;
            } else {
                string += item.role.name + ", ";
            }
        })
        return string;
    }
    RolesToArray(arr) {
        const list = [];
        arr.forEach(function (item, idx) {
            list.push(item.role.name);
        })
        return list;
    }

    handleDelete(event, nro) {
        if (RB.isSuperUser()) {
            event.preventDefault();
            console.log('*Delete* started on ', nro);
            this.setState({ selectedOrgForDelete: nro });
            this.onOpen();
        }
    }

    forceUpdateHeader() { //this function is for updating org name in the header, after session storage has been updated with the correct org id
        this.props.action.getSitesAction()
            .then((response) => {
            }).catch(error => {
                console.log('*DEBUG*- Sites List Error', error);
            });
        //this.setState({ updated: !updated });
    }

    eventDelete(event) {
        const selectedId = this.state.selectedOrgForDelete;

        if (selectedId) {
            this.setState({ selectedOrgForDelete: 0, open: undefined });
            this.setState({ deletedOrgIds: this.state.deletedOrgIds.concat([selectedId]) });
            var deletedOrg = this.props.organisationsdata.find(o => o.id == selectedId);
            deletedOrg.deleted = true;
            var sessionStOrgId = sessionStorage.getItem("organisationId");
            var sessionStOrgCode = sessionStorage.getItem("organisationCode");
            sessionStorage.setItem("organisationId", selectedId);
            sessionStorage.setItem("organisationCode", deletedOrg.code);
            var outside = 0;
            var inside = 0;
            this.props.action.getSitesAction()
                .then((response) => {
                    this.props.sitesdata.filter(s => s.deleted == false).map((location) => {
                        outside = outside + 1;
                        location.deleted = true;
                        this.props.action.saveSiteAction(location)
                            .then(() => {
                                inside = inside + 1;
                                if (outside == inside) {
                                    this.props.action.saveOrganisationAction(deletedOrg)
                                        .then((response) => {
                                            this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                                            if (sessionStOrgId != sessionStorage.getItem("organisationId")) { //if NOT current org is deleted, session storage will be updated with the original org ID
                                                sessionStorage.setItem("organisationId", sessionStOrgId);
                                                sessionStorage.setItem("organisationCode", sessionStOrgCode);
                                                this.forceUpdateHeader();
                                            }
                                            else if (sessionStOrgId == sessionStorage.getItem("organisationId")) { //if current org is deleted, session storage will be empty
                                                sessionStorage.setItem("organisationId", "");
                                                sessionStorage.setItem("organisationCode", "");
                                                this.forceUpdateHeader();
                                            }
                                        }).catch(error => { //if save failed still need to restore org id
                                            console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                                            this.setState({ disabledButton: false });
                                            sessionStorage.setItem("organisationId", sessionStOrgId);
                                            sessionStorage.setItem("organisationCode", sessionStOrgCode);
                                            this.forceUpdateHeader();
                                        });
                                }
                            }).catch(error => {
                                console.log('*DEBUG*-Save Sites Error', error);
                                this.setState({ disabledButton: false });
                            });
                    });
                    if (outside == 0) {
                        this.props.action.saveOrganisationAction(deletedOrg)
                            .then((response) => {
                                this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                                if (sessionStOrgId != sessionStorage.getItem("organisationId")) { //if NOT current org is deleted, session storage will be updated with the original org ID
                                    sessionStorage.setItem("organisationId", sessionStOrgId);
                                    sessionStorage.setItem("organisationCode", sessionStOrgCode);
                                    this.forceUpdateHeader();
                                }
                                else if (sessionStOrgId == sessionStorage.getItem("organisationId")) { //if current org is deleted, session storage will be empty
                                    sessionStorage.setItem("organisationId", "");
                                    sessionStorage.setItem("organisationCode", "");
                                    this.forceUpdateHeader();
                                }
                            }).catch(error => { //if save failed still need to restore org id
                                console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                                sessionStorage.setItem("organisationId", sessionStOrgId);
                                sessionStorage.setItem("organisationCode", sessionStOrgCode);
                                this.setState({ disabledButton: false });
                                this.forceUpdateHeader();
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*- Sites List Error', error);
                });

            //this.props.action.deleteOrganisationAction(selectedId)
            //    .catch(error => {
            //        console.log('*DEBUG*- Organisation List Delete Error',error);
            //    });
        }
    }

    handleInfo(event) {
        this.setState({ info: true });
    }

    closeInfo() {
        this.setState({ info: undefined });
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/orgsettings/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        var orgCode = "";
        if (this.props.organisationsdata.filter(o => o.id == nro)[0]) {
            orgCode = this.props.organisationsdata.filter(o => o.id == nro)[0].code
        }
        console.log('*Edit* started on ', nro);
        sessionStorage.setItem("organisationId", nro);
        sessionStorage.setItem("organisationCode", orgCode);
        //this.props.history.push('/orgsettings/'+nro);
        this.props.history.push(constants.getOrgLevelRoute() + '/sites');
    }

    justLoggedIn() {
        if (sessionStorage.getItem("loginProcessStarted")) {
            setTimeout(() => { sessionStorage.removeItem("loginProcessStarted") }, 3000);
            return true;
        }
        return false;
    }

    render() {
        const { organisationsdata, sitesdata, usersdata, userdata } = this.props;
        const { organisationsdataLoading, usersdataLoading, userdataLoading, sitesdataLoading } = this.state;
        if (organisationsdataLoading || usersdataLoading || userdataLoading || sitesdataLoading) {
            return (
                this.justLoggedIn() ? 
                    <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} message="Please wait while we get things ready for you" />
                    :
                    <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("saxOL", RB.canRead("Content"), RB.canEdit("Content"));
        const { open, selectedOrgForDelete, deletedOrgIds, info } = this.state;
        /*let user = adalGetUser();*/
        //console.log ('Account:', user)
        //const username = user.profile.name;
        //const email = user.profile.unique_name;
        //const email = 'cm@hpe.com'; //teszt
        //const sitesorg = sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId")).slice();
        //sitesorg.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        /*let locations = this.ArrayToString(sitesorg)*/
        //let appuser = usersdata.filter(s => s.email == email).slice(); //not working properly, usersdata only includes superusers in some cases
        //let appuser = [];
        //let temp = _.cloneDeep(userdata); //need to use temp, because of the delete in next line
        let orgswithroles = [];
        if (userdata != null) {
            //temp.userRoles = [];
            //appuser[0] = temp;
            //appuser[0].userRoles = userdata.userRoles.filter(r => r.organizationId == sessionStorage.getItem("organisationId")).slice();
            const distinct = (value, index, self) => {
                return self.indexOf(value) === index;
            }
            orgswithroles = userdata.userRoles.map(a => a.organizationId).filter(distinct);
        }
        //if (!appuser[0] || (!appuser[0].superUser && (appuser.length == 0 || appuser[0].userRoles.length == 0 || !appuser[0].active))) {
        if (orgswithroles.length == 0 && !RB.isSuperUser()) {
            console.log("US_RL_TST_RL1", organisationsdata, userdata.userRoles, orgswithroles);
            return (
                <Redirect
                    to={{
                        pathname: "/nouser"
                    }}
                />
            );
        }
        if (sessionStorage.getItem("directUrl") && this.props.location.pathname != sessionStorage.getItem("directUrl")) {
            let direct = sessionStorage.getItem("directUrl");
            sessionStorage.setItem("directUrl", "");
            console.log("fif2", direct, RB.canRead("Content"), RB.canEdit("Content"), organisationsdata, sitesdata, this.props);
            //this.props.history.push(direct); //if count of org is 1 then both push(line347) run
            return (
                <Redirect
                    to={{
                        pathname: direct
                    }}
                />
            );
        }
        //let roles = [];
        //var ur = [];
        //var list = [];
        //sitesorg.forEach(e => {
        //    if (appuser.length > 0) {
        //        ur = appuser[0].userRoles.filter(s => s.locationId == e.id).slice();
        //        list = this.RolesToArray(ur);
        //    }
        //    const role = { name: e.name, roles: list };
        //    if (list.length != 0) {
        //        roles.push({ role })
        //    }

        //});
        //const username = appuser[0].username;
        //const isadmin = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);
        const isSuperUser = RB.isSuperUser();

        var orgdata = organisationsdata;

        if (!isSuperUser) {
            orgdata = organisationsdata.filter(o => orgswithroles.includes(o.id));
        }

        if (orgdata.length == 1) {
            sessionStorage.setItem("organisationId", orgdata[0].id);
            sessionStorage.setItem("organisationCode", orgdata[0].code);
            this.props.history.push(constants.getOrgLevelRoute() + '/sites');
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                            </Heading>
                                <Text>Please confirm that you want to delete this organization.</Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}
                    <OrgListTable organisationsdata={orgdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} handleInfo={this.handleInfo} deletedOrgIds={deletedOrgIds} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    organisationsdata: state.organisationsReducer.organisationsdata,
    sitesdata: state.sitesReducer.sitesdata,
    usersdata: state.usersReducer.usersdata,
    userdata: state.selectedUserReducer.userdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...UserAction, ...OrganisationAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(OrgListContainer));