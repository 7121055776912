import * as ActionType from './ActionType';
import TrackerApi from '../api/TrackerApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getTrackersResponse = trackersdata => ({
    type: ActionType.GET_TRACKERS_RESPONSE,
    trackersdata
});

const queuedGetAllTrackers = queueApiCall(TrackerApi.getAllTrackers);

export function getTrackersAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllTrackers()
            .then(trackersdata => {
                dispatch(getTrackersResponse(trackersdata));
                return trackersdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewTrackerResponse = () => ({
    type: ActionType.ADD_NEW_TRACKER_RESPONSE
});

export const updateExistingTrackerResponse = () => ({
    type: ActionType.UPDATE_EXISTING_TRACKER_RESPONSE
});

export function saveTrackerAction(trackerdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return TrackerApi.saveTracker(trackerdraft)
            .then((response) => {
                if (trackerdraft.id) {
                    dispatch(updateExistingTrackerResponse());
                } else {
                    dispatch(addNewTrackerResponse());
                }
                return response;
            }).then((response) => {
                if (trackerdraft.id == "0") {
                    dispatch(getTrackerAction(trackerdraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getTrackerResponse = trackerdata => ({
    type: ActionType.GET_TRACKER_RESPONSE,
    trackerdata
});

export function getTrackerAction(trackerId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return TrackerApi.getTracker(trackerId)
            .then(trackerdata => {
                dispatch(getTrackerResponse(trackerdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteTrackerResponse = () => ({
    type: ActionType.DELETE_TRACKER_RESPONSE
});

const queuedDeleteTracker = queueApiCall(TrackerApi.deleteTracker);

export function deleteTrackerAction(trackerId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteTracker(trackerId)
            .then((response) => {
                dispatch(deleteTrackerResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getTrackersAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}