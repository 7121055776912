import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import * as PolicyAction from '../action/PolicyAction';
import * as GroupAction from '../action/GroupAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash, FormClose, Key, Download, Upload, DocumentCsv, Checkmark, CircleInformation, AppsRounded } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Tabs, Tab, Layer, DataTable, Stack, Grommet, Meter } from 'grommet';
import { error } from 'util';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';
import ReactTooltip from "react-tooltip";
import FilterSidePanel from './common/FilterSidePanel';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import Dropzone from 'react-dropzone';
import { CSVLink } from "react-csv";
import Papa from 'papaparse';
import TextInputWithState from './common/TextInputWithState';
import {
    CancellationLayer,
    Error,
    WizardContext,
    WizardHeader,
    StepContent,
    StepFooter,
    StepFooterG,
} from './wizzard';

const PaginatedDataTable = withPagination(DataTable);

var start = undefined;
//var orgroles = undefined;
//var locroles = undefined;

const ffTheme = deepMerge(hpe, {
    formField: {
        border: {
            color: "none",
        },
        //margin: {
        //    bottom: "0px"
        //}
    },
    //box: {
    //    extend: {
    //        "border-radius": "5px",
    //    }
    //}
});

class Option extends React.Component {
    render() {
        const { value, selected } = this.props;
        return (
            <Box direction="row" gap="small" align="center" pad="xsmall">
                <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
                {value}
            </Box>
        );
    }
}

export class UserCreateContainer extends React.Component {
    constructor() {
        super();
        this.dtwrapper = React.createRef();
        this.allRoles = [];
        this.allRolesSites = [];
        this.target = React.createRef();
        this.users_err = [];
        //this.rolefull = [];
        //this.sitesfull = [];
        //this.entryerrors = [];
        //this.didupdate = false;
        //this.orgId = sessionStorage.getItem("organisationId");
        //this.orgId = constants.organisation.id;
        //if (sessionStorage.getItem("organisationId")) {
        //    this.orgId = sessionStorage.getItem("organisationId");
        //}        
        this.state = {
            /*checked: false,*/
            //selected: [],
            //options: [],
            //hideSites: [],
            isSaved: false,
            //loaded: false,
            //locationroles: [
            //    //{ location: "HPE Galway", roles: [] },
            //    //{ location: "HPE London", roles: [] },
            //],
            //organisationroles: { roles: [] },
            //addAllRoles: "",
            //removeAllRoles: "",
            disabledButton: false,
            open: false,
            openManageRoles: false,
            openManageType: "",
            manageRoleType: "",
            manageRoleSites: [],
            manageRoleRoles: [],
            cancel: false,
            isSaveSuccessful: false,
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            filteredRoles: [],//constants.ROLES.filter(r => r.id != 0),
            selectedRoles: [],
            filteredGroups: [], //constants.GROUPS.filter(r => r.id != 0),
            selectedGroups: [],

            isRoleSidePanelOpen: false,
            selectedRolesCopy: [],
            roleSearch: "",

            isGroupSidePanelOpen: false,
            selectedGroupsCopy: [],
            groupSearch: "",

            policiesdataLoading: true,
            groupsdataLoading: true,

            data: [],
            addType: 0,

            steps: 4,
            currentStep: 1,

            uploadIssue: "",
            numberOfIssue: 0,
        };
    }

    //Validating errors
    /*group_err = '';*/
    email_err = '';
    username_err = '';
    showActiveMsg = false;

    ClearErrorMessages() {
        /*this.group_err = '';*/
        this.email_err = '';
        this.username_err = '';
        //this.entryerrors.map((item) => {
        //    item.locationId = "";
        //    item.role = "";
        //})
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.userId;
        this.props.action.getUserAction(this.props.match.params.userId)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-UserEdit Error", error);
            });
        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Role Error", error);
            });
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getPoliciesAction()
            .then(response => this.setState({ policiesdataLoading: false, filteredRoles: response.hasOwnProperty("status") ? [] : response }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
        this.props.action.getGroupsAction()
            .then(response => this.setState({ groupsdataLoading: false, filteredGroups: response.hasOwnProperty("status") ? [] : response }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
    }

    //componentWillReceiveProps({ userdata }) {
    //    if (userdata && userdata.userGroups && userdata.userRoles) {
    //        //this.isSet = true;
    //        this.setState({ selectedGroups: userdata.userGroups.map(ug=> ug.group), selectedRoles: userdata.userRoles.map(ur=> ur.role) });
    //        console.log("haloka", userdata.userGroups)
    //    }
    //}

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.userdata && !_.isEqual(start, this.props.userdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const userdefault = this.props.userdata;
        if (userdefault.id == "0") {
            userdefault.active = true;
        }

        const { selected, locationroles } = this.state;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        //if (userdefault.username.trim().length == 0) {
        //    errorsno++;
        //    this.username_err = "Username is required";
        //}
        if (userdefault.email.trim().length == 0) {
            errorsno++;
            this.email_err = "E-mail is required"
        }
        if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
            errorsno++;
            this.email_err = "E-mail is not correct";
        }
        if (userdefault.email.trim().length != 0 && this.isEmailAlreadyExisted(userdefault.email)) {
            errorsno++;
            this.email_err = constants.USER_EXISTS;
        }
        //if (this.state.organisationroles.roles.length == 0) {
        //    if (locationroles.length != 0) {
        //        locationroles.map((item, i) => {
        //            if (item.locationId == "") {
        //                errorsno++;
        //                this.entryerrors[i].locationId = "Select Site";
        //                console.log("Select Site");
        //            }
        //            if (item.roles.length == 0) {
        //                errorsno++;
        //                this.entryerrors[i].role = "Select a Role";
        //                console.log("Select a Role");
        //            }
        //        })
        //    }
        //}
        if (errorsno == 0) {
            this.setState({ disabledButton: true })
            //this.saveRoles();
            //userdefault.userGroups = userdefault.userGroups.map(g => { return { groupId: g.id } });
            //userdefault.userPolicies = userdefault.userPolicies.map(p => { return { roleId: p.id }; });
            userdefault.userPolicies = this.state.selectedRolesCopy.map(p => { return { roleId: p.id }; });
            userdefault.userGroups = this.state.selectedGroupsCopy.map(g => { return { groupId: g.id } });
            console.log('Save: OK', userdefault);
            this.props.action.saveUserAction(userdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/users');
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false })
                });
        }
        else {
            console.log('Save: NOT OK', userdefault);
            this.setState({ validating: true });
        }
    }

    clearValidation() {
        this.users_err.forEach((e, id) => this.users_err[id].email = "");
    }

    validationCheck() {
        this.clearValidation();
        let errorsno = 0;
        if (this.state.data && this.state.data.length > 0) {
            this.state.data.forEach((r, id) => {
                if (r["Email"].trim().length == 0) {
                    errorsno++;
                    this.users_err[id].email = "E-mail is required";
                }
                if (r["Email"].trim().length != 0 && !(validator.isEmail(r["Email"]))) {
                    errorsno++;
                    this.users_err[id].email = "E-mail is not correct";
                }
                if (r["Email"].trim().length != 0 && this.state.data.find((d, index) => d.Email == r.Email && id != index)) {
                    errorsno++;
                    this.users_err[id].email = "Duplication detected, please remove one";
                }
                if (r["Email"].trim().length != 0 && this.props.usersdata.find(u => u.email == r["Email"].trim())) {
                    errorsno++;
                    this.users_err[id].email = constants.USER_EXISTS;
                }
            });
        }
        this.setState({ numberOfIssue: errorsno });
        return errorsno;
    }

    handleSaveBulk() {
        let errorsno = 0;
        if (this.state.data && this.state.data.length > 0) {
            //this.state.data.forEach((r, id) => {
            //    if (r["Email"].trim().length == 0) {
            //        this.users_err[id].email = "E-mail is required";
            //    }
            //    if (r["Email"].trim().length != 0 && !(validator.isEmail(r["Email"]))) {
            //        this.users_err[id].email = "E-mail is not correct";
            //    }
            //    if (r["Email"].trim().length != 0 && this.state.data.find((d, index) => d.Email == r.Email && id != index)) {
            //        this.users_err[id].email = "Duplication detected, please remove one";
            //    }
            //})
            errorsno = this.validationCheck();

            //if (this.state.data.some(r => r["Email"].trim().length == 0 || (r["Email"].trim().length != 0 && !(validator.isEmail(r["Email"]))))) {
            //    errorsno++;
            //    this.email_err = "E-mail is required"
            //}
            if (errorsno == 0) {
                //Ok to save
                const role = this.props.rolesdata.find(r => r.id == "f028c85c16a148e8820da3537d74e9d9");
                this.state.data.forEach((element, idx) => {
                    let userdata = { ... start };
                    userdata.email = element["Email"];
                    //userdata.username = element["User Name"];
                    userdata.active = true;
                    //userdata.userRoles = this.state.selectedRolesCopy;
                    userdata.userPolicies = this.state.selectedRolesCopy.map(p => { return { roleId: p.id }; });
                    userdata.userGroups = this.state.selectedGroupsCopy.map(g => { return { groupId: g.id } });

                    //Add Org Admin
                    //const orgAdminRole = { active: true, locationId: null, organizationId: this.props.organisationdata.id, role: role, roleId: role.id, userId: userdata.email };
                    //userdata.userRoles.push(orgAdminRole);

                    console.log('Save: OK', userdata);
                    this.props.action.saveUserAction(userdata)
                        .then((response) => {
                            userdata.userRoles = [];
                            if (idx == this.state.data.length - 1) {
                                this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                            }
                        }).catch(error => {
                            console.log('*DEBUG*-Save UserEdit Error', error);
                            this.setState({ disabledButton: false })
                        });
                });
            }
            else {
                console.log("We have error");
                this.setState({validation: true});
            }
        }
    }

    handleCancel() {
        //this.saveRoles();
        if (!this.state.cancel && !_.isEqual(start, this.props.userdata)) {
            this.setState({ cancel: true });
        }
        else {
            const userId = this.props.userdata.id;
            this.props.action.getUserAction(userId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/locusers');;
                    }
                }).catch(error => {
                    console.log('*DEBUG*-UserEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
                });
            this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
        }
    }
    updateUsernameValue(evt) {
        this.props.userdata.username = evt.target.value;
        this.setState({ updated: true });
    }
    updateEmailValue(evt) {
        this.email_err = "";
        this.props.userdata.email = evt.target.value;
        this.props.userdata.userRoles.map(r => r.userId = evt.target.value);
        this.setState({ updated: true });
    }
    onBlur() {
        let userdefault = this.props.userdata;
        if (userdefault.email.trim().length != 0) {
            if (validator.isEmail(userdefault.email)) {
                //check if exist
                if (this.isEmailAlreadyExisted(userdefault.email))
                    this.email_err = constants.USER_EXISTS;
            }
            else
                this.email_err = "E-mail is not correct";
            this.setState({ updated: true });
        }
    }
    isEmailAlreadyExisted(email) {
        let existingUser = this.props.usersdata.find(u => u.email == email);
        return existingUser ? true : false;
    }
    Add() {
        this.setState({ openManageRoles: true, openManageType: "Add" });
        //if (this.state.locationroles.length < this.sitesfull.length) {
        //    const entries = [...this.state.locationroles];
        //    entries.push({ name: "", locationId: "", roles: [] });
        //    this.setState({ locationroles: entries });
        //}
    }
    DeleteRole(siteId) {
        const { userdata } = this.props;

        userdata.userRoles = userdata.userRoles.filter(r => r.locationId != siteId);
        this.setState({ updated: true });
    }
    isOrgAdmin() {
        const { userdata } = this.props;
        if (userdata && userdata.userRoles) {
            if (userdata.userRoles.find(r => r.role.name == "Organisation Admin")) return true;
            else return false;

        }
    }
    updateOrgAdmin() {
        const { userdata, rolesdata, organisationdata } = this.props;
        const isOrgAdmin = this.isOrgAdmin();

        if (isOrgAdmin) {
            userdata.userRoles = userdata.userRoles.filter(r => r.roleId != "f028c85c16a148e8820da3537d74e9d9");
            this.setState({ updated: true });
        }
        else {
            const active = userdata.id == "0" ? true : this.isActive();
            const role = rolesdata.find(r => r.id == "f028c85c16a148e8820da3537d74e9d9");
            const orgAdminRole = { active: active, locationId: null, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };
            userdata.userRoles.push(orgAdminRole);
            this.setState({ updated: true });
        }
    }
    isActive() {
        const { userdata } = this.props;
        if (userdata) {
            let activeRoles = userdata.userRoles.filter(r => r.active == true);

            if (activeRoles.length != 0) {
                this.showActiveMsg = false;
                return true;
            }
            else if (activeRoles.length == 0) {
                return false;
            }
        }
        else
            return false;
        
    }
    SetActive() {
        const { userdata } = this.props;
        const active = this.isActive();
        userdata.userRoles.map(r => r.active = !active);

        if (!active) {
            this.showActiveMsg = true;
        }

        this.setState({ updated: true });
    }
    showActiveMsgFn() {
        const { userdata } = this.props;
        if (userdata.userRoles.map(r => r).length != 0 && this.showActiveMsg) {
            return false;
        }
        else if (userdata.userRoles.map(r => r).length == 0 && this.showActiveMsg) {
            return true;
        }
    }
    onClose() {
        this.setState({ disabledButton: false, openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }
    onCloseSaving() {
        this.setState({ isSaved: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/locusers');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        if (this.state.addType == 1)
            this.handleSave();
        else if (this.state.addType == 2)
            this.handleSaveBulk();
    }
    handleEdit(event, siteId) {
        event.preventDefault();

        var existingRoles = this.allRolesSites.find(r => r.siteId == siteId).roleId;

        this.setState({ openManageRoles: true, openManageType: "Update", manageRoleType: siteId == -1 ? "Organization" : "Site", manageRoleSites: [siteId], manageRoleRoles: existingRoles });
    }
    setmanageRoleSites(option) {
        this.setState({ manageRoleType: "Site" });
        if (!option.value.includes("allsitesid")) {
            this.setState({ manageRoleSites: option.value });
        }
        else if (option.value.includes("allsitesid")) {
            const allSiteIds = this.props.sitesdata.map(s => s.id);

            if (allSiteIds.length == this.state.manageRoleSites.length) { //deselect all
                this.setState({ manageRoleSites: [] });
            }
            else { //select all
                this.setState({ manageRoleSites: allSiteIds });
            }
        }
    }
    isSiteChecked(id) {
        if (id == "allsitesid") {
            return this.state.manageRoleSites.length == this.props.sitesdata.map(s => s.id).length;
        }
        else {
            return this.state.manageRoleSites.includes(id);
        }
    }
    setSite(evt, id) {
        if (evt.target.checked) {
            if (id == "allsitesid") {
                this.setState({ manageRoleSites: this.props.sitesdata.map(s => s.id) });
            }
            else {
                var siteList = this.state.manageRoleSites;
                siteList.push(id);
                this.setState({ manageRoleSites: siteList });
            }
        }
        else {
            if (id == "allsitesid") {
                this.setState({ manageRoleSites: [] });
            }
            else {
                var siteList = this.state.manageRoleSites.filter(r => r != id);
                this.setState({ manageRoleSites: siteList });
            }
        }
    }
    setRole(evt, id) {
        if (evt.target.checked) {
            var roleList = this.state.manageRoleRoles;
            roleList.push(id);
            this.setState({ manageRoleRoles: roleList });
        }
        else {
            var roleList = this.state.manageRoleRoles.filter(r => r != id);
            this.setState({ manageRoleRoles: roleList });
        }
    }
    addRoles(type) {
        const { manageRoleType, manageRoleSites, manageRoleRoles } = this.state;
        const { organisationdata, rolesdata, userdata } = this.props;
        const active = userdata.id == "0" ? true : this.isActive();
        if (type == "Add") {
            this.showActiveMsg = false;
            if (manageRoleType == "Organization") {
                manageRoleRoles.forEach((roleId) => {
                    const role = rolesdata.find(r => r.id == roleId);
                    const roleToAdd = { active: active, locationId: null, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                    if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId)) {
                        userdata.userRoles.push(roleToAdd);
                    }
                })
            }
            else if (manageRoleType == "Site") {
                manageRoleRoles.forEach((roleId) => {
                    manageRoleSites.forEach((siteId) => {
                        const role = rolesdata.find(r => r.id == roleId);
                        const roleToAdd = { active: active, locationId: siteId, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                        if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId && r.locationId == siteId)) {
                            userdata.userRoles.push(roleToAdd);
                        }
                    })
                })
            }
        }
        else if (type == "Update") {
            const siteId = manageRoleSites[0];
            userdata.userRoles = userdata.userRoles.filter(r => r.locationId != siteId);

            manageRoleRoles.forEach((roleId) => {
                const role = rolesdata.find(r => r.id == roleId);
                const roleToAdd = { active: active, locationId: siteId, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId && r.locationId == siteId)) {
                    userdata.userRoles.push(roleToAdd);
                }
            })
        }
        this.setState({ openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }
    renderManage(type) {
        //type = Add or Update

        const { manageRoleType, manageRoleSites, manageRoleRoles } = this.state;
        const { organisationdata, sitesdata } = this.props;

        const disableAdd = () => {
            if (manageRoleSites.length == 0 || manageRoleRoles.length == 0) {
                return true;
            }
            else {
                return false;
            }
        }

        const returnSiteOptions = () => {
            var optionList = this.props.sitesdata.map(s => s).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            optionList.unshift({ name: "All", id: "allsitesid" });
            return optionList;
        }

        const returnRolesOptions = (roleType) => {
            if (roleType == "Organization") {
                return this.props.rolesdata.filter(s => s.level == "Organization").sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            }
            else if (roleType == "Site") {
                return this.props.rolesdata.filter(s => s.level == "Location").sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            }
        }

        const disableSites = () => {
            if (manageRoleType == "Organization" || type == "Update") {
                return true;
            }
            if (manageRoleSites.includes("allsitesid")) {
                const number = returnSiteOptions().length;
                return [...Array(number).keys()].splice(1);
            }

            return []
        }

        return (
            <Layer
                position="center"
                modal={true}
                onClickOutside={() => this.onClose()}
                onEsc={() => this.onClose()}
            >
                <Box pad="medium" width="medium">
                    <Box tag="header" direction="row">
                        <Text weight="bold">Add new Role(s)</Text>
                    </Box>
                    <Box tag="body" direction="column">
                        {/*<FormField label="Role Type" error={this.email_err}>*/}
                        {/*    <Select*/}
                        {/*        placeholder="Role Type"*/}
                        {/*        options={["Organization", "Site"]}*/}
                        {/*        value={manageRoleType}*/}
                        {/*        onChange={option => this.setState({ manageRoleType: option.value })}*/}
                        {/*        disabled={type == "Update"}*/}
                        {/*    />*/}
                        {/*</FormField>*/}
                        {/*<FormField label="Organisation" error={this.email_err}>*/}
                        {/*    <Select*/}
                        {/*        placeholder="Organisation"*/}
                        {/*        options={[organisationdata.name]}*/}
                        {/*        value={organisationdata.name}*/}
                        {/*        disabled*/}
                        {/*    />*/}
                        {/*</FormField>*/}
                        <FormField label="Site(s)" error={this.email_err}>
                            <Select
                                placeholder="Location"
                                multiple
                                messages={{ multiple: "Multiple Sites Selected" }}
                                labelKey="name"
                                valueKey={{ key: 'id', reduce: true }}
                                options={returnSiteOptions()}
                                value={manageRoleSites}
                                onChange={option => this.setmanageRoleSites(option)}
                                disabled={disableSites()}
                            />
                        </FormField>
                        {/*{manageRoleType == "Site" &&*/}
                        {/*    <FormField label="Site(s)" error={this.email_err}>*/}
                        {/*        <Box gap="small" pad="small">*/}
                        {/*            {returnSiteOptions().map((info, idx) =>*/}
                        {/*                <Box>*/}
                        {/*                    <CheckBox label={info.name} checked={this.isSiteChecked(info.id)} onChange={evt => this.setSite(evt, info.id)} />*/}
                        {/*                </Box>*/}
                        {/*            )}*/}
                        {/*        </Box>*/}
                        {/*    </FormField>}*/}
                        {/*{manageRoleType != "" &&*/}
                        {/*    <FormField label="Role(s)" error={this.email_err}>*/}
                        {/*        <Select*/}
                        {/*            placeholder="Role"*/}
                        {/*            multiple*/}
                        {/*            messages={{ multiple: "Multiple Roles Selected" }}*/}
                        {/*            labelKey="name"*/}
                        {/*            valueKey={{ key: 'id', reduce: true }}*/}
                        {/*            options={returnRolesOptions(manageRoleType)}*/}
                        {/*            value={manageRoleRoles}*/}
                        {/*            onChange={option => this.setState({ manageRoleRoles: option.value })}*/}
                        {/*        />*/}
                        {/*    </FormField>}*/}
                        {manageRoleType != "" &&
                            <FormField label="Role(s)" error={this.email_err}>
                                <Box gap="small" pad="small">
                                    {returnRolesOptions(manageRoleType).map((info, idx) =>
                                        <Box>
                                            <CheckBox label={info.name} checked={manageRoleRoles.includes(info.id)} onChange={evt => this.setRole(evt, info.id)} />
                                        </Box>
                                    )}
                                </Box>
                            </FormField>}
                    </Box>
                    <Box tag="footer" direction="row" align="center" justify="end" gap="small">
                        <StyledButton typeSave label={type == "Add" ? "Add" : "Update"} onClick={() => this.addRoles(type)} disabled={disableAdd()} />
                        <StyledButton typeCancel label="Cancel" onClick={() => this.onClose()} />
                    </Box>
                </Box>
            </Layer>
        )
    }
    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'lctype',
                header: 'Role Type',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.roleType}</Text></Box>
            },
            {
                property: 'lcsite',
                header: 'Site Name',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.siteName}</Text></Box>
            },
            {
                property: 'lcrole',
                header: 'Role',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.rolesString}</Text></Box>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: rolesdata => <Text hidden>{rolesdata.siteId}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: rolesdata =>
                    <Box width="24px">
                        <Button plain onClick={evt => this.DeleteRole(rolesdata.siteId)} icon={<Trash />} />
                    </Box>,
            },
        ];

        const { sitesdata, userdata, rolesdata } = this.props;

        if (sitesdata && userdata) {
            this.allRoles = [];
            userdata.userRoles.forEach((ev, id) => {
                let sName = sitesdata.find(s => s.id == ev.locationId) ? sitesdata.find(s => s.id == ev.locationId).name : "";
                let type = "";
                if (ev.role) {
                    if (ev.role.level == "Organization") type = "Organization"
                    if (ev.role.level == "Location") type = "Site"
                }

                this.allRoles.push({
                    ...ev,
                    orgId: ev.organizationId,
                    siteName: sName,
                    siteId: ev.locationId == null ? -1 : ev.locationId,
                    roleType: type,
                    roleName: ev.role ? ev.role.name : "",
                    id: ev.roleId
                });
            });

            this.allRoles.sort((p1, p2) => { if (p1.siteName.toLowerCase() < p2.siteName.toLowerCase()) return -1; return 1; });
            this.allRoles = this.allRoles.filter(r => r.roleId != "f028c85c16a148e8820da3537d74e9d9"); //Removing Org admin

            this.allRolesSites = [];
            this.allRoles.forEach((ev, id) => {
                if (this.allRolesSites.filter(r => r.siteName == ev.siteName && r.siteName != "").length == 0) {
                    this.allRolesSites.push({
                        orgId: ev.orgId,
                        siteName: ev.siteName,
                        siteId: ev.siteId,
                        roleType: ev.roleType,
                        roleId: [ev.roleId],
                        roleName: [ev.roleName]
                    });
                }
                else {
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleName.push(ev.roleName);
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleId.push(ev.roleId);
                }
            });

            this.allRolesSites.forEach((ev, id) => { ev.roleName.sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; }); });
            //this.orgsWithSites = [];
            //sitesdata.forEach((ev) => {
            //    this.orgsWithSites.push({
            //        name: ev.name,
            //        id: ev.organizationId,
            //        sites: sitesdata.map(s => ({ name: s.name, id: s.id })),
            //    });
            //})

            //this.orgsWithSites.forEach((ev) => {
            //    let currentOrgRoles = this.allRolesSites.filter(r => r.orgId == ev.id);
            //    let siteRoles = rolesdata.filter(r => r.level == "Location").map(r => ({ name: r.name, count: 0, sites: [] }));
            //    siteRoles.forEach((ev) => {
            //        ev.sites = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName);
            //        ev.count = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName).length;
            //    })

            //    if (siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name).length != 0) {
            //        this.allRolesSites.unshift({
            //            orgId: ev.id,
            //            siteName: "All",
            //            siteId: -1,
            //            roleType: "Site",
            //            roleName: siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name),
            //        });
            //    }
            //})

            //this.allRolesSites.forEach((ev) => {
            //    if (this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId)) {
            //        let rolesAllSites = this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId).roleName.map(r => r);
            //        if (ev.siteId != -1) {
            //            ev.roleName = ev.roleName.filter(r => !rolesAllSites.includes(r))
            //        }
            //    }
            //})

            this.allRolesSites = this.allRolesSites.filter(r => r.roleName.length != 0);
            this.allRolesSites.sort((p1, p2) => { if (p1.roleType.toLowerCase() < p2.roleType.toLowerCase()) return -1; return 1; });
        }

        let DATA = [];

        if (this.allRolesSites[0] && this.allRolesSites[0].count != 0) {
            this.allRolesSites.forEach(ev => {
                DATA.push({
                    ...ev,
                    rolesString: userdata.email == "admin@hpepngalway.onmicrosoft.com" ? "Asset Tracking Admin Roles" : ev.roleName.join(", "),
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.roleType.toLowerCase().includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.rolesString.toLowerCase().includes(search)
            );
        }

        if (this.allRolesSites.length == 0) return;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.userdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                noCounter

                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    renderNewRolesTable() {
        const COLUMNS = [
            {
                property: 'lcrole',
                header: 'Role',
                render: roles => <Text>{roles.name}</Text>
            },
            {
                property: 'description',
                header: 'Description',
                render: roles => <Text>{roles.description}</Text>
            },
            {
                property: 'policynumber',
                header: 'Permissions',
                align: "center",
                render: role => {
                    let size = role.permissions ? role.permissions.length : 0;
                    if (size != 0)
                        return (
                            <Box direction="row" alignContent="center" >
                                <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                    <Stack anchor="top-right" data-for={role.id}
                                        data-tip>
                                        <Key
                                            size="large"
                                            style={{ height: "24px" }}
                                        />
                                        <Box
                                            background="brand"
                                            pad={{ horizontal: 'xsmall' }}
                                            round
                                        >
                                            {size}
                                        </Box>
                                    </Stack>
                                    {size > 0 && <ReactTooltip id={role.id} place="left" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <Box/*StyledBox style1 border="all" gap="small" pad="small"*/>
                                            {role.permissions && role.permissions.map((policy, idx) => (
                                                <Text>
                                                    {policy.type + "s" + " - " + policy.level}
                                                </Text>
                                            ))}
                                        </Box>
                                    </ReactTooltip>}
                                </Box>
                            </Box>
                        );
                    else
                        return;
                }
            },
            //{
            //    property: 'count',
            //    header: 'User Count',
            //    align: "center"
            //},
            {
                property: 'default',
                header: 'Default',
                render: role => role.isDefault ? <CheckBox checked={true} disabled /> : <CheckBox disabled />,
                align: "center"
            },
            {
                property: 'isDeletable',
                sortable: false,
                header: '',
                render: role =>
                    <Box width="24px" direction='row' gap="xsmall">
                        <Button plain icon={<Trash />} onClick={() => this.setState(prevState => ({ selectedRoles: prevState.selectedRoles.filter(r=> r.id != role.id) }))} />
                    </Box>,
                align: "center"
            },
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    size: "0px",
            //    plain: true,
            //    render: role => <Text hidden>{role.id}</Text>,
            //},
        ];

        let DATA = [];

        this.state.selectedRoles.sort((p1, p2) => { if (p1.name < p2.name) return -1; return 1; }).filter(r => r.id != 0).forEach((ev, idx) => {
            DATA.push({
                ...ev,
                lcrole: ev.name.toLowerCase(),
                /* lcpermission: ev.permission.toLowerCase()*/
                //permission: ArrayToString(ev.permissions),
                lcpermissiondetail: ev.description,
                count: idx % 2 == 0 ? idx + 1 : idx,//Math.floor(Math.random()* 10), //ev.userCount.toString(),
                policynumber: ev.permissions ? ev.permissions.length : 0,
                isDeletable: !ev.isDefault
            })
        });

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.description.toLowerCase().includes(search)
            );
        }

        //if (!this.context.feature.find(f => f.name == "contentOrg").isEnabled) {
        //    DATA = DATA.filter(d => d.name != "Content Admin");
        //}

        //DATA.sort((p1, p2) => { if (p1.lcrole < p2.lcrole) return -1; return 1; });

        /*console.log('DATA:', DATA);*/

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcrole" || c.property === "lcpermissiondetail" || c.property === "count"
                }))}
                data={DATA}
                noLoadingMsg={/*this.props.groupdata.roles*/this.state.selectedRoles.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcrole', label: 'Role' },
                //    { col: 'lcpermissiondetail', label: 'Permission' },
                //    { col: 'userCount', label: 'User Count' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                line1="No roles added to this user yet."
                line2={<div>Please select the <b>Add Role(s)</b> button to add roles to this user.</div>}
                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                extraComponent={
                    <Box data-for={"addRolebtn"} data-tip={"Select to add a role(s) to this user"} /*style={{ "height": "24px" }}*/>
                        <StyledButton
                            typeSave
                            label="Add Role(s)"
                            alignSelf="end"
                            onClick={() => this.setState({ isRoleSidePanelOpen: true, selectedRolesCopy: this.state.selectedRoles /*this.props.groupdata.roles*/ })}
                        />
                        <ReactTooltip id={"addRolebtn"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                }
            />
        );
    }

    renderGroupsTable() {
        const COLUMNS = [
            {
                property: 'lcname',
                header: 'Name',
                render: groupdata => <Text>{groupdata.name}</Text>
            },
            {
                property: 'lcdescription',
                header: 'Description',
                render: groupdata => <Text>{groupdata.description}</Text>
            },
            {
                property: 'policynumber',
                header: 'Policies and Permissions',
                align: "center",
                render: groupdata => {
                    let size = groupdata.roles ? groupdata.roles.length : 0;
                    if (size != 0)
                        return (
                            <Box direction="row" alignContent="center" >
                                <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                    <Stack anchor="top-right" data-for={groupdata.id}
                                        data-tip>
                                        <Key
                                            size="large"
                                            style={{ height: "24px" }}
                                        />
                                        <Box
                                            background="brand"
                                            pad={{ horizontal: 'xsmall' }}
                                            round
                                        >
                                            {size}
                                        </Box>
                                    </Stack>
                                    {size > 0 && <ReactTooltip id={groupdata.id} place="left" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <Box/*StyledBox style1 border="all" gap="small" pad="small"*/>
                                            {groupdata.roles && groupdata.roles.map((policy, idx) => (
                                                <Text>
                                                    {policy.name /*+ " - " + policy.access*/}
                                                </Text>
                                            ))}
                                        </Box>
                                    </ReactTooltip>}
                                </Box>
                            </Box>
                        );
                    else
                        return;
                }
            },
            {
                property: 'contentCount',
                header: 'Content',
                align: "center",
                render: groupdata => {
                    let size = groupdata.contentCount;
                    if (size != 0)
                        return (
                            <Box direction="row" alignContent="center" >
                                <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                    <Stack anchor="top-right" data-for={groupdata.id}
                                        data-tip>
                                        <AppsRounded
                                            size="large"
                                            style={{ height: "24px" }}
                                        />
                                        <Box
                                            background="brand"
                                            pad={{ horizontal: 'xsmall' }}
                                            round
                                        >
                                            {size}
                                        </Box>
                                    </Stack>
                                    {size > 0 && <ReactTooltip id={groupdata.id} place="left" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <Box/*StyledBox style1 border="all" gap="small" pad="small"*/>
                                            {groupdata.content && groupdata.content.map((policy, idx) => (
                                                <Text>
                                                    {policy.name /*+ " - " + policy.access*/}
                                                </Text>
                                            ))}
                                        </Box>
                                    </ReactTooltip>}
                                </Box>
                            </Box>
                        );
                    else
                        return;
                }
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: groupdata => <Text hidden>{groupdata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: groupdata =>
                    <Box width="24px" direction='row' gap="xsmall">
                        <Button plain icon={<Trash />} onClick={() => this.setState(prevState => ({ selectedGroups: prevState.selectedGroups.filter(g => g.id != groupdata.id) }))} />
                    </Box>
            },
        ];

        let DATA = [];

        if (this.state.selectedGroups) {
            this.state.selectedGroups.forEach(ev => {
                DATA.push({
                    ...ev,
                    lcname: ev.name.toLowerCase(),
                    lcdescription: ev.description.toLowerCase(),
                    policynumber: ev.roles ? ev.roles.length : 0,
                    usernumber: ev.users ? ev.users.length : 0,
                    contentCount: ev.content ? ev.content.length : 0,
                });
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.description.toLowerCase().includes(search) ||
                //d.users && d.users.some(user => user.name.toLowerCase().includes(search)) ||
                d.policies && d.policies.some(p => p.name.toLowerCase().includes(search))
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                }))}
                data={DATA}
                noLoadingMsg={this.state.selectedGroups.length != 0 && notFilteredData.length != 0}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                line1="No groups added to this user yet."
                line2={<div>Please select the <b>Add Group(s)</b> button to add groups to this user.</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                extraComponent={
                    <Box data-for={"addGroupbtn"} data-tip={"Select to add a group(s) to this user"} style={{ "height": "24px" }}>
                        <StyledButton
                            typeSave
                            label="Add Group(s)"
                            alignSelf="end"
                            onClick={() => this.setState({ isGroupSidePanelOpen: true, selectedGroupsCopy: this.state.selectedGroups })}
                        />
                        <ReactTooltip id={"addGroupbtn"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                }
            />
        );
    }

    renderTable(data) {
        if (data.length != 0) {
            return (
                <Box /*style={{"max-width": "750px"}}*/>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((k, index) => (
                                    <th>
                                        {k}
                                    </th>
                                ))}
                                <th>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        Issue
                                        <Box data-for="all" data-tip={this.state.showIssuesOnly ? "Switch off to show all entries." : "Switch on to show Issues only. Items with issues will not be added to the metadata table"}>
                                            <CheckBox toggle={true} label="Show Issues only" checked={this.state.showIssuesOnly} onChange={evt => this.setState({ showIssuesOnly: evt.target.checked })} />
                                        </Box>
                                    </div>
                                </th>

                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((info, idx) => (
                                <tr>
                                    {Object.keys(info).map((key, index) => (
                                        <td class="upload">
                                            <div id="long">
                                                <Grommet theme={ffTheme}>
                                                        <TextInputWithState value={info[key]} plain
                                                            onInput={evt => {
                                                                //console.log(this.state.data.map((item, i) => {
                                                                //    if (i == index)
                                                                //        return {
                                                                //            ...item,
                                                                //            Email: evt.target.value
                                                                //        }
                                                                //    else
                                                                //        return item;
                                                                //}));
                                                                this.setState({
                                                                    data: this.state.data.map((item, i) => {
                                                                        if (i == idx)
                                                                            return {
                                                                                ...item,
                                                                                Email: index == 0 ? evt.target.value : item.Email,
                                                                                //"User Name": index == 1 ? evt.target.value : item["User Name"],
                                                                            }
                                                                        else
                                                                            return item;
                                                                    })
                                                                });
                                                            }} />
                                                </Grommet>
                                            </div>
                                        </td>
                                    ))}
                                    {false && <td class="upload" width={"50px"}>
                                        <Box align="center">
                                            <CheckBox checked={true} disabled />
                                        </Box>
                                    </td>}
                                    <td class="upload" width={"15%"}>
                                        <Box>
                                            {this.users_err[idx].email ? this.users_err[idx].email : this.users_err[idx].name}
                                        </Box>
                                    </td>

                                    <td class="upload" width={"50px"}>
                                        <Box align="center">
                                            <Button icon={<Trash />}
                                                onClick={() => {
                                                    console.log("ora", idx, this.state.data[idx]);
                                                    this.users_err = this.users_err.filter((item, i) => i != idx);
                                                    this.setState({ data: this.state.data.filter((item, i) => i != idx) }, () => this.validationCheck());
                                                }} />
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            );
        }
        return;
    }

    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    renderTable2() {
        let COLUMNS = [
            {
                property: 'email',
                header: <b>{"Email"}</b>,
                isFixed: true,
                size: "50%",
                render: u =>
                    <TextInputWithState
                        style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden", paddingLeft: "0", paddingRight: "0" }}
                        value={u.email}
                        plain
                        onInput={evt => {
                        this.setState({
                            data: this.state.data.map((item, i) => {
                                if (u.email == item["Email"] /*&& u.username == item["User Name"]*/)
                                    return {
                                        ...item,
                                        Email: evt.target.value,
                                        //"User Name": index == 1 ? evt.target.value : item["User Name"],
                                    }
                                else
                                    return item;
                            })
                        });
                            }} />
            },
            {
                sortable: true,
                property: 'issue',
                header: <b>{'Issue description'}</b>,
                //size: "24px",
                //plain: true,
                //size: "15%",
                isActive: this.state.numberOfIssue > 0, //false
                render: u => <Text>{u.issue}</Text>
            },
            {
                sortable: true,
                property: 'isAnyIssue',
                header: <b>{'Issue'}</b>,
                size: "50px",
                align: "center",
                //plain: true,
                render: u => u.isAnyIssue ?
                    <Box /*fill={true} align="end"*/>
                        <a data-for="issueInfo" style={{ "height": "24px" }}
                            data-tip={u.issue}>
                            <CircleInformation /*size="medium"*/ color="#F04953" />
                        </a>
                        <ReactTooltip id="issueInfo" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                    </Box> : null
                //render: u => u.isAnyIssue ? < CircleInformation /*size="medium"*/ color="#F04953" /> : null,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                align: "center",
                render: u =>
                        <Button plain icon={<Trash />}
                            onClick={() => {
                                this.users_err = this.users_err.filter((item, i) => i != u.idx);
                                this.setState({ data: this.state.data.filter((item, i) => i != u.idx/*u.email != item["Email"] && u.username != item["User Name"]*/)}, () => this.validationCheck());
                            }} />
            },
        ];

        let DATA = [];

        if (this.state.data) {
            this.state.data.forEach((ev,i) => {
                DATA.push({
                    ...ev,
                    email: ev["Email"],
                    //username: ev["User Name"],
                    idx: i,
                    issue: this.users_err[i].email ? this.users_err[i].email : this.users_err[i].name,
                    isAnyIssue: this.users_err[i].email || this.users_err[i].name ? true : false
                });
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.email.toLowerCase().includes(search) ||
                //d.username.toLowerCase().includes(search) ||
                d.issue.toLowerCase().includes(search)
            );
        }

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                }))}
                data={DATA}
                noLoadingMsg={this.state.data.length != 0 && notFilteredData.length != 0}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled
                line1="No user(s) added yet."
                line2={<div>Please select the <b>Drag and drop</b> to add user(s).</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                background={{ body: ['light-1', 'light-3'], }}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
                noBorder={false}
                border={{ body: { side: 'bottom' } }}
            />
        );
    }


    renderSummary() {
        const data = [
            `${this.state.data.length} users added`,
            `${this.state.selectedGroupsCopy.length} groups selected`,
            `${this.state.selectedRolesCopy.length} roles selected`,
        ];
        return (
            <Box gap="small">
                <Text>Review your configuration details.</Text>
                <Box gap="medium">
                    {data.map((datum, index) => (
                        <Box key={index} direction="row" gap="small" align="center">
                            <Checkmark color="text-strong" size="small" />
                            <Text color="text-strong" weight="bold">
                                {datum}
                            </Text>
                        </Box>
                    ))}
                </Box>
                <Text color="text-strong">
                    When “Finish” is selected the user(s) added will be created and will have the relevant permission(s) applied if group(s) and/or role(s) are selected.
                </Text>
                <Text color="text-strong">
                    Note this may take time, so please wait for activity completion message. Once complete, please review the Users(s) table to ensure the user(s) were
                    added successfully.
                </Text>
            </Box>
        );
    }

    renderHeader() {
        if (this.state.addType == 2) {
            if (this.state.currentStep == 1)
                return "Add Users";
            if (this.state.currentStep == 2)
                return "Select Group(s)";
            if (this.state.currentStep == 3)
                return "Select Role(s)";
            if (this.state.currentStep == 4)
                return "Review & Create";
        }
        return "User - Create";
    }

    render() {
        const { options, selected, checked, loaded, userdataLoading, rolesdataLoading, sitesdataLoading, organisationdataLoading, roleSearch, groupSearch, /*policiesdataLoading, groupdataLoading*/currentStep, steps } = this.state;
        const userdefault = this.props.userdata;
        /*const sitesdata = this.props.sitesdata;*/

        if (userdataLoading || rolesdataLoading || sitesdataLoading || organisationdataLoading /*|| policiesdataLoading, groupdataLoading*/ ) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = userdefault && (userdefault.id != "0") ? 'Edit' : 'Create';
        console.log("Save- after", this.props.groupsdata);
        return (
            <Grid fill={true}
                //rows={['xsmall', 'auto']}
                rows={['xsmall', 'flex', this.state.addType == 2 ? '85px' : 0]}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                    { name: 'footer', start: [0, 2], end: [1, 2] },

                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >
                        {this.renderHeader()}
                    </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {this.state.addType == 1 && <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium", bottom: "medium" }} /*fill="vertical"*/ overflow="auto">
                    <PopUp open={this.state.isSaved} onClose={() => this.onCloseSaving()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="medium">
                            {this.state.addType == 0 && <Box>
                                    <StyledBox style1 direction="row" gap="xlarge" pad={{ "vertical": "small", "horizontal": "small" }}>
                                        <Box data-for="user" data-tip="Add a single user">
                                            <CheckBox
                                                label="Add User"
                                                checked={this.state.addType == 1 ? true : false}
                                                onChange={() => this.setState({ addType: 1 })} />
                                        </Box>
                                        <Box data-for="users" data-tip="Add multiple users in bulk">
                                            <CheckBox
                                                label="Add Users"
                                                checked={this.state.addType == 2 ? true : false}
                                                onChange={() => this.setState({ addType: 2 })} />
                                        </Box>
                                        <ReactTooltip id="user" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                        <ReactTooltip id="users" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                    </StyledBox>
                                </Box>}
                            {this.state.addType == 1 && <Box gap="small">
                                    <Box>
                                        <Text size="large">Description</Text>
                                        <Text>All fields marked with an * are mandatory.</Text>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                                        <FormField label="Email *" error={this.email_err} width="100%">
                                            <TextInput onBlur={() => this.onBlur()} defaultValue={userdefault.email} onChange={evt => this.updateEmailValue(evt)} readOnly={userdefault.id != "0"} disabled={userdefault.id != "0"} />
                                        </FormField>

                                        {false && <Box width="60px">
                                            <FormField label="Org Admin">
                                                <Box pad="small" align="center" justify="center">
                                                    <CheckBox checked={this.isOrgAdmin()} onChange={evt => this.updateOrgAdmin()} />
                                                </Box>
                                            </FormField>
                                        </Box>}
                                    </StyledBox>
                                </Box>}
                            {this.state.addType == 2 &&
                                <Box /*gap="small"*/>
                                    <Box gap="small" /*height="500px"*/>
                                        {/*<TwoColumnWizardExample/>*/}

                                        {currentStep == 1 && <Box id="elso">
                                            <Box gap="small">
                                                <Text>Add multiple users by browsing to a file on your device.</Text>
                                                <Box>
                                                    <Box direction="row" justify="between" style={{ maxWidth: "600px" }}>
                                                        <Text size="large">File Upload</Text>
                                                        <Box style={{ maxWidth: "600px" }} direction="row" align="center" gap="small">
                                                            <CSVLink data={[["Email"/*, "User Name"*/],]} filename="users-template">
                                                                <Button plain icon={<Download color={constants.HPE_GREEN} />} data-for="Download Info" data-tip="Export to CSV" />
                                                                {/*Download Template*/}
                                                            </CSVLink>
                                                            <Box>
                                                                <a data-for="download_template_info" data-tip="Download the template file, add your users information and upload it.">
                                                                    <div>Download Template</div>
                                                                </a>
                                                                <ReactTooltip id="download_template_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Box>
                                                            </Box>
                                                    </Box>
                                                    <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                                                            {!this.state.file &&
                                                                <Dropzone accept=".csv" maxFiles={1} onDrop={acceptedFiles => {
                                                                    console.log(acceptedFiles);
                                                                    ///reader start
                                                                    acceptedFiles.forEach((file) => {
                                                                        const reader = new FileReader();
                                                                        reader.onprogress = (evt) => {
                                                                            this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                                                                        };
                                                                        reader.onabort = () => console.log('file reading was aborted');
                                                                        reader.onerror = () => console.log('file reading has failed');
                                                                        reader.onload = () => {
                                                                            // Do whatever you want with the file contents
                                                                            const binaryStr = reader.result;
                                                                            console.log(binaryStr);
                                                                        }
                                                                        reader.readAsArrayBuffer(file);
                                                                    })
                                                                    ///reader end


                                                                    this.setState({ file: acceptedFiles[0] });
                                                                    if (acceptedFiles[0].type == "application/vnd.ms-excel" || acceptedFiles[0].type == "text/csv") {
                                                                        Papa.parse(acceptedFiles[0], {
                                                                            delimiter: "",
                                                                            header: true,
                                                                            skipEmptyLines: "greedy",
                                                                            //step: (row, parser) => {
                                                                            //    console.log("Row data:", row.data);
                                                                            //    console.log("Row errors:", row.errors);
                                                                            //    this.setState(prevState => ({
                                                                            //        data: [...prevState.data, row.data]
                                                                            //    }));
                                                                            //},
                                                                            complete: (results) => {
                                                                                console.log(results);
                                                                                if (results && results.data && results.data.length > 0 && results.data[0].hasOwnProperty("Email") /*&& results.data[0].hasOwnProperty("User Name")*/) {
                                                                                    for (let i = 0; i <= results.data.length; i += 1) {
                                                                                        this.users_err.push({ email: "", name: "" });
                                                                                    }
                                                                                    this.setState({ data: results.data, uploadIssue: "" }, () => this.validationCheck());
                                                                                }
                                                                                else
                                                                                    this.setState({ data: [], file: undefined, uploadIssue: "The uploaded file does not align with the latest template. Please download the latest template, edit it and upload again." });
                                                                            }
                                                                        });
                                                                    }
                                                                }}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="upload-container">
                                                                            <div {...getRootProps()} className="drop-zone">
                                                                                <Box align="center" margin={{ top: "10px" }}>
                                                                                    <Upload />
                                                                                </Box>
                                                                                <input {...getInputProps()} />
                                                                                <p>Drag and drop or browse to your files</p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            }
                                                            {this.state.file &&
                                                                <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                                                                    {this.state.progressValue == 100 ?
                                                                        <DocumentCsv size="medium" />
                                                                        :
                                                                        <LoadingIndicator />
                                                                    }
                                                                    <Text>{this.state.file.name}</Text>
                                                                    <Box>
                                                                        <Meter type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]} />
                                                                        <Box justify="end" direction="row">
                                                                            <Text size="small">Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%</Text>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            }
                                                    <Text color="status-critical">{this.state.uploadIssue}</Text>
                                                        </Box>
                                                </Box>
                                            {false && <Box style={{ maxWidth: "600px" }} direction="row" align="center" gap="small">
                                                <CSVLink data={[["Email", /*"User Name"*/],]} filename="users-template">
                                                    <Button plain icon={<Download color={constants.HPE_GREEN} />} data-for="Download Info" data-tip="Export to CSV" />
                                                    {/*Download Template*/}
                                                </CSVLink>
                                                <Box>
                                                    <a data-for="download_template_info" data-tip="Download the template file, add your users information and upload it.">
                                                        <div>Download Template</div>
                                                    </a>
                                                    <ReactTooltip id="download_template_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </Box>}
                                                <Box id="table" /*style={{"overflow-y": "overlay"}}*/>
                                                {/*this.renderTable(this.state.data)*/}
                                                {this.renderTable2()}
                                                </Box>
                                            </Box>
                                        </Box>}

                                        {/*GROUPS*/}
                                        {currentStep == 2 && <Box>
                                            <Text>Select group(s) that a user will be added to. Once a user becomes a member of a group, it will be assigned the permissions allowed for that group.</Text>
                                            <Box gap="small" margin={{"top": "20px"}}/*width={{ min: 'medium' }}*/ width={{ min: 'medium', max: 'medium' }} /*height={{ max: '400px' }}*/>
                                            <Box direction="row" flex>
                                                <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                    <TextInput focusIndicator={false} plain placeholder="Search" value={groupSearch}
                                                        onChange={e => {
                                                            let searchText = e.target.value;
                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                            const filtered = /*constants.GROUPS*/this.props.groupsdata.filter(o => /*o.id != 0 &&*/ o.name.match(regexp));
                                                            this.setState({ filteredGroups: filtered, groupSearch: e.target.value });
                                                        }} />
                                                    {(groupSearch != "" && groupSearch != undefined) ?
                                                        <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={groupSearch == ""} onClick={() => this.setState({ groupSearch: "", filteredGroups: this.props.groupsdata/*constants.GROUPS.filter(r => r.id != 0)*/ })} />
                                                        :
                                                        <Button icon={<Blank />} disabled />}
                                                </StyledBox>
                                            </Box>
                                            {this.state.filteredGroups.length > 0 && <StyledBox style1/*height={{ max: 'medium' }}*/ style={{ "overflow-y": "auto", "display": "block", cursor: "pointer" }} width={{ min: 'medium', max: 'medium' }} height={{ max: '600px' }}>
                                                {this.state.filteredGroups/*.concat(this.state.filteredGroups).concat(this.state.filteredGroups)*/.map(option =>
                                                    <Box /*width={{ min: 'medium', max: 'medium' }}*/ pad="small" background={this.state.selectedGroupsCopy.some(r => r.name == option.name) ? 'brand' : undefined}
                                                        focusIndicator={false}
                                                        onClick={() => {
                                                            if (this.state.selectedGroupsCopy.some(u => u.name == option.name)) {
                                                                //remove
                                                                this.setState(prevState => ({
                                                                    selectedGroupsCopy: prevState.selectedGroupsCopy.filter(r => r.name != option.name)
                                                                }))
                                                            }
                                                            else {
                                                                this.setState(prevState => ({
                                                                    selectedGroupsCopy: [...prevState.selectedGroupsCopy, option]
                                                                }))
                                                            }
                                                            this.props.userdata.userGroups = this.state.selectedGroupsCopy;
                                                        }}>
                                                        <Box direction="row" justify="between">
                                                            <Text weight="bold" style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>{option.name}</Text>
                                                            <CheckBox focusIndicator={false} checked={this.state.selectedGroupsCopy.some(r => r.name == option.name)} />
                                                        </Box>
                                                        <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                            {option.description}
                                                        </Box>
                                                    </Box>
                                                )}
                                            </StyledBox>}
                                            {this.state.filteredGroups.length == 0 &&
                                                <Box>
                                                    <Text>
                                                        No Groups found
                                                    </Text>
                                                </Box>
                                            }
                                            </Box>
                                    </Box>}
                                        {/*ROLES*/}
                                        {currentStep == 3 && <Box>
                                            <Text>Select role(s) that a user will be assigned. Once assigned the user will have the permissions allowed for that role.</Text>
                                            <Box gap="small" margin={{ "top": "20px" }}/*width={{ min: 'medium' }}*/>
                                                <Box direction="row" flex width={{ min: 'medium', max: 'medium' }}>
                                                    <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                        <TextInput focusIndicator={false} plain placeholder="Search" value={roleSearch}
                                                            onChange={e => {
                                                                let searchText = e.target.value;
                                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                const filtered = /*constants.ROLES*/this.props.policiesdata.filter(o => /*o.id != 0 &&*/ o.name.match(regexp));
                                                                this.setState({ filteredRoles: filtered, roleSearch: e.target.value });
                                                            }} />
                                                        {(roleSearch != "" && roleSearch != undefined) ?
                                                            <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={roleSearch == ""} onClick={() => this.setState({ roleSearch: "", filteredRoles: /*constants.ROLES*/this.props.policiesdata.filter(r => r.id != 0) })} />
                                                            :
                                                            <Button icon={<Blank />} disabled />}
                                                    </StyledBox>
                                                </Box>
                                                {this.state.filteredRoles.length > 0 && <StyledBox style1 style={{ "overflow-y": "auto", "display": "block", cursor: "pointer" }} width={{ min: 'medium', max: 'medium' }} height={{ max: '600px' }}>
                                                    {this.state.filteredRoles/*.concat(this.state.filteredRoles).concat(this.state.filteredRoles).concat(this.state.filteredRoles)*/.map(option =>
                                                        <Box pad="small" background={this.state.selectedRolesCopy.some(r => r.name == option.name) ? 'brand' : undefined}
                                                            focusIndicator={false}
                                                            onClick={() => {
                                                                if (this.state.selectedRolesCopy.some(u => u.name == option.name)) {
                                                                    //remove
                                                                    this.setState(prevState => ({
                                                                        selectedRolesCopy: prevState.selectedRolesCopy.filter(r => r.name != option.name)
                                                                    }))
                                                                }
                                                                else {
                                                                    this.setState(prevState => ({
                                                                        selectedRolesCopy: [...prevState.selectedRolesCopy, option]
                                                                    }))
                                                                }
                                                                this.props.userdata.userPolicies = this.state.selectedRolesCopy;
                                                            }}>
                                                            <Box direction="row" justify="between">
                                                                <Text weight="bold" style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>{option.name}</Text>
                                                                <CheckBox focusIndicator={false} checked={this.state.selectedRolesCopy.some(r => r.name == option.name)} />
                                                            </Box>
                                                            <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                {option.description}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </StyledBox>}
                                                {this.state.filteredRoles.length == 0 &&
                                                    <Box>
                                                        <Text>
                                                            No Roles found
                                                        </Text>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>}
                                        {/*Summary*/}
                                        {currentStep == 4 && <Box>
                                            {this.renderSummary()}
                                            </Box>}
                                    </Box>

                                </Box>
                            }

                            {(this.state.addType == 1 || (false && this.state.addType == 2 && this.state.data && this.state.data.length > 0)) && <Box direction="row" gap="large">
                                <Tabs justify="start" width="100%" flex>
                                    <Tab title="Roles">
                                        <Box pad={{ "top": "small" }}>
                                            <ReactTooltip id={"addRolebtnEmpty"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            {this.state.selectedRoles.length == 0 && <Box data-for={"addRolebtnEmpty"} data-tip={"Select to add a role(s) to this user"} style={{ "height": "24px" }}>
                                                <StyledButton
                                                    typeSave
                                                    label="Add Role(s)"
                                                    alignSelf="end"
                                                    onClick={() => this.setState({ isRoleSidePanelOpen: true, selectedRolesCopy: this.state.selectedRoles })}
                                                />
                                            </Box>}
                                            {this.renderNewRolesTable()}
                                            <FilterSidePanel
                                                open={this.state.isRoleSidePanelOpen}
                                                onAssign={() => {
                                                    this.props.userdata.userPolicies = this.state.selectedRolesCopy;
                                                    this.setState({ selectedRoles: this.state.selectedRolesCopy, isRoleSidePanelOpen: false, roleSearch: "", filteredRoles: /*constants.ROLES.filter(r => r.id != 0)*/this.props.policiesdata })}
                                                }
                                                onClose={() => this.setState({ isRoleSidePanelOpen: false, roleSearch: "", filteredRoles: this.props.policiesdata/*constants.ROLES.filter(r => r.id != 0)*/ })}>
                                                <Box style={{ "margin-bottom": "15px" }}>
                                                    <Text>A user is not assigned any permissions when created or added to your organization. Assign a role to the user to assign permissions allowed for that role.</Text>
                                                </Box>
                                                <Box gap="small" /*width={{ min: 'medium' }}*/ >
                                                    <Box direction="row" flex>
                                                        <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                            <TextInput focusIndicator={false} plain placeholder="Search" value={roleSearch}
                                                                onChange={e => {
                                                                    let searchText = e.target.value;
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = /*constants.ROLES*/this.props.policiesdata.filter(o => /*o.id != 0 &&*/ o.name.match(regexp));
                                                                    this.setState({ filteredRoles: filtered, roleSearch: e.target.value });
                                                                }} />
                                                            {(roleSearch != "" && roleSearch != undefined) ?
                                                                <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={roleSearch == ""} onClick={() => this.setState({ roleSearch: "", filteredRoles: /*constants.ROLES*/this.props.policiesdata.filter(r => r.id != 0) })} />
                                                                :
                                                                <Button icon={<Blank />} disabled />}
                                                        </StyledBox>
                                                    </Box>
                                                    {this.state.filteredRoles.length > 0 && <StyledBox style1 style={{ "overflow-y": "auto", "display": "block", cursor: "pointer" }} /*width={{ min: 'medium', max: 'medium' }}*/ height={{ max: '600px' }}>
                                                        {this.state.filteredRoles/*.concat(this.state.filteredRoles).concat(this.state.filteredRoles)*/.map(option =>
                                                            <Box pad="small" background={this.state.selectedRolesCopy.some(r => r.name == option.name) ? 'brand' : undefined}
                                                                focusIndicator={false}
                                                                onClick={() => {
                                                                    if (this.state.selectedRolesCopy.some(u => u.name == option.name)) {
                                                                        //remove
                                                                        this.setState(prevState => ({
                                                                            selectedRolesCopy: prevState.selectedRolesCopy.filter(r => r.name != option.name)
                                                                        }))
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => ({
                                                                            selectedRolesCopy: [...prevState.selectedRolesCopy, option]
                                                                        }))
                                                                    }
                                                                }}>
                                                                <Box direction="row" justify="between">
                                                                    <Text weight="bold" style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>{option.name}</Text>
                                                                    <CheckBox focusIndicator={false} checked={this.state.selectedRolesCopy.some(r => r.name == option.name)} />
                                                                </Box>
                                                                <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                    {option.description}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </StyledBox>}
                                                    {this.state.filteredRoles.length == 0 &&
                                                        <Box>
                                                            <Text>
                                                                No Roles found
                                                            </Text>
                                                        </Box>
                                                    }
                                                </Box>
                                            </FilterSidePanel>
                                        </Box>
                                    </Tab>
                                    <Tab title="Groups">
                                        <Box pad={{ "top": "small" }}>
                                            <ReactTooltip id={"addGroupbtnEmpty"} place="right" type="dark" effect="solid" arrowColor="transparent" />
                                            {this.state.selectedGroups.length == 0 && <Box data-for={"addGroupbtnEmpty"} data-tip={"Select to add a group(s) to this user"} style={{ "height": "24px" }}>
                                                <StyledButton
                                                    typeSave
                                                    label="Add Group(s)"
                                                    alignSelf="end"
                                                    onClick={() => this.setState({ isGroupSidePanelOpen: true, selectedGroupsCopy: this.state.selectedGroups })}
                                                />
                                            </Box>}
                                            {this.renderGroupsTable()}
                                            <FilterSidePanel
                                                open={this.state.isGroupSidePanelOpen}
                                                onAssign={() => {
                                                    this.props.userdata.userGroups = this.state.selectedGroupsCopy;
                                                    this.setState({ selectedGroups: this.state.selectedGroupsCopy, isGroupSidePanelOpen: false, groupSearch: "", filteredGroups: this.props.groupsdata/*constants.GROUPS.filter(r => r.id != 0)*/ })}
                                                }
                                                onClose={() => this.setState({ isGroupSidePanelOpen: false, groupSearch: "", filteredGroups: this.props.groupsdata/*constants.GROUPS.filter(r => r.id != 0)*/ })}>
                                                <Box style={{ "margin-bottom": "15px" }}>
                                                    <Text>A user is not assigned any permissions when created or added to your organization. Assign a role to the user to assign permissions allowed for that role.</Text>
                                                </Box>
                                                <Box gap="small" width={{ min: 'medium' }} /*height={{ max: '400px' }}*/>
                                                    <Box direction="row" flex>
                                                        <StyledBox style1 flex direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid" }} onClick={() => { }}>
                                                            <TextInput focusIndicator={false} plain placeholder="Search" value={groupSearch}
                                                                onChange={e => {
                                                                    let searchText = e.target.value;
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = /*constants.GROUPS*/this.props.groupsdata.filter(o => /*o.id != 0 &&*/ o.name.match(regexp));
                                                                    this.setState({ filteredGroups: filtered, groupSearch: e.target.value });
                                                                }} />
                                                            {(groupSearch != "" && groupSearch != undefined) ?
                                                                <Button icon={<FormClose color={constants.HPE_GREEN} />} disabled={groupSearch == ""} onClick={() => this.setState({ groupSearch: "", filteredGroups: this.props.groupsdata/*constants.GROUPS.filter(r => r.id != 0)*/ })} />
                                                                :
                                                                <Button icon={<Blank />} disabled />}
                                                        </StyledBox>
                                                    </Box>
                                                    {this.state.filteredGroups.length > 0 && <StyledBox style1/*height={{ max: 'medium' }}*/ style={{ "overflow-y": "auto", "display": "block", cursor: "pointer" }} width={{ min: 'medium', max: 'medium' }} height={{ max: '600px' }}>
                                                        {this.state.filteredGroups/*.concat(this.state.filteredGroups).concat(this.state.filteredGroups)*/.map(option =>
                                                            <Box /*width={{ min: 'medium', max: 'medium' }}*/ pad="small" background={this.state.selectedGroupsCopy.some(r => r.name == option.name) ? 'brand' : undefined}
                                                                focusIndicator={false}
                                                                onClick={() => {
                                                                    if (this.state.selectedGroupsCopy.some(u => u.name == option.name)) {
                                                                        //remove
                                                                        this.setState(prevState => ({
                                                                            selectedGroupsCopy: prevState.selectedGroupsCopy.filter(r => r.name != option.name)
                                                                        }))
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => ({
                                                                            selectedGroupsCopy: [...prevState.selectedGroupsCopy, option]
                                                                        }))
                                                                    }
                                                                }}>
                                                                <Box direction="row" justify="between" >
                                                                    <Text weight="bold" style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>{option.name}</Text>
                                                                    <CheckBox focusIndicator={false} checked={this.state.selectedGroupsCopy.some(r => r.name == option.name)} />
                                                                </Box>
                                                                <Box style={{ "text-overflow": "ellipsis", "display": "inline-block", "white-space": "nowrap", "overflow": "hidden" }}>
                                                                    {option.description}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </StyledBox>}
                                                    {this.state.filteredGroups.length == 0 &&
                                                        <Box>
                                                            <Text>
                                                                No Groups found
                                                            </Text>
                                                        </Box>
                                                    }
                                                </Box>
                                            </FilterSidePanel>
                                        </Box>
                                    </Tab>
                                </Tabs>
                            </Box>}




                            {false && <Box id="jaj" direction="row" gap="large">
                                {!this.isOrgAdmin() &&
                                    <Box gap="small" flex="shrink">
                                        <Box>
                                            <Text size="large">Roles</Text>
                                        </Box>
                                        <Box>
                                            <StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} />
                                        </Box>
                                        <Box ref={this.dtwrapper} width={{ min: "700px" }}>
                                            {this.renderRolesTable()}
                                        </Box>
                                        {/*<Box gap="medium">*/}
                                        {/*    {this.state.locationroles.map((info, idx) =>*/}
                                        {/*        <Box direction="row">*/}
                                        {/*            <StyledBox style1 direction="row" justify="start" gap="medium" pad={{ "left": "small" }} width="" flex="shrink">*/}
                                        {/*                <Box width="350px">*/}
                                        {/*                    <FormField label="Site" error={this.entryerrors[idx].locationId}>*/}
                                        {/*                        <Select*/}
                                        {/*                            placeholder="Site"*/}
                                        {/*                            options={this.renderOptions("options")}*/}
                                        {/*                            onChange={evt => this.UpdateL(evt, idx)}*/}
                                        {/*                            //value={this.sitesfull.find(l => l.id == info.locationId) ? this.sitesfull.find(l => l.id == info.locationId).name : ""}*/}
                                        {/*                            //value={evt => this.LocValue(evt,info)}*/}
                                        {/*                            value={info.name}*/}
                                        {/*                            disabled={this.renderOptions("hide")}*/}
                                        {/*                        />*/}
                                        {/*                    </FormField>*/}
                                        {/*                </Box>*/}

                                        {/*                <Box direction="row" width="250px">*/}
                                        {/*                    <FormField label="Role" error={this.entryerrors[idx].role}>*/}
                                        {/*                        <Select*/}
                                        {/*                            multiple*/}
                                        {/*                            placeholder="Select Role"*/}
                                        {/*                            * /}
                                        {/*                            value={this.state.locationroles[idx].roles.length ? this.renderSelected1(idx) : ""}*/}
                                        {/*                            options={options.map(o => o.name)}*/}
                                        {/*                            onChange={({ option }) => {*/}
                                        {/*                                const newSelected = [...this.state.locationroles];*/}
                                        {/*                                const selectedIndex = newSelected[idx].roles.indexOf(option);*/}
                                        {/*                                if (selectedIndex >= 0) {*/}
                                        {/*                                    newSelected[idx].roles.splice(selectedIndex, 1);*/}
                                        {/*                                } else {*/}
                                        {/*                                    newSelected[idx].roles.push(option);*/}
                                        {/*                                }*/}
                                        {/*                                this.setState({ selected: newSelected.sort() });*/}
                                        {/*                            }}*/}
                                        {/*                        >*/}
                                        {/*                            {option => (*/}
                                        {/*                                <Option value={option} selected={this.state.locationroles[idx].roles.indexOf(option) >= 0} />*/}
                                        {/*                            )}*/}
                                        {/*                        </Select>*/}
                                        {/*                    </FormField>*/}

                                        {/*                    <Button icon={<Trash />} onClick={() => this.DeleteL(idx)} />*/}
                                        {/*                </Box>*/}
                                        {/*            </StyledBox>*/}
                                        {/*        </Box>*/}
                                        {/*    )}*/}
                                        {/*</Box>*/}
                                        {/*<StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} disabled={(this.state.locationroles.length == this.sitesfull.length) ? true : false} />*/}
                                    </Box>}
                            </Box>}


                        </Box>
                    </form>
                    {this.state.openManageRoles &&
                        <Box>
                            {this.renderManage(this.state.openManageType)}
                        </Box>
                    }
                </Box>

                <Box gridArea='footer' background={constants.BACKGROUND_COLOR} /*pad={{ horizontal: "medium" }}*/>
                    {this.state.addType == 2 && <StepFooterG
                        steps={steps}
                        currentStep={currentStep}
                        isDataUploaded={this.state.data.length > 0}
                        numberOfIssue={this.state.numberOfIssue}
                        onValidate={()=> this.validationCheck()}
                        onNext={() => this.setState({ currentStep: this.state.currentStep + 1 })}
                        onPrev={() => this.setState({ currentStep: this.state.currentStep - 1 })}
                        onSave={() => this.handleSaveBulk()} />}
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const userId = ownProps.match.params.userId || '';
    if (state.selectedUserReducer.userdata && (userId == state.selectedUserReducer.userdata.id)) {
        start = JSON.parse(JSON.stringify(state.selectedUserReducer.userdata));
        console.log("yay", state);
        return {
            userdata: state.selectedUserReducer.userdata,
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            usersdata: state.usersReducer.usersdata,
            groupsdata: state.groupsReducer.groupsdata,
            policiesdata: state.policiesReducer.policiesdata
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction, ...OrganisationAction, ...PolicyAction, ...GroupAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserCreateContainer));