import * as ActionType from './ActionType';
import TimeZoneApi from '../api/TimeZoneApi';
import { ApiCallBeginAction } from './ApiAction';


export const getTimeZonesResponse = timezonesdata => ({
    type: ActionType.GET_TIME_ZONE_RESPONSE,
    timezonesdata
});

export function getTimeZonesAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return TimeZoneApi.getTimeZones()
            .then(timezonesdata => {
                dispatch(getTimeZonesResponse(timezonesdata));
                return timezonesdata;
            }).catch(error => {
                throw error;
            });
    };
}