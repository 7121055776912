import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BuildingAction from '../action/BuildingAction';
import * as LevelAction from '../action/LevelAction';
import * as AssetAction from '../action/AssetAction';
import * as AssetsOverviewAction from '../action/AssetsOverviewAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import * as SiteAction from '../action/SiteAction';
import * as AssociationAction from '../action/AssociationAction';
import * as TrackerAction from '../action/TrackerAction';
import * as MetaDataAction from '../action/MetaDataAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import TrackerApi from '../api/TrackerApi';
import AssociationApi from '../api/AssociationApi';
import OrgTagListTable from './OrgTagListTable';
import OrgLabelListTable from './OrgLabelListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text, Tab, Tabs, Grid, Select, Grommet, TextInput } from 'grommet';
import { CircleInformation, Blank, Aggregate, Trash, Download, Edit, FormClose } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import constants from '../constants';
import PopUpConfirm from './common/PopUpConfirm';
import ReactTooltip from "react-tooltip";
import PopUp from './common/PopUp';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { Context } from './Context';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import AssetLabelApi from '../api/AssetLabelApi';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        }
    }
});
export class OrgTagListContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.downloadData = [];
        this.bulkLabels = [];
        this.selectedTagsFull = [];
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.handleEditLabel = this.handleEditLabel.bind(this);
        this.handleBulkAssign = this.handleBulkAssign.bind(this);
        this.handleEditAsset = this.handleEditAsset.bind(this);
        this.filter = "";
        this.metadata_err = {
            manufacturer: '',
            model: '',
            deviceType: ''
        };
        this.state = {
            selectedLabel: 0,
            selectedAsset: 0,
            selectedTracker: 0,
            openLabel: undefined,
            openAsset: undefined,
            openTracker: undefined,
            deletedCategoryId: 0,
            deletedLabelId: 0,
            deletedAssetId: 0,
            deletedTrackerId: 0,
            deletedLabelIds: [],
            deletedAssetIds: [],
            deletedTrackerIds: [],
            open: undefined,
            tabindex: 0,

            selectedTags: [],
            //openTags: undefined,
            openEdit: undefined,
            isDisabled: false,
            class: "",
            type: "",
            department: "",
            collection: "",

            isDuplication: false,
            select: [],

            selectedAssets: [],
            openAssets: undefined,
            mergedbuildingsdataLoading: true,
            mergedlevelsdataLoading: true,
            assetsdataLoading: true,
            assetsoverviewdataLoading: true,
            assetlabelsdataLoading: true,
            departmentsdataLoading: true,
            sitesdataLoading: true,
            collectionsdataLoading: true,
            associationsdataLoading: true,
            trackersdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            isDisabledLabels: false,
            isLabelsSaveSuccessful: false,
            isLabelsSaved: false,

            manufacturer: null,
            manufacturerId: null,
            model: null,
            modelId: null,
            deviceType: null,
            deviceTypeId: null,

            isDeletedWarning: false
        };

    }
    //componentWillReceiveProps(props) {
    //    console.log("filter", props);
    //    if (props.location.state && !this.filter) {
    //        this.filter = props.location.state.filter;
    //    }
    //}

    componentWillMount() {
        var index = this.props.location.state && this.props.location.state.index ? this.props.location.state.index : 0;
        this.setState({ tabindex: index });
    }

    componentDidMount() {
        /////
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        /////
        //this.props.action.getBuildingsAction()
        //    .then(response => this.setState({ buildingsdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Buildings List Error', error);
        //    });
        //this.props.action.getLevelsAction()
        //    .then(response => this.setState({ levelsdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Levels List Error', error);
        //    });
        this.props.action.getAssetsAction("", true)
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assets List Error', error);
            });
        this.props.action.getAssetsOverviewAction()
            .then(response => this.setState({ assetsoverviewdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetsOverviewAction List Error', error);
            });
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getAssociationsAction()
            .then(response => this.setState({ associationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getTrackersAction()
            .then(response => this.setState({ trackersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
        this.props.action.getMetadatasAction()
            .then(response => this.setState({ metadatasdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
    }

    handleBulkAssign(ids) {
        let tags = this.props.assetsdata.filter(a => ids.includes(a.id));
        if (this.props.trackersdata && this.props.trackersdata.length > 0 && !this.props.trackersdata[0].hasOwnProperty("count"))
            tags = tags.filter(t => !(this.props.trackersdata.map(item => item.name.toLowerCase()).includes(t.name.toLowerCase())));
        console.log("dupli0", tags, ids);
        let isDuplication = tags.length != ids.length ? true : false;
        let isControlTag = tags.some(s => s.isControlTag);
        tags = tags.filter(f => !f.isControlTag);
        this.setState({ selectedTags: tags, isDuplication: isDuplication, isControlTag: isControlTag, openEdit: (!isDuplication && !isControlTag) });
    }

    handleDelete(type, nro) {
        console.log(`*Delete* started on ${type}`, nro);
        switch (type) {
            case 'label':
                this.setState({ selectedLabel: nro, openLabel: true });
                break;
            case 'asset':
                this.setState({ selectedAsset: nro, openAsset: true });
                break;
            case 'assets':
                this.setState({ selectedAssets: nro, openAssets: true });
                break;
            default:
                break;
        }
    }

    eventDelete(event) {
        const selectedId = this.state.selectedCategory;

        if (selectedId) {
            this.setState({ selectedCategory: 0, open: undefined });
            this.setState({ deletedCategoryId: selectedId });
            this.props.action.deleteAssetAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Asset List Delete Error', error);
                });
        }
    }
    async labelDelete() {
        console.log("Delete", this.state.selectedLabel);
        if (this.state.selectedLabel.hasOwnProperty('organizationId')) {
            const selectedId = this.state.selectedLabel.id;
            this.setState({ selectedLabel: 0, openLabel: undefined });
            this.setState({ deletedLabelIds: this.state.deletedLabelIds.concat([selectedId]) });
            //this.props.action.deleteAssetLabelAction(selectedId)
            //    .catch(error => {
            //        console.log('*DEBUG*- AssetLabel List Delete Error', error);
            //    });
            AssetLabelApi.deleteAssetLabel(selectedId)
                .then(resp => {
                    if (resp.status == 205) {
                        this.setState({ isDeletedWarning: true }, () => this.props.action.getAssetLabelsAction());
                    } else
                        this.props.action.getAssetLabelsAction()
                })
        }
        else if (this.state.selectedLabel.hasOwnProperty('name')) {
            //Multiple delete
            const id = this.state.selectedLabel.id;
            let labels = this.props.assetlabelsdata.reduce((res, option)=> {
                if (option.name == this.state.selectedLabel.name) {
                    res.push(option.id);
                }
                return res;
            }, []);
            let missingFromMeridian = [];
            for (let i = 0; i < labels.length; i++) {
                await AssetLabelApi.deleteAssetLabel(labels[i])
                    .then(resp => {
                        if (resp.status == 205)
                            missingFromMeridian.push(true);
                        if (i == labels.length - 1) {
                            let openWarning = missingFromMeridian.some(i => i == true);
                            this.setState({ deletedLabelIds: this.state.deletedLabelIds.concat([id]), selectedLabel: 0, openLabel: undefined, isDeletedWarning: openWarning}, () => this.props.action.getAssetLabelsAction());
                        }
                    });
            }

        }

    }
    assetDelete() {
        const selectedId = this.state.selectedAsset;

        if (selectedId) {
            this.setState({ selectedAsset: 0, openAsset: undefined });
            this.setState({ deletedAssetIds: this.state.deletedAssetIds.concat([selectedId]) });
            this.props.action.deleteAssetAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Asset List Delete Error', error);
                });
            this.count--;
            this.setState({ select: this.state.select.filter(id => id != selectedId) });
        }
    }
    assetsDelete() {
        const selected = this.state.selectedAssets;

        if (selected) {
            this.setState({ selectedAssets: [], openAssets: undefined, select: [] });
            selected.forEach(i => {
                this.props.action.deleteAssetAction(i)
                    .catch(error => {
                        console.log('*DEBUG*- Asset List Delete Error', error);
                    });
            });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });
    onCloseLabel = () => this.setState({ openLabel: undefined });
    onCloseAsset = () => this.setState({ openAsset: undefined });
    onCloseAssets = () => this.setState({ openAssets: undefined });
    onCloseBulkAssign = () => {
        for (const property of Object.keys(this.metadata_err)) {
            this.metadata_err[property] = "";
        }
        this.setState({
            class: "",
            type: "",
            department: "",
            collection: "",
            manufacturer: null,
            manufacturerId: null,
            model: null,
            modelId: null,
            deviceType: null,
            deviceTypeId: null,
            openEdit: false,
            isDisabled: false
        });
    }

    handleNew() {
        if (this.state.tabindex == 0) {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgcreatetag/0');
            else
                this.props.history.push('/orgcreatetag/0');
        } 
        if (this.state.tabindex == 1) {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push('/' + sessionStorage.getItem("organisationId") +'/orglabel/0');
            else
                this.props.history.push('/orglabel/0');
        }
    }

    handleEditLabel(nro = 0) {
        if (sessionStorage.getItem("organisationId"))
            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orglabel/' + nro);
        else
            this.props.history.push('/orglabel/' + nro);
    }
    handleEditLabels=(nro = 0, name = "")=> {
        if (name) {
            let labels = this.props.assetlabelsdata.filter(l => l.name == name);
            if (sessionStorage.getItem("organisationId")) {
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orglabel/' + nro,
                    state: { labels: labels }
                });
            }
            else
                this.props.history.push({
                    pathname: '/orglabel/' + nro,
                    state: { labels: labels }
                });
        }
    }
    handleEditAsset(nro = 0) {
        if (sessionStorage.getItem("organisationId"))
            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgtag/' + nro);
        else
            this.props.history.push('/orgtag/' + nro);
    }
    onActive(idx) {
        this.setState({ tabindex: idx });
    }
    onChangeSelect = (select, count) => {
        this.count = count;
        this.setState({ select: select });
        //Create download data
        this.downloadData = [];
        this.selectedTagsFull = [];
        select.forEach(id => {
            let asset = this.props.assetsdata.find(a => a.id == id);
            this.selectedTagsFull.push({ ...asset });
            this.downloadData.push({
                "Name": asset.name,
                "External Ref ID": asset.externalId,
                "MAC": asset.id
            });
        });
    }
    onBulkEditLabels() {
        if (this.selectedTagsFull.length > 0) {
            this.setState({ isDisabledLabels: true });
            let newLabels = [];
            this.bulkLabels.forEach(element => newLabels.push({ "assetLabelId": element.id }));

            this.selectedTagsFull.forEach((item, id) => {
                item.assetBeaconTags = newLabels;
                this.props.action.saveAssetAction(item)
                    .then((response) => {
                        if (id == this.selectedTagsFull.length - 1)
                            this.props.action.getAssetsAction("", true)
                                .then(r => {
                                    this.onCloseBulkLabels();
                                    this.setState({
                                        isLabelsSaved: true,
                                        isLabelsSaveSuccessful: response.ok,
                                    });
                                })
                                .catch(error => {
                                    console.log('*DEBUG*- Assets List Error', error);
                                });

                        //this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    }).catch(error => {
                        console.log('*DEBUG*-Save AssetEdit Error', error);
                        this.setState({ disabledButton: false });
                    });
            });
        }
    }
    onCloseBulkLabels = () => { this.bulkLabels = []; this.setState({ openBulkLabels: false, isDisabledLabels: false }); }

    updateMetadata(value, name, nameId = "name") {
        if (value.length > 0) {
            let list = [];
            let exist = null;
            switch (name) {
                case "manufacturer":
                    list = this.props.manufacturersdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        this.setState({ manufacturerId: exist.id, manufacturer: exist });
                    } else {
                        this.setState({ manufacturerId: 0, manufacturer: { id: 0, name: value } });
                    }
                    break;
                case "model":
                    list = this.props.modelsdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        this.setState({ modelId: exist.id, model: exist });
                    } else {
                        this.setState({ modelId: 0, model: { id: 0, name: value } });
                    }
                    break;
                case "deviceType":
                    list = this.props.typesdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        this.setState({ deviceTypeId: exist.id, deviceType: exist });
                    } else {
                        this.setState({ deviceTypeId: 0, deviceType: { id: 0, name: value } });
                    }
                    break;
                default:
                    break;
            }
        } else {
            if (name == "manufacturer") this.setState({ anufacturerId: null, manufacturer: null });
            if (name = "mode") this.setState({ modelId: null, model: null });
            if (name = "deviceType") this.setState({ deviceTypeId: null, deviceType: null });
        }

        if (value.length > 30) {
            this.setState({ isDisabled: true });
            let displayName = "";
            if (name == "deviceType")
                displayName = "Model Type";
            else {
                displayName = name.charAt(0).toUpperCase() + name.slice(1);
            }
            this.metadata_err[name] = `${displayName} can't be longer than 30 character`;
        }
        else {
            this.setState({ isDisabled: false });
            this.metadata_err[name] = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    filterModelByManufacturer(manufacturer) {
        let list = this.props.metadatas.filter(f => f.manufacturer.name == manufacturer && f.model);
        list = list.map(e => e.model.name);
        return [...new Set(list)];
    }

    filterModelTypeByManufacturer(manufacturer, model) {
        let list = this.props.metadatas.filter(f => f.manufacturer.name == manufacturer && f.deviceType);
        if (model != "" && model != null) {
            list = list.filter(f => f.model && f.model.name == model);
        }
        list = list.map(e => e.deviceType.name);
        return [...new Set(list)];
    }

    getSuggestionOf(name, list) {
        if (list.length == 0)
            return [];
        else {
            list.sort(constants.byPropertyCalled("name"));
            if (this.state[name]) {
                return list.filter(d => d.name.toLowerCase().includes(this.state[name].name.toLowerCase())).map(l => l.name);
            }
            //return list;
            return list.map(item => item.name);
        }
    }

    resetMetaData(property) {
        this.metadata_err[property] = "";
        if (property == "manufacturer") this.setState({ manufacturerId: null, manufacturer: null, modelId: null, model: null, deviceTypeId: null, deviceType: null });
        if (property == "model") this.setState({ modelId: null, model: null, deviceTypeId: null, deviceType: null });
        if (property == "deviceType") this.setState({ deviceTypeId: null, deviceType: null });
    }

    async autoGenerateTracker() {
        this.setState({ isDisabled: true });
        let tags = [];
        for (let i = 0; i < this.state.selectedTags.length; i++) {
            let tag = this.state.selectedTags[i];
            let tracker = {
                appLocation: "",
                category: null,
                categoryName: "",
                categoryid: "0",
                class: "Asset",
                collection: null,
                collectionId: "0",
                createdAt: null,
                deleted: false,
                department: null,
                departmentId: "0",
                id: "0",
                imageUrl: "",
                locationId: sessionStorage.getItem("locationId"),
                name: "",
                organizationId: sessionStorage.getItem("organisationId"),
                servicedBy: "",
                stateId: null,
                type: null,
                updatedAt: null,
                version: null,
                manufacturer: null,
                manufacturerId: null,
                model: null,
                modelId: null,
                deviceType: null,
                deviceTypeId: null
            };
            if (this.state.class)
                tracker.class = this.state.class;
            //Set Metadata only if class == Asset
            if (this.state.class && this.state.class == "Asset") {
                if (this.state.manufacturer) {
                    tracker.manufacturer = this.state.manufacturer;
                    tracker.manufacturerId = this.state.manufacturerId;
                }
                if (this.state.model) {
                    tracker.model = this.state.model;
                    tracker.modelId = this.state.modelId;
                }
                if (this.state.deviceType) {
                    tracker.deviceType = this.state.deviceType;
                    tracker.deviceTypeId = this.state.deviceTypeId;
                }
                if (i != 0 && (this.state.manufacturerId == 0 || this.state.modelId == 0 || this.state.deviceTypeId == 0)) {
                    if (this.state.manufacturerId == 0) {
                        tracker.manufacturerId = this.manufacturerId;
                        tracker.manufacturer = this.manufacturer;
                    }
                    if (this.state.modelId == 0) {
                        tracker.modelId = this.modelId;
                        tracker.model = this.model;
                    }
                    if (this.state.deviceTypeId == 0) {
                        tracker.deviceTypeId = this.deviceTypeId;
                        tracker.deviceType = this.deviceType;
                    }
                }
            }
            else if (this.state.class && this.state.class == "Person") {
                tracker.manufacturer = null;
                tracker.manufacturerId = null;
                tracker.model = null;
                tracker.modelId = null;
                tracker.deviceType = null;
                tracker.deviceTypeId = null;
            }
            if (this.state.type)
                tracker.type = this.state.type;
            tracker.category = null;
            tracker.categoryId = null;
            if (this.state.department) {
                tracker.departmentId = this.state.department.id;
            } else {
                tracker.departmentId = tracker.departmentId != 0 ? tracker.departmentId : null;
            }
            tracker.department = null;

            if (this.state.collection) {
                tracker.collectionId = this.state.collection.id;
            }
            else {
                tracker.collectionId = tracker.collectionId != 0 ? tracker.collectionId : null;
            }
            tracker.collection = null;
            tracker.name = tag.name;
            tracker.locationId = tag.locationId;
            tags.push({ beaconId: tag.id, beaconName: tag.name, assetName: tag.name, assetId: null });
            //console.log("Saving", tracker);
            //If new metadata, only the first link will be created, so we need to treat the rest as existing
            if (i == 0 && (this.state.manufacturerId == 0 || this.state.modelId == 0 || this.state.deviceTypeId == 0)) {
                let respo = await TrackerApi.saveTracker(tracker)
                    .then((response) => {
                        return response.json();
                    })
                    .then(resp => {
                        if (this.state.manufacturerId == 0) {
                            this.manufacturerId = resp.manufacturerId;
                            this.manufacturer = resp.manufacturer;
                        }
                        if (this.state.modelId == 0) {
                            this.modelId = resp.modelId;
                            this.model = resp.model;
                        }
                        if (this.state.deviceTypeId == 0) {
                            this.deviceTypeId = resp.deviceTypeId;
                            this.deviceType = resp.deviceType;
                        }
                        console.log("Saving12", resp);
                    });
            } else {
                console.log("Saving123", tracker);
                this.props.action.saveTrackerAction(tracker)
                    .catch(error => {
                        console.log('*DEBUG*-Save TrackerEdit Error', error);
                        //this.setState({ disabledButton: false });
                    });
            }
        }
        TrackerApi.getAllTrackers()
            .then(data => {
                tags.forEach((t, i) => {
                    let track = data.find(e => e.name == t.assetName);
                    t.assetId = track.id;
                    AssociationApi.saveAssociation(t)
                        .then(response => {
                            if (i == tags.length - 1) {
                                this.setState({
                                    //openEdit: !response.ok,
                                    openEdit: false,
                                    isDisabled: false,
                                    class: "",
                                    type: "",
                                    department: "",
                                    collection: "",
                                    manufacturer: null,
                                    manufacturerId: null,
                                    model: null,
                                    modelId: null,
                                    deviceType: null,
                                    deviceTypeId: null,
                                    isSaved: true,
                                    isSaveSuccessful: response.ok,
                                    associationsdataLoading: true,
                                    trackersdataLoading: true,
                                });
                                this.props.action.getAssociationsAction()
                                    .then(response => this.setState({ associationsdataLoading: false }))
                                    .catch(error => {
                                        console.log('*DEBUG*- Tracker List Error', error);
                                    });
                                this.props.action.getTrackersAction()
                                    .then(response => this.setState({ trackersdataLoading: false }))
                                    .catch(error => {
                                        console.log('*DEBUG*- Tracker List Error', error);
                                    });
                            }
                        })
                        .catch(error => console.log('*DEBUG*- Create Association error', error));
                });
            })
            .catch(error => {
                console.log('*DEBUG*- Tracker List Error', error);
            });
    }

    render() {
        const { levelsdata, assetsdata, buildingsdata, assetsoverviewdata, assetlabelsdata, departmentsdata, collectionsdata, sitesdata, associationsdata, trackersdata, mergedbuildingsdata, mergedlevelsdata } = this.props;
        const { open, selectedCategory, deletedCategoryId, tabindex, openLabel, openAsset, deletedAssetIds, openAssets,
            mergedbuildingsdataLoading, mergedlevelsdataLoading, assetsdataLoading, assetsoverviewdataLoading, assetlabelsdataLoading, departmentsdataLoading, collectionsdataLoading, sitesdataLoading, associationsdataLoading, trackersdataLoading, metadatasdataLoading } = this.state;

        if (assetsdataLoading || assetsoverviewdataLoading || sitesdataLoading || assetlabelsdataLoading || metadatasdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("propi:", this.props);
        mergedlevelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        this.props.mergedbuildingsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        collectionsdata.sort(constants.byPropertyCalled());
        departmentsdata.sort(constants.byPropertyCalled());
        sitesdata.sort(constants.byPropertyCalled());
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'flex', this.state.tabindex == 0 && this.state.select.length > 0 ? '60px' : '5px']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                    { name: 'footer', start: [0, 2], end: [1, 2] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    {tabindex == 0 && <Box direction="row" gap="small">
                        <Heading level='2' >Tags</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} fill="false">
                            <a data-for="tags" data-tip="Aruba hardware attached to a Tracker." >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="tags" place="right" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>}
                    {tabindex == 1 && <Box direction="row" gap="small">
                        <Heading level='2' >Labels</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} fill="false">
                            <a data-for="labels" data-tip="Used for the labelling of tags. Multiple labels can be assigned to a tag." >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="labels" place="right" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>}
                    {tabindex == 2 && <Box direction="row" gap="small">
                        <Heading level='2' >Zones</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} fill="false">
                            <a data-for="zones" data-tip="Areas defined on the Aruba maps to check for tag entry/exit." >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="zones" place="right" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>}
                    {/*<StyledButton typeSave label='Export to CSV' alignSelf="center" onClick={evt => this.handleExport(evt)} />*/}
                    {/*<Text>
                        Last updated at: {sync}
                    </Text>*/}
                    {/*tabindex !== 2 && <Box ---OLD--->
                        <StyledButton typeSave label={this.state.tabindex == 0 ? "Add Tag" : "Create New"}
                            onClick={() => this.handleNew()} />
                    </Box>*/}
                    <Box direction="row" /*pad={{ horizontal: "small" }}*/ gap="small" >
                        {this.state.select.length > 0 && tabindex == 0 &&
                            <CSVLink data={this.downloadData} filename="orgtags-template">
                                <StyledButton typeAdd label="Bulk Edit"
                                    onClick={() => {
                                        if (sessionStorage.getItem("organisationId"))
                                            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgedittags');
                                        else
                                            this.props.history.push('/orgedittags');
                                    }}
                                />
                            </CSVLink>
                        }
                        <StyledButton typeSave label={this.state.tabindex == 0 ? "Add Tag" : "Create New"}
                            onClick={() => this.handleNew()} />
                    </Box>
                </Box>

                <PopUpConfirm open={openLabel} text={constants.confirmMessage("label. This will also delete it from any tag(s) currently referencing it")} onClose={() => this.onCloseLabel()} onDelete={() => this.labelDelete()} />
                <PopUpConfirm open={openAsset} text={constants.confirmMessage("tag")} onClose={() => this.onCloseAsset()} onDelete={() => this.assetDelete()} />
                <PopUpConfirm open={openAssets} text={constants.confirmMessage("tags")} onClose={() => this.onCloseAssets()} onDelete={() => this.assetsDelete()} />

                <PopUp open={this.state.isDuplication} onClose={() => this.setState({ isDuplication: false, openEdit: this.state.selectedTags.length > 0 ? true : false })} header="Warning" text="Tracker(s) with the same Tag(s) name detected. Duplications won't be created again. No Link will be created." />
                <PopUp open={this.state.isControlTag} onClose={() => this.setState({ isControlTag: false, openEdit: this.state.selectedTags.length > 0 ? true : false })} header="Warning" text="Control Tags cannot be linked as they are independent of Trackers. No Link will be created." />
                <PopUp open={this.state.isSaved} onClose={() => this.setState({ isSaved: false })} text={this.state.isSaveSuccessful ? "Save was successful." : "Warning, unable to complete link due to a configuration issue, please contact your admin."} error={!this.state.isSaveSuccessful} />
                <PopUp open={this.state.isLabelsSaved} onClose={() => this.setState({ isLabelsSaved: false })} text={this.state.isLabelsSaveSuccessful ? "Save was successful." : "Warning, unable to assign label(s) due to an issue, please contact your admin."} error={!this.state.isLabelsSaveSuccessful} />

                <PopUp open={this.state.isDeletedWarning} onClose={() => this.setState({ isDeletedWarning: false })} header="Warning"
                    text={"The label you are attempting to delete no longer exists in the source system. This label will be automatically removed during the next data sync."} />

                <Box gridArea='main' overflow="auto" background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }}>
                    <Box flex justify='between' fill>
                        <Tabs flex justify="start" activeIndex={this.state.tabindex} onActive={(i) => this.onActive(i)}>
                            <Tab title="Tags">
                                <form>
                                    <OrgTagListTable handleNew={this.handleNew} assetsdata={assetsdata} levelsdata={mergedlevelsdata.filter(f => f.meridianId)} buildingsdata={mergedbuildingsdata} sitesdata={sitesdata} syncTime={assetsoverviewdata.assetSyncTimestamp}
                                        associationsdata={associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "")}
                                        trackersdata={trackersdata} collectionsdata={collectionsdata} departmentsdata={departmentsdata}
                                        assetlabelsdata={assetlabelsdata.filter((value, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.place === value.place && t.name === value.name
                                            ))
                                        )}
                                        handleDelete={this.handleDelete} handleEdit={this.handleEditAsset} deletedAssetIds={deletedAssetIds} flag={this.filter} handleBulkAssign={this.handleBulkAssign}
                                        onChangeSelect={this.onChangeSelect} select={this.state.select} />
                                </form>

                                {this.state.openBulkLabels &&
                                    <Layer
                                        position="center"
                                        modal={true}
                                        onClickOutside={this.onCloseBulkLabels}
                                        onEsc={this.onCloseBulkLabels}
                                    >
                                        <Box pad="medium" gap="small" width="medium" >
                                            <Heading level={3} margin="none">
                                                Edit Tag(s)
                                            </Heading>
                                            <Box gap="small">
                                                <Select
                                                    placeholder="Label(s)"
                                                    options={assetlabelsdata.sort(constants.byPropertyCalled("name"))}
                                                    labelKey="name"
                                                    value=""
                                                    onChange={event => {
                                                        let selectedLabel = this.bulkLabels.find(label => label.id == event.option.id);
                                                        if (selectedLabel) {
                                                            this.bulkLabels = this.bulkLabels.filter(l => l.id != event.option.id);
                                                        } else {
                                                            this.bulkLabels.push(event.option);
                                                        }
                                                        this.setState({ label: event.option });
                                                    }
                                                    }
                                                />
                                                <Grommet theme={customTheme}>
                                                    <Box id="tags" direction="column-responsive" wrap={true} >
                                                        {this.bulkLabels.map((c, idx) =>
                                                            <Button style={{ width: "fit-content" }} id={idx}
                                                                onClick={evt => {
                                                                    this.bulkLabels = this.bulkLabels.filter(l => l.id != c.id);
                                                                    this.setState({ update: true });
                                                                }}>
                                                                <Box
                                                                    align="center"
                                                                    background="brand"
                                                                    pad={{ horizontal: '2px', vertical: '1px' }}
                                                                    margin="2px"
                                                                    round="medium"
                                                                    direction="row"
                                                                >
                                                                    <Text size="xsmall" margin={{ right: 'xxsmall' }} truncate>
                                                                        {c.name}
                                                                    </Text>
                                                                    <FormClose size="small" color="white" />
                                                                </Box>
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Grommet>
                                                <Text>Warning, Tag labels will be replaced with new selection made.</Text>
                                            </Box>
                                            <Box
                                                tag="footer"
                                                gap="small"
                                                direction="row"
                                                align="center"
                                                justify="end"
                                                pad={{ top: "medium", bottom: "small" }}

                                            >
                                                <StyledButton typeSave label="Save" onClick={() => this.onBulkEditLabels()} disabled={this.state.isDisabledLabels} />
                                                <StyledButton typeCancel label="Cancel" onClick={this.onCloseBulkLabels} />
                                            </Box>
                                        </Box>
                                    </Layer>
                                }


                                {this.state.openEdit &&
                                    <Layer
                                        position="center"
                                        modal={true}
                                        onClickOutside={this.onCloseBulkAssign}
                                        onEsc={this.onCloseBulkAssign}
                                    >
                                        <Box pad="medium" gap="small" width="medium" >
                                            <Heading level={3} margin="none">
                                                Auto generate Tracker(s)
                                            </Heading>
                                            <Box gap="small">
                                                <Select placeholder="Class" options={this.context.feature.find(f => f.name == "sitePersonTracking").isEnabled ? ["Asset", "Person"] : ["Asset"]} value={this.state.class} onChange={evt => this.setState({ class: evt.option })} />

                                                {this.state.class == "Asset" && <Box gap="small">
                                                    <Box direction="row">
                                                        <Box width={this.state.manufacturer ? "85%" : "100%"}>
                                                            <Select
                                                                placeholder="Manufacturer"
                                                                options={[...new Set(this.props.manufacturersdata.map(e => e.name))]}
                                                                value={this.state.manufacturer ? this.state.manufacturer.name : ""}
                                                                onChange={evt => this.updateMetadata(evt.option, "manufacturer", "manufacturerId")} />
                                                        </Box>
                                                        {this.state.manufacturer && <Button
                                                            icon={<FormClose />}
                                                            onClick={() => this.resetMetaData("manufacturer")}
                                                        />}
                                                    </Box>
                                                    <Box direction="row">
                                                        <Box width={this.state.model ? "85%" : "100%"}>
                                                            <Select
                                                                placeholder="Model"
                                                                options={this.filterModelByManufacturer(this.state.manufacturer ? this.state.manufacturer.name : "")}
                                                                value={this.state.model ? this.state.model.name : ""}
                                                                onChange={evt => this.updateMetadata(evt.option, "model", "modelId")} />
                                                        </Box>
                                                        {this.state.model && <Button
                                                            icon={<FormClose />}
                                                            onClick={() => this.resetMetaData("model")}
                                                        />}
                                                    </Box>
                                                    <Box direction="row">
                                                        <Box width={this.state.deviceType ? "85%" : "100%"}>
                                                            <Select
                                                                placeholder="Model Type"
                                                                options={this.filterModelTypeByManufacturer(this.state.manufacturer ? this.state.manufacturer.name : "", this.state.model ? this.state.model.name : "")}
                                                                value={this.state.deviceType ? this.state.deviceType.name : ""}
                                                                onChange={evt => this.updateMetadata(evt.option, "deviceType", "deviceTypeId")} />
                                                        </Box>
                                                        {this.state.deviceType && <Button
                                                            icon={<FormClose />}
                                                            onClick={() => this.resetMetaData("deviceType")}
                                                        />}
                                                    </Box>
                                                </Box>}
                                                <Box direction="row">
                                                    <Box width={this.state.type ? "85%" : "100%"}>
                                                        <Select
                                                            placeholder="Type"
                                                            options={constants.getTypeByClass(this.state.class)}
                                                            value={this.state.type}
                                                            onChange={evt => this.setState({ type: evt.option })} />
                                                    </Box>
                                                    {this.state.type && <Button
                                                        icon={<FormClose />}
                                                        onClick={() => this.setState({ type: null })}
                                                    />}
                                                </Box>
                                                <Box direction="row">
                                                    <Box width={this.state.collection ? "85%" : "100%"}>
                                                        <Select
                                                            placeholder="Collection"
                                                            options={collectionsdata}
                                                            labelKey="name"
                                                            value={this.state.collection}
                                                            onChange={evt => this.setState({ collection: evt.option })} />
                                                    </Box>
                                                    {this.state.collection && <Button
                                                        icon={<FormClose />}
                                                        onClick={() => this.setState({ collection: null })}
                                                    />}
                                                </Box>
                                                <Box direction="row">
                                                    <Box width={this.state.department ? "85%" : "100%"}>
                                                        <Select
                                                            placeholder="Department"
                                                            options={departmentsdata}
                                                            labelKey="name"
                                                            value={this.state.department}
                                                            onChange={evt => this.setState({ department: evt.option })} />
                                                    </Box>
                                                    {this.state.department && <Button
                                                        icon={<FormClose />}
                                                        onClick={() => this.setState({ department: null })}
                                                    />}
                                                </Box>
                                            </Box>
                                            <Box
                                                tag="footer"
                                                gap="small"
                                                direction="row"
                                                align="center"
                                                justify="end"
                                                pad={{ top: "medium", bottom: "small" }}

                                            >
                                                <StyledButton
                                                    onClick={async () =>
                                                        await this.autoGenerateTracker()
                                                    }
                                                    typeSave
                                                    label="Link"
                                                    disabled={this.state.isDisabled}
                                                //    onClick={() => {
                                                //        if (this.props.clickonce) {
                                                //            this.setState({ isDisabled: true });
                                                //        }
                                                //        this.props.onDelete();
                                                //}}

                                                />
                                                <StyledButton typeCancel label="Cancel" onClick={this.onCloseBulkAssign} />
                                            </Box>
                                        </Box>
                                    </Layer>
                                }
                            </Tab>
                            <Tab title="Labels">
                                <OrgLabelListTable assetlabelsdata={assetlabelsdata} sitesdata={sitesdata} handleEdit={this.handleEditLabel} handleEditLabels={this.handleEditLabels} handleDelete={this.handleDelete} deletedLabelIds={this.state.deletedLabelIds} />
                            </Tab>
                            {false && <Tab title="Zones">
                            </Tab>}
                        </Tabs>
                    </Box>
                </Box>
                <Box gridArea='footer' background={constants.BACKGROUND_COLOR} /*pad={{ "horizontal": "medium" }}*/ /*pad="medium"*/ /*fill="vertical"*/>
                    {this.state.tabindex == 0 && this.state.select.length > 0 && <Box /*style1gray*/ background="light-4" width="100%" justify="between" direction="row" pad="small" /*style={{ borderRadius: "5px 5px 5px 5px", boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" }}*/>
                        <Box direction="row" gap="medium">
                            <Box>
                                {this.state.select.length} of {this.count} selected
                            </Box>
                            <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ select: [] })}>
                                Clear
                            </Box>
                        </Box>
                        {!RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                            <Box direction='row'>
                                <Button plain style={{ cursor: 'inherit' }} icon={<Blank />} />
                            </Box>
                            : <Box direction='row' gap="10px">
                                <ReactTooltip id="download" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <ReactTooltip id="edit" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <ReactTooltip id="link" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <ReactTooltip id="delete" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <CSVLink data={this.downloadData} filename="orgtags-template" style={{ height: "26px" }}>
                                    <Button /*style={{ paddingLeft: "0px" }}*/ plain icon={<Download />} data-for="download" data-tip="Export to CSV" />
                                </CSVLink>
                                <Button plain icon={<Edit />} onClick={() => this.setState({ openBulkLabels: true })} data-for="edit" data-tip="Edit" />

                                <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.handleBulkAssign(this.state.select)} icon={<Aggregate />} data-for="link" data-tip="Create Tracker and Link to Tag" />
                                {/*<Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ /*onClick={() => this.props.handleBulkAssign(this.state.select)} icon={<Edit />} /> */}
                                <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.handleDelete("assets", this.state.select)} icon={<Trash />} data-for="delete" data-tip="Delete" />
                            </Box>
                        }
                    </Box>}
                </Box>
            </Grid>
        );
    }
}



const mapStateToProps = state => ({
    buildingsdata: state.buildingsReducer.buildingsdata,
    levelsdata: state.levelsReducer.levelsdata,
    assetsdata: state.assetsReducer.assetsdata,
    assetsoverviewdata: state.assetsOverviewReducer.assetsoverviewdata,
    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    sitesdata: state.sitesReducer.sitesdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    associationsdata: state.associationsReducer.associationsdata,
    trackersdata: state.trackersReducer.trackersdata,
    mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
    mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
    metadatas: state.metadatasReducer.metadatas,
    manufacturersdata: state.manufacturersReducer.manufacturersdata,
    modelsdata: state.modelsReducer.modelsdata,
    typesdata: state.typesReducer.typesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...LevelAction, ...AssetAction, ...BuildingAction, ...AssetsOverviewAction, ...AssetLabelsAction, ...DepartmentAction, ...CollectionAction, ...SiteAction, ...AssociationAction, ...TrackerAction, ...MergedBuildingAction, ...MergedLevelAction, ...MetaDataAction, ...ManufacturerAction, ...ModelAction, ...TypeAction  }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(OrgTagListContainer);
