import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedUserPreferencesReducer = (state = initialState.selectedUserPreferencesReducer, action) => {
    switch(action.type) {

        case ActionType.GET_USER__PREFERENCES_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                userpreferencesdata: _.assign(action.userpreferencesdata)
            };
        }

        default: { return state; }
    }
};


export default selectedUserPreferencesReducer;