import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/assetbeacon`;
console.log('HOST:', SERVER_URL);

const apiqueue = [];
const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof AssetHistoryApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class AssetHistoryApi {
    static async getAllAssetHistories() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?$expand=ServiceState,History&$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=ServiceState,History&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }
    //static async getAllMappedAssets() {
    //    function getAllRecords(url, options, recordCount = 0, skipCount = 0, allRaws = []) {
    //        let urlWithSkip = `${url}&$skip=${skipCount}`;
    //        console.log(urlWithSkip, recordCount, skipCount);
    //        return fetch(urlWithSkip, options).then(response => response.json())
    //            .then(data => {
    //                allRaws = allRaws.concat(data);
    //                skipCount += data.length;
    //                if (skipCount >= recordCount || data.length === 0) {
    //                    return allRaws
    //                }
    //                else {
    //                    return getAllRecords(url, options, recordCount, skipCount, allRaws)
    //                }
    //            });
    //    }

    //    const options = {
    //        method: 'get',
    //        headers: await myHeadersWithToken()
    //    }
    //    return fetch(`${SERVER_URL}/?$expand=Map($select=Name;$expand=Group($select=Name))&$count=true&$top=0&${API_VERSION}`, options)
    //        .then(response => response.json())
    //        .then(data => {
    //            return getAllRecords(`${SERVER_URL}/?$expand=AssetBeaconTags($expand=Tag),Map($select=Name,Id;$expand=Group($select=Name,Id))&$filter=mapId ne null and isControlTag eq false&${API_VERSION}`, options, data.count);
    //        });
    //}

    static async saveAssetHistory(pagedata) {
        if (pagedata.id && pagedata.id != "0") {
            return fetch(`${SERVER_URL}/${pagedata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(pagedata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(pagedata)
            }).then(response => response);
        }
    }

    static async deleteAssetHistory(pageId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${pageId}/?${API_VERSION}`, options)
            .then(response => response);
    }


    static async getAssetHistory(pageId) {
        console.log('API:', pageId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${pageId}/?$expand=ServiceState,History&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}


applyHocOnClass(AssetHistoryApi);

export default AssetHistoryApi;
