import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class PageListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        }
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        return (
            <StyledBox ref={this.dtwrapper}>
                {this.renderPagesTable(this.props)}
            </StyledBox>
        );
    }

    renderPagesTable(props) {

        const COLUMNS = [
            {
                property: 'lcname',
                header: 'Page Name',
                render: pagedata => <Text>{pagedata.name}</Text>
            },
            {
                property: 'type',
                header: <Box justify="center" height="100%">Page Type</Box>,
                render: pagedata => <Text>{pagedata.type}</Text>
                /*render: pagedata => <Text>{(pagedata.type == "Detail") ? 'Detail' : ''}{(pagedata.type == "Web") ? 'Web' : ''}</Text>*/
            },
            //{
            //    property: 'lccategoryname',
            //    header: <Box justify="center" height="100%">Category Name</Box>,
            //    render: pagedata => <Text>{pagedata.categoryname}</Text>
            //},
            //{
            //    property: 'lccategoryparent',
            //    header: <Box justify="center" height="100%">Category Parent</Box>,
            //    render: pagedata => <Text>{pagedata.categoryparent}</Text>
            //},
            //{
            //    property: 'lccategorylocation',
            //    header: <Box justify="center" height="100%">App Location</Box>,
            //    render: pagedata => <Text>{pagedata.categorylocation}</Text>
            //},
            {
                property: 'lcparentloc',
                header: 'App Location',
                render: pagedata =>
                    <Text>
                        {constants.APP_LPN(pagedata.categorylocation, pagedata.categoryparent, pagedata.categoryname)}
                    </Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: pagedata => <Text hidden>{pagedata.id}</Text>,
            },
            {
                sortable: false,
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: pagedata => !RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, pagedata.id)} icon={<Blank />} /></Box>
                    : pagedata.orphaned ?
                        <Box width="24px">
                            {/*<Button plain disabled={props.deletedPageIds.includes(pagedata.id)} onClick={evt => props.handleEdit(evt, pagedata.id)} icon={<Edit color="#f04953" />} />*/}
                            <Button plain disabled={props.deletedPageIds.includes(pagedata.id)} onClick={evt => props.handleDelete(evt, pagedata.id)} icon={<Trash color="#333333" />} />
                        </Box> :
                        <Box width="24px">
                            {/*<Button plain disabled={props.deletedPageIds.includes(pagedata.id)} onClick={evt => props.handleEdit(evt, pagedata.id)} icon={<Edit color="#333333" />} />*/}
                            <Button plain disabled={props.deletedPageIds.includes(pagedata.id)} onClick={evt => props.handleDelete(evt, pagedata.id)} icon={<Trash color="#333333" />} />
                        </Box>
            },
            {
                //sortable: false,
                property: 'orphaned',
                header: "",
                size: "40px",
                render: pagedata => pagedata.orphaned ?
                    <Box fill={true} align="center">
                        <a data-for="PageInfo" style={{ /*"height": "24px"*/ display: "flex" }}
                            data-tip="This content has no app location, quick action or deep link associated. It will not be displayed in the client app.">
                            <CircleInformation size="medium" color="#F04953" />
                        </a>
                        <ReactTooltip id="PageInfo" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                    </Box> : null
            },
        ];

        let DATA = [];
        let QuickActions = [];
        this.props.sitesdata.quickActions.forEach((qa) => {
            if (!qa.deleted && qa.type == "PAGE") {
                QuickActions.push(
                    qa.foreignId,
                );
            }
        });

        if (props.pagesdata[0] && props.pagesdata[0].count != 0) {
            let deepLinkEnabled = props.organisationdata.appURI && props.organisationdata.contentUriEnabled;
            props.pagesdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.pagesdata.forEach(ev => {
                let p = (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent.toLowerCase() : '';
                let l = (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location.toLowerCase() : '';
                let n = (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name.toLowerCase() : '';
                let lpn = constants.APP_LPN(l, p, n);
                let deeplink = deepLinkEnabled ? props.organisationdata.appURI + constants.getSiteLevelRoute().substr(1) + "/page/" + ev.id : "";
                let isDeepLinked = deeplink && (props.pagesdata.some(p => p.id != ev.id && p.detail.includes(deeplink)) || props.placemarksdata.some(p => p.detail.includes(deeplink))) ? true : false;
                DATA.push({
                    ...ev,
                    lcname: ev.name ? ev.name.toLowerCase() : '',
                    categoryname: (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name : '',
                    lccategoryname: n,
                    categoryparent: (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '',
                    lccategoryparent: p,
                    categorylocation: (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '',
                    lccategorylocation: l,
                    lcparentloc: lpn,
                    deepLink: deeplink,
                    isDeepLinked: isDeepLinked,
                    orphaned: !lpn && !QuickActions.includes(ev.id) && !isDeepLinked
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                constants.APP_LPN(d.categorylocation, d.categoryparent, d.categoryname).toLowerCase().includes(search) ||
                ("detail".includes(search) && d.type == "Detail") ||
                ("web".includes(search) && d.type == "Web") ||
                ("html".includes(search) && d.type == "HTML") ||
                (("error".includes(search) || "info".includes(search)) && d.orphaned)
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.pagesdata.length != 0}
                sortable
                //sortoptions={[
                //    {col: 'lcname', label: 'Page Name'},
                //    {col: 'type', label: 'Page Type'},
                //    {col: 'lcparentloc', label: 'App Location'},
                //    ]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}
export default PageListTable;
